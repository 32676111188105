const colors = {
    // primary: "#D32626",
    // hoverPrimary: "#D32626",
    primary: "#7928ca",
    navBar: "gray.700",
    secondary: "#ff0080",
    hoverPrimary: "#7928ca",
    hoverSecondary: "#ff0080",
    gradient: `linear-gradient(to right, #7928ca, #ff0080)`,
    hoverGradient: `linear-gradient(to right, #7928ca, #ff0080)`
    // Add other colors as needed
};



export default colors;

import { Th, Table, TableContainer, Thead, Tr, Td, Tbody, Button, useDisclosure, useToast, Box, Flex, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import moment from 'moment/moment';
import { truncateText } from '../../services/apis/api';
import { formatDateArEn, formatNumber } from '../../services/glocalFunctions';


const ExpensesTransactionsTable = ({
    rows,
    columns,
    setPage,
    limit,
    skip,
    totalPages,
    page,
    setSkip,
    onPagination
}) => {
    const { user } = UserState();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const handleNextPage = () => {
        try {
            if (page < totalPages) {
                setPage(page + 1);
                // console.log(page * limit);
                onPagination(page * limit, limit);
                setSkip(page * limit); // Update skip whenever page or limit changes
            }
        } catch (e) {
            console.log(`ERROR IN PAGINATION ${e}`);
        }
    };

    const handlePrevPage = () => {
        try {
            if (page > 1) {
                setPage(page - 1);
                onPagination((page - 2) * limit, limit);
                setSkip((page - 2) * limit);
            }
        } catch (e) {
            console.log(`ERROR IN PAGINATION ${e}`);
        }
    };

    // TABLE COLUMNS AND COLUMNS  
    const direction = isArabic ? 'rtl' : 'ltr';
    const textAlign = isArabic ? 'right' : 'left';

    return (
        <>
            <TableContainer shadow="md" borderRadius="lg" display="flex" flexDirection="column">
                <Table dir={isArabic ? 'rtl' : "ltr"} variant="simple">
                    <Thead bg="gray.50">
                        <Tr>
                            {columns?.map((column, index) => (
                                <Th textAlign={textAlign} dir={direction} key={index}>{t(column)}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rows?.length > 0 && (
                            rows?.map((request, index) => (
                                <Tr key={request._id} _hover={{ bg: 'gray.50' }}>
                                    <>
                                        <Td textAlign={textAlign} dir={direction}>{formatNumber(index + 1, isArabic)}</Td>
                                        <Td textAlign={textAlign} dir={direction}>{request?.date ? formatDateArEn(request?.date, isArabic) : ''}</Td>
                                        <Td textAlign={textAlign} dir={direction}>{formatNumber(request?.amount, isArabic) || 'N/A'}</Td>
                                        {columns.includes('transaction-type') && (
                                            <Td textAlign={textAlign} dir={direction}>{request.transactionType || 'N/A'}</Td>
                                        )}
                                        {columns?.includes('payment-method') &&
                                            <Td textAlign={textAlign} dir={direction}>{request?.paymentMethod || 'N/A'}</Td>
                                        }
                                        {columns?.includes('payment-id') &&
                                            <Td textAlign={textAlign} dir={direction}>{request?.paymentId || 'N/A'}</Td>
                                        }
                                        {columns.includes('source-of-income') && (
                                            <Td textAlign={textAlign} dir={direction}>{request?.source || 'N/A'}</Td>
                                        )}
                                        {columns.includes('player-name') && (
                                            <Td textAlign={textAlign} dir={direction}>{request?.playerId?.userName || request?.player?.userName || request?.employeeId?.name || request?.employee?.name || 'N/A'}</Td>
                                        )}
                                        {columns.includes('game-name') && (
                                            <Td textAlign={textAlign} dir={direction}>{request?.game?.name || 'N/A'}</Td>
                                        )}
                                        <Td textAlign={textAlign} dir={direction}>{request?.createdBy?.name || request?.requestedBy?.name || 'N/A'}</Td>
                                        <Tooltip label={request?.description || 'N/A'}>
                                            <Td textAlign={textAlign} dir={direction}>{request?.description ? truncateText(request?.description) : 'N/A'}</Td>
                                        </Tooltip>
                                    </>
                                </Tr>
                            ))
                        )}
                    </Tbody>
                </Table>
                {rows ?
                    rows?.length <= 0 &&
                    <div className='w-full  min-h-80 flex items-center justify-center'>
                        <h1 className='text-center'>{t('no-expenses-found')}</h1>
                    </div>
                    :
                    <div className='w-full  min-h-80 flex items-center justify-center'>
                        <h1 className={`text-center `}>
                            {isArabic && '....'} {t('loading')} {!isArabic && '....'}
                        </h1>
                    </div>
                }
            </TableContainer>
            {/* Pagination Controls */}
            {rows?.length > 0 &&
                <Flex justify="space-between" align="center" mt={4}>
                    <Button onClick={handlePrevPage} isDisabled={page === 1}>
                        {t('previous')}
                    </Button>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isArabic ? 'row-reverse' : 'row',
                            gap: 1
                        }}
                    >
                        <h1>{t('page')}</h1>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isArabic ? 'row-reverse' : 'row',
                                gap: 1
                            }}
                        >
                            {page}

                            <h1>{t('of')}</h1>
                            {totalPages}
                        </ Box>
                    </Box>
                    <Button onClick={handleNextPage} isDisabled={page === totalPages}>
                        {t('next')}
                    </Button>
                </Flex>
            }
        </>
    );
};

export default ExpensesTransactionsTable
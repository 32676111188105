import React, { useEffect, useState } from 'react';
import CreateGame from '../../components/dialogueBoxes/CreateGame';
import { get } from '../../services/apis/api';
import _ from 'lodash';
import { Tooltip, Button } from '@chakra-ui/react';
import { truncateText } from '../../utills/truncateText';
import { useTranslation } from 'react-i18next';
import CreateAlRwaadService from '../../components/dialogueBoxes/CreateAlRwaadService';
import DeleteAlRwaadService from '../../components/dialogueBoxes/DeleteAlRwaadService';
import ViewAlRwaadUser from '../../components/operations/ViewAlRwaadUsers';


const AlRwaad = () => {
    const [alRwaadServices, setAlRwaadServices] = useState([]);
    const [search, setSearch] = useState('');
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [showAlRwaadUsers, setShowAlRwaadUsers] = useState(false);
    const getAlRwaadServices = async (searchQuery = '') => {
        try {
            const res = await get(`al-rwaad/all${searchQuery ? `?search=${searchQuery}` : ''}`);
            console.log(res);
            setAlRwaadServices(res.data.alRwaadServices);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getAlRwaadServices();
    }, []);

    // Debounced search function
    const debouncedSearch = _.debounce((query) => {
        getAlRwaadServices(query);
    }, 300);

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearch(query);
        debouncedSearch(query);
    };

    return (
        <>{!showAlRwaadUsers ?
            <div>
                <div className={`flex justify-between px-2 items-center mb-4 ${isArabic ? "flex-row-reverse" : "row"}`}>
                    <div>
                        <div className="relative">
                            <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input
                                type="text"
                                id="table-search-users"
                                className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                                placeholder={t("search-for-al-rwaad-services")}
                                value={search}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>


                    <div className="flex space-x-4">
                        <ViewAlRwaadUser showAlRwaadUsers={showAlRwaadUsers} setShowAlRwaadUsers={setShowAlRwaadUsers} />
                        <CreateAlRwaadService getAlRwaadServices={getAlRwaadServices} isEdit={false} />
                    </div>
                </div>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg" dir={isArabic ? 'rtl' : 'ltr'}>
                    <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50" >
                            <tr>
                                <th scope="col" className="px-16 py-3">
                                    {t("image")}

                                </th>
                                <th scope="col" className="px-6 py-3">
                                    {t("name")}
                                </th>


                                <th scope="col" className="px-6 py-3">
                                    {t("action")}

                                </th>
                                <th scope="col" className="px-6 py-3">
                                    {t("delete")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                alRwaadServices.map((alRwaadService, index) => (
                                    <tr key={index} className="w-full bg-white border-b hover:bg-gray-50">
                                        <td className="p-4 w-32 h-32">
                                            <img src={alRwaadService.icon} className="object-cover h-full rounded max-w-full max-h-full" alt="alRwaadService Icon" />
                                        </td>
                                        <td className="px-6 py-4 font-semibold text-gray-900">
                                            {!isArabic ? alRwaadService.name : alRwaadService.arabicName}
                                        </td>

                                        <td className="px-6 py-4">
                                            <CreateAlRwaadService getAlRwaadServices={getAlRwaadServices} AlRwaadService={alRwaadService} isEdit={true} />
                                        </td>
                                        <td className="px-6 py-4">
                                            <DeleteAlRwaadService alRwaadService={alRwaadService} getAlRwaadServices={getAlRwaadServices} />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div> :
            <ViewAlRwaadUser showAlRwaadUsers={showAlRwaadUsers} setShowAlRwaadUsers={setShowAlRwaadUsers} />

        }
        </>
    );
};



export default AlRwaad
import React, { useState } from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    Tooltip,
    FormLabel,
    Textarea,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { RxCrossCircled } from 'react-icons/rx'
import { IoIosClose } from 'react-icons/io'

const RequestMoreInfo = ({
    handleSubmit,
    message,
    setMessage,
    isButton,
    isLoading,
    isOpen,
    onClose,
    onOpen
}) => {
    // const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return (
        <>
            <Tooltip isDisabled={isButton} label={t('request-more-info')} aria-label="Reject tooltip">
                {isButton ?
                    <Button
                        bg="gray.50"
                        _hover={{ bg: "gray.100" }}
                        color="gray.700"
                        borderColor="gray.200"
                        onClick={onOpen}
                        leftIcon={<IoIosClose className="w-4 h-4" />}
                    >
                        <span className="text-sm font-medium">{t('request-more-info')}</span>
                    </Button>
                    :
                    <button
                        onClick={onOpen}
                        className="text-red-600 hover:text-red-800"
                    >
                        <RxCrossCircled className="h-5 w-5" />
                    </button>
                }
            </Tooltip>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader textAlign={isArabic ? "right" : "left"} fontSize='lg' fontWeight='bold'>
                            {t('request-more-info')}
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <FormLabel textAlign={isArabic ? "right" : "left"}>
                                <Textarea
                                    mt={3}
                                    value={message}
                                    textAlign={isArabic ? "right" : "left"}
                                    onChange={(e) => setMessage(e?.target?.value)}
                                    placeholder={`${t('request-more-info')}...`}
                                    resize="vertical"  // Optional: allows vertical resizing of the textarea
                                />
                            </FormLabel>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button
                                size={"sm"}
                                ref={cancelRef}
                                onClick={onClose}
                            >
                                {t('close')}
                            </Button>
                            <Button
                                size={"sm"}
                                variant="solid"
                                colorScheme='blue'
                                isLoading={isLoading}
                                isDisabled={!message}
                                onClick={() => {
                                    handleSubmit();
                                }}
                                ml={3}
                            >
                                {t('request-more-info')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default RequestMoreInfo
import React, { useRef, useState } from 'react';
import { Badge, Button, IconButton, InputGroup, Select, Tooltip, useToast } from '@chakra-ui/react';
import { MdFilterAlt } from "react-icons/md";
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    //   DrawerTitle,
    DrawerTrigger,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import { CiCalendarDate } from 'react-icons/ci';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const PayrollFilters = ({
    allDepartments,
    onApplyFilter,
    filterData,
    setFilterData,
    onResetFilter,
    appliedFilterCount
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const { pathname } = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const datePickerRef = useRef(null);  // Ref for DatePicker component
    const toast = useToast();

    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);

    // Function to open the calendar on calendar icon click
    const handleCalendarIconClick = () => {
        if (datePickerRef.current) {
            datePickerRef.current.setOpen(true);  // Open the calendar
        }
    };

    // HANDLE DATE CHANGE FUNCTION 
    const handleDateChange = (date) => {
        try {
            if (date instanceof Date && !isNaN(date)) {
                const currentDate = new Date(); // Get the current date
                const selectedMonth = date.getMonth(); // Get the selected month
                const selectedYear = date.getFullYear(); // Get the selected year

                const currentMonth = currentDate.getMonth(); // Get the current month
                const currentYear = currentDate.getFullYear(); // Get the current year

                // Check if the selected date is in the current or future month
                if (
                    selectedYear > currentYear ||
                    (selectedYear === currentYear && selectedMonth >= currentMonth)
                ) {
                    toast({
                        title: "Warning",
                        description: "You cannot select the current or a future month.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    setFilterData((prevData) => ({
                        ...prevData,
                        attendanceDate: {
                            ...prevData.attendanceDate,
                            startDate: new Date(selectedYear, selectedMonth, 1),
                            month: selectedMonth + 1, // Convert 0-based to 1-based
                            year: selectedYear,
                        },
                    }));
                }
            } else {
                console.log("Invalid date selected");
            }
        } catch (e) {
            console.log(`ERROR WHILE SETTING DATE ${e}`);
        }
    };

    const handleChangeData = (field, value) => {
        try {
            setFilterData((prevData) => ({
                ...prevData,
                [field]: value
            }))
        } catch (e) {
            console.log(`ERROR WHILE CHANGING FILTER DATA ${e}`);
        }
    }


    // ROW CLASS STYLE 
    const rowClass = `flex flex-col w-full ${isArabic ? 'items-end' : 'items-start'}   gap-2`

    return (
        <>
            <Badge
                display="flex"
                alignItems="center"
                gap={2}
                px={2}
                py={1}
                borderRadius="md"
                onClick={onOpen}
            // colorScheme={appliedFilterCount > 0 ? "blue" : "gray"} // Highlight badge if filters are applied
            >
                {/* Display the applied filter count */}
                {appliedFilterCount}

                {/* Tooltip and IconButton for the filter icon */}
                <Tooltip
                    label={t('payroll-filters')}
                >
                    <IconButton
                        onClick={onOpen}
                    >
                        <MdFilterAlt />
                    </IconButton>
                </Tooltip >
            </Badge>

            <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader
                            padding={3}
                            borderBottom="1px solid #E1E1E1"
                        >
                            <h1>{t('payroll-filters')}</h1>
                        </DrawerHeader>
                        <DrawerBody padding={3}>
                            <div className='w-full flex flex-col items-start gap-2 lg:gap-4'>
                                {/* DATE PICKER  */}
                                <div className={rowClass}>
                                    <h1 className='text-sm lg:text-base font-semibold'>{t('select-month')}</h1>
                                    <InputGroup
                                        onClick={handleCalendarIconClick}  // Open calendar on icon click
                                        w="full"
                                        // className="border rounded-md"
                                        className="border rounded-md relative z-10"  //
                                    >
                                        <DatePicker
                                            ref={datePickerRef}  // Attach the ref
                                            selected={filterData?.attendanceDate?.startDate}
                                            onChange={handleDateChange}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            placeholderText="Select Month"
                                            className="p-2 w-full relative z-50"  // Ensure full width
                                        />
                                        <CiCalendarDate
                                            onClick={handleCalendarIconClick}  // Open calendar on icon click
                                            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-xl text-gray-500 pointer-events-auto cursor-pointer"
                                        />
                                    </InputGroup>
                                </div>

                                {/* PAYROLL STATUSES DROP DOWN  */}
                                {!pathname?.includes('account') &&
                                    <div className={rowClass}>
                                        <h1 className='text-sm lg:text-base font-semibold'>{t('status')}</h1>
                                        <Select
                                            name="status"
                                            width="100%"
                                            zIndex={0}
                                            value={filterData?.payrollStatus}
                                            onChange={(e) => {
                                                handleChangeData('payrollStatus', e?.target?.value);
                                            }}
                                        >
                                            <option value="ALL">{t('all')}</option>
                                            <option value="generated">{t('generated')}</option>
                                            <option value="pending">{t('pending')}</option>
                                        </Select>
                                    </div>
                                }
                                {/* PAYMENT STATUSES DROP DOWN  */}
                                <div className={rowClass}>
                                    <h1 className='text-sm lg:text-base font-semibold'>{t('payment-status')}</h1>
                                    <Select
                                        name="status"
                                        width="100%"
                                        value={filterData?.paymentStatus}
                                        onChange={(e) => {
                                            handleChangeData('paymentStatus', e?.target?.value);
                                        }}
                                    >
                                        <option value="ALL">{t('all')}</option>
                                        <option value="PENDING">{t('pending')}</option>
                                        <option value="APPROVED">{t('approved')}</option>
                                        <option value="REJECTED">{t('rejected')}</option>
                                    </Select>
                                </div>

                                {/* DEPARTMENT DROP DOWN  */}
                                <div className={rowClass}>
                                    <h1 className='text-sm lg:text-base font-semibold'>{t('department')}</h1>
                                    <Select
                                        name="department"
                                        width="100%"
                                        value={filterData?.selectedDepartment}
                                        onChange={(e) => {
                                            handleChangeData('selectedDepartment', e?.target?.value);
                                        }}
                                    >
                                        <option value="all">{t('all')}</option>
                                        {allDepartments?.map((department, index) => (
                                            <option key={index} value={department?.name}>
                                                {t(department?.name)}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </DrawerBody>
                        <DrawerFooter
                            borderTop="1px solid #E1E1E1"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            padding={3}
                        >
                            <Button
                                variant="outline"
                                onClick={() => {
                                    onResetFilter();
                                    onClose();
                                }}
                                mr={2}
                            >
                                {t('reset')}
                            </Button>
                            <Button
                                colorScheme="blue"
                                onClick={() => {
                                    onApplyFilter();
                                    onClose();
                                }}
                            >
                                {t('apply')}
                            </Button>
                        </DrawerFooter>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    );
};

export default PayrollFilters;

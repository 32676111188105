import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter } from "react-router-dom";
import UserProvider from './context/user';
import EmployeeProvider from './context/addEmployee';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { NetworkStatusProvider } from './context/networkStatusContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <I18nextProvider i18n={i18n}> */}
      <ChakraProvider>
        <BrowserRouter>
        <NetworkStatusProvider>
          <UserProvider>
            <EmployeeProvider>
              <App />
            </EmployeeProvider>
          </UserProvider>
        </NetworkStatusProvider>
        </BrowserRouter>
      </ChakraProvider>
    {/* </I18nextProvider> */}
  </React.StrictMode>
);



import React, { useEffect, useState } from 'react';
import CreateAdmin from '../../components/dialogueBoxes/CreateAdmin';
import ClubProfile from '../../components/organization/ClubProfile';
import { get } from '../../services/apis/api';

const Clubs = () => {
    const [admins, setAdmins] = useState([]);
    const [viewClub, setViewClub] = useState(false);
    const [selectedClub, setSelectedClub] = useState();
    const [searchTerm, setSearchTerm] = useState('');

    const getAdmins = async (search = '') => {
        try {
            const res = await get(`user/admins?search=${search}`);
            setAdmins(res.data.admins);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAdmins(searchTerm);
    }, [searchTerm]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <>
            {viewClub ? (
                <ClubProfile setViewClub={setViewClub} selectedClub={selectedClub} />
            ) : (
                <>
                    <div className='flex w-full justify-between px-2 items-center mb-4'>
                        <div>
                            <div className="relative">
                                <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    id="table-search-users"
                                    className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Search club admin name"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </div>
                        </div>
                        <div>
                            <CreateAdmin getAdmins={() => getAdmins(searchTerm)} />
                        </div>
                    </div>
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                <tr>
                                    <th scope="col" className="px-16 py-3">
                                        Logo
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Club Name
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Admin Name
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Mobile
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Club Designation
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {admins.map((admin, index) => (
                                    <tr key={index} onClick={() => { setViewClub(true); setSelectedClub(admin); }} className="w-full bg-white border-b hover:bg-gray-50 cursor-pointer">
                                        <td className="p-4 w-32 h-32 ">
                                            {admin?.organizationId?.logo ? (
                                                <img src={admin?.organizationId?.logo} className="object-cover h-full rounded max-w-full max-h-full" alt="Club Logo" />
                                            ) : "-"}
                                        </td>
                                        <td className="px-6 py-4 font-semibold text-gray-900 ">
                                            {admin?.organizationId?.name ? admin?.organizationId?.name : '-'}
                                        </td>
                                        <td className="px-6 py-4">
                                            {admin.name}
                                        </td>
                                        <td className="px-6 py-4">
                                            {admin.mobile}
                                        </td>
                                        <td className="px-6 py-4">
                                            {admin.role}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </>
    );
};

export default Clubs;

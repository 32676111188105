import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Button,
    useDisclosure,
    useToast,
    Spinner,
} from '@chakra-ui/react';
import Select from 'react-select';
import { get, post, postFormData } from '../../services/apis/api';

const CreateAward = ({ getAllAwards }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);
    const toast = useToast();
    const [name, setName] = useState('');
    const [media, setMedia] = useState([]);
    const [eventId, setEventId] = useState(null);
    const [teamId, setTeamId] = useState(null);
    const [individual, setIndividual] = useState('');
    const [description, setDescription] = useState('');
    const [additionalNotes, setAdditionalNotes] = useState('');

    const [events, setEvents] = useState([]);
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getAllEvents();
        getAllTeams();
    }, []);

    const handleFileChange = (e) => {
        setMedia(e.target.files);
    };

    const getAllEvents = async () => {
        try {
            const res = await get('event/dropdown');
            if (res.statusCode === 200) {
                const eventOptions = res.data.dropdown.map(event => ({
                    value: event._id,
                    label: event.name,
                }));
                setEvents(eventOptions);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getAllTeams = async () => {
        try {
            const res = await get('team/dropdown');
            if (res.statusCode === 200) {
                const teamOptions = res.data.dropdown.map(team => ({
                    value: team._id,
                    label: team.name,
                }));
                setTeams(teamOptions);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = async () => {
        try {
            setLoading(true)
            const formData = new FormData();
            formData.append('name', name);
            Array.from(media).forEach((file) => {
                formData.append('media', file);
            });
            formData.append('eventId', eventId);
            formData.append('teamId', teamId);
            formData.append('individual', individual);
            formData.append('description', description);
            formData.append('additionalNotes', additionalNotes);

            const res = await postFormData('award/add', formData);
            console.log(res);
            if(res.statusCode===201){
                getAllAwards();
                onClose();
                setLoading(false)
                toast({
                    title: 'Successful...!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast({
                title: 'Error...!',
                description: "Please, fill all the field properly",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            <Button onClick={onOpen}>Create Award</Button>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
                size={'5xl'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create Award</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '16px' }}>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    ref={initialRef}
                                    placeholder='Name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Media</FormLabel>
                                <Input
                                    type='file'
                                    multiple
                                    onChange={handleFileChange}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Event</FormLabel>
                                <Select
                                    options={events}
                                    value={events.find(event => event.value === eventId)}
                                    onChange={(selectedOption) => setEventId(selectedOption.value)}
                                    placeholder="Select Event"
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Team</FormLabel>
                                <Select
                                    options={teams}
                                    value={teams.find(team => team.value === teamId)}
                                    onChange={(selectedOption) => setTeamId(selectedOption.value)}
                                    placeholder="Select Team"
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Individual</FormLabel>
                                <Input
                                    placeholder='Individual'
                                    value={individual}
                                    onChange={(e) => setIndividual(e.target.value)}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Description</FormLabel>
                                <Textarea
                                    placeholder='Description'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Additional Notes</FormLabel>
                                <Textarea
                                    placeholder='Additional Notes'
                                    value={additionalNotes}
                                    onChange={(e) => setAdditionalNotes(e.target.value)}
                                />
                            </FormControl>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={onClose} colorScheme='gray' mr={3}>Cancel</Button>
                        <Button colorScheme='blue'  onClick={handleSubmit}>
                           {loading ? <Spinner /> : " Save"}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateAward;

import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    Switch,
    useDisclosure,
    useToast,
    Spinner,
} from '@chakra-ui/react';
import { get, post, patch } from '../../../services/apis/api'; // Adjust your API import paths

const CompanyLeavePolicyModal = ({ getLeavePolicy }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [leavePolicy, setLeavePolicy] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    // Initial form state for new leave policy
    const initialFormState = {
        name: '', // Add the company name field
        year: new Date().getFullYear(), // Add the year field
        paidLeave: 12,
        sickLeave: 6,
        casualLeave: 4,
        unpaidLeave: 0,
        carryForward: false,
    };
    const [formData, setFormData] = useState(initialFormState);

    // Fetch existing leave policy for the specified company
    const fetchLeavePolicy = async () => {
        try {
            const res = await get(`leave-policy/get`); // Adjust API route based on your backend
            if (res.statusCode === 200 && res.data) {
                setLeavePolicy(res.data);
                setFormData({
                    name: res.data.name || '',
                    year: res.data.year || new Date().getFullYear(),
                    paidLeave: res.data.paidLeave || 12,
                    sickLeave: res.data.sickLeave || 6,
                    casualLeave: res.data.casualLeave || 4,
                    unpaidLeave: res.data.unpaidLeave || 0,
                    carryForward: res.data.carryForward || false,
                });
                setIsEditing(true);
            } else {
                setIsEditing(false);
            }
        } catch (error) {
            console.log('Error fetching leave policy:', error);
        }
    };

    useEffect(() => {
        if (isOpen) {
            fetchLeavePolicy();
        }
    }, [isOpen]);

    // Handle form input change
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // Handle add/update leave policy
    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const apiCall = isEditing ? patch : post;
            const url = isEditing ? `leave-policy/update/${leavePolicy._id}` : 'leave-policy/add';
            const res = await apiCall(url, {
                ...formData,
            });
            if (res.statusCode === 200 || res.statusCode === 201) {
                toast({
                    title: isEditing ? 'Leave Policy Updated' : 'Leave Policy Added',
                    description: isEditing
                        ? 'The leave policy has been successfully updated.'
                        : 'The leave policy has been successfully created.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                onClose();
                getLeavePolicy();
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false);
            console.log('Error submitting leave policy:', error);
            toast({
                title: 'Error',
                description: 'An error occurred while submitting the leave policy.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            {/* Button to open the modal */}
            <Button onClick={onOpen} colorScheme="blue">
                {isEditing ? 'Edit Leave Policy' : 'Add Leave Policy'}
            </Button>

            {/* Modal for adding/editing leave policy */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{isEditing ? 'Edit Leave Policy' : 'Add Leave Policy'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl mb={4}>
                            <FormLabel>Policy Name</FormLabel>
                            <Input
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Enter the policy name"
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Year</FormLabel>
                            <Input
                                name="year"
                                type="number"
                                value={formData.year}
                                onChange={handleChange}
                                placeholder="Enter the year"
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Paid Leave Days</FormLabel>
                            <Input
                                name="paidLeave"
                                type="number"
                                value={formData.paidLeave}
                                onChange={handleChange}
                                placeholder="Enter the number of paid leave days"
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Sick Leave Days</FormLabel>
                            <Input
                                name="sickLeave"
                                type="number"
                                value={formData.sickLeave}
                                onChange={handleChange}
                                placeholder="Enter the number of sick leave days"
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Casual Leave Days</FormLabel>
                            <Input
                                name="casualLeave"
                                type="number"
                                value={formData.casualLeave}
                                onChange={handleChange}
                                placeholder="Enter the number of casual leave days"
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Unpaid Leave Days</FormLabel>
                            <Input
                                name="unpaidLeave"
                                type="number"
                                value={formData.unpaidLeave}
                                onChange={handleChange}
                                placeholder="Enter the number of unpaid leave days"
                            />
                        </FormControl>
                        <FormControl display="flex" alignItems="center" mb={4}>
                            <FormLabel mb={0}>Allow Carry Forward</FormLabel>
                            <Switch
                                name="carryForward"
                                isChecked={formData.carryForward}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" ml={3} onClick={handleSubmit}>
                            { isLoading ? <Spinner /> :  isEditing ? 'Update' : 'Add'}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CompanyLeavePolicyModal;

import React, { useState } from 'react';
import { Box, Input, FormControl, FormLabel, Flex } from '@chakra-ui/react';

const DateRangePicker = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        setStartDate(newStartDate);

        // Reset end date if it's before the new start date
        if (newStartDate > endDate) {
        setEndDate('');
        }
    };

    return (
        <Box className="p-4">
            <Flex alignItems="center" gap={4}>
            <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="start-date" mb="0" className="text-sm font-medium text-gray-700">
                StartDate
                </FormLabel>
                <Input
                    type="date"
                    id="start-date"
                    value={startDate}
                    onChange={handleStartDateChange}
                    className="ml-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
            </FormControl>
            <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="end-date" mb="0" className="text-sm font-medium text-gray-700">
                EndDate
                </FormLabel>
                <Input
                    type="date"
                    id="end-date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    min={startDate} // Set the minimum value for end date
                    className="ml-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
            </FormControl>
            </Flex>
        </Box>
    );
};

export default DateRangePicker;

import React, { useState } from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    Button,
    Tooltip,
    useToast,
    Textarea,
} from '@chakra-ui/react'
import { post } from '../../services/apis/api'
import { useTranslation } from 'react-i18next'
import { FaCheckCircle } from 'react-icons/fa'

const ConfirmCloseRequestBox = ({
    request,
    fetchData,
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const toast = useToast();
    const cancelRef = React.useRef()
    const [closingNote, setClosingNote] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // CLOSE TREATMENT REQUEST 
    const closeRequest = async () => {
        try {
            setIsLoading(true);
            const _payload = {
                closingNote: closingNote
            }
            const response = await post(`clinic/close-request/${request?._id}`, _payload);
            setIsLoading(false);
            if (response.statusCode == 200) {
                fetchData();
                // navigate('/clinic/appointments');
                onClose();
                toast({
                    title: response?.data?.message || 'Successfull...!',
                    description: 'Request  Closed!',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: response?.data?.message || 'error...!',
                    description: 'Request not Closed!',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (e) {
            setIsLoading(false);
            toast({
                title: e?.response?.data?.message || 'error...!',
                description: 'Request not Closed!',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            console.log(`ERROR WHILE CLOSING TREATMENT REQUEST : ${e}`);
        }
    };

    return (
        <>
            <Tooltip label="Close Request" aria-label="Reject tooltip">
                <Button
                    colorScheme="green"
                    aria-label="Reject"
                    onClick={onOpen}
                    leftIcon={<FaCheckCircle />}
                >
                    {t('close-request')}
                </Button>
            </Tooltip>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t('close-request')}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to close this request? This action will close all associated appointments and cannot be undone.

                            {/* ADD NOTE/FEEDBACK  */}
                            <Textarea
                                mt={4}
                                value={closingNote}
                                textAlign={isArabic ? "right" : "left"}
                                placeholder={t('add-closing')}
                                resize="vertical"  // Optional: allows vertical resizing of the textarea
                                onChange={(e) => {
                                    setClosingNote(e?.target?.value)
                                }}
                            />
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                {t('cancel')}
                            </Button>
                            <Button
                                colorScheme='green'
                                isLoading={isLoading}
                                onClick={() => {
                                    closeRequest();
                                }} ml={3}
                            >
                                {t('close-request')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default ConfirmCloseRequestBox
import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Button,
  useToast,
} from '@chakra-ui/react';
import { Select } from '@chakra-ui/react';
import { get, patch, post } from '../../services/apis/api';
import { BiNotepad } from 'react-icons/bi';
import { calculateAge } from '../../utills/calculateAge';



const AssignCoachToPlayer = ({getPlayerData, player}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = useRef(null);
    const finalRef = useRef(null);
    const toast = useToast();
    const [coaches, setCoaches] = useState([]);
    const [selectedCoach, setSelectedCoach] = useState('');
    

    const handleSubmit = async() => {
        try {
            const age = await calculateAge(player?.dateOfBirth);
            // console.log("my age is ---->", player);

            const res = await patch(`player/assign-coach/${player?._id}`, {coachId: selectedCoach, age});
            console.log(res);
            if(res.statusCode === 200){
                onClose();
                getPlayerData();
                toast({
                    title: 'Successfull...!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: 'Opps...!',
                description: error.response.data.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
            console.log(error);
        }
    };

    const getCoaches = async()=>{
        try {
            console.log("",player);
            const age = await calculateAge(player?.dateOfBirth);
            console.log("my age is ---->", age, player?.gameId?._id);
            const res = await get(`coach/player-category?gameId=${player?.gameId?._id}&age=${age}`);
            console.log(res);
            if(res.statusCode === 200){
                setCoaches(res.data.dropdown);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getCoaches();
    }, [])
    

    return (
        <>
            <Button colorScheme='blue' onClick={onOpen}>Assign Coach</Button>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Assign Coach To Player</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    
                    <FormControl mt={4}>
                        <FormLabel>Coach Name</FormLabel>
                        <Select
                            placeholder='Select Coach Name'
                            name='coach'
                            value={selectedCoach}
                            onChange={(e)=>{setSelectedCoach(e.target.value)}}
                        >
                            {coaches.length> 0 && coaches.map((coach, index) => (
                            <option key={coach._id} value={coach._id}>
                                {coach.name}
                            </option>
                            ))}
                        </Select>
                    </FormControl>
                  
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={handleSubmit}>
                    Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AssignCoachToPlayer;


'use client'
import React from 'react'

import { useState } from 'react'
import {
  Progress,
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  GridItem,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  InputLeftAddon,
  InputGroup,
  Textarea,
  FormHelperText,
  InputRightElement,
  Stack,
  useDisclosure,
  Spinner
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import { post, postFormData } from '../../services/apis/api'

const Form1 = ({setName, setGender, setMobile, setEmail, setPassword, setcnfPassword}) => {
    const [show, setShow] = useState(false)
    const handleClick = () => setShow(!show)


    return (
        <>
            <h2 className='text-lg font-semibold text-[#000]'>
                Enter Your Details 
            </h2>
            <span className='text-red-500 text-sm'>( You are about to create a new organization and designate yourself as the Super Admin.)</span>
            <Flex>
                <FormControl mr="3%">
                <FormLabel htmlFor="full-name" fontWeight={'normal'}>
                    Full Name<span className='text-red-500'>*</span>
                </FormLabel>
                <Input id="full-name" onChange={(e)=>{setName(e.target.value)}} placeholder="Full Name" />
                </FormControl>

                <FormControl>
                <FormLabel htmlFor="user-name" fontWeight={'normal'}>
                    Gender<span className='text-red-500'>*</span>
                </FormLabel>
                <Select placeholder='Select option'  onChange={(e)=>{setGender(e.target.value)}}>
                    <option value='MALE'>Male</option>
                    <option value='FEMALE'>Female</option>
                    <option value='OTHER'>Other</option>
                </Select>
                </FormControl>
            </Flex>
            <Flex>
                <FormControl mr="3%" mt="2%">
                    <FormLabel htmlFor="email" fontWeight={'normal'}>
                        Mobile<span className='text-red-500'>*</span>
                    </FormLabel>
                    <Input id="nobile" onChange={(e)=>{setMobile(e.target.value)}} type="number" placeholder='+91 8889132167' />
                </FormControl>
                <FormControl mt="2%">
                    <FormLabel htmlFor="email" fontWeight={'normal'}>
                        Email address<span className='text-red-500'>*</span>
                    </FormLabel>
                    <Input id="email" onChange={(e)=>{setEmail(e.target.value)}} type="email" placeholder='abc@mail.com'/>
                </FormControl>
            </Flex>
            <Flex mt={'2%'}>
                <FormControl mr="3%" >
                    <FormLabel htmlFor="password" fontWeight={'normal'} mt="2%">
                    Password<span className='text-red-500'>*</span>
                    </FormLabel>
                    <InputGroup size="md">
                    <Input
                        onChange={(e)=>{setPassword(e.target.value)}}
                        pr="4.5rem"
                        type={show ? 'text' : 'password'}
                        placeholder="Enter password"
                    />
                    <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                        {show ? 'Hide' : 'Show'}
                        </Button>
                    </InputRightElement>
                    </InputGroup>
                </FormControl>

                <FormControl>
                    <FormLabel htmlFor="password" fontWeight={'normal'} mt="2%">
                       Confirm Password
                    </FormLabel>
                    <InputGroup size="md">
                    <Input
                        onChange={(e)=>{setcnfPassword(e.target.value)}}
                        pr="4.5rem"
                        type={show ? 'text' : 'password'}
                        placeholder="Enter confirm password"
                    />
                    <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                        {show ? 'Hide' : 'Show'}
                        </Button>
                    </InputRightElement>
                    </InputGroup>
                </FormControl>
            </Flex>
        
        </>
    )
}

const Form2 = ({setOrgName, setMission, setVision, setDescription, setFounder, setFoundedOn, setLocation, setRegistrationNumber}) => {
    const [show, setShow] = useState(false)
    const handleClick = () => setShow(!show)

    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <h2 className='text-lg font-semibold text-[#000]'>
                Club Details
            </h2>
            <Stack>
            <Flex>
                    <FormControl mr="3%">
                    <FormLabel htmlFor="full-name" fontWeight={'normal'}>
                        Organization Name<span className='text-red-500'>*</span>
                    </FormLabel>
                    <Input id="full-name" onChange={(e)=>{setOrgName(e.target.value)}} placeholder="E.g. Al-Ibtisam" />
                    </FormControl>

                    <FormControl>
                    <FormLabel htmlFor="user-name" fontWeight={'normal'}>
                        Registration Number<span className='text-red-500'>*</span>
                    </FormLabel>
                    <Input id="user-name" onChange={(e)=>{setRegistrationNumber(e.target.value)}} placeholder="E.g. SD45D5CAACA" />
                    </FormControl>
                </Flex>
                <Flex>
                    <FormControl mr="3%" mt="2%">
                        <FormLabel htmlFor="email" fontWeight={'normal'}>
                            Founder<span className='text-red-500'>*</span>
                        </FormLabel>
                        <Input id="text" type="text" onChange={(e)=>{setFounder(e.target.value)}} placeholder='E.g. Sanjay'/>
                    </FormControl>
                    <FormControl mt="2%">
                        <FormLabel htmlFor="email" fontWeight={'normal'}>
                            Foundation Date<span className='text-red-500'>*</span>
                        </FormLabel>
                        <Input placeholder='Select Date and Time' onChange={(e)=>{setFoundedOn(e.target.value)}} size='md' type='date' />
                    </FormControl>
                </Flex>
                <Flex>
                    <FormControl mr="3%" mt="2%">
                        <FormLabel htmlFor="title" fontWeight={'normal'}>
                            Address<span className='text-red-500'>*</span>
                        </FormLabel>
                        <Input id="title" type="text" onChange={(e)=>{setLocation(e.target.value)}} placeholder='E.g. Bhopal, India' />
                    </FormControl>
                    <FormControl mr="3%" mt="2%">
                        <FormLabel htmlFor="title" fontWeight={'normal'}>
                            Description<span className='text-red-500'>*</span>
                        </FormLabel>
                        <Input id="title" type="text" onChange={(e)=>{setDescription(e.target.value)}} placeholder='E.g. best sports organization among all' />
                    </FormControl>
                
                </Flex>
                <Flex>
                    <FormControl mr="3%" mt="2%">
                        <FormLabel htmlFor="title" fontWeight={'normal'}>
                            Mission<span className='text-red-500'>*</span>
                        </FormLabel>
                        <Input id="title" onChange={(e)=>{setMission(e.target.value)}} type="text" placeholder='E.g. best in sports' />
                    </FormControl>
                    <FormControl mr="3%" mt="2%">
                        <FormLabel htmlFor="title" fontWeight={'normal'}>
                            Vision<span className='text-red-500'>*</span>
                        </FormLabel>
                        <Input id="title" type="text" onChange={(e)=>{setVision(e.target.value)}} placeholder='E.g. best sports organization among all' />
                    </FormControl>
                
                </Flex>

                
            </Stack>
       
        </>
    )
}

const Form3 = ({setLogo, setBanner, imgLink, setImgLink, bannerLink, setBannerLink}) => {
  return (
    <>
        <h2 className='text-lg font-semibold text-[#000]'>
            Upload Files
        </h2>
        <Box mb={8}>
            <div>
                <img className='h-20'  src={imgLink} />
            </div>
            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">Upload Logo</label>
            <input onChange={(e)=>{setLogo(e.target.files[0]); const imageUrl = URL.createObjectURL(e.target.files[0]); setImgLink(imageUrl);}} type="file" name="small-file-input" id="small-file-input" class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-2 file:px-4 " />
        </Box>
        <Box>
            <div>
                <img className='h-20'  src={bannerLink} />
            </div>
            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">Upload Banner</label>
            <input onChange={(e)=>{setBanner(e.target.files[0]); const imageUrl = URL.createObjectURL(e.target.files[0]); setBannerLink(imageUrl); }} type="file" name="small-file-input" id="small-file-input" class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-2 file:px-4 " />
        </Box>

    </>
  )
}

const CreateOrgForm = ({onClose}) => {
    const toast = useToast()
    const [step, setStep] = useState(1)
    const [progress, setProgress] = useState(33.33);

    const [name, setName] = useState('');
    const [gender, setGender] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [cnfPassword, setcnfPassword] = useState('');

    const [orgName, setOrgName] = useState('');
    const [description, setDescription] = useState('');
    const [founder, setFounder] = useState('');
    const [foundedOn, setFoundedOn] = useState('');
    const [mission, setMission] = useState('');
    const [vision, setVision] = useState('');
    const [location, setLocation] = useState('');
    const [registrationNumber, setRegistrationNumber] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [banner, setBanner] = useState();
    const [logo, setLogo] = useState();

    const [imgLink, setImgLink] = useState("");
    const [bannerLink, setBannerLink] = useState("");
    

    const createOrgnization = async()=>{
        try {   
            setIsLoading(true);
            let formData = new FormData();
            formData.append('name', orgName);
            formData.append('foundedOn', foundedOn);
            formData.append('founder', founder);
            formData.append('location', location);
            formData.append('mission', mission);
            formData.append('vision', vision);
            formData.append('description', description);
            formData.append('registrationNumber', registrationNumber);
            formData.append('superAdminId', '65ffc6a186fae60ee697b5fe');
            formData.append('logo', logo )
            formData.append('banner', banner)
    
            const res = await postFormData('organization/add', formData);
            console.log(res);
            if(res.statusCode === 201){
                setIsLoading(false);
                toast({
                    title: 'Successfull...!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
                onClose();
            }
        } catch (error) {
            console.log(error);
            toast({
                title: 'Opps...!',
                description: error?.response?.data?.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }

    }

    const createUser = async()=>{
        try {
            setIsLoading(true);
            const body = {
                name, gender, mobile, email, password, role: "SUPER-ADMIN" 
            }
            if(password !== cnfPassword){
                toast({
                    title: 'Opps...!',
                    description: "Password and confirm Password is not same",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            }else{
                console.log("running....!");
                const res = await post('user/register', body);
                console.log(res);
                if(res.statusCode === 200){
                    sessionStorage.setItem('token',JSON.stringify((res.data.token)));
                    toast({
                        title: 'Successfull...!',
                        description: res.data.message,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    })
                    setStep(step+1);
                    setProgress(progress + 33.33)
                    setIsLoading(false)
                }
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false)
            toast({
                title: 'Opps...!',
                description: error?.response?.data?.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
        
    }
    
    return (
        <>
            <Box
            borderWidth="1px"
            rounded="lg"
            // shadow="1px 1px 3px rgba(0,0,0,0.3)"
            maxWidth={800}
            p={6}
            m="10px auto"
            as="form">
            <Progress hasStripe value={progress} mb="5%" mx="5%" isAnimated></Progress>
            {
                step === 1 ? 
                <Form1 setName={setName} setGender={setGender} setMobile={setMobile} setEmail={setEmail} setPassword={setPassword} setcnfPassword={setcnfPassword} /> 
                    : step === 2 ? 
                        <Form2 setOrgName={setOrgName} setDescription={setDescription} setFounder={setFounder} setFoundedOn={setFoundedOn} setMission={setMission} setVision={setVision} setLocation={setLocation} setRegistrationNumber={setRegistrationNumber} /> 
                            : <Form3 setBanner={setBanner} setLogo={setLogo} imgLink={imgLink} setImgLink={setImgLink} bannerLink={bannerLink} setBannerLink={setBannerLink} />
            }
            <ButtonGroup mt="5%" w="100%">
                <Flex w="100%" justifyContent="space-between">
                <Flex>
                    <Button
                        onClick={() => {
                            setStep(step - 1)
                            setProgress(progress - 33.33)
                        }}
                        isDisabled={step === 1}
                        colorScheme="teal"
                        variant="solid"
                        w="7rem"
                        mr="5%"
                    >
                        Back
                    </Button>
                    {
                        step === 3 ? "" : 
                            <Button
                                w="7rem"
                                isDisabled={step === 3}
                                onClick={() => {
                                    if(step=== 1){
                                        createUser();
                                    }else{
                                        setStep(step + 1)
                                        if (step === 3) {
                                            setProgress(100)
                                        } else {
                                            setProgress(progress + 33.33)
                                        }
                                    }
                                }}
                                colorScheme="teal"
                                variant="outline"
                            >
                            {
                                isLoading ? <Spinner /> : "Next"
                            }
                            </Button>
                    }
                </Flex>
                {step === 3 ? (
                    <Button
                    w="7rem"
                    colorScheme="red"
                    variant="solid"
                    onClick={() => {
                        createOrgnization()
                    }}>
                    {
                        isLoading ? <Spinner /> : "Submit"
                    }
                    </Button>
                ) : null}
                </Flex>
            </ButtonGroup>
            </Box>
        </>
    )
}

export default CreateOrgForm



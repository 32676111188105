import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    Input,
    FormLabel,
    Button,
    Select,
    useToast,
    Textarea
} from '@chakra-ui/react'
import { set } from 'lodash';

import React from 'react'
import { BiImage } from 'react-icons/bi'
import { postFormData } from '../../services/apis/api';
import { useTranslation } from 'react-i18next';
import SelectTransactionStateComponent from './SelectTransactionStateComponent';

const _methodType = [
    'cash',
    'online',
    'check'
]

function AddAditionalIncome({ isOpen, heading, onClose, onReset, formData, onHandleChange, isLoading, onHandleSubmit }) {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';


    const direction = isArabic ? 'rtl' : 'ltr';
    const textAlign = isArabic ? 'right' : 'left';
    return (
        <>
            {/* <Button >Open Modal</Button> */}
            <Modal
                // initialFocusRef={initialRef}
                // finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign={isArabic ? 'right' : 'left'} dir={isArabic ? 'rtl' : 'ltr'}>{t(heading)}</ModalHeader>
                    <ModalCloseButton
                        style={{
                            [isArabic ? 'left' : 'right']: '10px' // Conditional positioning based on language
                        }}
                    />
                    <ModalBody>
                        {heading?.includes("expense") ?
                            <SelectTransactionStateComponent
                                selectedStatus={formData?.paymentState}
                                onSelelectState={(value) => {
                                    onHandleChange('paymentState', value); // Use the value directly
                                }}
                            />
                            : null}
                        <FormControl mt={4}>
                            <FormLabel textAlign={textAlign}>{t('amount')} <span className='text-red-600'>*</span></FormLabel>
                            <Input
                                value={formData?.amount}
                                type='number'
                                textAlign={textAlign}
                                onChange={(e) => {
                                    onHandleChange('amount', e.target.value)
                                }}
                                placeholder={t('amount')}
                            />
                        </FormControl>
                        {formData?.paymentState == 'PAID' ?
                            <>
                                <FormControl mt={4}>
                                    <FormLabel textAlign={textAlign}>{t('date')} <span className='text-red-600'>*</span></FormLabel>
                                    <Input
                                        type='date'
                                        value={formData?.entryDate}
                                        onChange={(e) => {
                                            const selectedDate = new Date(e.target.value);
                                            const today = new Date();
                                            // Check if the selected date is greater than today
                                            if (selectedDate <= today) {
                                                onHandleChange('entryDate', e.target.value);
                                            } else {
                                                alert("The selected date cannot be greater than today.");
                                            }
                                        }}
                                        placeholder={t('date')}
                                    />
                                </FormControl>

                                <FormControl mt={4}>
                                    <FormLabel textAlign={textAlign}>{t('select-payment-method')}<span className='text-red-600'>*</span></FormLabel>
                                    <Select
                                        placeholder={t('select-payment-method')}
                                        textAlign={isArabic ? 'right' : 'left'}
                                        bg="#f9fafb"
                                        borderColor="gray.300"
                                        value={formData?.paymentMethod}
                                        onChange={(e) => {
                                            onHandleChange('paymentMethod', e?.target?.value); // Call the function with course name
                                        }}
                                    >
                                        {_methodType?.map((method) => (
                                            <option key={method} value={method}>
                                                {t(method)}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>

                                {formData.paymentMethod != 'cash' && (
                                    <FormControl mt={4}>
                                        <FormLabel textAlign={textAlign}>{formData.paymentMethod === 'check' ? t('check-number') : t('transaction-id')}<span className='text-red-600'>*</span></FormLabel>
                                        <Input
                                            type="text"
                                            placeholder={formData.paymentMethod === 'check' ? t('enter-check-number') : t('enter-transaction-id')}
                                            value={formData?.paymentId}
                                            onChange={(e) => onHandleChange('paymentId', e?.target?.value)} // Update paymentId
                                        />
                                    </FormControl>
                                )}

                            </>
                            : null}

                        <FormControl mt={4}>
                            <FormLabel textAlign={textAlign}>{t('description')}</FormLabel>
                            <Textarea
                                value={formData?.description}
                                textAlign={textAlign}
                                onChange={(e) => {
                                    onHandleChange('description', e?.target?.value)
                                }}
                                placeholder={t('description')}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={4}
                            onClick={onReset}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={onHandleSubmit}
                            colorScheme='blue'
                            isLoading={isLoading}
                            isDisabled={
                                formData?.paymentState === 'PAID'
                                    ? (
                                        // !formData?.entryDate || // Entry date is mandatory
                                        !formData?.amount ||    // Amount is mandatory
                                        (formData?.paymentMethod !== 'cash' && !formData?.paymentId) // If payment method is not cash, paymentId is mandatory
                                    )
                                    : !formData?.amount
                            }
                        >
                            {t('add')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    )
}

export default AddAditionalIncome
import { Button } from '@chakra-ui/react';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { formatDateArEn, getStatusColor } from '../../services/glocalFunctions';
import { FaCheckCircle, FaRegEdit } from 'react-icons/fa';

const PaymentDetail = ({
    amount,
    status,
    dueDate,
    paymentNote,
    onEditPayment,
    onConfirmComplete
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return (
        <>
            <div className={`flex w-full bg-gray-50 p-4 rounded-xl  flex-col items-start justify-between`}>
                <div className={`flex w-full items-start justify-between`}>
                    {/* APPOINTMENT PAYMENT AMOUNT  */}
                    <div className='flex items-center gap-2'>
                        <span className={`text-sm lg:text-base font-semibold text-gray-900`}>
                            {t('requested-amount')}
                        </span>
                        :
                        {amount ?
                            <span className={`text-sm lg:text-base font-semibold text-gray-900`}>
                                {amount}
                            </span>
                            : null}
                    </div>
                    <div className={`flex items-center gap-2`}>
                        {/* APPOINTMENT PAYMENT STATUS  */}
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(status)}`}>
                            {t(status?.toLowerCase()) || '--'}
                        </span>
                        {status != 'COMPLETED' &&
                            <>
                                {/* EDIT PAYMENT DETAIL BUTTON  */}
                                <Button
                                    colorScheme="gray"
                                    aria-label="Reject"
                                    size="sm"
                                    leftIcon={<FaRegEdit />}
                                    onClick={() => {
                                        onEditPayment();
                                    }}
                                >
                                    {t('edit-payment')}
                                </Button>
                                {/* COMPLETE PAYMENT BUTTON  */}
                                <Button
                                    colorScheme="green"
                                    aria-label="Reject"
                                    onClick={() => {
                                        onConfirmComplete();
                                    }}
                                    size="sm"
                                    leftIcon={<FaCheckCircle />}
                                >
                                    {t('complete-payment')}
                                </Button>
                            </>
                        }
                    </div>
                </div>
                {/* APPOINTMENT PAYMENT DUE DATE  */}
                <div className='flex items-center gap-2'>
                    <span className={`text-sm lg:text-sm font-medium text-gray-500`}>
                        {t('due-date')}
                    </span>
                    :
                    {dueDate ?
                        <span className={`text-sm lg:text-sm font-medium text-gray-500`}>
                            {formatDateArEn(dueDate, isArabic) || '--'}
                        </span>
                        : null}
                </div>
                {/* APPOINTMENT PAYMENT NOTE  */}
                <div className='flex items-center gap-2'>
                    <span className={`text-sm lg:text-sm font-medium text-gray-500`}>
                        {t('payment-note')}
                    </span>
                    :
                    {paymentNote ?
                        <span className={`text-sm lg:text-sm font-medium text-gray-500`}>
                            {paymentNote || '--'}
                        </span>
                        : null}
                </div>
            </div>
        </>
    )
}

export default PaymentDetail

import React, { useEffect, useState } from 'react'
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { get, patch } from '../../services/apis/api';
import CreateEvent from '../../components/dialogueBoxes/CreateEvent'
import { formatDateTime } from '../../utills/formateDate';
import ViewEvent from '../../components/mediaCenter/ViewEvent';
import CreateTournament from '../../components/dialogueBoxes/CreateTournament';

const Tournament = () => {
  const [tournaments, setTournaments] = useState([]);
  const [viewEvent, setViewEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  const getAllTournament = async () => {
    const res = await get('tournament/all');
    console.log(res);
    setTournaments(res.data.tournaments);
  }

  useEffect(() => {
    getAllTournament();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  }

  const filteredTournaments = tournaments.filter(tournament => 
    tournament.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div className='flex justify-between px-2 items-center mb-4'>
        <div>
          <div className="relative">
            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
              </svg>
            </div>
            <input
              type="text"
              id="table-search-users"
              className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
              placeholder="Search for tournaments"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div>
          <CreateTournament getAllTournament={getAllTournament} />
        </div>
      </div>
      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                <th scope="col" className="p-4">
                  <div className="flex items-center">
                    S.No.
                  </div>
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Matches
                </th>
                <th scope="col" className="px-6 py-3">
                  Start Date
                </th>
                <th scope="col" className="px-6 py-3">
                  End Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Action Required
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredTournaments.map((tournament, index) => (
                <tr className="bg-white border-b  hover:bg-gray-50 " key={tournament._id}>
                  <td className="w-4 p-4">
                    <div className="flex items-center">
                      {index + 1}
                    </div>
                  </td>
                  <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                    <div className="text-base font-semibold">{tournament?.name}</div>
                  </th>
                  <td className="px-6 py-4">
                    {tournament?.status}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      {tournament?.matches}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      {formatDateTime(tournament.startDate, 'date')}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      {formatDateTime(tournament.endDate, 'date')}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <button className='text-[#ffffff] bg-[#290dbd] font-semibold py-2 px-4 rounded-md'>Edit</button>
                      <button className='text-[#ffffff] bg-red-500 ml-2 font-semibold py-2 px-4 rounded-md'>Delete</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Tournament

export function getAssetConditions(startCondition) {
    // Full array of asset conditions, add more if needed
    const conditions = ['excellent', 'good', 'fair', 'poor', 'bad'];
    
    const startIndex = conditions.indexOf(startCondition.toLowerCase());
    
    if (startIndex !== -1) {
      return conditions.slice(startIndex);
    }
    return [];
}
  
import React from 'react'
import { Routes, Route } from "react-router-dom";
import CommanNav from '../components/globles/CommanNav';
import Home from '../pages/operations/Home';
import Appointments from '../pages/clinic/Appointments';
import Requests from '../pages/clinic/Requests';
import RequestDetails from '../pages/clinic/RequestDetails';

const Clinic = () => {
  return (
    <Routes>
      <Route path="/clinic" element={<CommanNav />}>
        <Route index element={<Home />} />
        <Route path="appointments" element={<Appointments />} />
        <Route path="requests" element={<Requests />} />
        <Route path="request/:id" element={<RequestDetails />} />
        <Route path="appointment/:id" element={<RequestDetails />} />
      </Route>
    </Routes>
  )
}

export default Clinic
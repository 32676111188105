import React from 'react';
import { Select as ReactSelect } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';

const SelectYearComponent = ({
    selectedYear,
    setSelectedYear,
}) => {
    const { t, i18n } = useTranslation();
    const currentYear = new Date().getFullYear(); // Get the current year
    const years = Array.from({ length: currentYear - 2000 + 1 }, (_, i) => 2000 + i); // Generate years from 2000 to current year

    // Map years into options format for chakra-react-select
    const yearOptions = years.map((year) => ({
        value: year,
        label: year.toString(),
    }));

    return (
        <>
            <ReactSelect
                name='select-year'
                placeholder={t('select-year')}
                value={yearOptions.find((option) => option.value === selectedYear)} // Match selected value
                onChange={(option) => setSelectedYear(option?.value || currentYear)}  // Handle option change
                options={yearOptions} // Pass the year options
                isClearable // Adds a clear button to the select
                chakraStyles={{
                    container: (provided) => ({
                        ...provided,
                        minWidth: '200px', // Set minimum width
                    }),
                }}
            />
        </>
    );
};

export default SelectYearComponent;

import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Avatar,
    Select,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    useToast,
    Spinner,
    Checkbox,
    IconButton,
    Tooltip,
    FormControl,
    FormLabel,
    InputRightElement
} from '@chakra-ui/react';
import { DownloadIcon, SearchIcon } from '@chakra-ui/icons';
import { get } from '../../services/apis/api';
import PayslipModal from '../../components/hr/dialogBoxes/PayslipModal';
import { BsDownload } from "react-icons/bs";
import { FaRegCheckCircle } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import PayrollFilters from '../../components/hr/components/filters/PayrollFilters';
import BreadCrumbs from '../../components/BreadCrumbs';
import { debounce, filter } from 'lodash';
import { IoMdTime } from "react-icons/io";
import ConfirmDownload from '../../components/dialogueBoxes/ConfirmDownload';

// Initialize _filterData with default values
const _filterData = {
    attendanceDate: {
        month: 0,
        year: 0,
        startDate: ''
    },
    selectedDepartment: 'ALL',
    payrollStatus: '',
    paymentStatus: 'ALL',
    search: ''
};

// Compute initial attendanceDate dynamically
const computeInitialAttendanceDate = () => {
    const currentAttendanceDate = new Date();
    const previousMonth = currentAttendanceDate.getMonth(); // Zero-based
    const previousYear = previousMonth === 0
        ? currentAttendanceDate.getFullYear() - 1 // Move to the previous year if January
        : currentAttendanceDate.getFullYear();

    return {
        month: previousMonth === 0 ? 12 : previousMonth, // Set to December if it's January
        year: previousYear,
        startDate: new Date(
            previousMonth === 0 ? previousYear : currentAttendanceDate.getFullYear(), // Adjust year for January
            previousMonth === 0 ? 11 : previousMonth - 1, // Adjust month (Dec for Jan)
            1 // First day of the previous month
        ),
    };
};

// TABLE COLUMNS 
const allColumns = [
    'employee',
    'employee-code',
    'department',
    'designation',
    'status',
    'actions'
]

const Payroll = () => {
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [data, setData] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    // Initialize filterData with the computed attendanceDate
    const [filterData, setFilterData] = useState({
        ..._filterData,
        attendanceDate: computeInitialAttendanceDate()
    });
    const [appliedFilterCount, setAppliedFilterCount] = useState(0);
    const [totalEmployees, setTotalEmployees] = useState(0);
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [allDepartments, setAllDepartments] = useState([])
    const [downloadOpen, setDownloadOpen] = useState(false);  // for confirmation dialog

    // SELECT ROWS LOGIC 
    const [selectedRows, setSelectedRows] = useState([]);

    // Handle select all
    const handleSelectAll = (e) => {
        try {
            if (e.target.checked) {
                // Select rows where row.payroll is null
                setSelectedRows(data?.filter((row) => row.payroll === null).map((row) => row._id));
            } else {
                // Deselect all rows
                setSelectedRows([]);
            }
        } catch (e) {
            console.log(`ERROR WHILE SELECTING ALL ROWS ${e}`);
        }
    };

    // Handle row selection
    const handleRowSelect = (e, id) => {
        try {
            if (e.target.checked) {
                setSelectedRows((prev) => [...prev, id]);
            } else {
                setSelectedRows((prev) => prev.filter((rowId) => rowId !== id));
            }
        } catch (e) {
            console.log(`ERROR WHILE SELECTING  ROW ${e}`);
        }
    };

    // Check if all rows with null payroll are selected
    const isAllSelected = selectedRows.length === data?.filter((row) => row?.payroll === null)?.length && data?.length > 0;
    // SELECT ROWS LOGIC 

    // GET THE ALL APPLIED FILTER COUNT 
    const checkAppliedFilterCount = (filterData) => {
        try {
            console.log(filterData, 'filter data');

            // Initialize filtercount to 0
            let filtercount = 0;

            // Increment count for each applied filter
            if (filterData?.search) {
                filtercount++;
            }
            if (filterData?.attendanceDate?.startDate) {
                filtercount++;
            }
            if (filterData?.paymentStatus && filterData?.paymentStatus.toLocaleLowerCase() !== 'all') {
                filtercount++;
            }
            if (filterData?.payrollStatus && filterData?.payrollStatus.toLocaleLowerCase() !== 'all') {
                filtercount++;
            }
            if (filterData?.selectedDepartment && filterData?.selectedDepartment.toLocaleLowerCase() !== 'all') {
                filtercount++;
            }

            return filtercount; // Return the total count of applied filters
        } catch (error) {
            console.error("Error calculating applied filter count:", error);
            return 0; // Return a default value in case of an error
        }
    };

    // GET ALL DEPARTMENTS FOR DROP DOWN 
    const getAllDepartments = async () => {
        try {
            const res = await get('department/all');
            if (res.statusCode === 200) {
                setAllDepartments(res?.data?.department);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // FETCH EMPLOYEES DATA WITH PREVIOUS MONTH PAYROLL
    const getEmployeesWithPayroll = async (_filterData, skip, limit,) => {
        try {
            const filtercount = checkAppliedFilterCount(_filterData);
            setAppliedFilterCount(filtercount)

            // Construct the query parameters
            let queryParams = `name=${_filterData?.search}&payrollMonth=${_filterData?.attendanceDate?.month}&payrollYear=${_filterData?.attendanceDate?.year}&status=${_filterData?.payrollStatus}&department=${_filterData?.selectedDepartment}&paymentStatus=${_filterData?.paymentStatus}`;

            // Add skip and limit to the query string if they are available
            if (skip && limit) {
                queryParams += `&page=${skip}&limit=${limit}`;
            }

            // Make the GET request with the query parameters
            const res = await get(`payroll/employee-with-payroll?${queryParams}`);
            // const res = await get(`payroll/employee-with-payroll?page=${skip}&limit=${limit}&name=${_filterData?.search}&payrollMonth=${_filterData?.attendanceDate?.month}&payrollYear=${_filterData?.attendanceDate?.year}&status=${_filterData?.payrollStatus}&department=${_filterData?.selectedDepartment}&paymentStatus=${_filterData?.paymentStatus}`);
            if (res?.statusCode == 200) {
                setData(res?.data?.data);
                setTotalPages(res?.data?.pages);
                setTotalEmployees(res.data.total);
            } else {
                setData([]);
            }
        } catch (error) {
            setData([]);
            console.log(`ERROR WHILE FETCHING EMPLOYEES WITH PAYROLL ${error}`);
        }
    };

    useEffect(() => {
        getAllDepartments();
        getEmployeesWithPayroll(filterData, 1, 10);
    }, []);
    // FETCH EMPLOYEES DATA WITH PREVIOUS MONTH PAYROLL

    // GENERATE EMPLOYEE PAYROLLS BY EMPLOEES IDS 
    const generatePayrollsByEmpId = async (employeeIds) => {
        try {
            setLoading(true);
            const stringEmployeeIds = JSON.stringify(employeeIds)
            const res = await get(`payroll/generate?date=${filterData?.attendanceDate?.startDate}&employeeIds=${stringEmployeeIds}`)
            if (res.statusCode === 200) {
                setLoading(false);
                toast({
                    title: 'Success',
                    description: res?.data?.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                getEmployeesWithPayroll(filterData, 1, 10);
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: 'Error',
                description: error?.response?.data?.message || 'Failed to generate Payroll',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    }

    // EXPORT EMPLOYEE PAYROLL AS CSV 
    const downloadEmployeePayrollCSV = async (_filterData) => {
        setLoading(true);
        try {
            // Construct the query parameters
            let queryParams = `payrollMonth=${_filterData?.attendanceDate?.month}&payrollYear=${_filterData?.attendanceDate?.year}&status=${_filterData?.payrollStatus}&department=${_filterData?.selectedDepartment}&paymentStatus=${_filterData?.paymentStatus}`;

            // Make the GET request with the query parameters
            const res = await get(`payroll/employee-with-payroll?${queryParams}`);
            setLoading(false);
            if (res?.statusCode == 200) {
                const payrollData = res?.data?.data
                //filename
                const filename = `employee-payroll-${filterData?.attendanceDate?.month}-${filterData?.attendanceDate?.year}.csv`;

                // Check if there are payslips to export
                if (!payrollData || payrollData?.length === 0) {
                    console.error("No payrolls to export.");
                    return;
                }

                // Generate headers from the first payslip object keys
                const headers = [
                    "Employee Name",
                    "Employee Code",
                    "Payroll Month",
                    "Salary Breakdown",
                    "Deductions",
                    "Paid Leaves",
                    "Unpaid Leaves",
                    "Paid Days",
                    "Payable Days",
                    "Final Net Pay",
                    "Payment Status",
                ];

                // Convert payslip data into CSV rows
                const rows = payrollData?.map((employee) => {
                    const payslip = employee?.payroll
                    const salaryBreakdown = JSON?.stringify(payslip?.salaryBreakdown || {});
                    const deductions = JSON?.stringify(payslip?.deductions || {});

                    return [
                        employee?.name || employee?.userName || "N/A",
                        employee?.employeeCode || "N/A",
                        new Date(payslip?.payrollMonth).toLocaleDateString() || "N/A",
                        salaryBreakdown,
                        deductions,
                        payslip?.paidLeaves || 0,
                        payslip?.unpaidLeaves || 0,
                        payslip?.paidDays || 0,
                        payslip?.payableDays || 0,
                        payslip?.finalNetPay?.toFixed(2) || 0,
                        payslip?.paymentStatus || "N/A",
                    ]?.join(",");
                });

                // Combine headers and rows
                const csvContent = [headers?.join(","), ...rows]?.join("\n");

                // Create Blob and trigger download
                const blob = new Blob([csvContent], { type: "text/csv" });
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                setLoading(false);
                toast({
                    title: 'Success',
                    description: 'CSV Downloaded Successfully!',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (e) {
            console.log(e, 'erroridcsv');

            setLoading(false);
            toast({
                title: 'Success',
                description: 'Error While Downloading CSV!',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            console.log(`ERROR WHILE DOWNLOADING ALL GENERATED PAYROLL AS CSV ${e}`);
        }
    };

    // Debounced version of the getEmployeesWithPayroll function
    const debouncedGetAllEmployeesPayroll = useCallback(
        debounce((_filterData, skip, limit) => {
            getEmployeesWithPayroll(_filterData, skip, limit);
        }, 300), // Adjust debounce delay as needed
    );

    // Confirm download
    const confirmDownload = () => {
        try {
            downloadEmployeePayrollCSV(filterData);
            setDownloadOpen(false);
        } catch (e) {
            console.log(`ERROR WHILE GENERATING PDF ${e}`);
        }
    };

    return (
        <Box width="100%" p={4}>
            <BreadCrumbs />
            <Flex justify="space-between" align="center" mb={4}>
                <Box fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
                    {t('payroll')} {totalEmployees > 0 ? `(${totalEmployees})` : ''}
                </Box>
                <div className='flex items-center gap-2 lg:gap-4'>
                    {/* SEARCH FIELD */}
                    <InputGroup mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "auto" }}>
                        <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder={t('search')}
                            value={filterData?.search}
                            onChange={(e) => {
                                const value = e?.target?.value
                                const _searchPayload = {
                                    ...filterData,
                                    search: value
                                }
                                setFilterData(_searchPayload)
                                debouncedGetAllEmployeesPayroll(_searchPayload, 1, 10);
                                setPage(1); // Reset to the first page when searching
                            }}
                        />
                        {filterData?.search &&
                            <InputRightElement
                                onClick={() => {
                                    const _searchPayload = {
                                        ...filterData,
                                        search: ""
                                    }
                                    setFilterData(_searchPayload)
                                    debouncedGetAllEmployeesPayroll(_searchPayload, 1, 10);
                                    setPage(1); // Reset to the first page when searching
                                }}
                            >
                                <MdOutlineCancel color="gray.300" />
                            </InputRightElement>
                        }
                    </InputGroup>

                    {/* DOWNLOAD CSV BUTTTON  */}
                    {/* <Tooltip
                        label={t('download-csv')}
                    >
                        <IconButton
                            onClick={() => {
                                downloadEmployeePayrollCSV(filterData);
                            }}
                            isLoading={loading}
                        >
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip> */}
                    {/* Download Confirmation Dialog */}
                    <ConfirmDownload
                        isOpen={downloadOpen}
                        label='download-csv'
                        setIsOpen={setDownloadOpen}
                        handleDownload={() => {
                            setDownloadOpen(true);
                        }}
                        confirmDownload={() => {
                            confirmDownload();
                        }}
                        closeDialog={() => {
                            setDownloadOpen(false);
                        }}
                        loading={loading}
                    />
                    {/* PAYROLL FILTERS  */}
                    <PayrollFilters
                        filterData={filterData}
                        setFilterData={setFilterData}
                        allDepartments={allDepartments}
                        appliedFilterCount={appliedFilterCount}
                        onApplyFilter={() => {
                            getEmployeesWithPayroll(filterData, page, 10);
                        }}
                        onResetFilter={() => {
                            const _payload = {
                                ..._filterData,
                                attendanceDate: computeInitialAttendanceDate(),
                                search: filterData?.search
                            }
                            setFilterData(_payload)
                            setPage(1);
                            getEmployeesWithPayroll(_payload, 1, 10,);
                        }}
                    />
                </div>
            </Flex>
            {/* Bulk Actions */}
            {
                selectedRows?.length > 0 && (
                    <div className="bg-blue-50 p-4 rounded-lg mb-4 flex items-center justify-between">
                        <span className="text-blue-700">
                            {selectedRows?.length} employees selected
                        </span>
                        <Button
                            leftIcon={<BsDownload />}
                            colorScheme='blue'
                            size="sm"
                            onClick={() => {
                                generatePayrollsByEmpId(selectedRows)
                            }}
                            isDisabled={selectedRows?.length === 0}
                            isLoading={loading}
                        >
                            Generate Bulk Payroll
                        </Button>
                    </div>
                )
            }

            <div className=" overflow-x-auto shadow-md sm:rounded-lg">
                <Table dir={isArabic ? 'rtl' : 'ltr'} className="w-full text-sm text-left text-gray-500">
                    <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <Tr>
                            <Th>
                                <Checkbox
                                    isChecked={isAllSelected}
                                    onChange={handleSelectAll}
                                />
                            </Th>
                            {allColumns.map((column, index) => (
                                <Th key={index}>{t(column)}</Th>
                            ))}
                            {filterData?.paymentStatus !== 'ALL' && <Th>{t('payment-status')}</Th>}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {dataLoading ? (
                            <Tr>
                                <Td colSpan="9" textAlign="center">Loading...</Td>
                            </Tr>
                        ) : (
                            data?.map((row) => (
                                <Tr key={row?._id}>
                                    <Td>
                                        {row?.payroll ? <span className="text-gray-400">•</span> :
                                            <Checkbox
                                                isChecked={selectedRows.includes(row._id) || row?.payroll}
                                                onChange={(e) => {
                                                    if (row?.payroll) {
                                                        toast({
                                                            title: 'Info',
                                                            description: `Payroll Already generated for Employee ${row?.name || row?.userName}`,
                                                            status: 'info',
                                                            duration: 5000,
                                                            isClosable: true,
                                                        });
                                                    } else {
                                                        handleRowSelect(e, row._id);
                                                    }
                                                }}
                                            />
                                        }
                                    </Td>
                                    <Td>{row?.name}</Td>
                                    <Td>{row?.employeeCode || 'N/A'}</Td>
                                    <Td>{t(row?.department?.toLocaleLowerCase()) || 'N/A'}</Td>
                                    <Td>{t(row?.designation?.toLocaleLowerCase()) || 'N/A'}</Td>
                                    <Td>
                                        <span
                                            className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium
                                        ${row?.payroll
                                                    ? 'bg-green-100 text-green-800'
                                                    : 'bg-yellow-100 text-yellow-800'
                                                }`
                                            }
                                        >
                                            {row?.payroll ? (
                                                <FaRegCheckCircle className="mr-1" size={14} />
                                            ) : (
                                                <IoMdTime className="mr-1" size={14} />
                                            )}
                                            {row?.payroll ? t('generated') : t('pending')}
                                        </span>
                                    </Td>
                                    {filterData?.paymentStatus != 'ALL' ?
                                        <Td>
                                            {(() => {
                                                const payrollPaymentStatus = row?.payroll?.paymentStatus?.toLowerCase() || null;

                                                const statusClasses = !payrollPaymentStatus
                                                    ? 'bg-gray-100 text-gray-800'
                                                    : payrollPaymentStatus === 'approved'
                                                        ? 'bg-green-100 text-green-800'
                                                        : payrollPaymentStatus === 'rejected'
                                                            ? 'bg-red-100 text-red-800'
                                                            : 'bg-yellow-100 text-yellow-800';

                                                const StatusIcon = payrollPaymentStatus === 'approved'
                                                    ? FaRegCheckCircle
                                                    : payrollPaymentStatus === 'rejected'
                                                        ? MdOutlineCancel
                                                        : null;

                                                return (
                                                    <span
                                                        className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium ${statusClasses}`}
                                                    >
                                                        {StatusIcon ? (
                                                            <StatusIcon className="mr-1" size={14} />
                                                        ) : (
                                                            <IoMdTime className="mr-1" size={14} />
                                                        )}
                                                        {payrollPaymentStatus || 'N/A'}
                                                    </span>
                                                );
                                            })()}
                                        </Td>
                                        : null}
                                    <Td>

                                        {row?.payroll ?
                                            <PayslipModal
                                                payslip={row?.payroll}
                                                employeeName={row?.name}
                                                employeeCode={row?.employeeCode}
                                            />
                                            :
                                            <Button
                                                leftIcon={<BsDownload />}
                                                colorScheme='blue'
                                                size="sm"
                                                isLoading={loading}
                                                onClick={() => {
                                                    generatePayrollsByEmpId([row?._id])
                                                }}
                                            >
                                                {t('generate')}
                                            </Button>
                                        }
                                    </Td>
                                </Tr>
                            ))
                        )}
                    </Tbody>
                </Table>
                {data ?
                    data?.length <= 0 &&
                    <div className='w-full  min-h-80 flex items-center justify-center'>
                        <h1 className='text-center'>{t('no-payrolls-found')}</h1>
                    </div>
                    :
                    <div className='w-full  min-h-80 flex items-center justify-center'>
                        <h1 className={`text-center `}>
                            {isArabic && '....'} {t('loading')} {!isArabic && '....'}
                        </h1>

                    </div>
                }
            </div>
            {data?.length > 0 &&
                <Flex justify="space-between" mt={4}>
                    <Button
                        onClick={() => {
                            const newPage = Math.max(page - 1, 1); // Precompute the new page number
                            setPage(newPage); // Update the page state
                            getEmployeesWithPayroll(filterData, newPage, 10,); // Fetch the data for the new page
                        }}
                        isDisabled={page === 1}
                    >
                        {t('previous')}
                    </Button>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isArabic ? 'row-reverse' : 'row',
                            gap: 1
                        }}
                    >
                        <h1>{t('page')}</h1>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isArabic ? 'row-reverse' : 'row',
                                gap: 1
                            }}
                        >
                            {page}

                            <h1>{t('of')}</h1>
                            {totalPages}
                        </ Box>
                    </Box>
                    <Button
                        onClick={() => {
                            const newPage = Math.min(page + 1, totalPages); // Precompute the new page number
                            setPage(newPage); // Update the page state
                            getEmployeesWithPayroll(filterData, newPage, 10,); // Fetch the data for the new page
                        }}
                        isDisabled={page === totalPages}
                    >
                        {t('next')}
                    </Button>
                </Flex>
            }
        </Box >
    );
};

export default Payroll;

import React from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator, Button } from '@chakra-ui/react'
import General from '../../components/settings/General'
import Profile from '../../components/settings/Profile'
import Account from '../../components/settings/Account'
import Accessibility from '../../components/settings/Accessibility'
import Notification from '../../components/settings/Notification'
import { UserState } from '../../context/user'
import { useTranslation } from 'react-i18next'


const AllSettings = () => {
    const { user, setUser } = UserState();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    return (
        <div>
            <div className='flex items-center justify-between bg-slate-50 rounded-lg p-4'>
                <div className='flex items-center'>
                    <div className='me-4'>
                        <img className='h-12 w-12 rounded-full' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTO2Zv5-ftMaaOpqJv7YXJjOw-JasAjG9YzZ5RSO7UqbiQkJn-z2MRxAkyoRMMWC7t_S9Q&usqp=CAU' />
                    </div>
                    <div>
                        <h2 className='font-semibold text-lg'>{user.name}</h2>
                        <h4 className='font-normal text-sm '>{user.email}</h4>
                    </div>
                </div>
                <div>
                    <Button colorScheme='blue'>Save Changes</Button>
                </div>
            </div>
            <div className={`mt-2 ${isArabic ? 'rtl' : 'ltr'}`} >
                <Tabs position='relative' variant='unstyled'>
                    <TabList dir={isArabic ? 'rtl' : 'ltr'}>
                        <Tab>{t("general")}</Tab>
                        <Tab>{t("profile")}</Tab>
                        <Tab>{t("account")}</Tab>
                        <Tab>{t("accessibility")}</Tab>
                        <Tab>{t("notification")}</Tab>
                    </TabList>
                    <TabIndicator mt='-1.5px' height='2px' bg='blue.500' borderRadius='1px' />
                    <TabPanels>
                        <TabPanel>
                            <General />
                        </TabPanel>
                        <TabPanel>
                            <Profile />
                        </TabPanel>
                        <TabPanel>
                            <Account />
                        </TabPanel>
                        <TabPanel>
                            <Accessibility />
                        </TabPanel>
                        <TabPanel>
                            <Notification />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>

        </div>
    )
}

export default AllSettings

import React from 'react'
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../services/glocalFunctions';

const ShowTotalValue = ({
    title,
    value
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return (
        <>
            {/* SHOW TOTAL VALUE  */}
            <div className={`flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} items-center gap-2`}>
                <h3 className='text-lg lg:text-xl font-semibold text-gray-500'>
                    {isArabic && ':'} {t(title)} {!isArabic && ':'}
                </h3>
                <h3
                    className={`text-xl lg:text-2xl font-bold ${isArabic ? 'text-right' : 'text-left'}`} // Align the amount based on language
                    dir={isArabic ? 'rtl' : 'ltr'} // Set text direction based on language
                >
                    <span className="text-lg lg:text-lg">
                        {isArabic ? 'ر.س' : 'SAR'}
                    </span>{' '}
                    {formatNumber(value, isArabic)}
                </h3>
            </div>
        </>
    )
}

export default ShowTotalValue

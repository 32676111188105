import React, { useEffect, useState } from 'react';
import { get } from '../../services/apis/api';
import CreateExpense from '../../components/dialogueBoxes/CreateExpense';
import ViewEvent from '../../components/mediaCenter/ViewEvent';
import { formatDateTime } from '../../utills/formateDate';
import { truncateText } from '../../utills/truncateText';

const Expense = () => {
  const [expenses, setExpenses] = useState([]);
  const [viewEvent, setViewEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('');
  const [error, setError] = useState('');

  const getAllExpenses = async (category = '') => {
    try {
      const res = await get(`expense/all?category=${category}`);
      console.log('Expenses', res.data.data);
      setExpenses(res.data.data);
      setError('');
    } catch (err) {
      setError('Failed to fetch expenses');
      console.error(err);
    }
  };

  useEffect(() => {
    getAllExpenses();
  }, []);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    getAllExpenses(e.target.value);
  };

  return (
    <div>
      {viewEvent ? (
        <ViewEvent event={selectedEvent} setViewEvent={setViewEvent} />
      ) : (
        <div>
          <div className='flex justify-between px-2 items-center mb-4'>
            <div>
              <div className='relative'>
                <div className='absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none'>
                  <svg
                    className='w-4 h-4 text-gray-500 '
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 20 20'
                  >
                    <path
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                    />
                  </svg>
                </div>
                <select
                  id='category-filter'
                  className='block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 '
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                >
                  <option value=''>All Categories</option>
                  <option value='Office'>Office</option>
                  <option value='Marketing'>Marketing</option>
                  <option value='Transport'>Transport</option>
                  <option value='Miscellaneous'>Miscellaneous</option>
                </select>
              </div>
            </div>
            <div>
              <CreateExpense getAllExpenses={getAllExpenses} />
            </div>
          </div>
          
          <div>
            <div className='relative overflow-x-auto shadow-md sm:rounded-lg'>
              <table className='w-full text-sm text-left rtl:text-right text-gray-500 '>
                <thead className='text-xs text-gray-700 uppercase bg-gray-50'>
                  <tr>
                    <th scope='col' className='p-4'>
                      <div className='flex items-center'>S.No.</div>
                    </th>
                    <th scope='col' className='px-6 py-3'>Category</th>
                    <th scope='col' className='px-6 py-3'>Amount</th>
                    <th scope='col' className='px-6 py-3'>Description</th>
                    <th scope='col' className='px-6 py-3'>Date</th>
                    <th scope='col' className='px-6 py-3'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {expenses.map((expense, index) => (
                    <tr
                      className='bg-white border-b  hover:bg-gray-50 '
                      key={expense._id}
                    >
                      <td className='px-6 py-4'>
                        <div className='flex items-center'>{index + 1}</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='flex items-center'>{expense.category}</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='flex items-center'>{expense.amount}</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='flex items-center'>{truncateText(expense.description, 60)}</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='flex items-center'>
                          {formatDateTime(expense.expenseDate, 'date')}
                        </div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='flex items-center'>
                          <CreateExpense
                            getAllExpenses={getAllExpenses}
                            expense={expense}
                            isEdit={true}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {error && (
            <div className='flex justify-center items-center h-[60vh]'>
              <div className='text-red-500 text-center'>
                {error}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Expense;

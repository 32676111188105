import React from 'react'
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../services/glocalFunctions';

const ShowName = ({
    title,
    value
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return (
        <>
            {/* SHOW NAME  */}
            <div className={`flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} items-center gap-2`}>
                <h1 className='text-xl lg:text-3xl font-semibold text-gray-500'>
                    {isArabic && ':'} {t(title)} {!isArabic && ':'}
                </h1>
                <h1
                    className={`text-xl lg:text-3xl font-bold ${isArabic ? 'text-right' : 'text-left'}`} // Align the amount based on language
                    dir={isArabic ? 'rtl' : 'ltr'} // Set text direction based on language
                >
                    {t(value)}
                </h1>
            </div>
        </>
    )
}

export default ShowName

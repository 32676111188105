import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    Box,
    Text,
    HStack,
    Flex,
    IconButton,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import Select from "react-select";
import { FaEdit, FaTrash } from "react-icons/fa";
import { get, patch, post } from "../../services/apis/api"; // Import the PUT method

const CreateProductInWarehouse = ({
    warehouse,
    isEdit,
    product,
    getWarehouseProducts,
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [price, setPrice] = useState("");
    const [size, setSize] = useState("");
    const [quantity, setQuantity] = useState("");
    const [color, setColor] = useState("#fff");
    const [sizesList, setSizesList] = useState([]);
    const [productOptions, setProductOptions] = useState([]);

    const toast = useToast();

    // Pre-fill the form if editing
    useEffect(() => {
        if (isEdit && product) {
            setSelectedProduct({
                label: `${product?.productId?.name} `,
                value: product?.productId?._id,
            });
            setPrice(product?.price || "");
            setSizesList(product?.sizes || []);
        }
    }, [isEdit, product]);

    const handleAddSize = () => {
        if (size && quantity) {
            const newSize = { size, quantity, color };
            setSizesList([...sizesList, newSize]);
            setSize("");
            setQuantity("");
        }
    };

    const handleEditSize = (index) => {
        const sizeToEdit = sizesList[index];
        setSize(sizeToEdit.size);
        setQuantity(sizeToEdit.quantity);
        setColor(sizeToEdit.color);
        handleRemoveSize(index);
    };

    const handleRemoveSize = (index) => {
        setSizesList(sizesList.filter((_, i) => i !== index));
    };
    const filterOption = (option, inputValue) => {
        return option.data.name
            .toLowerCase()
            .includes(inputValue.toLowerCase());
    };
    const getAllProducts = async () => {
        const res = await get(`product/dropdown?type=${warehouse.type}`);
        if (res.statusCode === 200) {
            const productList = res.data.dropdown.map((product) => ({
                name: product.name,
                _id: product._id,
                label: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={product.images[0]}
                            alt={product.name}
                            style={{
                                marginRight: "10px",
                                width: "50px",
                                height: "50px",
                            }}
                        />
                        <div>
                            <div>{product.name}</div>
                            <div
                                style={{
                                    color: "grey",
                                    fontSize: "12px",
                                    WebkitLineBreak: 2,
                                }}
                            >
                                {product.description}
                            </div>
                        </div>
                    </div>
                ),
            }));
            setProductOptions(productList);
        }
    };

    useEffect(() => {
        getAllProducts();
    }, []);

    const handleSubmit = async () => {
        const productData = {
            productId: selectedProduct?._id,
            price,
            sizes: sizesList,
            warehouseId: warehouse._id,
        };

        try {
            if (isEdit) {
                // Update existing product
                const response = await patch(
                    `warehouse/products/${product._id}`,
                    productData
                );
                if (response.statusCode === 200) {
                    toast({
                        title: "Product Updated",
                        description: response.data.message,
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                    getWarehouseProducts();
                    onClose();
                }
            } else {
                // Create new product
                const response = await post(
                    "warehouse/add-product",
                    productData
                );
                if (response.statusCode === 201) {
                    toast({
                        title: "Product Added",
                        description: response.data.message,
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                    getWarehouseProducts();
                    onClose();
                }
            }
        } catch (error) {
            toast({
                title: "Oops!",
                description:
                    error?.response?.data?.message || "An error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            {isEdit ? (
                <Button
                    onClick={onOpen}
                    className="px-4 py-1 text-white bg-[#290dbd] rounded-md"
                >
                    Edit
                </Button>
            ) : (
                <Button colorScheme="blue" onClick={onOpen}>
                    Add Product
                </Button>
            )}

            <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {isEdit
                            ? "Edit Product in Warehouse"
                            : "Add Product to Warehouse"}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {isEdit ? (
                            <div></div>
                        ) : (
                            <FormControl mb={4}>
                                <FormLabel>Product</FormLabel>
                                <Select
                                    options={productOptions}
                                    value={selectedProduct}
                                    styles={{
                                        option: (provided, state) => ({
                                            backgroundColor: "white",
                                        }),
                                    }}
                                    onChange={(option) =>
                                        setSelectedProduct(option)
                                    }
                                    filterOption={filterOption}
                                    placeholder="Select a product"
                                    isDisabled={isEdit} // Disable product selection when editing
                                />
                            </FormControl>
                        )}

                        <FormControl mb={4}>
                            <FormLabel>Price</FormLabel>
                            <Input
                                type="number"
                                placeholder="Enter price"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </FormControl>

                        <Flex mb={4} gap={4}>
                            <FormControl>
                                <FormLabel>Size</FormLabel>
                                <Input
                                    placeholder="Enter size (e.g., XL)"
                                    value={size}
                                    onChange={(e) => setSize(e.target.value)}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Quantity</FormLabel>
                                <Input
                                    type="number"
                                    placeholder="Enter quantity"
                                    value={quantity}
                                    onChange={(e) =>
                                        setQuantity(e.target.value)
                                    }
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Color</FormLabel>
                                <Input
                                    type="color"
                                    style={{
                                        borderRadius: "20%",
                                        width: "70px",
                                        height: "40px",
                                        padding: 0,
                                        border: "none",
                                    }}
                                    value={color}
                                    onChange={(e) => setColor(e.target.value)}
                                />
                            </FormControl>
                        </Flex>

                        <Button colorScheme="blue" onClick={handleAddSize}>
                            Add Size
                        </Button>

                        <Box mt={4}>
                            {sizesList.map((item, index) => (
                                <Flex
                                    key={index}
                                    align="center"
                                    mb={2}
                                    justify="space-between"
                                >
                                    <Box>
                                        <HStack>
                                            <Text>
                                                {item.size} - {item.quantity}
                                            </Text>
                                            <Box
                                                mt={0}
                                                width="40px"
                                                height="40px"
                                                backgroundColor={item.color}
                                                borderRadius="md"
                                                border="1px solid"
                                                borderColor="gray.200"
                                            ></Box>
                                        </HStack>
                                    </Box>
                                    <Box>
                                        <IconButton
                                            icon={<FaEdit />}
                                            size="sm"
                                            mr={2}
                                            onClick={() =>
                                                handleEditSize(index)
                                            }
                                            aria-label="Edit size"
                                        />
                                        <IconButton
                                            icon={<FaTrash />}
                                            size="sm"
                                            onClick={() =>
                                                handleRemoveSize(index)
                                            }
                                            aria-label="Remove size"
                                        />
                                    </Box>
                                </Flex>
                            ))}
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme="blue"
                            ml={3}
                            onClick={handleSubmit}
                        >
                            {isEdit ? "Update" : "Save"}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateProductInWarehouse;

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import { CheckCircleIcon, CheckIcon, CloseIcon, HamburgerIcon, InfoIcon, ViewIcon } from '@chakra-ui/icons';
import { Th, Table, TableContainer, Thead, Tr, Td, Tbody, Button, Badge, MenuButton, Menu, MenuList, MenuItem, Select, Divider, IconButton, Tooltip, useToast } from '@chakra-ui/react';
import { AiFillCheckCircle, AiOutlineCheck, AiOutlineClockCircle, AiOutlineClose } from 'react-icons/ai';
import { TbEyeDiscount } from 'react-icons/tb';
import { CiCircleCheck } from "react-icons/ci";
import { RxCrossCircled } from "react-icons/rx";
import { FiEye } from "react-icons/fi";
import { FaFileInvoiceDollar } from 'react-icons/fa';
import PayslipModal from '../../components/hr/dialogBoxes/PayslipModal';
import { get, patch } from '../../services/apis/api';
import moment from 'moment';
import ConfirmRejectBox from '../../components/alerts/ConfirmRejectBox';
import { debounce } from 'lodash';
import ConfirmApproveBox from '../../components/alerts/ConfirmApprove';


const Heading = ({ title }) => (
    <h2 className="text-base text-gray-600 mb-2 lg:text-xl font-bold">{title}</h2>
)


const Payroll = () => {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const toast = useToast();
    const [requests, setRequests] = useState([]);
    const [selectedReq, setSelectedReq] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [view, setView] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    // Get current date and determine the default month and year
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // 0 for January, 11 for December
    const currentYear = currentDate.getFullYear();

    // If current month is January, set the default month to December of the previous year
    const defaultMonth = currentMonth === 0 ? 'December' : currentDate.toLocaleString('default', { month: 'long' });
    const defaultYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    const [selectedMonth, setSelectedMonth] = useState(defaultMonth);
    const [selectedYear, setSelectedYear] = useState(defaultYear.toString());
    const [data, setData] = useState([]);
    const [counts, setCounts] = useState(null);

    // FETCH ALL PAYROLLS 
    const fetchData = async () => {
        setLoading(true);
        try {
            // Include month and year in the API call if selected
            const res = await get(
                `payroll/all?page=${page}&limit=${10}&search=${searchTerm}&month=${selectedMonth}&year=${selectedYear}`
            );
            if (res.statusCode === 200) {
                setData(res.data.data);
                setCounts(res.data.counts);
                setTotalPages(res.data.pages);
            }
        } catch (error) {
            console.error('Error fetching payroll data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, selectedMonth, selectedYear]);


    // Debounce the getAllData function
    const debouncedGetAllData = debounce(fetchData, 300); // Adjust delay as needed

    useEffect(() => {
        debouncedGetAllData();
        // Cleanup function to cancel the debounced call on component unmount
        return () => {
            debouncedGetAllData.cancel();
        };
    }, [searchTerm]);

    //  HANDLE PAYSLIP REQUEST BY API
    const handlePaySlipRequest = async (_id, decision) => {
        try {
            const response = await patch(`payroll/update/${_id}`, { paymentStatus: decision });
            if (response.statusCode === 200) {
                fetchData();
                toast({
                    title: 'Successfull...!',
                    description: decision == 'APPROVED' ? 'Payroll Request Approves' : 'Payroll Request Rejected',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (e) {
            console.log(`ERROR WHILE UPDATING PAYSLIP DATA : ${e}`);
        }
    }

    // ALL REQUESTS LIST 
    const RequestList = () => {
        return (
            <TableContainer shadow="md" borderRadius="lg" flex={1} display="flex" flexDirection="column">
                <Table variant="simple">
                    <Thead bg="gray.50">
                        <Tr>
                            <Th>{t('s-no')}</Th>
                            <Th>{t('employee-name')}</Th>
                            <Th>{t('employee-code')}</Th>
                            <Th>{t('requested-amount')}</Th>
                            <Th>{t('performance')}</Th>
                            <Th>{t('payroll-month')}</Th>
                            <Th>{t('status')}</Th>
                            <Th>{t('action')}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data?.length > 0 ? (
                            data?.map((request, index) => (
                                <Tr key={request.requestId}>
                                    <Td>{index + 1}</Td> {/* Serial Number */}
                                    <Td>{request?.employee?.name}</Td>
                                    <Td>{request?.employee?.employeeCode}</Td>
                                    <Td>{request?.finalNetPay?.toFixed(2)}</Td> {/* Requested Amount */}
                                    <Td>{request.performance || 'N/A'}</Td> {/* Performance */}
                                    {/* <Td>{request?.payrollMonth}</Td> Submission Date */}
                                    <Td>{request?.payrollMonth ? moment(request.payrollMonth).format('DD-MM-YYYY') : ''}</Td>
                                    <Td>
                                        <Badge colorScheme={getBadgeColor(request.paymentStatus)}>
                                            {request.paymentStatus}
                                        </Badge>
                                    </Td>
                                    <Td>
                                        {request?.paymentStatus === 'PENDING' && user?.role?.toLowerCase() === 'account-manager' ? (
                                            <>
                                                <ConfirmApproveBox
                                                    handlePaySlipRequest={async () => {
                                                        await handlePaySlipRequest(request?._id, 'APPROVED');
                                                        fetchData();
                                                    }}
                                                    request={request}
                                                    fetchData={() => { fetchData() }}
                                                />
                                                <ConfirmRejectBox
                                                    handlePaySlipRequest={async () => {
                                                        handlePaySlipRequest(request?._id, 'REJECTED');
                                                    }}
                                                    request={request}
                                                    fetchData={() => { fetchData() }}
                                                />
                                            </>
                                        ) : null}
                                        <PayslipModal
                                            payslip={request}
                                            handlePaySlipRequest={(_id, decision) => {
                                                handlePaySlipRequest(_id, decision);
                                            }}
                                            fetchData={() => { fetchData() }}
                                        />
                                    </Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Td colSpan="5" textAlign="center">
                                    {t('no-requests-found')}
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </TableContainer>
        );
    };

    // ADD BADGE COLOR ON STATUS FIELD 
    const getBadgeColor = (status) => {

        switch (status) {
            case 'APPROVED':
                return 'green';
            case 'REJECTED':
                return 'red';
            case 'PENDING':
                return 'yellow';
            default:
                return 'gray';
        }
    };

    // OVERVIEW CARDS 
    const StatusItem = ({ title, count, color }) => {
        const colorClasses = {
            blue: "bg-blue-100 border-blue-400 text-blue-800",
            green: "bg-green-100 border-green-400 text-green-800",
            yellow: "bg-yellow-100 border-yellow-400 text-yellow-800",
            red: "bg-red-100 border-red-400 text-red-800",
        };

        const iconMap = {
            "Total Requests": <AiFillCheckCircle className="mr-1" />,
            "Approved": <AiOutlineCheck className="mr-1" />,
            "Pending": <AiOutlineClockCircle className="mr-1" />,
            "Rejected": <AiOutlineClose className="mr-1" />,
        };

        return (
            <div
                className={`flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} items-center border-2 ${colorClasses[color]} rounded-lg p-2 shadow-sm transition-transform transform hover:scale-105 duration-200`}
            >
                {/* {iconMap[title]} */}
                <span className="font-bold text-lg ml-2">{count}</span>
                <span className="ml-2 text-sm">{t(title)}</span>
            </div>
        );
    };

    return (
        <>
            <div className='w-full flex-1 flex flex-col items-start gap-2 lg:gap-4 p-2 lg:p-4'>
                <div className={`flex justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                    <h2 className=' text-xl lg:text-2xl  whitespace-nowrap font-bold'>{t("payroll-&-salaries")}</h2>
                </div>
                <div className="flex gap-4">
                    <StatusItem title="total-requests" count={counts?.total || '0'} color="blue" />
                    <StatusItem title="approved" count={counts?.approved || '0'} color="green" />
                    <StatusItem title="pending" count={counts?.pending || '0'} color="yellow" />
                    <StatusItem title="rejected" count={counts?.rejected || '0'} color="red" />
                </div>
                <div className="w-full flex flex-col items-start gap-3 flex-1 min-h-[50vh]">
                    <div className='w-full flex items-center justify-between py-3'>
                        <Heading
                            title={t("request")}
                        />
                        <div className='flex w-full justify-between lg:w-fit items-center'>
                            <div className="relative">
                                <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    id="table-search-users"
                                    className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                                    placeholder={t("search-request")}
                                    value={searchTerm}
                                    onChange={(e) => { setSearchTerm(e.target.value) }}
                                />
                            </div>
                            {/* HIDDEN FOR NOW  */}
                            <div className='ms-2 hidden'>
                                <Menu closeonselect={false}>
                                    <MenuButton
                                        as={IconButton}
                                        aria-label='Options'
                                        icon={<HamburgerIcon />}
                                        variant='outline'
                                    />
                                    <MenuList p={2}>
                                        <Select placeholder={t('expense-type')} textAlign={isArabic ? "right" : 'left'} bg="#f9fafb" borderColor="gray.300" >
                                            {/* {expenseTypeOptions.map((item) => (
                                            <option key={item} value={item}>
                                                {t(item)}
                                            </option>
                                        ))} */}
                                        </Select>
                                        <Divider my={1} />
                                        <Select placeholder={t('request-status')} textAlign={isArabic ? "right" : 'left'} bg="#f9fafb" borderColor="gray.300">
                                            {/* {requestStatusOptions.map((item) => (
                                            <option key={item} value={item}>
                                                {t(item)}
                                            </option>
                                        ))} */}
                                        </Select>
                                    </MenuList>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex-1 flex flex-col '>
                        <RequestList />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Payroll

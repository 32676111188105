import React, { useEffect, useState } from "react";
import { DownloadIcon, InfoIcon } from "@chakra-ui/icons";
import { UserState } from "../../context/user";
import { useTranslation } from 'react-i18next';
import { Th, Table, TableContainer, Thead, Tr, Td, Tbody, Button, useDisclosure, useToast, Box } from '@chakra-ui/react';
import { FaStore } from "react-icons/fa";
import { GrVmMaintenance } from "react-icons/gr";
import { TbBrandMiniprogram } from "react-icons/tb"
import AddCategoryModel from "../../components/account/AddCategory";
import { get, postFormData } from "../../services/apis/api";
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import { CategorySkeleton, EarningCardSkeleton } from "../../components/skeletons/Skeletons";
import { useNavigate } from "react-router-dom";
import TableComponent from "../../components/account/TableComponent";
import { debounce } from 'lodash';
import PastTransactions from "../../components/account/PastTransactions";
import { formatNumber } from "../../services/glocalFunctions";
import BreadCrumbs from "../../components/BreadCrumbs";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ConfirmDownload from "../../components/dialogueBoxes/ConfirmDownload";

const _directIncmeCategory = [
  {
    name: 'academy',
  },
  {
    name: 'winning-tournament',
  },
  {
    name: 'vip-club',
  },
  {
    name: 'store',
  },
  {
    name: 'clinic',
  },
]

// CATEGORY CARD COLORS 
const cardColors = [
  '#e1f5fe', // Light cyan
  '#ffebee', // Light red
  '#c8e6c9', // Light green
  '#b3e5fc', // Light blue
]

const Heading = ({ title }) => (
  <h2 className="text-base text-gray-600 mb-2 lg:text-xl font-bold">{title}</h2>
)

const SubHeading = ({ title }) => (
  <h3 className="text-sm text-gray-500 mb-1 lg:text-lg font-semibold">{title}</h3>
);

// ADD CATEGORY FORM DATA 
const _categoryFormData = {
  name: '',
  // mode: '',
  arabicName: '',
  file: null,
  selectedImg: null,
  description: ''
}

const EarningsAndIncome = () => {
  const { user } = UserState();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const isArabic = i18n.language === 'ar';
  const [requests, setRequests] = useState([]);
  const [categoryData, setCategoryData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryFormData, setCategoryFormData] = useState({ ..._categoryFormData });
  const [statisticsData, setStatisticsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);  // for confirmation dialog

  // EARNINGS CARD 
  const EarningsCard = ({ earning, color }) => {
    return (
      <div
        onClick={() => {
          if (earning?.label === 'additional-income') {
            navigate('/account/additional-income');
          }
        }}
        style={{ backgroundColor: color }}
        className={`bg-[#fcfbfb] shadow-md flex flex-col ${isArabic ? 'items-end' : "items-start"} gap-2 w-full md:w-[49%] lg:w-[24%] md py-6 px-8 rounded-md mb-4`}
      >

        <div className={`w-ful flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} items-center  gap-2`}>
          <InfoIcon w={3} color="gray.500" />
          <h6 className="text-sm whitespace-nowrap lg:text-xs text-gray-500 font-medium capitalize">{t(earning?.label) || '--'}</h6>
        </div>
        <h4
          className={`text-xl lg:text-2xl font-bold ${isArabic ? 'text-right' : 'text-left'}`} // Align the amount based on language
          dir={isArabic ? 'rtl' : 'ltr'} // Set text direction based on language
        >
          <span className="text-lg lg:text-lg">
            {isArabic ? 'ر.س' : 'SAR'}
          </span>{' '}
          {formatNumber(earning?.value, isArabic)}
        </h4>
      </div>
    )
  }

  // CATEGORY CARD 
  const CategoryCard = ({ category, onHandleClick }) => {
    return (
      <div onClick={onHandleClick} className='bg-[#f1f5f9] shadow-md lg:shadow-sm flex flex-col items-center justify-center gap-2 w-[49%] lg:w-1/5 md py-6 px-8 rounded-md mb-4'>
        {category?.categoryImg ?
          <img src={category?.categoryImg} className="w-14" alt="categoryImg" />
          :
          <GrVmMaintenance size={28} />
        }
        <h6 className="text-sm  lg:text-base font-semibold capitalize text-center">{isArabic ? category?.arabicName ? category?.arabicName : t(category?.name) : t(category?.name)}</h6>
      </div>
    )
  }

  // CREATE A NEW CATEGORY 
  const addNewCategory = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("categoryImg", categoryFormData?.file);
      formData.append("name", categoryFormData?.name);
      formData.append("arabicName", categoryFormData?.arabicName);
      formData.append("description", categoryFormData?.description);
      // formData.append("incomeType", categoryFormData?.mode);

      const res = await postFormData(`transactionsCategory/add/`, formData);
      setIsLoading(false);
      if (res.statusCode === 201 || res.statusCode === 200) {
        getAllIncomeCategories();
        toast({
          title: 'Success!',
          description: res.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // getAlRwaadServices();
        // resetForm();
        setCategoryFormData({ ..._categoryFormData });
        onClose();
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: 'Error!',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.log(`ERROR WHILE CREATING CATEGORY: ${error}`);
    }
  };

  // GET ALL CATEGORY DATA BY API 
  const getAllIncomeCategories = async () => {
    try {
      const res = await get(`transactionsCategory/all`);
      if (res.statusCode === 200) {
        setCategoryData(res.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // GET TOTAL EARNINGS AND INCOMES BY API
  const getTotalEarnings = async () => {
    try {
      const res = await get(`transactionsCategory/get-total`);
      if (res.statusCode === 200) {
        setStatisticsData(res?.data?.data)
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllIncomeCategories();
    getTotalEarnings();
  }, []);

  // DOWNLOAD FULL TOTAL EXPENSES PAGE AS PDF
  const downloadFullPageAsPdf = async () => {
    try {
      setLoading(true);
      const pageElement = document.getElementById('earnings-total'); // Target the full page

      const canvas = await html2canvas(pageElement, {
        scale: 2, // High-quality output
        useCORS: true, // Handle cross-origin images
      });

      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = 210; // A4 width in mm
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      // Split content into pages if necessary
      let currentHeight = 0;
      while (currentHeight < canvas.height) {
        const sourceCanvas = document.createElement('canvas');
        const context = sourceCanvas.getContext('2d');
        sourceCanvas.width = canvas.width;
        sourceCanvas.height = pdf.internal.pageSize.height * (canvas.width / pdfWidth);

        context.drawImage(
          canvas,
          0,
          currentHeight,
          canvas.width,
          sourceCanvas.height,
          0,
          0,
          sourceCanvas.width,
          sourceCanvas.height
        );

        const pageImage = sourceCanvas.toDataURL('image/png');
        pdf.addImage(pageImage, 'PNG', 0, 0, pdfWidth, pdfHeight);

        currentHeight += sourceCanvas.height;
        if (currentHeight < canvas.height) pdf.addPage();
      }

      pdf.save('TotalEarnings&Income.pdf');
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(`ERROR WHILE DOWNLOADING TOTAL EXPENSES ${e}`);
    }
  };

  // CONFIRM DOWNLOAD FUNCTION 
  const confirmDownload = () => {
    try {
      downloadFullPageAsPdf();
      setDownloadOpen(false);
    } catch (e) {
      console.log(`ERROR WHILE GENERATING PDF ${e}`);
    }
  };


  return (
    <>
      <BreadCrumbs />
      <div className='w-full flex-1 flex flex-col gap-3 lg:gap-4 p-2 lg:p-4'>
        <div className={`flex justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
          <h2 className=' text-xl lg:text-2xl  whitespace-nowrap font-bold'>{t("earnings-income")}</h2>
          {/* Download Confirmation Dialog */}
          <ConfirmDownload
            isOpen={downloadOpen}
            label='download-pdf'
            setIsOpen={setDownloadOpen}
            handleDownload={() => {
              setDownloadOpen(true);
            }}
            confirmDownload={() => {
              confirmDownload();
            }}
            closeDialog={() => {
              setDownloadOpen(false);
            }}
            loading={loading}
          />
        </div>
        <div id="earnings-total">
          {/* TOTAL CARDS  */}
          <div className={`w-full flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} flex-wrap gap-2 lg:gap-4 items-start`}>
            {statisticsData ? statisticsData?.map((earning, index) => (
              <EarningsCard
                key={index}
                earning={earning}
                color={cardColors[index % cardColors.length]}
              />
            )) : <EarningCardSkeleton />}

          </div>
          <div className="w-full">
            <div className={`w-full flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} flex-wrap py-4 gap-2 lg:gap-4 items-center justify-start`}>
              {_directIncmeCategory
                ? _directIncmeCategory?.length > 0
                  ? _directIncmeCategory?.map((category, index) => (
                    <CategoryCard
                      key={index}
                      category={category}
                      onHandleClick={() => {
                        navigate(`/account/earnings/direct/${category?.name?.toLowerCase().replace(/\s+/g, '-')}`);
                      }}
                    />
                  ))
                  : 'NO CATEGORIES AVAILABLE'
                : <CategorySkeleton />
              }
            </div>
            <div className={`w-full flex ${isArabic ? 'flex-row-reverse' : 'flex-row'}  justify-between items-center mb-4`}>
              <Heading title={t("income-categories")} />
              <Button
                colorScheme="blue"
                onClick={onOpen}
              >
                {t("add-category")}
              </Button>
              <AddCategoryModel
                isOpen={isOpen}
                onClose={onClose}
                categoryFormData={categoryFormData}
                onHandleChange={(field, value) => {
                  setCategoryFormData((prevData) => ({
                    ...prevData,
                    [field]: value
                  }));
                }}
                isLoading={isLoading}
                onReset={() => {
                  setCategoryFormData({ ..._categoryFormData });
                  onClose();
                }}
                onHandleSubmit={addNewCategory}
              />
            </div>
            <div className="w-full py-4">
              <div className={`w-full ${isArabic ? 'flex-row-reverse' : 'flex-row'} flex flex-wrap py-4 gap-2 lg:gap-4 items-center justify-start`}>
                {categoryData
                  ? categoryData?.length > 0
                    ? categoryData?.map((category, index) => (
                      <CategoryCard
                        key={index}
                        category={category}
                        onHandleClick={() => {
                          // navigate(`/account/earnings/${category?.name?.toLowerCase()}/${category?._id}`)
                          navigate(`/account/earnings/${category?.name?.toLowerCase().replace(/\s+/g, '-')}/${category?._id}`);
                        }}
                      />
                    ))
                    : 'NO CATEGORIES AVAILABLE'
                  : <CategorySkeleton />
                }
              </div>
            </div>
          </div>
        </div>
        <PastTransactions
          txnType={'CR'}
        />
      </div>
    </>
  );
};

export default EarningsAndIncome;

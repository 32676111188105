import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Switch,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useDisclosure,
    useToast,
    Box,
} from '@chakra-ui/react';
import Select from 'react-select';
import { get, patch } from '../../services/apis/api';

const ChangeTeamPlayer = ({ teamId, getTeamDetails }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const [players, setPlayers] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [allPlayers, setAllPlayers] = useState([]);

    const handlePlayerChange = (selectedOption) => {
        setSelectedPlayer(selectedOption);
    };

    const handleAddPlayer = () => {
        if (selectedPlayer && !players.some(player => player.value === selectedPlayer.value)) {
            setPlayers([...players, { ...selectedPlayer, isPlaying: true }]);
            setSelectedPlayer(null);
        } else {
            toast({
                title: "Player already added or not selected",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleRemovePlayer = (playerId) => {
        setPlayers(players.filter(player => player.value !== playerId));
    };

    const handleTogglePlaying = (playerId) => {
        setPlayers(players.map(player =>
            player.value === playerId ? { ...player, isPlaying: !player.isPlaying } : player
        ));
    };

    const updateTeamPlayer = async () => {
        try {
            const formattedPlayers = players.map(player => ({
                playerId: player.value,
                isPlaying: player.isPlaying,
            }));

            const res = await patch(`team/update/${teamId}`, { players: formattedPlayers });
            if (res.statusCode === 200) {
                toast({
                    title: "Team updated successfully",
                    description: "The team players have been updated.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                onClose();
                getTeamDetails();
            } else {
                throw new Error('Update failed');
            }
        } catch (error) {
            toast({
                title: "Error updating team",
                description: "There was an error updating the team players.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const getMyTeamDetails = async () => {
        try {
            const teamRes = await get(`team/get/${teamId}`);
            if (teamRes.statusCode === 200) {
                const teamPlayers = teamRes.data.team.players.map(player => ({
                    value: player.playerId._id,
                    label: player.playerId.name,
                    isPlaying: player.isPlaying,
                }));
                setPlayers(teamPlayers);

                const playerRes = await get(`player/dropdown?gameId=${teamRes.data.team.gameId._id}`);
                if (playerRes.statusCode === 200) {
                    const playerOptions = playerRes.data.dropdown.map(player => ({
                        value: player._id,
                        label: player.name,
                    }));
                    setAllPlayers(playerOptions);
                }
            }
        } catch (error) {
            toast({
                title: "Error fetching team details",
                description: "There was an error fetching the team details.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        getMyTeamDetails();
    }, []);

    return (
        <>
            <Button onClick={onOpen}>Update Team Player</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Update Team Player</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Search Player</FormLabel>
                            <Select
                                options={allPlayers}
                                value={selectedPlayer}
                                onChange={handlePlayerChange}
                                placeholder="Select player..."
                            />
                        </FormControl>
                        <Button mt={4} colorScheme="blue" onClick={handleAddPlayer}>
                            Add Player
                        </Button>
                        <Box mt={4}>
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Player Name</Th>
                                        <Th>Action</Th>
                                        <Th>Is Playing</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {players.map(player => (
                                        <Tr key={player.value}>
                                            <Td>{player.label}</Td>
                                            <Td>
                                                <Button colorScheme="red" onClick={() => handleRemovePlayer(player.value)}>
                                                    Remove
                                                </Button>
                                            </Td>
                                            <Td>
                                                <Switch
                                                    isChecked={player.isPlaying}
                                                    onChange={() => handleTogglePlaying(player.value)}
                                                />
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="gray" mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button onClick={updateTeamPlayer} colorScheme='blue'>Save Changes</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ChangeTeamPlayer;

import React, { useState, useRef, useEffect } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    Button,
    useToast,
    Checkbox,
    Stack,
    Spinner,
} from "@chakra-ui/react";
import { post, patch } from "../../services/apis/api";

const CreateWarehouse = ({ getAllWarehouses, warehouse, isEdit }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const initialRef = useRef(null);
    const toast = useToast();

    const [formValues, setFormValues] = useState({
        name: "",
        location: "",
        type: "",
    });

    useEffect(() => {
        if (isEdit && warehouse) {
            setFormValues({
                name: warehouse.name,
                location: warehouse.location,
                type: warehouse.type
            });
        }
    }, [isEdit, warehouse]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };
    const handleTypeCheckboxChange = (e) => {
        const { value, checked } = e.target;

        setFormValues((prevValues) => {
            if (checked) {
                return {
                    ...prevValues,
                    type: value,
                };
            } else {
                return {
                    ...prevValues,
                    type: "",
                };
            }
        });
    };
    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            let res;
            if (isEdit) {
                res = await patch(
                    `warehouse/update/${warehouse._id}`,
                    formValues
                );
            } else {
                res = await post("warehouse/add", formValues);
            }

            if (res.statusCode === 201 || res.statusCode === 200) {
                onClose();
                getAllWarehouses();
                setIsLoading(false);
                toast({
                    title: "Success!",
                    description: res.data.message,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            setIsLoading(false);
            toast({
                title: "Oops!",
                description: error?.response?.data?.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            console.log(error);
        }
    };

    return (
        <>
            <Button
                colorScheme="blue"
                onClick={(e) => {
                    e.stopPropagation();
                    onOpen();
                }}
            >
                {isEdit ? "Edit" : "Create Warehouse"}
            </Button>
            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {isEdit ? "Edit Warehouse" : "Create New Warehouse"}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>Warehouse Name</FormLabel>
                            <Input
                                ref={initialRef}
                                placeholder="Warehouse Name"
                                name="name"
                                value={formValues.name}
                                onChange={handleChange}
                            />
                            <FormLabel>Location</FormLabel>
                            <Input
                                placeholder="Location"
                                name="location"
                                value={formValues.location}
                                onChange={handleChange}
                            />
                            <FormLabel>Type?</FormLabel>
                            <Stack direction={"row"}>
                                <Checkbox
                                    value={"EXTERNAL"}
                                    isChecked={formValues.type === "EXTERNAL"}
                                    onChange={handleTypeCheckboxChange}
                                >
                                    External
                                </Checkbox>
                                <Checkbox
                                    value={"INTERNAL"}
                                    isChecked={formValues.type === "INTERNAL"}
                                    onChange={handleTypeCheckboxChange}
                                >
                                    Internal
                                </Checkbox>
                            </Stack>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button
                            colorScheme="blue"
                            ml={3}
                            onClick={handleSubmit}
                        >
                            {isLoading ? (
                                <Spinner />
                            ) : isEdit ? (
                                "Update"
                            ) : (
                                "Save"
                            )}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateWarehouse;

import React from 'react'
import Attendance from '../pages/employee/Attendance'
import Performance from '../pages/employee/Performance'
import Payroll from '../pages/employee/Payroll'
import Request from '../pages/employee/Request'
import EmployeeHome from '../pages/employee/EmployeeHome'
import CommanNav from '../components/globles/CommanNav'
import { Route, Routes } from 'react-router-dom'

const Employee = () => {
    return (
        <div>
            <Routes>
                <Route path="/employee" element={<CommanNav />}>
                    <Route index element={<EmployeeHome />} />
                    <Route path="attendance" element={<Attendance />} />
                    <Route path="performance" element={<Performance />} />
                    <Route path="payroll" element={<Payroll />} />
                    <Route path="request" element={<Request />} />
                </Route>
            </Routes>
        </div>
    )
}

export default Employee

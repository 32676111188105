import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const ViewAssetDetailsModal = ({ isOpen, onClose, asset }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent dir={isArabic ? 'rtl' : 'ltr'}>
                <ModalHeader textAlign={isArabic ? 'right' : 'left'} dir={isArabic ? 'rtl' : 'ltr'}>{t('asset-details')}</ModalHeader>
                <ModalCloseButton
                    style={{
                        [isArabic ? 'left' : 'right']: '10px' // Conditional positioning based on language
                    }}
                />
                <ModalBody>
                    <Box textAlign={isArabic ? 'right' : 'left'}>
                        <Text><strong>{t('asset-name')}:</strong> {asset?.name}</Text>
                        <Text><strong>{t('model')}:</strong> {asset?.model}</Text>
                        <Text><strong>{t('asset-id')}:</strong> {asset?.serialNumber}</Text>
                        <Text><strong>{t('price')}:</strong> {asset?.price}</Text>
                        <Text><strong>{t('depritiation-rate')}:</strong> {asset?.depritiationRate}%</Text>
                        <Text><strong>{t('depritiation-frequency')}:</strong> {asset?.depritiationFrequency}</Text>
                        <Text><strong>{t('purchase-date')}:</strong> {new Date(asset?.purchaseDate).toLocaleDateString()}</Text>
                        <Text><strong>{t('status')}:</strong> {asset?.status}</Text>
                        <Text><strong>{t('condition')}:</strong> {asset?.condition}</Text>
                        <Text><strong>{t('category')}:</strong> {asset?.category}</Text>
                        <Text><strong>{t('description')}:</strong> {asset?.description}</Text>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        {t('close')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ViewAssetDetailsModal;

import React, { useEffect, useState } from 'react';
import { get, patch } from '../../services/apis/api';
import CreateSalesLedger from '../../components/dialogueBoxes/CreateSalesLedger';
import ViewEvent from '../../components/mediaCenter/ViewEvent';
import { formatDateTime } from '../../utills/formateDate';

const SalesLedger = () => {
  const [salesLedgers, setSalesLedgers] = useState([]);
  const [viewEvent, setViewEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  const getAllSalesLedgers = async (query = '') => {
    try {
      const res = await get(`sales-ledger/all?search=${query}`);
      console.log("SalesLedgers", res.data.data);
      setSalesLedgers(res.data.data);
    } catch (error) {
      console.error('Error fetching sales ledgers:', error);
    }
  };

  useEffect(() => {
    getAllSalesLedgers(searchQuery);
  }, [searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div>
      {
        viewEvent ?
          <ViewEvent event={selectedEvent} setViewEvent={setViewEvent} /> :
          <div>
            <div className='flex justify-between px-2 items-center mb-4'>
              <div>
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                  </div>
                  <input 
                    type="text" 
                    id="table-search-users" 
                    className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500" 
                    placeholder="Search for name" 
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
              <div>
                <CreateSalesLedger getAllSalesLedgers={getAllSalesLedgers} />
              </div>
            </div>
            <div>
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="p-4">
                        <div className="flex items-center">
                          S.No.
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Phone NO.
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Email
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Date
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Product Name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Quantity
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Price
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {salesLedgers.map((salesLedger, index) => (
                      <tr key={salesLedger._id} className="bg-white border-b hover:bg-gray-50">
                        <td className="w-4 p-4">
                          <div className="flex items-center">
                            {index + 1}
                          </div>
                        </td>
                        <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                          <div className="text-base font-semibold">{salesLedger.customerDetails.name}</div>
                        </th>
                        <td className="px-6 py-4">
                          {salesLedger.customerDetails.phone}
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex items-center">
                            {salesLedger.customerDetails.email}
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          {formatDateTime(salesLedger.saleDate, "date")}
                        </td>
                        <td className="px-6 py-4">
                          {salesLedger.product.name}
                        </td>
                        <td className="px-6 py-4">
                          {salesLedger.quantity}
                        </td>
                        <td className="px-6 py-4">
                          {salesLedger.price}
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex items-center">
                            <CreateSalesLedger getAllSalesLedgers={getAllSalesLedgers} salesLedger={salesLedger} isEdit={true} />
                            {/* <button className='text-[#ffffff] ml-2 bg-red-500 font-semibold py-2 px-4 rounded-md'>Delete</button> */}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      }
    </div>
  );
}

export default SalesLedger;

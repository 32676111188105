import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Button,
  useToast,
} from '@chakra-ui/react';
import { Select } from '@chakra-ui/react';
import { get, patch, post } from '../../services/apis/api';
import { BiNotepad } from 'react-icons/bi';



const AssignPlayertoTeam = ({playerId, name, getAllPlayers}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = useRef(null);
    const finalRef = useRef(null);
    const toast = useToast();
    const [isPlaying, setIsPlaying] = useState();
    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState('');
    

    const handleSubmit = async() => {
        try {
            const res = await patch(`team/player/update/${selectedTeam}`, { playerId, isPlaying});
            console.log(res);
            if(res.statusCode === 200){
                onClose();
                getAllPlayers();
                toast({
                    title: 'Successfull...!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: 'Opps...!',
                description: error.response.data.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
            console.log(error);
        }
    };

    const getTeams = async()=>{
        const res = await get('team/dropdown');
        if(res.statusCode === 200){
            setTeams(res.data.dropdown);
        }
    }

    useEffect(() => {
        getTeams();
    }, [])
    

    return (
        <>
            <Button onClick={onOpen} colorScheme='blue' me={6}>Assign Team</Button>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Assign Player to Team</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl mt={4}>
                    <FormLabel>Player Name</FormLabel>
                    <Input
                        placeholder='Select Player Name'
                        name='player'
                        value={name}
                    />
                 
                    </FormControl>
                    <FormControl mt={4}>
                    <FormLabel>Team Name</FormLabel>
                    <Select
                        placeholder='Select Team Name'
                        name='team'
                        value={selectedTeam}
                        onChange={(e)=>{setSelectedTeam(e.target.value)}}
                    >
                        {teams.length> 0 && teams.map((team, index) => (
                        <option key={team._id} value={team._id}>
                            {team.name}
                        </option>
                        ))}
                    </Select>
                    </FormControl>
                    <FormControl mt={4}>
                    <FormLabel>Is Playing</FormLabel>
                    <Select
                        placeholder='Is Playing'
                        name='isPlaying'
                        value={isPlaying}
                        onChange={(e)=>{setIsPlaying(e.target.value)}}
                    >
                        <option value={true}>
                            {"Yes"}
                        </option>
                        <option value={false}>
                            {"No"}
                        </option>
                    </Select>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={handleSubmit}>
                    Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AssignPlayertoTeam;

import React, { useEffect, useState } from 'react';
import CreateGame from '../../components/dialogueBoxes/CreateGame'; // Assuming this is for creating age categories
import { get } from '../../services/apis/api';
import _ from 'lodash';
import CreateAgeCategory from '../../components/dialogueBoxes/CreateAgeCategory';
import { truncateText } from '../../utills/truncateText';

const AgeCategory = () => {
    const [ageCategories, setAgeCategories] = useState([]);
    const [search, setSearch] = useState('');

    const getAgeCategories = async (searchQuery = '') => {
        try {
            const res = await get(`ageCategories/all${searchQuery ? `?search=${searchQuery}` : ''}`);
            console.log(res);
            setAgeCategories(res.data.ageCategories);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getAgeCategories();
    }, []);

    // Debounced search function
    const debouncedSearch = _.debounce((query) => {
        getAgeCategories(query);
    }, 300);

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearch(query);
        debouncedSearch(query);
    };

    return (
        <>
            <div className='flex w-full justify-between px-2 items-center mb-4'>
                <div>
                    <div className="relative">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                        <input
                            type="text"
                            id="table-search-categories"
                            className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Search for age categories"
                            value={search}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>
                <div>
                    <CreateAgeCategory getAgeCategories={getAgeCategories} isEdit={false} />
                </div>
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Age Category Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Min Age
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Max Age
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Description
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ageCategories.map((category, index) => (
                                <tr key={index} className="w-full bg-white border-b hover:bg-gray-50">
                                    <td className="px-6 py-4 font-semibold text-gray-900">
                                        {category?.name}
                                    </td>
                                    <td className="px-6 py-4">
                                        {category?.minAge}
                                    </td>
                                    <td className="px-6 py-4">
                                        {category?.maxAge}
                                    </td>
                                    <td className="px-6 py-4">
                                        {truncateText(category.description, 50)}
                                    </td>
                                    <td className="px-6 py-4">
                                        <CreateAgeCategory getAgeCategories={getAgeCategories} category={category} isEdit={true} /> 
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default AgeCategory;

import { Th, Table, TableContainer, Thead, Tr, Td, Tbody, Button, useDisclosure, useToast, Box, Flex, Tooltip, IconButton } from '@chakra-ui/react';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import moment from 'moment/moment';
import { truncateText } from '../../services/apis/api';
import CreateAsset from '../hr/dialogBoxes/CreateAssets';
import { ViewIcon } from '@chakra-ui/icons';


const AssetsTable = ({
    rows,
    columns,
    setPage,
    limit,
    skip,
    totalPages,
    page,
    setSkip,
    getAllAssets,
    selectedAsset,
    setSelectedAsset,
    onOpen
}) => {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
            console.log(page * limit);

            setSkip(page * limit); // Update skip whenever page or limit changes
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
            setSkip((page - 2) * limit);
        }
    };

    // Function to handle viewing the asset details
    const handleViewDetails = (asset) => {
        setSelectedAsset(asset);
        onOpen();
    };

    return (
        <>
            <TableContainer shadow="md" borderRadius="lg" display="flex" flexDirection="column">
                <Table variant="simple">
                    <Thead bg="gray.50">
                        <Tr>
                            {columns?.map((column, index) => (
                                <Th key={index}>{t(column)}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rows?.length > 0 ? (
                            rows?.map((asset, index) => (
                                <Tr key={asset._id} _hover={{ bg: 'gray.50' }}>
                                    <Td>{index + 1}</Td>
                                    <Td>{asset?.name || 'N/A'}</Td>
                                    {/* <Td>{asset.category || 'N/A'}</Td> */}
                                    <Td>{asset.model || 'N/A'}</Td>
                                    <Td>{asset?.serialNumber || 'N/A'}</Td>
                                    <Td>{asset?.purchaseDate ? moment(asset?.purchaseDate).format('DD-MM-YYYY') : ''}</Td>
                                    {/* <Td>{asset?.price || 'N/A'}</Td> */}
                                    {/* <Td>{asset?.depritiationRate || 'N/A'}</Td> */}
                                    {/* <Td>{asset?.depritiationFrequency || 'N/A'}</Td> */}
                                    <Td>{asset?.status || 'N/A'}</Td>
                                    <Td>{asset?.condition || 'N/A'}</Td>
                                    {/* <Tooltip label={asset?.description || 'N/A'}>
                                        <Td>{truncateText(asset?.description)}</Td>
                                    </Tooltip> */}
                                    <Td className='flex space-x-2'>
                                        {/* Action buttons: Edit and View */}
                                        {user?.role?.toLowerCase() == 'account-manager' ? 
                                        <CreateAsset getAssets={getAllAssets} isEdit={true} asset={asset} />
                                        :null}
                                        <Tooltip label='View Asset'>
                                            <IconButton
                                                icon={<ViewIcon />}
                                                aria-label="View Asset Details"
                                                ml={2}
                                                onClick={() => handleViewDetails(asset)}
                                            />
                                        </Tooltip>
                                    </Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Td colSpan="5" textAlign="center">
                                    {t('no-data-found')}
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </TableContainer>
            {/* Pagination Controls */}
            <Flex justify="space-between" align="center" mt={4}>
                <Button onClick={handlePrevPage} isDisabled={page === 1}>
                    Previous
                </Button>
                <Box>
                    Page {page} of {totalPages}
                </Box>
                <Button onClick={handleNextPage} isDisabled={page === totalPages}>
                    Next
                </Button>
            </Flex>
        </>
    );
};

export default AssetsTable
import React, { useState, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Button,
  useToast,
} from '@chakra-ui/react';
import { Select } from '@chakra-ui/react';
import { post } from '../../services/apis/api';

const noticeTypes = [
  "GENERAL",
  "EVENT",
  "ALERT",
  "REMINDER",
  "NOTICE",
  "ANNOUNCEMENT",
  "WARNING",
  "INFORMATION",
  "MEETING",
  "DEADLINE",
  "HOLIDAY",
  "SURVEY",
  "POLICY"
];

const CreateNotice = ({getAllNotices}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = useRef(null);
    const finalRef = useRef(null);
    const toast = useToast();

    const [formValues, setFormValues] = useState({
        title: '',
        content: '',
        type: '',
        validTill: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
        ...formValues,
        [name]: value
        });
    };

    const handleSubmit = async() => {
        try {
            console.log(formValues);
            const res = await post('notice/add', formValues);
            console.log(res);
            if(res.statusCode === 201){
                onClose();
                getAllNotices();
                toast({
                    title: 'Successfull...!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: 'Opps...!',
                description: error.response.data.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
            console.log(error);
        }
        
        
    };

    return (
        <>
            <Button onClick={onOpen}>Create Notice</Button>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Create New Notice</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel>Title</FormLabel>
                        <Input
                            ref={initialRef}
                            placeholder='title'
                            name='title'
                            value={formValues.title}
                            onChange={handleChange}
                        />
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel>Content</FormLabel>
                        <Input
                            placeholder='content'
                            name='content'
                            value={formValues.content}
                            onChange={handleChange}
                        />
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel>Type</FormLabel>
                        <Select
                            placeholder='Select Type'
                            name='type'
                            value={formValues.type}
                            onChange={handleChange}
                        >
                            {noticeTypes.map((item, index) => (
                            <option key={index} value={item}>
                                {item}
                            </option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl mt={4}>
                    <FormLabel>ValidTill</FormLabel>
                    <Input
                        placeholder='Select Date and Time'
                        size='md'
                        type='datetime-local'
                        name='validTill'
                        value={formValues.validTill}
                        onChange={handleChange}
                    />
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={handleSubmit}>
                    Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateNotice;

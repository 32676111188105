import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, useDisclosure, useToast } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom';
import { get, post } from '../../../services/apis/api';
import { UserState } from '../../../context/user';
import { CategoryDetailSkeleton } from '../../../components/skeletons/Skeletons';
import TableComponent from '../../../components/account/TableComponent';
import AddTournamentIncome from '../../../components/account/directIncomeCategory/AddTournamentIncome';
import BasicDataComponent from '../../../components/account/directIncomeCategory/BasicDataComponent';
import BreadCrumbs from '../../../components/BreadCrumbs';
import { formatDate } from '../../../services/glocalFunctions';

const Heading = ({ title }) => (
    <h2 className="text-base text-gray-600 mb-2 lg:text-xl font-bold">{title}</h2>
)

// TABLE COLUMNS 
const columns = [
    's-no',
    'Date-of-entry',
    'amount',
    'payment-method',
    'payment-id',
    'created-by',
    'description',
    // 'Actions' //QUETIONS IT SHOLD GIVE HERE OR NOT 
];

const _formData = {
    entryDate: '',
    amount: null,
    description: '',
    sourceOfIncome: 'clinic',
    transactionType: 'CR',
    paymentType: 'offline',
    paymentMethod: 'cash',
    paymentId: ''
}

const ClinicDetail = () => {
    const { user } = UserState();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const _id = location.pathname.split('/')[4];
    const [categoryData, setCategoryData] = useState(null)
    const [transactionsData, setTransactionsData] = useState(null)
    const [basicData, setBasicData] = useState(null)
    const [isLoading, setIsLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({ ..._formData });
    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0); // Initial skip should be 0
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [loading, setLoading] = useState(false);

    // GET ALL CATEGORY TRANSECTIONS BY CATEGORY ID 
    const getTransactionsDataById = async (source, year, _skip, _limit) => {
        try {
            const response = await get(`transactionsCategory/transactions?source=${source}&year=${year}&skip=${_skip}&limit=${_limit}`);
            if (response?.statusCode == 200) {
                setTransactionsData(response?.data?.data?.transactions);
                setBasicData(response?.data?.data?.basic);
                const totalCount = response?.data?.data?.basic?.totalCount || 0; // Ensure totalCount is defined
                setTotalPages(Math.ceil(totalCount / limit));
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING DATA:${e}`);
            setTransactionsData(e?.response?.data?.data?.transactions);
            setBasicData(e?.response?.data?.data?.basic);
            setTotalPages(0);
        }
    }

    //FETCH DATA BY SOURCE 
    useEffect(() => {
        // if (skip && limit) {
        getTransactionsDataById('clinic', selectedYear, skip, limit);
        // }
    }, [skip, limit, selectedYear])


    // ADD NEW EARNING HISTORY  
    const addNewEarning = async (_id) => {
        setIsLoading(true)
        try {
            const _payload = {
                ...formData,
            }
            const res = await post(`transactionsCategory/add-earning`, _payload)
            setIsLoading(false);
            if (res.statusCode === 201 || res.statusCode === 200) {
                toast({
                    title: 'Success!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                setFormData({ ..._formData });
                getTransactionsDataById('clinic', selectedYear, skip, limit);
                onClose();
            }
        } catch (e) {
            setIsLoading(false);
            console.log(`ERROR WHILE FETCHING DATA:${e}`);
        }
    }

    // DOWNLOAD DATA AS CSV 
    const downloadCSVData = async () => {
        try {
            setLoading(true);

            const response = await get(`transactionsCategory/transactions?source=${'clinic'}&year=${selectedYear}`);
            // const response = await get(`expense-category/details/${_id}?year=${selectedYear}`)
            if (response?.statusCode === 200) {
                const transactionsData = response?.data?.data?.transactions

                // Construct the filename with the current year
                const filename = `${basicData?.source}-earnings-${selectedYear}.csv`;

                // Check if there are payslips to export
                if (!transactionsData || transactionsData?.length === 0) {
                    setLoading(false);
                    toast({
                        title: 'Info',
                        description: 'No data to export!',
                        status: 'info',
                        duration: 5000,
                        isClosable: true,
                    });
                    return;
                }

                // Generate headers from the first payslip object keys
                const headers = [
                    "S NO.",
                    "Date Of Entry",
                    "Amount",
                    "Payment Method",
                    "Payment Type",
                    "Payment ID",
                    "Created By",
                    "Description"
                ];

                // Convert payslip data into CSV rows
                const rows = transactionsData?.map((txn, index) => {
                    return [
                        index + 1,
                        txn?.date ? formatDate(txn?.date) : "N/A",
                        txn?.amount ?? 0,
                        txn?.paymentMethod || "N/A",
                        txn?.paymentType || "N/A",
                        txn?.paymentId || "N/A",
                        txn?.createdBy?.name || txn?.requestedBy?.name || 'N/A',
                        txn?.description || "N/A"
                    ]?.join(",");
                });

                // Combine headers and rows
                const csvContent = [headers?.join(","), ...rows]?.join("\n");

                // Create Blob and trigger download
                const blob = new Blob([csvContent], { type: "text/csv" });
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                setLoading(false);
                toast({
                    title: 'Success',
                    description: 'CSV Downloaded Successfully!',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Info',
                    description: 'No data to export!',
                    status: 'info',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (e) {
            setLoading(false);
            toast({
                title: 'Info',
                description: 'No data to export!',
                status: 'info',
                duration: 5000,
                isClosable: true,
            });
            console.log(`ERROR WHILE DOWNLOADING CSV ${e}`);
        }
    };

    return (
        <div className='p-2 lg:p-4'>
            {/* <CategoryHeader /> */}
            {/* <button onClick={() => { navigate(-1) }} className="px-4 py-2 text-white bg-[#290dbd] rounded-md hover:bg-[#1d0a8c]">
                {t("back")}
            </button> */}
            <BreadCrumbs />
            {!categoryData ?
                <div className={`w-full flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} items-start justify-between py-4 lg:py-6 `}>
                    <BasicDataComponent
                        basicData={basicData}
                        selectedYear={selectedYear}
                        setSelectedYear={setSelectedYear}
                        totalAmount={basicData?.totalAmount}
                        onAddOpen={() => {
                            onOpen();
                        }}
                        onDownloadCSV={() => {
                            downloadCSVData();
                        }}
                        loading={loading}
                    />
                    <AddTournamentIncome
                        onOpen={onOpen}
                        isOpen={isOpen}
                        onClose={onClose}
                        formData={formData}
                        onHandleChange={(field, value) => {
                            setFormData((prevData) => ({
                                ...prevData,
                                [field]: value
                            }));
                        }}
                        isLoading={isLoading}
                        onReset={() => {
                            setFormData({ ..._formData });
                            onClose();
                        }}
                        onHandleSubmit={() => addNewEarning(_id)}
                    />
                </div>
                :
                <div className='my-6'>
                    <CategoryDetailSkeleton />
                </div>
            }
            <div className={`w-full flex flex-col ${isArabic ? 'items-end' : 'items-start '}  gap-3 flex-1 min-h-[50vh]`}>
                <Heading
                    title={t("past-transections")}
                />
                <div className='w-full flex-1 flex flex-col '>
                    <TableComponent
                        page={page}
                        skip={skip}
                        limit={limit}
                        setSkip={setSkip}
                        setLimit={setLimit}
                        setPage={setPage}
                        columns={columns}
                        rows={transactionsData}
                        totalPages={totalPages}
                    />
                </div>
            </div>
        </div>
    )
}

export default ClinicDetail

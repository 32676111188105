import React, { useEffect, useState } from 'react';
import CreateUser from '../../components/dialogueBoxes/CreateUser';
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { get, patch, post } from '../../services/apis/api';
import _ from 'lodash';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import UpdatePassword from "../../components/operations/dialogBoxes/Updatepassword";
import { IconButton, Tooltip, useDisclosure, useToast } from '@chakra-ui/react';
import { BsFillBackspaceFill } from 'react-icons/bs';
import CustomAlert from '../../components/alerts/CustomAlert';
const Role = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const toast = useToast();
    const [selectedUser, setSelectedUser] = useState(null)
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');

    const getUsers = async (searchQuery = '') => {
        try {
            const res = await get(`user/all${searchQuery ? `?search=${searchQuery}` : ''}`);
            console.log(res);
            setUsers(res?.data?.users);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getUsers();
    }, []);

    const handleCheckboxChange = async (id, value) => {
        try {
            const res = await post(`user/update/${id}`, { isActive: value });
            if (res.statusCode === 200) {
                getUsers();
            }
        } catch (error) {
            console.log();

            toast({
                title: 'Opps...!',
                description: error.response.data.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
    }

    // Debounced search function
    const debouncedSearch = _.debounce((query) => {
        getUsers(query);
    }, 300);

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearch(query);
        debouncedSearch(query);
    }
    const confirmApprove = ()=>{
        handleRemoveAccess();
        onClose();
    }
    
    const handleRemoveAccess = async ()=>{
        try {
            const res = await post('user/remove-access', {userId: selectedUser?._id});
            if(res.statusCode === 200){
                getUsers();
                setSelectedUser(null);
                toast({
                    title: 'Success..!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
            }
        } catch (error) {
            toast({
                title: 'Opps...!',
                description: error?.response?.data?.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
            console.log(error);
        }
    }

    return (
        <div>
            <div className='flex justify-between px-2 items-center mb-4'>
                <div>
                    <div className="relative">
                        <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                            <svg
                                className="w-4 h-4 text-gray-500"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                            </svg>
                        </div>
                        <input
                            type="text"
                            id="table-search-users"
                            className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                            placeholder={t("search-users-name")}
                            value={search}
                            onChange={handleSearchChange}
                        />
                    </div>

                </div>
                <div>
                    <CreateUser isEdit={false} getUsers={getUsers} />
                </div>
            </div>
            <div>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" className="p-4">
                                    <div className="flex items-center">
                                        {t("s-no")}
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    {t("user-name")}
                                </th>
                                {/* <th scope="col" className="px-6 py-3">
                                    {t("phone")}
                                </th> */}
                                <th scope="col" className="px-6 py-3">
                                    {t("job-title")}
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    {t("status")}
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    {t("change password")}
                                </th>

                                <th scope="col" className="px-6 py-3">
                                    {t("action")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                users.map((user, index) => (
                                    <tr className="bg-white border-b hover:bg-gray-50" key={user._id}>
                                        <td className="w-4 p-4">
                                            <div className="flex items-center">
                                                {index + 1}
                                            </div>
                                        </td>
                                        <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                                            <img className="w-10 h-10 rounded-full" src="https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg" alt="User" />
                                            <div className="ps-3">
                                                <div className="text-base font-semibold">{user.name}</div>
                                                <div className="font-normal text-gray-500">{user.email}</div>
                                            </div>
                                        </th>
                                        {/* <td className="px-6 py-4">
                                            {user.mobile}
                                        </td> */}
                                        <td className="px-6 py-4">
                                            <div className="flex items-center">
                                                {user.jobTitle}
                                            </div>
                                        </td>




                                        <td className="px-6 py-4 flex items-center">
                                            <label className="inline-flex items-center cursor-pointer me-8">
                                                <input type="checkbox" className="sr-only peer" checked={user.isActive} onChange={() => handleCheckboxChange(user._id, !user.isActive)} />
                                                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                            </label>

                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="flex items-center">
                                                <UpdatePassword userId={user._id} />
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <CreateUser isEdit={true} user={user} getUsers={getUsers} />
                                            <Tooltip label='Remove all Access'>
                                                <button onClick={() => {onOpen(); setSelectedUser(user)}} className='bg-red-500 text-white p-[9px] rounded-md ms-2 '>
                                                    <BsFillBackspaceFill />
                                                </button>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <CustomAlert
                isOpen={isOpen}
                onClose={onClose}
                title={"Remove Access"}
                description={(`Are you sure you want to remove all access of : ${selectedUser?.name}`)}
                onConfirm={confirmApprove}
                confirmButtonText={t("confirm")}
                cancelButtonText={t("cancel")}
            />
        </div>
    )
}

export default Role;

import React, { useEffect, useState } from 'react'
import CategoryHeader from '../../components/account/CategoryHeader'
import { useTranslation } from 'react-i18next'
import { Button, useDisclosure, useToast } from '@chakra-ui/react'
import { UserState } from "../../context/user";
import TableComponent from '../../components/account/TableComponent';
import AddEarningsHistory from '../../components/account/AddEarningHistory';
import { get, patch, post } from '../../services/apis/api';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { CategoryDetailSkeleton } from '../../components/skeletons/Skeletons';


const Heading = ({ title }) => (
    <h2 className="text-base text-gray-600 mb-2 lg:text-xl font-bold">{title}</h2>
)

// TABLE COLUMNS 
const columns = [
    's-no',
    'Date-of-entry',
    'amount',
    'Transection Type',
    'source-of-income',
    'Description',
    'created-by'
    // 'Actions' //QUETIONS IT SHOLD GIVE HERE OR NOT 
];

const _formData = {
    entryDate: '',
    amount: null,
    description: 'This is an Academy course fees',
    sourceOfIncome: 'Academy',
    transactionType: 'CR',
    paymentType: 'OFFLINE'
}

const CategoryDetail = () => {
    const { user } = UserState();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { t, i18n } = useTranslation();
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const _id = location.pathname.split('/')[3];
    const [categoryData, setCategoryData] = useState(null)
    const [transactionsData, setTransactionsData] = useState(null)
    const [isLoading, setIsLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({ ..._formData })
    const [allPendingSubs, setAllPendingSubs] = useState([])
    const [selectedSubs, setSelectedSubs] = React.useState(null);

    //    FETCH DATA FOR EARNING HISTORY TABLE
    useEffect(() => {
        getCategoryDataById(_id);
        getTransactionsDataById(_id);
        getAllNotPaidOfflineSubscriptions();
    }, [])

    // GET CATEGORY DATA BY CATEGORY ID
    const getCategoryDataById = async (_id) => {
        try {
            const response = await get(`transactionsCategory/detail/${_id}`)
            if (response?.statusCode == 200) {
                setCategoryData(response?.data?.data);
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING DATA:${e}`);

        }
    }

    // ADD NEW EARNING HISTORY  
    const addNewEarning = async (_id) => {
        setIsLoading(true)
        try {
            const _payload = {
                ...formData,
                categoryId: _id,
                incomeType: categoryData?.incomeType
            }
            const res = await post(`transactionsCategory/add-earning`, _payload)
            console.log(res, 'ADD NEW EARNING HISTORY RESPONSE');

            const _updatePaymentPayload = {
                subscriptionId: selectedSubs?._id,
                paymentStatus: 'COMPLETED',
                paymentMode: 'OFFLINE',
                transactionId: res?.data?.data?._id
            }
            const updarePlayerPaymentStatusResponse = await post(`academy-course/update-payment`, _updatePaymentPayload)
            // console.log(updarePlayerPaymentStatusResponse, 'UPDATE PAYMENT STATUS RESPONSE');
            setIsLoading(false);
            if (res.statusCode === 201 || res.statusCode === 200) {
                toast({
                    title: 'Success!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                setFormData({ ..._formData });
                getTransactionsDataById(_id);
                getCategoryDataById(_id);
                onClose();
            }
        } catch (e) {
            setIsLoading(false);
            console.log(`ERROR WHILE FETCHING DATA:${e}`);

        }
    }

    // GET ALL CATEGORY TRANSECTIONS BY CATEGORY ID
    const getTransactionsDataById = async (_id) => {
        try {
            const response = await get(`transactionsCategory/transactions/${_id}`)

            if (response?.statusCode == 200) {
                setTransactionsData(response?.data?.data);
            }

        } catch (e) {
            console.log(`ERROR WHILE FETCHING DATA:${e}`);

        }
    }

    // GET ALL NOT PAID OFFLINE SUBSCRIPTIONS
    const getAllNotPaidOfflineSubscriptions = async () => {
        try {
            const response = await get(`academy-course/not-paid-offline-players`)
            if (response?.statusCode == 200) {
                setAllPendingSubs(response?.data?.data);
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING DATA:${e}`);

        }
    }


    return (
        <div className='p-2 lg:p-4'>
            {/* <CategoryHeader /> */}
            <button onClick={() => { navigate(-1) }} className="px-4 py-2 text-white bg-[#290dbd] rounded-md hover:bg-[#1d0a8c]">
                {t("back")}
            </button>
            {categoryData ?
                <div className='w-full flex items-start justify-between py-4 lg:py-6 '>
                    <div className='flex flex-col items-start gap-2'>
                        <h1 className='text-xl lg:text-3xl font-bold mb-4 lg:mb-2 capitalize'>Name : {categoryData?.name || '--'}</h1>
                        <h3 className='text-lg lg:text-xl font-semibold text-gray-500'>Total Earnings : {categoryData?.totalAmountGenerated?.toLocaleString('en-IN') || '--'}</h3>
                        <p className='text-sm w-full lg:w-10/12 lg:text-base text-gray-600'>Income Type : {categoryData?.incomeType || '--'} </p>
                        <p className='text-sm w-full lg:w-10/12 lg:text-base'>{categoryData?.description || '--'} </p>
                    </div>
                    <Button
                        variant="solid"
                        colorScheme='blue'
                        onClick={onOpen}
                    >
                        Add New
                    </Button>
                    <AddEarningsHistory
                        onOpen={onOpen}
                        isOpen={isOpen}
                        onClose={onClose}
                        formData={formData}
                        selectedSubs={selectedSubs}
                        setSelectedSubs={setSelectedSubs}
                        allPendingSubs={allPendingSubs}
                        onHandleChange={(field, value) => {
                            setFormData((prevData) => ({
                                ...prevData,
                                [field]: value
                            }));
                        }}
                        isLoading={isLoading}
                        onReset={() => {
                            setFormData({ ..._formData });
                            onClose();
                        }}
                        onHandleSubmit={() => addNewEarning(_id)}
                    />
                </div>
                :
                <div className='my-6'>
                    <CategoryDetailSkeleton />
                </div>
            }
            <div className="w-full flex flex-col items-start gap-3 flex-1 min-h-[50vh]">
                <Heading
                    title={t("earnings")}
                />
                <div className='w-full flex-1 flex flex-col '>
                    <TableComponent
                        columns={columns}
                        rows={transactionsData || []}
                    />
                </div>
            </div>
        </div>
    )
}

export default CategoryDetail

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArrowForwardIcon, InfoIcon } from '@chakra-ui/icons';
import { FiArrowUp } from 'react-icons/fi'; // Feather Icons
import { MdTrendingUp } from 'react-icons/md'; // Material Design Icons
import { AiFillCaretUp } from 'react-icons/ai'; // Ant Design Icons
import { FiArrowDown } from 'react-icons/fi'; // Feather Icons
import { MdTrendingDown } from 'react-icons/md'; // Material Design Icons
import { AiFillCaretDown } from 'react-icons/ai'; // Ant Design Icons
import { formatNumber } from "../../services/glocalFunctions";

// EXPENSE CARD 
// EXPENSE CARD
const CategoryExpenseCard = ({ expense, colors, isPer, onHandleClick, dynamicCategory }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const navigate = useNavigate();

    const totalValue = expense?.value || expense?.totalAmountGenerated || expense?.totalAmountExpensed || expense?.totalAmount || 0;
    return (
        <div
            onClick={() => {
                if (dynamicCategory) {
                    onHandleClick();
                    return
                }
                if (expense?.kind?.includes('additional-expenses')) {
                    navigate(`/account/additional-expenses`);
                } else {
                    navigate(`/account/expenses/${expense?.kind || expense?.name}`)
                }
            }}
            // style={{ backgroundColor: color }}
            style={{
                background: colors?.bg,
                // color: brColor,
            }}
            className={` transition-all duration-300  flex flex-col ${isArabic ? 'items-end justify-end ' : 'items-start justify-start'} hover:shadow-md gap-2 lg:gap-4 w-full py-6 px-5 rounded-xl mb-4`}
        >
            <div className={`w-full flex items-center ${isArabic ? 'justify-end' : 'justify-between'}`}>
                <div className={`flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} items-center  gap-2`}>
                    <div className='p-2 rounded-md bg-white text-red'>
                        {colors?.icon}
                    </div>
                    <h6 className={`text-sm whitespace-nowrap lg:text-base font-bold capitalize`}>
                        {isArabic && expense?.arabicName
                            ? expense?.arabicName
                            : t(expense?.label?.toLowerCase() || expense?.name?.toLowerCase())}
                    </h6>
                </div>
                {/* {isPer ?
                    <div className='py-2 px-4 flex items-center gap-2 rounded-full bg-white text-[#40cc73] text-[#f15655]'>
                        <MdTrendingUp />
                        <MdTrendingDown color="#40cc73" />
                        <h6 className="font-bold text-xs">
                            5.2%
                        </h6>
                    </div>
                    : null} */}
            </div>
            <h4
                className={`text-xl lg:text-2xl font-bold ${isArabic ? 'text-right' : 'text-left'}`} // Align the amount based on language
                dir={isArabic ? 'rtl' : 'ltr'} // Set text direction based on language
            >
                <span className="text-lg lg:text-lg">
                    {isArabic ? 'ر.س' : 'SAR'}
                </span>{' '}
                {formatNumber(totalValue, isArabic)}
            </h4>
            {/* <div className="w-full flex items-center  gap-2">
                <ArrowForwardIcon style={{ rotate: '-45deg' }} w={3} color="gray.500" />
                <h6 className="text-sm whitespace-nowrap lg:text-sm text-gray-600 font-medium">Since last year</h6>
            </div> */}
        </div>
    )
}

export default CategoryExpenseCard 
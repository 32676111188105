import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import { DownloadIcon } from '@chakra-ui/icons';
import { get, post } from '../../services/apis/api';
import { useNavigate } from 'react-router-dom';
import PastTransactions from '../../components/account/PastTransactions';
import { Button, Skeleton, useDisclosure, useToast } from '@chakra-ui/react';
import AddExpenseCategoryModel from '../../components/account/AddExpenseCategory';
import AccordianComponent from '../../components/account/AccordianComponent';
import ExpenseCard from './ExpenseCard';
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { FaUserFriends } from "react-icons/fa";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { IoGameControllerOutline } from "react-icons/io5";
import { IoTrophyOutline } from "react-icons/io5";
import { FaChartPie } from 'react-icons/fa'; // Font Awesome
import BreadCrumbs from '../../components/BreadCrumbs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ConfirmDownload from '../../components/dialogueBoxes/ConfirmDownload';


// ADD CATEGORY FORM DATA 
const _expenseFormData = {
    name: '',
    arabicName: '',
    description: ''
};

const gameColors = [
    {
        bg: '#f4f0ff', // Soft pastel blue
        colo: '#7c3aed',
        brder: '#7dd3fc', // Sky blue
        icon: '#e32952',
        icon: <IoGameControllerOutline color='#7c3aed' />
    },
    {
        bg: '#eaf1ff', // Very light pink
        color: '#5249e5',
        brder: '#f472b6', // Rose pink
        icon: '#e32952',
        icon: <FaUserFriends color="#5249e5" />
    },
    {
        bg: '#eaf5fe', // Light pastel gray-blue
        color: '#5e8cf0',
        brder: '#64748b', // Muted slate blue
        icon: '#e32952',
        icon: <IoTrophyOutline color='#5e8cf0' />
    },
];

const directExpensesColors = [
    // {
    //     bgColor: "#f0f9ff", // Light blue shade
    //     color: "#0369a1",   // Deep blue for contrast
    // },
    {
        bg: "#ffeaeb", // Light green shade
        color: "#065f46",   // Dark green for contrast
        iconColor: '#e32952',
        icon: <FaUserFriends color='#e32952' />
    },
    {
        bg: "#fff5e1", // Light yellow shade
        color: "#854d0e",   // Rich brown for text
        iconColor: '#f29c6d',
        icon: <FaMoneyCheckAlt color="#f29c6d" />
    },
    {
        bg: "#e2fcef", // Light red shade
        color: "#991b1b",   // Dark red for text
        iconColor: '#059669',
        icon: <HiOutlineBuildingOffice2 color='#059669' />
    },
];

const indirectExpenseColors = [
    {
        bg: "#eef2ff", // Light lavender
        color: "#4338ca",   // Deep indigo
        iconColor: '#e32952',
        icon: <FaChartPie color='#c6d4ff ' />
    },
    {
        bg: "#fef9c3", // Pale yellow
        color: "#854d0e",   // Rich brown
        iconColor: '#e32952',
        icon: <FaChartPie color='#e0d200' />
    },
    {
        bg: "#f0fdf4", // Mint green
        color: "#15803d",   // Forest green
        iconColor: '#e32952',
        icon: <FaChartPie color='#c1e7d2' />
    },
    {
        bg: "#fff7ed", // Soft peach
        color: "#c2410c",   // Burnt orange
        iconColor: '#e32952',
        icon: <FaChartPie color='#f4e2c0' />
    },
];

const Expenses = () => {
    const { user } = UserState();
    const toast = useToast();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [isLoading, setIsLoading] = useState(false);
    const [expenseFormData, setExpenseFormData] = useState({ ..._expenseFormData });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [expensesData, setExpensesData] = React.useState(null);
    const [gamesExpensesData, setGamesExpensesData] = React.useState(null);
    const [directExpensesData, setdirectExpensesData] = React.useState(null);
    const [totalExpensesData, setTotalExpensesData] = React.useState(null);
    const [additionalExpensesData, setAdditionalExpensesData] = React.useState(null);
    const [expenseCategories, setExoenseCategories] = React.useState(null);
    const [loading, setLoading] = useState(false);
    const [downloadOpen, setDownloadOpen] = useState(false);  // for confirmation dialog

    // GET ALL EXEPENSE DATA 
    const getTotalExpenses = async () => {
        try {
            // console.log('data');
            const response = await get(`expenses/get-total`)
            if (response?.statusCode == 200) {
                // console.log(response, 'response');
                setExpensesData(response?.data?.data);
                setdirectExpensesData(response?.data?.data?.directExpenses);
                setGamesExpensesData(response?.data?.data?.gamerelated);
                setTotalExpensesData(response?.data?.data?.total);
                setAdditionalExpensesData(response?.data?.data?.additional);
                setExoenseCategories(response?.data?.data?.indirectExpenses);
            } else {
                setExpensesData([]);
            }
        } catch (e) {
            setExpensesData([]);
            console.log(`ERROR WHILE FETCHING DATA:${e}`);
        }
    }

    useEffect(() => {
        getTotalExpenses();
    }, [])

    // CREATE A NEW EXPENSE CATEGORY 
    const addNewCategory = async () => {
        try {
            setIsLoading(true);
            const res = await post(`expense-category/add`, expenseFormData)
            setIsLoading(false);
            if (res.statusCode === 201 || res.statusCode === 200) {
                getTotalExpenses();
                toast({
                    title: 'Success!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                setExpenseFormData({ ..._expenseFormData });
                onClose();
            }
        } catch (error) {
            setIsLoading(false);
            toast({
                title: 'Error!',
                description: error.response.data.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            console.log(`ERROR WHILE CREATING CATEGORY: ${error}`);
        }
    };

    const ReyalIcon = () => (
        <div className='p-1 rounded-md bg-white text-black'>
            ریال
        </div>
    )

    // DOWNLOAD FULL TOTAL EXPENSES PAGE AS PDF
    const downloadFullPageAsPdf = async () => {
        try {
            setLoading(true);
            const pageElement = document.getElementById('expense-total'); // Target the full page

            const canvas = await html2canvas(pageElement, {
                scale: 2, // High-quality output
                useCORS: true, // Handle cross-origin images
            });

            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = 210; // A4 width in mm
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

            // Split content into pages if necessary
            let currentHeight = 0;
            while (currentHeight < canvas.height) {
                const sourceCanvas = document.createElement('canvas');
                const context = sourceCanvas.getContext('2d');
                sourceCanvas.width = canvas.width;
                sourceCanvas.height = pdf.internal.pageSize.height * (canvas.width / pdfWidth);

                context.drawImage(
                    canvas,
                    0,
                    currentHeight,
                    canvas.width,
                    sourceCanvas.height,
                    0,
                    0,
                    sourceCanvas.width,
                    sourceCanvas.height
                );

                const pageImage = sourceCanvas.toDataURL('image/png');
                pdf.addImage(pageImage, 'PNG', 0, 0, pdfWidth, pdfHeight);

                currentHeight += sourceCanvas.height;
                if (currentHeight < canvas.height) pdf.addPage();
            }

            pdf.save('TotalExpenses.pdf');
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log(`ERROR WHILE DOWNLOADING TOTAL EXPENSES ${e}`);
        }
    };

    // FUNCTION TO DOWNLOAD THE REPORT AS PDF
    const downloadReport = () => {
        setIsLoading(true);
        try {
            const doc = new jsPDF();

            doc.setFontSize(14);
            doc.text(`Expenses`, 14, 20); // Heading at the top, left-aligned

            // Define the starting x and y positions
            const startX = 14; // Fixed left margin
            const lineHeight = 10; // Space between lines
            const pageWidth = doc.internal.pageSize.width; // Width of the page
            const marginRight = 14; // Right margin
            const contentWidth = pageWidth - startX - marginRight; // Usable content width

            doc.setFontSize(12);
            // Render the texts side by side, left-aligned
            doc.text(`Total Expenses: ${totalExpensesData?.value}`, startX, 30);
            doc.text(`Additional Expenses: ${additionalExpensesData?.value}`, startX + 100, 30); // Adjust spacing (100) as needed

            // Direct Expenses Section
            doc.setFontSize(14);
            doc.text(`Direct Expenses`, 14, 50); // Heading at the top, left-aligned

            let currentX = startX; // Reset currentX for the first item
            let currentY = 60; // Start below the "Direct Expenses" heading

            for (let i = 0; i < directExpensesData?.length; i++) {
                const record = directExpensesData[i];
                const text = `${record?.label}: ${record?.value}`;
                const textWidth = doc.getTextWidth(text);

                // Check if the current text exceeds the content width
                if (currentX + textWidth > contentWidth) {
                    currentX = startX; // Reset to the left margin
                    currentY += lineHeight; // Move to the next line
                }
                doc.setFontSize(12);
                // Render the text
                doc.text(text, currentX, currentY);
                currentX += textWidth + 10; // Add spacing between items
            }

            // Game Expenses Section
            doc.setFontSize(14);
            currentY += lineHeight + 10; // Add spacing after the previous section
            doc.text(`Game Expenses`, 14, currentY); // Heading at the top, left-aligned

            currentX = startX; // Reset currentX for the new section
            currentY += lineHeight; // Start below the "Game Expenses" heading

            for (let i = 0; i < gamesExpensesData?.length; i++) {
                const record = gamesExpensesData[i];
                const text = `${record?.label}: ${record?.value}`;
                const textWidth = doc.getTextWidth(text);

                // Check if the current text exceeds the content width
                if (currentX + textWidth > contentWidth) {
                    currentX = startX; // Reset to the left margin
                    currentY += lineHeight; // Move to the next line
                }

                doc.setFontSize(12);
                // Render the text
                doc.text(text, currentX, currentY);
                currentX += textWidth + 10; // Add spacing between items
            }

            // Indirect Expenses Section
            doc.setFontSize(14);
            currentY += lineHeight + 10; // Add spacing after the previous section
            doc.text(`Indirect Expenses`, 14, currentY); // Heading at the top, left-aligned

            currentX = startX; // Reset currentX for the new section
            currentY += lineHeight; // Start below the "Indirect Expenses" heading

            for (let i = 0; i < expenseCategories?.length; i++) {
                const record = expenseCategories[i];
                const text = `${record?.label}: ${record?.totalAmount}`;
                const textWidth = doc.getTextWidth(text);

                // Check if the current text exceeds the content width
                if (currentX + textWidth > contentWidth) {
                    currentX = startX; // Reset to the left margin
                    currentY += lineHeight; // Move to the next line
                }

                doc.setFontSize(12);
                // Render the text
                doc.text(text, currentX, currentY);
                currentX += textWidth + 10; // Add spacing between items
            }

            // Save the PDF
            doc.save('expenses_report.pdf');
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            console.error(`ERROR WHILE DOWNLOADING: ${e}`);
        }
    };

    // CONFIRM DOWNLOAD FUNCTION 
    const confirmDownload = () => {
        try {
            downloadReport();
            // downloadFullPageAsPdf();
            setDownloadOpen(false);
        } catch (e) {
            console.log(`ERROR WHILE GENERATING PDF ${e}`);
        }
    };

    return (
        <>
            <div className='w-full flex-1 flex flex-col p-2 lg:p-4'>
                <BreadCrumbs />
                <div id="expense-total" className='w-full flex flex-col'>
                    <div className={`flex justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                        <h2 className='text-xl lg:text-2xl whitespace-nowrap font-bold mb-4'>{t("expenses")}</h2>
                        {/* Download Confirmation Dialog */}
                        <ConfirmDownload
                            isOpen={downloadOpen}
                            label='download-report'
                            setIsOpen={setDownloadOpen}
                            handleDownload={() => {
                                setDownloadOpen(true);
                            }}
                            confirmDownload={() => {
                                confirmDownload();
                            }}
                            closeDialog={() => {
                                setDownloadOpen(false);
                            }}
                            loading={loading}
                        />
                        <AddExpenseCategoryModel
                            isOpen={isOpen}
                            onClose={onClose}
                            expenseFormData={expenseFormData}
                            onHandleChange={(field, value) => {
                                setExpenseFormData((prevData) => ({
                                    ...prevData,
                                    [field]: value
                                }));
                            }}
                            isLoading={isLoading}
                            onReset={() => {
                                setExpenseFormData({ ..._expenseFormData });
                                onClose();
                            }}
                            onHandleSubmit={() => {
                                addNewCategory();
                            }}
                        />
                    </div>
                    {/* TOTAL CARDS  */}
                    <div className={`w-full flex ${isArabic ? 'flex-row-reverse' : 'flex-row'}  gap-3 lg:gap-4`}>
                        {totalExpensesData ?
                            <div className='min-w-full lg:min-w-[32%]'>
                                <ExpenseCard
                                    expense={totalExpensesData}
                                    color={"#fef2f2"}
                                    brColor={"#ef4444"}
                                    icon={<ReyalIcon />}
                                />
                            </div>
                            :
                            <Skeleton
                                height={150}
                                width={'30%'}
                                isLoaded={false}
                                fadeDuration={1}
                            />
                        }
                        {additionalExpensesData ?
                            <div className='min-w-full lg:min-w-[32%]'>
                                <ExpenseCard
                                    expense={additionalExpensesData}
                                    color={"#faf5ff"}
                                    brColor={"#a855f7"}
                                    icon={<ReyalIcon />}
                                />
                            </div>
                            :
                            <Skeleton
                                height={150}
                                width={'30%'}
                                isLoaded={false}
                                fadeDuration={1}
                            />
                        }
                    </div>

                    <div className="w-full flex flex-col justify-start py-4 gap-2 lg:gap-4 items-start mb-4">
                        <AccordianComponent
                            options={directExpensesData || []}
                            label="direct-expenses"
                            cardColors={directExpensesColors}
                        />
                        <AccordianComponent
                            options={gamesExpensesData || []}
                            label="game-expenses"
                            cardColors={gameColors}
                        />
                        <AccordianComponent
                            options={expenseCategories || []}
                            label="indirect-expenses"
                            onOpenDialogue={onOpen}
                            cardColors={indirectExpenseColors}
                        />
                    </div>
                </div>

                {/* PAST DR TRANSACTIONS  */}
                <PastTransactions
                    txnType={'DR'}
                />
            </div>
        </>
    )
}

export default Expenses

import { useTranslation } from "react-i18next";
import { UserState } from "../../context/user";
import { useEffect, useState } from "react";
import { Button, useToast } from '@chakra-ui/react';
import { get } from "../../services/apis/api";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs";
import { PiFileText } from "react-icons/pi";
import { FiUserCheck, FiUsers } from "react-icons/fi";
import { CiCalendar } from "react-icons/ci";
import { LuBuilding2, LuReceipt } from "react-icons/lu";
import { MdOutlineChevronRight } from "react-icons/md";
import { formatDateArEn, getStatusColor } from "../../services/glocalFunctions";
import { CorporateField } from "../../components/clinic/ClinicComponent";

const CorporateRequests = () => {
    const { user } = UserState();
    const navigate = useNavigate();
    const toast = useToast();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [searchTerm, setSearchTerm] = useState('');
    const [requests, setRequests] = useState(null);

    // GET ALL AVAILABLE REQUEST BY ID
    const getAllRequest = async () => {
        try {
            if (user) {
                const res = await get(`clinic/all/corporate-requests`);
                if (res?.statusCode === 200) {
                    setRequests(res?.data?.corporateRequestst || []);
                } else {
                    setRequests([]);
                }
            }
        } catch (error) {
            setRequests([]);
            console.log(`ERROR WHILE FETCHING CORPORATE REQUESTST ${error}`);
        }
    };

    useEffect(() => {
        getAllRequest();
    }, [])

    return (
        <>
            <BreadCrumbs />
            <div className='w-full flex-1 flex flex-col p-2 gap-4'>
                {/* {HEADER PART} */}
                <div className={`flex w-full flex-col items-start gap-4`}>
                    <div className={`w-full flex flex-col items-start gap-2`}>
                        <h2 className='text-xl whitespace-nowrap font-bold'>{t("corporate-requests")}</h2>
                        <p className="text-gray-600 text-sm">Manage and review treatment requests from corporates/organizations</p>
                    </div>
                    <div className={`flex w-full justify-between ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                        <div className='hidden w-full justify-between lg:w-fit items-center'>
                            <div className="relative">
                                <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    id="table-search-users"
                                    className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                                    placeholder={t("search-request")}
                                    value={searchTerm}
                                    onChange={(e) => { setSearchTerm(e.target.value) }}
                                />
                            </div>
                        </div>
                        {/* <Button
                            colorScheme="blue"
                            leftIcon={<PiFileText />}
                        >
                            Export
                        </Button> */}
                    </div>
                </div>
                {/* LIST PART  */}
                <div className='w-full flex-1 flex flex-col gap-4 '>
                    {/* Requests List */}
                    <div className="space-y-4">
                        {requests?.map((request, index) => (
                            <div
                                key={request?._id || index}
                                className="bg-white rounded-xl border border-gray-200 p-6 hover:shadow-md transition-all duration-200 cursor-pointer"
                                onClick={() => {
                                    navigate(`/clinic/corporate-requests/${request?._id}`)
                                }}
                            >
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <div className="bg-blue-50 p-3 rounded-lg">
                                            <LuBuilding2 className="w-6 h-6 text-blue-600" />
                                        </div>
                                        <div>
                                            <h3 className="text-lg font-semibold text-gray-900">{request?.organizationName || '--'}</h3>
                                            <div className="flex items-center space-x-4 mt-1">
                                                <span className="text-sm text-gray-600">{t('id')}: {request?.corporateId || '--'}</span>
                                                <span className="text-sm text-gray-600">{request?.location || '--'}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center space-x-6">
                                        {request?.status?.toLowerCase() == 'approved' &&
                                            <div className="text-right">
                                                <div className="text-sm font-medium text-gray-900">{t('wallet-balance')}</div>
                                                <div className={`text-lg font-semibold ${request.walletBalance < 0 ? 'text-red-600' : 'text-green-600'}`}>
                                                    {/* // change the icon into reyal */}
                                                    ${request.walletBalance.toLocaleString()}
                                                </div>
                                            </div>
                                        }
                                        <div className="flex items-center space-x-3">
                                            <span className={`h-fit p-2 px-4 flex items-center justify-center text-xs font-semibold rounded-full ${getStatusColor(request?.status)}`}>
                                                {t(request?.status?.toLowerCase()) || '--'}
                                            </span>
                                            <MdOutlineChevronRight className="w-5 h-5 text-gray-400" />
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4 grid grid-cols-4 gap-4">
                                    <CorporateField
                                        title="employees"
                                        icon={<FiUsers className="w-4 h-4 text-gray-400" />}
                                        value={request?.employeeCount}
                                    />
                                    <CorporateField
                                        title="since"
                                        icon={<CiCalendar className="w-4 h-4 text-gray-400" />}
                                        value={formatDateArEn(request?.createdAt, isArabic)}
                                    />
                                    <CorporateField
                                        title="treatment-requests"
                                        icon={<LuReceipt className="w-4 h-4 text-gray-400" />}
                                        value={request?.treatmentRequests}
                                    />
                                    <CorporateField
                                        title="active-patients"
                                        icon={<FiUserCheck className="w-4 h-4 text-gray-400" />}
                                        value={request?.activePatients}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
export default CorporateRequests;
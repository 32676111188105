import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Flex,
    Button,
    Text,
    Badge,
    useColorModeValue,
    Divider,
    Heading,
    InputGroup,
    Avatar,
    Grid,
    GridItem,
    useToast,
} from '@chakra-ui/react';
import { get } from '../../services/apis/api';
import { UserState } from '../../context/user';
import DatePicker from 'react-datepicker';
import { CiCalendarDate } from 'react-icons/ci';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useTranslation } from 'react-i18next';
import { formatDate, formatPayrollMonth } from '../../services/glocalFunctions';
import ConfirmDownload from '../../components/dialogueBoxes/ConfirmDownload';

const Payroll = () => {
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const { user } = UserState();
    const sectionBg = useColorModeValue('gray.100', 'gray.800');
    const textColor = useColorModeValue('gray.700', 'gray.200');
    const [payrolls, setPayrolls] = useState([]);
    const [downloadOpen, setDownloadOpen] = useState(false);  // for confirmation dialog
    const hasFetched = useRef(false); // Initially false
    // State for start date and current dates
    const [startDate, setStartDate] = useState(() => {
        const currentDate = new Date();
        const previousMonth = currentDate.getMonth() === 0 ? 11 : currentDate.getMonth() - 1;
        const year = currentDate.getMonth() === 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
        return new Date(year, previousMonth, 1); // Default to the first day of the previous month
    });
    const [currentDates, setCurrentDates] = useState(() => {
        const currentDate = new Date();
        const previousMonth = currentDate.getMonth() === 0 ? 11 : currentDate.getMonth() - 1;
        const year = currentDate.getMonth() === 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
        return {
            month: previousMonth + 1, // Convert 0-based to 1-based
            year: year,
        };
    });

 

    // FETCH PAYROLL DATA 
    const getPayrollData = async (month, year) => {
        try {
            const res = await get(`payroll/employee/all?payrollMonth=${month}&payrollYear=${year}`);
            if (res.statusCode === 200) {
                setPayrolls(res?.data?.data || []);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!hasFetched.current) {
            getPayrollData(currentDates?.month, currentDates?.year);
            hasFetched.current = true; // Mark as fetched
        }
    }, []);
    // FETCH PAYROLL DATA 

    const lastMonthPayroll = payrolls[0];

    const generatePayslipPdf = () => {
        try {
            if (!lastMonthPayroll) {
                alert('No payroll data available to download.');
                return;
            }

            const doc = new jsPDF();
            const payrollDetails = [
                ['Paid Days', lastMonthPayroll?.paidDays],
                ['Payable Days', lastMonthPayroll?.payableDays],
                ['Leave Days', lastMonthPayroll?.paidLeaves],
                ['Absent Days', lastMonthPayroll?.unpaidLeaves],
                ['Gross Salary', `$${lastMonthPayroll?.salaryBreakdown?.grossPay?.toFixed(2)}`],
                ['Basic Pay', `$${lastMonthPayroll?.salaryBreakdown?.basicSalary?.toFixed(2)}`],
                ['Allowances', `$${lastMonthPayroll?.salaryBreakdown?.specialAllowance?.toFixed(2)}`],
                ['Deductions', `$${lastMonthPayroll?.deductions?.totalDeductions?.toFixed(2)}`],
                ['Overtime Pay', `$${lastMonthPayroll?.salaryBreakdown?.variablePay?.toFixed(2)}`],
                ['Bonus', `$${lastMonthPayroll?.salaryBreakdown?.bonus?.toFixed(2)}`],
                ['Net Pay', `$${lastMonthPayroll?.salaryBreakdown?.netPay?.toFixed(2)}`],
                ['Payment Status', lastMonthPayroll?.paymentStatus],
            ];

            doc.setFontSize(18);
            doc.text('Payslip', 14, 20);

            doc.setFontSize(14);
            doc.text(`Employee Name: ${user?.name}`, 14, 30);
            doc.text(`Employee Code: ${user?.employeeCode}`, 14, 37);
            doc.text(`Designation: ${user?.designation}`, 14, 44);
            doc.text(`Payroll Month: ${formatDate(lastMonthPayroll?.payrollMonth)}`, 14, 51);


            doc.autoTable({
                startY: 60,
                head: [['Detail', 'Value']],
                body: payrollDetails,
            });

            doc.save(`Payslip_${formatPayrollMonth(lastMonthPayroll?.payrollMonth, isArabic)}.pdf`);
        } catch (e) {
            console.log(`ERROR WHILE GENERATING PAYROLL PDF ${e}`);
        }
    };

    // HANDLE DATE CHANGE FUNCTION 
    const handleDateChange = (date) => {
        try {
            if (date instanceof Date && !isNaN(date)) {
                const currentDate = new Date(); // Get the current date
                const selectedMonth = date.getMonth(); // Get the selected month
                const selectedYear = date.getFullYear(); // Get the selected year

                const currentMonth = currentDate.getMonth(); // Get the current month
                const currentYear = currentDate.getFullYear(); // Get the current year

                // Check if the selected date is in the current or future month
                if (
                    selectedYear > currentYear ||
                    (selectedYear === currentYear && selectedMonth >= currentMonth)
                ) {
                    toast({
                        title: "Warning",
                        description: "You cannot select the current or a future month.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    getPayrollData(selectedMonth + 1, selectedYear)
                    // Update state with the selected date and currentDates
                    setStartDate(new Date(selectedYear, selectedMonth, 1)); // Set to the 1st day of the selected month
                    setCurrentDates({
                        month: selectedMonth + 1, // Convert 0-based to 1-based
                        year: selectedYear,
                    });
                }
            } else {
                console.log("Invalid date selected");
            }
        } catch (e) {
            console.log(`ERROR WHILE SETTING DATE ${e}`);
        }
    };

    // Confirm download
    const confirmDownload = () => {
        try {
            generatePayslipPdf();
            setDownloadOpen(false);
        } catch (e) {
            console.log(`ERROR WHILE GENERATING PDF ${e}`);
        }
    };

    const formatNumberWithCurrency = (amount, isArabic) => {
        const formattedAmount = amount?.toFixed(2);
        const currency = isArabic ? "ر.س" : "SAR";

        // Format based on language
        return isArabic ? `${formattedAmount} ${currency}` : `${currency} ${formattedAmount}`;
    };

    return (
        <Box width="100%" p={4}>
            <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} justify="space-between" align="center" mb={6} wrap={'wrap'}>
                <Heading size="lg">{t('payroll')}</Heading>
                <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} align="center" gap={2} wrap={'wrap'}>
                    <InputGroup position="relative" w="full" maxW="240px">
                        <DatePicker
                            selected={startDate}
                            onChange={handleDateChange}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            placeholderText={t('select-month')}
                            className="border rounded-md p-2 w-[240px]"
                        />
                        <CiCalendarDate className="absolute right-3 top-1/2 transform -translate-y-1/2 text-xl text-gray-500 pointer-events-none" />
                    </InputGroup>
                    {/* Download Confirmation Dialog */}
                    <ConfirmDownload
                        isOpen={downloadOpen}
                        label="download-payslip"
                        setIsOpen={setDownloadOpen}
                        handleDownload={() => {
                            setDownloadOpen(true);
                        }}
                        confirmDownload={() => {
                            confirmDownload();
                        }}
                        closeDialog={() => {
                            setDownloadOpen(false);
                        }}
                    />
                </Flex>
            </Flex>

            {/* Employee Profile Card */}
            <Box bg={"sectionBg"} p={3} rounded="lg" mb={6} shadow="sm">
                <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} gap={4} align="center">
                    <Avatar src={user?.pic} name={user?.name} size="lg" />
                    <Box>
                        <Text textAlign={isArabic ? "right" : 'left'} dir={isArabic ? 'rtl' : 'ltr'} fontSize="lg" fontWeight="bold" color={textColor}>{user?.name || '--'}</Text>
                        <Text textAlign={isArabic ? "right" : 'left'} dir={isArabic ? 'rtl' : 'ltr'} color="gray.500">{t('employee-code')}: {user?.employeeCode || '--'}</Text>
                        <Text textAlign={isArabic ? "right" : 'left'} dir={isArabic ? 'rtl' : 'ltr'} color="gray.500">{t('designation')}: {user?.designation || '--'}</Text>
                    </Box>
                </Flex>
            </Box>

            {!lastMonthPayroll ? (
                <Text textAlign="center" fontSize="lg" color="gray.500">
                    {t('no-data-found')}
                </Text>
            ) : (
                <Box mb={8} p={6} shadow="md" borderWidth="1px" borderRadius="lg" bg="white">
                    <Text textAlign={isArabic ? 'right' : 'left'} fontSize="xl" fontWeight="bold" mb={4} color="blue.600">
                        {t('last-month-payroll')} - {lastMonthPayroll?.payrollMonth ? formatPayrollMonth(lastMonthPayroll?.payrollMonth, isArabic) : '--'}
                    </Text>
                    <Divider mb={4} />
                    {/* Payroll Details Grid */}
                    <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
                        <GridItem>
                            <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">{t('paid-days')}</Text>
                                <Text color="gray.800">{lastMonthPayroll?.paidDays}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">{t('payable-days')}</Text>
                                <Text color="gray.800">{lastMonthPayroll?.payableDays}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">{t('leave-days')}</Text>
                                <Text color="gray.800">{lastMonthPayroll?.paidLeaves}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">{t('absent-days')}</Text>
                                <Text color="gray.800">{lastMonthPayroll?.unpaidLeaves}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">{t('gross-salary')}</Text>
                                <Text color="gray.800">{lastMonthPayroll?.salaryBreakdown?.grossPay}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">{t('basic-pay')}</Text>
                                <Text color="gray.800">{lastMonthPayroll?.salaryBreakdown?.basicSalary?.toFixed(2)}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">{t('allowances')}</Text>
                                <Text color="gray.800">{lastMonthPayroll?.salaryBreakdown?.specialAllowance?.toFixed(2)}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">{t('deductions')}</Text>
                                <Text color="gray.800">{lastMonthPayroll?.deductions?.totalDeductions?.toFixed(2)}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">{t('overtime-pay')}</Text>
                                <Text color="gray.800">{lastMonthPayroll?.salaryBreakdown?.variablePay?.toFixed(2)}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">{t('bonus')}</Text>
                                <Text color="gray.800">{lastMonthPayroll?.salaryBreakdown?.bonus?.toFixed(2)}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem colSpan={{ base: 1, md: 2 }}>
                            <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} justify="space-between">
                                <Text fontWeight="semibold" fontSize="lg" color="blue.700">{t('net-pay')}</Text>
                                <Text fontSize="lg" fontWeight="bold" color="blue.700">{lastMonthPayroll?.salaryBreakdown?.netPay?.toFixed(2)}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem colSpan={{ base: 1, md: 2 }}>
                            <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">{t('payment-status')}</Text>
                                <Badge colorScheme={lastMonthPayroll?.paymentStatus === 'Paid' ? 'green' : 'red'}>
                                    {t(lastMonthPayroll?.paymentStatus?.toLowerCase())}
                                </Badge>
                            </Flex>
                        </GridItem>
                    </Grid>
                </Box>
            )}
        </Box>
    );
};

export default Payroll;

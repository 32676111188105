
import React, { useEffect, useRef, useState } from 'react';
import { CiCalendar, CiClock1, CiStar, CiStethoscope, CiUser, CiWallet } from 'react-icons/ci';
import { FaDollarSign, FaRegMoneyBillAlt } from 'react-icons/fa';
import { FiActivity, FiRefreshCcw, FiUsers } from "react-icons/fi";
import { GoCheckCircle, GoXCircle } from 'react-icons/go';
import { LuBuilding2, LuCreditCard } from 'react-icons/lu';
import { MdOutlineFileDownload } from 'react-icons/md';
import { RiDownloadCloud2Line } from 'react-icons/ri';
import { UserState } from '../../context/user';
import { Button, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AnalyticHeader, AnlyticCard, DashboardListCard, DashboardTotalCard } from '../../components/clinic/ClinicComponent';
import { LuCircleDollarSign } from "react-icons/lu";
import { CiReceipt } from "react-icons/ci";
import { FiAlertOctagon } from "react-icons/fi";
import { DownloadIcon } from '@chakra-ui/icons';
import { BsArrowDownRight, BsArrowUpRight } from 'react-icons/bs';
import { TbArrowDownRight, TbArrowUpRight } from 'react-icons/tb';
import { LuClipboardList } from "react-icons/lu";
import { LiaHourglassEndSolid } from "react-icons/lia";
import { get } from '../../services/apis/api';
import { formatTimeArEn } from '../../services/glocalFunctions';

const mockData = {
    metrics: {
        totalPatients: 1247,
        totalDoctors: 32,
        totalAppointments: 856,
        pendingPayments: { amount: 45600, count: 23 },
        corporateAccounts: 15,
        // New financial metrics
        totalRevenue: 256800,
        monthlyRevenue: 45200,
        pendingAmount: 12500,
        revenueGrowth: 12.5
    },
    treatmentStatus: {
        open: 0,
        "in-progress": 0,
        closed: 0,
        "pending_approval": 0,
        approved: 0,
        rejected: 0
    },
    upcomingAppointments: [
        { doctor: "Dr. Sarah Wilson", time: "10:30 AM", patient: "John Doe", status: "Confirmed", fee: 150 },
        { doctor: "Dr. Michael Chen", time: "11:45 AM", patient: "Emma Smith", status: "Pending", fee: 200 },
        { doctor: "Dr. Lisa Kumar", time: "2:15 PM", patient: "Robert Brown", status: "Confirmed", fee: 175 }
    ],
    activeDoctors: [
        { name: "Dr. Sarah Wilson", specialty: "Cardiology", availableSlots: 5, revenue: 12500 },
        { name: "Dr. Michael Chen", specialty: "Orthopedics", availableSlots: 3, revenue: 15800 },
        { name: "Dr. Lisa Kumar", specialty: "Pediatrics", availableSlots: 4, revenue: 9600 }
    ],
    topPatients: [
        { name: "John Doe", visits: 12, lastVisit: "2024-02-28", totalSpent: 2400 },
        { name: "Emma Smith", visits: 8, lastVisit: "2024-03-01", totalSpent: 1800 },
        { name: "Robert Brown", visits: 7, lastVisit: "2024-03-05", totalSpent: 1500 }
    ],
    // New payment analytics
    paymentAnalytics: {
        pending: 12500,
        overdue: 8200,
        collected: 45600,
        // refunded: 1200
    }
};

// STYLES FOR PAYMENT ANLYTICS CARD 
const paymentConfig = {
    collected: {
        icon: LuCircleDollarSign,
        color: '#10B981',
        bgColor: 'bg-emerald-500',
        lightColor: 'bg-emerald-50',
        textColor: 'text-emerald-700',
        borderColor: 'border-emerald-200',
        label: 'Collected',
        description: 'Successfully processed payments'
    },
    pending: {
        icon: CiReceipt,
        color: '#F59E0B',
        bgColor: 'bg-amber-500',
        lightColor: 'bg-amber-50',
        textColor: 'text-amber-700',
        borderColor: 'border-amber-200',
        label: 'Pending',
        description: 'Awaiting payment processing'
    },
    overdue: {
        icon: FiAlertOctagon,
        color: '#EF4444',
        bgColor: 'bg-red-500',
        lightColor: 'bg-red-50',
        textColor: 'text-red-700',
        borderColor: 'border-red-200',
        label: 'Overdue',
        description: 'Past payment deadline'
    },
    refunded: {
        icon: FiRefreshCcw,
        color: '#6B7280',
        bgColor: 'bg-gray-500',
        lightColor: 'bg-gray-50',
        textColor: 'text-gray-700',
        borderColor: 'border-gray-200',
        label: 'Refunded',
        description: 'Returned payments'
    }
};

// STYLES FOR REQUEST STATUS ANLYTICS CARD 
const statusConfig = {
    pending_approval: {
        icon: LuClipboardList,
        color: '#F59E0B',
        bgColor: 'bg-yellow-500',
        lightColor: 'bg-yellow-50',
        textColor: 'text-yellow-700',
        borderColor: 'border-yellow-200',
        label: 'Pending Review',
        description: 'Request created but not yet approved for treatment'
    },
    open: {
        icon: LuClipboardList,
        color: 'orange',
        bgColor: 'bg-orange-500',
        lightColor: 'bg-orange-50',
        textColor: 'text-orange-700',
        borderColor: 'border-orange-200',
        label: 'Open Request',
        description: 'Treatment request approved, no appointment scheduled yet'
    },
    "in-progress": {
        icon: LiaHourglassEndSolid,
        color: '#3B82F6',
        bgColor: 'bg-blue-500',
        lightColor: 'bg-blue-50',
        textColor: 'text-blue-700',
        borderColor: 'border-blue-200',
        label: 'In Progress',
        description: 'Treatment in progress, the patient is being treated'
    },
    closed: {
        icon: GoCheckCircle,
        color: '#10B981',
        bgColor: 'bg-gray-500',
        lightColor: 'bg-gray-50',
        textColor: 'text-gray-700',
        borderColor: 'border-gray-200',
        label: 'Completed',
        description: 'Treatment completed, no further action required'
    },
    approved: {
        icon: GoCheckCircle,
        color: '#10B981',
        bgColor: 'bg-emerald-500',
        lightColor: 'bg-emerald-50',
        textColor: 'text-emerald-700',
        borderColor: 'border-emerald-200',
        label: 'Approved',
        description: 'Treatment request has been approved and will proceed'
    },
    rejected: {
        icon: GoXCircle,
        color: '#EF4444',
        bgColor: 'bg-red-500',
        lightColor: 'bg-red-50',
        textColor: 'text-red-700',
        borderColor: 'border-red-200',
        label: 'Rejected',
        description: 'Treatment request has been declined and will not proceed'
    }
};

const ClinicDashboard = () => {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const toast = useToast();
    const isArabic = i18n.language === 'ar';
    const [timeRange, setTimeRange] = useState('week');
    const [dashboardData, setDashboardData] = useState(null);

    // Enhanced metrics data
    const keyMetrics = {
        totalRevenue: 12350,
        monthlyRevenue: 5100,
        pendingAmout: 1700,
        revenueGrowth: 12,
        totalPatients: 1250,
        totalDoctors: 28,
        totalAppointments: 3456,
        pendingPayments: {
            amount: 45000,
            count: 32
        },
        corporateAccounts: 15
    };

    const treatmentRequests = {
        pending: 45,
        inProgress: 78,
        completed: 890,
        rejected: 12
    };

    const doctorsList = [
        {
            id: 'DOC001',
            name: 'Dr. Sarah Wilson',
            specialty: 'Physical Therapy',
            availableSlots: 5,
            todayAppointments: 8,
            rating: 4.8,
            status: 'AVAILABLE'
        },
        {
            id: 'DOC002',
            name: 'Dr. Michael Brown',
            specialty: 'Sports Medicine',
            availableSlots: 3,
            todayAppointments: 10,
            rating: 4.9,
            status: 'BUSY'
        },
        {
            id: 'DOC003',
            name: 'Dr. Lisa Anderson',
            specialty: 'Rehabilitation',
            availableSlots: 0,
            todayAppointments: 12,
            rating: 4.7,
            status: 'FULLY_BOOKED'
        }
    ];

    const topPatients = [
        {
            id: 'PAT001',
            name: 'John Smith',
            visits: 24,
            lastVisit: '2024-03-10',
            upcomingAppointment: '2024-03-20',
            treatmentType: 'Physical Therapy'
        },
        {
            id: 'PAT002',
            name: 'Emma Johnson',
            visits: 18,
            lastVisit: '2024-03-12',
            upcomingAppointment: '2024-03-22',
            treatmentType: 'Sports Injury'
        },
        {
            id: 'PAT003',
            name: 'Robert Davis',
            visits: 15,
            lastVisit: '2024-03-15',
            upcomingAppointment: '2024-03-25',
            treatmentType: 'Rehabilitation'
        }
    ];

    const corporateTreatments = [
        {
            id: 'CORP001',
            company: 'SportsTech Industries',
            patientCount: 12,
            totalAmount: 25000,
            status: 'ACTIVE',
            lastTreatment: '2024-03-15'
        },
        {
            id: 'CORP002',
            company: 'Athletic Club Pro',
            patientCount: 8,
            totalAmount: 18000,
            status: 'PENDING_PAYMENT',
            lastTreatment: '2024-03-14'
        },
        {
            id: 'CORP003',
            company: 'Elite Sports Academy',
            patientCount: 15,
            totalAmount: 30000,
            status: 'ACTIVE',
            lastTreatment: '2024-03-16'
        }
    ];

    const urgentActions = [
        {
            id: 'URG001',
            type: 'PAYMENT',
            title: 'Pending Payment Approval',
            amount: 5000,
            company: 'SportsTech Industries',
            dueDate: '2024-03-20'
        },
        {
            id: 'URG002',
            type: 'TREATMENT',
            title: 'Treatment Request Approval',
            patient: 'Emma Johnson',
            requestDate: '2024-03-15',
            priority: 'HIGH'
        },
        {
            id: 'URG003',
            type: 'CORPORATE',
            title: 'Corporate Account Verification',
            company: 'Elite Sports Academy',
            submittedDate: '2024-03-14'
        }
    ];

    // Download handlers
    const handleDownloadReport = (reportType) => {
        // In a real application, this would trigger an API call to generate and download the report
        console.log(`Downloading ${reportType} report...`);
    };


    // GET DASHBOARD DATA   
    const hasFetched = useRef(false); // Initially false

    const getDashboardData = async () => {
        try {
            if (user) {
                const res = await get(`clinic/dashboard-data`)
                if (res.statusCode === 200) {
                    setDashboardData(res?.data?.data || {});
                }
            }
        } catch (error) {
            console.log(`ERROR WHILE FETHCING APPOINTMENT DETAIL ${error}`);
        }
    };

    useEffect(() => {
        if (!hasFetched.current) {
            getDashboardData();
            hasFetched.current = true; // Mark as fetched
        }
    }, [])
    // GET REQUEST DETAILS  

    const total = Object.values(mockData.paymentAnalytics).reduce((a, b) => a + b, 0);

    return (
        <div className="min-h-screen bg-white">
            <div className="w-full flex flex-col items-start gap-4 px-4 sm:px-6 lg:px-8 py-8">
                {/* Dashboard Header */}
                <div className="mb-8 flex items-center justify-between">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">{t('clinic-dashboard')}</h1>
                        <p className="mt-1 text-gray-600">{t('clinic-dash-msg')}</p>
                    </div>
                    {/* NOT FOR NOW  */}
                    <div className="hidden items-center space-x-4">
                        <select
                            className="px-4 py-2 border border-gray-200 rounded-lg text-sm"
                            value={timeRange}
                            onChange={(e) => setTimeRange(e.target.value)}
                        >
                            <option value="today">Today</option>
                            <option value="week">This Week</option>
                            <option value="month">This Month</option>
                        </select>
                        <button className="btn-outline">
                            <FiRefreshCcw className="w-4 h-4" />
                            <span>Refresh</span>
                        </button>
                    </div>
                </div>

                {/* CLINIC METRICS */}
                <div className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 ">
                    {/* Total Revenue */}
                    <DashboardTotalCard
                        label="total-revenue"
                        value={`$${dashboardData?.metrics?.totalRevenue?.toLocaleString()}` || 0}
                        colors='bg-green-100 text-green-600'
                        icon={<FaRegMoneyBillAlt />}
                        subMsg="All time"
                        growth={12.5}
                    />

                    {/* Monthly Revenue */}
                    <DashboardTotalCard
                        label="monthly-revenue"
                        value={`$${dashboardData?.metrics?.monthlyRevenue?.currentRevenue?.toLocaleString()}` || 0}
                        colors='bg-blue-100 text-blue-600'
                        icon={<CiWallet />}
                        subMsg="This Month"
                        growth={dashboardData?.metrics?.monthlyRevenue?.percentageChange || '0.0'}
                    />

                    {/* Pending Amount */}
                    <DashboardTotalCard
                        label="pending-payments"
                        value={`$${dashboardData?.metrics?.pendingPayments?.amount?.toLocaleString()}` || 0}
                        colors='bg-yellow-100 text-yellow-600'
                        icon={<LuCreditCard />}
                        subMsg="Needs attention"
                    // growth={8.5}
                    />

                    {/*  Revenue Growth FROM API */}
                    <DashboardTotalCard
                        label="revenue-growth"
                        value={`${dashboardData?.metrics?.revenueGrowth}%` || '0%'}
                        colors='bg-purple-100 text-purple-600'
                        icon={<FiActivity />}
                        subMsg="vs last month"
                    />

                    {/* Total PatientsFROM API  */}
                    <DashboardTotalCard
                        label="total-patients"
                        value={dashboardData?.metrics?.totalPatients}
                        colors='bg-blue-100 text-blue-600'
                        icon={<FiUsers />}
                        subMsg="Unique patients"
                    />

                    {/* Total Doctors */}
                    <DashboardTotalCard
                        label="total-doctors"
                        value={dashboardData?.metrics?.totalDoctors || 0}
                        colors='bg-green-100 text-green-600'
                        icon={<CiStethoscope />}
                        subMsg="Active doctors"
                    />

                    {/* Total Appointments FROM API * */}
                    <DashboardTotalCard
                        label="total-appointments"
                        value={dashboardData?.metrics?.totalAppointments || 0}
                        colors='bg-purple-100 text-purple-600'
                        icon={<CiCalendar />}
                        subMsg="This Month"
                    />

                    {/* Corporate Accounts */}
                    <DashboardTotalCard
                        label="corporate-accounts"
                        value={dashboardData?.metrics?.corporateAccounts?.approved || 0}
                        colors='bg-pink-100 text-pink-600'
                        icon={<FaDollarSign />}
                        subMsg="Connected organizations"
                    // growth={12.5}
                    />
                </div>

                {/* CURRENTLY MOCK DATA  */}
                {/* PAYMENT ANALYTICS  */}
                <div className="bg-white w-full rounded-xl border border-gray-200 p-6 hover:shadow-lg">
                    {/* HEADER PART  */}
                    <AnalyticHeader
                        label="payment-analytics"
                        msg="payment-ana-msg"
                    />
                    {/* Payment Status Cards */}
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
                        {Object.entries(mockData.paymentAnalytics).map(([status, amount]) => {
                            const config = paymentConfig[status];
                            const Icon = config.icon;
                            const percentage = ((amount / total) * 100).toFixed(1);
                            const isPositive = status === 'collected' || Math.random() > 0.5;

                            return (
                                <>
                                    <AnlyticCard
                                        key={status}
                                        status={status}
                                        percentage={percentage}
                                        amount={amount}
                                        isPositive={isPositive}
                                        Icon={Icon}
                                        config={config}
                                        lineLabel="share"
                                    />
                                </>
                            );
                        })}
                    </div>
                </div>

                {/* CURRENTLY MOCK DATA  */}
                {/* REQUESTST STATUS ANALYTICS  */}
                <div className="bg-white w-full rounded-xl border border-gray-200 p-6 hover:shadow-lg">
                    {/* HEADER PART  */}
                    <AnalyticHeader
                        label="treatment-analytics"
                        msg="treatment-ana-msg"
                    />
                    {/* REQUEST Status Cards FROM API */}
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
                        {dashboardData?.treatmentStatus && Object.entries(dashboardData?.treatmentStatus)?.map(([status, amount]) => {
                            const config = statusConfig[status?.toLowerCase()];
                            const Icon = config?.icon;
                            const treatmentTotal = Object.values(dashboardData?.treatmentStatus).reduce((a, b) => a + b, 0);
                            const percentage = ((amount / treatmentTotal) * 100).toFixed(1);
                            const isPositive = status === 'collected' || Math.random() > 0.5;
                            // Add a fallback if the config is undefined
                            if (!config) return null;
                            return (
                                <>
                                    <AnlyticCard
                                        key={status}
                                        status={status}
                                        percentage={percentage}
                                        amount={amount}
                                        isPositive={isPositive}
                                        Icon={Icon}
                                        config={config}
                                        lineLabel="progress"
                                    />
                                </>
                            );
                        })}
                    </div>
                </div>

                {/* RECENT APPOINTMENT LIST FROM API  */}
                <div className="bg-white w-full rounded-xl border border-gray-200 p-6 hover:shadow-lg">
                    <AnalyticHeader
                        label={'recent-appointments'}
                    />
                    <div className="space-y-4">
                        {dashboardData?.recentAppointments?.map((apt, idx) => (
                            <DashboardListCard
                                key={idx}
                                colors="bg-blue-100 text-blue-600"
                                icon={<CiClock1 />}
                                status={apt?.status}
                                heading={apt?.doctorId?.name}
                                subHeading={apt?.requestId?.userId?.name}
                                rightHeading={formatTimeArEn(apt?.appointmentDate, isArabic)}
                            />
                        ))}
                    </div>
                </div>

                {/* RECENT PATIENT LIST FROM API  -- NEED TO FIX IT */}
                <div className="bg-white w-full rounded-xl border border-gray-200 p-6 hover:shadow-lg">
                    <AnalyticHeader
                        label={'recent-patient'}
                    />
                    <div className="space-y-4">
                        {dashboardData?.recentPatients?.map((apt, idx) => (
                            <DashboardListCard
                                key={idx}
                                colors="bg-purple-100 text-purple-600"
                                icon={<CiUser />}
                                // status={'Recurring Patient'}
                                apt={apt}
                                heading={apt?.name}
                                // subHeading={`Last visit: ${apt.lastVisit}`}
                                rightHeading={`${apt?.visits} visits`}
                            />
                        ))}
                    </div>
                </div>

                {/* CURRENTLY MOCK DATA  */}
                {/* ACIVE DOCTORS LIST  */}
                <div className="bg-white w-full hidden rounded-xl border border-gray-200 p-6 hover:shadow-lg">
                    <AnalyticHeader
                        label={'active-doctors'}
                    />
                    {/* CURRENTLY MOCK DATA  */}
                    <div className="space-y-4">
                        {mockData?.activeDoctors.map((apt, idx) => (
                            <DashboardListCard
                                key={idx}
                                colors="bg-green-100 text-green-600"
                                icon={<CiStethoscope />}
                                status={'Available Today'}
                                apt={apt}
                                heading={apt?.name}
                                subHeading={apt.specialty}
                                rightHeading={`${apt?.availableSlots} slots`}
                            />
                        ))}
                    </div>
                </div>

                {/* Main Content Grid */}
                <div className=" gap-8 hidden">
                    {/* Left Column - Treatment Status & Doctors */}
                    <div className="space-y-8">
                        {/* Treatment Requests Status */}
                        <div className="bg-white rounded-xl border border-gray-200 p-6 hover:shadow-lg transition-all duration-200">
                            <div className="flex items-center justify-between mb-6">
                                <div>
                                    <h2 className="text-lg font-semibold text-gray-900">Treatment Requests</h2>
                                    <p className="text-sm text-gray-600 mt-1">Current status overview</p>
                                </div>
                                <button onClick={() => handleDownloadReport('treatments')} className="text-gray-400 hover:text-gray-600">
                                    <MdOutlineFileDownload className="w-4 h-4" />
                                </button>
                            </div>
                            <div className="space-y-4">
                                <div className="flex items-center justify-between p-3 bg-yellow-50 rounded-lg">
                                    <span className="text-sm font-medium text-gray-900">Pending</span>
                                    <span className="text-sm font-bold text-yellow-600">{treatmentRequests.pending}</span>
                                </div>
                                <div className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                                    <span className="text-sm font-medium text-gray-900">In Progress</span>
                                    <span className="text-sm font-bold text-blue-600">{treatmentRequests.inProgress}</span>
                                </div>
                                <div className="flex items-center justify-between p-3 bg-green-50 rounded-lg">
                                    <span className="text-sm font-medium text-gray-900">Completed</span>
                                    <span className="text-sm font-bold text-green-600">{treatmentRequests.completed}</span>
                                </div>
                                <div className="flex items-center justify-between p-3 bg-red-50 rounded-lg">
                                    <span className="text-sm font-medium text-gray-900">Rejected</span>
                                    <span className="text-sm font-bold text-red-600">{treatmentRequests.rejected}</span>
                                </div>
                            </div>
                        </div>

                        {/* Doctors Overview */}
                        <div className="bg-white rounded-xl border border-gray-200 p-6 hover:shadow-lg transition-all duration-200">
                            <div className="flex items-center justify-between mb-6">
                                <div>
                                    <h2 className="text-lg font-semibold text-gray-900">Doctors Overview</h2>
                                    <p className="text-sm text-gray-600 mt-1">Available slots & status</p>
                                </div>
                                <button onClick={() => handleDownloadReport('doctors-detail')} className="text-gray-400 hover:text-gray-600">
                                    <MdOutlineFileDownload className="w-4 h-4" />
                                </button>
                            </div>
                            <div className="space-y-4">
                                {doctorsList.map((doctor) => (
                                    <div key={doctor.id} className="p-4 bg-gray-50 rounded-lg">
                                        <div className="flex items-center justify-between mb-2">
                                            <h4 className="font-medium text-gray-900">{doctor.name}</h4>
                                            <span className={`px-2 py-1 rounded-full text-xs font-medium ${doctor.status === 'AVAILABLE' ? 'bg-green-100 text-green-700' :
                                                doctor.status === 'BUSY' ? 'bg-yellow-100 text-yellow-700' :
                                                    'bg-red-100 text-red-700'
                                                }`}>
                                                {doctor.status.replace('_', ' ')}
                                            </span>
                                        </div>
                                        <p className="text-sm text-gray-600">{doctor.specialty}</p>
                                        <div className="mt-2 flex items-center justify-between text-sm">
                                            <span className="text-gray-600">Available Slots: {doctor.availableSlots}</span>
                                            <span className="text-gray-600">Today: {doctor.todayAppointments}</span>
                                        </div>
                                        <div className="mt-2 flex items-center text-sm">
                                            <CiStar className="w-4 h-4 text-yellow-400 mr-1" />
                                            <span className="text-gray-600">{doctor.rating}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Middle Column - Patients & Corporate */}
                    <div className="space-y-8">
                        {/* Top Patients */}
                        <div className="bg-white rounded-xl border border-gray-200 p-6 hover:shadow-lg transition-all duration-200">
                            <div className="flex items-center justify-between mb-6">
                                <div>
                                    <h2 className="text-lg font-semibold text-gray-900">Top Patients</h2>
                                    <p className="text-sm text-gray-600 mt-1">Recurring patients overview</p>
                                </div>
                                <button onClick={() => handleDownloadReport('top-patients')} className="text-gray-400 hover:text-gray-600">
                                    <MdOutlineFileDownload className="w-4 h-4" />
                                </button>
                            </div>
                            <div className="space-y-4">
                                {topPatients.map((patient) => (
                                    <div key={patient.id} className="p-4 bg-gray-50 rounded-lg">
                                        <h4 className="font-medium text-gray-900">{patient.name}</h4>
                                        <p className="text-sm text-gray-600 mt-1">{patient.treatmentType}</p>
                                        <div className="mt-2 grid grid-cols-2 gap-2 text-sm">
                                            <div>
                                                <span className="text-gray-500">Total Visits:</span>
                                                <span className="ml-1 font-medium text-gray-900">{patient.visits}</span>
                                            </div>
                                            <div>
                                                <span className="text-gray-500">Last Visit:</span>
                                                <span className="ml-1 font-medium text-gray-900">{new Date(patient.lastVisit).toLocaleDateString()}</span>
                                            </div>
                                        </div>
                                        <div className="mt-2 text-sm text-blue-600">
                                            Next Appointment: {new Date(patient.upcomingAppointment).toLocaleDateString()}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Corporate Treatments */}
                        <div className="bg-white rounded-xl border border-gray-200 p-6 hover:shadow-lg transition-all duration-200">
                            <div className="flex items-center justify-between mb-6">
                                <div>
                                    <h2 className="text-lg font-semibold text-gray-900">Corporate Treatments</h2>
                                    <p className="text-sm text-gray-600 mt-1">Recent corporate activity</p>
                                </div>
                                <button onClick={() => handleDownloadReport('corporate-treatments')} className="text-gray-400 hover:text-gray-600">
                                    <MdOutlineFileDownload className="w-4 h-4" />
                                </button>
                            </div>
                            <div className="space-y-4">
                                {corporateTreatments.map((treatment) => (
                                    <div key={treatment.id} className="p-4 bg-gray-50 rounded-lg">
                                        <div className="flex items-center justify-between mb-2">
                                            <h4 className="font-medium text-gray-900">{treatment.company}</h4>
                                            <span className={`px-2 py-1 rounded-full text-xs font-medium ${treatment.status === 'ACTIVE' ? 'bg-green-100 text-green-700' :
                                                'bg-yellow-100 text-yellow-700'
                                                }`}>
                                                {treatment.status.replace('_', ' ')}
                                            </span>
                                        </div>
                                        <div className="mt-2 grid grid-cols-2 gap-2 text-sm">
                                            <div>
                                                <span className="text-gray-500">Patients:</span>
                                                <span className="ml-1 font-medium text-gray-900">{treatment.patientCount}</span>
                                            </div>
                                            <div>
                                                <span className="text-gray-500">Amount:</span>
                                                <span className="ml-1 font-medium text-gray-900">${treatment.totalAmount.toLocaleString()}</span>
                                            </div>
                                        </div>
                                        <div className="mt-2 text-sm text-gray-600">
                                            Last Treatment: {new Date(treatment.lastTreatment).toLocaleDateString()}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Right Column - Urgent Actions */}
                    <div className="space-y-8">
                        <div className="bg-white rounded-xl border border-gray-200 p-6 hover:shadow-lg transition-all duration-200">
                            <div className="flex items-center justify-between mb-6">
                                <div>
                                    <h2 className="text-lg font-semibold text-gray-900">Urgent Actions</h2>
                                    <p className="text-sm text-gray-600 mt-1">Pending approvals & payments</p>
                                </div>
                                <button onClick={() => handleDownloadReport('urgent-actions')} className="text-gray-400 hover:text-gray-600">
                                    <MdOutlineFileDownload className="w-4 h-4" />
                                </button>
                            </div>
                            <div className="space-y-4">
                                {urgentActions.map((action) => (
                                    <div key={action.id} className="p-4 bg-red-50 rounded-lg">
                                        <div className="flex items-center justify-between mb-2">
                                            <h4 className="font-medium text-gray-900">{action.title}</h4>
                                            <span className="px-2 py-1 bg-red-100 text-red-700 rounded-full text-xs font-medium">
                                                {action.type}
                                            </span>
                                        </div>
                                        {action.type === 'PAYMENT' && (
                                            <div className="mt-2 space-y-1 text-sm">
                                                <p className="text-gray-600">Amount: ${action.amount.toLocaleString()}</p>
                                                <p className="text-gray-600">Company: {action.company}</p>
                                                <p className="text-gray-600">Due: {new Date(action.dueDate).toLocaleDateString()}</p>
                                            </div>
                                        )}
                                        {action.type === 'TREATMENT' && (
                                            <div className="mt-2 space-y-1 text-sm">
                                                <p className="text-gray-600">Patient: {action.patient}</p>
                                                <p className="text-gray-600">Requested: {new Date(action.requestDate).toLocaleDateString()}</p>
                                                <p className="text-gray-600">Priority: {action.priority}</p>
                                            </div>
                                        )}
                                        {action.type === 'CORPORATE' && (
                                            <div className="mt-2 space-y-1 text-sm">
                                                <p className="text-gray-600">Company: {action.company}</p>
                                                <p className="text-gray-600">Submitted: {new Date(action.submittedDate).toLocaleDateString()}</p>
                                            </div>
                                        )}
                                        <div className="mt-3 flex space-x-2">
                                            <button className="btn-success flex-1">
                                                <GoCheckCircle className="w-4 h-4" />
                                                <span>Approve</span>
                                            </button>
                                            <button className="btn-danger flex-1">
                                                <GoXCircle className="w-4 h-4" />
                                                <span>Reject</span>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Quick Stats */}
                        <div className="bg-white rounded-xl border border-gray-200 p-6 hover:shadow-lg transition-all duration-200">
                            <h2 className="text-lg font-semibold text-gray-900 mb-4">Quick Stats</h2>
                            <div className="space-y-4">
                                <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                                    <span className="text-sm text-gray-600">Today's Revenue</span>
                                    <span className="text-sm font-bold text-gray-900">$12,450</span>
                                </div>
                                <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                                    <span className="text-sm text-gray-600">Pending Reviews</span>
                                    <span className="text-sm font-bold text-gray-900">15</span>
                                </div>
                                <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                                    <span className="text-sm text-gray-600">Treatment Success Rate</span>
                                    <span className="text-sm font-bold text-green-600">95%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClinicDashboard;


import React, { useMemo } from 'react'
import Chart from 'react-apexcharts'

const BarChart = () => {
  const options = useMemo(() => ({
    chart: {
      id: 'basic-bar',
      type: 'bar'
    },
    plotOptions: {
      bar: {
        columnWidth: '30%'
      }
    },
    xaxis: {
      categories: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'july', 'aug', 'sep', 'oct', 'nov', 'dec']
    }
  }), []);

  const series = useMemo(() => [
    {
      name: 'series-1',
      data: [30, 40, 45, 50, 49, 60, 70, 91, 34, 56, 74, 44, ]
    }
  ], []);

  return (
    <div className='w-full h-full'>
      <Chart
        options={options}
        series={series}
        type='bar'
        width='100%'
        height='100%'
      />
    </div>
  )
}

export default BarChart

import React, { useState, useEffect } from 'react';
import { get, patch } from '../../services/apis/api';
import {
    Box, Spinner, Text, Flex, Heading, Divider, SimpleGrid, Badge,
    VStack, HStack, Avatar, useDisclosure, useToast
} from '@chakra-ui/react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../alerts/CustomAlert';

// Status-to-role mappings for request approval flow
const statusRoleMapping = {
    'HR-MANAGER-REVIEW': 'ACCOUNT-MANAGER-REVIEW',
    'ACCOUNT-MANAGER-REVIEW': 'SUPER-ADMIN-REVIEW',
    'SUPER-ADMIN-REVIEW': 'HR-MANAGER-APPROVED',
};

// Role-to-type-and-status mappings
const roleToTypeAndStatus = {
    'HR-MANAGER': 'HR-MANAGER-REVIEW',
    'ACCOUNT-MANAGER': 'ACCOUNT-MANAGER-REVIEW',
    'SUPER-ADMIN': 'SUPER-ADMIN-REVIEW',
};

const ViewFNFRequest = ({ requestId, setView, user }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState(null);
    const [canApprove, setCanApprove] = useState(false);
    const [requestAction, setRequestAction] = useState(false);
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    // Fetch FNF request details
    const getRequestDetails = async () => {
        try {
            const res = await get(`request/get-fnf/${requestId}`);
            if (res.statusCode === 200) {
                setRequest(res.data.data);
                const userCanApprove = roleToTypeAndStatus[user?.role] === res.data.data.status;
                setCanApprove(userCanApprove);
            }
        } catch (error) {
            console.error('Error fetching request details', error);
        }
    };

    useEffect(() => {
        getRequestDetails();
    }, []);

    // Handle request approval
    const confirmApprove = async () => {
        const nextStatus = statusRoleMapping[request.status];
        if (!nextStatus) {
            toast({
                title: t('error'),
                description: t('cannot-approve-request'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        setLoading(true);
        const res = await patch(`request/update-fnf/${requestId}`, { status: nextStatus });
        setLoading(false);
        if (res.statusCode === 200) {
            getRequestDetails();
            toast({
                title: t('success'),
                description: t('request-approved'),
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: t('error'),
                description: t('failed-to-approve'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        onClose(); // Close the alert after confirmation
    };

    // Handle request rejection
    const handleReject = async () => {
        setLoading(true);
        const res = await patch(`request/update-fnf/${requestId}`, { status: 'REJECTED', rejectedBy: user._id });
        setLoading(false);
        if (res.statusCode === 200) {
            getRequestDetails();
            toast({
                title: t('success'),
                description: t('request-rejected'),
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: t('error'),
                description: t('failed-to-reject'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        onClose(); // Close the alert after confirmation
    };

    const handleApproveRejectRequest = (type) => {
        if (type === "APPROVED") {
            setRequestAction(true);
            onOpen()
        } else {
            setRequestAction(false);
            onOpen()
        }
    }

    if (!request) {
        return (
            <Box className="w-full h-[70vh]" display="flex" alignItems="center" justifyContent="center">
                <Spinner />
            </Box>
        );
    }

    const employee = request.employeeId;
    const fnfDetails = request.fnfId;

    return (
        <Box p={6} borderWidth="1px" borderRadius="lg" bg="white">
            {/* Back Button */}
            <Flex justify="space-between" mb={4}>
                <button onClick={() => setView(false)} className='bg-[#290dbd] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md flex items-center'>
                    <IoMdArrowRoundBack /> {t('back')}
                </button>
                {
                    canApprove ?
                        <div>
                            <button onClick={() => handleApproveRejectRequest('REJECTED')} className='bg-[#e32c35] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md me-2'>{loading ? <Spinner /> : t("reject-request")}</button>
                            <button onClick={() => handleApproveRejectRequest('APPROVED')} className='bg-[#290dbd] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md'>{loading ? <Spinner /> : t("approve-request")}</button>
                        </div> : ""
                }
                <div>
                    <button onClick={() => handleApproveRejectRequest('REJECTED')} className='bg-[#e32c35] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md me-2'>{loading ? <Spinner /> : t("reject-request")}</button>
                    <button onClick={() => handleApproveRejectRequest('APPROVED')} className='bg-[#290dbd] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md'>{loading ? <Spinner /> : t("approve-request")}</button>
                </div>
            </Flex>
            {/* Employee Information */}
            <Heading as="h2" size="md" mb={4} color="teal.600">
                {t('employee-details')}
            </Heading>
            <Flex alignItems="center" mb={4}>
                <Avatar size="lg" name={employee.name} src={employee.avatar} />
                <Box ml={4}>
                    <Text fontSize="xl" fontWeight="bold">{employee.name}</Text>
                    <Text fontSize="md" color="gray.600">{employee.email}</Text>
                </Box>
            </Flex>

            <SimpleGrid columns={[1, 2, 2, 3]} spacing={6} mb={4}>
                <VStack align="flex-start">
                    <Text fontWeight="semibold">{t('employee-code')}</Text>
                    <Text>{employee.employeeCode}</Text>
                </VStack>
                <VStack align="flex-start">
                    <Text fontWeight="semibold">{t('mobile')}</Text>
                    <Text>{employee.mobile}</Text>
                </VStack>
                <VStack align="flex-start">
                    <Text fontWeight="semibold">{t('designation')}</Text>
                    <Text>{employee.designation}</Text>
                </VStack>
                <VStack align="flex-start">
                    <Text fontWeight="semibold">{t('gender')}</Text>
                    <Text>{employee.gender}</Text>
                </VStack>
                <VStack align="flex-start">
                    <Text fontWeight="semibold">{t('resignation-date')}</Text>
                    <Text>{new Date(request.resignationDate).toLocaleDateString()}</Text>
                </VStack>
                <VStack align="flex-start">
                    <Text fontWeight="semibold">{t('last-working-date')}</Text>
                    <Text>{new Date(request.lastWorkingDate).toLocaleDateString()}</Text>
                </VStack>
            </SimpleGrid>

            <Divider my={6} />

            {/* Request Status */}
            <Box>
                <Heading as="h2" size="md" mb={4} color="teal.600">
                    {t('request-status')}
                </Heading>
                <HStack spacing={4}>
                    <Badge colorScheme={request.status === "PENDING" ? "orange" : "green"} fontSize="md" py={2} px={4} rounded={'lg'}>
                        {t(request.status)}
                    </Badge>
                </HStack>

                {request.status === "PENDING" ? (
                    <Box mt={6}>
                        <Text fontSize="lg" fontWeight="semibold" color="orange.500">
                            {t('fnf-pending')}
                        </Text>
                        <Text className='text-xs text-red-500'>** {t('fnf-process-note')} **</Text>
                    </Box>
                ) : (
                    <Box mt={6}>
                        <Heading as="h3" size="md" mb={4} color="teal.500">
                            {t('fnf-details')}
                        </Heading>
                        {fnfDetails && (
                            <SimpleGrid columns={[1, 2, 2, 3]} spacing={6}>
                                <VStack align="flex-start">
                                    <Text fontWeight="semibold">{t('pending-salary')}</Text>
                                    <Text>₹{fnfDetails.pendingSalary}</Text>
                                </VStack>
                                <VStack align="flex-start">
                                    <Text fontWeight="semibold">{t('leave-encashment')}</Text>
                                    <Text>₹{fnfDetails.leaveEncashment}</Text>
                                </VStack>
                                <VStack align="flex-start">
                                    <Text fontWeight="semibold">{t('gratuity')}</Text>
                                    <Text>₹{fnfDetails.gratuity}</Text>
                                </VStack>
                                <VStack align="flex-start">
                                    <Text fontWeight="semibold">{t('bonuses')}</Text>
                                    <Text>₹{fnfDetails.bonuses}</Text>
                                </VStack>
                                <VStack align="flex-start">
                                    <Text fontWeight="semibold">{t('deductions')}</Text>
                                    <Text>₹{fnfDetails.deductions || 'N/A'}</Text>
                                </VStack>
                                <VStack align="flex-start">
                                    <Text fontWeight="semibold">{t('total-amount-payable')}</Text>
                                    <Text>₹{fnfDetails.totalPayable}</Text>
                                </VStack>
                            </SimpleGrid>
                        )}
                    </Box>
                )}
            </Box>

            {/* CustomAlert Component for approval/rejection */}
            {
                requestAction ?
                    <CustomAlert
                        isOpen={isOpen}
                        onClose={onClose}
                        title={t("approve-request")}
                        description={t("confirm-approve-request")}
                        onConfirm={confirmApprove}
                        confirmButtonText={t("confirm")}
                        confirmButtonColorScheme={'blue'}
                        cancelButtonText={t("cancel")}
                    />
                    :
                    <CustomAlert
                        isOpen={isOpen}
                        onClose={onClose}
                        title={t("reject-request")}
                        description={t("confirm-reject-request")}
                        onConfirm={handleReject}
                        confirmButtonText={t("confirm")}
                        cancelButtonText={t("cancel")}
                    />
            }
        </Box>
    );
};

export default ViewFNFRequest;

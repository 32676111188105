import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import { CheckCircleIcon, CheckIcon, CloseIcon, DownloadIcon, HamburgerIcon, InfoIcon, ViewIcon } from '@chakra-ui/icons';
import { Th, Table, TableContainer, Thead, Tr, Td, Tbody, Button, Badge, MenuButton, Menu, MenuList, MenuItem, Select, Divider, IconButton, Tooltip, useToast, Flex, Box } from '@chakra-ui/react';
import { AiFillCheckCircle, AiOutlineCheck, AiOutlineClockCircle, AiOutlineClose } from 'react-icons/ai';
import { TbEyeDiscount } from 'react-icons/tb';
import { CiCircleCheck } from "react-icons/ci";
import { RxCrossCircled } from "react-icons/rx";
import { FiEye } from "react-icons/fi";
import { FaFileInvoiceDollar } from 'react-icons/fa';
import PayslipModal from '../../components/hr/dialogBoxes/PayslipModal';
import { get, patch } from '../../services/apis/api';
import moment from 'moment';
import ConfirmRejectBox from '../../components/alerts/ConfirmRejectBox';
import { debounce } from 'lodash';
import ConfirmApproveBox from '../../components/alerts/ConfirmApprove';
import { formatDate, formatMonthDateTime, formatNumber } from '../../services/glocalFunctions';
import PayrollFilters from '../../components/hr/components/filters/PayrollFilters';
import ConfirmDownload from '../../components/dialogueBoxes/ConfirmDownload';


const columns = [
    's-no',
    'employee-name',
    'employee-code',
    'requested-amount',
    'performance',
    'payroll-month',
    'status',
    'action'
]

// Compute initial attendanceDate dynamically
const computeInitialAttendanceDate = () => {
    const currentAttendanceDate = new Date();
    const previousMonth = currentAttendanceDate.getMonth(); // Zero-based
    const previousYear = previousMonth === 0
        ? currentAttendanceDate.getFullYear() - 1 // Move to the previous year if January
        : currentAttendanceDate.getFullYear();

    return {
        month: previousMonth === 0 ? 12 : previousMonth, // Set to December if it's January
        year: previousYear,
        startDate: new Date(
            previousMonth === 0 ? previousYear : currentAttendanceDate.getFullYear(), // Adjust year for January
            previousMonth === 0 ? 11 : previousMonth - 1, // Adjust month (Dec for Jan)
            1 // First day of the previous month
        ),
    };
};

// Initialize _filterData with default values
const _filterData = {
    attendanceDate: {
        month: 0,
        year: 0,
        startDate: ''
    },
    selectedDepartment: 'ALL',
    payrollStatus: '',
    paymentStatus: 'ALL',
    search: ''
};

const Payroll = () => {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const toast = useToast();
    const [requests, setRequests] = useState([]);
    const [selectedReq, setSelectedReq] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [view, setView] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    // Get current date and determine the default month and year
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // 0 for January, 11 for December
    const currentYear = currentDate.getFullYear();

    // If current month is January, set the default month to December of the previous year
    const defaultMonth = currentMonth === 0 ? 'December' : currentDate.toLocaleString('default', { month: 'long' });
    const defaultYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    const [selectedMonth, setSelectedMonth] = useState(defaultMonth);
    const [selectedYear, setSelectedYear] = useState(defaultYear.toString());
    const [data, setData] = useState(null);
    const [counts, setCounts] = useState(null);
    const [allDepartments, setAllDepartments] = useState([])
    const hasFetched = useRef(false); // Initially false
    const [appliedFilterCount, setAppliedFilterCount] = useState(0);
    const [downloadOpen, setDownloadOpen] = useState(false);  // for confirmation dialog
    // Initialize filterData with the computed attendanceDate
    const [filterData, setFilterData] = useState({
        ..._filterData,
        attendanceDate: computeInitialAttendanceDate()
    });

    // GET ALL DEPARTMENTS FOR DROP DOWN 
    const getAllDepartments = async () => {
        try {
            const res = await get('department/all');
            if (res.statusCode === 200) {
                setAllDepartments(res?.data?.department);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const Heading = ({ title }) => (
        <h2 className={`text-base text-gray-600 mb-2 lg:text-xl font-bold`}>{title}</h2>
    )

    // GET THE ALL APPLIED FILTER COUNT 
    const checkAppliedFilterCount = (filterData) => {
        try {
            console.log(filterData, 'filter data');

            // Initialize filtercount to 0
            let filtercount = 0;

            // Increment count for each applied filter
            if (filterData?.search) {
                filtercount++;
            }
            if (filterData?.attendanceDate?.startDate) {
                filtercount++;
            }
            if (filterData?.paymentStatus && filterData?.paymentStatus.toLocaleLowerCase() !== 'all') {
                filtercount++;
            }
            if (filterData?.payrollStatus && filterData?.payrollStatus.toLocaleLowerCase() !== 'all') {
                filtercount++;
            }
            if (filterData?.selectedDepartment && filterData?.selectedDepartment.toLocaleLowerCase() !== 'all') {
                filtercount++;
            }

            return filtercount; // Return the total count of applied filters
        } catch (error) {
            console.error("Error calculating applied filter count:", error);
            return 0; // Return a default value in case of an error
        }
    };

    // FETCH ALL PAYROLLS 
    const fetchData = async (_filterData, skip, limit) => {
        setLoading(true);
        try {
            const filtercount = checkAppliedFilterCount(_filterData);
            setAppliedFilterCount(filtercount)
            // Construct the query parameters dynamically
            let queryParams = [];

            if (_filterData?.search) {
                queryParams.push(`search=${encodeURIComponent(_filterData.search)}`);
            }

            if (_filterData?.attendanceDate?.month) {
                queryParams.push(`month=${encodeURIComponent(_filterData.attendanceDate.month)}`);
            }

            if (_filterData?.attendanceDate?.year) {
                queryParams.push(`year=${encodeURIComponent(_filterData.attendanceDate.year)}`);
            }

            if (_filterData?.payrollStatus) {
                queryParams.push(`status=${encodeURIComponent(_filterData.payrollStatus)}`);
            }

            if (_filterData?.selectedDepartment && _filterData?.selectedDepartment != 'ALL') {
                queryParams.push(`department=${encodeURIComponent(_filterData.selectedDepartment?.toUpperCase())}`);
            }

            if (_filterData?.paymentStatus) {
                queryParams.push(`paymentStatus=${encodeURIComponent(_filterData.paymentStatus)}`);
            }

            if (skip) {
                queryParams.push(`page=${encodeURIComponent(skip)}`);
            }

            if (limit) {
                queryParams.push(`limit=${encodeURIComponent(limit)}`);
            }

            // Join the parameters into a query string
            const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";

            // Make the GET request with the constructed query parameters
            const res = await get(`payroll/all${queryString}`);
            if (res.statusCode === 200) {
                setData(res?.data?.data);
                setCounts(res?.data?.counts);
                setTotalPages(res?.data?.pages);
            } else {
                setData([]);
                setPage(1);
                setTotalPages(1);
            }
        } catch (error) {
            setData([]);
            setPage(1);
            setTotalPages(1);
            console.error('Error fetching payroll data:', error);
        } finally {
            setLoading(false);
        }
    };

    // GET ALL ANNOUNCEMENT LIST 
    useEffect(() => {
        if (!hasFetched.current) {
            getAllDepartments();
            fetchData(filterData, page, 10);
            hasFetched.current = true; // Mark as fetched
        }
    }, []);

    // Debounced version of the fetchData function
    const debouncedGetAllData = useCallback(
        debounce((_filterData, skip, limit) => {
            fetchData(_filterData, skip, limit);
        }, 300), // Adjust debounce delay as needed
    );

    //  HANDLE PAYSLIP REQUEST BY API
    const handlePaySlipRequest = async (_id, decision) => {
        try {
            const response = await patch(`payroll/update/${_id}`, { paymentStatus: decision });
            if (response.statusCode === 200) {
                fetchData(filterData, page, 10);
                toast({
                    title: 'Successfull...!',
                    description: decision == 'APPROVED' ? 'Payroll Request Approves' : 'Payroll Request Rejected',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (e) {
            console.log(`ERROR WHILE UPDATING PAYSLIP DATA : ${e}`);
        }
    }


    // TABLE COLUMNS AND COLUMNS  
    const direction = isArabic ? 'rtl' : 'ltr';
    const textAlign = isArabic ? 'right' : 'left';

    // ALL REQUESTS LIST 
    const RequestList = () => {
        return (
            <>
                <TableContainer shadow="md" borderRadius="lg" flex={1} display="flex" flexDirection="column">
                    <Table dir={isArabic ? 'rtl' : "ltr"} variant="simple">
                        <Thead bg="gray.50">
                            <Tr>
                                {columns?.map((column, index) => (
                                    <Th textAlign={textAlign} dir={direction} key={index}>{t(column)}</Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data?.length > 0 && (
                                data?.map((request, index) => (
                                    <Tr key={request.requestId}>
                                        <>
                                            <Td textAlign={textAlign} dir={direction}>{formatNumber(index + 1, isArabic)}</Td> {/* Serial Number */}
                                            <Td textAlign={textAlign} dir={direction}>{request?.employee?.name || 'N/A'}</Td>
                                            <Td textAlign={textAlign} dir={direction}>{request?.employee?.employeeCode || 'N/A'}</Td>
                                            <Td textAlign={textAlign} dir={direction}>{request?.finalNetPay ? formatNumber(request?.finalNetPay, isArabic) : 'N/A'}</Td> {/* Requested Amount */}
                                            <Td textAlign={textAlign} dir={direction}>{request.performance || 'N/A'}</Td> {/* Performance */}
                                            {/* <Td textAlign={textAlign} dir={direction}>{request?.payrollMonth}</Td> Submission Date */}
                                            <Td textAlign={textAlign} dir={direction}>{request?.payrollMonth ? formatMonthDateTime(request?.payrollMonth, isArabic) : ''}</Td>
                                            <Td textAlign={textAlign} dir={direction}>
                                                {request?.paymentStatus ?
                                                    <Badge colorScheme={getBadgeColor(request.paymentStatus)}>
                                                        {t(request?.paymentStatus?.toLowerCase())}
                                                    </Badge>
                                                    : 'N/A'}
                                            </Td>
                                            <Td textAlign={textAlign} dir={direction}>
                                                {request?.paymentStatus === 'PENDING' && user?.role?.toLowerCase() === 'account-manager' ? (
                                                    <>
                                                        <ConfirmApproveBox
                                                            handlePaySlipRequest={async () => {
                                                                await handlePaySlipRequest(request?._id, 'APPROVED');
                                                                fetchData(filterData, page, 10);
                                                            }}
                                                            request={request}
                                                            fetchData={() => {
                                                                fetchData(filterData, page, 10);
                                                            }}
                                                        />
                                                        <ConfirmRejectBox
                                                            handlePaySlipRequest={async () => {
                                                                handlePaySlipRequest(request?._id, 'REJECTED');
                                                            }}
                                                            request={request}
                                                            fetchData={() => {
                                                                fetchData(filterData, page, 10);
                                                            }}
                                                        />
                                                    </>
                                                ) : null}
                                                <PayslipModal
                                                    payslip={request}
                                                    handlePaySlipRequest={(_id, decision) => {
                                                        handlePaySlipRequest(_id, decision);
                                                    }}
                                                    fetchData={() => {
                                                        fetchData(filterData, page, 10);
                                                    }}
                                                />
                                            </Td>
                                        </>
                                    </Tr>
                                ))
                            )}
                        </Tbody>
                    </Table>
                    {data ?
                        data?.length <= 0 &&
                        <div className='w-full  min-h-80 flex items-center justify-center'>
                            <h1 className='text-center'>{t('no-data-found')}</h1>
                        </div>
                        :
                        <div className='w-full  min-h-80 flex items-center justify-center'>
                            <h1 className={`text-center `}>
                                {isArabic && '....'} {t('loading')} {!isArabic && '....'}
                            </h1>

                        </div>
                    }
                </TableContainer>
                {data?.length > 0 &&
                    <Flex justify="space-between" mt={4}>
                        <Button
                            onClick={() => {
                                const newPage = Math.max(page - 1, 1); // Precompute the new page number
                                setPage(newPage); // Update the page state
                                fetchData(filterData, newPage, 10,); // Fetch the data for the new page
                            }}
                            isDisabled={page === 1}
                        >
                            {t('previous')}
                        </Button>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isArabic ? 'row-reverse' : 'row',
                                gap: 1
                            }}
                        >
                            <h1>{t('page')}</h1>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: isArabic ? 'row-reverse' : 'row',
                                    gap: 1
                                }}
                            >
                                {page}

                                <h1>{t('of')}</h1>
                                {totalPages}
                            </ Box>
                        </Box>
                        <Button
                            onClick={() => {
                                const newPage = Math.min(page + 1, totalPages); // Precompute the new page number
                                setPage(newPage); // Update the page state
                                fetchData(filterData, newPage, 10,); // Fetch the data for the new page
                            }}
                            isDisabled={page === totalPages}
                        >
                            {t('next')}
                        </Button>
                    </Flex>
                }
            </>

        );
    };

    // ADD BADGE COLOR ON STATUS FIELD 
    const getBadgeColor = (status) => {
        switch (status) {
            case 'APPROVED':
                return 'green';
            case 'REJECTED':
                return 'red';
            case 'PENDING':
                return 'yellow';
            default:
                return 'gray';
        }
    };

    // OVERVIEW CARDS 
    const StatusItem = ({ title, count, color }) => {
        const colorClasses = {
            blue: "bg-blue-100 border-blue-400 text-blue-800",
            green: "bg-green-100 border-green-400 text-green-800",
            yellow: "bg-yellow-100 border-yellow-400 text-yellow-800",
            red: "bg-red-100 border-red-400 text-red-800",
        };

        const iconMap = {
            "Total Requests": <AiFillCheckCircle className="mr-1" />,
            "Approved": <AiOutlineCheck className="mr-1" />,
            "Pending": <AiOutlineClockCircle className="mr-1" />,
            "Rejected": <AiOutlineClose className="mr-1" />,
        };

        return (
            <div
                className={`flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} items-center border-2 ${colorClasses[color]} rounded-lg p-2 shadow-sm transition-transform transform hover:scale-105 duration-200`}
            >
                {/* {iconMap[title]} */}
                <span className="font-bold text-lg ml-2">{count}</span>
                <span className="ml-2 text-sm">{t(title)}</span>
            </div>
        );
    };

    // DOWNLOAD DATA AS CSV 
    const downloadCSVData = async () => {
        try {
            setLoading(true);
            // Get current year
            let currentYear = new Date().getFullYear();
            const queryParams = [];

            if (searchTerm) queryParams.push(`search=${encodeURIComponent(searchTerm)}`);
            if (selectedMonth) queryParams.push(`month=${encodeURIComponent(selectedMonth)}`);
            if (selectedYear) queryParams.push(`year=${encodeURIComponent(selectedYear)}`);

            const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";

            const response = await get(`payroll/all${queryString}`);
            console.log(response, 'response --->');

            if (response?.statusCode === 200) {
                const payrollData = response?.data?.data

                // Construct the filename with the current year
                const filename = `payroll-${selectedMonth}-${selectedYear}.csv`;

                // Check if there are payslips to export
                if (!payrollData || payrollData?.length === 0) {
                    setLoading(false);
                    toast({
                        title: 'Info',
                        description: 'No data to export!',
                        status: 'info',
                        duration: 5000,
                        isClosable: true,
                    });
                    return;
                }

                // Generate headers from the first payslip object keys
                const headers = [
                    "S NO.",
                    "Employee Name",
                    "Employee Code",
                    "Requested Amount",
                    "Performance",
                    "Payroll Month",
                    "Status"
                ];

                // Convert payslip data into CSV rows
                const rows = payrollData?.map((payroll, index) => {
                    return [
                        index + 1,
                        payroll?.employee?.name || "N/A",
                        payroll?.employee?.code || "N/A",
                        payroll?.finalNetPay ?? 0,
                        payroll?.performance || "N/A",
                        payroll?.payrollMonth ? formatMonthDateTime(payroll?.payrollMonth, isArabic) : 'N/A',
                        payroll?.paymentStatus || "N/A",
                    ]?.join(",");
                });

                // Combine headers and rows
                const csvContent = [headers?.join(","), ...rows]?.join("\n");

                // Create Blob and trigger download
                const blob = new Blob([csvContent], { type: "text/csv" });
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                setLoading(false);
                toast({
                    title: 'Success',
                    description: 'CSV Downloaded Successfully!',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (e) {
            setLoading(false);
            console.log(`ERROR WHILE DOWNLOADING CSV ${e}`);
        }
    };

    // CONFIRM DOWNLOAD FUNCTION 
    const confirmDownload = () => {
        try {
            downloadCSVData();
            setDownloadOpen(false);
        } catch (e) {
            console.log(`ERROR WHILE GENERATING PDF ${e}`);
        }
    };

    return (
        <>
            <div className='w-full flex-1 flex flex-col items-start gap-2 lg:gap-4 p-2 lg:p-4'>
                <div className={`flex  w-full justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                    <h2 className={`text-xl lg:text-2xl ${isArabic ? 'text-right' : 'text-left'}  whitespace-nowrap font-bold`}>{t("payroll-&-salaries")}</h2>
                </div>
                <div className={`flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} w-full gap-4`}>
                    <StatusItem title="total-requests" count={counts?.total || '0'} color="blue" />
                    <StatusItem title="approved" count={counts?.approved || '0'} color="green" />
                    <StatusItem title="pending" count={counts?.pending || '0'} color="yellow" />
                    <StatusItem title="rejected" count={counts?.rejected || '0'} color="red" />
                </div>
                <div className="w-full flex flex-col items-start gap-3 flex-1 min-h-[50vh]">
                    <div className={`w-full flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} items-center justify-between py-3`}>
                        <Heading
                            title={t("request")}
                        />
                        <div className={`flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} w-full justify-between gap-2 lg:gap-4 lg:w-fit items-center`}>
                            <div className="relative">
                                <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    id="table-search-users"
                                    className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                                    placeholder={t("search-request")}
                                    value={filterData?.search}
                                    // onChange={(e) => { setSearchTerm(e.target.value) }}
                                    onChange={(e) => {
                                        const value = e?.target?.value
                                        const _searchPayload = {
                                            ...filterData,
                                            search: value
                                        }
                                        setFilterData(_searchPayload);
                                        debouncedGetAllData(_searchPayload, 1, 10);
                                        setPage(1); // Reset to the first page when searching
                                    }}
                                />
                            </div>
                            {/* Download Confirmation Dialog */}
                            <ConfirmDownload
                                isOpen={downloadOpen}
                                label='download-csv'
                                setIsOpen={setDownloadOpen}
                                handleDownload={() => {
                                    setDownloadOpen(true);
                                }}
                                confirmDownload={() => {
                                    confirmDownload();
                                }}
                                closeDialog={() => {
                                    setDownloadOpen(false);
                                }}
                                loading={loading}
                            />
                            {/* PAYROLL FILTERS  */}
                            <PayrollFilters
                                filterData={filterData}
                                setFilterData={setFilterData}
                                allDepartments={allDepartments}
                                appliedFilterCount={appliedFilterCount}
                                onApplyFilter={() => {
                                    fetchData(filterData, 1, 10);
                                    setPage(1);
                                }}
                                onResetFilter={() => {
                                    const _payload = {
                                        ..._filterData,
                                        attendanceDate: computeInitialAttendanceDate(),
                                        search: filterData?.search
                                    }
                                    setFilterData(_payload)
                                    fetchData(_payload, 1, 10,);
                                    setPage(1);
                                }}
                            />
                            {/* HIDDEN FOR NOW  */}
                            <div className='ms-2 hidden'>
                                <Menu closeonselect={false}>
                                    <MenuButton
                                        as={IconButton}
                                        aria-label='Options'
                                        icon={<HamburgerIcon />}
                                        variant='outline'
                                    />
                                    <MenuList p={2}>
                                        <Select placeholder={t('expense-type')} textAlign={isArabic ? "right" : 'left'} bg="#f9fafb" borderColor="gray.300" >
                                            {/* {expenseTypeOptions.map((item) => (
                                            <option key={item} value={item}>
                                                {t(item)}
                                            </option>
                                        ))} */}
                                        </Select>
                                        <Divider my={1} />
                                        <Select placeholder={t('request-status')} textAlign={isArabic ? "right" : 'left'} bg="#f9fafb" borderColor="gray.300">
                                            {/* {requestStatusOptions.map((item) => (
                                            <option key={item} value={item}>
                                                {t(item)}
                                            </option>
                                        ))} */}
                                        </Select>
                                    </MenuList>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex-1 flex flex-col '>
                        <RequestList />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Payroll

import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  useDisclosure,
  FormControl,
  FormLabel,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import Select from 'react-select';
import { get, postFormData } from '../../services/apis/api'; // Ensure this import is correct for your API structure
import { useTranslation } from 'react-i18next';

const CreateCollection = ({ getAllCollections }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [gameOptions, setGameOptions] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const getAllGames = async () => {
    const res = await get('game/dropdown');
    if (res.statusCode === 200) {
      const options = res.data.dropdown.map(game => ({
        value: game._id,
        label: game.name,
      }));
      setGameOptions(options);
    }
  };

  useEffect(() => {
    getAllGames();
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setFilePreview(null);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('gameId', selectedGame?.value);
      formData.append('media', file);

      const res = await postFormData('collection/add', formData);
      if (res.statusCode === 201) {
        getAllCollections();
        setLoading(false);
        onClose();
        toast({
          title: 'Successful...!',
          description: res.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: 'Error...!',
        description: "Please fill all the fields correctly",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const renderPreview = () => {
    if (!filePreview) return null;

    const isVideo = /\.(mp4|webm|ogg)$/i.test(file.name);
    if (isVideo) {
      return (
        <video controls className="w-full h-auto">
          <source src={filePreview} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return (
        <img src={filePreview} alt="Preview" className="w-full h-auto" />
      );
    }
  };

  return (
    <>
      <Button onClick={onOpen}>{t('Create Collection')}</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('Create Collection')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="gameId" mb={4}>
              <FormLabel>{t('game')}</FormLabel>
              <Select
                options={gameOptions}
                value={selectedGame}
                onChange={setSelectedGame}
                placeholder={t("select-game")}
              />
            </FormControl>
            <FormControl id="file" mb={4}>
              <FormLabel>{t('Upload File')}</FormLabel>
              <Input type="file" accept="image/*,video/*" onChange={handleFileChange} />
              {renderPreview()}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              {t('close')}
            </Button>
            <Button colorScheme="blue" onClick={handleSubmit}>{loading ? <Spinner /> : t("save")}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateCollection;

// import moment from "moment/moment";
import { formatDateTime } from "../utills/formateDate";
import 'moment/locale/ar'; // Import Arabic locale for moment.js
import moment from 'moment-timezone'; // Import moment with timezone support

// HR EXPENSE CATEGORIES 
export const _hrExpenseCategories = [
    { value: 'travel', label: 'travel' },
    { value: 'recruitment', label: 'recruitment' },
    { value: 'training', label: 'training' },
    { value: 'medical', label: 'medical' },
    { value: 'employee-benefits', label: 'employee-benefits' },
    { value: 'meals', label: 'meals' },
    { value: 'supplies', label: 'supplies' },
    { value: 'equipment', label: 'equipment' },
    { value: 'accommodation', label: 'accommodation' },
    { value: 'software', label: 'software' },
    { value: 'other', label: 'other' }
]

// EMPLOYEMENT TYPE 
export const employmentType = [
    { value: "full-time", label: "full-time" },
    { value: "part-time", label: "part-time" },
    { value: "contract", label: "contract" }
]

// ROLE OPTIONS 
export const departmentOptions = [
    { value: 'OPERATION', label: 'operation' },
    { value: 'GAME-MANAGER', label: 'game-management' },
    { value: 'MEDIA-CENTER', label: 'media-center' },
    { value: 'ONLINE-STORE', label: 'inventory' },
    { value: 'ACCOUNT-MANAGER', label: 'finance' },
    { value: 'HR-MANAGEMENT', label: 'human-resource' },
    { value: 'ALRWAAD', label: 'alrwaad' },
];

// ALL ANOUNCEMENT CATEGORY 
export const hrAnnouncementCategories = [
    { value: 'general', label: 'general-announcement' },
    { value: 'policy-update', label: 'policy-update' },
    { value: 'holiday', label: 'holiday-notice' },
    { value: 'event', label: 'event-announcement' },
    { value: 'salary', label: 'salary-update' },
    { value: 'training', label: 'training-session' },
    { value: 'seminar', label: 'seminar' },
    { value: 'employee-benefits', label: 'employee-benefits' },
    { value: 'performance-review', label: 'performance-review' },
    { value: 'health-and-safety', label: 'health-and-safety' },
    { value: 'recruitment', label: 'recruitment-news' },
    { value: 'work-schedule', label: 'work-schedule' },
];

// ALL GAME ANOUNCEMENT CATEGORY 
export const gameAnnouncementCategories = [
    { value: 'general', label: 'general-announcement' },
    { value: 'policy-update', label: 'policy-update' },
    { value: 'event', label: 'event-announcement' },
    { value: 'alerts', label: 'alerts' }
];

// ALL EXPENSE STATUS 
export const allExpenseStatus = [
    { value: 'account-manager-review', label: 'account-manager-review' },
    { value: 'pending', label: 'pending' },
    { value: 'approved', label: 'approved' },
    { value: 'rejected', label: 'rejected' },
    { value: 'cancelled', label: 'cancelled' }
]

// ALL EXPENSE STATUS 
export const allPaymentMethod = [
    { value: 'cash', label: 'cash' },
    { value: 'personal-card', label: 'personal-card' },
    { value: 'corporate-card', label: 'corporate-card' },
    { value: 'bank-transfer', label: 'bank-transfer' },
    { value: 'other', label: 'other' }
]

// ALL EXPENSE STATUS 
export const allPriorities = [
    { value: 'urgent', label: 'urgent' },
    { value: 'high', label: 'high' },
    { value: 'medium', label: 'medium' },
    { value: 'low', label: 'low' }
]

// LEAVE FREQUENCY  OPTONS 
export const leaveFrequencyOptions = [
    { value: "Annual", label: "annual" },
    { value: "Emergency", label: "emergency" },
    { value: "Hajj Leave", label: "hajj-leave" },
    { value: "Absent", label: "absent" },
    { value: "Casual Leave", label: "casual-leave" },
    { value: "Sick Leave", label: "sick-leave" },
];


// REQUEST TYPE OPTIONS FOR EMPLOYEE 
export const requestTypeOptions = [
    { value: "Leave Request", label: "leave-request" },
    { value: "Salary Increment Request", label: "salary-increment-request" },
    { value: "Contract Renewal Request", label: "contract-renewal-request" },
    { value: "Training Request", label: "training-request" },
    { value: "Employee Certificate Request", label: "employee-certificate-request" },
    { value: "Fixation of Salary Request", label: "fixation-of-salary-request" },
    { value: "Authorization Letter", label: "authorization-letter" },
    { value: "Resignation Request", label: "resignation-request" },
];
// Format file name to show the first part (max 30 chars) and the extension
export const formatFileName = (name) => {
    try {
        // Extract the core file name without query parameters
        const baseNameWithParams = name.split('/').pop(); // Get the last segment of the path
        const [baseName, ...params] = baseNameWithParams.split('?');

        // Find the last period to determine the extension
        const dotIndex = baseName.lastIndexOf('.');
        if (dotIndex === -1) {
            return baseName; // No extension found, return the base name
        }

        const extension = baseName.slice(dotIndex); // Extract the extension
        if (extension.length < 4 || extension.length > 6) {
            throw new Error("Invalid extension length"); // Guard against invalid extensions
        }

        const fileNameWithoutExt = baseName.slice(0, dotIndex); // Extract the name without extension

        // Format file name
        if (fileNameWithoutExt.length > 30) {
            const truncatedName = fileNameWithoutExt.slice(0, 30 - extension.length - 3); // Truncate to fit 30 chars
            return `${truncatedName}...${extension}`; // Return formatted name
        }

        return `${fileNameWithoutExt}${extension}`; // Return full name if under limit
    } catch (error) {
        console.error('Error formatting file name:', error);
        return name; // Return the original name if something goes wrong
    }
};

// ALL ROLES 
export const allRoles = [
    { value: 'coach', label: 'coach' },
    { value: 'external user', label: 'users' },
    { value: 'internal user', label: 'player' },
    { value: 'guardian', label: 'guardian' },
    { value: 'super-admin', label: 'super-admin' },
    { value: 'employee', label: 'employee' },
    { value: 'organizer', label: 'organizer' },
    { value: 'alrwaad user', label: 'alrwaad-user' },
    { value: 'hr-manager', label: 'hr-manager' },
    { value: 'account-manager', label: 'account-manager' },
    { value: 'online-store-manager', label: 'online-store-manager' },
    { value: 'media-center-manager', label: 'media-center-manager' },
    { value: 'operation-manager', label: 'operation-manager' },
    { value: 'game-manager', label: 'game-manager' },
    { value: 'alrwaad-manager', label: 'alrwaad-manager' },
    { value: 'clinic-manager', label: 'clinic-manager' },
    { value: 'hr-secretory', label: 'hr-secretory' },
    { value: 'account-secretory', label: 'account-secretory' },
    { value: 'online-store-secretory', label: 'online-store-secretory' },
    { value: 'media-center-secretory', label: 'media-center-secretory' },
    { value: 'operation-secretory', label: 'operation-secretory' },
    { value: 'game-secretory', label: 'game-secretory' },
    { value: 'hr-assistant', label: 'hr-assistant' },
    { value: 'account-assistant', label: 'account-assistant' },
    { value: 'online-store-assistant', label: 'online-store-assistant' },
    { value: 'media-center-assistant', label: 'media-center-assistant' },
    { value: 'operation-assistant', label: 'operation-assistant' },
    { value: 'game-assistant', label: 'game-assistant' },
];

// ALL ROLES UNDER GAME MANAGER 
export const gameManagerRoles = [
    { value: 'coach', label: 'coach' },
    { value: 'internal user', label: 'player' }
]

// FORMAT DATE FUNCTION 
export const formatDate = (date) => {
    try {
        if (!date) return "N/A"; // Return "N/A" if no date is provided

        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const year = d.getFullYear();

        return `${day}/${month}/${year}`;
    } catch (e) {
        console.log(`ERROR WHILE FETCHING ${e}`);
    }
};

// Utility function to convert attendance data to CSV format
export const convertToCSV = (data) => {
    try {
        const headers = ['Name', 'EmployeeCode', 'PaidDays', 'PayableDays', 'OvertimeHours', 'MonthEndDate'];

        const rows = data.map((attendance) => [
            attendance?.employeeId?.name,
            attendance?.employeeId?.employeeCode,
            attendance.paidDays,
            attendance.payableDays,
            attendance.overTimeHours,
            formatDateTime(attendance.monthEndDate, 'date'),
        ]);

        const csvContent = [headers, ...rows].map((e) => e.join(",")).join("\n");
        return csvContent;
    } catch (e) {
        console.log(`ERROR WHILE CONVERTING CSV ${e}`);
    }
};

// utils/numberFormatter.js
export const formatNumber = (value, isArabic = false) => {
    try {
        if (value === null || value === undefined) {
            return '--';
        }

        // Round the number to two decimal places
        const roundedValue = value.toFixed(2);

        if (isArabic) {
            // Format for Arabic (SAR for example)
            return parseFloat(roundedValue).toLocaleString('ar-SA') || '--';
        } else {
            // Format for English (IN for example)
            return parseFloat(roundedValue).toLocaleString('en-IN') || '--';
        }
    } catch (e) {
        console.log(`ERROR WHILE FORMATTING DATE ${e}`);
    }
};

// Utility function to format date with day based on isArabic flag
export const formatDateWithDayArEn = (date, isArabic) => {
    try {
        if (!date) return ''; // If no date is provided, return an empty string

        // Set moment's locale based on the isArabic flag
        moment.locale(isArabic ? 'ar' : 'en'); // Set Arabic or English locale dynamically

        // Convert date to UTC and format it with day of the week, day, month, and year
        return moment.tz(date, 'UTC').format('dddd DD MMMM YYYY');
    } catch (e) {
        console.log(`ERROR WHILE FORMATTING DATE: ${e}`);
        return ''; // Return empty string in case of an error
    }
};

// Utility function to format date with day based on isArabic flag
export const formatDateTimeArEn = (date, isArabic) => {
    try {
        if (!date) return ''; // If no date is provided, return an empty string

        // Set moment's locale based on the isArabic flag
        moment.locale(isArabic ? 'ar' : 'en'); // Set Arabic or English locale dynamically

        // Parse the date as UTC
        const formattedDate = moment.utc(date);

        // Separate date and time
        const datePart = formattedDate.format("MMMM D");  // e.g., January 30
        const timePart = formattedDate.format("h:mm A");  // e.g., 5:00 PM

        return { datePart, timePart };
    } catch (e) {
        console.log(`ERROR WHILE FORMATTING DATE: ${e}`);
        return { datePart: '', timePart: '' }; // Return empty strings in case of an error
    }
};


// Utility function to format date based on isArabic flag
export const formatDateArEn = (date, isArabic) => {
    try {
        if (!date) return ''; // If no date is provided, return an empty string

        // Set moment's locale based on the isArabic flag
        moment.locale(isArabic ? 'ar' : 'en'); // Set Arabic or English locale dynamically

        // Format the date accordingly
        return moment(date).format(isArabic ? 'LL' : 'DD-MM-YYYY');
    } catch (e) {
        console.log(`ERROR WHILE FORMATTING DATE: ${e}`);
    }
};

// Utility function to format time based on isArabic flag
export const formatTimeArEn = (date, isArabic) => {
    try {
        if (!date) return ''; // If no date is provided, return an empty string

        // Set moment's locale based on the isArabic flag
        moment.locale(isArabic ? 'ar' : 'en');

        // Parse the date as UTC and format only the time
        return moment.utc(date).format('hh:mm A'); // Example: "05:30 PM"
    } catch (e) {
        console.log(`ERROR WHILE FORMATTING TIME: ${e}`);
        return ''; // Return empty string in case of an error
    }
};

// CONVERT DATE INTO THE READABE TIME WITH ARABIC OR ENGLISH NUMBERS
export const formatSessionTime = (dateString, isArabic) => {
    try {
        if (!dateString) return "00:00"; // If no date is provided, return default time

        // Set moment's locale based on the isArabic flag
        moment.locale(isArabic ? 'ar' : 'en'); // Set Arabic or English locale dynamically

        // Parse the date string into a moment object
        const date = moment(dateString);

        // Check if the date is valid
        if (!date.isValid()) {
            return "00:00"; // If invalid, return default time
        }

        // Format the time accordingly
        const formattedHours = date.format('HH'); // Get hours
        const formattedMinutes = date.format('mm'); // Get minutes

        // If Arabic, the hours should come after the minutes (right to left)
        if (isArabic) {
            const arabicNumbers = (str) =>
                str.replace(/\d/g, (digit) =>
                    String.fromCharCode(0x0660 + Number(digit))
                );
            return `${arabicNumbers(formattedMinutes)}:${arabicNumbers(formattedHours)}`;
        }

        // If English, the hours should come first (left to right)
        return `${formattedHours}:${formattedMinutes}`;
    } catch (e) {
        console.log(`ERROR WHILE FORMATTING TIME: ${e}`);
    }
};

// CONVERT TIME TO READABLE FORMAT WITH ARABIC OR ENGLISH NUMBERS
export function formatDailyAttendanceTime(minutes, isArabic) {
    try {
        const hours = Math.floor(minutes / 60); // Get total hours
        const remainingMinutes = minutes % 60; // Get remaining minutes

        // Pad with leading zeros for single digits
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(remainingMinutes).padStart(2, '0');

        if (isArabic) {
            // Convert numbers to Arabic (Eastern Arabic numerals)
            const arabicNumbers = (str) =>
                str.replace(/\d/g, (digit) =>
                    String.fromCharCode(0x0660 + Number(digit))
                );

            return `${arabicNumbers(formattedHours)}:${arabicNumbers(
                formattedMinutes
            )} ساعة`;
        }

        // Default English format
        return `${formattedHours}:${formattedMinutes} hrs`;
    } catch (e) {
        console.log(`ERROR WHILE FORMATTING TIME: ${e}`);
    }
}

// CONVERT DATE INTO THE READABE STRING 
export function formatDateToReadableString(isoDate, isArabic) {
    try {
        if (!isoDate) return null;

        // Set moment's locale based on the isArabic flag
        moment.locale(isArabic ? 'ar' : 'en'); // Set Arabic or English locale dynamically

        // Format the date accordingly
        return moment(isoDate).format(isArabic ? 'D MMMM YYYY' : 'D MMM, YYYY');
    } catch (e) {
        console.log(`ERROR WHILE FORMATTING DATE ${e}`);
    }
}

// Function to format the date in "Month YYYY" format for English and Arabic
export const formatMonthDateTime = (date, isArabic) => {
    try {
        if (!date) return ''; // Return an empty string if no date is provided

        const options = { year: 'numeric', month: 'long' };
        const locale = isArabic ? 'ar-SA' : 'en-US'; // Choose locale based on isArabic flag

        return new Date(date).toLocaleDateString(locale, options);
    } catch (e) {
        console.log(`ERROR WHILE FORMATTING MONTH ${e}`);
    }
};

export const formatPayrollMonth = (date, isArabic) => {
    try {
        if (!date) return '--';

        // Set moment locale based on isArabic flag
        const locale = isArabic ? 'ar' : 'en';
        moment.locale(locale);

        // Format the date to "MMMM YYYY"
        return moment(date).format('MMMM YYYY');
    } catch (error) {
        console.error('Error formatting date:', error);
        return '--';
    }
};

// GET STATUS COLOR FOR BOTH REQUESTS AND APPOINTMENTS IN CLINIC SECTION
export const getStatusColor = (status) => {
    try {
        switch (status) {
            // Request statuses
            case 'OPEN':
                return 'bg-blue-100 text-blue-800'; // Blue for open requests
            case 'IN-PROGRESS':
                return 'bg-yellow-100 text-yellow-800'; // Yellow for in-progress requests
            case 'CLOSED':
                return 'bg-green-100 text-green-800'; // Green for closed requests
            case 'PENDING_APPROVAL':
                return 'bg-orange-100 text-orange-800'; // Orange for pending approval
            case 'PENDING':
                return 'bg-orange-100 text-orange-800'; // Orange for pending approval
            case 'APPROVED':
                return 'bg-green-100 text-green-800'; // Green for approved requests
            case 'REJECTED':
                return 'bg-red-100 text-red-800'; // Red for rejected requests
            case 'REQUESTED':
                return 'bg-teal-100 text-teal-800'; // Teal for requested status (newly added)
            case 'MODIFIED':
                return 'bg-purple-100 text-purple-800'; // Purple for modified status


            // Appointment statuses
            case 'SCHEDULED':
                return 'bg-blue-100 text-blue-800'; // Light blue for scheduled appointments
            case 'ATTENDED':
                return 'bg-green-100 text-green-800'; // Green for attended appointments
            case 'CANCELLED':
                return 'bg-red-100 text-red-800'; // Red for cancelled appointments
            case 'MISSED':
                return 'bg-yellow-100 text-yellow-800'; // Yellow for missed appointments
            case 'COMPLETED':
                return 'bg-teal-100 text-teal-800'; // Teal for completed appointments
            case 'RESCHEDULED':
                return 'bg-purple-100 text-purple-800'; // Purple for rescheduled appointments
            case 'NO_SHOW':
                return 'bg-gray-100 text-gray-800'; // Gray for no-show appointments

            // Recovery statuses
            case 'NOT_STARTED':
                return 'bg-gray-100 text-gray-800'; // Gray for not started recovery
            case 'IN_PROGRESS':
                return 'bg-yellow-100 text-yellow-800'; // Yellow for recovery in progress
            case 'COMPLETED':
                return 'bg-green-100 text-green-800'; // Green for completed recovery
            case 'FOLLOW_UP':
                return 'bg-blue-100 text-blue-800'; // Blue for follow-up recovery
            case 'REQUIRES_FURTHER_TREATMENT':
                return 'bg-orange-100 text-orange-800'; // Orange for further treatment required
            case 'DISCHARGED':
                return 'bg-teal-100 text-teal-800'; // Teal for discharged recovery status

            // REQUEST TYPE S
            case 'EXTERNAL':
                return 'bg-purple-100 text-purple-800'; // External type color
            case 'INTERNAL':
                return 'bg-teal-100 text-teal-800'; // Internal type color
            case 'CORPORATE':
                return 'bg-orange-100 text-orange-800'; // Corporate type color

            default:
                return 'bg-gray-100 text-gray-800'; // Default gray color if status doesn't match
        }
    } catch (e) {
        console.log(`ERROR WHILE GETTING THE STATUS COLOR ${e}`);
    }
};

// FORMATTING DATE / TIME FOR INPUT 
export const formatDateForInput = (isoString) => {
    try {
        if (!isoString) return "";
        return isoString.slice(0, 16); // Trims the date to "YYYY-MM-DDTHH:MM"
    } catch (e) {
        console.log(`ERROR WHILE FORMATING DATE TIME ${e}`);
    }
};

// Function to format the date and check if it's valid
export const formatAndValidateDate = (dateString) => {
    if (!dateString) return "";

    // Format the date to 'YYYY-MM-DD'
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Extract the date part (YYYY-MM-DD)
};
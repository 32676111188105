import React, { useEffect, useState } from 'react';
import { GrAttachment } from "react-icons/gr";
import { Badge, Button, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Tooltip, useDisclosure } from '@chakra-ui/react';
import { IoEyeOutline } from "react-icons/io5";
import { CiSaveDown2 } from "react-icons/ci";
import { formatDistanceToNow } from 'date-fns';
// import { Badge, Button, IconButton, Tooltip } from '@chakra-ui/react';
import { UserState } from '../../../../context/user';
import { createAttachment } from '../../../../services/apis/api';
import { useTranslation } from 'react-i18next';


// PRIORITY STYLES 
const priorityStyles = {
    urgent: {
        bgColor: 'bg-[#fde2e1]',
        textColor: 'text-[#981b1b]',
    },
    high: {
        bgColor: 'bg-[#ffedd5]',
        textColor: 'text-[#a24425]',
    },
    medium: {
        bgColor: 'bg-[#faf8d0]',
        textColor: 'text-[#a67d55]',
    },
    default: {
        bgColor: 'bg-[#dbe9fe]',
        textColor: 'text-[#1d40af]',
    },
};

const AnnouncementDetailDialogue = ({
    announcement,
    setAnnouncement,
    isOpen,
    onOpen,
    onClose
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const { user } = UserState();
    const priority = announcement?.priority?.toLowerCase();
    const { bgColor, textColor } = priorityStyles[priority] || priorityStyles.default;
    const [selectedAttachment, setSelectedAttachment] = useState(null)

    // Open dialog when announcement exists
    useEffect(() => {
        if (announcement) {
            onOpen();
        }
    }, [announcement, onOpen]);

    // Close dialog when user sets announcement to null
    const handleClose = () => {
        onClose();
        setAnnouncement(null);  // Clear the selected attachment
    };

    if (!announcement) return null;

    return (
        <>
            {/* Chakra UI Modal for attachment viewer */}
            <Modal isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay>
                    <ModalContent>
                        <ModalHeader>
                            {announcement?.title}
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody maxW="3xl" h="80vh">
                            {/* <div className="flex-1 overflow-hidden rounded-lg border">
                                {announcement?.title}
                            </div> */}
                            <div className={`rounded-lg p-4 w-full transition-all duration-300 cursor-pointer  bg-gray-50 hover:bg-gray-100`} >
                                <div className="flex w-full items-start gap-4">
                                    <div className="flex-1">
                                        {/* <div className="flex items-start justify-between gap-2">
                                            <h4 className="text-sm lg:text-lg font-semibold leading-none">
                                                <Tooltip label={announcement?.title?.length > 60 ? announcement?.title : ''} hasArrow>
                                                    <h4 className="text-sm font-semibold text-ellipsis leading-none">
                                                        {announcement?.title?.length > 60
                                                            ? `${announcement.title.slice(0, 60)}...`
                                                            : announcement?.title}
                                                    </h4>
                                                </Tooltip>
                                            </h4>

                                        </div> */}
                                        {/* <div className='flex w-full items-center gap-2 py-1'>
                                            <div className={`${bgColor} w-fit py-1 text-xs px-2 capitalize font-semibold border border-gray-200 rounded-base ${textColor}`}>
                                                {announcement?.priority}
                                            </div>
                                            <Badge variant="outline" p={1} px={2} rounded={4}>
                                                {announcement?.createdAt
                                                    ? formatDistanceToNow(new Date(announcement?.createdAt), { addSuffix: true })
                                                    : "Just now"}
                                            </Badge>
                                        </ div> */}
                                        {/* <div className='min-h-8 max-h-8 flex items-center justify-start'>
                                            <p className="mt-1 text-sm text-muted-foreground">
                                                <Tooltip label={announcement?.description} hasArrow>
                                                    {announcement?.description?.length > 40
                                                        ? `${announcement.description.slice(0, 40)}...`
                                                        : announcement?.description}
                                                </Tooltip>
                                            </p>
                                        </div> */}
                                        {announcement?.attachments && announcement?.attachments.length > 0 && (
                                            <div className="space-y-4">
                                                <div className='w-full flex items-center justify-between'>
                                                    <h3 className="text-sm font-medium">{t('attachments')}:</h3>
                                                </div>
                                                <div className="space-y-2">
                                                    {announcement?.attachments.map((attachment) => {
                                                        // Extract filename from URL
                                                        const _formattedAttchment = createAttachment(attachment);
                                                        const truncatedName = _formattedAttchment?.name?.length > 15
                                                            ? `${_formattedAttchment?.name?.slice(0, 15)}...`
                                                            : _formattedAttchment?.name;
                                                        return (
                                                            <div
                                                                key={attachment?.id}
                                                                className="flex items-center justify-between p-3 rounded-lg border bg-muted/50"
                                                            >
                                                                <div className="flex items-center gap-3">
                                                                    <GrAttachment
                                                                        fontSize="small"
                                                                        color='#878d98'
                                                                    />
                                                                    <div>
                                                                        <p className="text-sm font-medium">{truncatedName || 'View Attachment'}</p>
                                                                        <p className="text-xs text-muted-foreground">{_formattedAttchment?.size}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex gap-2">
                                                                    <IconButton
                                                                        variant="ghost"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            window.open(attachment, '_blank', 'noopener,noreferrer');
                                                                        }}
                                                                    >
                                                                        <IoEyeOutline />
                                                                    </IconButton>
                                                                    {/* <IconButton
                                                        variant="ghost"
                                                        size="sm"
                                                    >
                                                        <CiSaveDown2 />
                                                    </IconButton> */}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </ModalContent>
                </ModalOverlay>
            </Modal>
        </>
    );
};

export default AnnouncementDetailDialogue;

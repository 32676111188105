import React, { useState, useEffect } from 'react';
import {
    Box,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Avatar,
    Input,
    InputGroup,
    InputLeftElement,
    Button,
    IconButton,
    useDisclosure,
    Tooltip,
} from '@chakra-ui/react';
import { SearchIcon, ViewIcon } from '@chakra-ui/icons';
import CreateAsset from '../../dialogBoxes/CreateAssets';
import { get } from '../../../../services/apis/api';
import ViewAssetDetailsModal from './ViewAssetDetailsModal';
import { AssetState } from '../../../../context/assets';
import AssetsTable from '../../../account/AssetsTable';
import { debounce } from 'lodash';

// TABLE COLUMNS 
const columns = [
    's-no',
    'asset-name',
    // 'category',
    'asset-model',
    'asset-id',
    'purhcase-date',
    // 'depritiated-rate',
    // 'depritiated-freuency',
    'status',
    'condition',
    // 'Description',
    'Actions'
];

const ComponentAssets = () => {
    const [assets, setAssets] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const { assetRefresh, setAssetRefresh, } = AssetState('')
    const [limit,setLimit] = useState(10); // Fixed limit of 10 items per page
    const [totalPages, setTotalPages] = useState(1);
    const [selectedAsset, setSelectedAsset] = useState(null); // State to hold selected asset for viewing
    const [skip, setSkip] = useState(0); // Initial skip should be 0

    const { isOpen, onOpen, onClose } = useDisclosure(); // Chakra UI's modal controls

    const getAssets = async () => {
        try {
            const res = await get(`assets/all?page=${page}&limit=${limit}&search=${search}`);
            if (res.statusCode === 200) {
                setAssets(res.data.data);
                const totalCount = res?.data?.totalCount || 0; // Ensure totalCount is defined
                setTotalPages(Math.ceil(totalCount / limit)); // Calculate total pages
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        }
    };

    useEffect(() => {
        getAssets();
    }, [page,skip,limit, assetRefresh]);

        // Debounce the getAllAssets function
        const debouncedGetAllRequest = debounce(getAssets, 300); // Adjust delay as needed

        useEffect(() => {
            debouncedGetAllRequest();
            // Cleanup function to cancel the debounced call on component unmount
            return () => {
                debouncedGetAllRequest.cancel();
            };
        }, [search]);
    

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPage(1); // Reset to page 1 when search changes
    };

    const handleNextPage = () => {
        if (page < totalPages) setPage(page + 1);
    };

    const handlePrevPage = () => {
        if (page > 1) setPage(page - 1);
    };

    // Function to handle viewing the asset details
    const handleViewDetails = (asset) => {
        setSelectedAsset(asset);
        onOpen();
    };

    return (
        <Box width="100%" p={4}>
            <Flex justify="space-between" align="center" mb={4}>
                <Box fontSize="xl" fontWeight="bold">
                    Asset Management
                </Box>
                <Flex align="center">
                    <InputGroup mr={2}>
                        <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder="Search assets"
                            value={search}
                            onChange={handleSearchChange}
                        />
                    </InputGroup>
                    {/* <CreateAsset getAssets={getAssets} isEdit={false} /> */}
                </Flex>
            </Flex>
            <div className="w-full   justify-between py-4 gap-2 lg:gap-4 items-start">
                <AssetsTable
                    page={page}
                    skip={skip}
                    limit={limit}
                    setSkip={setSkip}
                    setLimit={setLimit}
                    selectedAsset={selectedAsset}
                    setSelectedAsset={setSelectedAsset}
                    setPage={setPage}
                    columns={columns}
                    rows={assets}
                    totalPages={totalPages}
                    onOpen={onOpen}
                    getAllAssets={getAssets}
                />
            </div>

            {/* Modal for viewing asset details */}
            {selectedAsset && (
                <ViewAssetDetailsModal
                    isOpen={isOpen}
                    onClose={onClose}
                    asset={selectedAsset}
                />
            )}
        </Box>
    );
};

export default ComponentAssets;

import {
    Spinner,
    useDisclosure,
    useToast,
    Box,
    Badge,
    Heading,
    Text,
    Button,
    Flex,
    SimpleGrid,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Image,
    ModalCloseButton

} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { get, patch, post } from '../../services/apis/api';
import CustomAlert from '../alerts/CustomAlert';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { formatDateTime } from '../../utills/formateDate';
import BreadCrumbs from '../BreadCrumbs';
import { useTranslation } from 'react-i18next';
import { formatDateArEn, formatNumber } from '../../services/glocalFunctions';

const statusRoleMapping = {
    'GAME-MANAGER-REVIEW': 'ACCOUNT-MANAGER-REVIEW',
    'ACCOUNT-MANAGER-REVIEW': 'ACCEPTED',
};


const roleToTypeAndStatus = {
    'GAME-MANAGER': 'GAME-MANAGER-REVIEW',
    'ACCOUNT-MANAGER': 'ACCOUNT-MANAGER-REVIEW',
};

const ViewTournamentRequest = ({ requestId, setView, user, getAllRequest }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [loading, setLoading] = useState(false);
    const [requestAction, setRequestAction] = useState(false);
    const [request, setRequest] = useState({});
    const [canApprove, setCanApprove] = useState(false);
    const [approvedAmount, setApprovedAmount] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const {
        isOpen: isImageOpen,
        onOpen: onImageOpen,
        onClose: onImageClose
    } = useDisclosure();
    const toast = useToast();
    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
        onImageOpen();
    };

    const getRequestDetails = async () => {
        try {
            const res = await get(`request/get/${requestId}`);
            if (res.statusCode === 200) {
                setRequest(res.data.request);
                const userCanApprove = roleToTypeAndStatus[user?.role] === res.data.request?.status;
                setCanApprove(userCanApprove);
            }
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to fetch request details.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        getRequestDetails();
    }, [requestId]);

    const handleApprove = async () => {
        const nextStatus = statusRoleMapping[request?.status];
        if (!nextStatus) {
            toast({
                title: "Error",
                description: "Cannot approve this request.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        setLoading(true);
        try {
            let res;
            if (request.status == 'GAME-MANAGER-REVIEW') {
                res = await patch(`request/update/tournament/${requestId}`, { status: 'ACCOUNT-MANAGER-REVIEW', approvedAmount, userId: user._id, role: user?.role });
            }
            else {
                res = await patch(`request/update/tournament/${requestId}`, { status: 'ACCOUNT-MANAGER-REVIEWED', approvedAmount, userId: user._id, role: user?.role });
            }
            setLoading(false);
            if (res.statusCode === 200) {
                getRequestDetails();
                getAllRequest();
                toast({
                    title: "Success",
                    description: res?.data?.message,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                throw new Error(res?.message);
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: "Error",
                description: error.message || "Failed to approve the request.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleReject = async () => {
        try {
            setLoading(true);
            const res = await patch(`request/update/tournament/${requestId}`, { status: "REJECTED", rejectedBy: user._id, userId: user._id, role: user?.role });
            setLoading(false);
            if (res.statusCode === 200) {
                getRequestDetails();
                getAllRequest();
                onClose();
                toast({
                    title: "Success",
                    description: "Request has been rejected.",
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                throw new Error(res.message);
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: "Error",
                description: error.message || "Failed to reject the request.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleComplete = async () => {
        setLoading(true);
        try {
            setLoading(false);
            // console.log(request, 'request------>');
            // const approvedAmount = request?.tournamentId?.approvedAmount || 0;
            // const exceededAmount = request?.tournamentId?.exceededAmount || 0;
            // const remainingAmount = request?.tournamentId?.remainingAmount || 0;
            // // Calculate the total amount using the formula
            // const finalAmount = (approvedAmount + exceededAmount) - remainingAmount;

            const approvedAmount = request?.tournamentId?.approvedAmount || 0;
            const exceededAmount = request?.tournamentId?.exceededAmount || 0;
            const remainingAmount = request?.tournamentId?.remainingAmount || 0;

            // Calculate the final amount conditionally
            const finalAmount = (exceededAmount === 0 && remainingAmount === 0)
                ? approvedAmount // When both are not available
                : (exceededAmount > 0 && remainingAmount === 0)
                    ? approvedAmount + exceededAmount // When only exceededAmount is available
                    : (approvedAmount + exceededAmount) - remainingAmount; // When both are available

            console.log(finalAmount, "Final Amount");

            const res = await patch(`request/update/tournament/${requestId}`, { status: 'APPROVED', approvedAmount: finalAmount, userId: user._id, role: user?.role });
            setLoading(false);
            if (res.statusCode === 200) {
                getRequestDetails();
                getAllRequest();
                toast({
                    title: "Success",
                    description: res.data.message,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                throw new Error(res?.message);
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: "Error",
                description: error.message || "Failed to approve the request.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const confirmApprove = () => {
        if (request.status === 'ACCOUNT-MANAGER-REVIEW' && !approvedAmount) {
            toast({
                title: "Error",
                description: "Please enter the approved amount.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        handleApprove();
        onClose();
    };

    const confirmComplete = () => {

        handleComplete();
        onClose();
    };
    const handleApproveRejectRequest = (type) => {
        setRequestAction(type);
        onOpen();
    };
    const handleApproveRejectReceipt = (type) => {
        setRequestAction(type);  // Set the action type directly
        onOpen();  // Open the popup
    };

    const handleApproveOrRejectReceipt = async ({ status, receiptId }) => {

        setLoading(true);
        try {
            const res = await post(`tournament/${request?.tournamentId._id}/receipts/${receiptId}`, { status: `${status}` });
            setLoading(false);
            if (res.statusCode === 200) {
                getRequestDetails();
                getAllRequest();
                if (status === 'APPROVED') {
                    toast({
                        title: "Success",
                        description: res.data.message,
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: "Success",
                        description: "Request has been rejected.",
                        status: "warning",
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } else {
                throw new Error(res?.message);
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: "Error",
                description: error.message || "Failed to approve the request.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Box>
            <Flex flexDirection={{ base: 'column', md: 'row', lg: 'row' }} justifyContent="space-between" alignItems={{ base: 'flex-start', md: 'center', lg: 'center' }} gap={2} mb={5}>
                <Button size={"sm"} onClick={() => setView(false)} colorScheme="blue" leftIcon={<IoMdArrowRoundBack />}>
                    {t('back')}
                </Button>
                {user?.role === 'ACCOUNT-MANAGER' && request?.status?.toLowerCase() === 'approved' && (
                    <Button
                        colorScheme="blue"
                        size="lg"
                        mr={3}
                        variant={"ghost"}
                    >
                        {loading ? <Spinner size="sm" /> : t('completed')}
                    </Button>
                )}
                {user?.role == 'ACCOUNT-MANAGER' && request?.status === 'ACCOUNT-MANAGER-REVIEWED' && (
                    <Box>
                        <Button
                            colorScheme="red"
                            size="sm"
                            mr={3}
                            onClick={() => handleApproveRejectReceipt('ACCOUNT-MANAGER-REVIEWED')}
                        >
                            {loading ? <Spinner size="sm" /> : t('complete-request')}
                        </Button>
                    </Box>
                )}
                {canApprove && (
                    <div className='flex items-center gap-2 lg:gap-4'>
                        {canApprove && request.status === 'ACCOUNT-MANAGER-REVIEW' && (
                            <Box>
                                <Input
                                    placeholder="Enter approved amount"
                                    value={approvedAmount}
                                    onChange={(e) => setApprovedAmount(e.target.value)}
                                    type="number"
                                    min={0}
                                />
                            </Box>
                        )}
                        <Button size="sm" onClick={() => handleApproveRejectRequest('REJECTED')} colorScheme="red" mr={3}>
                            {loading ? <Spinner size="sm" /> : t('reject-request')}
                        </Button>
                        <Button size="sm" onClick={() => handleApproveRejectRequest('APPROVED')} colorScheme="blue">
                            {loading ? <Spinner size="sm" /> : t('approve-request')}
                        </Button>
                    </div>
                )}
            </Flex>

            <Heading textAlign={isArabic ? "right" : "left"}  as="h2" size="lg" mb={4}>{t('request-details')}</Heading>
            <SimpleGrid columns={[1, null, 2]} spacing={5} mb={5}>
                <Box bg="gray.100" p={4} borderRadius="md">
                    <Text><strong>{t('request-by')}:</strong> <span className='text-[#485060]'>{request?.requestedBy?.name}</span></Text>
                </Box>
                <Box bg="gray.100" p={4} borderRadius="md">
                    <Text>
                        <strong>{t('request-status')}:</strong> <Badge colorScheme={(request?.status === "APPROVED") ? 'green' : (request?.status === "REJECTED") ? 'red' : 'orange'}>{t(request?.status?.toLowerCase())}</Badge>
                    </Text>
                </Box>
                <Box bg="gray.100" p={4} borderRadius="md">
                    <Text><strong>{t('request-type')} :</strong> <span className='text-[#485060]'>{request?.kind}</span></Text>
                </Box>
                <Box bg="gray.100" p={4} borderRadius="md">
                    <Text><strong>{('tournament-name')} :</strong> <span className='text-[#485060]'>{request?.tournamentId?.name}</span></Text>
                </Box>
            </SimpleGrid>

            <Heading as="h3" size="md" mb={2}>{t('tournament-details')}</Heading>
            <Box bg="gray.100" p={4} borderRadius="md">
                <SimpleGrid columns={[1, null, 2]} spacing={5}>
                    <Text><strong>{t('start-date')} :</strong> {request?.tournamentId?.startDate ? formatDateArEn(request?.tournamentId?.startDate, isArabic) : 'N/A'}</Text>
                    <Text><strong>{t('end-date')}:</strong> {request?.tournamentId?.endDate ? formatDateArEn(request?.tournamentId?.endDate, isArabic) : 'N/A'}</Text>
                    <Text><strong>{t('location')}:</strong> {request?.tournamentId?.location || 'N/A'}</Text>
                    <Text><strong>{t('travel-date')}:</strong> {request?.tournamentId?.travelDate ? formatDateArEn(request?.tournamentId?.travelDate, isArabic) : 'N/A'}</Text>
                    <Text><strong>{t('transport-medium')}:</strong> {request?.tournamentId?.transportMedium || 'N/A'}</Text>
                    <Text><strong>{t('expected-departure')}:</strong> {request?.tournamentId?.expectedDeparture ? formatDateArEn(request?.tournamentId?.expectedDeparture, isArabic) : 'N/A'}</Text>
                    <Text><strong>{t('expected-arrival')}:</strong> {request?.tournamentId?.expectedArrival ? formatDateArEn(request?.tournamentId?.expectedArrival, isArabic) : 'N/A'}</Text>
                    <Text><strong>{t('from')}:</strong> {request?.tournamentId?.from || 'N/A'}</Text>
                    <Text><strong>{t('to')}:</strong> {request?.tournamentId?.to || 'N/A'}</Text>
                    <Text><strong>{t('team-name')}:</strong> {request?.tournamentId?.teamName || 'N/A'}</Text>
                    <Text><strong>{t('requested-amount')}:</strong> {formatNumber(request?.tournamentId?.requestedAmount, isArabic) || 'N/A'}</Text>
                    <Text><strong>{t('approved-amount')}:</strong> {formatNumber(request?.tournamentId?.approvedAmount, isArabic) || 'N/A'}</Text>
                    <Text><strong>{t('remaining-amount')}:</strong> {formatNumber(request?.tournamentId?.remainingAmount, isArabic) || 'N/A'}</Text>
                    <Text><strong>{t('exceeded-amount')}:</strong> {formatNumber(request?.tournamentId?.exceededAmount, isArabic) || 'N/A'}</Text>
                </SimpleGrid>
            </Box>

            {requestAction === 'APPROVED' ? (
                <CustomAlert
                    isOpen={isOpen}
                    onClose={onClose}
                    title="Approve request"
                    description="Are you sure you want to approve this request?"
                    onConfirm={confirmApprove}
                    confirmButtonText="Approve"
                    cancelButtonText="Cancel"
                    confirmButtonColorScheme="blue"
                />
            ) : requestAction === 'ACCOUNT-MANAGER-REVIEWED' ? (
                <CustomAlert
                    isOpen={isOpen}
                    onClose={onClose}
                    title="Completed request"
                    description="Are you sure you want to complete this request?"
                    onConfirm={confirmComplete}
                    confirmButtonText="Complete"
                    cancelButtonText="Cancel"
                    confirmButtonColorScheme="blue"
                />
            ) : (
                <CustomAlert
                    isOpen={isOpen}
                    onClose={onClose}
                    title="Reject request"
                    description="Are you sure you want to reject this request?"
                    onConfirm={handleReject}
                    confirmButtonText="Reject"
                    cancelButtonText="Cancel"
                    confirmButtonColorScheme="red"
                />

            )
            }
            {request?.tournamentId?.receipts?.length > 0 && (
                <>
                    <Heading as="h3" size="md" mt={8} mb={2}>{t('receipts')}</Heading>
                    {request?.tournamentId?.receipts?.map((receipt) => (

                        <Box key={receipt?._id} bg="gray.100" p={4} borderRadius="md" mb={4}>
                            <img
                                src={receipt?.invoice}
                                alt="Invoice"
                                style={{
                                    width: '150px',
                                    height: '150px',
                                    objectFit: 'cover'
                                }}
                                onClick={() => handleImageClick(receipt?.invoice)}
                            />

                            <SimpleGrid columns={[1, null, 1]} spacing={2}>

                                <Text><strong>{t('title')}:</strong> {receipt?.title}</Text>
                                <Text><strong>{t('amount')}:</strong> {formatNumber(receipt?.amount, isArabic) || 'N/A'}</Text>
                                <Text><strong>{t('description')}:</strong> {receipt?.description || 'N/A'}</Text>

                                {receipt.status == 'PENDING' ?
                                    user?.role == 'ACCOUNT-MANAGER' && request?.status === 'ACCOUNT-MANAGER-REVIEWED' &&
                                    <Box>
                                        <Button
                                            size="sm"
                                            colorScheme="green"
                                            mr={3}
                                            onClick={() => handleApproveOrRejectReceipt({ receiptId: receipt?._id, status: "APPROVED" })}
                                            disabled={loading}
                                        >
                                            {t('approve')}
                                        </Button>
                                        <Button
                                            size="sm"
                                            colorScheme="red"
                                            onClick={() => handleApproveOrRejectReceipt({ receiptId: receipt?._id, status: "REJECTED" })}
                                            disabled={loading}
                                        >
                                            {t('reject')}
                                        </Button>
                                    </Box>

                                    : <Text><strong>{t('status')}:</strong> {t(receipt?.status?.toLowerCase()) || 'N/A'}</Text>
                                }

                            </SimpleGrid>
                        </Box>
                    ))}
                    <Modal isOpen={isImageOpen} onClose={onImageClose} size="full">
                        <ModalOverlay />
                        <ModalContent>
                            <ModalCloseButton />
                            <ModalBody p={20}>
                                {selectedImage && (
                                    <Image src={selectedImage} alt="Full-screen Invoice" style={{

                                        objectFit: 'cover'
                                    }} />
                                )}
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                </>
            )}
        </Box>
    );

};

export default ViewTournamentRequest;

import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Button,
  useToast,
  Spinner,
  Switch
} from '@chakra-ui/react';
import { Select } from '@chakra-ui/react';
import { post,get, patch } from '../../services/apis/api';

const CreateSalesLedger = ({getAllSalesLedgers, salesLedger,isEdit}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const initialRef = useRef(null);
    const finalRef = useRef(null);
    const toast = useToast();

    console.log(salesLedger);

    const [formValues, setFormValues] = useState({
        customerDetails: {
            name: '',
            phone: '',
            email: '',
        },
        product: '',
        quantity: '',
        price: '',
        saleDate: ''
    });

    const fetchProducts = async () => {
        try {
          const res = await get('product/dropdown'); 
          setProducts(res.data.dropdown);
        } catch (error) {
          console.error('Error fetching products:', error);
        }
      };
      useEffect(() => {
        if (isEdit) {
            setFormValues({
                customerDetails: {
                    name: salesLedger.customerDetails.name,
                    phone: salesLedger.customerDetails.phone,
                    email: salesLedger.customerDetails.email,
                },
                product: salesLedger.product?._id,
                quantity: salesLedger.quantity,
                price: salesLedger.price,
                saleDate: new Date(salesLedger.saleDate),
                isActive: salesLedger.isActive,

        })}
        fetchProducts();
      }, []);

      const handleSwitchChange = (e) => {
        setFormValues({
          ...formValues,
          isActive: e.target.checked,
        });
      };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const keys = name.split('.');
    
        if (keys.length === 2) {
            // For nested fields in customerDetails
            setFormValues((prevState) => ({
                ...prevState,
                customerDetails: {
                    ...prevState.customerDetails,
                    [keys[1]]: value
                }
            }));
        } else {
            // For other fields at the top level
            setFormValues((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    };
    

    const handleSubmit = async() => {
        try {
            setIsLoading(true)
            console.log(formValues);
            let res
            if (isEdit) {
                res = await patch(`sales-ledger/update/${salesLedger._id}`, formValues);
            }else{
                res = await post('sales-ledger/add', formValues);
            }
            
            console.log(res);
            if (res.statusCode === 201 || res.statusCode === 200) {
                onClose();
                getAllSalesLedgers();
                setIsLoading(false)
                toast({
                    title: 'Successfull...!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            setIsLoading(false)
            toast({
                title: 'Opps...!',
                description: error?.response?.data?.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
            console.log(error);
        }
        
        
    };

    return (
        <>
            <Button colorScheme='blue' onClick={onOpen}>{isEdit ? "Edit" : "Create SalesLedger"}</Button>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Create New SalesLedger</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input
                        ref={initialRef}
                        placeholder='Name'
                        name='customerDetails.name'
                        value={formValues.customerDetails.name}
                        onChange={handleChange}
                    />
                
                    <FormLabel>Phone</FormLabel>
                    <Input
                        placeholder='Phone'
                        type="number"
                        name='customerDetails.phone'
                        value={formValues.customerDetails.phone}
                        onChange={handleChange}
                    />
                   

                    <FormLabel>Email</FormLabel>
                    <Input
                        ref={initialRef}
                        placeholder='Email'
                        name='customerDetails.email'
                        value={formValues.customerDetails.email}
                        onChange={handleChange}
                    />
                    </FormControl>

                    <FormLabel>Date</FormLabel>
                    <Input
                        placeholder='Select Date and Time'
                        size='md'
                        type='datetime-local'
                        name='saleDate'
                        value={formValues.saleDate}
                        onChange={handleChange}
                    />

                    <FormControl mt={4}>
                    <FormLabel>Product</FormLabel>
                    <Select
                        placeholder='Select Product'
                        name='product'
                        value={formValues.product}
                        onChange={handleChange}
                    >
                        {products.map((item, index) => (
                        <option key={index} value={item._id}>
                            {item.name}
                        </option>
                        ))}
                   
                    </Select>
                    
                    </FormControl>
                    <FormControl mt={4}>
                    <FormLabel>Quantity</FormLabel>
                    <Input
                        placeholder='Quantity'
                        type="number"
                        name='quantity'
                        value={formValues.quantity}
                        onChange={handleChange}
                    />
                    </FormControl>

                    <FormLabel>Price</FormLabel>
                    <Input
                        ref={initialRef}
                        type="number"
                        placeholder='Price'
                        name='price'
                        value={formValues.price}
                        onChange={handleChange}
                    />
                       {isEdit && (
                <>
                  <FormControl mt={5} display='flex' alignItems='center'>
                    <FormLabel htmlFor='email-alerts' mb='0'>
                      Status of Sales Ledger ?
                    </FormLabel>
                    <Switch id='email-alerts' name='isActive' onChange={handleSwitchChange} isChecked={formValues.isActive} />
                  </FormControl>
                </>
              )}
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={handleSubmit}>
                    {isLoading ? <Spinner /> : isEdit ? "Update" : "Save"}
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateSalesLedger;

import React, { useEffect, useState } from 'react'
import { get, patch } from '../../services/apis/api';
import CreateTeam from '../../components/dialogueBoxes/CreateTeam'
import ViewEvent from '../../components/mediaCenter/ViewEvent';
import ViewCoachProfile from '../../components/game-management/ViewCoachProfile';
import CreateCoach from '../../components/dialogueBoxes/CreateCoach';
import { truncateText } from '../../utills/truncateText';
import { useTranslation } from 'react-i18next';
import { Switch } from '@chakra-ui/react';


const Coach = () => {
    const [coaches, setCoaches] = useState([]);
    const [viewCoach, setViewCoach] = useState(false);
    const [selectedCoach, setSelectedCoach] = useState({});
    const [search, setSearch] = useState('');

    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const handleViewCoach = (coach)=>{
        setViewCoach(true);
        setSelectedCoach(coach);
    }

    const getAllCoaches = async(searchQuery = '')=>{
        const res = await get(`coach/all${searchQuery ? `?search=${searchQuery}` : ''}`);
        if(res.statusCode === 200){
            setCoaches(res.data.coaches)
        }
    }

    useEffect(() => {
        getAllCoaches();
    }, [])

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearch(query);
        getAllCoaches(query);
    };

    const handleCheckboxChange = async (id, value) => {
        try {
            const res = await patch(`coach/update/${id}`, { isActive: value });
            console.log(res);
            if (res.statusCode === 200) {
                getAllCoaches();
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    
    return (
        <div>
        {
            viewCoach ? 
                <ViewCoachProfile coach={selectedCoach} setViewCoach={setViewCoach} getAllCoaches={getAllCoaches}/>      : 
                <div>
                <div className={`flex justify-between px-2 items-center mb-4 ${isArabic ? "flex-row-reverse" : "row"}`}>
                    <div>
                        <div className="relative">
                            <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input
                                type="text"
                                id="table-search-users"
                                className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                                placeholder={t("search-for-games")}
                                value={search}
                                onChange={handleSearchChange}     
                            />
                        </div>
                    </div>
                    <div>
                    <CreateCoach getAllCoaches={getAllCoaches}/>
                    </div>
                </div>
                <div>
                    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500 " dir={isArabic ? 'rtl' : 'ltr'}>
                            <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
                                <tr>
                                    <th scope="col" class="p-4 w-24" className={`px-4 py-3 ${isArabic ? 'w-36' : 'w-20'}`}>
                                        <div class="flex items-center">
                                        {t("s-no")}
                                        </div>
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        {t("coach-name")}
                                    </th>
                                  
                                    <th scope="col" class="px-6 py-3">
                                        {t("game")}
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        {t("experience")}
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        {t("specialization")}
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        {t("action")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    coaches.map((coach, index)=>(
                                        <tr key={index} class="bg-white border-b  hover:bg-gray-50 ">
                                            <td class="w-4 p-4">
                                                <div class="flex items-center">
                                                {index+1}
                                                </div>
                                            </td>
                                            <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap ">
                                            <div class="text-base font-semibold">{coach.name}</div>                                    
                                            </th>
                                         
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {coach?.gameId?.name}
                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                {coach.experience}
                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {truncateText(coach.specialization, 150)}
                                                </div>
                                            </td>
                                            <td className="px-4 py-4 flex items-center">
                                                <Switch
                                                    id={`coach-${coach._id}`}
                                                    isChecked={coach.isActive}
                                                    onChange={() => handleCheckboxChange(coach._id, !coach.isActive)}
                                                    size="lg"
                                                    colorScheme="blue"
                                                />
                                                <button onClick={()=>{handleViewCoach(coach)}} className='text-[#ffffff] bg-[#290dbd] font-semibold py-2 px-4 rounded-md mx-4' >{t("view")}</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                </div>

        }
    </div>
    )
}

export default Coach
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    Input,
    FormLabel,
    Button,
    Select,
    useToast,
    Textarea,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import ReactSelect from 'react-select';

// ALL ROLES 
export const allDoctors = [
    { value: 'clinic-manager', label: 'Clinic Manager' } // Single static option
];

function ScheduleAppointmentModal({
    isOpen,
    onClose,
    scheduleFormData,
    onHandleChange,
    isLoading,
    onHandleSubmit,
    request,
    isRechedule
}) {
    // const { isOpen, onOpen, onClose } = useDisclosure()
    const { t, i18n } = useTranslation();
    const { user } = UserState();
    const toast = useToast();
    const isArabic = i18n.language === 'ar';
    const [error, setError] = useState('')
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    return (
        <>
            {/* <Button >Open Modal</Button> */}
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign={isArabic ? 'right' : 'left'}>
                        {isRechedule ? t('re-schedule-appointment') : t('schedule-appointment')}
                    </ModalHeader>
                    <ModalCloseButton
                        style={{
                            [isArabic ? 'left' : 'right']: '10px' // Conditional positioning based on language
                        }}
                    />
                    <ModalBody pb={6}>
                        {/* SELECT APPOINTMENT DATE  */}
                        <FormControl>
                            <FormLabel textAlign={isArabic ? 'right' : 'left'}>{t('select-d/t')}</FormLabel>
                            <Input
                                value={scheduleFormData?.appointmentDate || ""}
                                onChange={(e) => {
                                    const selectedDateTime = new Date(e?.target?.value); // Convert the input value to a Date object
                                    const currentDateTime = new Date(); // Get the current date and time

                                    // Validate the selected date
                                    if (selectedDateTime < currentDateTime) {
                                        toast({
                                            title: "Invalid Date Selection",
                                            description: t('select-future-d-t-msg'),
                                            status: "error",
                                            duration: 3000,
                                            isClosable: true,
                                            position: "top",
                                        });

                                        return; // Stop further execution if the date is invali
                                    }
                                    onHandleChange('appointmentDate', e?.target?.value);
                                }}
                                type='datetime-local'
                                ref={initialRef}
                                isInvalid={error !== ''}
                            />
                        </FormControl>

                        {/* ASSIGN DOCTOR  */}
                        <FormControl mt={4}>
                            <FormLabel htmlFor='employee' textAlign={isArabic ? 'right' : 'left'}>
                                {t("assign-doctor")}<span className='text-red-500'></span>
                            </FormLabel>
                            <ReactSelect
                                placeholder={t('assign-doctor')}
                                options={allDoctors} // Only one option available
                                isClearable={false} // Prevent clearing the selection
                                isSearchable={false} // Disable searching since it's a single option
                                value={allDoctors[0]} // Preselect the single available option
                                onChange={() => { }} // No need to change since it's static
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        textAlign: isArabic ? 'right' : 'left',
                                        background: '#f9fafb',
                                        borderColor: 'gray.300',
                                    }),
                                }}
                            />
                        </FormControl>

                        {/* RESHEDULE REASON  */}
                        {isRechedule &&
                            <FormLabel mt={4} textAlign={isArabic ? "right" : "left"}>
                                <FormLabel textAlign={isArabic ? "right" : "left"}>
                                    {t('reschedule-msg')}
                                </FormLabel>
                                <Textarea
                                    value={scheduleFormData?.reason}
                                    textAlign={isArabic ? "right" : "left"}
                                    onChange={(e) => {
                                        onHandleChange('reason', e?.target?.value);
                                    }}
                                    placeholder={t('add-reason')}
                                    resize="vertical"  // Optional: allows vertical resizing of the textarea
                                />
                            </FormLabel>
                        }

                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={4}
                            onClick={onClose}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={onHandleSubmit}
                            colorScheme='blue'
                            isLoading={isLoading}
                            isDisabled={!scheduleFormData?.appointmentDate}
                        >
                            {t('schedule')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    )
}

export default ScheduleAppointmentModal
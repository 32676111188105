import React, { useEffect, useState } from 'react';
import AssignPlayertoTeam from '../dialogueBoxes/AssignPlayertoTeam';
import { formatDateTime } from '../../utills/formateDate';
import AssignCoachToPlayer from '../dialogueBoxes/AssignCoachToPlayer';
import { get } from '../../services/apis/api';
import { Spinner } from '@chakra-ui/react';
import { calculateAge } from '../../utills/calculateAge';
import UpdatePassword from '../operations/dialogBoxes/Updatepassword';
import { useTranslation } from 'react-i18next';

const ViewPlayerProfile = ({ playerId, setViewPlayer, getAllPlayers }) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const [player, setPlayer] = useState();

  const getPlayerData = async()=>{
    try {
      const res = await get(`player/get/${playerId}`);
      if(res.statusCode===200){
        setPlayer(res.data.player);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getPlayerData();
  }, [])

  if(!player){
    return (
      <div className='min-h-screen w-full flex justify-center items-center'>
        <Spinner />
      </div>
    )
  }
  
  return (
    <div className="min-h-screen ">
        <div className='flex justify-between p-5'> {/* Added space-y-4 for consistent spacing */}
        <button onClick={() => { setViewPlayer(false) }} className="px-4 py-2 text-white bg-[#290dbd] rounded-md hover:bg-[#1d0a8c]">
          {t("back")}
        </button>
        <UpdatePassword className="mt-4" userId={player._id} />
      </div>

      <div className="container mx-auto my-5 p-5" dir={isArabic ? 'rtl' : 'ltr'}>
        <div className="md:flex no-wrap md:-mx-2 ">
          <div className="w-full md:w-3/12 md:mx-2">
            <div className="bg-gray-100 p-3 border-t-4 border-[#290dbd] rounded-lg shadow-md">
              <div className="image overflow-hidden rounded-lg">
                <img className="h-48 w-full mx-auto object-cover"
                  src={player?.pic}
                  alt="" />
              </div>
              <h1 className={`text-gray-900 font-bold text-xl leading-8 my-1 ${isArabic ? "text-right" : "text-left"}`}>{player.name}</h1>
              <h3 className={`text-gray-600 font-lg text-semibold leading-6 ${isArabic ? "text-right" : "text-left"}`}>{player?.gameId?.name}</h3>
              <p className={`text-sm text-gray-500 hover:text-gray-600 leading-6 ${isArabic ? "text-right" : "text-left"}`}>{player?.city}</p>
              <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded-lg shadow-sm">
                <li className="flex items-center justify-between py-3 space-x-4">
                  <span>{t("status")}</span>
                { player.isActive? 
                  <span className="ml-auto"><span className="bg-green-500 py-1 px-2 rounded text-white text-sm">{'Active'}</span></span>:
                  <span className="ml-auto"><span className="bg-red-500 py-1 px-2 rounded text-white text-sm">{'InActive'}</span></span>}
                </li>
                <li className="flex items-center justify-between py-3 space-x-4">
                  <span>{t("member-since")}</span>
                  <span className="ml-auto">{"30 sept 2023"}</span>
                </li>
              </ul>
            </div>
            <div className="my-4"></div>
          </div>
          <div className="w-full md:w-9/12 mx-2 h-64">
            <div className="bg-gray-100 p-5 shadow-md rounded-lg">
              <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                <span className="text-green-500">
                  <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                </span>
                <span className="tracking-wide">{t("about")}</span>
              </div>
              <div className="text-gray-700">
                <div class={`grid md:grid-cols-2 text-sm ${isArabic ? 'text-right' : ""}`}>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">{t("name")}</div>
                    <div className="px-4 py-2">{player.name}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">{t("user-name")}</div>
                    <div className="px-4 py-2">{player?.userName}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">{t("gender")}</div>
                    <div className="px-4 py-2">{player.gender}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">{t("contact-no")}</div>
                    <div className="px-4 py-2">{player.mobile}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">{t("current-address")}</div>
                    <div className="px-4 py-2">{player?.address}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">{t("permanent-address")}</div>
                    <div className="px-4 py-2">{player?.address}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">{t("email")}</div>
                    <div className="px-4 py-2">
                      <a className="text-blue-800" href={`mailto:${player?.email}`}>{player?.email}</a>
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">{t("date-of-birth")}</div>
                    <div className="px-4 py-2">{formatDateTime(player?.dateOfBirth, "date")}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">{t("age")}</div>
                    <div className="px-4 py-2">{calculateAge(player?.dateOfBirth)}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">{t("blood-group")}</div>
                    <div className="px-4 py-2">{(player?.bloodGroup)}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">{t("mother-name")}</div>
                    <div className="px-4 py-2">{(player?.motherName)}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">{t("father-name")}</div>
                    <div className="px-4 py-2">{(player?.fatherName)}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">{t("game-name")}</div>
                    <div className="px-4 py-2">{(player?.gameId?.name)}</div>
                  </div>
                
                </div>
              </div>
              {/* <button className="block w-full text-blue-800 text-sm font-semibold rounded-lg hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs p-3 my-4">
                Show Full Information
              </button> */}
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewPlayerProfile;

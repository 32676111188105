import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box, Flex, Button, Tabs, TabList, Tab, Input, IconButton, InputGroup, InputLeftElement, TabPanels, TabPanel, Table, Thead, Tbody, Tr, Th, Td, Image, Text, Checkbox } from "@chakra-ui/react";
import { SearchIcon, DownloadIcon } from "@chakra-ui/icons";
import CreateEmployee from '../../components/hr/dialogBoxes/CreateEmployee';
import CreateMultipleEmployee from '../../components/hr/dialogBoxes/CreateMultipleEmployee';
import { get } from '../../services/apis/api';
import { formatDateTime } from '../../utills/formateDate';
import EmployeeDetails from '../../components/hr/components/addEmployee/EmployeeDetails';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../components/BreadCrumbs';
import ConfirmDownload from '../../components/dialogueBoxes/ConfirmDownload';

// Utility function to convert data to CSV format
const convertToCSV = (data) => {
  try {
    const headers = ['Name', 'Employee Code', 'Gender', 'Designation', 'Mobile Number', 'Date of Joining', 'Email'];

    const rows = data?.map((employee) => [
      employee?.name,
      employee?.employeeCode,
      employee?.gender,
      employee?.designation,
      employee?.mobile,
      formatDateTime(employee?.joiningDate, 'date'),
      employee?.email
    ]);

    const csvContent = [headers, ...rows].map((e) => e.join(",")).join("\n");
    return csvContent;
  } catch (e) {
    console.log(`ERORR WHILE CONVERTING TO CSV ${e}`);
  }
};

// EMPLOYEE COLUMNS 
const employeeColumns = [
  'name',
  'employee-code',
  'gender',
  'designation',
  'mobile',
  'date-of-joining'
]

const Employee = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const [employees, setEmployees] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [status, setStatus] = useState('ACTIVE');
  const [showDetails, setShowDetails] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [downloadOpen, setDownloadOpen] = useState(false);  // for confirmation dialog
  const [loading, setLoading] = useState(false);

  // FETCH EMPLOYEES DATA 
  const getEmployees = async () => {
    try {
      const res = await get(`employee/all?page=${page}&limit=10&name=${search}&status=${status}`);
      if (res?.statusCode == 200) {
        setEmployees(res?.data?.data);
        setTotalPages(res?.data?.pages);
        setTotalEmployees(res?.data?.total);
      } else {
        setEmployees([]);
      }
    } catch (error) {
      setEmployees([]);
      console.error(error);
    }
  };

  useEffect(() => {
    getEmployees();
  }, [page, search, status]);

  // HANDLE SEARCH CHANGE 
  const handleSearchChange = (e) => {
    try {
      setSearch(e?.target?.value);
      setPage(1);
    } catch (e) {
      console.log(`ERROR WHILE SEARCHING ${e}`);
    }
  };

  // HANDLE PAGINATION CHANGE 
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  // HANDLE TABE CHANGE 
  const handleTabChange = (index) => {
    try {
      const statuses = ['ACTIVE', 'PIP', 'EEP'];
      setStatus(statuses[index]);
      setPage(1);
    } catch (e) {
      console.log(`ERROR WHILE CHANGING TAB ${e}`);
    }
  };

  // DOWNLOAD CSV 
  const downloadCSV = async () => {
    try {
      setLoading(true);
      const params = new URLSearchParams();

      // Add query parameters if they have values
      if (search) params.append('name', search);
      if (status) params.append('status', status);

      // Build the final URL
      const apiUrl = `employee/all?${params.toString()}`;

      // Make the API call
      const res = await get(apiUrl);
      if (res?.statusCode == 200) {
        const employeesData = res?.data?.data;
        const csvContent = convertToCSV(employeesData);

        // Create a blob with the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create a link element
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'employees.csv');
        link.style.visibility = 'hidden';

        // Append link to body and initiate download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.log(`ERROR WHILE DOWNLOADING CSV`);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(`ERROR WHILE DOWNLOADING ${e}`);
    }
  };

  // EMPLOYEE TABLE  
  const EmployeeTable = ({ employees, page, totalPages, handlePageChange, setShowDetails, setSelectedEmployee }) => (
    <>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <Table dir={isArabic ? 'rtl' : 'ltr'} className="w-full text-sm text-left text-gray-500">
          <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <Tr>
              {employeeColumns?.map((column, index) => (
                <Th key={index}>
                  {t(column)}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {employees?.map(employee => (
              <Tr
                key={employee?.employeeCode}
                onClick={(e) => {
                  setShowDetails(true);
                  setSelectedEmployee(employee);
                }}
                className="bg-white border-b hover:bg-gray-50 cursor-pointer"
              >
                <Td className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                  <Image className="w-10 h-10 rounded-full" src={employee?.pic} alt="Employee image" />
                  <Box ml={3}>
                    <Text className="text-base font-semibold">{employee?.name}</Text>
                    <Text className="font-normal text-gray-500">{employee?.email}</Text>
                  </Box>
                </Td>
                <Td>{employee?.employeeCode}</Td>
                <Td>{employee?.gender}</Td>
                <Td>{employee?.designation}</Td>
                <Td>{employee?.mobile}</Td>
                <Td>{formatDateTime(employee?.joiningDate, 'date')}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {employees ?
          employees?.length <= 0 &&
          <div className='w-full  min-h-80 flex items-center justify-center'>
            <h1 className='text-center'>{t('no-employees-found')}</h1>
          </div>
          :
          <div className='w-full  min-h-80 flex items-center justify-center'>
            <h1 className={`text-center `}>
              {isArabic && '....'} {t('loading')} {!isArabic && '....'}
            </h1>

          </div>
        }
      </div>
      {employees?.length > 0 &&
        <Flex justify="space-between" align="center" mt={4}>
          <Button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
          >
            {(t('previous'))}
          </Button>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isArabic ? 'row-reverse' : 'row',
              gap: 1
            }}
          >
            <h1>{t('page')}</h1>
            <Box
              sx={{
                display: 'flex',
                flexDirection: isArabic ? 'row-reverse' : 'row',
                gap: 1
              }}
            >
              {page}

              <h1>{t('of')}</h1>
              {totalPages}
            </ Box>
          </Box>
          <Button
            onClick={() => handlePageChange(page + 1)}
            disabled={page >= totalPages}
          >
            {t('next')}
          </Button>
        </Flex>
      }
    </>
  );

  // Confirm download
  const confirmDownload = () => {
    try {
      downloadCSV();
      setDownloadOpen(false);
    } catch (e) {
      console.log(`ERROR WHILE GENERATING PDF ${e}`);
    }
  };

  return (
    <>
      {
        showDetails ?
          <EmployeeDetails setShowDetails={setShowDetails} selectedEmployee={selectedEmployee} /> :
          <>
            <BreadCrumbs />
            <Box p={4}>
              <Flex
                flexDir={isArabic ? 'row-reverse' : 'row'}
                justify="space-between"
                align="center"
                flexWrap="wrap" mb={4}
              >
                <Box fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
                  {t('employees')} ({totalEmployees})
                </Box>
                <Flex flexDir={isArabic ? "row-reverse" : 'row'} gap={2} flexWrap="wrap" justify={{ base: "center", md: "flex-end" }} w={{ base: "100%", md: "auto" }}>
                  <InputGroup mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "auto" }}>
                    <InputLeftElement pointerEvents="none">
                      <SearchIcon color="gray.300" />
                    </InputLeftElement>
                    <Input
                      type="text"
                      placeholder={t('search')}
                      value={search}
                      onChange={handleSearchChange}
                    />
                  </InputGroup>
                  <CreateEmployee />
                  <CreateMultipleEmployee getEmployees={getEmployees} />
                  {/* Download Confirmation Dialog */}
                  <ConfirmDownload
                    isOpen={downloadOpen}
                    label='download-report'
                    setIsOpen={setDownloadOpen}
                    handleDownload={() => {
                      setDownloadOpen(true);
                    }}
                    confirmDownload={() => {
                      confirmDownload();
                    }}
                    closeDialog={() => {
                      setDownloadOpen(false);
                    }}
                    loading={loading}
                  />
                </Flex>
              </Flex>
              <EmployeeTable
                employees={employees}
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                setShowDetails={setShowDetails}
                setSelectedEmployee={setSelectedEmployee}
              />

              {/* <Tabs colorScheme="blue" onChange={handleTabChange}>
              <TabList>
                <Tab>ACTIVE</Tab>
                <Tab>PIP</Tab>
                <Tab>EEP</Tab>
              </TabList>
              <TabPanels>
                <TabPanel px={'0px'} py={'16px'}>
                  <EmployeeTable
                    employees={employees}
                    page={page}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    setShowDetails={setShowDetails}
                    setSelectedEmployee={setSelectedEmployee}
                  />
                </TabPanel>
                <TabPanel px={'0px'} py={'16px'}>
                  <EmployeeTable
                    employees={employees}
                    page={page}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    setShowDetails={setShowDetails}
                    setSelectedEmployee={setSelectedEmployee}
                  />
                </TabPanel>
                <TabPanel px={'0px'} py={'16px'}>
                  <EmployeeTable
                    employees={employees}
                    page={page}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    setShowDetails={setShowDetails}
                    setSelectedEmployee={setSelectedEmployee}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs> */}
            </Box>
          </>

      }
    </>
  );
};

export default Employee;

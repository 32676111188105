import {
    Spinner,
    useDisclosure,
    useToast,
    Box,
    Badge,
    Heading,
    Text,
    Button,
    Flex,
    SimpleGrid,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { get, patch } from '../../services/apis/api';
import CustomAlert from '../alerts/CustomAlert';
import { IoMdArrowRoundBack } from 'react-icons/io';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { formatDateArEn, formatNumber } from '../../services/glocalFunctions';

const ViewHrExpenseRequest = ({ requestId, setView, user, getAllRequest }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const [requestAction, setRequestAction] = useState(false);
    const [request, setRequest] = useState({});
    const toast = useToast();

    const getRequestDetails = async () => {
        try {
            const res = await get(`expenses/request/${requestId}`);
            if (res.statusCode === 200) {
                setRequest(res.data.request);
            }
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to fetch request details.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        getRequestDetails();
    }, [requestId]);

    // HANLDE REQUESTR APPROVAL
    const handleApprove = async (_status) => {
        console.log(_status);
        setLoading(true);
        let _payload = {
            status: _status
        }
        if (_status?.toLowerCase() === 'rejected') {
            _payload = {
                ..._payload,
                rejectedBy: user._id
            }
        }
        try {
            let res;
            res = await patch(`expenses/request/update/${requestId}`, _payload);
            setLoading(false);
            if (res.statusCode === 200) {
                getRequestDetails();
                getAllRequest();
                toast({
                    title: "Success",
                    description: res.data.message,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                onClose();
            } else {
                throw new Error(res?.message);
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: "Error",
                description: error.message || "Failed to approve the request.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // HANDLE APPROVE REQUESTS  DIALOGUE
    const handleApproveRequest = (type) => {
        setRequestAction(type);
        onOpen();
    };

    return (
        <Box>
            <Flex flexDirection={{ base: 'column', md: 'row', lg: 'row' }} justifyContent="space-between" alignItems={{ base: 'flex-start', md: 'center', lg: 'center' }} gap={2} my={5}>
                <Button size={"sm"} onClick={() => setView(false)} colorScheme="blue" leftIcon={<IoMdArrowRoundBack />}>
                    {t('back')}
                </Button>
                {user?.role === 'ACCOUNT-MANAGER' && request?.status?.toLowerCase() === 'approved' ? (
                    <Button
                        colorScheme="blue"
                        size="lg"
                        mr={3}
                        variant={"ghost"}
                    >
                        {loading ? <Spinner size="sm" /> : t('completed')}
                    </Button>
                ) :
                    <div className='flex items-center gap-2 lg:gap-4'>
                        <Button size="sm" onClick={() => handleApproveRequest('REJECTED')} colorScheme="red" mr={3}>
                            {loading ? <Spinner size="sm" /> : t('reject-request')}
                        </Button>
                        <Button size="sm" onClick={() => handleApproveRequest('APPROVED')} colorScheme="blue">
                            {loading ? <Spinner size="sm" /> : t('approve-request')}
                        </Button>
                    </div>
                }
            </Flex>

            <Heading textAlign={isArabic ? "right" : "left"} as="h2" size="lg" mb={4}>{t('request-details')}</Heading>
            <SimpleGrid columns={[1, null, 2]} spacing={5} mb={5}>
                <Box bg="gray.100" p={4} borderRadius="md">
                    <Text><strong>{t('request-by')}:</strong> <span className='text-[#485060]'>{request?.createdBy?.name}</span></Text>
                </Box>

                <Box bg="gray.100" p={4} borderRadius="md">
                    <Text>
                        <strong>{t('request-status')}:</strong> <Badge colorScheme={(request?.status === "APPROVED") ? 'green' : (request?.status === "REJECTED") ? 'red' : 'orange'}>{request?.status}</Badge>
                    </Text>
                </Box>
                <Box bg="gray.100" p={4} borderRadius="md">
                    <Text><strong>{t('request-amoun')}t:</strong> <span className='text-[#485060]'>{formatNumber(request?.amount, isArabic) || '00'}</span></Text>
                </Box>
                <Box bg="gray.100" p={4} borderRadius="md">
                    <Text><strong>{t('expense-type')}:</strong> <span className='text-[#485060]'>{request?.expenseType || '--'}</span></Text>
                </Box>
                <Box bg="gray.100" p={4} borderRadius="md">
                    <Text><strong>{t('request-type')}:</strong> <span className='text-[#485060]'>{request?.kind || '--'}</span></Text>
                </Box>
                <Box bg="gray.100" p={4} borderRadius="md">
                    <Text><strong>{t('expense-type')}:</strong> <span className='text-[#485060]'>{request?.expenseType || '--'}</span></Text>
                </Box>
                <Box bg="gray.100" p={4} borderRadius="md">
                    <Text><strong>{t('request-date')}:</strong> <span className='text-[#485060]'>{request?.updatedAt ? formatDateArEn(request?.updatedAt, isArabic) : '--'}</span></Text>
                </Box>
                <Box bg="gray.100" p={4} borderRadius="md">
                    <Text><strong>{t('payment-method')}:</strong> <span className='text-[#485060]'>{request?.paymentMethod || '--'}</span></Text>
                </Box>
            </SimpleGrid>
            <Box bg="gray.100" p={4} borderRadius="md">
                <Text><strong>{t('description')}:</strong></Text>
                <Text> <span className='text-[#485060]'>{request?.description}</span></Text>
            </Box>

            {
                requestAction === 'APPROVED' ? (
                    <CustomAlert
                        isOpen={isOpen}
                        onClose={onClose}
                        title="approve-request"
                        description="Are you sure you want to approve this request?"
                        onConfirm={() => handleApprove('APPROVED')}
                        confirmButtonText="approve"
                        cancelButtonText="cancel"
                        confirmButtonColorScheme="blue"
                    />
                ) : (
                    <CustomAlert
                        isOpen={isOpen}
                        onClose={onClose}
                        title="reject-request"
                        description="Are you sure you want to reject this request?"
                        onConfirm={() => handleApprove('REJECTED')}
                        confirmButtonText="reject"
                        cancelButtonText="cancel"
                        confirmButtonColorScheme="red"
                    />
                )
            }
        </Box >


    );

};

export default ViewHrExpenseRequest;

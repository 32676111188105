import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  IconButton,
  Text,
  VStack,
  HStack,
  Divider,
  Grid,
  GridItem,
  useDisclosure,
  Tooltip,
  Input,
  useToast
} from '@chakra-ui/react';
import { CiCircleCheck } from "react-icons/ci";
import { RxCrossCircled } from "react-icons/rx";
import { FaFileInvoiceDollar, FaPrint, FaEnvelope } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';
import { patch } from '../../../services/apis/api';
import { UserState } from '../../../context/user';
import ConfirmRejectBox from '../../alerts/ConfirmRejectBox';

const PayslipModal = ({ payslip, handlePaySlipRequest, fetchData }) => {
  // console.log(payslip,'payslip ---');
  const { user } = UserState();
  const toast = useToast();
  const [paySlipData, setPaySlipData] = useState({});
  const location = useLocation();
  const [isAccount, setIsAccount] = useState(false);

  // UPDATE PAYSLIP DATA IN A USESTATE 
  useEffect(() => {
    setPaySlipData(payslip);
  }, [payslip])

  // UPDATE PAYSLIP DATA ON ONCHANGE EVENT 
  const handleChangeDataEvent = (e, category, key) => {
    try {
      if (key) {
        setPaySlipData((prevData) => ({
          ...prevData,
          [category]: {
            ...prevData[category],
            [key]: e.target.value
          }
        }))
      } else {
        setPaySlipData((prevData) => ({
          ...prevData,
          [category]: e.target.value
        }))
      }
    } catch (e) {
      console.log(`ERROR WHILE SETING PAYSLIP DATA : ${e}`);
    }
  }

  // UPDATE PAYSLIP DATA BY API
  const updatePaySlipdata = async (_id) => {
    try {
      const _payload = {
        ...paySlipData,
        paymentStatus: 'APPROVED'
      }
      const response = await patch(`payroll/update/${_id}`, _payload);
      if (response.statusCode === 200) {
        setPaySlipData(response?.data?.payroll);
        fetchData();
        toast({
          title: 'Successfull...!',
          description: response.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
      // console.log(response, 'response ----');

    } catch (e) {
      console.log(`ERROR WHILE UPDATING PAYSLIP DATA : ${e}`);
    }
  }

  useEffect(() => {
    let _isAc = user?.role?.toLowerCase() === 'account-manager' && payslip?.paymentStatus?.toLowerCase() == 'pending'
    setIsAccount(_isAc);
  }, [location])

  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalRef = React.useRef();

  // // Function to handle printing
  // const handlePrint = useReactToPrint({
  //   content: () => modalRef.current,
  //   documentTitle: 'Payslip',
  // });

    // React to print configuration
    const handlePrint = useReactToPrint({
      content: () => modalRef.current,
      documentTitle: 'Payslip',
    });
  
    const printPayslip = async () => {
      if (modalRef.current) {
        try {
          console.log(modalRef.current, 'modal ref');
          await handlePrint();
        } catch (error) {
          console.error('Print operation failed:', error);
          toast({
            title: 'Print Failed',
            description: 'There was an issue printing the payslip. Please try again later.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: 'No Content to Print',
          description: 'Ensure the payslip modal is open and contains data to print.',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
      }
    };

  return (
    <>
      {/* Icon button to open modal */}
      <Tooltip label="Pay Slip" aria-label="Pay Slip tooltip">
        <IconButton
          icon={<FaFileInvoiceDollar />}
          onClick={onOpen}
          aria-label="Open Payslip"
        />
      </Tooltip>

      {/* Payslip modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent ref={modalRef}>
          <ModalHeader>Payslip for : {paySlipData?.employee?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={8}>
            <VStack align="start" spacing={4}>
              {/* Employee Information */}
              <Grid templateColumns="repeat(2, 1fr)" gap={6} width="full">
                <GridItem>
                  <HStack justify="space-between" width="full">
                    <Text fontWeight="bold">Employee Code:</Text>
                    <Text>{paySlipData?.employee?.employeeCode}</Text>
                    {/* <Input value readOnly placeholder='Basic usage' /> */}
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack justify="space-between" width="full">
                    <Text fontWeight="bold">Payroll Month:</Text>
                    <Text>{new Date(paySlipData?.payrollMonth).toLocaleDateString()}</Text>
                  </HStack>
                </GridItem>
              </Grid>

              <Divider />

              {/* Salary Breakdown */}
              <Text fontWeight="bold" fontSize="lg">Salary Breakdown:</Text>
              <Grid templateColumns="repeat(2, 1fr)" gap={6} width="full">
                {paySlipData?.salaryBreakdown ? Object?.entries(paySlipData?.salaryBreakdown)?.map(([key, value]) => (
                  <GridItem key={key}>
                    <HStack justify="space-between" width="full">
                      <Text textTransform="capitalize">{key?.replace(/([A-Z])/g, ' $1')}</Text>
                      {isAccount ?
                        <Input
                          value={value}
                          maxWidth={120}
                          onChange={(e) => handleChangeDataEvent(e, 'salaryBreakdown', key)}
                          placeholder='Basic usage'
                        />
                        :
                        <Text>₹ {value?.toFixed(2)}</Text>
                      }
                    </HStack>
                  </GridItem>
                )) : null}
              </Grid>

              <Divider />

              {/* Deductions */}
              <Text fontWeight="bold" fontSize="lg">Deductions:</Text>
              <Grid templateColumns="repeat(2, 1fr)" gap={6} width="full">
                {paySlipData?.deductions ? Object.entries(paySlipData?.deductions).map(([key, value]) => (
                  <GridItem key={key}>
                    <HStack justify="space-between" width="full">
                      <Text textTransform="capitalize">{key.replace(/([A-Z])/g, ' $1')}</Text>
                      {isAccount && key !== 'totalDeductions' ?
                        <Input
                          value={value}
                          maxWidth={120}
                          onChange={(e) => handleChangeDataEvent(e, 'deductions', key)}
                          placeholder='Basic usage'
                        /> :
                        <Text>₹ {value?.toFixed(2)}</Text>}
                    </HStack>
                  </GridItem>
                )) : null}
              </Grid>

              <Divider />

              {/* Leave Information */}
              <Grid templateColumns="repeat(2, 1fr)" gap={6} width="full">
                <GridItem>
                  <HStack justify="space-between" width="full">
                    <Text fontWeight="bold">Paid Leaves:</Text>
                    {isAccount ?
                      <Input
                        value={paySlipData?.paidLeaves}
                        maxWidth={120}
                        placeholder='Basic usage'
                        onChange={(e) => handleChangeDataEvent(e, 'paidLeaves')}
                      /> :
                      <Text>{paySlipData?.paidLeaves}</Text>
                    }
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack justify="space-between" width="full">
                    <Text fontWeight="bold">Unpaid Leaves:</Text>
                    {isAccount ?
                      <Input
                        value={paySlipData?.unpaidLeaves}
                        maxWidth={120}
                        placeholder='Basic usage'
                        onChange={(e) => handleChangeDataEvent(e, 'unpaidLeaves')}
                      /> :
                      <Text>{paySlipData?.unpaidLeaves}</Text>
                    }
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack justify="space-between" width="full">
                    <Text fontWeight="bold">Paid Days:</Text>
                    {isAccount ?
                      <Input
                        value={paySlipData?.paidDays}
                        maxWidth={120}
                        placeholder='Basic usage'
                        onChange={(e) => handleChangeDataEvent(e, 'paidDays')}
                      /> :
                      <Text>{paySlipData?.paidDays}</Text>
                    }
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack justify="space-between" width="full">
                    <Text fontWeight="bold">Payable Days:</Text>
                    {isAccount ?
                      <Input
                        value={paySlipData?.payableDays}
                        maxWidth={120}
                        placeholder='Basic usage'
                        onChange={(e) => handleChangeDataEvent(e, 'payableDays')}
                      /> :
                      <Text>{paySlipData?.payableDays}</Text>
                    }
                  </HStack>
                </GridItem>
              </Grid>

              <Divider />

              {/* Final Net Pay */}
              <HStack justify="space-between" width="full">
                <Text fontWeight="bold" fontSize="xl">Final Net Pay:</Text>
                <Text fontWeight="bold" fontSize="xl" color="green.500">
                  ₹ {paySlipData?.finalNetPay?.toFixed(2)}
                </Text>
              </HStack>

              {/* Payment Status */}
              <HStack justify="space-between" width="full">
                <Text fontWeight="bold">Payment Status:</Text>
                <Text color={paySlipData?.paymentStatus === 'PENDING' ? 'yellow.500' : paySlipData?.paymentStatus === 'REJECTED' ? 'red.500' : 'green.500'}>
                  {paySlipData?.paymentStatus}
                </Text>
              </HStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            {/* Buttons to mail and print */}
            {isAccount ?
              <>
                {paySlipData?.paymentStatus === 'PENDING'
                  ?
                  <div className="flex justify-end gap-3">
                    <ConfirmRejectBox
                      label={true}
                      request={paySlipData}
                      handlePaySlipRequest={async () => {
                        handlePaySlipRequest(payslip?._id, 'REJECTED');
                        // fetchData();
                      }}
                      fetchData={fetchData}
                      onCloseModel={() => onClose()}
                    />
                    <Button colorScheme="green" leftIcon={<CiCircleCheck />}
                      onClick={() => {
                        if (paySlipData?.paymentStatus != 'APPROVED') {
                          updatePaySlipdata(payslip?._id)
                          // handlePaySlipRequest(payslip?._id, 'APPROVED')
                        }
                      }}
                    >
                      {paySlipData?.paymentStatus === 'APPROVED' ? 'APPROVED' : 'APPROVE'}
                    </Button>
                  </div>
                  : <>
                    {paySlipData?.paymentStatus === 'REJECTED' ?
                      <Button colorScheme="red" leftIcon={<RxCrossCircled />} mr={3}>
                        Rejected
                      </Button>
                      :
                      <Button colorScheme="green" leftIcon={<CiCircleCheck />} mr={3}>
                        APPROVED
                      </Button>
                    }
                  </>}
              </>
              : (
                <>
                  <Button colorScheme="blue" leftIcon={<FaEnvelope />} mr={3}>
                    Mail Payslip
                  </Button>
                  <Button
                    colorScheme="green"
                    leftIcon={<FaPrint />}
                    onClick={printPayslip}
                  >
                    Print Payslip
                  </Button>
                </>
              )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PayslipModal;

import { Box, Grid, Text, Flex, Stat, StatLabel, StatNumber, StatHelpText, StatArrow, useColorModeValue, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaMoneyBillWave, FaChartLine, FaUsers, FaClinicMedical, FaWarehouse } from 'react-icons/fa';
import { MdAttachMoney, MdTrendingUp, MdTrendingDown } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { get } from '../../services/apis/api';
import Chart from 'react-apexcharts';

const StatCard = ({ title, value, icon, trend, trendValue, helpText, bgColor }) => {
    return (
        <Box p={5} bg={bgColor} borderRadius="lg" boxShadow="sm">
            <Flex justify="space-between" align="center">
                <Stat>
                    <StatLabel color="gray.600">{title}</StatLabel>
                    <StatNumber fontSize="2xl">{value}</StatNumber>
                    {trend && (
                        <StatHelpText>
                            <StatArrow type={trend} />
                            {trendValue}
                        </StatHelpText>
                    )}
                    {helpText && <Text fontSize="sm" color="gray.500">{helpText}</Text>}
                </Stat>
                <Icon as={icon} w={8} h={8} color="gray.600" />
            </Flex>
        </Box>
    );
};

const Dashboard = () => {
    const { t } = useTranslation();
    const isArabic = t('isArabic');
    const [dashboardData, setDashboardData] = useState(null);

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const response = await get('dashboard/overview');
                console.log(response);
                setDashboardData(response.data.data);
                
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };
        fetchDashboardData();
    }, []);

    // Financial chart options
    const financialChartOptions = {
        chart: {
            type: 'line',
            toolbar: { show: false }
        },
        stroke: { curve: 'smooth', width: 2 },
        xaxis: {
            categories: dashboardData?.financial?.graph?.labels || []
        },
        yaxis: {
            labels: {
                formatter: (value) => Math.round(value).toLocaleString()
            }
        },
        colors: ['#38A169', '#E53E3E'],
        tooltip: {
            y: {
                formatter: (value) => Math.round(value).toLocaleString()
            }
        }
    };

    const financialChartSeries = dashboardData?.financial?.graph?.datasets || [];

    // Attendance chart options
    const attendanceChartOptions = {
        chart: {
            type: 'line',
            toolbar: { show: false }
        },
        stroke: { curve: 'smooth', width: 2 },
        xaxis: {
            categories: dashboardData?.attendance?.graph?.labels || []
        },
        yaxis: {
            labels: {
                formatter: (value) => Math.round(value).toLocaleString()
            }
        },
        colors: ['#4299E1', '#F6AD55'],
        tooltip: {
            y: {
                formatter: (value) => Math.round(value).toLocaleString()
            }
        }
    };

    const attendanceChartSeries = dashboardData?.attendance?.graph?.datasets || [];

    return (
        <Box className={`w-full ${isArabic ? 'rtl' : 'ltr'}`} p={6}>
            <Text fontSize="2xl" fontWeight="bold" mb={6}>Dashboard Overview</Text>
            
            {/* Financial Section */}
            <Box mb={8}>
                <Text fontSize="xl" fontWeight="semibold" mb={4}>Financial Overview</Text>
                <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }} gap={4}>
                    <StatCard
                        title="Monthly Income"
                        value={`$${dashboardData?.financial?.monthlyIncome || 0}`}
                        icon={FaMoneyBillWave}
                        bgColor="green.50"
                    />
                    <StatCard
                        title="Monthly Expenses"
                        value={`$${dashboardData?.financial?.monthlyExpenses || 0}`}
                        icon={MdAttachMoney}
                        bgColor="red.50"
                    />
                    <StatCard
                        title="Net Revenue"
                        value={`$${dashboardData?.financial?.netRevenue || 0}`}
                        icon={FaChartLine}
                        bgColor="blue.50"
                    />
                </Grid>
                <Box mt={6} p={4} bg="white" borderRadius="lg" boxShadow="sm">
                    <Text fontSize="lg" fontWeight="semibold" mb={4}>Revenue vs Expenses</Text>
                    <Chart
                        options={financialChartOptions}
                        series={financialChartSeries}
                        type="line"
                        height={300}
                    />
                </Box>
            </Box>

            {/* Attendance Section */}
            <Box mb={8}>
                <Text fontSize="xl" fontWeight="semibold" mb={4}>Attendance Overview</Text>
                <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }} gap={4}>
                    <StatCard
                        title="Today's Attendance"
                        value={dashboardData?.attendance?.todayAttendance || 0}
                        icon={FaUsers}
                        helpText={`Out of ${dashboardData?.attendance?.totalEmployees || 0} employees`}
                        bgColor="purple.50"
                    />
                    <StatCard
                        title="Attendance Rate"
                        value={`${dashboardData?.attendance?.attendanceRate || 0}%`}
                        icon={FaChartLine}
                        bgColor="teal.50"
                    />
                </Grid>
                <Box mt={6} p={4} bg="white" borderRadius="lg" boxShadow="sm">
                    <Text fontSize="lg" fontWeight="semibold" mb={4}>Attendance Trends</Text>
                    <Chart
                        options={attendanceChartOptions}
                        series={attendanceChartSeries}
                        type="line"
                        height={300}
                    />
                </Box>
            </Box>

            {/* Clinic Section */}
            <Box mb={8}>
                <Text fontSize="xl" fontWeight="semibold" mb={4}>Clinic Overview</Text>
                <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} gap={4}>
                    <StatCard
                        title="Today's Appointments"
                        value={dashboardData?.clinic?.todayAppointments || 0}
                        icon={FaClinicMedical}
                        bgColor="cyan.50"
                    />
                    <StatCard
                        title="Pending Requests"
                        value={dashboardData?.clinic?.pendingRequests || 0}
                        icon={FaUsers}
                        bgColor="orange.50"
                    />
                    <StatCard
                        title="Appointment Utilization"
                        value={`${dashboardData?.clinic?.appointmentUtilization || 0}%`}
                        icon={FaChartLine}
                        bgColor="yellow.50"
                    />
                </Grid>
            </Box>

            {/* HR Section */}
            <Box mb={8}>
                <Text fontSize="xl" fontWeight="semibold" mb={4}>HR Overview</Text>
                <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }} gap={4}>
                    <StatCard
                        title="Active Employees"
                        value={dashboardData?.hr?.activeEmployees || 0}
                        icon={FaUsers}
                        bgColor="blue.50"
                    />
                    <StatCard
                        title="Pending Leaves"
                        value={dashboardData?.hr?.pendingLeaves || 0}
                        icon={FaUsers}
                        bgColor="pink.50"
                    />
                    <StatCard
                        title="Payroll In Process"
                        value={dashboardData?.hr?.payrollInProcess || 0}
                        icon={FaMoneyBillWave}
                        bgColor="purple.50"
                    />
                </Grid>
            </Box>

            {/* Inventory Section */}
            <Box mb={8}>
                <Text fontSize="xl" fontWeight="semibold" mb={4}>Inventory Overview</Text>
                <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }} gap={4}>
                    <StatCard
                        title="Low Stock Items"
                        value={dashboardData?.inventory?.lowStockItems || 0}
                        icon={FaWarehouse}
                        bgColor="red.50"
                    />
                    <StatCard
                        title="Total Products"
                        value={dashboardData?.inventory?.totalProducts || 0}
                        icon={FaWarehouse}
                        bgColor="green.50"
                    />
                    <StatCard
                        title="Warehouse Count"
                        value={dashboardData?.inventory?.warehouseCount || 0}
                        icon={FaWarehouse}
                        bgColor="blue.50"
                    />
                    <StatCard
                        title="Stock Value"
                        value={`$${dashboardData?.inventory?.stockValue?.toLocaleString() || 0}`}
                        icon={FaMoneyBillWave}
                        bgColor="purple.50"
                    />
                </Grid>
            </Box>

            {/* VIP Club Section */}
            <Box>
                <Text fontSize="xl" fontWeight="semibold" mb={4}>VIP Club Overview</Text>
                <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap={4}>
                    <StatCard
                        title="Active Services"
                        value={dashboardData?.vipClub?.activeServices || 0}
                        icon={FaUsers}
                        bgColor="gold.50"
                    />
                    <StatCard
                        title="Services Utilization"
                        value={`${dashboardData?.vipClub?.activeServicesUtilization || 0}%`}
                        icon={FaChartLine}
                        bgColor="yellow.50"
                    />
                </Grid>
            </Box>
        </Box>
    );
};

export default Dashboard;


import { Button, FormControl, FormLabel, Input, useToast } from '@chakra-ui/react';
import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
} from '@chakra-ui/react';
import { post } from '../../services/apis/api';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const AdministratorHireRequest = ({ isOpen, onClose, userId }) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const toast = useToast();
  const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
  // State to hold form values
  const [formValues, setFormValues] = useState({
    requestedBy: '',
    experience: '',
    specialization: '',
    budget: '',
    startDate: '',
    status: 'HR-MANAGER-REVIEW',
  });

  // Populate `requestedBy` with the userId when the component mounts
  useEffect(() => {
    if (userId) {
      setFormValues((prevValues) => ({ ...prevValues, requestedBy: userId }));
    }
  }, [userId]);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // Handle form save
  const handleSave = async () => {
    try {
      console.log(formValues);
      const res = await post('request/administrator-hire', formValues);
      if (res.statusCode === 200) {
        toast({
          title: 'Successful!',
          description: res.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        onClose();
      } else {
        throw new Error(res.data.message || 'An error occurred');
      }
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('create-administrator-hire-request')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <SimpleGrid columns={2} spacing={4} dir={isArabic ? 'rtl' : 'ltr'}>
            <FormControl>
              <FormLabel>{t("experience")}</FormLabel>
              <Input
                name="experience"
                type="number"
                value={formValues.experience}
                onChange={handleChange}
                placeholder={t("experience")}
              />
            </FormControl>

            <FormControl>
              <FormLabel>{t("specialization")}</FormLabel>
              <Input
                name="specialization"
                value={formValues.specialization}
                onChange={handleChange}
                placeholder={t("specialization")}
              />
            </FormControl>

            <FormControl>
              <FormLabel>{t("budget")}</FormLabel>
              <Input
                name="budget"
                type="number"
                value={formValues.budget}
                onChange={handleChange}
                placeholder={t("budget")}
              />
            </FormControl>

            <FormControl>
              <FormLabel>{t('start-date')}</FormLabel>
              <Input
                name="startDate"
                type="date"
                value={formValues.startDate}
                onChange={handleChange}
                placeholder={t('start-date')}
              />
            </FormControl>
          </SimpleGrid>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>{t('cancel')}</Button>
          <Button colorScheme="blue" onClick={handleSave}>
            {t('save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AdministratorHireRequest;

import React, { useEffect, useState } from 'react';
import HrExpensesAssetsOverview from '../../components/hr/components/reports/HrExpensesAssetsOverview';
import { get } from '../../services/apis/api';
import { Skeleton } from '@chakra-ui/react';
import RequestSummery from '../../components/account/RequestSummery';
import ExpensesSummery from '../../components/account/ExpensesSummery';
import EarnignsAndSpendingsSummery from '../../components/account/EarnignsAndSpendingsSummery';
import { UserState } from '../../context/user';
import { useTranslation } from 'react-i18next';

const ReportsAndSummery = () => {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [reports, setReports] = useState(undefined);
    const [reportsData, setReportsData] = useState([]);

    // FETCH REPORTS GRAPHS DATA 
    const getReportsGraph = async () => {
        const res = await get('finance-reports/transactions/graph-data');
        if (res.statusCode === 200) {
            setReportsData(res?.data);
        }
    }

    useEffect(() => {
        getReportsGraph();
    }, [])

    return (
        <div className='w-full flex-1 flex flex-col p-2 lg:p-4'>
            <div className={`flex justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                <h2 className='text-xl whitespace-nowrap font-bold mb-4'>{t("reports&summery")}</h2>
            </div>
            <div className="bg-gray-100">
                <div className='w-full flex flex-col items-center justify-between'>
                    <div className='w-full  p-6 bg-gray-100 flex gap-4 lg:gap-6 items-center justify-between rounded'>
                        <div className='w-full bg-white rounded'>
                            {reportsData?.earningsGraph ?
                                <EarnignsAndSpendingsSummery
                                    graphData={reportsData?.earningsGraph || []}
                                    currentYear={reportsData?.currentYear || ''}
                                />
                                :
                                <Skeleton height="300px" />
                            }
                        </div>
                    </div>
                    <div className='w-full p-6 bg-gray-100 flex-col lg:flex-row flex gap-4 lg:gap-6 items-center justify-between rounded'>
                        {reportsData?.requestStats ?
                            <RequestSummery
                                requestsData={reportsData?.requestStats || []}
                            />
                            : <Skeleton width={'50%'} marginRight={3} height="300px" />}
                        {reportsData?.expensesByRefType ?
                            <ExpensesSummery
                                expensesData={reportsData?.expensesByRefType || []}
                            />
                            : <Skeleton width={'50%'} height="300px" />}
                    </div>
                </div>
                {reportsData?.assets ?
                    <HrExpensesAssetsOverview
                        data={reports?.monthlyHRExpenditure || []}
                        assets={reportsData?.assets || []}
                        expensesChartData={reportsData?.monthlySourceTotals || []}
                    />
                    :
                    <div className='w-full flex items-center justify-between'>
                        <Skeleton marginRight={3} width={'50%'} height="300px" />
                        <Skeleton width={'50%'} height="300px" />
                    </div>
                }
            </div>
        </div>
    );
};

export default ReportsAndSummery;

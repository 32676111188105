import React, { useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    useToast,
    Input,
    Select,
    IconButton,
    Tooltip,
} from '@chakra-ui/react';
import { get, post } from '../../../services/apis/api'; 
import { PiArrowUUpLeftBold } from "react-icons/pi";
import { getAssetConditions } from '../../../utills/assetsConditions';
import { AssetState } from '../../../context/assets';

const ReturnAsset = ({ getAssetAssignments, assetHolder }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [conditions, setConditions] = useState([]);
    const {assetRefresh, setAssetRefresh} = AssetState();
    const toast = useToast();

    // Pre-fill the data from assetHolder
    const [formData, setFormData] = useState({
        assetId: assetHolder.asset._id,
        employeeId: assetHolder.employee._id,
        returnDate: '',
        conditionAtReturning: 'good',
    });

    const fetchAssetsDeatails = async () => {
        try {
            const res = await get(`assets/get/${formData.assetId}`);
            if (res.statusCode === 200) {
                setConditions(getAssetConditions(res.data.data.condition))
                setFormData({
                    ...formData,
                    conditionAtAssigning: res.data.data.condition,
                });
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        }
    };

    useEffect(() => {
        fetchAssetsDeatails();
    }, [formData.assetId])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async () => {
        try {
            const response = await post('assets/return-asset', formData);
            if (response.statusCode === 200) {
                toast({
                    title: 'Asset Returned.',
                    description: 'The asset has been successfully returned by the employee.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onClose();
                setAssetRefresh(formData.assetId);
                getAssetAssignments();
                setFormData({
                    assetId: '',
                    employeeId: '',
                    returnDate: '',
                    conditionAtReturning: 'good',
                });
            } else {
                toast({
                    title: 'Error',
                    description: 'Failed to return the asset.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <div>
            <Tooltip label='Return Asset'>
                <IconButton
                    icon={<PiArrowUUpLeftBold />}
                    aria-label="View Assignment Details"
                    onClick={onOpen}
                />
            </Tooltip>

            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Return Asset from Employee</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl id="assetId" isRequired>
                            <FormLabel>Asset</FormLabel>
                            {/* Display the asset name and serial number, make it read-only */}
                            <Input
                                value={`${assetHolder.asset.name} - ${assetHolder.asset.serialNumber}`}
                                isReadOnly
                            />
                        </FormControl>

                        <FormControl id="employeeId" isRequired mt={4}>
                            <FormLabel>Employee</FormLabel>
                            {/* Display the employee name, make it read-only */}
                            <Input
                                value={assetHolder.employee.name}
                                isReadOnly
                            />
                        </FormControl>

                        <FormControl id="returnDate" isRequired mt={4}>
                            <FormLabel>Return Date</FormLabel>
                            <Input
                                type="date"
                                name="returnDate"
                                value={formData.returnDate}
                                onChange={handleInputChange}
                                required
                            />
                        </FormControl>

                        <FormControl id="conditionAtReturning" isRequired mt={4}>
                            <FormLabel>Condition at Returning</FormLabel>
                            <Select
                                name="conditionAtReturning"
                                value={formData.conditionAtReturning}
                                onChange={handleInputChange}
                            >
                                {
                                    conditions?.map((item)=>(
                                        <option value={item}>{item}</option>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" mx={3} onClick={handleSubmit}>
                            Submit
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default ReturnAsset;

import React, { useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Progress,
    Box,
    ButtonGroup,
    Heading,
    Flex,
    FormControl,
    GridItem,
    FormLabel,
    Input,
    Select,
    SimpleGrid,
    InputLeftAddon,
    InputGroup,
    Textarea,
    FormHelperText,
    InputRightElement,
    Stack,
} from '@chakra-ui/react'
import CreateOrgForm from '../organization/CreateOrgForm'

const CreateOrgnization = () => {
    const [show, setShow] = useState(false)
    const handleClick = () => setShow(!show)

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>  
            <Button
                rounded={'full'}
                bg={'#290dbd'}
                color={'white'}
                _hover={{
                    bg: '#3c1cea',
                }}
                onClick={onOpen}
            >
                Create Your Organization
                {/* <Link to={'dashboard'}>
                </Link> */}
            </Button>
            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={'3xl'}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Create New Club</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <CreateOrgForm onClose={onClose} />
                </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default CreateOrgnization
import { useTranslation } from "react-i18next";
import { UserState } from "../../context/user";
import { useCallback, useEffect, useRef, useState } from "react";
import { get, post } from "../../services/apis/api";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs";
import ConfirmDownload from "../../components/dialogueBoxes/ConfirmDownload";
import { formatDateTime } from "../../utills/formateDate";
import ClinicRequestsFilter from "../../components/clinic/ClinicRequestsFilter";
import { debounce } from "lodash";
import ScheduleAppointmentModal from "../../components/clinic/ScheduleAppoinment";
import ClinicTable from "../../components/clinic/ClinicTable";
import { useDisclosure, useToast } from "@chakra-ui/react";


// DEFAULT FILTER FORM DATA 
const defaultClinicForm = {
    paymentStatus: '',
    requestStatus: '',
    requestType: '',
    search: '',
    dates: {
        start: '',
        end: ''
    },
};

// TABLE COLUMNS 
const clinicRequestColumns = [
    "player-info",
    "request-info",
    "request-type",
    "status",
    "payment",
    "last-appointment-status",
    "next-appointment",
    "action"
];


// DEFAULT SCHEDULE FORM DATA 
const _scheduleFormData = {
    appointmentDate: '',
    feedback: '',
    recoveryStatus: '',
    reason: ''
}

const Requests = () => {
    const { user } = UserState();
    const toast = useToast();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [requests, setRequests] = useState(null);
    const [downloadOpen, setDownloadOpen] = useState(false);  // for confirmation dialog
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRequests, setTotalRequests] = useState(0);
    const [filterData, setFilterData] = useState({ ...defaultClinicForm })
    const [appliedFilterCount, setAppliedFilterCount] = useState(0);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const [isRechedule, setIsRechedule] = useState(false);
    const [request, setRequest] = useState(null)
    const [scheduleFormData, setScheduleFormData] = useState({ ..._scheduleFormData });
    const [selectedDoctor, setSelectedDoctor] = useState({
        role: 'clinic-manager',
        _id: '6711fb36d66326c16c5763da'
    });

    // SCHEDULE APPOINTMENT
    const scheduleAppointment = async (requestId) => {
        setIsLoading(true);
        try {
            let _payload = {
                doctorId: selectedDoctor?._id,
                requestId: requestId
            };

            let res
            // WHEN THE REQUEST ONLY APPROVED NOT OPEN OR TREATMENT STARTED  
            if (request?.status == 'APPROVED' || request?.currentLastAppointment?.status == 'CANCELLED') { // need to fix condition
                _payload.appointmentDate = scheduleFormData?.appointmentDate;
                res = await post(`clinic/schedule/appointment/assign`, _payload);
            } else {
                res = await post(`clinic/feedback/${requestId}`, { ..._payload, ...scheduleFormData });
            }
            setIsLoading(false);

            if (res?.statusCode === 200 || res?.statusCode === 201) {
                getAllRequest(0, limit, filterData);
                onClose();
                setScheduleFormData(_scheduleFormData);
                toast({
                    title: "Request scheduled successfully.",
                    status: "success",
                    duration: 5000, // 5 seconds    
                    isClosable: true,
                });
            } else {
                toast({
                    title: res?.data?.message || "Request scheduling failed.",
                    status: "error",
                    duration: 5000, // 5 seconds    
                    isClosable: true,
                });
            }
        } catch (error) {
            setIsLoading(false);
            toast({
                title: error?.response?.data?.message || "Request scheduling failed.",
                status: "error",
                duration: 5000, // 5 seconds    
                isClosable: true,
            });
        }
    };

    // RE - SCHEDULE APPOINTMENT
    const reScheduleAppointment = async (appointmentId) => {
        setIsLoading(true);
        try {
            let _payload = {
                doctorId: selectedDoctor?._id,
                appointmentDate: scheduleFormData?.appointmentDate,
                reason: scheduleFormData?.reason
            };

            const res = await post(`clinic/re-schedule/appointment/${appointmentId}`, _payload)
            setIsLoading(false);

            if (res?.statusCode === 200 || res?.statusCode === 201) {
                getAllRequest(0, limit, filterData);
                onClose();
                setScheduleFormData(_scheduleFormData);
                setIsRechedule(false);
                toast({
                    title: "Request scheduled successfully.",
                    status: "success",
                    duration: 5000, // 5 seconds    
                    isClosable: true,
                });
            } else {
                toast({
                    title: res?.data?.message || "Request scheduling failed.",
                    status: "error",
                    duration: 5000, // 5 seconds    
                    isClosable: true,
                });
            }
        } catch (error) {
            setIsLoading(false);
            toast({
                title: error?.response?.data?.message || "Request scheduling failed.",
                status: "error",
                duration: 5000, // 5 seconds    
                isClosable: true,
            });
        }
    };

    // GET THE ALL APPLIED FILTER COUNT 
    const checkAppliedFilterCount = (filterData) => {
        try {
            // Initialize filtercount to 0
            let filtercount = 0;

            // Increment count for each applied filter
            if (filterData?.search) {
                filtercount++;
            }
            if (filterData?.dates?.start || filterData?.dates?.end) {
                filtercount++;
            }
            if (filterData?.requestStatus) {
                filtercount++;
            }
            if (filterData?.paymentStatus) {
                filtercount++;
            }
            if (filterData?.requestType) {
                filtercount++;
            }

            return filtercount; // Return the total count of applied filters
        } catch (error) {
            console.error("Error calculating applied filter count:", error);
            return 0; // Return a default value in case of an error
        }
    };

    // FETCH INTIAL DATA 
    const hasFetched = useRef(false); // Initially false

    // GET ALL AVAILABLE REQUESTS 
    const getAllRequest = async (skip = 0, limit, _filter) => {
        try {
            if (user) {
                const filtercount = checkAppliedFilterCount(_filter);
                setAppliedFilterCount(filtercount);

                const queryParams = new URLSearchParams();
                if (skip !== undefined && skip !== null) queryParams.append('skip', skip);
                if (limit) queryParams.append('limit', limit);
                if (_filter?.requestStatus) queryParams.append('status', _filter.requestStatus);
                if (_filter?.search) queryParams.append('search', _filter.search);
                if (_filter?.paymentStatus) queryParams.append('paymentStatus', _filter.paymentStatus);
                if (_filter?.requestType) queryParams.append('userAppointmentType', _filter.requestType);
                if (_filter?.dates?.start) queryParams.append('startDate', _filter?.dates?.start);
                if (_filter?.dates?.end) queryParams.append('endDate', _filter?.dates?.end);

                const requestUrl = `clinic/all/treament-requests${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;
                // Make the GET request
                const res = await get(requestUrl);
                if (res?.statusCode === 200) {
                    setRequests(res?.data?.treatmentRequests || []);
                    setTotalPages(res?.data?.totalPages);
                    setTotalRequests(res?.data?.totalCount)
                } else {
                    setRequests([]);
                    setTotalPages(0);
                    setTotalPages(0);
                }
            }
        } catch (error) {
            setRequests([]);
            setTotalPages(0);
            setTotalPages(0);
            console.log(error);
        }
    };

    useEffect(() => {
        if (!hasFetched.current) {
            getAllRequest(0, limit, defaultClinicForm);
            hasFetched.current = true; // Mark as fetched
        }
    }, [])

    // HANDLE PAGE CHANGE FUNCTION 
    const handlePageChange = (newPage) => {
        try {
            if (newPage >= 0 && newPage <= totalPages) {
                setPage(newPage);
                getAllRequest(newPage * limit, limit)
            }
        } catch (e) {
            console.log(`ERROR WHILE CHANGING PAGE ${e}`);
        }
    };

    // Fetch all attendances and then download as CSV
    const downloadCSV = async () => {
        try {
            const requestUrl = `clinic/all/treament-requests`;
            const res = await get(requestUrl);
            if (res?.statusCode == 200) {
                const requests = res?.data?.treatmentRequests || [];
                const headers = ['S.No', 'Player Name', 'Player Game', 'Player Mobile', 'Request Type', 'Request Status', 'Request Description', 'Request Date'];

                const rows = requests?.map((request, index) => [
                    index + 1,
                    request?.userId?.name,
                    request?.userId?.gameId?.name,
                    request?.userId?.mobile,
                    request?.corporateRequest && request?.userAppointmentType?.toLowerCase() === 'external' ?
                        'CORPORATE' :
                        request?.userAppointmentType,
                    request?.status,
                    request.injuryDescription,
                    formatDateTime(request?.createdAt, 'date'),
                ]);
                const csvContent = [headers, ...rows].map((e) => e.join(",")).join("\n");
                const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', 'attendances.csv');
                link.style.visibility = 'hidden';

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error('Error fetching attendances for CSV:');
            }
        } catch (error) {
            console.error('Error fetching attendances for CSV:', error);
        }
    };

    // Confirm download
    const confirmDownload = () => {
        try {
            downloadCSV();
            setDownloadOpen(false);
        } catch (e) {
            console.log(`ERROR WHILE GENERATING PDF ${e}`);
        }
    };

    // Debounced version of the getRequeste function
    const debouncedGetAllRequests = useCallback(
        debounce((skip, limit, _filter) => {
            getAllRequest(skip, limit, _filter);
        }, 300), // Adjust debounce delay as needed
    );

    // FORMATTING DATE / TIME FOR INPUT 
    const formatDateForInput = (isoString) => {
        try {
            if (!isoString) return "";
            return isoString.slice(0, 16); // Trims the date to "YYYY-MM-DDTHH:MM"
        } catch (e) {
            console.log(`ERROR WHILE FORMATING DATE TIME ${e}`);
        }
    };

    return (
        <>

            <BreadCrumbs />
            <div className='w-full flex-1 flex flex-col p-2'>
                <div className={`flex justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                    <h2 className='text-xl whitespace-nowrap font-bold mb-4'>{t("clinic")} {totalRequests > 0 && `(${totalRequests})`}</h2>
                    <div className={`flex w-full justify-between lg:w-fit gap-3 items-center ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}>
                        <div className="relative">
                            <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input
                                type="text"
                                id="table-search-users"
                                className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                                placeholder={t("search-request")}
                                value={filterData?.search}
                                onChange={(e) => {
                                    const _filter = {
                                        ...filterData,
                                        search: e?.target?.value
                                    }
                                    debouncedGetAllRequests(0, 10, _filter);
                                    setFilterData(_filter);
                                    setPage(0); // Reset to page 1 on new search
                                }}
                            />
                        </div>
                        {/* Download Confirmation Dialog */}
                        <ConfirmDownload
                            isOpen={downloadOpen}
                            label='download-report'
                            setIsOpen={setDownloadOpen}
                            handleDownload={() => {
                                setDownloadOpen(true);
                            }}
                            confirmDownload={() => {
                                confirmDownload();
                            }}
                            closeDialog={() => {
                                setDownloadOpen(false);
                            }}
                        />
                        <ClinicRequestsFilter
                            filterData={filterData}
                            setFilterData={setFilterData}
                            appliedFilterCount={appliedFilterCount}
                            onApplyFilter={() => {
                                setPage(0);
                                getAllRequest(0, limit, filterData);
                            }}
                            onResetFilter={() => {
                                const _payload = {
                                    ...defaultClinicForm,
                                    search: filterData?.search
                                }
                                setFilterData(_payload)
                                setPage(0);
                                getAllRequest(0, limit, _payload);
                            }}
                        />
                    </div>
                </div>
                <div className='w-full flex-1 flex flex-col '>
                    <ClinicTable
                        rows={requests}
                        columns={clinicRequestColumns}
                        totalPages={totalPages}
                        page={page}
                        handlePageChange={(newPage) => {
                            handlePageChange(newPage);
                        }}
                        onSchedule={(request) => {
                            setRequest(request);
                            if (request?.currentLastAppointment &&
                                ['scheduled', 'rescheduled'].includes(request?.currentLastAppointment?.status?.toLowerCase())) {
                                setIsRechedule(true);
                                setScheduleFormData((prevData) => ({
                                    ...prevData,
                                    appointmentDate: formatDateForInput(request?.currentLastAppointment?.appointmentDate),
                                    reason: ''
                                }));
                            } else {
                                setIsRechedule(false);
                                setScheduleFormData((prevData) => ({
                                    ...prevData,
                                    appointmentDate: '',
                                    reason: ''
                                }))
                            }
                            onOpen();
                        }}
                        onNavigate={(request) => {
                            navigate(`/clinic/request/${request?._id}`);
                        }}
                        fetchUpdatedData={() => {
                            getAllRequest(0, limit, filterData);
                        }}
                    />
                </div>
            </div>
            {/* SCHEDULE APPOINTMENT MODEL  */}
            <ScheduleAppointmentModal
                isOpen={isOpen}
                isRechedule={isRechedule}
                onClose={() => {
                    onClose();
                    setIsRechedule(false);
                }}
                scheduleFormData={scheduleFormData}
                onHandleChange={(field, value) => {
                    setScheduleFormData((prevData) => ({
                        ...prevData,
                        [field]: value
                    }));
                }}
                isLoading={isLoading}
                onHandleSubmit={() => {
                    if (isRechedule) {
                        reScheduleAppointment(request?.currentLastAppointment?._id)
                    } else {
                        scheduleAppointment(request?._id)
                    }
                }}
                request={request}
            />
        </>
    );
}
export default Requests;
import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Select,
    Textarea,
    Grid,
    IconButton,
    Spinner,
    Text
} from '@chakra-ui/react';
import { AddIcon, CloseIcon } from "@chakra-ui/icons";

import { useToast } from '@chakra-ui/react';
import { get, postFormData } from '../../services/apis/api';
import { useTranslation } from 'react-i18next';

const Form1 = ({ formData, setFormData, selectedMedia, setSelectedMedia, existingMedia, setExistingMedia, isEdit }) => {
    const [games, setGames] = useState([]);
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const getGameDropdown = async () => {
        const res = await get('game/dropdown');
        if (res.statusCode === 200) {
            setGames(res.data.dropdown);
        }
    }

    useEffect(() => {
        getGameDropdown();
    }, []);

    const removeMedia = (index, isExisting = false) => {
        if (isExisting) {
            setExistingMedia((prevImages) =>
                prevImages.filter((_, i) => i !== index)
            );
        } else {
            setSelectedMedia((prevImages) =>
                prevImages.filter((_, i) => i !== index)
            );
        }
    };

    const handleMediaChange = (e) => {
        const files = Array.from(e.target.files);
        setSelectedMedia((prevImages) => [...prevImages, ...files]);
    };

    return (
        <>
            <Flex>
                <FormControl mr="5%">
                    <FormLabel htmlFor="event-name" fontWeight={'normal'}>
                        {t('Event name')}
                    </FormLabel>
                    <Input
                        id="event-name"
                        name="name"
                        onChange={handleChange}
                        value={formData.name}
                        placeholder={t('Event name')}
                    />
                </FormControl>

                {!isEdit && <FormControl>
                    <FormLabel htmlFor="category" fontWeight={'normal'}>
                        {t('Category')}

                    </FormLabel>
                    <Select
                        id="category"
                        name="category"
                        onChange={handleChange}
                        value={formData.category}
                        placeholder={t('Select option')}
                    >
                        <option value='ANNOUNCEMENT EVENT'>ANNOUNCEMENT EVENT</option>
                        <option value='GENERAL EVENT'>GENERAL EVENT</option>
                        <option value='SPORT EVENT'>SPORT EVENT</option>
                        <option value='LIVE STREAM'>LIVE STREAM</option>
                    </Select>
                </FormControl>}
            </Flex>
            <Flex>
                <FormControl mr="5%">
                    <FormLabel htmlFor="location" fontWeight={'normal'}>
                        {t("Location")}
                    </FormLabel>
                    <Input
                        id="location"
                        name="location"
                        onChange={handleChange}
                        value={formData.location}
                        placeholder={t("Location")}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel htmlFor="date-time" fontWeight={'normal'}>
                        {t('Date & Time')}
                    </FormLabel>
                    <Input
                        id="date-time"
                        name="dateTime"
                        onChange={handleChange}
                        value={formData.dateTime}
                        placeholder='Select Date and Time'
                        size='md'
                        type='datetime-local'
                    />
                </FormControl>
            </Flex>
            {
                formData.category === 'SPORT EVENT' ?
                    <FormControl>
                        <FormLabel htmlFor="game-category" fontWeight={'normal'}>
                            Game Category
                        </FormLabel>
                        <Select
                            id="game-category"
                            name="gameId"
                            onChange={handleChange}
                            value={formData.gameCategory}
                            placeholder='Select Game'
                        >
                            {
                                games.map((game) => (
                                    <option key={game._id} value={game._id}>{game.name}</option>
                                ))
                            }
                        </Select>
                    </FormControl> : ""
            }
            <FormControl id="about" mt={1}>
                <FormLabel htmlFor="game-category" fontWeight={'normal'}>
                    {t('Description')}
                </FormLabel>
                <Textarea
                    id="description"
                    name="description"
                    onChange={handleChange}
                    value={formData.description}
                    placeholder={t("Brief description for your Event")}
                    rows={3}
                    shadow="sm"
                    focusBorderColor="brand.400"
                    fontSize={{
                        sm: 'sm',
                    }}
                />
            </FormControl>
            {formData.category === 'LIVE STREAM' && (
                <FormControl mt="4">
                    <FormLabel htmlFor="stream-url" fontWeight={'normal'}>
                        {t('Stream URL')}
                    </FormLabel>
                    <Input
                        id="stream-url"
                        name="streamUrl"
                        onChange={handleChange}
                        value={formData.streamUrl}
                        placeholder="Enter stream URL"
                    />
                </FormControl>
            )}
            {formData.category !== 'LIVE STREAM' && (<FormControl>{t('Add Media')}
                <Grid templateColumns="repeat(4, 1fr)" gap={4}>
                    {existingMedia.map((media, index) => (
                        media.type === 'image' ? (
                            <Box
                                key={index}
                                position="relative"
                                borderRadius="md"
                                overflow="hidden"
                                width="100px"
                                height="100px"
                                bgImage={`url(${media.url})`}
                                bgSize="cover"
                                bgPosition="center"
                            >
                                <IconButton
                                    aria-label="Remove image"
                                    size="xs"
                                    icon={<CloseIcon />}
                                    position="absolute"
                                    top="2px"
                                    right="2px"
                                    onClick={() => removeMedia(index, true)}
                                />
                            </Box>
                        ) : (
                            <Box key={index}
                                position="relative"
                                borderRadius="md"
                                overflow="hidden"
                                width="100px"
                                height="100px"
                                bgSize="cover"
                                bgPosition="center">
                                <video controls className="absolute w-full h-full object-cover">
                                    <source src={media.url} type="video/mp4" />
                                </video>
                                <IconButton
                                    aria-label="Remove video"
                                    size="xs"
                                    icon={<CloseIcon />}
                                    position="absolute"
                                    top="2px"
                                    right="2px"
                                    onClick={() => removeMedia(index, true)}
                                />
                            </Box>
                        )
                    ))}

                    {selectedMedia.map((media, index) => {
                        const isVideo = media.type.startsWith('video/');
                        const isImage = media.type.startsWith('image/');

                        return (
                            <Box
                                key={index}
                                position="relative"
                                borderRadius="md"
                                overflow="hidden"
                                width="100px"
                                height="100px"
                                bgSize="cover"
                                bgPosition="center"
                            >
                                {isImage ? (
                                    <Box
                                        key={index}
                                        position="relative"
                                        borderRadius="md"
                                        overflow="hidden"
                                        width="100px"
                                        height="100px"
                                        bgImage={URL.createObjectURL(media)}
                                        bgSize="cover"
                                        bgPosition="center"
                                    />
                                ) : isVideo ? (
                                    <video controls className="absolute w-full h-full object-cover">
                                        <source src={URL.createObjectURL(media)} type={media.type} />
                                    </video>
                                ) : (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        width="100px"
                                        height="100px"
                                        bgColor="gray.300"
                                    >
                                        <Text>Unsupported type</Text>
                                    </Box>
                                )}
                                <IconButton
                                    aria-label={isVideo ? "Remove video" : "Remove image"}
                                    size="xs"
                                    icon={<CloseIcon />}
                                    position="absolute"
                                    top="2px"
                                    right="2px"
                                    onClick={() => removeMedia(index)}
                                />
                            </Box>
                        );
                    })}


                    <Box
                        as="label"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        border="1px dashed"
                        borderColor="gray.300"
                        borderRadius="md"
                        width="100px"
                        height="100px"
                        cursor="pointer"
                    >
                        <Input
                            type="file"
                            display="none"
                            multiple
                            accept="image/*,video/*"
                            onChange={handleMediaChange}
                        />
                        <AddIcon />
                    </Box>
                </Grid>
            </FormControl>
            )}  </>
    );
};

export default function CreateEventForm({ getAllEvents, onClose, isEdit, event, setViewEvent }) {
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [existingMedia, setExistingMedia] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        category: '',
        location: '',
        dateTime: '',
        description: '',
    });
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    useEffect(() => {
        if (isEdit) {
            const date = new Date(event.dateTime);
            const formattedDate = date.toISOString().slice(0, 16);
            setFormData({
                name: event.name || '',
                category: event.category || '',
                location: event.location || '',
                dateTime: formattedDate || '',
                description: event.description || '',
                streamUrl: event.streamUrl || '',

            });
            setExistingMedia(event.media || []);
        }
    }, [isEdit, event]);
    console.log('formdata---->', event);

    const handleEventSubmit = async () => {
        try {
            setLoading(true);
            if (!formData.name || !formData.category || !formData.dateTime || !formData.location || !formData.description) {
                throw new Error("All required fields must be filled.");
            }
            const data = new FormData();
            Object.entries(formData).forEach(([key, value]) => data.append(key, value));

            // if (formData.category !== "SPORT EVENT") {
            //     data.append("gameId", formData.gameId);
            // }

            // Add new media
            selectedMedia.forEach(file => data.append("media", file));

            // Append existing media URLs (to be sent with the request)
            data.append("existingMedia", JSON.stringify(existingMedia.map(media => media.url)));

            if (isEdit) {
                const res = await postFormData(`event/update/${event._id}`, data);
                setViewEvent(false)
                if (res.statusCode === 200) {
                    setLoading(false);
                    getAllEvents();
                    onClose();
                    toast({
                        title: 'Success!',
                        description: res.data.message,
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } else {
                const res = await postFormData('event/add', data);

                if (res.statusCode === 201) {
                    setLoading(false);
                    getAllEvents();
                    onClose();
                    toast({
                        title: 'Success!',
                        description: res.data.message,
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: 'Error!',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };


    return (
        <Box
            borderWidth="1px"
            rounded="lg"
            shadow="1px 1px 3px rgba(0,0,0,0.3)"
            maxWidth={800}
            p={4}
            m="10px auto"
            as="form"
        >
            <Form1
                setFormData={setFormData}
                formData={formData}
                selectedMedia={selectedMedia}
                setSelectedMedia={setSelectedMedia}
                existingMedia={existingMedia}
                setExistingMedia={setExistingMedia}
                isEdit={isEdit}
            />
            <Flex justifyContent="flex-end" mt="4">
                <Button
                    colorScheme="red"
                    onClick={handleEventSubmit}
                    isLoading={loading}
                >
                    {t('submit')}
                </Button>
            </Flex>
        </Box>
    );
}

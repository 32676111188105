import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Initialize i18next
i18n
  .use(HttpApi) // Load translations using Http backend
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Pass i18n instance to react-i18next
  .init({
    fallbackLng: 'en', // Default language when translation is missing
    supportedLngs: ['en', 'ar'], // Languages to support
    debug: true, // Enable this for debugging purposes
    detection: {
      order: ['localStorage', 'cookie', 'navigator'],
      caches: ['localStorage', 'cookie'], // Store language choice in localStorage or cookies
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Path for translation files
    },
    react: {
      useSuspense: false, // Set to false if not using Suspense in React
    },
  });

export default i18n;

import React, { useState, useEffect } from 'react';
import {
    Button,
    Input,
    Textarea,
    Select,
    useDisclosure,
    FormControl,
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast,
    IconButton,
    Grid,
    GridItem,
    Tooltip,
} from '@chakra-ui/react';
import { patch, post } from '../../../services/apis/api';
import { EditIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

const CreateAsset = ({ getAssets, isEdit, asset }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const [isLoadingState, setIsLoadingState] = useState(false);
    const isArabic = i18n.language === 'ar';
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        category: '',
        model: '',
        serialNumber: '',
        purchaseDate: '',
        status: 'available',
        condition: 'excellent',
        price: '',
        depritiationRate: '',
        depritiationFrequency: 'monthly'
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        if (isEdit) {
            setFormData({
                name: asset?.name || '',
                description: asset?.description || '',
                category: asset?.category || '',
                model: asset?.model || '',
                serialNumber: asset?.serialNumber || '',
                purchaseDate: asset?.purchaseDate || '',
                status: asset?.status || 'available',
                condition: asset?.condition || 'excellent',
                price: asset?.price || '',
                depritiationRate: asset?.depritiationRate || '',
                depritiationFrequency: asset?.depritiationFrequency || 'monthly'
            });
        }
    }, [isEdit, asset]);

    // HANDLE ASSETS REQUEST 
    const handleSubmit = async () => {
        setIsLoadingState(true);
        try {
            let response;
            if (isEdit) {
                response = await patch(`assets/update/${asset._id}`, formData);
            } else {
                response = await post('assets/add', formData);
            }
            setIsLoadingState(false);
            if (response.statusCode === 201 || response.statusCode === 200) {
                toast({
                    title: 'Asset saved.',
                    description: 'The asset has been saved successfully.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onClose();
                getAssets();
                setFormData({
                    name: '',
                    description: '',
                    category: '',
                    model: '',
                    serialNumber: '',
                    purchaseDate: '',
                    status: 'available',
                    condition: 'excellent',
                    price: 0,
                    depritiationFrequency: 'monthly',
                    depritiationRate: 0
                });
            } else {
                toast({
                    title: 'Error',
                    description: 'Failed to save the asset.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            setIsLoadingState(false);
            toast({
                title: 'Error',
                description: 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const textAlign = isArabic ? 'right' : 'left'

    return (
        <div>
            {
                isEdit ?
                    <Tooltip label='Edit Asset'>
                        <IconButton
                            icon={<EditIcon />}
                            onClick={onOpen}
                            aria-label="Edit Asset"
                            ml={2}
                        />
                    </Tooltip>
                    :
                    <Button onClick={onOpen} colorScheme="blue">
                        {t('add-asset')}
                    </Button>
            }

            <Modal isOpen={isOpen} onClose={onClose} size="4xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign={isArabic ? 'right' : 'left'} dir={isArabic ? 'rtl' : 'ltr'}>{isEdit ? t('edit-asset') : t('add-asset')}</ModalHeader>
                    <ModalCloseButton
                        style={{
                            [isArabic ? 'left' : 'right']: '10px' // Conditional positioning based on language
                        }}
                    />
                    <ModalBody>
                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                            <GridItem>
                                <FormControl id="name" isRequired>
                                    <FormLabel textAlign={textAlign}>{t('asset-name')}</FormLabel>
                                    <Input
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        textAlign={textAlign}
                                        placeholder={t('asset-name')}
                                    />
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl id="category" isRequired>
                                    <FormLabel textAlign={textAlign}>{t('category')}</FormLabel>
                                    <Input
                                        name="category"
                                        textAlign={textAlign}
                                        value={formData.category}
                                        onChange={handleInputChange}
                                        placeholder={t('category')}
                                    />
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl id="model">
                                    <FormLabel textAlign={textAlign}>{t('model')}</FormLabel>
                                    <Input
                                        name="model"
                                        textAlign={textAlign}
                                        value={formData.model}
                                        onChange={handleInputChange}
                                        placeholder={t('model')}
                                    />
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl id="serialNumber">
                                    <FormLabel textAlign={textAlign}>{t('serial-number')}</FormLabel>
                                    <Input
                                        name="serialNumber"
                                        textAlign={textAlign}
                                        value={formData.serialNumber}
                                        onChange={handleInputChange}
                                        placeholder={t('serial-number')}
                                    />
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl id="purchaseDate">
                                    <FormLabel textAlign={textAlign}>{t('purchase-date')}</FormLabel>
                                    <Input
                                        type="date"
                                        name="purchaseDate"
                                        textAlign={textAlign}
                                        value={formData.purchaseDate ? new Date(formData.purchaseDate).toISOString().split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>
                            </GridItem>

                            {/* <GridItem>
                                <FormControl id="status" isRequired>
                                    <FormLabel>Status</FormLabel>
                                    <Select
                                        name="status"
                                        value={formData.status}
                                        onChange={handleInputChange}
                                    >
                                        <option value="available">Available</option>
                                        <option value="assigned">Assigned</option>
                                        <option value="under-maintenance">Under Maintenance</option>
                                        <option value="returned">Returned</option>
                                    </Select>
                                </FormControl>
                            </GridItem> */}

                            <GridItem>
                                <FormControl id="condition" isRequired>
                                    <FormLabel textAlign={textAlign}>{t('condition')}</FormLabel>
                                    <Select
                                        name="condition"
                                        value={formData.condition}
                                        onChange={handleInputChange}
                                    >
                                        <option value="poor">{t('poor')}</option>
                                        <option value="fair">{t('fair')}</option>
                                        <option value="good">{t('good')}</option>
                                        <option value="excellent">{t('excellent')}</option>
                                    </Select>
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl id="price">
                                    <FormLabel textAlign={textAlign}>{t('price')}</FormLabel>
                                    <Input
                                        name="price"
                                        type="number"
                                        textAlign={textAlign}
                                        value={formData?.price}
                                        onChange={handleInputChange}
                                        placeholder={t('price')}
                                    />
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl id="depritiationFrequency" isRequired>
                                    <FormLabel textAlign={textAlign}>{t('depritiation-frequency')}</FormLabel>
                                    <Select
                                        name="depritiationFrequency"
                                        value={formData?.depritiationFrequency}
                                        onChange={handleInputChange}
                                    >
                                        <option value="monthly">{t('monthly')}</option>
                                        <option value="quaterly">{t('quaterly')}</option>
                                        <option value="half-yearly">{t('half-yearly')}</option>
                                        <option value="yearly">{t('yearly')}</option>
                                    </Select>
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl id="depritiationRate" isRequired>
                                    <FormLabel textAlign={textAlign}>{t('depritiation-rate')}</FormLabel>
                                    <Input
                                        type="number"
                                        name="depritiationRate"
                                        textAlign={textAlign}
                                        value={formData?.depritiationRate}
                                        onChange={handleInputChange}
                                        placeholder={t('depritiation-rate')}
                                    />
                                </FormControl>
                            </GridItem>

                            <GridItem colSpan={2}>
                                <FormControl id="description">
                                    <FormLabel textAlign={textAlign}>{t('description')}</FormLabel>
                                    <Textarea
                                        name="description"
                                        textAlign={textAlign}
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        placeholder={t('description')}
                                    />
                                </FormControl>
                            </GridItem>
                        </Grid>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" onClick={onClose}>
                            {t('cancel')}
                        </Button>
                        <Button isLoading={isLoadingState} colorScheme="blue" mx={3} onClick={handleSubmit}>
                            {t('submit')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default CreateAsset;

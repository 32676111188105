import { Button, FormControl, FormLabel, Input, useDisclosure, SimpleGrid, GridItem, Select, Spinner, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { get, post } from '../../services/apis/api'
import ReactSelect from 'react-select'
import { useTranslation } from 'react-i18next'
const PlayerPenaltyRequest = ({ isOpen, onClose }) => {
  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)
  const toast = useToast();
  const [players, setPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const [formValues, setFormValues] = useState({
    playerId: '',
    penaltyType: 'MORAL',
    status: 'HR-MANAGER-REVIEW',
    description: '',
    violationDetails: {
      date: '',
      place: '',
      time: '',
      type: ''
    }
  })

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  // Handle nested input change for violation details
  const handleNestedChange = (e) => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      violationDetails: { ...formValues.violationDetails, [name]: value }
    })
  }

  // Handle form save
  const handleSave = async() => {
    try {
      setLoading(true);
      console.log(formValues);
      const res = await post('request/player-penalty', formValues);
      if(res.statusCode === 200){
        onClose();
        setLoading(false);
        toast({
            title: 'Successful!',
            description: res.data.message,
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
      } 
    } catch (error) {
      setLoading(false);
      console.error(error);
      if(error.response.status === 500){
        toast({
          title: 'Error!',
          description: "Please fill all the fields correctly",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  }

  useEffect(() => {
    getPlayers();
  }, []);

  // Fetch players data
  const getPlayers = async () => {
    try {
      const res = await get('player/dropdown');
      if (res.statusCode === 200) {
        const formattedPlayers = res.data.dropdown.map(player => ({
          label: player.name,
          value: player._id
        }));
        setPlayers(formattedPlayers);
      }
    } catch (error) {
      console.error('Error fetching players:', error);
    }
  }

  const handlePlayerSelectChange = (selectedOption) => {
    setSelectedPlayer(selectedOption);
    setFormValues({
      ...formValues,
      playerId: selectedOption ? selectedOption.value : ''
    });
  }

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Player Penalty Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <SimpleGrid columns={3} spacing={4} dir={isArabic ? 'rtl' : 'ltr'}>
              <GridItem colSpan={2} >
                <FormControl>
                  <FormLabel>{t('player-id')}</FormLabel>
                  <ReactSelect
                    className='text-right'
                    options={players}
                    value={selectedPlayer}
                    onChange={handlePlayerSelectChange}
                    placeholder={t("select-player-id")}
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>{t('penalty-type')}</FormLabel>
                  <Select
                    name="penaltyType"
                    value={formValues.penaltyType}
                    onChange={handleChange}
                    placeholder={t("select-penalty-type")}
                    textAlign={'center'}
                  >
                    <option value="MORAL">{t('moral')}</option>
                    <option value="FINANCE">{t('finance')}</option>
                  </Select>
                </FormControl>
              </GridItem>

              <GridItem colSpan={3}>
                <FormControl>
                  <FormLabel>{t(']description')}</FormLabel>
                  <Input
                    name="description"
                    value={formValues.description}
                    onChange={handleChange}
                    placeholder={t("description")}
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>{t('violation-date')}</FormLabel>
                  <Input
                    name="date"
                    value={formValues.violationDetails.date}
                    onChange={handleNestedChange}
                    type="date"
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>{t('violation-place')}</FormLabel>
                  <Input
                    name="place"
                    value={formValues.violationDetails.place}
                    onChange={handleNestedChange}
                    placeholder={t("violation-place")}
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>{t('violation-time')}</FormLabel>
                  <Input
                    name="time"
                    value={formValues.violationDetails.time}
                    onChange={handleNestedChange}
                    type="time"
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>{t('violation-type')}</FormLabel>
                  <Input
                    name="type"
                    value={formValues.violationDetails.type}
                    onChange={handleNestedChange}
                    placeholder={t('violation-type')}
                  />
                </FormControl>
              </GridItem>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3}>{t('cancel')}</Button>
            <Button colorScheme="blue" onClick={handleSave}>
              {loading ? <Spinner /> : t("save")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default PlayerPenaltyRequest

import React, { useEffect, useState } from 'react'
import AddPerformance from '../../components/hr/dialogBoxes/AddPerformance';
import { get } from '../../services/apis/api';
import { UserState } from '../../context/user';
import { Box, Image, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TeamPerformance = () => {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const isArabic = i18n.language === 'ar';
    const [teamMembers, setTeamMembers] = useState(null);

    const getReportingHeadMembers = async () => {
        try {
            const res = await get(`performance/reporting-head-members?reportingHead=${user._id}`);
            if (res?.statusCode == 200) {
                setTeamMembers(res?.data?.data)
            } else {
                setTeamMembers([]);
            }
        } catch (error) {
            setTeamMembers([]);
            console.log(error);
        }
    }

    useEffect(() => {
        getReportingHeadMembers();
    }, []);

    if (teamMembers === null) {
        return (
            <div className='flex !w-full h-[75vh] items-center justify-center'>
                <Spinner />
            </div>
        )
    }

    return (
        <div className='p-4'>
            <div className={`flex justify-between items-center ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}>
                <div>
                    <h1 className="text-xl font-bold mb-4">{t('team-performance')}</h1>
                </div>
                <div>
                    <AddPerformance getAllEmployeePerformance={() => { }} isEdit={false} />
                </div>
            </div>

            <div className="overflow-x-auto mt-4 shadow-md sm:rounded-lg">
                <Table dir={isArabic ? 'rtl' : 'ltr'} className="w-full text-sm text-left text-gray-500">
                    <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <Tr>
                            <Th>{t('user')}</Th>
                            <Th>{t('employee-code')}</Th>
                            <Th>{t('designation')}</Th>
                            <Th>{t('mobile')}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {teamMembers?.length > 0 && teamMembers?.map((member) => (
                            <Tr
                                onClick={() => {
                                    navigate(`/employee/team-performance/emp/${member._id}`)
                                }}
                                key={member?._id}
                                cursor="pointer"
                            >
                                <Td className="flex items-center px-6 gap-4 py-4 text-gray-900 whitespace-nowrap">
                                    <Image className="w-10 h-10 rounded-full" src={member?.pic} alt="Employee image" />
                                    <Box >
                                        <Text className="text-base font-semibold">{member?.name || '--'}</Text>
                                        <Text className="font-normal text-gray-500">{member?.email || '--'}</Text>
                                    </Box>
                                </Td>
                                <Td>{member?.employeeCode || 'N/A'}</Td>
                                <Td>{member?.designation || 'N/A'}</Td>
                                <Td>{member?.mobile || 'N/A'}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </div>
        </div>
    )
}

export default TeamPerformance;

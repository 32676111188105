import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    Input,
    FormLabel,
    Button,
    Select,
} from '@chakra-ui/react'

import React, { useState } from 'react'



function ScheduleAppointmentModal({ isOpen, onClose, scheduleFormData, onHandleChange, isLoading, onHandleSubmit, request }) {
    // const { isOpen, onOpen, onClose } = useDisclosure()
    const [error, setError] = useState('')
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    return (
        <>
            {/* <Button >Open Modal</Button> */}
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Schedule Appointment</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>Select Date/Time</FormLabel>
                            <Input
                                value={scheduleFormData?.name}

                                onChange={(e) => {
                                    const selectedDateTime = new Date(e.target.value); // Convert the input value to a Date object
                                    const currentDateTime = new Date(); // Get the current date and time

                                    onHandleChange('appointmentDate', e.target.value); // Call the handle change function 
                                    // Validate the selected date
                                    if (selectedDateTime < currentDateTime) {
                                        setError('Selected date and time cannot be in the past.');
                                    } else {
                                        setError(''); // Clear the error if the date is valid
                                    }
                                }}
                                type='datetime-local'
                                ref={initialRef}
                                placeholder='Category Name'
                                isInvalid={error !== ''}
                            />
                            {error && <p className="text-red-500 text-xs">{error}</p>}
                        </FormControl>
                        {request?.status === 'IN-PROGRESS'? (
                            <>
                                <FormControl mt={4}>
                                    <FormLabel>Feedback</FormLabel>
                                    <Input
                                        value={scheduleFormData?.feedback}
                                        onChange={(e) => {
                                            onHandleChange('feedback', e.target.value); // Call the handle change function 
                                        }}
                                        type='text'
                                        ref={initialRef}
                                        placeholder='Feedback'
                                    />
                                </FormControl>
                                <FormControl mt={4}>
                                    <FormLabel>Category Mode</FormLabel>
                                    <Select
                                        value={scheduleFormData?.mode}
                                        onChange={(e) => {
                                            onHandleChange('recoveryStatus', e.target.value)
                                        }}
                                        placeholder='Select Recovery Status'
                                    >
                                        <option value='IN_PROGRESS'>In Progress</option>
                                        <option value='COMPLETED'>Completed </option>
                                    </Select>
                                </FormControl>
                            </>
                        ) : null}
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={4}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={onHandleSubmit}
                            colorScheme='blue'
                            isLoading={isLoading}
                            isDisabled={error !== ''}
                        >
                            Schedule
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    )
}

export default ScheduleAppointmentModal
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    Input,
    FormLabel,
    Button,
    Select,
    useToast,
    Textarea
} from '@chakra-ui/react'
import { set } from 'lodash';

import React from 'react'
import { BiImage } from 'react-icons/bi'
import { postFormData } from '../../services/apis/api';

function AddAditionalIncome({ isOpen, onClose, onReset, formData, onHandleChange, isLoading, onHandleSubmit }) {

    return (
        <>
            {/* <Button >Open Modal</Button> */}
            <Modal
                // initialFocusRef={initialRef}
                // finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Additional Income</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody mt={4}>
                        <FormControl>
                            <FormLabel>Date <span className='text-red-600'>*</span></FormLabel>
                            <Input
                                type='date'
                                value={formData?.entryDate}
                                onChange={(e) => {
                                    const selectedDate = new Date(e.target.value);
                                    const today = new Date();
                                    // Check if the selected date is greater than today
                                    if (selectedDate <= today) {
                                        onHandleChange('entryDate', e.target.value);
                                    } else {
                                        alert("The selected date cannot be greater than today.");
                                    }
                                }}
                                placeholder='Date'
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Amount <span className='text-red-600'>*</span></FormLabel>
                            <Input
                                value={formData?.amount}
                                type='number'
                                onChange={(e) => {
                                    onHandleChange('amount', e.target.value)
                                }}
                                placeholder='Amount'
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                value={formData?.description}
                                onChange={(e) => {
                                    onHandleChange('description', e.target.value)
                                }}
                                placeholder='Entry Description'
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={4}
                            onClick={onReset}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={onHandleSubmit}
                            // onClick={handleSave}
                            colorScheme='blue'
                            isLoading={isLoading}
                            isDisabled={formData?.entryDate && formData?.amount && formData?.sourceOfIncome ? false : true}
                        >
                            Add
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    )
}

export default AddAditionalIncome
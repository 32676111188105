import { Skeleton } from "@chakra-ui/react";

// CATEGORY CARD SKELETON
export const CategorySkeleton = () => {
    return (
        <>
            {[...Array(4)].map((_, index) => (
                <Skeleton
                    key={index}
                    height={120}
                    width={'20%'}
                    isLoaded={false}
                    fadeDuration={1}
                />
            ))}
        </>
    );
}

// EARNINGS AND INCOME STATISTICS SKELETON
export const EarningCardSkeleton = () => {
    return (
        <>
            {[...Array(4)].map((_, index) => (
                <Skeleton
                    key={index}
                    height={120}
                    width={'24%'}
                    isLoaded={false}
                    fadeDuration={1}
                />
            ))}
        </>
    );
}

// EXPENSES STATISTICS SKELETON
export const ExpensesCardSkeleton = () => {
    return (
        <>
            {[...Array(6)].map((_, index) => (
                <Skeleton
                    key={index}
                    height={120}
                    width={'32%'}
                    isLoaded={false}
                    fadeDuration={1}
                />
            ))}
        </>
    );
}

// CATEGORY DETAILS SKELETON 
export const CategoryDetailSkeleton = () => {
    return (
        <>
            <div className="w-full items-start flex justify-between">
                <div className="flex items-start gap-3 w-full flex-col">
                    <Skeleton
                        height={5}
                        variant={'text'}
                        width={'30%'}
                        isLoaded={false}
                        fadeDuration={1}
                    />
                    <Skeleton
                        height={5}
                        variant={'text'}
                        width={'25%'}
                        isLoaded={false}
                        fadeDuration={1}
                    />
                    <Skeleton
                        height={5}
                        variant={'text'}
                        width={'22%'}
                        isLoaded={false}
                        fadeDuration={1}
                    />
                    <Skeleton
                        height={5}
                        variant={'text'}
                        width={'70%'}
                        isLoaded={false}
                        fadeDuration={1}
                    />
                </div>
                <Skeleton
                    height={10}
                    variant={'button'}
                    width={'10%'}
                    isLoaded={false}
                    fadeDuration={1}
                />
            </div>
        </>
    );
}




import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Button,
    Select,
    useToast,
    Textarea,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';

// RECOVERY STATUS OPTIONS 
const recoveryStatusOptions = [
    { label: "not_started", value: "NOT_STARTED" },
    { label: "in_progress", value: "IN_PROGRESS" },
    { label: "completed", value: "COMPLETED" },
    { label: "follow_up", value: "FOLLOW_UP" },
    { label: "requires_treatment", value: "REQUIRES_FURTHER_TREATMENT" },
    { label: "discharged", value: "DISCHARGED" }
];

function AddFeedbackNote({
    isOpen,
    onClose,
    isLoading,
    onHandleSubmit,
    note,
    setNote,
    label
}) {
    // const { isOpen, onOpen, onClose } = useDisclosure()
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    return (
        <>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign={isArabic ? 'right' : 'left'}>
                        {t(label)}
                    </ModalHeader>
                    <ModalCloseButton
                        style={{
                            [isArabic ? 'left' : 'right']: '10px' // Conditional positioning based on language
                        }}
                    />
                    <ModalBody pb={6}>
                        {/* ADD NOTE/FEEDBACK  */}
                        <FormControl>
                            <FormLabel textAlign={isArabic ? 'right' : 'left'}>{t('feedback')}</FormLabel>
                            <Textarea
                                value={note}
                                textAlign={isArabic ? "right" : "left"}
                                placeholder={t('add-feedback')}
                                resize="vertical"  // Optional: allows vertical resizing of the textarea
                                onChange={(e) => {
                                    setNote(e?.target?.value)
                                }}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={4}
                            onClick={onClose}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={onHandleSubmit}
                            colorScheme='blue'
                            isLoading={isLoading}
                            isDisabled={!note}
                        >
                            {t('add')}  
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    )
}

export default AddFeedbackNote
import React, { useEffect, useState } from 'react';
import { get } from '../../services/apis/api';
import CreateSupplier from '../../components/dialogueBoxes/CreateSupplier';
import ViewEvent from '../../components/mediaCenter/ViewEvent';

const Supplier = () => {
  const [viewEvent, setViewEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [suppliers, setSuppliers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const getAllSuppliers = async (query = '') => {
    try {
      const res = await get(`supplier/all?search=${query}`);
      console.log("supplier", res.data.data);
      setSuppliers(res.data.data);
    } catch (error) {
      console.error('Error fetching suppliers:', error);
    }
  };

  useEffect(() => {
    getAllSuppliers(searchQuery);
  }, [searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div>
      {
        viewEvent ?
          <ViewEvent event={selectedEvent} setViewEvent={setViewEvent} /> :
          <div>
            <div className='flex justify-between px-2 items-center mb-4'>
              <div>
                <div className="relative">
                  <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                  </div>
                  <input 
                    type="text" 
                    id="table-search-users" 
                    className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " 
                    placeholder="Search for supplier" 
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
              <div>
                <CreateSupplier getAllSuppliers={getAllSuppliers} />
              </div>
            </div>
            <div>
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
                    <tr>
                      <th scope="col" className="p-4">
                        <div className="flex items-center">
                          S.No.
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Supplier
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Contact Person
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Email
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Phone
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Product
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {suppliers.map((supplier, index) => (
                      <tr key={supplier._id} className="bg-white border-b  hover:bg-gray-50 ">
                        <td className="w-4 p-4">
                          <div className="flex items-center">
                            {index + 1}
                          </div>
                        </td>
                        <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap ">
                          <div className="text-base font-semibold">{supplier.companyName}</div>
                        </th>
                        <td className="px-6 py-4">
                          {supplier.contactPerson}
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex items-center">
                            {supplier.email}
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          {supplier.mobile}
                        </td>
                        <td className="px-6 py-4">
                          {
                            supplier.productsSupplied.map(product => product?.productId?.name).join(',')
                          }
                        </td>
                        <td className="px-6 py-4">
                          <CreateSupplier getAllSuppliers={getAllSuppliers} supplier={supplier} isEdit={true} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      }
    </div>
  );
}

export default Supplier;

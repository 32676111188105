import React from "react";
import { Box } from "@chakra-ui/react";
export default function ContactMahCompany() {
    return (
        <>
        <Box height={300}></Box>
            <div>
                <Box
                    display="flex"
                    flexDirection="column"
                    backgroundColor="white"
                    position="absolute" // Position the contact section absolutely
                    bottom={0} // Align it to the bottom with some padding
                    width="100%" // Ensure it takes the full width of the sidebar
                    px={4}
                >
                    <div className="container my-3 mx-4 text-sm">
                        <div className="font-bold text-lg">
                            For more information <br />
                            Please contact MAH-IT Software.
                        </div>
                        <div>
                            Phone:{" "}
                            <a
                                href="tel:+916267282312"
                                className="text-blue-500 underline"
                            >
                                +91 6267282312
                            </a>
                        </div>
                        <div>
                            WhatsApp:{" "}
                            <a
                                href="https://wa.me/916267282312"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 underline"
                            >
                                @WhatsApp
                            </a>
                        </div>
                        <div>
                            Email:{" "}
                            <a
                                href="mailto:info@mahcompany.com"
                                className="text-blue-500 underline"
                            >
                                info@mahcompany.com
                            </a>
                        </div>
                    </div>
                </Box>
            </div>
        </>
    );
}

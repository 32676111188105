import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Input,
  Button,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { DownloadIcon, SearchIcon, ChevronLeftIcon, ChevronRightIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { formatDateTime } from '../../utills/formateDate';
import CreateFNFRequest from '../../components/hr/dialogBoxes/CreateFNFRequest';
import { get } from '../../services/apis/api';
import ViewFNFRequest from '../../components/hr/components/fnf/ViewFNFRequest';

const Fnf = () => {
  const [requests, setRequests] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [viewFNF, setViewFNF] = useState(false);
  const [selectedReq, setSelectedReq] = useState(null);

  // Fetch FNF requests from the backend
  const fetchFnfRequests = async () => {
    setLoading(true);
    try {
      const res = await get(`request/all-fnf?search=${search}&page=${page}&limit=10`);
      if (res.statusCode === 200) {
        setRequests(res.data.data);
        setTotalPages(res.data.totalPages);
      }
    } catch (error) {
      console.error('Error fetching FNF requests:', error);
    }
    setLoading(false);
  };

  // Fetch data on page load and when search or page changes
  useEffect(() => {
    fetchFnfRequests();
  }, [search, page]);

  // Handle search input
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to page 1 when search changes
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  if(viewFNF){
    return (
      <Box>
          <Flex justifyContent={'space-between'}>
            <IconButton icon={<ArrowBackIcon />} aria-label="Search" mr={2} onClick={()=>{setViewFNF(false)}} />
            {/* <Button>View</Button> */}
          </Flex>
          <Box className='mt-4'>
            <ViewFNFRequest request={selectedReq} />
          </Box>
      </Box>
    )
  }

  return (
    <Box width="100%" p={4}>
      <Flex justify="space-between" align="center" mb={4}>
        <Box fontSize="xl" fontWeight="bold">
          FNF Requests
        </Box>
        <Flex align="center">
          <Input
            placeholder="Search..."
            value={search}
            onChange={handleSearchChange}
            w="200px"
            mr={2}
          />
          <IconButton icon={<SearchIcon />} aria-label="Search" mr={2} onClick={fetchFnfRequests} />
          <CreateFNFRequest />
          <Button leftIcon={<DownloadIcon />} colorScheme="gray">
            Download CSV
          </Button>
        </Flex>
      </Flex>

      <Table className="w-full text-sm text-left text-gray-500">
        <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <Tr>
            <Th>Name</Th>
            <Th>Employee Code</Th>
            <Th>Gender</Th>
            <Th>Designation</Th>
            <Th>Mobile Number</Th>
            <Th>Resignation Date</Th>
            <Th>Last Working Date</Th>
          </Tr>
        </Thead>
        <Tbody>
          {!loading && requests.length > 0 ? (
            requests.map((request) => (
              <Tr key={request._id} className="bg-white border-b hover:bg-gray-50 cursor-pointer" onClick={()=>{setViewFNF(true); setSelectedReq(request)}}> 
                <Td>
                  <Flex align="center">
                    <Avatar name={request.employeeId.name} mr={3} />
                    <Box>
                      <Text fontWeight="bold">{request.employeeId.name}</Text>
                      <Text fontSize="sm" color="gray.500">{request.employeeId.email}</Text>
                    </Box>
                  </Flex>
                </Td>
                <Td>{request.employeeId.employeeCode}</Td>
                <Td>{request.employeeId.gender}</Td>
                <Td>{request.employeeId.designation}</Td>
                <Td>{request.employeeId.mobile}</Td>
                <Td>{formatDateTime(request.resignationDate, 'date')}</Td>
                <Td>{formatDateTime(request.lastWorkingDate, 'date')}</Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="7" textAlign="center">
                {loading ? 'Loading...' : 'No records found.'}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>

      {/* Pagination Controls */}
      <Flex justify="space-between" align="center" mt={4}>
        <Text>Page {page} of {totalPages}</Text>
        <Flex>
          <IconButton
            icon={<ChevronLeftIcon />}
            aria-label="Previous Page"
            onClick={() => handlePageChange(page - 1)}
            isDisabled={page === 1}
          />
          <IconButton
            icon={<ChevronRightIcon />}
            aria-label="Next Page"
            onClick={() => handlePageChange(page + 1)}
            isDisabled={page === totalPages}
            ml={2}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default Fnf;

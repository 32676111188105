import React from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

const HrExpensesAssetsOverview = ({ data, assets }) => {
  console.log("data---------------->", data);
  
  const { t, i18n } = useTranslation();
  // Pie chart data and configuration for HR Expenses
  const expensesChartOptions = {
    chart: {
      type: 'pie',
    },
    labels: data?.map(item => item.expenseType),
    colors: ['#FF7F50', '#87CEEB', '#FF69B4', '#FFD700', '#8A2BE2', '#32CD32', '#DC143C'],
    legend: {
      position: 'bottom',
    },
  };

  // const expensesChartData = [
  //   { expenseType: 'Salaries', totalExpenditure: 5000 },
  //   { expenseType: 'Maintenance', totalExpenditure: 3000 },
  //   { expenseType: 'Training', totalExpenditure: 1500 },
  //   { expenseType: 'Equipment', totalExpenditure: 2000 },
  //   { expenseType: 'Utilities', totalExpenditure: 1000 },
  //   { expenseType: 'Travel', totalExpenditure: 2500 },
  //   { expenseType: 'Miscellaneous', totalExpenditure: 800 }
  // ];

  const expensesChartSeries = data?.map(item => item?.totalExpenditure);// Sample data for expenses

  // Asset status data (replace with actual data)
  const assetStatusData = {
    available: assets.status?.find(item => item.status === 'available')?.totalAssets || 0,
    assigned: assets.status?.find(item => item.status === 'assigned')?.totalAssets || 0,
    underMaintenance: assets.status?.find(item => item.status === 'underMaintenance')?.totalAssets || 0,
    returned: assets.status?.find(item => item.status === 'returned')?.totalAssets || 0,
  };

  // Asset condition data (replace with actual data)
  const assetConditionData = {
    excellent: assets.condition?.find(item => item.condition === 'excellent')?.totalAssets || 0,
    good: assets.condition?.find(item => item.condition === 'good')?.totalAssets || 0,
    fair: assets.condition?.find(item => item.condition === 'fair')?.totalAssets || 0,
    poor: assets.condition?.find(item => item.condition === 'poor')?.totalAssets || 0,
    bad: assets.condition?.find(item => item.condition === 'bad')?.totalAssets || 0,
  };

  // if (!data || !assets) return <div>Loading...</div>

  return (
    <div className="p-6 bg-gray-100">
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Expenses Summary Pie Chart */}
        <div className="flex-1 bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-lg font-semibold mb-4">{t('categories-income')}</h3>
          <Chart options={expensesChartOptions} series={expensesChartSeries} type="pie" width="100%" />
        </div>

        {/* Asset Status and Condition Summary */}
        <div className="flex-1 bg-white p-6 rounded-lg shadow-lg">
          <div className='flex justify-between items-center w-full'>
            <h3 className="text-lg font-semibold mb-4">{t('assets-overview')}</h3>
            <h3 className="text-lg font-semibold mb-4">{t("total-assets")}: {assets?.condition?.reduce((sum, value) => sum + value?.totalAssets, 0)}</h3>
          </div>

          {/* Asset Status Summary */}
          <div className="grid grid-cols-2 gap-4 mb-6">
            {/* Available */}
            <div className="bg-green-100 text-green-800 p-4 rounded-lg shadow-md flex flex-col items-center">
              <span className="text-2xl font-bold">{assetStatusData.available}</span>
              <span className="text-sm font-medium">{t('available')}</span>
            </div>

            {/* Assigned */}
            <div className="bg-blue-100 text-blue-800 p-4 rounded-lg shadow-md flex flex-col items-center">
              <span className="text-2xl font-bold">{assetStatusData.assigned}</span>
              <span className="text-sm font-medium">{t("assigned")}</span>
            </div>

            {/* Under Maintenance */}
            <div className="bg-yellow-100 text-yellow-800 p-4 rounded-lg shadow-md flex flex-col items-center">
              <span className="text-2xl font-bold">{assetStatusData.underMaintenance}</span>
              <span className="text-sm font-medium">{t("under-maintanance")}</span>
            </div>

            {/* Returned */}
            <div className="bg-gray-100 text-gray-800 p-4 rounded-lg shadow-md flex flex-col items-center">
              <span className="text-2xl font-bold">{assetStatusData.returned}</span>
              <span className="text-sm font-medium">{t("returned")}</span>
            </div>
          </div>

          {/* Asset Condition Summary */}
          <h4 className="text-md font-semibold mb-4">{t("asset-conditions")}</h4>
          <div className="grid grid-cols-3 gap-4">
            {/* Excellent */}
            <div className="bg-green-200 text-green-800 p-4 rounded-lg shadow-md flex flex-col items-center">
              <span className="text-2xl font-bold">{assetConditionData.excellent}</span>
              <span className="text-sm font-medium">{t("excellent")}</span>
            </div>

            {/* Good */}
            <div className="bg-blue-200 text-blue-800 p-4 rounded-lg shadow-md flex flex-col items-center">
              <span className="text-2xl font-bold">{assetConditionData.good}</span>
              <span className="text-sm font-medium">{t("good")}</span>
            </div>

            {/* Fair */}
            <div className="bg-yellow-200 text-yellow-800 p-4 rounded-lg shadow-md flex flex-col items-center">
              <span className="text-2xl font-bold">{assetConditionData.fair}</span>
              <span className="text-sm font-medium">{t("fair")}</span>
            </div>

            {/* Poor */}
            <div className="bg-orange-200 text-orange-800 p-4 rounded-lg shadow-md flex flex-col items-center">
              <span className="text-2xl font-bold">{assetConditionData.poor}</span>
              <span className="text-sm font-medium">{t("poor")}</span>
            </div>

            {/* Bad */}
            <div className="bg-red-200 text-red-800 p-4 rounded-lg shadow-md flex flex-col items-center">
              <span className="text-2xl font-bold">{assetConditionData.bad}</span>
              <span className="text-sm font-medium">{t("bad")}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HrExpensesAssetsOverview;

import React,{ useState, useEffect} from "react";
import { Box, Image, Text, Badge, Flex, Button } from "@chakra-ui/react";
import CreateProductInWarehouse from "../dialogueBoxes/CreateProductInWarehouse";
import DeleteProduct from "../dialogueBoxes/DeleteWarehouseProduct";
import { get } from '../../services/apis/api';

const ProductCard = ({ product, warehouse, getWarehouseProducts }) => {
    return (
        <Box
            className="shadow-lg rounded-lg p-4 bg-white"
            maxW="sm"
            width="250px"
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
        >
            <Box position="relative" height="200px" overflow="hidden" mb={4}>
                <Image
                    src={product?.productId?.images[0]}
                    alt={product?.productId?.name}
                    width="100%"
                    height="100%"
                    objectFit="cover"
                />
                <Box position="absolute" top="2" right="2">
                    <DeleteProduct
                        product={product}
                        getWarehouseProducts={getWarehouseProducts}
                    />
                </Box>
            </Box>

            <Text fontWeight="medium" fontSize="xl" mb={2}>
                {product?.productId?.name}
            </Text>

            <Flex justify="space-between" align="center" mb={2}>
                <Text className="text-base font-normal">
                    SAR {product?.price}
                </Text>
                <CreateProductInWarehouse
                    warehouse={warehouse}
                    product={product}
                    isEdit={true}
                    getWarehouseProducts={getWarehouseProducts}
                />
            </Flex>

            <Flex wrap="wrap" gap={2}>
                {product?.sizes?.map((size, index) => (
                    <Badge
                        key={index}
                        borderRadius="full"
                        px={2}
                        py={1}
                        className="bg-gray-200 text-gray-700"
                    >
                        {size.size} - {size.quantity}
                    </Badge>
                ))}
            </Flex>
        </Box>
    );
    
};

const ViewWarehouse = ({ warehouse, setShow }) => {
  const [products, setProducts] = useState([]);

  const getWarehouseProducts = async () => {
    try {
      const res = await get(`warehouse/products/${warehouse._id}`);
      console.log('API Response:', res); // Log the response for debugging
      if (res.statusCode === 200) {        
        setProducts(res.data.products);
      } else {
        console.error('Failed to fetch products:', res);
      }
    } catch (error) {
      console.error('Error fetching warehouse products:', error);
    }
  };

  useEffect(() => {
    getWarehouseProducts();
  }, []);

  return (
    <div>
      <div className="py-4 flex justify-between">
        <button
          onClick={() => {
            setShow(false);
          }}
          className="bg-[#290dbd] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md"
        >
          Back
        </button>
        <CreateProductInWarehouse warehouse={warehouse} isEdit={false} getWarehouseProducts={getWarehouseProducts}/>
      </div>

      <div className="grid gap-4 mx-auto">
        <Flex wrap="wrap" justify="start" gap={2}>
          {products.length > 0 ? (
            products.map((product) => (
              <ProductCard key={product._id} product={product} warehouse={warehouse} getWarehouseProducts={getWarehouseProducts}/>
            ))
          ) : (
            <div className='flex justify-center items-center w-full h-60'>
              <Text>No products available</Text>
            </div>
          )}
        </Flex>
      </div>
    </div>
  );
};
export default ViewWarehouse;

import React, { useState } from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { FaCheckCircle } from 'react-icons/fa'

const ShowInjuryImg = ({
    isOpen,
    onClose,
    imgUrl
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const cancelRef = React.useRef()


    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t('view-injury')}
                        </AlertDialogHeader>

                        <AlertDialogBody py={4}>
                            <div className='w-full flex items-center justify-center h-80 overflow-hidden'>
                                <img
                                    className='h-full'
                                    style={{ clipPath: 'inset(0px 0px 40px 0px)' }}
                                    src={imgUrl}
                                    alt="injuryBodyImage"
                                />
                            </div>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                {t('close')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default ShowInjuryImg
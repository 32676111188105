import React from 'react';

import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import ComponentAssets from '../../components/hr/components/assets/ComponentAssets';
import AssetsAssignment from '../../components/hr/components/assets/AssetsAssignment';

const Assets = () => {
    

    return (
        <div>
            <Tabs size='md'>
                <TabList>
                    <Tab>Company Assets</Tab>
                    <Tab>Asset Assignment</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <ComponentAssets />
                    </TabPanel>
                    <TabPanel>
                        <AssetsAssignment />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </div>
    );
};

export default Assets;

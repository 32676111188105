import React from 'react'
import { useLocation, Link } from 'react-router-dom';
import { FaAngleRight } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';
import { FaAngleLeft } from 'react-icons/fa';

const BreadCrumbs = () => {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    // Split the path into segments (exclude the leading empty string)
    const pathSegments = location.pathname.split('/').filter(segment => segment);

    // Function to check if a segment is an ID (numeric or UUID-like)
    const isId = (segment) => /^[0-9a-fA-F]{24}$/.test(segment) || /^\d+$/.test(segment);

    return (
        <div className={`w-full p-2 px-6 flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} items-center gap-1 rounded-full bg-[#fcfcfd]`}>
            {pathSegments.map((segment, index) => {
                const linkTo = `/${pathSegments.slice(0, index + 1).join('/')}`;
                const isLast = index === pathSegments.length - 1;

                // If the segment is an ID, don't translate it
                const displayText = isId(segment) ? segment : t(segment?.toLowerCase());

                return (
                    <React.Fragment key={index}>
                        {isArabic
                            ? index > 0 && <FaAngleLeft fontSize={12} color="#69727e" />
                            : index > 0 && <FaAngleRight fontSize={12} color="#69727e" />
                        }

                        <Link
                            className={`capitalize ${isLast ? 'text-[#746dea]' : 'text-[#4b5563]'} hover:underline`}
                            to={isLast ? '#' : linkTo} // Disable link for the last breadcrumb
                        >
                            {displayText}
                        </Link>
                    </React.Fragment>
                );
            })}
        </div>
    );
}

export default BreadCrumbs;




// import React from 'react'
// import { useLocation, Link } from 'react-router-dom';
// import { FaAngleRight } from "react-icons/fa6";
// import { useTranslation } from 'react-i18next';
// import { FaAngleLeft } from 'react-icons/fa';

// const BreadCrumbs = () => {
//     const location = useLocation();
//     const { t, i18n } = useTranslation();
//     const isArabic = i18n.language === 'ar';

//     // Split the path into segments (exclude the leading empty string)
//     const pathSegments = location.pathname.split('/').filter(segment => segment);

//     return (
//         <div className={`w-full p-2 px-6 flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} items-center gap-1 rounded-full bg-[#fcfcfd]`}>
//             {pathSegments.map((segment, index) => {
//                 // Construct the URL for each breadcrumb (all segments up to the current one)
//                 const linkTo = `/${pathSegments.slice(0, index + 1).join('/')}`;

//                 // Format the segment by replacing dashes with spaces and capitalizing the first letter of each word
//                 const formattedSegment = segment.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase());

//                 // Check if the current segment is the last one in the path
//                 const isLast = index === pathSegments.length - 1;

//                 return (
//                     <React.Fragment key={index}>
//                         {/* Show the arrow separator */}
//                         {isArabic
//                             ? index > 0
//                                 ? <FaAngleLeft fontSize={12} color="#69727e" />
//                                 : null
//                             : index > 0
//                                 ? <FaAngleRight fontSize={12} color="#69727e" />
//                                 : null
//                         }

//                         {/* Breadcrumb link or text */}
//                         <Link
//                             className={`capitalize ${isLast ? 'text-[#746dea]' : 'text-[#4b5563]'} hover:underline`}
//                             to={isLast ? '#' : linkTo} // Disable the link for the last breadcrumb
//                         >
//                             {t(segment?.toLocaleLowerCase())}
//                         </Link>
//                     </React.Fragment>
//                 );
//             })}
//         </div>
//     );
// }

// export default BreadCrumbs;


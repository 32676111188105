import React, { useEffect, useState } from "react";
import { formatDateTime } from "../../utills/formateDate";
import { get, patch } from "../../services/apis/api";
import ViewAwards from "../../components/mediaCenter/ViewAwards";
import CreateNotice from "../../components/dialogueBoxes/CreateNotice";
import { Tooltip } from "@chakra-ui/react";

const Notices = () => {
  const [notices, setNotices] = useState([]);
  const [filteredNotices, setFilteredNotices] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [viewAward, setViewAward] = useState(false);
  const [selectedAward, setSelectedAward] = useState({});

  const getAllNotices = async () => {
    try {
      const res = await get("notice/all");
      console.log(res);
      setNotices(res.data.notice);
      setFilteredNotices(res.data.notice); // Initialize filtered notices with all notices
    } catch (error) {
      console.error("Error fetching notices:", error);
    }
  };

  useEffect(() => {
    getAllNotices();
  }, []);

  const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return text.substring(0, length) + "...";
  };

  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchInput(searchTerm);
    const filteredList = notices.filter(
      (notice) =>
        notice.title.toLowerCase().includes(searchTerm) ||
        notice.content.toLowerCase().includes(searchTerm)
    );
    setFilteredNotices(filteredList);
  };

  return (
    <div>
      <div className="flex justify-between px-2 items-center mb-4">
        <div>
          <div className="relative">
            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="table-search-users"
              className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
              placeholder="Search for notices"
              value={searchInput}
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
        <div>
          <CreateNotice getAllNotices={getAllNotices} />
        </div>
      </div>
      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                <th scope="col" className="p-4">
                  <div className="flex items-center">S.No.</div>
                </th>
                <th scope="col" className="px-6 py-3">
                  Notice Title
                </th>
                <th scope="col" className="px-6 py-3">
                  Notice Message
                </th>
                <th scope="col" className="px-6 py-3">
                  Created Date/Time
                </th>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Expiration Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Action Required
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredNotices?.map((notice, index) => (
                <tr
                  className="bg-white border-b hover:bg-gray-50 "
                  key={notice._id}
                >
                  <td className="w-4 p-4">
                    <div className="flex items-center">{index + 1}</div>
                  </td>
                  <th
                    scope="row"
                    className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap "
                  >
                    <div className="text-base font-semibold">{notice.title}</div>
                  </th>
                  <td className="px-6 py-4">
                    <Tooltip label={notice.content} fontSize="md">
                      <span>{truncateText(notice.content, 50)}</span>
                    </Tooltip>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      {formatDateTime(notice.createdAt, "date")}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-base font-semibold">{notice.type}</div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      {formatDateTime(notice.validTill, "date")}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <button className="text-[#ffffff] bg-[#290dbd] font-semibold py-2 px-4 rounded-md">
                        Edit
                      </button>
                      <button className="text-[#ffffff] bg-red-700 ml-2 font-semibold py-2 px-4 rounded-md">
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Notices;

import React, { useEffect, useState } from "react";
import CreateGame from "../../components/dialogueBoxes/CreateGame";
import { get } from "../../services/apis/api";
import _ from "lodash";
import {
    Tooltip,
    Button,
    Spinner,
    Box,
    Heading,
    Text,
    useToast,
} from "@chakra-ui/react";
import { truncateText } from "../../utills/truncateText";
import { useTranslation } from "react-i18next";
import CreateAlRwaadService from "../../components/dialogueBoxes/CreateAlRwaadService";
import DeleteAlRwaadService from "../../components/dialogueBoxes/DeleteAlRwaadService";
import ViewAlRwaadUser from "../../components/operations/ViewAlRwaadUsers";

const AlRwaad = () => {
    const [alRwaadServices, setAlRwaadServices] = useState([]);
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const [showAlRwaadUsers, setShowAlRwaadUsers] = useState(false);
    const toast = useToast();

    const getAlRwaadServices = async (searchQuery = "") => {
        setIsLoading(true);
        try {
            const res = await get(
                `al-rwaad/all${searchQuery ? `?search=${searchQuery}` : ""}`
            );
            setAlRwaadServices(res.data.alRwaadServices);
        } catch (error) {
            console.error(error);
            toast({
                title: t("error"),
                description: t("failed-to-fetch-services"),
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAlRwaadServices();
    }, []);

    // Debounced search function
    const debouncedSearch = _.debounce((query) => {
        getAlRwaadServices(query);
    }, 300);

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearch(query);
        debouncedSearch(query);
    };

    return (
        <Box className="p-6">
            {!showAlRwaadUsers ? (
                <div>
                    <Box mb={6}>
                        <Heading size="lg" mb={4}>
                            {t("VIP Services")}
                        </Heading>
                        <div
                            className={`flex flex-col md:flex-row gap-4 ${
                                isArabic ? "md:flex-row-reverse" : ""
                            } justify-between items-center`}
                        >
                            <div className="w-full md:w-auto">
                                <div className="relative">
                                    <div
                                        className={`absolute inset-y-0 ${
                                            isArabic
                                                ? "start-auto end-0 pe-3"
                                                : "start-0 ps-3"
                                        } flex items-center pointer-events-none`}
                                    >
                                        <svg
                                            className="w-4 h-4 text-gray-500"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                            />
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        className={`block w-full p-2 ${
                                            isArabic
                                                ? "pe-10 text-right"
                                                : "ps-10 text-left"
                                        } text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out`}
                                        placeholder={t(
                                            "search-for-al-rwaad-services"
                                        )}
                                        value={search}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>

                            <div className="flex gap-3">
                                <ViewAlRwaadUser
                                    showAlRwaadUsers={showAlRwaadUsers}
                                    setShowAlRwaadUsers={setShowAlRwaadUsers}
                                />
                                <CreateAlRwaadService
                                    getAlRwaadServices={getAlRwaadServices}
                                    isEdit={false}
                                />
                            </div>
                        </div>
                    </Box>

                    <div
                        className="relative overflow-hidden shadow-md sm:rounded-lg bg-white"
                        dir={isArabic ? "rtl" : "ltr"}
                    >
                        {isLoading ? (
                            <div className="flex justify-center items-center h-64">
                                <Spinner size="xl" color="blue.500" />
                            </div>
                        ) : alRwaadServices.length === 0 ? (
                            <div className="flex flex-col justify-center items-center h-64 text-center">
                                <Box className="text-gray-500 mb-4">
                                    <svg
                                        className="w-16 h-16 mx-auto mb-4"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                                        />
                                    </svg>
                                    <Text fontSize="lg" fontWeight="medium">
                                        {t("no-services-found")}
                                    </Text>
                                    <Text color="gray.500" mt={2}>
                                        {t("try-different-search")}
                                    </Text>
                                </Box>
                            </div>
                        ) : (
                            <div className="overflow-x-auto">
                                <table className="w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-16 py-4 text-start text-xs font-semibold text-gray-600 uppercase tracking-wider w-48"
                                            >
                                                {t("image")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-4 text-start text-xs font-semibold text-gray-600 uppercase tracking-wider"
                                            >
                                                {t("name")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-4 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider w-32"
                                            >
                                                {t("action")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-4 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider w-32"
                                            >
                                                {t("delete")}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {alRwaadServices.map(
                                            (alRwaadService, index) => (
                                                <tr
                                                    key={index}
                                                    className="hover:bg-gray-50 transition-colors duration-200"
                                                >
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="flex justify-center">
                                                            <div className="w-20 h-20 flex-shrink-0">
                                                                <img
                                                                    src={
                                                                        alRwaadService.icon
                                                                    }
                                                                    className="w-full h-full rounded-lg object-cover shadow-sm"
                                                                    alt={
                                                                        !isArabic
                                                                            ? alRwaadService.name
                                                                            : alRwaadService.arabicName
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="flex flex-col">
                                                            <Text className="text-sm font-medium text-gray-900">
                                                                {!isArabic
                                                                    ? alRwaadService.name
                                                                    : alRwaadService.arabicName}
                                                            </Text>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-center">
                                                        <div className="flex justify-center">
                                                            <CreateAlRwaadService
                                                                getAlRwaadServices={
                                                                    getAlRwaadServices
                                                                }
                                                                AlRwaadService={
                                                                    alRwaadService
                                                                }
                                                                isEdit={true}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-center">
                                                        <div className="flex justify-center">
                                                            <DeleteAlRwaadService
                                                                alRwaadService={
                                                                    alRwaadService
                                                                }
                                                                getAlRwaadServices={
                                                                    getAlRwaadServices
                                                                }
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <ViewAlRwaadUser
                    showAlRwaadUsers={showAlRwaadUsers}
                    setShowAlRwaadUsers={setShowAlRwaadUsers}
                />
            )}
        </Box>
    );
};

export default AlRwaad;

import { Spinner, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { get, patch } from '../../services/apis/api';
import { IoMdArrowRoundBack } from 'react-icons/io';
import CustomAlert from '../alerts/CustomAlert';
import { formatDateTime } from '../../utills/formateDate';
import { useTranslation } from 'react-i18next';

const statusRoleMapping = {
    'HR-MANAGER-REVIEW': 'APPROVED',
    // Add other status-to-role mappings as needed
};

const roleToTypeAndStatus = {
    'HR-MANAGER': 'HR-MANAGER-REVIEW',
    // Add other role-to-type-and-status mappings as needed
};

const ViewAdministratorHireRequest = ({ requestId, setView, user, getAllRequest }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const [requestAction, setRequestAction] = useState(false);
    const [request, setRequest] = useState({});
    const [canApprove, setCanApprove] = useState(false);
    const toast = useToast();

    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const getRequestDetails = async () => {
        const res = await get(`request/get/${requestId}`);
        if (res.statusCode === 200) {
            setRequest(res.data.request);
            const userCanApprove = roleToTypeAndStatus[user?.role] === res.data.request.status;
            setCanApprove(userCanApprove);
        }
    };

    useEffect(() => {
        getRequestDetails();
    }, []);

    const handleApprove = async () => {
        const nextStatus = statusRoleMapping[request.status];
        if (!nextStatus) {
            toast({
                title: t("error"),
                description: t("cannot-approve-request"),
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        setLoading(true);
        const res = await patch(`request/update/administrator-hire/${requestId}`, { status: nextStatus });
        setLoading(false);
        if (res.statusCode === 200) {
            getRequestDetails();
        } else {
            toast({
                title: t("error"),
                description: t("failed-to-approve-request"),
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const confirmApprove = () => {
        handleApprove();
        onClose();
    };

    const handleReject = async () => {
        try {
            setLoading(true);
            const res = await patch(`request/update/administrator-hire/${requestId}`, { status: "REJECTED", rejectedBy: user._id });
            if (res.statusCode === 200) {
                setLoading(false);
                getRequestDetails();
                onClose();
                toast({
                    title: t("success"),
                    description: t("request-rejected"),
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                onClose();
                toast({
                    title: t("error"),
                    description: t("failed-to-reject-request"),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: t("error"),
                description: error.response.data.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleApproveRejectRequest = (type) => {
        if (type === "APPROVED") {
            setRequestAction(true);
            onOpen();
        } else {
            setRequestAction(false);
            onOpen();
        }
    };

    return (
        <div>
            <div className='w-full p-4 flex justify-between'>
                <button onClick={() => setView(false)} className='bg-[#290dbd] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md flex items-center'>
                    <IoMdArrowRoundBack /> {t('back')}
                </button>
                <div className='flex'>
                    {canApprove ? (
                        <div>
                            <button onClick={() => handleApproveRejectRequest('REJECTED')} className='bg-[#e32c35] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md me-2'>
                                {loading ? <Spinner /> : t("reject-request")}
                            </button>
                            <button onClick={() => handleApproveRejectRequest('APPROVED')} className='bg-[#290dbd] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md'>
                                {loading ? <Spinner /> : t("approve-request")}
                            </button>
                        </div>
                    ) : ""}
                </div>
            </div>
            <div className='ps-2' dir={isArabic ? 'rtl' : 'ltr'}>
                <div className={`container mb-2 ${isArabic ? "text-right" : "text-left"}`}>
                    <h2 className='font-bold text-xl text-slate-950'>{t('request-details')}</h2>
                </div>
                <div>
                    <div className='flex flex-wrap mb-5'>
                        {/* Use conditional text alignment based on language */}
                        <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-start items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>
                                {t('request-by')} - <span className='text-[#485060] px-4'>{request?.requestedBy?.name}</span>
                            </h2>
                        </div>
                        <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-start items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>
                                {t('request-status')} - 
                                <span className={request?.status === "APPROVED" ? 'text-green-600' : request?.status === "REJECTED" ? 'text-red-600' : 'text-[#f99d4dfd]'}>
                                    {request?.status}
                                </span>
                            </h2>
                        </div>
                        <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-start items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>
                                {t('request-type')} - <span className='text-[#485060] px-4'>{request?.kind}</span>
                            </h2>
                        </div>
                        <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-start items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>
                                {t('budget')} - <span className='text-[#485060] px-4'>{request?.budget}</span>
                            </h2>
                        </div>
                        <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-start items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>
                                {t('experience')} - <span className='text-[#485060] px-4'>{request?.experience}</span>
                            </h2>
                        </div>
                        <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-start items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>
                                {t('start-date')} - <span className='text-[#485060] px-4'>{formatDateTime(request?.startDate, "date")}</span>
                            </h2>
                        </div>
                    </div>
                    <div className={`flex mb-3 flex-col items-start`}>
                        <h2 className='font-bold text-md text-black font-[outfit]'>{t('specialization')} - </h2>
                        <p className='font-[outfit]'>{request?.specialization}</p>
                    </div>
                    <div  className={`flex mb-3 flex-col items-start`}>
                        <h2 className='font-bold text-md text-black font-[outfit]'>{t('description')} - </h2>
                        <p className='font-[outfit]'>{request?.description}</p>
                    </div>
                </div>
            </div>
            {
                requestAction ? 
                    <CustomAlert
                        isOpen={isOpen}
                        onClose={onClose}
                        title={t("approve-request")}
                        description={t("confirm-approve-request")}
                        onConfirm={confirmApprove}
                        confirmButtonText={t("confirm")}
                        cancelButtonText={t("cancel")}
                    /> 
                    : 
                    <CustomAlert
                        isOpen={isOpen}
                        onClose={onClose}
                        title={t("reject-request")}
                        description={t("confirm-reject-request")}
                        onConfirm={handleReject}
                        confirmButtonText={t("confirm")}
                        cancelButtonText={t("cancel")}
                    />
            }
        </div>
    );
};

export default ViewAdministratorHireRequest;

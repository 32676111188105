import { Button, Select } from '@chakra-ui/react'
import React, { useState } from 'react'
import SelectYearComponent from '../SelectYearComponent'
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../../services/glocalFunctions';
import { DownloadIcon } from '@chakra-ui/icons';
import ShowTotalValue from '../ShoTotalValue';
import ShowName from '../ShowName';
import ConfirmDownload from '../../dialogueBoxes/ConfirmDownload';

const BasicDataComponent = ({
    basicData,
    onAddOpen,
    selectedYear,
    setSelectedYear,
    isDesc,
    isExpense,
    totalAmount,
    onDownloadCSV,
    loading
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const currentYear = new Date().getFullYear(); // Get the current year
    const years = Array.from({ length: currentYear - 2000 + 1 }, (_, i) => 2000 + i); // Generate years from 2000 to current year
    const [downloadOpen, setDownloadOpen] = useState(false);  // for confirmation dialog

    const _name = isArabic
        ? basicData?.arabicName
            ? basicData?.arabicName
            : basicData?.name || basicData?.source
        : basicData?.source || basicData?.name;

    // CONFIRM DOWNLOAD FUNCTION 
    const confirmDownload = () => {
        try {
            if (onDownloadCSV) {
                onDownloadCSV()
            }
            setDownloadOpen(false);
        } catch (e) {
            console.log(`ERROR WHILE GENERATING PDF ${e}`);
        }
    };

    return (
        <>
            <div className={`flex  flex-col ${isArabic ? 'items-end' : 'items-start'} gap-2`}>
                {/* SHOW NAME HEADING  */}
                <ShowName
                    title="name"
                    value={_name}
                />
                {/* SHOW TOTAL VALUE  */}
                <ShowTotalValue
                    title={isExpense ? "total-expenses" : "total-earnings"}
                    value={totalAmount || basicData?.totalAmountGenerated || 0}
                />
                {/* <p className='text-sm w-full lg:w-10/12 lg:text-base whitespace-nowrap text-gray-600'>Income Type : offline </p> */}
                {isDesc ?
                    <p className={`text-sm w-full lg:w-10/12 lg:text-base ${isArabic ? 'text-right' : 'text-left'}`}>{basicData?.description || '--'} </p>
                    : null}
            </div>
            <div className={`flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} items-center gap-2`}>
                <SelectYearComponent
                    selectedYear={selectedYear}
                    setSelectedYear={setSelectedYear}
                />
                {/* Download Confirmation Dialog */}
                <ConfirmDownload
                    isOpen={downloadOpen}
                    label='download-csv'
                    setIsOpen={setDownloadOpen}
                    handleDownload={() => {
                        setDownloadOpen(true);
                    }}
                    confirmDownload={() => {
                        confirmDownload();
                    }}
                    closeDialog={() => {
                        setDownloadOpen(false);
                    }}
                    loading={loading}
                />
                <Button
                    variant="solid"
                    paddingX={6}
                    colorScheme='blue'
                    onClick={onAddOpen}
                >
                    {t('add-new')}
                </Button>
            </div>
        </>
    )
}

export default BasicDataComponent

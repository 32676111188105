import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, FormControl, FormLabel, IconButton, InputGroup, Select, Tooltip, useToast } from '@chakra-ui/react';
import { MdFilterAlt } from "react-icons/md";
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    //   DrawerTitle,
    DrawerTrigger,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { FaCalendarAlt } from "react-icons/fa";
import DateRangeSelect from '../../account/DateRangeSelect';
import { _hrExpenseCategories, allExpenseStatus, allPaymentMethod } from '../../../services/glocalFunctions';

// ALL ROLES 
// const allExpenseStatus = [
//     { value: 'coach', label: 'coach' },
//     { value: 'external user', label: 'users' },
//     { value: 'internal user', label: 'player' },
//     { value: 'guardian', label: 'guardian' },
//     { value: 'super-admin', label: 'super-admin' },
//     { value: 'employee', label: 'employee' },
//     { value: 'organizer', label: 'organizer' },
//     { value: 'alrwaad user', label: 'alrwaad-user' },
//     { value: 'hr-manager', label: 'hr-manager' },
//     { value: 'account-manager', label: 'account-manager' },
//     { value: 'online-store-manager', label: 'online-store-manager' },
//     { value: 'media-center-manager', label: 'media-center-manager' },
//     { value: 'operation-manager', label: 'operation-manager' },
//     { value: 'game-manager', label: 'game-manager' },
//     { value: 'alrwaad-manager', label: 'alrwaad-manager' },
//     { value: 'clinic-manager', label: 'clinic-manager' },
//     { value: 'hr-secretory', label: 'hr-secretory' },
//     { value: 'account-secretory', label: 'account-secretory' },
//     { value: 'online-store-secretory', label: 'online-store-secretory' },
//     { value: 'media-center-secretory', label: 'media-center-secretory' },
//     { value: 'operation-secretory', label: 'operation-secretory' },
//     { value: 'game-secretory', label: 'game-secretory' },
//     { value: 'hr-assistant', label: 'hr-assistant' },
//     { value: 'account-assistant', label: 'account-assistant' },
//     { value: 'online-store-assistant', label: 'online-store-assistant' },
//     { value: 'media-center-assistant', label: 'media-center-assistant' },
//     { value: 'operation-assistant', label: 'operation-assistant' },
//     { value: 'game-assistant', label: 'game-assistant' },
// ];



// DEFAULT DATES 
const _defaultDates = {
    startDate: '',
    endDate: ''
}

const ExpenseFilter = ({
    onApplyFilter,
    filterData,
    setFilterData,
    onResetFilter,
    appliedFilterCount
}) => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [date, setDate] = useState({ ..._defaultDates });
    const datePickerRef = useRef(null);  // Ref for DatePicker component
    const toast = useToast();

    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);


    // HANDLE DATE CHANGE FUNCTION 
    const handleDateChange = (date) => {
        try {
            if (date instanceof Date && !isNaN(date)) {
                const currentDate = new Date(); // Get the current date
                const selectedMonth = date.getMonth(); // Get the selected month
                const selectedYear = date.getFullYear(); // Get the selected year

                const currentMonth = currentDate.getMonth(); // Get the current month
                const currentYear = currentDate.getFullYear(); // Get the current year

                // Check if the selected date is in the current or future month
                if (
                    selectedYear > currentYear ||
                    (selectedYear === currentYear && selectedMonth >= currentMonth)
                ) {
                    toast({
                        title: "Warning",
                        description: "You cannot select the current or a future month.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    setFilterData((prevData) => ({
                        ...prevData,
                        attendanceDate: {
                            ...prevData.attendanceDate,
                            startDate: new Date(selectedYear, selectedMonth, 1),
                            month: selectedMonth + 1, // Convert 0-based to 1-based
                            year: selectedYear,
                        },
                    }));
                }
            } else {
                console.log("Invalid date selected");
            }
        } catch (e) {
            console.log(`ERROR WHILE SETTING DATE ${e}`);
        }
    };

    const handleChangeData = (field, value) => {
        try {
            setFilterData((prevData) => ({
                ...prevData,
                [field]: value
            }))
        } catch (e) {
            console.log(`ERROR WHILE CHANGING FILTER DATA ${e}`);
        }
    }

    // HADLE SELEC USERS FUNCTION 
    const handleSelectChange = (field, selectedOptions) => {
        try {
            setFilterData((prevData) => ({
                ...prevData,
                [field]: selectedOptions?.map((option) => option?.value),
            }));
        } catch (e) {
            console.log(`ERROR WHILE SELECING USERS ${e}`);
        }
    };

    // UPDATE FILTERDATA ANNOUNCEMENT DATE RANGE ON DATE CHANGE 
    useEffect(() => {
        setFilterData((prevData) => ({
            ...prevData,
            expenseDate: {
                start: date?.startDate,
                end: date?.endDate
            }
        }));
    }, [date, setFilterData]);

    // HEADING FIELD 
    const HeadingField = ({ title }) => (
        <h1 className='text-sm lg:text-base font-semibold'>{t(title)}</h1>
    )

    // CUSTOM OPTIONS FOR REACT SELECT  
    const CustomOption = (props) => {
        const { data, innerRef, innerProps } = props;
        return (
            <div ref={innerRef} {...innerProps} className="custom-option">
                {/* Customize the option here */}
                <span style={{ fontWeight: 'bold' }}>{data.label}</span>
            </div>
        );
    };

    return (
        <>
            <Badge
                display="flex"
                alignItems="center"
                gap={1}
                px={2}
                borderRadius="md"
                onClick={onOpen}
            >
                {/* Display the applied filter count */}
                {appliedFilterCount}

                {/* Tooltip and IconButton for the filter icon */}
                <Tooltip
                    label={t('expense-filter')}
                >
                    <IconButton
                        onClick={onOpen}
                    >
                        <MdFilterAlt />
                    </IconButton>
                </Tooltip >
            </Badge>

            <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader
                            padding={3}
                            borderBottom="1px solid #E1E1E1"
                        >
                            <h1>{t('expense-filter')}</h1>
                        </DrawerHeader>
                        <DrawerBody padding={3}>
                            <div className='w-full flex flex-col items-start gap-2 lg:gap-4'>
                                <HeadingField
                                    title={"expense-date"}
                                />
                                <DateRangeSelect
                                    date={date}
                                    setDate={setDate}
                                    resetDates={async () => {
                                        await setDate({ startDate: '', endDate: '' })
                                        // getAllTransactions(skip, limit, searchTerm, '', '');
                                    }}
                                    getData={() => {
                                        // getAllTransactions(skip, limit, searchTerm, date?.startDate, date?.endDate);
                                    }}
                                />

                                {/* CATEGORIES DROP DOWN  */}
                                <div className='flex flex-col w-full items-start gap-2'>
                                    <HeadingField
                                        title={"category"}
                                    />
                                    {/*  CATEGORIES  */}
                                    <div className='w-full flex items-center gap-2 lg:gap-4'>
                                        <ReactSelect
                                            isMulti
                                            name="categories"
                                            className='w-full'
                                            placeholder={t('categories')}
                                            options={_hrExpenseCategories}
                                            value={_hrExpenseCategories?.filter((ec) => filterData?.categories?.includes(ec?.value))}
                                            onChange={(option) => handleSelectChange("categories", option)}
                                            getOptionLabel={(e) => t(e?.label)} // FOR ARABIC / ENGLISH 
                                        />
                                    </div>
                                </div>

                                {/* PAYMENT METHODS DROP DOWN  */}
                                <div className='flex flex-col w-full items-start gap-2'>
                                    <HeadingField
                                        title={"payment-methods"}
                                    />
                                    {/*  PAYMENT METHOD  */}
                                    <div className='w-full flex items-center gap-2 lg:gap-4'>
                                        <ReactSelect
                                            isMulti
                                            name="paymentMethods"
                                            className='w-full'
                                            placeholder={t('payment-method')}
                                            options={allPaymentMethod}
                                            value={allPaymentMethod?.filter((pm) => filterData?.paymentMethods?.includes(pm?.value))}
                                            onChange={(option) => handleSelectChange("paymentMethods", option)}
                                            getOptionLabel={(e) => t(e?.label)} // FOR ARABIC / ENGLISH 
                                        />
                                    </div>
                                </div>

                                {/* STATUS DROP DOWN  */}
                                <div className='flex flex-col w-full items-start gap-2'>
                                    <HeadingField
                                        title={"status"}
                                    />
                                    <Select
                                        mr={2}
                                        name="status"
                                        value={filterData?.status}
                                        onChange={(e) => {
                                            // setFilterDatae(e?.target?.value);
                                            setFilterData((prevData => ({
                                                ...prevData,
                                                status: e?.target?.value
                                            })))
                                        }}
                                        placeholder={t("all")}
                                    >
                                        {allExpenseStatus?.map((status, idx) => (
                                            <option
                                                key={idx}
                                                value={status?.value}
                                            >
                                                {t(status?.label)}
                                            </option>
                                        ))}
                                    </Select>
                                </div>

                            </div>
                        </DrawerBody>
                        <DrawerFooter
                            borderTop="1px solid #E1E1E1"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            padding={3}
                        >
                            <Button
                                variant="outline"
                                onClick={() => {
                                    setDate(_defaultDates)
                                    onResetFilter();
                                    onClose();
                                }}
                                mr={2}
                            >
                                {t('reset')}
                            </Button>
                            <Button
                                colorScheme="blue"
                                onClick={() => {
                                    onApplyFilter();
                                    onClose();
                                }}
                            >
                                {t('apply')}
                            </Button>
                        </DrawerFooter>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    );
};

export default ExpenseFilter;

import { Input } from '@chakra-ui/react';
import React from 'react';
import { MdCancel } from 'react-icons/md';

// INITIAL DATES DUMMY 
const _initialDate = {
    startDate: '',
    endDate: ''
}

export default function DateRangeSelect({ setDate, date ,resetDates}) {

    return (
        <div className='flex justify-center items-center'>
            <Input
                type='date'
                value={date?.startDate}
                onChange={(e) => {
                    const selectedDate = new Date(e.target.value);
                    const today = new Date();

                    // Check if the selected date is in the past
                    if (selectedDate <= today) {
                        // Update startDate
                        setDate(prevData => ({
                            ...prevData,
                            startDate: e.target.value,
                            // Reset endDate if it is before the new startDate
                            endDate: prevData.endDate && new Date(prevData.endDate) < selectedDate ? '' : prevData.endDate
                        }));
                    } else {
                        alert('The selected date cannot be in the future.');
                    }
                }}
            />
            <h1>-</h1>
            <Input
                type='date'
                value={date?.endDate}
                onChange={(e) => {
                    const selectedDate = new Date(e.target.value);
                    const today = new Date();
                    const startDate = new Date(date.startDate);

                    // Check if the selected date is in the past
                    if (selectedDate <= today) {
                        // Check if endDate is before startDate
                        if (selectedDate < startDate) {
                            alert('End date cannot be earlier than start date.');
                        } else {
                            setDate(prevData => ({
                                ...prevData,
                                endDate: e.target.value
                            }));
                        }
                    } else {
                        alert('The selected date cannot be in the future.');
                    }
                }}
            />
            {
                date?.startDate || date?.endDate ? (
                    <MdCancel
                        className='cursor-pointer ml-3'
                        size={40}
                        onClick={() => {
                            resetDates();
                        }}
                    />
                ) : (
                    <div></div>
                )
            }
        </div>
    )
}

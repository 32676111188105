import { Spinner, useDisclosure, useToast, Box, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { get, patch } from '../../services/apis/api';
import CustomAlert from '../alerts/CustomAlert';
import { IoMdArrowRoundBack } from 'react-icons/io';
import CreateMeasurementRequest from '../dialogueBoxes/CreateMeasurementRequest';
import { formatDateTime } from '../../utills/formateDate';
import { calculateAge } from '../../utills/calculateAge';

const statusRoleMapping = {
    'ALRWAAD-MANAGER-REVIEW': 'APPROVED',
};
const roleToTypeAndStatus = {
    'ALRWAAD-MANAGER': 'ALRWAAD-MANAGER-REVIEW',
};
const ViewAlRwaadGeneralRequest = ({ requestId, setView, user, getAllRequest }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState({});
    const toast = useToast();
    const [canApprove, setCanApprove] = useState(false);

    const [requestAction, setRequestAction] = useState(false);

    const getRequestDetails = async () => {
        const res = await get(`request/alrwaad-general-request/get/${requestId}`);
        if (res.statusCode === 200) {
            setRequest(res.data.request);
            const userCanApprove = roleToTypeAndStatus[user?.role] === res.data.request.status;
            setCanApprove(userCanApprove);
        }
    };

    useEffect(() => {
        getRequestDetails();
    }, []);

    const handleApprove = async () => {
        const res = await patch(`request/update/alrwaad-general-request/${requestId}`, { status: 'APPROVED' });
        if (res.statusCode === 200) {
            getRequestDetails();
            getAllRequest();
            onClose();
            toast({
                title: "Success",
                description: "Request has been approved.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const confirmApprove = () => {
        handleApprove();
        getRequestDetails();
        setLoading(false);
        onClose();
    };

    const onCancel = () => {
        setLoading(false);
        onClose();
    };

    const handleApproveClick = () => {
        setLoading(true);
        onOpen();
    };
    const handleReject = async () => {
        try {
            setLoading(true);
            const res = await patch(`request/update/alrwaad-general-request/${requestId}`, { status: "REJECTED", rejectedBy: user._id });
            setLoading(false);
            if (res.statusCode === 200) {
                getRequestDetails();
                getAllRequest();
                onClose();
                toast({
                    title: "Success",
                    description: "Request has been rejected.",
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                throw new Error(res.message);
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: "Error",
                description: error.message || "Failed to reject the request.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleApproveRejectRequest = (type) => {
        setRequestAction(type === "APPROVED");
        onOpen();
    };
    return (
        <div className='p-4'>
            <div className='flex justify-between items-center mb-4'>
                <button onClick={() => { setView(false); }} className='bg-blue-600 text-white px-4 py-2 rounded-md flex items-center'>
                    <IoMdArrowRoundBack className='mr-2' /> Back
                </button>
                <div>
                    {
                        canApprove &&
                        (
                            <Box>
                                <Button onClick={() => handleApproveRejectRequest('REJECTED')} colorScheme="red" mr={3}>
                                    {loading ? <Spinner size="sm" /> : "Reject Request"}
                                </Button>
                                <Button onClick={() => handleApproveRejectRequest('APPROVED')} colorScheme="blue">
                                    {loading ? <Spinner size="sm" /> : "Approve Request"}
                                </Button>
                            </Box>
                        )}

                </div>
            </div>

            <section className='bg-gray-50 p-6 rounded-lg mb-4'>
                <div className="image overflow-hidden rounded-lg grid grid-cols-1 md:grid-cols-3 gap-4">
                    <img className="h-48 w-full mx-auto object-cover"
                        src={request?.requestedBy?.pic}
                        alt="" />
                </div>
                <h2 className='text-2xl font-bold mb-4'>Request Details</h2>

                <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>

                    <div>
                        <h3 className='font-bold'>Request By:</h3>
                        <p>{request?.requestedBy?.name}</p>
                    </div>
                    <div>
                        <h3 className='font-bold'>Request Status:</h3>
                        <p className={request?.status === 'REJECTED' ? 'text-red-500 font-bold' : request?.status === 'APPROVED' ? 'text-green-500 font-bold' : 'text-orange-500 font-bold'}>
                            {request?.status}
                        </p>
                    </div>
                    <div>
                        <h3 className='font-bold'>Request Type:</h3>
                        <p>{request?.kind}</p>
                    </div>
                    <div>
                        <h3 className='font-bold'>Username:</h3>
                        <p>{request?.requestedBy?.userName}</p>
                    </div>
                    <div>
                        <h3 className='font-bold'>Mobile:</h3>
                        <p>{request?.requestedBy?.mobile}</p>
                    </div>    <div>
                        <h3 className='font-bold'>Email:</h3>
                        <p>{request?.requestedBy?.email}</p>
                    </div>    <div>
                        <h3 className='font-bold'>Date of Birth:</h3>
                        <p>{formatDateTime(request?.requestedBy?.dateOfBirth, 'date')}</p>
                    </div>

                    <div>
                        <h3 className='font-bold'>Age:</h3>
                        <p>{calculateAge(request?.requestedBy?.dateOfBirth)}</p>
                    </div>
                    <div>
                        <h3 className='font-bold'>Government Id Number:</h3>
                        <p>{request?.requestedBy?.govIdNumber}</p>
                    </div>
                </div>

            </section>





            <CustomAlert
                isOpen={isOpen}
                onClose={onClose}
                title='Approve request'
                description='Are you sure you want to approve this request?'
                onConfirm={confirmApprove}
                onCancel={onCancel}
                confirmButtonText='Approve'
                cancelButtonText='Cancel'
                confirmButtonColorScheme='blue'
            />
            {requestAction ? (
                <CustomAlert
                    isOpen={isOpen}
                    onClose={onClose}
                    title="Approve request"
                    description="Are you sure you want to approve this request?"
                    onConfirm={handleApprove}
                    confirmButtonText="Approve"
                    cancelButtonText="Cancel"
                    confirmButtonColorScheme="blue"
                />
            ) : (
                <CustomAlert
                    isOpen={isOpen}
                    onClose={onClose}
                    title="Reject request"
                    description="Are you sure you want to reject this request?"
                    onConfirm={handleReject}
                    confirmButtonText="Reject"
                    cancelButtonText="Cancel"
                    confirmButtonColorScheme="red"
                />
            )}
        </div>
    );
};

export default ViewAlRwaadGeneralRequest;

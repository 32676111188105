import React from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
} from "@chakra-ui/react";
import CreateEventForm from "../mediaCenter/CreateEventForm";
import { useTranslation } from 'react-i18next';

const CreateEvent = ({ getAllEvents, isEdit, event, setViewEvent }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    return (
        <>
            <Button onClick={onOpen}> {isEdit ? t(`Edit`) : t(`Create Event`)}</Button>
            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={'3xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {isEdit ? t("Edit Event") : t('Create New Event')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <div>
                            <CreateEventForm getAllEvents={getAllEvents} isEdit={isEdit} event={event} onClose={onClose} setViewEvent={setViewEvent} />
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateEvent;

import { Spinner, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { get, patch } from '../../services/apis/api';
import CustomAlert from '../alerts/CustomAlert';
import { IoMdArrowRoundBack } from 'react-icons/io';
import CreateMeasurementRequest from '../dialogueBoxes/CreateMeasurementRequest';
import { formatDateTime } from '../../utills/formateDate';
import { useTranslation } from 'react-i18next';

const ViewPlayerRequest = ({ requestId, setView, user, getAllRequest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState({});
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const getRequestDetails = async () => {
    const res = await get(`request/get/${requestId}`);
    if (res.statusCode === 200) {
      setRequest(res.data.request);
    }
  };

  useEffect(() => {
    getRequestDetails();
  }, []);

  const handleApprove = async () => {
    const res = await patch(`request/approved-player/${requestId}`, { status: 'APPROVED' });
    if (res.statusCode === 200) {
      getRequestDetails();
    }
  };

  const confirmApprove = () => {
    handleApprove();
    getRequestDetails();
    setLoading(false);
    onClose();
  };

  const onCancel = () => {
    setLoading(false);
    onClose();
  };

  const handleApproveClick = () => {
    setLoading(true);
    onOpen();
  };

  return (
    <div className='p-4'>
      <div className='flex justify-between items-center mb-4'>
        <button onClick={() => { setView(false); }} className='bg-blue-600 text-white px-4 py-2 rounded-md flex items-center'>
          <IoMdArrowRoundBack className='mr-2' /> Back
        </button>
        <div>
          {request && request.status === 'GAME-MANAGER-MEASUREMENT-REVIEW' &&
            <button onClick={handleApproveClick} className='bg-blue-600 text-white px-4 py-2 rounded-md'>
              {loading ? <Spinner /> : 'Approve Request'}
            </button>
          }
          {request && request.status === 'GAME-MANAGER-REVIEW' &&
            <CreateMeasurementRequest getRequestDetails={getRequestDetails} request={request} />
          }
        </div>
      </div>

      <section className='bg-gray-50 p-6 rounded-lg mb-4'>
        <h2 className={`text-2xl ${isArabic ? 'text-right' : 'text-left'} font-bold mb-4`}>Request Details</h2>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
          <div>
            <h3 className='font-bold'>Request By:</h3>
            <p>{request?.requestedBy?.name}</p>
          </div>
          <div>
            <h3 className='font-bold'>Request Status:</h3>
            <p className={request?.status === 'REJECTED' ? 'text-red-500 font-bold' : request?.status === 'APPROVED' ? 'text-green-500 font-bold' : 'text-orange-500 font-bold'}>
              {request?.status}
            </p>
          </div>
          <div>
            <h3 className='font-bold'>Request Type:</h3>
            <p>{request?.kind}</p>
          </div>
          <div>
            <h3 className='font-bold'>Player Name:</h3>
            <p>{request?.playerId?.name}</p>
          </div>
        </div>
        <div className='mt-4'>
          <h3 className='font-bold'>Description:</h3>
          <p>{request?.description}</p>
        </div>
      </section>

      {request?.playerId &&
        <section className='bg-gray-50 p-6 rounded-lg mb-4'>
          <h2 className='text-2xl font-bold mb-4'>Player's Information</h2>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
            <div>
              <h3 className='font-bold'>Name:</h3>
              <p>{request?.playerId?.name}</p>
            </div>
            <div>
              <h3 className='font-bold'>Email:</h3>
              <p>{request?.playerId?.email}</p>
            </div>
            <div>
              <h3 className='font-bold'>Mobile:</h3>
              <p>{request?.playerId?.mobile}</p>
            </div>
            <div>
              <h3 className='font-bold'>Father Name:</h3>
              <p>{request?.playerId?.fatherName}</p>
            </div>
            <div>
              <h3 className='font-bold'>Mother Name:</h3>
              <p>{request?.playerId?.motherName}</p>
            </div>
            <div>
              <h3 className='font-bold'>Date Of Birth:</h3>
              <p>{formatDateTime(request?.playerId?.dateOfBirth, 'date')}</p>
            </div>
            <div>
              <h3 className='font-bold'>Country:</h3>
              <p>{request?.playerId?.country}</p>
            </div>
            <div>
              <h3 className='font-bold'>State:</h3>
              <p>{request?.playerId?.state}</p>
            </div>
            <div>
              <h3 className='font-bold'>City:</h3>
              <p>{request?.playerId?.city}</p>
            </div>
            <div>
              <h3 className='font-bold'>Address:</h3>
              <p>{request?.playerId?.address}</p>
            </div>
            <div>
              <h3 className='font-bold'>Postal Code:</h3>
              <p>{request?.playerId?.pinCode}</p>
            </div>
          </div>
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-4'>
            {request?.playerId?.pic && <img src={request?.playerId?.pic} alt='Player' className='w-full h-auto object-cover rounded-md' />}
            {request?.playerId?.idFrontImage && <img src={request?.playerId?.idFrontImage} alt='ID Front' className='w-full h-auto object-cover rounded-md' />}
            {request?.playerId?.idBackImage && <img src={request?.playerId?.idBackImage} alt='ID Back' className='w-full h-auto object-cover rounded-md' />}
            {request?.playerId?.certificateLink && <img src={request?.playerId?.certificateLink} alt='Certificate' className='w-full h-auto object-cover rounded-md' />}
          </div>
        </section>
      }

      {request?.playerId?.chestSize &&
        <section className='bg-gray-50 p-6 rounded-lg mb-4'>
          <h2 className='text-2xl font-bold mb-4'>Coach Measurement Data</h2>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
            <div>
              <h3 className='font-bold'>Chest Size:</h3>
              <p>{request?.playerId?.chestSize}</p>
            </div>
            <div>
              <h3 className='font-bold'>Heart Beating Rate:</h3>
              <p>{request?.playerId?.heartBeatingRate}</p>
            </div>
            <div>
              <h3 className='font-bold'>High Jump:</h3>
              <p>{request?.playerId?.highJump}</p>
            </div>
            <div>
              <h3 className='font-bold'>Low Jump:</h3>
              <p>{request?.playerId?.lowJump}</p>
            </div>
            <div>
              <h3 className='font-bold'>Normal Chest Size:</h3>
              <p>{request?.playerId?.normalChestSize}</p>
            </div>
            <div>
              <h3 className='font-bold'>Pulse Rate:</h3>
              <p>{request?.playerId?.pulseRate}</p>
            </div>
            <div>
              <h3 className='font-bold'>Shoe Size:</h3>
              <p>{request?.playerId?.shoeSize}</p>
            </div>
            <div>
              <h3 className='font-bold'>T-Shirt Size:</h3>
              <p>{request?.playerId?.tshirtSize}</p>
            </div>
            <div>
              <h3 className='font-bold'>Waist Size:</h3>
              <p>{request?.playerId?.waistSize}</p>
            </div>
          </div>
        </section>
      }

      <CustomAlert
        isOpen={isOpen}
        onClose={onClose}
        title='Approve request'
        description='Are you sure you want to approve this request?'
        onConfirm={confirmApprove}
        onCancel={onCancel}
        confirmButtonText='Approve'
        cancelButtonText='Cancel'
        confirmButtonColorScheme='blue'
      />
    </div>
  );
};

export default ViewPlayerRequest;

import React, { useEffect, useState } from 'react';
import {
    Button,
    Input,
    Textarea,
    Select,
    useDisclosure,
    FormControl,
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast,
    IconButton,
    Tooltip
} from '@chakra-ui/react';
import { patch, post } from '../../../services/apis/api';
import { EditIcon } from '@chakra-ui/icons';

const CreateAnnouncement = ({getAllAnnouncements, isEdit, announcement}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        media: [],
        announcementDate: '',
        status: 'new'
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            media: [...e.target.files] 
        });
    };

    useEffect(() => {
      if(isEdit) {
        setFormData({
            title: announcement?.title,
            description : announcement?.description,
            announcementDate : announcement.announcementDate,
            status: announcement.status,
        })
      }
    }, [])
    

    const handleSubmit = async () => {
        try {
            let response ;
            if(isEdit){
                response = await patch(`announcement/update/${announcement._id}`, formData);
            }else{
                response = await post('announcement/add', formData);
            }

            if (response.statusCode === 201 || response.statusCode === 200) {
                toast({
                    title: 'Announcement created.',
                    description: 'Your announcement has been created successfully.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onClose(); 
                getAllAnnouncements();
                setFormData({
                    title: '',
                    description: '',
                    media: [],
                    announcementDate: '',
                    status: 'new'
                });
            } else {
                toast({
                    title: 'Error',
                    description: 'Failed to create announcement.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <div>
            {
                isEdit ? 
                <Tooltip label='Edit Announcement'>
                    <IconButton
                        icon={<EditIcon />}
                        onClick={onOpen}
                        aria-label="edit Details"
                    /> 
                </Tooltip>
                : 
                <Button onClick={onOpen} colorScheme="blue">
                    Create Announcement
                </Button>
            }

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create Announcement</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl id="title" isRequired>
                            <FormLabel>Title</FormLabel>
                            <Input
                                name="title"
                                value={formData.title}
                                onChange={handleInputChange}
                                placeholder="Enter title"
                            />
                        </FormControl>

                        <FormControl id="description" mt={4}>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                placeholder="Enter description"
                            />
                        </FormControl>
{/* 
                        <FormControl id="media" mt={4}>
                            <FormLabel>Media</FormLabel>
                            <Input
                                type="file"
                                multiple
                                onChange={handleFileChange}
                            />
                        </FormControl> */}

                        <FormControl id="announcementDate" mt={4}>
                            <FormLabel>Announcement Date</FormLabel>
                            <Input
                                type="date"
                                name="announcementDate"
                                value={formData?.announcementDate ? new Date(formData?.announcementDate).toISOString().split('T')[0] : ""}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        <FormControl id="status" mt={4}>
                            <FormLabel>Status</FormLabel>
                            <Select
                                name="status"
                                value={formData.status}
                                onChange={handleInputChange}
                            >
                                <option value="new">New</option>
                                <option value="upcoming">Upcoming</option>
                                <option value="ongoing">Ongoing</option>
                                <option value="completed">Completed</option>
                            </Select>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='ghost' onClick={onClose}>Cancel</Button>
                        <Button colorScheme='blue' mx={3} onClick={handleSubmit}>
                            Submit
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default CreateAnnouncement;


// src/pages/employee/EmployeeAttendance.jsx
import React, { useState } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  Text,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { DownloadIcon, SearchIcon } from '@chakra-ui/icons';
import DateRangePicker from '../../components/hr/components/attendance/DateRangePicker';
import DatePicker from 'react-datepicker';
import { CiCalendarDate } from 'react-icons/ci';
import { get } from '../../services/apis/api';
import { useEffect } from 'react';

const EmployeeAttendance = () => {
  const [startDate, setStartDate] = useState('');
  const [attendanceData, setAttendanceDate] = useState(undefined);
  const toast = useToast()
  const handleDateChange = (date) => {
    const updatedDate = new Date(date.getFullYear(), date.getMonth(), 30);
    setStartDate(updatedDate);
  };


  const getAttendanceDetails = async () => {
    try {
      const res = await get(`attendance/employee?date=${startDate}`);
      if (res.statusCode === 200) {
        setAttendanceDate(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getAttendanceDetails();
  }, [startDate])

  const handleCheckIn = async ()=>{
    try {
      const res = await get('attendance/check-in')
      if (res.statusCode === 200) {
        toast({
          title: 'Successfull...!',
          description: 'Check in Success',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error...!',
        description: error?.response?.data?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }
  const handleCheckOut = async ()=>{
    try {
      const res = await get('attendance/check-out')
      if (res.statusCode === 200) {
        toast({
          title: 'Successfull...!',
          description: 'Check out Success',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Successfull...!',
        description: error?.response?.data?.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    }
  }


  if (!attendanceData) {
    return (
      <div className='w-[70vw] h-[70vh] flex justify-center items-center'>
        <Spinner />
      </div>
    )
  }
 
  return (
    <Box width="100%" p={4}>
      <Flex justify="space-between" align="center" mb={4}>
        <Box fontSize="xl" fontWeight="bold">
          My Attendance
        </Box>
        <Flex align="center">
          <InputGroup mr={2} w={{ base: "100%", md: "auto" }}>
           <h2 className='font-semibold text-lg'>Search By Month : </h2>
          </InputGroup>
          <div className="relative w-[195px] me-4 ">
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              placeholderText="Select Month"
              className="w-[200px] border rounded-md p-2 pr-12"
            />
            <CiCalendarDate className="absolute right-0 top-1/2 transform -translate-y-1/2 text-xl text-gray-500 pointer-events-none" />
          </div>
          <Button colorScheme='green' me={2} onClick={handleCheckIn}>CheckIn</Button>
          <Button colorScheme='green' me={2} onClick={handleCheckOut}>CheckOut</Button>

          <Button leftIcon={<DownloadIcon />} colorScheme="gray">
            Download Report
          </Button>
        </Flex>
      </Flex>
      {
        (attendanceData && !attendanceData.monthData) ?
          <div className='w-[70vw] h-[70vh] flex justify-center items-center'>
            <h2>No data available for this month</h2>
          </div> :

          <div>
            <Flex mb={6} align="center">
              <Avatar src={attendanceData?.monthData?.employeeDetails?.pic} name={attendanceData?.monthData?.employeeDetails?.name} mr={4} />
              <Box>
                <Text fontSize="lg" fontWeight="bold">{attendanceData?.monthData?.employeeDetails?.name}</Text>
                <Text color="gray.500">Employee Code: {attendanceData?.monthData?.employeeDetails?.employeeCode}</Text>
              </Box>
            </Flex>


            <Box mb={6} p={4} bg="gray.50" borderRadius="md" boxShadow="sm">
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                Attendance Summary
              </Text>
              <Flex justify="space-between">
                <Box>
                  <Text>Total Paid Days: {attendanceData?.monthData?.paidDays}</Text>
                  <Text>Total Payable Days: {attendanceData?.monthData?.payableDays}</Text>
                  <Text>Total Leave Days: {attendanceData?.monthData?.totalLeave}</Text>
                </Box>
                <Box>
                  <Text>Total Overtime Hours: {attendanceData?.monthData?.overTimeHours}</Text>
                  <Text>Total Absent Days: {attendanceData?.monthData?.totalAbsent}</Text>
                </Box>
              </Flex>
            </Box>

            <Table className="w-full text-sm text-left text-gray-500">
              <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <Tr>
                  <Th>Date</Th>
                  <Th>Status</Th>
                  <Th>Overtime Hours</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  attendanceData.dayData.length > 0 ?
                    attendanceData.dayData.map((record, index) => (
                      <Tr key={index}>
                        <Td>{record.date}</Td>
                        <Td>{record.status}</Td>
                        <Td>{record.overtimeHours}</Td>
                      </Tr>
                    )) :
                    <div className='flex justify-center w-full mt-6'>
                      <h2> No Data found for daily attendance of this Month</h2>
                    </div>
                }
              </Tbody>
            </Table>
          </div>
      }



    </Box>
  );
};

export default EmployeeAttendance;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Button,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Tooltip
} from '@chakra-ui/react';
import { SearchIcon, ViewIcon, EditIcon } from '@chakra-ui/icons';
import CreateAnnouncement from '../../components/hr/dialogBoxes/CreateAnnouncement';
import { get } from '../../services/apis/api';

const Announcements = () => {
const [announcements, setAnnouncements] = useState([]);
const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
const [search, setSearch] = useState('');
const [page, setPage] = useState(1);
const [limit] = useState(10);
const [totalPages, setTotalPages] = useState(1);
const { isOpen, onOpen, onClose } = useDisclosure();

const getAllAnnouncements = async () => {
  try {
    const res = await get(`announcement/all?page=${page}&limit=${limit}&search=${search}`);
    if (res.statusCode === 200) {
      setAnnouncements(res.data.data);
      setTotalPages(res.data.pages);
    }
  } catch (error) {
    console.error('Error fetching announcements:', error);
  }
};

useEffect(() => {
  getAllAnnouncements();
}, [page, search]);

const handleSearchChange = (e) => {
  setSearch(e.target.value);
  setPage(1); // Reset to page 1 on new search
};

const handleNextPage = () => {
  if (page < totalPages) setPage(page + 1);
};

const handlePrevPage = () => {
  if (page > 1) setPage(page - 1);
};

const openAnnouncementDetails = (announcement) => {
  setSelectedAnnouncement(announcement);
  onOpen();
};

  return (
    <Box width="100%" p={4}>
      <Flex justify="space-between" align="center" mb={4}>
        <Box fontSize="xl" fontWeight="bold">
          Announcements
        </Box>
        <Flex align="center">
          <InputGroup mr={2} mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "auto" }}>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              type="text"
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
            />
          </InputGroup>
          <CreateAnnouncement getAllAnnouncements={getAllAnnouncements} isEdit={false}/>
        </Flex>
      </Flex>
      <Table className="w-full text-sm text-left text-gray-500">
        <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <Tr>
            <Th>Profile</Th>
            <Th>Title</Th>
            <Th>Description</Th>
            <Th>Date</Th>
            <Th>Posted By</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {announcements.map((row, index) => (
            <Tr key={index}>
              <Td>
                <Avatar src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzmFrbddbvFMCyU-QsaiG3zaIRC4JAnVJN0A&s'} name={row.createdBy.name} />
              </Td>
              <Td>{row.title}</Td>
              <Td>
                <Text noOfLines={1}>{row.description}</Text>
              </Td>
              <Td>{new Date(row.announcementDate).toLocaleDateString()}</Td>
              <Td>{row.createdBy.name}</Td>
              <Td className='flex space-x-2'>
                <Tooltip label='View Announcement'>
                  <IconButton
                    icon={<ViewIcon />}
                    onClick={() => openAnnouncementDetails(row)}
                    aria-label="View Details"
                  />
                </Tooltip>
                <CreateAnnouncement getAllAnnouncements={getAllAnnouncements} isEdit={true} announcement={row}/>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {/* Pagination controls */}
      <Flex justify="space-between" align="center" mt={4}>
        <Button onClick={handlePrevPage} isDisabled={page === 1}>
          Previous
        </Button>
        <Text>
          Page {page} of {totalPages}
        </Text>
        <Button onClick={handleNextPage} isDisabled={page === totalPages}>
          Next
        </Button>
      </Flex>

      {/* Modal for viewing announcement details */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Announcement Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedAnnouncement && (
              <Box>
                <Text fontWeight="bold">Title:</Text>
                <Text mb={2}>{selectedAnnouncement.title}</Text>

                <Text fontWeight="bold">Description:</Text>
                <Text mb={2}>{selectedAnnouncement.description}</Text>

                <Text fontWeight="bold">Date:</Text>
                <Text mb={2}>{new Date(selectedAnnouncement.announcementDate).toLocaleDateString()}</Text>

                <Text fontWeight="bold">Posted By:</Text>
                <Text mb={2}>{selectedAnnouncement.createdBy.name}</Text>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Announcements;

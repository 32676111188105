import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import { get, patch } from '../../services/apis/api';
import { useLocation, useNavigate } from 'react-router-dom';
import ExpensesTransactionsTable from '../../components/account/ExoensesTransactionsTable';




// TABLE COLUMNS 
const columns = [
    's-no',
    'Date-of-entry',
    'amount',
    'Transection Type',
    'source-of-income',
    'created-by',
    'Description',
    // 'Actions' //QUETIONS IT SHOLD GIVE HERE OR NOT 
];

const ExpensesList = () => {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const navigate = useNavigate();
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0); // Initial skip should be 0
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const route = location.pathname.split('/').pop();
    const [allTransactions, setAllTransactions] = useState([]);
    const [limit, setLimit] = useState(10);


    // FETCH ALL TRANSACTIONS BY KIND 
    const fetchData = async () => {
        setLoading(true);
        try {
            // Include month and year in the API call if selected
            const res = await get(
                `expenses/transactions/kind/${route}`
            );
            if (res.statusCode === 200) {
                setAllTransactions(res.data.data);
                // setCounts(res.data.counts);
                // setTotalPages(res.data.pages);
            }
        } catch (error) {
            console.error('Error fetching payroll data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    // // Debounce the getAllData function
    // const debouncedGetAllData = debounce(fetchData, 300); // Adjust delay as needed

    // useEffect(() => {
    //     debouncedGetAllData();
    //     // Cleanup function to cancel the debounced call on component unmount
    //     return () => {
    //         debouncedGetAllData.cancel();
    //     };
    // }, [searchTerm]);

    return (
        <>
            <div className='w-full flex-1 flex flex-col items-start gap-2 lg:gap-4 p-2 lg:p-4'>
                <button onClick={() => { navigate(-1) }} className="px-4 py-2 text-white bg-[#290dbd] rounded-md hover:bg-[#1d0a8c]">
                    {t("back")}
                </button>
                <div className={`flex w-full justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                    <h2 className=' text-xl lg:text-2xl  whitespace-nowrap font-bold'>{t(route)}</h2>
                    {/* SEARCH HIDE FOR CURRENT  */}
                    {/* <div className="relative">
                        <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                            <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                        <input
                            type="text"
                            id="table-search-users"
                            className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                            placeholder={t("search-request")}
                            value={searchTerm}
                            onChange={(e) => { setSearchTerm(e.target.value) }}
                        />
                    </div> */}
                </div>
                <div className='w-full flex-1 flex flex-col '>
                    <ExpensesTransactionsTable
                        page={page}
                        skip={skip}
                        limit={limit}
                        setSkip={setSkip}
                        setLimit={setLimit}
                        setPage={setPage}
                        columns={columns}
                        rows={allTransactions}
                        totalPages={totalPages}
                    />
                </div>
            </div>
        </>
    )
}

export default ExpensesList 

import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  InputGroup,
  Text,
  useToast,
  VStack,
  HStack,
  Heading,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import DatePicker from 'react-datepicker';
import { CiCalendarDate } from 'react-icons/ci';
import { get, post } from '../../services/apis/api';
import { UserState } from '../../context/user';
import { jsPDF } from 'jspdf'; // Import jsPDF for PDF generation
import { Link, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AttendanceAccordian from '../../components/AttendanceAccordian';
import { FaRegClock } from "react-icons/fa6";
import { GoOrganization } from "react-icons/go";
import { CiPhone } from "react-icons/ci";
import { MdOutlineEmail } from "react-icons/md";
import { BsPersonX } from "react-icons/bs";
import { BsPersonCheck } from "react-icons/bs";
import BreadCrumbs from '../../components/BreadCrumbs';
import LeaveDialogue from '../../components/dialogueBoxes/LeavesDialogue';
import { formatDailyAttendanceTime, formatDateToReadableString, formatNumber } from '../../services/glocalFunctions';
import ConfirmDownload from '../../components/dialogueBoxes/ConfirmDownload';

const EmployeeAttendance = () => {
  const { user } = UserState();
  const navigate = useNavigate();
  const { employeeId } = useParams();
  const { pathname } = useLocation();
  const isReportingHead = matchPath({ path: '/employee/team-attendance/*', end: false }, pathname) !== null;
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    // Set the date to the first day of the next month
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    // Set the date to the last day of the current month (which is the day before the first of the next month)
    nextMonth.setDate(nextMonth.getDate() - 1);
    return nextMonth;
  });

  const [attendanceData, setAttendanceDate] = useState(null);
  const [dailyAttendances, setDailyAttendances] = useState(null);
  const [lastAction, setLastAction] = useState('');
  const [lastestAttendance, setLastestAttendance] = useState(null);
  const [isLoading, setIsLoading] = React.useState(false)
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure(); // Chakra UI modal hooks
  const {
    isOpen: isOpenAttendance,
    onOpen: onOpenAttendance,
    onClose: onCloseAttendance,
  } = useDisclosure(); // Chakra UI modal hooks
  const [downloadOpen, setDownloadOpen] = useState(false);  // for confirmation dialog
  //  FLEX DIRECTION CLASSES 
  const flDir = isArabic ? 'flex-row-reverse' : 'flex-row'


  // HANDLE DATE CHANGE FUNCTION 
  const handleDateChange = (date) => {
    try {
      if (date instanceof Date && !isNaN(date)) {
        const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        nextMonth.setDate(nextMonth.getDate() - 1); // Get last day of the month
        setStartDate(nextMonth);
      } else {
        console.log("Invalid date selected");
      }
    } catch (e) {
      console.log(`ERROR WHILE SETTING DATE ${e}`);
    }
  };

  // FETCH ATTANDANCE DETAILS 
  const getAttendanceDetails = async (startDate) => {
    try {
      const res = await get(`attendance/employee?date=${startDate}&employeeId=${employeeId || user?._id}`);
      if (res.statusCode == 200) {
        const __data = res?.data?.data;
        setAttendanceDate(__data?.monthData);
        setDailyAttendances(__data?.dayData);
        let lastAttendance = __data?.dayData[0];
        setLastestAttendance(lastAttendance)
        if (__data?.dayData?.length > 0) {
          const lastSession = lastAttendance?.sessionIds?.[0];
          if (lastSession) {
            let _lastAction = 'check-in'; // Default to check-in
            if (lastSession && lastSession.checkOutTime) {
              _lastAction = 'check-out';
            }
            setLastAction(_lastAction);
          }
        } else {
          console.log('No attendance data available.');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAttendanceDetails(startDate);
  }, [startDate]);

  // HANDLE CHECK IN FUNCTION 
  const handleCheckIn = async () => {
    setIsLoading(true);
    try {
      const res = await get('attendance/check-in');
      setIsLoading(false);
      if (res.statusCode === 200) {
        getAttendanceDetails(startDate);
        toast({
          title: 'Successful!',
          description: 'Check-in Successful',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: 'Error!',
        description: error?.response?.data?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // HANDLE CHECK OUT FUNCTION 
  const handleCheckOut = async () => {
    setIsLoading(true);
    try {
      const _sessionId = lastestAttendance?.sessionIds[0]?._id;
      console.log(lastestAttendance?.sessionIds[0], 'latest attendance----->');
      const res = await get(`attendance/check-out?session_id=${_sessionId}`);
      setIsLoading(false);
      if (res.statusCode === 200) {
        getAttendanceDetails(startDate);
        toast({
          title: 'Successful!',
          description: 'Check-out Successful',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: 'Error!',
        description: error?.response?.data?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // FUNCTION TO DOWNLOAD THE REPORT AS PDF
  const downloadReport = () => {
    setIsLoading(true);
    try {
      const doc = new jsPDF();

      // Set title of the report
      doc.setFontSize(18);
      doc.text('Attendance Report', 14, 20);

      // Add user details to the PDF
      doc.setFontSize(12);
      doc.text(`Name: ${attendanceData?.employeeId?.name}`, 14, 30);
      doc.text(`Employee Code: ${attendanceData?.employeeId?.employeeCode}`, 14, 40);
      doc.text(`Designation: ${attendanceData?.employeeId?.designation}`, 14, 50);

      // Add attendance summary to the PDF
      if (attendanceData) {
        doc.text(`Total Paid Days: ${attendanceData?.paidDays}`, 14, 60);
        doc.text(`Total Payable Days: ${attendanceData?.payableDays}`, 14, 70);
        doc.text(`Total Leave Days: ${attendanceData?.totalLeave}`, 14, 80);
        doc.text(`Total Overtime Hours: ${attendanceData?.overTimeHours}`, 14, 90);
        doc.text(`Total Absent Days: ${attendanceData?.totalAbsent}`, 14, 100);
      }

      // Add daily attendance table to the PDF
      // Add daily attendance table header
      // Add daily attendance table header
      let yPosition = 110;
      doc.text('Date', 14, yPosition);
      doc.text('Status', 100, yPosition);

      // Loop through the daily attendance data and add session details
      for (let i = 0; i < dailyAttendances?.length; i++) {
        const record = dailyAttendances[i];
        yPosition += 10;

        // doc.text('Check In', 14, yPosition);
        // doc.text('Check Out', 100, yPosition);
        // doc.text('Status', 150, yPosition);
        // Add Date and Status
        doc.text(record?.date.split('T')[0], 14, yPosition); // Format the date (YYYY-MM-DD)
        doc.text(record?.approvedStatus, 100, yPosition); // Attendance Status

        // Add session details below the status
        const sessionDetails = record?.sessionIds?.map((session) => {
          // Assuming you have a function to fetch session data by sessionId
          // const session = getSessionDetailsById(sessionId); // Replace with actual data fetching logic
          return `${session?.checkInTime.split('T')[1]}, ${session?.checkOutTime.split('T')[1]}, ${session?.approvedStatus}`;
        });

        // Add sessions for each day
        if (sessionDetails) {
          sessionDetails.forEach((sessionDetail, index) => {
            yPosition += 10;
            doc.text(sessionDetail, 20, yPosition); // Position for session details (under Date/Status)
          });
        }
      }

      // Save the PDF
      doc.save('attendance_report.pdf');
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(`ERROR WHILE DOWNLODING ${e}`);
    }
  };

  // BASIC COMPONENT OF EMPLOYEE DETAILS 
  const BasicInfoComponent = ({
    heading,
    label1,
    label2,
    icon
  }) => (
    <div className={`flex ${flDir} items-center gap-2 lg:gap-4`}>
      {icon}
      <div className={`flex flex-col ${isArabic ? 'items-end' : 'items-start'}`}>
        <span className='text-xs text-[#6c7381]  font-medium '>
          {t(heading)}
        </span>
        <h1 className='text-xl lg:text-lg text-[#1f2937]  font-bold '>
          {label1}
        </h1>
        <span className='text-xs text-[#4f45e4]  font-medium '>
          {label2}
        </span>
      </div>
    </div>
  )

  // DAYS COUNT CARD 
  const DaysCard = ({
    bg,
    heading,
    value,
    icon,
    iconColor,
    onClickAttendance
  }) => (
    <div
      style={{
        background: bg
      }}
      onClick={() => {
        if (onClickAttendance) {
          onClickAttendance();
        }
      }}
      className={`w-[23.5%] hover:shadow-md hover:scale-105 transition-all duration-300 shadow rounded-lg p-4`}>
      <div className={`flex items-center gap-2 ${flDir}`}>
        <div className='bg-[#ffffff] w-fit p-2 mb-2 rounded-lg'>
          {icon}
        </div>
        <span className={`text-xs lg:text-base text-[#6c7381]  font-bold ${isArabic ? 'text-right' : 'text-left'}`}>
          {heading}
        </span>
      </div>
      <h1 className={`text-xl lg:text-lg text-[#1f2937]  font-bold ${isArabic ? 'text-right' : 'text-left'}`}>
        {/* {value} */}
        {formatNumber(value, isArabic)}
      </h1>
    </div>
  )

  // Approve attendance
  const approveAttendance = async (attendanceId, _status) => {

    setIsLoading(true);
    try {
      const res = await post(`attendance/update-session`, { sId: attendanceId, status: _status });

      setIsLoading(false);
      if (res?.statusCode === 200) {
        getAttendanceDetails(startDate);
        toast({
          title: 'Successful!',
          description: res?.data?.message || (_status !== 'APPROVED' ? 'REJECTED Successfully' : 'Approved Successfully'),
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: 'Error !',
        description: error?.response?.data?.message || 'Some error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error approving attendance:', error);
    }
  };

  // Approve MULTILPLE SESSION A ONCE attendance
  const approveMultipleSesions = async (sessionIds, _status) => {
    setIsLoading(true);
    try {
      const res = await post(`attendance/update-multilpe-session`, { sessionIds: sessionIds, status: _status });
      console.log(res, 'res-----mul>');

      setIsLoading(false);
      if (res?.statusCode === 200) {
        getAttendanceDetails(startDate);
        toast({
          title: 'Successful!',
          description: res?.data?.message || (_status !== 'APPROVED' ? 'REJECTED Successfully' : 'Approved Successfully'),
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: 'Error !',
        description: error?.response?.data?.message || 'Some error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error approving attendance:', error);
    }
  };

  // Confirm download
  const confirmDownload = () => {
    try {
      downloadReport();
      setDownloadOpen(false);
    } catch (e) {
      console.log(`ERROR WHILE GENERATING PDF ${e}`);
    }
  };

  return (
    <Box width="100%" px={2}>
      <BreadCrumbs />
      {/* HEADER PART / */}
      <div className={`w-full py-2 lg:py-4 flex ${flDir} items-center justify-between`}>
        <h1 className='text-[#5145e5] text-lg lg:text-3xl font-bold'>{t('attendance')}</h1>
        <div className={`flex ${flDir} flex-wrap gap-2 lg:gap-4`}>
          <InputGroup position="relative" w="full" maxW="240px">
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              placeholderText="Select Month"
              className="border rounded-md p-2 w-[240px]"
            />
            <CiCalendarDate className="absolute right-3 top-1/2 transform -translate-y-1/2 text-xl text-gray-500 pointer-events-none" />
          </InputGroup>
          {!isReportingHead ?
            <>
              <Button
                colorScheme="green"
                onClick={handleCheckIn}
                isLoading={isLoading}
                isDisabled={lastAction == 'check-in' ? true : false}
                leftIcon={isArabic ? null : <FaRegClock />}
                rightIcon={isArabic ? <FaRegClock /> : null}
                bg={"#effdf4"}
                color={"#16a349"}
                _hover={{
                  bg: "#d4f4e0", // Lighter green background on hover
                  color: "#128036", // Darker green text color on hover
                  transform: "scale(1.05)", // Slightly enlarge the button on hover
                }}
              >
                {t('check-in')}
              </Button>
              <Button
                colorScheme="red"
                onClick={handleCheckOut}
                isLoading={isLoading}
                leftIcon={isArabic ? null : <FaRegClock />}
                rightIcon={isArabic ? <FaRegClock /> : null}
                isDisabled={lastAction == 'check-out' || lastestAttendance?.sessionIds?.length == 0 ? true : false}
                bg={"#fdf2f2"} // Light red background
                color={"#e11d48"} // Text/icon color for red
                _hover={{
                  bg: "#fce7e7", // Slightly lighter red background on hover
                  color: "#9f1239", // Darker red text/icon color on hover
                  transform: "scale(1.05)", // Slightly enlarge the button on hover
                }}
              >
                {t('check-out')}
              </Button>
            </>
            : null}
          {/* Download Confirmation Dialog */}
          <ConfirmDownload
            isOpen={downloadOpen}
            label='download-report'
            setIsOpen={setDownloadOpen}
            handleDownload={() => {
              setDownloadOpen(true);
            }}
            confirmDownload={() => {
              confirmDownload();
            }}
            closeDialog={() => {
              setDownloadOpen(false);
            }}
            // loading={loading}
          />
        </div>
      </div>

      {/* EMPLOYEE BASIC INFO SECTION  */}
      <div className={`w-full flex ${flDir} items-center justify-between border-b mb-4 py-4 border-b-[#dfe7ff]`}>
        <BasicInfoComponent
          heading={t('employee-name')}
          label1={attendanceData?.employeeId?.name || '--'}
          label2={attendanceData?.employeeId?.employeeCode || '--'}
          icon={
            <Avatar
              src={attendanceData?.employeeId?.pic}
              name={attendanceData?.employeeId?.name}
              size="md"
              borderRadius={"lg"}
            />
          }
        />
        <BasicInfoComponent
          heading={t("department")}
          label1={attendanceData?.employeeId?.department || '--'}
          label2={attendanceData?.employeeId?.designation || '--'}
          icon={
            <div className='bg-[#f2f2ff] p-3 rounded-lg'>
              <GoOrganization
                size={20}
                color='#4f45e4'
              />
            </div>
          }
        />
        <BasicInfoComponent
          heading={t("joining-date")}
          label1={formatDateToReadableString(attendanceData?.employeeId?.joiningDate, isArabic) || '--'}
          label2={""}
          icon={
            <div className='bg-[#f2f2ff] p-3 rounded-lg'>
              <CiCalendarDate
                size={20}
                color='#4f45e4'
              />
            </div>
          }
        />
        <div className={`flex flex-col ${isArabic ? 'items-end' : 'items-start'} gap-2`}>
          <div className={`flex items-center gap-1 bg-[#f9fafb] py-2 px-4 rounded ${flDir}`}>
            <MdOutlineEmail />
            <span className='text-sm text-[#949aa3]  font-medium '>
              {attendanceData?.employeeId?.email || '--'}
            </span>
          </div>
          <div className={`flex items-center gap-1 bg-[#f9fafb] py-2 px-4 rounded ${flDir}`}>
            <CiPhone />
            <span className={`text-sm text-[#949aa3]  font-medium ${isArabic ? 'rtl-class' : 'ltr-class'}`}>
              {attendanceData?.employeeId?.mobile || '--'}
            </span>
          </div>
        </div>
      </div>

      {/* MONTHLY ATTENDANCE OVER VIEW  */}
      <div className={`w-full flex  items-center justify-start gap-2 lg:gap-4 py-4 flex-wrap ${flDir}`}>
        <DaysCard
          heading={t("total-days")}
          value={attendanceData?.totalDays || 0}
          bg={"#edf5fe"}
          iconColor="#2463eb"
          icon={<CiCalendarDate color='#2463eb' />}
        />
        <DaysCard
          heading={t("working-days")}
          value={attendanceData?.paidDays || 0}
          bg={"#eef2fe"}
          iconColor="#2463eb"
          icon={<CiCalendarDate color='#4f45e4' />}
        />
        <DaysCard
          heading={t("present-days")}
          value={attendanceData?.payableDays || 0}
          bg={"#effdf3"}
          iconColor="#2463eb"
          icon={<BsPersonCheck color='#16a349' />}
        />
        <DaysCard
          heading={t("overtime-hours")}
          value={attendanceData?.overTimeHours ? formatDailyAttendanceTime(attendanceData?.overTimeHours) : 0}
          bg={"#f7f2fc"}
          iconColor="#2463eb"
          icon={
            <FaRegClock color='#2463eb' />
          }
        />
        <DaysCard
          heading={t("total-absents")}
          value={attendanceData?.totalAbsent || 0}
          bg={"#fef1f2"}
          iconColor="#2463eb"
          icon={<BsPersonX color='#dc2625' />}
        />
        <DaysCard
          heading={t("total-leaves")}
          value={attendanceData?.totalLeave || 0}
          bg={"#fef6ec"}
          iconColor="#2463eb"
          icon={<CiCalendarDate color='#ea580b' />}
          onClickAttendance={() => {
            onOpenAttendance();
          }}
        />
        <DaysCard
          heading={t("total-hours")}
          value={attendanceData?.totalHours ? formatDailyAttendanceTime(attendanceData?.totalHours) : 0}
          bg={"#f7f2fc"}
          iconColor="#2463eb"
          icon={
            <FaRegClock color='#2463eb' />
          }
        />
      </div>

      {/* DAILY ATTENDANCE SECTION  */}
      <div className='bg-[#ffffff] border border-[#dfe7ff]  rounded-2xl'>
        <div className={`w-full border-b border-b-[#dfe7ff] p-4 flex ${isArabic ? 'items-end' : 'items-start'} flex-col  gap-2`}>
          <h1 className='text-xl lg:text-2xl text-[#1f2937]  font-bold '>
            {t('attendance-record')}
          </h1>
          <span className='text-xl lg:text-sm text-[#6c7381]  font-medium '>
            {t('attendance-msg')}
          </span>
        </div>
        <div className='w-full flex flex-col items-start gap-2 lg:gap-4 p-4'>
          {dailyAttendances?.length > 0 ? dailyAttendances?.map((attendance, index) => {
            return (
              <AttendanceAccordian
                key={index}
                attendance={attendance}
                isReportingHead={isReportingHead}
                isLoading={isLoading}
                onAttenDanceUpdate={(sessionId, _status) => {
                  approveAttendance(sessionId, _status)
                }}
                onMultipleUpdates={(sessionIds, _status) => {
                  approveMultipleSesions(sessionIds, _status);
                }}
              />
            )
          }) :
            <div className='w-full h-20 flex items-center justify-center'>
              <h1>{t('no-attendance-available')}</h1>
            </div>
          }
        </div>
      </div>

      {/* SHOW THE ATTENDANCE LEAVES DETAILS  */}
      <LeaveDialogue
        isOpen={isOpenAttendance}
        onClose={onCloseAttendance}
        onOpen={onOpenAttendance}
        leaves={attendanceData?.leaveDetails}
      />
    </Box >
  );
};

export default EmployeeAttendance;

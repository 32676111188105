import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    Input,
    FormLabel,
    Button,
    Select,
    useToast,
    Textarea,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import ReactSelect from 'react-select';

// ALL ROLES 
export const allDoctors = [
    { value: 'clinic-manager', label: 'Clinic Manager' } // Single static option
];

function AppointmentPaymentRequest({
    isOpen,
    onClose,
    formData,
    onHandleChange,
    isLoading,
    onHandleSubmit,
    label,
    isCorporateRequest
}) {
    // const { isOpen, onOpen, onClose } = useDisclosure()
    const { t, i18n } = useTranslation();
    const { user } = UserState();
    const toast = useToast();
    const isArabic = i18n.language === 'ar';
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    return (
        <>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign={isArabic ? 'right' : 'left'}>
                        {t(label)}
                    </ModalHeader>
                    <ModalCloseButton
                        style={{
                            [isArabic ? 'left' : 'right']: '10px' // Conditional positioning based on language
                        }}
                    />
                    <ModalBody pb={6}>
                        {/* SELECT APPOINTMENT DATE  */}
                        <FormControl>
                            <FormLabel textAlign={isArabic ? 'right' : 'left'}>{t('amount')}  <span className='text-red-600'>*</span></FormLabel>
                            <Input
                                value={formData?.amount || ""}
                                onChange={(e) => {
                                    onHandleChange('amount', e?.target?.value);
                                }}
                                type='number'
                                placeholder={t('enter-amount')}
                                ref={initialRef}
                            />
                        </FormControl>
                        {/* NO DUE DATE FOR CORPORATE ACCOUNTS */}
                        {/* SELECT PAYMENT DUE DATE   */}
                        {!isCorporateRequest &&
                            <FormControl mt={4}>
                                <FormLabel textAlign={isArabic ? 'right' : 'left'}>{t('select-due-date')}</FormLabel>
                                <Input
                                    type='date'
                                    ref={initialRef}
                                    value={formData?.paymentDueDate || ""}
                                    onChange={(e) => {
                                        const selectedDateTime = new Date(e?.target?.value); // Convert input value to Date object
                                        const currentDateTime = new Date(); // Get current date and time

                                        // Remove time part for both selected and current dates
                                        const selectedDate = new Date(selectedDateTime.setHours(0, 0, 0, 0));
                                        const currentDate = new Date(currentDateTime.setHours(0, 0, 0, 0));

                                        // Validate: Allow today and future dates, but prevent past dates
                                        if (selectedDate < currentDate) {
                                            toast({
                                                title: "Invalid Date Selection",
                                                description: t('select-future-d-t-msg'),
                                                status: "error",
                                                duration: 3000,
                                                isClosable: true,
                                                position: "top",
                                            });

                                            return; // Stop further execution if the date is invalid
                                        }

                                        onHandleChange('paymentDueDate', e?.target?.value);
                                    }}
                                />
                            </FormControl>
                        }
                        {/* ADDIITIONAL NOTE  */}
                        <FormLabel mt={4} textAlign={isArabic ? "right" : "left"}>
                            <FormLabel textAlign={isArabic ? "right" : "left"}>
                                {t('additional-note')}
                            </FormLabel>
                            <Textarea
                                value={formData?.paymentNote}
                                textAlign={isArabic ? "right" : "left"}
                                onChange={(e) => {
                                    onHandleChange('paymentNote', e?.target?.value);
                                }}
                                placeholder={t('add-note')}
                                resize="vertical"  // Optional: allows vertical resizing of the textarea
                            />
                        </FormLabel>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={4}
                            onClick={onClose}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={onHandleSubmit}
                            colorScheme='blue'
                            isLoading={isLoading}
                            isDisabled={!formData?.amount}
                        >
                            {t('submit')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    )
}

export default AppointmentPaymentRequest
import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Select,
    useDisclosure,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Avatar,
    Checkbox,
    GridItem,
    FormControl,
    FormLabel,
    Text,
    Input,
    Box,
    useToast,
    IconButton,
} from '@chakra-ui/react';
import PerformanceQuestionList from '../components/performance/PerformanceQuestionList';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { get } from '../../../services/apis/api';
import { UserState } from '../../../context/user';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { EditIcon } from '@chakra-ui/icons';

const AddPerformance = ({getAllEmployeePerformance, isEdit, performance}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [step, setStep] = useState('first');
    const [plans, setPlans] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [plan, setPlan] = useState({});
    const { user } = UserState();
    const toast = useToast();
    const [checkedEmployees, setCheckedEmployees] = useState([]); 
    const [selectAll, setSelectAll] = useState(false);
    const [error, setError] = useState("");
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 30)); 
    

    console.log(performance);
    
    
    const getAllPerformancePlans = async () => {
        try {
            const res = await get('performance/plan/dropdown');
            if (res.statusCode === 200) {
                const myPlans = res.data.data.map(plan => ({ 
                    value: plan._id, 
                    label: plan.name 
                }));
                setPlans(myPlans);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getEmployeesForPerformance = async () => {
        try {
            const res = await get(`performance/reporting-head-members?reportingHead=${user._id ? user._id : undefined}&performancePlan=${plan.value ? plan.value : undefined}`);
            setEmployees(res.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
      if(isEdit){
        setStep('third');
      }
    }, [])
    

    useEffect(() => {
        getAllPerformancePlans();
        if (step === "second") {
            getEmployeesForPerformance();
        }
    }, [step]);

    const handleCheckboxChange = (employeeId) => {
        setCheckedEmployees((prevChecked) => {
            if (prevChecked.includes(employeeId)) {
                return prevChecked.filter(id => id !== employeeId); // Remove if already checked
            } else {
                return [...prevChecked, employeeId]; // Add if not checked
            }
        });
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setCheckedEmployees([]); // Clear all selected if 'Select All' is unchecked
        } else {
            const allEmployeeIds = employees.map(emp => emp._id);
            setCheckedEmployees(allEmployeeIds); // Select all employee IDs
        }
        setSelectAll(!selectAll); // Toggle the select all state
    };

    const handleNextStep = () => {
        if (checkedEmployees.length === 0) {
            setError("Please select at least one employee to proceed."); // Show error message
            return;
        }
        setError(""); // Clear error message if valid
        setStep('third');
    };

    const handleDateChange = (date) => {
        const updatedDate = new Date(date.getFullYear(), date.getMonth(), 30);
        setStartDate(updatedDate); 
    };

    const moveTosecondStep = ()=>{
        if(plan.value){
            setStep('second');
        }else{
            toast({
                title: "Invalid Input",
                description: "Please select a Plan",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    }
    return (
        <>
            {
                isEdit ? 
                    <IconButton
                        ms={6}
                        icon={<EditIcon />}
                        onClick={onOpen}
                        aria-label="edit performace"
                    /> : 
                    <Button colorScheme='blue' onClick={onOpen} ms={2}>Add Performance</Button>
            }

            <Modal isOpen={isOpen} onClose={onClose} size={step === "first" ? "md" : step === "third" ? "3xl" : "6xl"}>
                <ModalOverlay />
                {step === "first" && (
                    <ModalContent>
                        <ModalHeader>Add Employee Performance</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <GridItem>
                                <FormControl>
                                    <FormLabel>{t('performance-plan')}<span className='text-red-500'>*</span></FormLabel>
                                    <ReactSelect
                                        name="performancePlan"
                                        placeholder={t('select-performance-plan')}
                                        options={plans}
                                        value={plans.find(item => item.value === plan.value) || null}
                                        onChange={(option) => setPlan(option)}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl mt={2}>
                                    <FormLabel>{t('select-Month')}<span className='text-red-500'>*</span></FormLabel>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={handleDateChange} // Ensure the 30th day is selected
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            className='w-full border rounded-md p-2'
                                        />
                                </FormControl>
                            </GridItem>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='ghost' mr={3} onClick={onClose}>Close</Button>
                            <Button colorScheme='blue' onClick={() => { moveTosecondStep() }}>Next</Button>
                        </ModalFooter>
                    </ModalContent>
                )}

                {step === 'second' && (
                    <ModalContent height={'80vh'} overflowY={'scroll'}>
                        <ModalHeader>Add Employee Performance</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {error && <Text color="red.500" mb={4}>{error}</Text>}
                            <Table className="text-sm text-left text-gray-500">
                                <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                    <Tr>
                                        <Th>
                                            <Checkbox
                                                isChecked={selectAll}
                                                onChange={handleSelectAllChange}
                                            />
                                        </Th>
                                        <Th>Profile</Th>
                                        <Th>Name</Th>
                                        <Th>Employee Code</Th>
                                        <Th>Designation</Th>
                                        <Th>Performance</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {employees.map((row) => (
                                        <Tr key={row._id}>
                                            <Td>
                                                <Checkbox
                                                    isChecked={checkedEmployees.includes(row._id)}
                                                    onChange={() => handleCheckboxChange(row._id)}
                                                />
                                            </Td>
                                            <Td><Avatar src={row.profile} name={row.name} /></Td>
                                            <Td>{row.name}</Td>
                                            <Td>{row.employeeCode}</Td>
                                            <Td>{row.designation}</Td>
                                            <Td className='text-orange-500'>not added</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='ghost' mr={3} onClick={()=>{setStep('first')}}>back</Button>
                            <Button
                                colorScheme='blue'
                                onClick={handleNextStep} // Validate before moving to the third step
                            >
                                Add Performance
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                )}

                {step === 'third' && (
                    <ModalContent height={'55vh'}>
                        <ModalHeader>Add Employee Performance</ModalHeader>
                        <ModalBody>
                            <PerformanceQuestionList plan={plan} setStep={setStep} checkedEmployees={checkedEmployees} onClose={onClose} startDate={startDate} getAllEmployeePerformance={getAllEmployeePerformance} isEdit={isEdit} performance={performance} />
                        </ModalBody>
                    </ModalContent>
                )}
            </Modal>
        </>
    );
};

export default AddPerformance;

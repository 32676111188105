import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Text,
    Divider,
    VStack,
    HStack,
    Tag,
    Flex,
    useDisclosure,
    ModalFooter,
    useToast,
    Spinner,
} from '@chakra-ui/react';
import { patch } from '../../services/apis/api';

const RequestDetailsModal = ({ request, getAllRequests }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);
    console.log(request);
    const toast = useToast();
    const [loading, setLoading] = useState();

    const handleCancelRequest = async () => {
        try {
            setLoading(true)
            const res = await patch(`request/update-employee-request/${request._id}`, { status: 'CANCELLED' });
            if(res.statusCode === 200){
                toast({
                    title: 'Successfull...!',
                    description: "Request has been Cancelled",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onConfirmClose();
                closeModal();
                setLoading(false);
                getAllRequests();
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }


    return (
        <>
            <button colorScheme="blue" onClick={openModal} className='text-white bg-blue-600 px-4 py-[6px] rounded-lg font-medium'>
                View
            </button>

            <Modal isOpen={isOpen} onClose={closeModal} size="lg">
                <ModalOverlay />
                <ModalContent borderRadius="lg" boxShadow="lg" overflow="hidden">
                    <ModalHeader bg="blue.500" color="white" fontSize="lg">
                        Request Details
                    </ModalHeader>
                    <ModalCloseButton color="white" />
                    <ModalBody py={6} px={8}>
                        <VStack align="stretch" spacing={6}>
                            <Box>
                                <Flex justify="space-between" align="center">
                                    <Box>
                                        <Text fontSize="sm" color="gray.500">
                                            Employee Name
                                        </Text>
                                        <Text fontWeight="bold">{request?.employeeId?.name}</Text>
                                    </Box>
                                    <Box>
                                        <Text fontSize="sm" color="gray.500">
                                            Employee Code
                                        </Text>
                                        <Text fontWeight="bold">{request?.employeeId?.employeeCode}</Text>
                                    </Box>
                                </Flex>
                            </Box>
                            <Divider />
                            <Box>
                                <Flex justify="space-between" align="center">
                                    <Box>
                                        <Text fontSize="sm" color="gray.500">
                                            Request Type
                                        </Text>
                                        <Text fontWeight="bold">{request?.requestType}</Text>
                                    </Box>
                                    <Box>
                                        <Text fontSize="sm" color="gray.500">
                                            Status
                                        </Text>
                                        <Tag
                                            size="md"
                                            colorScheme={
                                               ( request?.status === "REJECTED" || request?.status === "CANCELLED")
                                                    ? "red"
                                                    : request?.status === "APPROVED"
                                                        ? "green"
                                                        : "yellow"
                                            }
                                            fontWeight="bold"
                                        >
                                            {request?.status}
                                        </Tag>
                                    </Box>
                                </Flex>
                            </Box>
                            <Divider />
                            <Box>
                                <Text fontSize="sm" color="gray.500" mb={2}>
                                    Details
                                </Text>
                                <VStack align="start" spacing={2}>
                                    {/* Leave request */}
                                    {request?.details?.startDate && (
                                        <Text>
                                            <strong>Start Date:</strong> {request.details.startDate}
                                        </Text>
                                    )}
                                    {request?.details?.endDate && (
                                        <Text>
                                            <strong>End Date:</strong> {request.details.endDate}
                                        </Text>
                                    )}
                                    {request?.details?.leaveType && (
                                        <Text>
                                            <strong>Leave Type:</strong> {request.details.leaveType}
                                        </Text>
                                    )}
                                    {request?.details?.leaveReason && (
                                        <Text>
                                            <strong>Reason:</strong> {request.details.leaveReason}
                                        </Text>
                                    )}
                                    {/* for contract */}
                                    {request?.details?.previousContractEndDate && (
                                        <Text>
                                            <strong>Previous Contract EndDate:</strong> {request.details.previousContractEndDate}
                                        </Text>
                                    )}
                                    {request?.details?.contractStartDate && (
                                        <Text>
                                            <strong>New Contract StartDate:</strong> {request.details.contractStartDate}
                                        </Text>
                                    )}
                                    {request?.details?.contractEndDate && (
                                        <Text>
                                            <strong>New Contract EndDate:</strong> {request.details.contractEndDate}
                                        </Text>
                                    )}
                                    {request?.details?.contractDuration && (
                                        <Text>
                                            <strong>New Contract Duration:</strong> {request.details.contractDuration}
                                        </Text>
                                    )}
                                    {request?.details?.renewalReason && (
                                        <Text>
                                            <strong>Renewal Reason:</strong> {request.details.renewalReason}
                                        </Text>
                                    )}
                                    {/* salary Incriment Request */}
                                    {request?.details?.incrementPercentage && (
                                        <Text>
                                            <strong>Increment Percentage:</strong> {request.details.incrementPercentage}%
                                        </Text>
                                    )}
                                    {request?.details?.incrementAmount && (
                                        <Text>
                                            <strong>Total Amount After Increment:</strong> {request.details.incrementAmount}
                                        </Text>
                                    )}
                                    {request?.details?.incrementReason && (
                                        <Text>
                                            <strong>Increment Reason:</strong> {request.details.incrementReason}
                                        </Text>
                                    )}
                                    {/* traning request */}
                                    {request?.details?.traningStartDate && (
                                        <Text>
                                            <strong>Traning StartDate:</strong> {request.details.traningStartDate}
                                        </Text>
                                    )}
                                    {request?.details?.traningEndDate && (
                                        <Text>
                                            <strong>Traning EndDate:</strong> {request.details.traningEndDate}
                                        </Text>
                                    )}
                                    {request?.details?.letterContext && (
                                        <Text>
                                            <strong>Training Program Details:</strong> {request.details.letterContext}
                                        </Text>
                                    )}
                                    {/* Autherization letter */}
                                    {request?.details?.trainingDetails && (
                                        <Text>
                                            <strong> More Context about letter:</strong> {request.details.trainingDetails}
                                        </Text>
                                    )}
                                    {/* employee Certificate type */}
                                    {request?.details?.certificateType && (
                                        <Text>
                                            <strong>Certificate Type:</strong> {request.details.certificateType}
                                        </Text>
                                    )}
                                    {request?.details?.purposeOfCertificate && (
                                        <Text>
                                            <strong>Purpose Of Certificate:</strong> {request.details.purposeOfCertificate}
                                        </Text>
                                    )}
                                    {/* resignation request  */}
                                    {request?.details?.lastWorkingDay && (
                                        <Text>
                                            <strong>Last Working Day:</strong> {request.details.lastWorkingDay}
                                        </Text>
                                    )}
                                    {request?.details?.resignationReason && (
                                        <Text>
                                            <strong>Reasons for Resignation:</strong> {request.details.resignationReason}
                                        </Text>
                                    )}
                                    {request?.details?.fixationReason && (
                                        <Text>
                                            <strong>Specify the reason for the request:</strong> {request.details.fixationReason}
                                        </Text>
                                    )}
                                </VStack>

                            </Box>
                            <Divider />
                            <Box>
                                <Text fontSize="sm" color="gray.500">
                                    Created At
                                </Text>
                                <Text>{new Date(request?.createdAt).toLocaleString()}</Text>
                            </Box>
                            {
                                request.status != "CANCELLED" && 
                                    <Box display="flex" justifyContent="flex-end" onClick={onConfirmOpen}>
                                        <Button colorScheme="red">Cancel Request</Button>
                                    </Box>
                            }
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* confirm model  */}

            <Modal isOpen={isConfirmOpen} onClose={onConfirmClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirm Cancellation</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Are you sure you want to cancel this request?</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" onClick={onConfirmClose}>
                            No
                        </Button>
                        <Button disabled={loading} colorScheme="red" ml={3} onClick={handleCancelRequest}>
                            { loading ? <Spinner /> : "Yes, Cancel" }
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default RequestDetailsModal;

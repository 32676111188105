
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { get } from "../services/apis/api";

const About = () => {
  const [data, setData] = useState("[]");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await get("getAllAboutUs");
      setData(response.data.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  console.log(data);
  return (
    <div className="lg:mx-w-[1200px] grid grid-cols-1 sm:cols-2 md:cols-2 xl:cols-2 md-py-[80]  min-h-screen mx-auto lg:px-10 lg:py-3 px-2 py-2">
      <h1 className=" flex justify-center font-bold text-4xl mt-5 ">
        About Us
      </h1>
      <p className="flex justify-center items text-gray-400 mt-6 text-lg">
        Al Ibtisam Sports Club is a thriving hub for sports enthusiasts,
        offering a dynamic range <br /> of activities and facilities designed to
        promote health, fitness, and community engagement.
      </p>
      <div className="lg:mx-w-[1200px] max-w-auto grid grid-cols-1 sm:cols-1 md:cols-2 lg:cols-2 md-py-[80] lg:px-10 lg:py-3 px-3 py-3 sm:grid-cols-1 mt-10 justify-center items-center lg:space-y-5">
        {Array.isArray(data) &&
          data.map((item, index) => (
            <React.Fragment key={item._id}>
              {index % 2 === 0 || index === 0 ? (
                <div className="border-3  lg:flex lg:space-x-50 ">
                  <div className=" border-6 rounded mt-5">
                    <h1 className="lg:text-2xl text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 font-bold text-center mt-5 ">
                      {item.title}
                    </h1>
                    <h3 className=" text-lg  mt-2 lg:py-10 lg:mx-10 text-justify text-balance text-gray-500 ">
                      {item.subtitle}
                    </h3>
                  </div>
                  <img
                    src={item.image}
                    alt="image"
                    className="lg:w-[300px] lg:h-[300px] w-[250px] h-[250px] lg:rounded-full lg:mx-20 lg:gap-10 lg:mt-8 outline outline-white shadow-2xl shadow-black"
                  />
                </div>
              ) : (
                <div className="border-3  lg:flex lg:space-x-50">
                  <img
                    src={item.image}
                    alt="image"
                    className="lg:w-[300px] lg:h-[300px] w-96 h-96 mt-10 lg:rounded-full lg:mx-20 lg:gap-10 outline outline-white shadow-2xl shadow-black"
                  />
                  <div className=" border-6 rounded ">
                    <h1 className="lg:text-2xl text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 font-bold text-center mt-10 ">
                      {item.title}
                    </h1>
                    <h3 className=" text-lg  mt-1 lg:py-10 lg:mx-10 lg:text-justify text-balance text-gray-500 whitespace-nowrap">
                      {item.subtitle}
                    </h3>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default About
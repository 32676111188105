import React, { useState } from 'react'
import { UserState } from '../../context/user';
import { useTranslation } from 'react-i18next';
import { Th, Table, TableContainer, Thead, Tr, Td, Tbody, Button, Flex, Box, useToast, Tooltip } from '@chakra-ui/react';
import { CiCalendar, CiUser } from "react-icons/ci";
import { MdOutlineEmail } from "react-icons/md";
import { CiPhone } from "react-icons/ci";
import { MdOutlineVideogameAsset } from "react-icons/md";
import { PiFileText } from "react-icons/pi";
import { CiClock1 } from "react-icons/ci";
import { FaRegEye } from "react-icons/fa";
import { formatDateArEn, formatDateTimeArEn, getStatusColor } from '../../services/glocalFunctions';
import ConfirmApproveRequest from './ConfirmApproveRequest';
import { post } from '../../services/apis/api';
import ConfirmRejectRequest from './ConfirmRejectRequest';
import { MdUpdate } from "react-icons/md";

const ClinicTable = ({
    rows,
    columns,
    totalPages,
    onSchedule,
    page,
    handlePageChange,
    onNavigate,
    fetchUpdatedData,
    isHideFooter
}) => {
    const { user } = UserState();
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [reason, setReason] = useState('');

    // GET TYPE COLOR 
    const getTypeColor = (type) => {
        try {
            switch (type) {
                case 'external':
                    return 'bg-purple-100 text-purple-800'; // External type color
                case 'internal':
                    return 'bg-teal-100 text-teal-800'; // Internal type color
                case 'corporate':
                    return 'bg-orange-100 text-orange-800'; // Corporate type color
                default:
                    return 'bg-gray-100 text-gray-800'; // Default color for unknown types
            }
        } catch (e) {
            console.log(`ERROR WHILE GETTING THE TYPE COLOR ${e}`);
        }
    };

    // CHANGE THE REQUEST STATUS 
    const changeRequestStatus = async (_id, _status, rejectReason) => {
        try {
            const _body = {
                requestId: _id,
                status: _status,
                rejectReason: rejectReason || ''
            }
            const response = await post(`clinic/chage-request-status`, _body);
            if (response?.statusCode == 200) {
                fetchUpdatedData();
                setReason('');
                toast({
                    title: response?.data?.message || 'success',
                    description: "",
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: response?.data?.message || 'error',
                    description: "",
                    status: 'error',
                    duration: 6000,
                    isClosable: true,
                })
            }
        } catch (e) {
            toast({
                title: e?.response?.data?.message || 'error',
                description: "",
                status: 'error',
                duration: 6000,
                isClosable: true,
            })
            console.log(`ERROR WHILE CHANGING STATUS ${e}`);
        }
    }

    // CHANGE THE REQUEST LATEST LAST APPOINTMENT STATUS 
    const changeAppointmentStatus = async (_id, _status, reason) => {
        try {
            const _body = {
                appointmentId: _id,
                status: _status,
                reason: reason || ''
            }

            const response = await post(`clinic/chage-appointment-status`, _body);
            if (response?.statusCode == 200) {
                fetchUpdatedData();
                setReason('');
                toast({
                    title: response?.data?.message || 'success',
                    description: "",
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: response?.data?.message || 'error',
                    description: "",
                    status: 'error',
                    duration: 6000,
                    isClosable: true,
                })
            }
        } catch (e) {
            toast({
                title: e?.response?.data?.message || 'error',
                description: "",
                status: 'error',
                duration: 6000,
                isClosable: true,
            })
            console.log(`ERROR WHILE CHANGING STATUS ${e}`);
        }
    }

    return (
        <>
            <TableContainer shadow="md" borderRadius="lg" flex={1} display="flex" flexDirection="column">
                <Table dir={isArabic ? 'rtl' : 'ltr'} variant="simple">
                    <Thead bg="gray.50">
                        <Tr>
                            {columns?.length > 0 ? columns?.map((column, index) => (
                                <Th
                                    key={index}
                                >
                                    {t(column)}
                                </Th>
                            )) : t('no-columns-provided')}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rows?.length > 0 && (
                            rows?.map((request, index) => (
                                <Tr key={request?._id || index} _hover={{ bg: 'gray.50' }}>
                                    {/* APPOINTMENT ID COLUMN ONLY FOR APPOINTMENT  */}
                                    {columns?.length > 0 && columns?.includes('appointment-id') &&
                                        <Td>
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full`}>
                                                {request?.appointmentId || 'N/A'}
                                            </span>
                                        </Td>
                                    }
                                    {/* PLAYER INFO */}
                                    {columns?.length > 0 && columns?.includes('player-info') &&
                                        <Td>
                                            <div className={`flex items-start flex-col`}>
                                                <div className={`flex items-center gap-2  text-gray-900 text-base font-semibold`}>
                                                    <CiUser className="text-gray-400" />
                                                    {request?.userId?.name || request?.requestId?.userId?.name || '--'}
                                                </div>
                                                <div className={`flex items-center gap-2  text-gray-500 capitalize text-sm font-medium`}>
                                                    <MdOutlineVideogameAsset className="text-gray-400" />
                                                    {request?.userId?.gameId?.name || request?.requestId?.userId?.gamenId?.name || request?.userId?.role?.toLowerCase() || request?.requestId?.userId?.role?.toLowerCase() || '--'}
                                                </div>
                                                <div className={`flex items-center gap-2  text-gray-500 text-sm font-medium`}>
                                                    <CiPhone className="text-gray-400" />
                                                    {request?.userId?.mobile || request?.requestId?.userId?.mobile || '--'}
                                                </div>
                                            </div>
                                        </Td>
                                    }
                                    {/* REQUEST ID COLUMN ONLY FOR APPOINTMENT  */}
                                    {columns?.length > 0 && columns?.includes('request-id') &&
                                        <Td>
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full`}>
                                                {request?.requestId?.requestId || 'N/A'}
                                            </span>
                                        </Td>
                                    }
                                    {/* REQUEST INFO COLUMN ONLY FOR REQUEST */}
                                    {columns?.length > 0 && columns?.includes('request-info') &&
                                        <Td>
                                            <div className={`flex items-start flex-col`}>
                                                <Tooltip label={request?.injuryType} isDisabled={!(request?.injuryType?.length > 30)}>
                                                    <div className="flex items-center gap-2 text-gray-500 text-sm font-medium">
                                                        <PiFileText className="text-gray-400" />
                                                        {request?.injuryType?.length > 30
                                                            ? `${request?.injuryType?.slice(0, 30)}...`
                                                            : request?.injuryType || '--'}
                                                    </div>
                                                </Tooltip>
                                                <div className={`flex items-center gap-2  text-gray-500 text-xs font-medium`}>
                                                    <CiClock1 className="text-gray-400" />
                                                    {request?.createdAt ? formatDateArEn(request?.createdAt, isArabic) : 'N/A'}
                                                </div>
                                            </div>
                                        </Td>
                                    }
                                    {/* INJURY COLUMN ONLY FOR APPOINTMENT  */}
                                    {columns?.length > 0 && columns?.includes('injury') &&
                                        <Td>
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full`}>
                                                {request?.requestId?.injuryType || 'N/A'}
                                            </span>
                                        </Td>
                                    }
                                    {/* APPOINTMENT DATE COLUMN ONLY FOR APPOINTMENT */}
                                    {columns?.length > 0 && columns?.includes('appointment-date') &&
                                        <Td>
                                            <div className="text-gray-900 text-xs">
                                                {request?.appointmentDate ? (
                                                    <>
                                                        <div>{`${formatDateTimeArEn(request?.appointmentDate, isArabic)?.datePart}`}</div>
                                                        <div>{formatDateTimeArEn(request?.appointmentDate, isArabic)?.timePart}</div>
                                                    </>
                                                ) : (
                                                    'N/A'
                                                )}
                                            </div>
                                        </Td>
                                    }
                                    {/* REQUEST TYPE COLUMN ONLY FOR REQUEST  */}
                                    {columns?.length > 0 && columns?.includes('request-type') &&
                                        <Td>
                                            <span
                                                className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${request?.corporateRequest && request?.userAppointmentType?.toLowerCase() === 'external'
                                                    ? getTypeColor('corporate') // Apply the corporate color for external corporate requests
                                                    : getTypeColor(request?.userAppointmentType?.toLowerCase()) // Apply the color for other userAppointmentTypes
                                                    }`}
                                            >
                                                {request?.corporateRequest && request?.userAppointmentType?.toLowerCase() === 'external'
                                                    ? t('corporate') // Display "corporate" for external corporate requests
                                                    : t(request?.userAppointmentType?.toLowerCase()) // Display the translated userAppointmentType
                                                }
                                            </span>
                                        </Td>
                                    }
                                    {/* STATUS COLUMN  COMMON FOR BOTH REQUEST/APPOINTMENT */}
                                    <Td>
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(request?.status)}`}>
                                            {t(request?.status?.toLowerCase()) || 'N/A'}
                                        </span>
                                    </Td>

                                    {/* RECOVERY STATUS COLUMN  ONLY FOR APPOINTMENT */}
                                    {columns?.length > 0 && columns?.includes('recovery-status') &&
                                        <Td>
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(request?.requestId?.recoveryStatus || request?.recoveryStatus)}`}>
                                                {t(request?.recoveryStatus?.toLowerCase()) || 'N/A'}
                                            </span>
                                        </Td>
                                    }
                                    {/* PAYMENT STATUS COLUMN  COMMON FOR BOTH REQUEST/APPOINTMENT */}
                                    {columns?.length > 0 && columns?.includes('payment') &&
                                        <Td>
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${request.paymentStatus === 'COMPLETED'
                                                ? 'bg-green-100 text-green-800'
                                                : 'bg-yellow-100 text-yellow-800'
                                                }`}>
                                                {t(request.paymentStatus?.toLowerCase()) || 'N/A'}
                                            </span>
                                        </Td>
                                    }
                                    {/* INJURY COLUMN ONLY FOR APPOINTMENT  */}
                                    {columns?.length > 0 && columns?.includes('doctor-feedback') &&
                                        <Td>
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full`}>
                                                {request?.feedback || 'N/A'}
                                            </span>
                                        </Td>
                                    }
                                    {/* LAST APPOINTMENT STATUS COLUMN  ONLY FOR REQUEST */}
                                    {columns?.length > 0 && columns?.includes('last-appointment-status') &&
                                        <Td>
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(request?.currentLastAppointment?.status)}`}>
                                                {t(request?.currentLastAppointment?.status?.toLowerCase()) || 'N/A'}
                                            </span>
                                        </Td>
                                    }
                                    {/* LAST APPOINTMENT DATE COLUMN  ONLY FOR REQUEST */}
                                    {columns?.length > 0 && columns?.includes('next-appointment') &&
                                        <Td>
                                            <div className="text-gray-900 text-xs">
                                                {request?.currentLastAppointment?.appointmentDate ? (
                                                    <>
                                                        <div>{`📅 ${formatDateTimeArEn(request?.currentLastAppointment?.appointmentDate, isArabic)?.datePart}`}</div>
                                                        <div>{formatDateTimeArEn(request?.currentLastAppointment?.appointmentDate, isArabic)?.timePart}</div>
                                                    </>
                                                ) : (
                                                    'N/A'
                                                )}
                                            </div>
                                        </Td>
                                    }
                                    <Td>
                                        <div className={`flex gap-4 `}>
                                            <Tooltip label={t('view-request')} aria-label="Reject tooltip">
                                                <button
                                                    className="text-gray-600 hover:text-gray-800"
                                                    onClick={() => {
                                                        onNavigate(request)
                                                    }}
                                                >
                                                    <FaRegEye className='h-5 w-5' />
                                                </button>
                                            </Tooltip>
                                            {request?.status?.toLowerCase() == 'pending_approval' ?
                                                <>
                                                    <ConfirmApproveRequest
                                                        handleApprove={() => {
                                                            changeRequestStatus(request?._id, 'APPROVED');
                                                        }}
                                                    />
                                                    <ConfirmRejectRequest
                                                        toolLabel="reject-request"
                                                        label="reject-request"
                                                        buttonLabel="reject"
                                                        handleSubmit={() => {
                                                            changeRequestStatus(request?._id, 'REJECTED', reason);
                                                        }}
                                                        message="reject-request-msg"
                                                        reason={reason}
                                                        setReason={setReason}
                                                    />
                                                </>
                                                : request?.status?.toLowerCase() == 'approved' ? // || request?.currentLastAppointment?.status?.toLowerCase() == 'cancelled'
                                                    <button
                                                        className="text-blue-600 hover:text-blue-800"
                                                        onClick={() => {
                                                            onSchedule(request);
                                                        }}
                                                    >
                                                        <CiCalendar className="h-5 w-5" />
                                                    </button>
                                                    : request?.status?.toLowerCase() == 'open' ?
                                                        <>
                                                            <Tooltip label={t('reschedule-appointment')} aria-label="Reject tooltip">
                                                                <button
                                                                    className="text-yellow-600 hover:text-yellow-800 flex items-center gap-1"
                                                                    onClick={() => onSchedule(request)}
                                                                >
                                                                    <MdUpdate className="h-5 w-5" />
                                                                </button>
                                                            </Tooltip>
                                                            {/* CONFIRM CANCEL REQUEST  */}
                                                            <ConfirmRejectRequest
                                                                toolLabel="cancel-appointment"
                                                                label="cancel-appointment"
                                                                buttonLabel="cancel"
                                                                handleSubmit={() => {
                                                                    changeAppointmentStatus(request?.currentLastAppointment?._id, 'CANCELLED', reason);
                                                                }}
                                                                message="cancel-request-msg"
                                                                reason={reason}
                                                                setReason={setReason}
                                                            />
                                                        </>
                                                        : null}

                                            {/* {request?.status?.toLowerCase() != 'closed' &&
                                                <button
                                                    className="text-blue-600 hover:text-blue-800"
                                                    onClick={() => {
                                                        onSchedule(request);
                                                        // onOpen();
                                                    }}
                                                >
                                                    <CiCalendar className="h-5 w-5" />
                                                </button>
                                            } */}

                                        </div>
                                    </Td>
                                </Tr>
                            ))
                        )}
                    </Tbody>
                </Table>
                {rows ?
                    rows?.length <= 0 &&
                    <div className='w-full  min-h-80 flex items-center justify-center'>
                        <h1 className='text-center'>{t('no-data-found')}</h1>
                    </div>
                    :
                    <div className='w-full  min-h-80 flex items-center justify-center'>
                        <h1 className={`text-center `}>
                            {isArabic && '....'} {t('loading')} {!isArabic && '....'}
                        </h1>

                    </div>
                }
            </TableContainer >
            {rows?.length > 0 && !isHideFooter &&
                <Flex justify="space-between" align="center" mt={4}>
                    <Button
                        onClick={() => handlePageChange(page - 1)}
                        isDisabled={page === 0}
                    >
                        {t('previous')}
                    </Button>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isArabic ? 'row-reverse' : 'row',
                            gap: 1
                        }}
                    >
                        <h1>{t('page')}</h1>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isArabic ? 'row-reverse' : 'row',
                                gap: 1
                            }}
                        >
                            {page + 1}

                            <h1>{t('of')}</h1>
                            {totalPages}
                        </ Box>
                    </Box>
                    <Button
                        onClick={() => handlePageChange(page + 1)}
                        isDisabled={page + 1 >= totalPages}
                    >
                        {t('next')}
                    </Button>
                </Flex>
            }
        </>
    )
}

export default ClinicTable

import React, { useEffect, useState } from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, useDisclosure, FormControl, FormLabel, Input, SimpleGrid, GridItem, Select, Textarea, useToast, Spinner} from '@chakra-ui/react'
import { get, post } from '../../services/apis/api';
import ReactSelect from 'react-select';
const CreateTournament = ({getAllTournament}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [formValues, setFormValues] = useState({
        name: '',
        teams: [],
        matches: '',
        startDate: '',
        endDate: '',
        status: 'upcoming',
        description: '',
    });
    
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const toast = useToast();
    
    const getAllTeams = async()=>{
        try {
            const res = await get('team/dropdown');
            if(res.statusCode===200){
                setTeams(res.data.dropdown.map(team => ({ label: team.name, value: team._id })));
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getAllTeams();
    }, []);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleTeamsChange = (selectedOptions) => {
        setSelectedTeams(selectedOptions);
        setFormValues({ ...formValues, teams: selectedOptions.map(option => option.value) });
    };

    const handleSubmit = async (e) => {
        try {
            setLoading(true)
            const res = await post('tournament/add', formValues); 
            if (res.statusCode === 201) {
                toast({
                    title: 'Tournament created.',
                    description: 'The tournament has been created successfully.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onClose();
                getAllTournament();
                setFormValues({ name: '', teams: [], matches: '', startDate: '', endDate: '', status: 'upcoming', description: '', winnerTeam: '', organizationId: '', createdBy: ''});
                setSelectedTeams([]);
            } else {
                toast({
                    title: 'Error creating tournament.',
                    description: res.data.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error creating tournament:', error);
            toast({
            title: 'Error creating tournament.',
            description: "Please fill all field...!",
            status: 'error',
            duration: 5000,
            isClosable: true,
            });
        }
    };

    return (
        <>
        <Button onClick={onOpen}>Create Tournament</Button>
    
        <Modal isOpen={isOpen} onClose={onClose} size={'5xl'}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>Tournament Details</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <SimpleGrid columns={3} spacing={4}>
                <GridItem colSpan={1}>
                <FormControl isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input
                    name="name"
                    value={formValues.name}
                    onChange={handleChange}
                    placeholder="Tournament Name"
                    />
                </FormControl>
                </GridItem>

                <GridItem colSpan={1}>
                <FormControl isRequired>
                    <FormLabel>Teams</FormLabel>
                    <ReactSelect
                    isMulti
                    options={teams}
                    value={selectedTeams}
                    onChange={handleTeamsChange}
                    placeholder="Select Teams"
                    />
                </FormControl>
                </GridItem>

                <GridItem colSpan={1}>
                <FormControl isRequired>
                    <FormLabel>Matches</FormLabel>
                    <Input
                    name="matches"
                    type="number"
                    value={formValues.matches}
                    onChange={handleChange}
                    placeholder="Number of Matches"
                    />
                </FormControl>
                </GridItem>

                <GridItem colSpan={1}>
                <FormControl isRequired>
                    <FormLabel>Start Date</FormLabel>
                    <Input
                    name="startDate"
                    type="date"
                    value={formValues.startDate}
                    onChange={handleChange}
                    />
                </FormControl>
                </GridItem>

                <GridItem colSpan={1}>
                <FormControl isRequired>
                    <FormLabel>End Date</FormLabel>
                    <Input
                    name="endDate"
                    type="date"
                    value={formValues.endDate}
                    onChange={handleChange}
                    />
                </FormControl>
                </GridItem>

                <GridItem colSpan={1}>
                <FormControl isRequired>
                    <FormLabel>Status</FormLabel>
                    <Select
                    name="status"
                    value={formValues.status}
                    onChange={handleChange}
                    placeholder="Select Status"
                    >
                    <option value="upcoming">Upcoming</option>
                    <option value="ongoing">Ongoing</option>
                    <option value="completed">Completed</option>
                    </Select>
                </FormControl>
                </GridItem>

                <GridItem colSpan={3}>
                <FormControl>
                    <FormLabel>Description</FormLabel>
                    <Textarea
                    name="description"
                    value={formValues.description}
                    onChange={handleChange}
                    placeholder="Description"
                    />
                </FormControl>
                </GridItem>


                
            </SimpleGrid>

            </ModalBody>
            <ModalFooter>
                <Button colorScheme='gray' mr={3} onClick={onClose}>
                    Close
                </Button>
                <Button onClick={()=>{handleSubmit()}} colorScheme='blue'>{loading ? <Spinner /> : "Save"}</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
        </>
    )
}


export default CreateTournament
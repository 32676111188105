import React from 'react'
import { Routes, Route } from "react-router-dom";
import CommanNav from '../components/globles/CommanNav';
import Home from '../pages/operations/Home';
import Expenses from '../pages/account/Expenses';
import FinancialRequests from '../pages/account/FinancialRequests';
import EarningsAndIncome from '../pages/account/EarningsIncomes';
import Payroll from '../pages/account/Payroll';
import CategoryDetail from '../pages/account/CategoryDetail';
import AdditionalIncome from '../pages/account/AdditionalIncome';
import ReportsAndSummery from '../pages/account/Reports&Summery';
import Assets from '../pages/account/Assets';
import ExpensesList from '../pages/account/ExpensesList';
import AdditionalExpenses from '../pages/account/AdditionalExpense';

const Account = () => {
  return (
    <Routes>
      <Route path="/account" element={<CommanNav />}>
        <Route index element={<Home />} />
        <Route path='request' element={<FinancialRequests />} />
        <Route path='expenses'>
          <Route index element={<Expenses />} />
          <Route path=':id' element={<ExpensesList />} />
        </Route>
        <Route path='payroll' element={<Payroll />} />
        <Route path='assets' element={<Assets />} />
        <Route path='reports' element={<ReportsAndSummery />} />
        <Route path='additional-income' element={<AdditionalIncome />} />
        <Route path='additional-expenses' element={<AdditionalExpenses />} />
        <Route path='earnings'>
          <Route index element={<EarningsAndIncome />} />
          <Route path=':id' element={<CategoryDetail />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default Account


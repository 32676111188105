import React from 'react';
import Chart from 'react-apexcharts';

const PerformanceDashboard = ({ data, performers }) => {
    // Chart options for horizontal bar chart
    const chartOptions = {
        chart: {
            type: 'bar',
            height: 300,
        },
        colors: ['#3a416f'], // Updated color for bars
        plotOptions: {
            bar: {
                horizontal: true, // Horizontal bar design to match the image
                barHeight: '40%', // Adjust bar height for better spacing
                endingShape: 'rounded',
            },
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '12px',
                colors: ['#fff'],
            },
        },
        xaxis: {
            categories: data?.map(item => item._id),
        title: {
                text: 'Performance Score',
            },
            min: 0,
            max: 100, // Adjust scale to match expected range
        },
        yaxis: {
            title: {
                text: 'Departments',
            },
        },
        tooltip: {
            enabled: true,
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f4f6', 'transparent'], // Alternating background for rows
                opacity: 0.5,
            },
        },
    };

    // Data series for department performance scores
    const chartSeries = [
        {
            name: 'Avg Performance Score',
            data: data.map(item => item.averagePerformancePercentage),
        },
    ];

    // Top performers data by department (simplified)
    

    return (
        <div className="flex flex-col lg:flex-row gap-6 p-6 bg-gray-100">
            {/* Horizontal Bar Chart */}
            <div className="w-full lg:w-1/2 bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-lg font-semibold mb-4">Performance Overview by Department</h2>
                <Chart options={chartOptions} series={chartSeries} type="bar" height={350} />
            </div>

            {/* Top Performers Table */}
            <div className="w-full lg:w-1/2 bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-lg font-semibold mb-4">Top Performers by Department</h2>
                <table className="w-full text-left">
                    <thead>
                        <tr>
                            <th className="p-2 border-b-2 border-gray-200">Employee Name</th>
                            <th className="p-2 border-b-2 border-gray-200">Department</th>
                            <th className="p-2 border-b-2 border-gray-200">Achieved Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {performers?.map((performer, index) => (
                            <tr key={index} className="hover:bg-gray-100">
                                <td className="p-2 border-b border-gray-200">{performer?._id}</td>
                                <td className="p-2 border-b border-gray-200">{performer?.topPerformer?.name}</td>
                                <td className="p-2 border-b border-gray-200">{performer?.topPerformer?.performance}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PerformanceDashboard;

import React, { useEffect, useState } from 'react';
import CreateGame from '../../components/dialogueBoxes/CreateGame';
import { get } from '../../services/apis/api';
import _ from 'lodash';
import { Tooltip } from '@chakra-ui/react';
import { truncateText } from '../../utills/truncateText';
import { useTranslation } from 'react-i18next';

const Game = () => {
    const [games, setGames] = useState([]);
    const [search, setSearch] = useState('');
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const getGames = async (searchQuery = '') => {
        try {
            const res = await get(`game/all${searchQuery ? `?search=${searchQuery}` : ''}`);
            console.log(res);
            setGames(res.data.games);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getGames();
    }, []);

    // Debounced search function
    const debouncedSearch = _.debounce((query) => {
        getGames(query);
    }, 300);

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearch(query);
        debouncedSearch(query);
    };

    return (
        <>
            <div className={`flex justify-between px-2 items-center mb-4 ${isArabic ? "flex-row-reverse" : "row"}`}>
                <div>
                    <div className="relative">
                        <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                            <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                        <input
                            type="text"
                            id="table-search-users"
                            className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                            placeholder={t("search-for-games")}
                            value={search}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>
                <div>
                    <CreateGame getGames={getGames} isEdit={false} />
                </div>
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg" dir={isArabic ? 'rtl' : 'ltr'}>
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50" >
                        <tr>
                            <th scope="col" className="px-16 py-3">
                                <span className="sr-only">Image</span>
                            </th>
                            <th scope="col" className="px-6 py-3">
                                {t("game-name")}
                            </th>
                            <th scope="col" className="px-6 py-3">
                                {t("stage")}
                            </th>
                            <th scope="col" className="px-6 py-3">
                                {t("category")}
                            </th>
                            <th scope="col" className="px-6 py-3">
                                {t("action")}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            games.map((game, index) => (
                                <tr key={index} className="w-full bg-white border-b hover:bg-gray-50">
                                    <td className="p-4 w-32 h-32">
                                        <img src={game.icon} className="object-cover h-full rounded max-w-full max-h-full" alt="Game Icon"/>
                                    </td>
                                    <td className="px-6 py-4 font-semibold text-gray-900">
                                        {
                                        isArabic? game.arabicName:
                                        game.name}
                                    </td>
                                    <td className="px-6 py-4">
                                        {game?.stage.join(", ")}
                                    </td>
                                    <td className="px-6 py-4">
                                        <Tooltip label={game?.ageCategoryIds.map(cat => cat.name).join(", ")}>
                                            <span>
                                                {truncateText(game.ageCategoryIds.length!=0 ? game?.ageCategoryIds.map(cat => cat.name).join(", ") : "-", 30)}
                                            </span>
                                        </Tooltip>
                                    </td>
                                    <td className="px-6 py-4">
                                        <CreateGame getGames={getGames} game={game} isEdit={true} />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Game;

import { Spinner, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { get, patch } from '../../services/apis/api';
import CustomAlert from '../alerts/CustomAlert';
import { IoMdArrowRoundBack } from 'react-icons/io';
import CreateCoach from '../dialogueBoxes/CreateCoach';
import { UserState } from '../../context/user';
import { useTranslation } from 'react-i18next';

const statusRoleMapping = {
    'ONLINE-STORE-MANAGER-REVIEW': 'APPROVED',
};

const roleToTypeAndStatus = {
    'ONLINE-STORE-MANAGER': 'ONLINE-STORE-MANAGER-REVIEW',
};

const ViewBuyPlayerProductRequest = ({ requestId, setView, user, getAllRequest }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const [requestAction, setRequestAction] = useState(false);
    const [request, setRequest] = useState({});
    const [canApprove, setCanApprove] = useState(false);
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const getRequestDetails = async () => {
        const res = await get(`request/buy-product-for-player/get/${requestId}`);
        if (res.statusCode === 200) {
            setRequest(res.data.request);
            const userCanApprove = roleToTypeAndStatus[user?.role] === res.data.request.status;
            setCanApprove(userCanApprove);
        }
    };

    useEffect(() => {
        getRequestDetails();
    }, []);

    const handleApprove = async () => {
        try {
            const nextStatus = statusRoleMapping[request.status];
            console.log("next status---->", nextStatus);
            if (!nextStatus) {
                toast({
                    title: "Error",
                    description: "Cannot approve this request.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }
            setLoading(true);
            const res = await patch(`request/approved/buy-product-for-player/${requestId}`, { status: nextStatus });
            setLoading(false);
            if (res.statusCode === 200) {
                getRequestDetails();
                getAllRequest();
            } else {
                toast({
                    title: "Error",
                    description: "Failed to approve the request.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Error",
                description: error.response.data.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }

    };

    const handleReject = async () => {
        try {
            setLoading(true);
            const res = await patch(`request/update/buy-product-for-player/${requestId}`, { status: "REJECTED", rejectedBy: user._id });
            if (res.statusCode === 200) {
                setLoading(false);
                getRequestDetails();
                getAllRequest();
                onClose();
                toast({
                    title: "Success",
                    description: "Request has been rejected.",
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                onClose();
                toast({
                    title: "Error",
                    description: "Failed to reject the request.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Error",
                description: error.response.data.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const confirmApprove = () => {
        handleApprove();
        onClose();
    };

    const handleApproveRejectRequest = (type) => {
        if (type === "APPROVED") {
            setRequestAction(true);
            onOpen();
        } else {
            setRequestAction(false);
            onOpen();
        }
    };

    return (
        <div className="bg-white p-6 rounded-lg shadow-md mx-auto" >
            <div className='w-full flex justify-between items-center mb-4'>
                <button onClick={() => setView(false)} className='bg-blue-600 text-white px-4 py-2 font-medium text-base rounded-md flex items-center'>
                    <IoMdArrowRoundBack className="mr-2" /> {t('back')}
                </button>
                {canApprove && (
                    <div>
                        <button onClick={() => handleApproveRejectRequest('REJECTED')} className='bg-red-600 text-white px-4 py-2 font-medium text-base rounded-md mr-2'>
                            {loading ? <Spinner /> : t("reject-request")}
                        </button>
                        <button onClick={() => handleApproveRejectRequest('APPROVED')} className='bg-blue-600 text-white px-4 py-2 font-medium text-base rounded-md'>
                            {loading ? <Spinner /> : t("approve-request")}
                        </button>
                    </div>
                )}
                {request.status === "HR-MANAGER-HIRING" && <CreateCoach byHr={true} requestId={requestId} getRequestDetails={getRequestDetails} />}
            </div>
            <div className='' dir={isArabic ? 'rtl' : 'ltr'}>
                <div className="mb-4">
                    <h2 className="text-2xl font-bold text-gray-800 mb-2 flex">{t("request-details")}</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <div className="bg-gray-100 p-4 rounded-md flex justify-start">
                            <h3 className="font-semibold text-lg">{t("requested-by")} : </h3>
                            <p className="text-gray-700">{request?.requestedBy?.name}</p>
                        </div>
                        <div className="bg-gray-100 p-4 rounded-md flex justify-start">
                            <h3 className="font-semibold text-lg">{t('request-status')} :</h3>
                            <p className={`text-lg font-medium ${request?.status === "APPROVED" ? 'text-green-600' : request?.status === "REJECTED" ? 'text-red-600' : 'text-yellow-600'}`}>
                                {request?.status}
                            </p>
                        </div>
                        <div className="bg-gray-100 p-4 rounded-md flex justify-start">
                            <h3 className="font-semibold text-lg">{t('request-type')} :</h3>
                            <p className="text-gray-700">{request?.kind}</p>
                        </div>
                        <div className="bg-gray-100 p-4 rounded-md flex justify-start">
                            <h3 className="font-semibold text-lg">{t('description')} : </h3>
                            <p className="text-gray-700">{request?.description}</p>
                        </div>
                    </div>
                </div>

                <div className="mb-4">
                    <h2 className="text-xl font-semibold text-gray-800 mb-2 flex">{t('players-involved')}</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                        {request?.playerIds?.map(player => (
                            <div key={player?._id} className="bg-gray-50 p-4 rounded-md shadow-sm">
                                <h3 className="font-semibold text-lg text-gray-800">{player?.name}</h3>
                                <p className="text-gray-600">{t('shoe-size')} : {player?.shoeSize}</p>
                                <p className="text-gray-600">{t('t-shirt-Size')} : {player?.tshirtSize}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mb-4">
                    <h2 className="text-xl font-semibold text-gray-800 mb-2 flex">{t('products-requested')}</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                        {request?.productIds?.map(product => (
                            <div key={product?.productId?._id} className="bg-gray-50 p-4 rounded-md shadow-sm">
                                <h3 className="font-semibold text-lg text-gray-800">{product?.productId?.name}</h3>
                                <div className="mt-2">
                                    <img key={0} src={product?.productId?.images[0]} alt={product?.productId?.name} className="rounded-md object-cover h-45 w-full" />
                                </div>
                                {product.customization !== '' &&
                                    <div className="mt-2">
                                        {t('customization')}: {product?.customization}
                                    </div>}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {
                requestAction ? 
                    <CustomAlert
                        isOpen={isOpen}
                        onClose={onClose}
                        title={t("approve-request")}
                        description={t("confirm-approve-request")}
                        onConfirm={confirmApprove}
                        confirmButtonText={t("confirm")}
                        cancelButtonText={t("cancel")}
                    /> 
                    : 
                    <CustomAlert
                        isOpen={isOpen}
                        onClose={onClose}
                        title={t("reject-request")}
                        description={t("confirm-reject-request")}
                        onConfirm={handleReject}
                        confirmButtonText={t("confirm")}
                        cancelButtonText={t("cancel")}
                    />
            }
        </div>
    );
};


export default ViewBuyPlayerProductRequest

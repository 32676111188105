import React, { useState, useEffect } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    useToast,
    Input,
    Select,
} from '@chakra-ui/react';
import ReactSelect from 'react-select'; // Importing ReactSelect
import { get, post } from '../../../services/apis/api';
import { getAssetConditions } from '../../../utills/assetsConditions';
import { AssetState } from '../../../context/assets';

const AssignAsset = ({ getAssetAssignments }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const {assetRefresh,setAssetRefresh } = AssetState()
    const [employees, setEmployees] = useState([]);
    const [assets, setAssets] = useState([]);
    const [formData, setFormData] = useState({
        assetId: '',
        employeeId: '',
        assignDate: '',
        conditionAtAssigning: 'excellent',
    });
    const [conditions, setConditions] = useState([]);

    // Fetch Employees
    const fetchEmployees = async () => {
        try {
            const res = await get('employee/dropdown');
            if (res.statusCode === 200) {
                const employeeOptions = res.data.dropdown.map((employee) => ({
                    value: employee._id,
                    label: employee.name,
                }));
                setEmployees(employeeOptions);
            }
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    // Fetch Assets
    const fetchAssets = async () => {
        try {
            const res = await get('assets/dropdown?status=available');
            if (res.statusCode === 200) {
                const assetOptions = res.data.data.map((asset) => ({
                    value: asset._id,
                    label: `${asset.name} - ${asset.serialNumber}`,
                }));
                setAssets(assetOptions);
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        }
    };

    const fetchAssetsDeatails = async () => {
        try {
            const res = await get(`assets/get/${formData.assetId}`);
            if (res.statusCode === 200) {
                setConditions(getAssetConditions(res.data.data.condition))
                setFormData({
                    ...formData,
                    conditionAtAssigning: res.data.data.condition,
                });
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        }
    };

    useEffect(() => {
        fetchEmployees();
        fetchAssets();
    }, []);

    useEffect(() => {
        fetchAssetsDeatails();
    }, [formData.assetId])
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectChange = (selectedOption, { name }) => {
        setFormData({
            ...formData,
            [name]: selectedOption ? selectedOption.value : '',
        });
    };

    const handleSubmit = async () => {
        try {
            const response = await post('assets/assign-asset-to-employee', formData);
            if (response.statusCode === 201 || response.statusCode === 200) {
                toast({
                    title: 'Asset Assigned.',
                    description: 'The asset has been successfully assigned to the employee.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onClose();
                getAssetAssignments();
                setAssetRefresh(formData.assetId);
                setFormData({
                    assetId: '',
                    employeeId: '',
                    assignDate: '',
                    conditionAtAssigning: 'excellent',
                });
            } else {
                toast({
                    title: 'Error',
                    description: 'Failed to assign the asset.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: error.response.data.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <div>
            <Button onClick={onOpen} colorScheme="blue">
                Assign Asset
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Assign Asset to Employee</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl id="assetId" isRequired>
                            <FormLabel>Asset</FormLabel>
                            <ReactSelect
                                name="assetId"
                                options={assets}
                                value={assets.find((option) => option.value === formData.assetId)}
                                onChange={handleSelectChange}
                                placeholder="Select asset"
                            />
                        </FormControl>

                        <FormControl id="employeeId" isRequired mt={4}>
                            <FormLabel>Employee</FormLabel>
                            <ReactSelect
                                name="employeeId"
                                options={employees}
                                value={employees.find((option) => option.value === formData.employeeId)}
                                onChange={handleSelectChange}
                                placeholder="Select employee"
                            />
                        </FormControl>

                        <FormControl id="assignDate" isRequired mt={4}>
                            <FormLabel>Assign Date</FormLabel>
                            <Input
                                type="date"
                                name="assignDate"
                                value={formData.assignDate}
                                onChange={handleInputChange}
                                required
                            />
                        </FormControl>

                        <FormControl id="conditionAtAssigning" isRequired mt={4}>
                            <FormLabel>Condition at Assigning</FormLabel>
                            <Select
                                name="conditionAtAssigning"
                                value={formData.conditionAtAssigning}
                                onChange={handleInputChange}
                                placeholder="Select condition"
                            >
                                {
                                    conditions?.map((item)=>(
                                        <option value={item}>{item}</option>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" mx={3} onClick={handleSubmit}>
                            Submit
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default AssignAsset;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { leaveFrequencyOptions } from '../../services/glocalFunctions';

const RequestBody = ({ requestType, additionalFields, setAdditionalFields }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const labelAlign = isArabic ? 'text-right' : 'text-left';

    useEffect(() => {

    }, [additionalFields])

    switch (requestType) {
        case 'Contract Renewal Request':
            const handleDateChange = () => {
                try {
                    if (!additionalFields.contractStartDate || !additionalFields.contractEndDate) {
                        return;
                    }
                    const startDate = new Date(additionalFields.contractStartDate);
                    const endDate = new Date(additionalFields.contractEndDate);

                    // Calculate duration in months
                    if (startDate && endDate) {
                        const duration = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth();
                        console.log(duration);

                        setAdditionalFields({ ...additionalFields, contractDuration: duration });
                    }
                } catch (e) {
                    console.log(`ERROR WHILE CHANGING DATE ${e}`);
                }
            };

            return (
                <div className="mb-4">
                    <label className={`block text-gray-600 dark:text-gray-300 font-medium mb-2 ${labelAlign}`}>
                        {t('previous-contract-end-date')}
                    </label>
                    <input
                        type="date"
                        value={additionalFields.previousContractEndDate || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                previousContractEndDate: e?.target?.value,
                            })
                        }
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />

                    <label className={`block text-gray-600 dark:text-gray-300 font-medium mt-2 ${labelAlign}`}>
                        {t('new-contract-start-date')}
                    </label>
                    <input
                        type="date"
                        value={additionalFields.contractStartDate || ''}
                        onChange={(e) => {
                            setAdditionalFields({
                                ...additionalFields,
                                contractStartDate: e?.target?.value,
                            });
                            handleDateChange();
                        }}
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />

                    <label className={`block text-gray-600 dark:text-gray-300 font-medium mt-2 ${labelAlign}`}>
                        {t('new-contract-end-date')}
                    </label>
                    <input
                        type="date"
                        value={additionalFields.contractEndDate || ''}
                        onChange={(e) => {
                            setAdditionalFields({
                                ...additionalFields,
                                contractEndDate: e?.target?.value,
                            });
                            handleDateChange();
                        }}
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />

                    <label className={`block text-gray-600 dark:text-gray-300 font-medium mt-2 ${labelAlign}`}>
                        {t('contract-duration')} ({t('months')})
                    </label>
                    <input
                        type="text"
                        value={additionalFields.contractDuration || ''}
                        readOnly
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        placeholder={t('contract-duration-in-months')}
                    />

                    <label className={`block text-gray-600 dark:text-gray-300 font-medium mt-2 ${labelAlign}`}>
                        {t('reasons-for-renewal')}
                    </label>
                    <textarea
                        value={additionalFields.renewalReason || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                renewalReason: e?.target?.value,
                            })
                        }
                        className={`w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white ${labelAlign}`}
                    ></textarea>
                </div>
            );
        case 'Salary Increment Request':
            return (
                <div>
                    {/* Increment Percentage Field */}
                    <div className="mb-2">
                        <label className={`${labelAlign} block text-gray-600 dark:text-gray-300 font-medium mb-1`}>
                            {t('increment-percentage')}
                        </label>
                        <input
                            type="number"
                            value={additionalFields.incrementPercentage || ''}
                            onChange={(e) =>
                                setAdditionalFields({
                                    ...additionalFields,
                                    incrementPercentage: e.target.value,
                                })
                            }
                            className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            placeholder={t('enter-increment-percentage')}
                        />
                    </div>

                    {/* Increment Amount Field */}
                    <div className="mb-2">
                        <label className={`${labelAlign} block text-gray-600 dark:text-gray-300 font-medium mb-1`}>
                            {t('total-amount-after-increment')} <span className='text-xs text-blue-600'>({t('new-ctc')})</span>
                        </label>
                        <input
                            type="number"
                            value={additionalFields.incrementAmount || ''}
                            onChange={(e) =>
                                setAdditionalFields({
                                    ...additionalFields,
                                    incrementAmount: e.target.value,
                                })
                            }
                            className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            placeholder={t("enter-increment-amount")}
                        />
                    </div>

                    {/* Specify Reason for Increment Field */}
                    <div className="mb-2">
                        <label className={`${labelAlign} block text-gray-600 dark:text-gray-300 font-medium mb-1`}>
                            {t('specify-reason-for-increment')}
                        </label>
                        <textarea
                            value={additionalFields.incrementReason || ''}
                            onChange={(e) =>
                                setAdditionalFields({
                                    ...additionalFields,
                                    incrementReason: e.target.value,
                                })
                            }
                            className={`w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white ${labelAlign}`}
                            placeholder={t("enter-reason-for-increment")}
                        />
                    </div>
                </div>
            );

        case 'Training Request':
            return (
                <div className="mb-4">
                    <label className={`${labelAlign} block text-gray-600 dark:text-gray-300 font-medium mb-1`}>
                        {t('training-start-from')}
                    </label>
                    <input
                        type="date"
                        value={additionalFields?.traningStartDate || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                traningStartDate: e?.target?.value,
                            })
                        }
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                    <label className={`block text-gray-600 dark:text-gray-300 font-medium mt-2 mb-1 ${labelAlign}`}>
                        {t('to')}
                    </label>
                    <input
                        type="date"
                        value={additionalFields?.traningEndDate || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                traningEndDate: e?.target?.value,
                            })
                        }
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                    <label className={`block text-gray-600 dark:text-gray-300 font-medium mb-1 mt-2 ${labelAlign}`}>
                        {t('training-program-details')}
                    </label>
                    <textarea
                        value={additionalFields?.trainingDetails || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                trainingDetails: e?.target?.value,
                            })
                        }
                        className={`w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white ${labelAlign}`}
                        placeholder={t("enter-details-about-the-training-program")}
                    />
                </div>
            );
        case 'Authorization Letter':
            return (
                <div className="mb-4">
                    <label className={`block text-gray-600 dark:text-gray-300 font-medium mb-2 ${labelAlign}`}>
                        {t('more-context-about-letter')}
                    </label>
                    <textarea
                        value={additionalFields.letterContext || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                letterContext: e.target.value,
                            })
                        }
                        className={`w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white ${labelAlign}`}
                        placeholder={t("enter-project-access-details")}
                    />
                </div>
            );
        case 'Leave Request':
            return (
                <div className="mb-4">
                    <label className={`block text-gray-600 dark:text-gray-300 font-medium mb-2 ${labelAlign}`}>
                        {t('leave-type')}
                    </label>
                    <select
                        value={additionalFields.leaveType || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                leaveType: e.target.value,
                            })
                        }
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    >
                        <option value="" disabled hidden>{t('select-leave-type')}</option>
                        {leaveFrequencyOptions && leaveFrequencyOptions?.map((leave, index) => (
                            <option key={index} value={leave?.value}>{t(leave?.label)}</option>
                        ))}
                    </select>
                    <label className={`block text-gray-600 dark:text-gray-300 font-medium my-2 ${labelAlign}`}>
                        {t('specify-dates')}
                    </label>
                    <label className={`block text-gray-600 dark:text-gray-300 font-medium mb-1 ${labelAlign}`}>
                        {t('from')}
                    </label>
                    <input
                        type="date"
                        value={additionalFields.startDate || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                startDate: e.target.value,
                            })
                        }
                        min={new Date().toISOString().split('T')[0]}
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                    <label className={`block text-gray-600 dark:text-gray-300 font-medium mb-1 ${labelAlign}`}>
                        {t('to')}
                    </label>
                    <input
                        type="date"
                        value={additionalFields.endDate || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                endDate: e.target.value,
                            })
                        }
                        min={new Date().toISOString().split('T')[0]}
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />

                    <label className={`block text-gray-600 dark:text-gray-300 font-medium mt-2 mb-1 ${labelAlign}`}>
                        {t('specify-reason')}
                    </label>
                    <textarea
                        value={additionalFields?.leaveReason || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                leaveReason: e?.target?.value,
                            })
                        }
                        className={`w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white ${isArabic ? 'text-right' : 'text-left'}`}
                    ></textarea>
                </div>
            );
        case 'Employee Certificate Request':
            return (
                <div className="mb-4">
                    <label className={`block text-gray-600 dark:text-gray-300 font-medium mb-2 ${labelAlign}`}>
                        {t('certificate-type')}
                    </label>
                    <select
                        value={additionalFields?.certificateType || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                certificateType: e?.target?.value,
                            })
                        }
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    >
                        <option value="Employment">{t('employment')}</option>
                        <option value="Salary">{t('salary')}</option>
                        <option value="Experience">{t('experience')}</option>
                        <option value="Other">{t('other')}</option>
                    </select>

                    {
                        additionalFields.certificateType === 'Other' &&
                        <div>
                            <label className={`block text-gray-600 dark:text-gray-300 font-medium mt-2 ${labelAlign}`}>
                                purpose of the certificate
                            </label>
                            <textarea
                                value={additionalFields.purposeOfCertificate || ''}
                                onChange={(e) =>
                                    setAdditionalFields({
                                        ...additionalFields,
                                        purposeOfCertificate: e.target.value,
                                    })
                                }
                                className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            ></textarea>
                        </div>
                    }
                </div>
            );
        case 'Resignation Request':
            return (
                <div className="mb-4">
                    <label className={`block text-gray-600 dark:text-gray-300 font-medium mb-2 ${labelAlign}`}>
                        {t('last-working-day')}
                    </label>
                    <input
                        type="date"
                        value={additionalFields?.lastWorkingDay || ''}
                        onChange={(e) => {
                            setAdditionalFields({
                                ...additionalFields,
                                lastWorkingDay: e?.target?.value,
                            });
                        }}
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />

                    <label className={`block text-gray-600 dark:text-gray-300 font-medium mt-2 ${labelAlign}`}>
                        {t('reason-for-resignation')}
                    </label>
                    <textarea
                        value={additionalFields?.resignationReason || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                resignationReason: e?.target?.value,
                            })
                        }
                        className={`w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white ${labelAlign}`}
                    ></textarea>
                </div>
            );
        case 'Fixation of Salary Request':
            return (
                <div className="mb-4">
                    <label className={`block text-gray-600 dark:text-gray-300 font-medium mt-2 ${labelAlign}`}>
                        {t('specify-the-reason-for-the-request')}
                    </label>
                    <textarea
                        value={additionalFields?.fixationReason || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                fixationReason: e?.target?.value,
                            })
                        }
                        className={`w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white ${labelAlign}`}
                    ></textarea>
                </div>
            )
        default:
            return null;
    }
};

export default RequestBody;

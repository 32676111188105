import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    Input,
    FormLabel,
    Button,
    Select,
    useToast,
    Textarea
} from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next';


function AddExpenseCategoryModel({ isOpen, onClose, onReset, expenseFormData, onHandleChange, isLoading, onHandleSubmit }) {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return (
        <>
            {/* <Button >Open Modal</Button> */}
            <Modal
                // initialFocusRef={initialRef}
                // finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign={isArabic ? 'right' : 'left'} dir={isArabic ? 'rtl' : 'ltr'}>{t('create-new-category')}</ModalHeader>
                    <ModalCloseButton
                        style={{
                            [isArabic ? 'left' : 'right']: '10px' // Conditional positioning based on language
                        }}
                    />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel textAlign={isArabic ? 'right' : 'left'}>{t('name')}</FormLabel>
                            <Input
                                value={expenseFormData?.name}
                                textAlign={isArabic ? 'right' : 'left'}
                                onChange={(e) => {
                                    onHandleChange('name', e?.target?.value)
                                }}
                                placeholder={t('name')}
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel textAlign={isArabic ? 'right' : 'left'}>{t('arabic-name')}</FormLabel>
                            <Input
                                textAlign={isArabic ? 'right' : 'left'}
                                value={expenseFormData?.arabicName}
                                onChange={(e) => {
                                    onHandleChange('arabicName', e?.target?.value)
                                }}
                                placeholder={t('arabic-name')}
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel textAlign={isArabic ? 'right' : 'left'}>{t('description')}</FormLabel>
                            <Textarea
                                value={expenseFormData?.description}
                                textAlign={isArabic ? 'right' : 'left'}
                                onChange={(e) => {
                                    onHandleChange('description', e?.target?.value)
                                }}
                                placeholder={t('description')}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={4}
                            onClick={onReset}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={onHandleSubmit}
                            // onClick={handleSave}
                            colorScheme='blue'
                            isLoading={isLoading}
                            isDisabled={expenseFormData?.name ? false : true}
                        >
                            {t('add')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    )
}

export default AddExpenseCategoryModel
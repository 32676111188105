import React, { useEffect, useState } from 'react'
import { get, patch } from '../../services/apis/api'
import UpdateApplicationAccess from '../../components/dialogueBoxes/UpdateApplicationAccess';
import { useTranslation } from 'react-i18next';

const ApplicationService = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const [services, setServices] = useState();
    const getApplicationServices = async()=>{
        try {
            const res = await get('org-application-service/all');
            console.log(res);
            if(res.statusCode === 200){
                setServices(res.data.services)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getApplicationServices();
    }, [])

    const handleCheckboxChange = async(id, active)=>{
        console.log(id, active);
        const res = await patch(`org-application-service/update/${id}`, {active: active});
        if(res.statusCode === 200){
            getApplicationServices();
        }
    }   
    
    return (
        <div className={`flex flex-col ${isArabic ? 'rtl' : 'ltr'}`}>
            <div>
                <div>
                    <h2 className={`text-black text-2xl font-bold ${isArabic ? 'text-right' : 'text-left'}`}>
                        {t("application-services")}
                    </h2>
                </div>
                <div className={`w-full pt-2 flex flex-wrap ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}>
                    {
                        services && services.map((service, index) => (
                            <div key={index} className={`w-[300px] bg-gray-100 rounded-md shadow p-4 m-1 ${isArabic ? 'text-right' : 'text-left'}`}>
                                <div className={`flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} justify-between`}>
                                    <h2 className={`text-black font-bold text-lg ${isArabic ? 'text-right' : 'text-left'}`}>
                                        {!isArabic ? service.name : service.arabicName}
                                    </h2>
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input
                                            checked={service.active}
                                            onClick={() => { handleCheckboxChange(service._id, !service.active) }}
                                            type="checkbox"
                                            className="sr-only peer"
                                        />
                                        <div className={`relative w-11 h-6 ${isArabic ? 'bg-gray-200' : 'bg-gray-200'} peer-focus:outline-none dark:peer-focus:ring-[#290dbd] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#290dbd]`}>
                                        </div>
                                    </label>
                                </div>
                                <div className='mt-2 h-24'>
                                    <h2 className={`text-black font-bold text-md ${isArabic ? 'text-right' : 'text-left'}`}>
                                        {t("accessible-to")}
                                    </h2>
                                    <div className={`flex flex-wrap mt-2 ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}>
                                        {
                                            service?.access.map((item, index) => (
                                                <h2 key={index} className='px-3 py-1 h-auto bg-green-500 text-xs font-semibold text-[#ffffff] rounded-md m-1'>
                                                    {item}
                                                </h2>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='flex justify-end pt-3'>
                                    <UpdateApplicationAccess service={service} getApplicationServices={getApplicationServices}/>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default ApplicationService

import React from 'react'

const Accessibility = () => {
    return (
        <div>
        Accessibility
        </div>
    )
}

export default Accessibility

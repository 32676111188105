import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  Stack,
  VStack,
  HStack,
  Divider,
  Avatar,
  Link,
  Center,
  Spinner,
  Button,
  SimpleGrid,
} from '@chakra-ui/react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { get, patch, post } from '../../services/apis/api';

const ClubProfile = ({ selectedClub, setViewClub }) => {
  const [club, setClub] = useState({});
  const [loading, setLoading] = useState(true);
  const [btnloading, setbtnLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const fetchClubData = async () => {
    try {
      if (selectedClub && selectedClub.organizationId) {
        const res = await get(`organization/getById/${selectedClub.organizationId._id}`);
        setClub(res.data.organization);
        setIsActive(res.data.organization.isActive);  // Assuming isActive is a field in your club data
      }
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchClubData();
  }, []);

  const handleToggleActiveStatus = async () => {
    try {
        setbtnLoading(true)
        const updatedStatus = !club.disabled;
        // Assuming there's an endpoint to update the active status of the club
        await patch(`organization/update/${club._id}`, { disabled: updatedStatus });
        fetchClubData();
    } catch (error) {
        console.error('Failed to update status:', error);
    } finally {
        setbtnLoading(false);
    }
  };

  if (loading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }
  if (!selectedClub.organizationId) {
    return (
      <Center height="100vh">
        <VStack spacing={4}>
          <Text fontSize="xl" color="red.600">Super Admin has not created the club yet</Text>
          <Button
            onClick={() => { setViewClub(false); }}
            colorScheme="purple"
            leftIcon={<IoMdArrowRoundBack />}
          >
            Back
          </Button>
        </VStack>
      </Center>
    );
  }

  return (
    <Box p={6} w="100%" maxW="1200px" mx="auto" bg="white" borderRadius="lg" boxShadow="lg">
      <Box position="relative" mb={6}>
        <Image src={club.banner} alt={`${club.name} banner`} w="100%" h="200px" objectFit="cover" borderRadius="lg" />
        <Avatar
          size="2xl"
          src={club.logo}
          name={club.name}
          position="absolute"
          bottom="-50px"
          left="50%"
          transform="translateX(-50%)"
          border="4px solid white"
          boxShadow="lg"
        />
        <Button
          onClick={() => { setViewClub(false); }}
          leftIcon={<IoMdArrowRoundBack />}
          colorScheme='blue'
          position="absolute"
          top="10px"
          left="10px"
        >
          Back
        </Button>
        <Button
          onClick={handleToggleActiveStatus}
          colorScheme={club?.disabled ? "green" : "red"}
          position="absolute"
          top="10px"
          right="10px"
        >
          {btnloading ? <Spinner /> :  club?.disabled ? "Activate Club" : "Deactivate Club"}
        </Button>
      </Box>
      <Box mt={12} textAlign="center">
        <Heading as="h1" size="2xl" mb={2} color="purple.600">{club?.name}</Heading>
      </Box>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mt={4}>
        <Text fontSize="lg" color="gray.700" mb={2}><strong>Founded On:</strong> {new Date(club?.foundedOn).toLocaleDateString()}</Text>
        <Text fontSize="lg" color="gray.700" mb={2}><strong>Founder:</strong> {club?.founder}</Text>
        <Text fontSize="lg" color="gray.700" mb={2}><strong>Location:</strong> {club?.location}</Text>
        <Text fontSize="lg" color="gray.700" mb={2}><strong>Mission:</strong> {club?.mission}</Text>
        <Text fontSize="lg" color="gray.700" mb={2}><strong>Vision:</strong> {club?.vision}</Text>
        <Text fontSize="lg" color="gray.700" mb={2}><strong>Description:</strong> {club?.description}</Text>
        <Text fontSize="lg" color="gray.700" mb={2}><strong>Registration Number:</strong> {club?.registrationNumber}</Text>
        <Text fontSize="lg" color="gray.700" mb={2}><strong>Status:</strong> {club?.disabled ? "In-Active" : "Active"}</Text>
      </SimpleGrid>
      <Box mt={4} textAlign="center">
        <Heading as="h3" size="md" mb={2} color="purple.600">Social Accounts</Heading>
        <VStack align="center">
          {club?.socialAccount && club?.socialAccount.map((account, index) => (
            <HStack key={index} spacing={2}>
              <Text>{account.name}:</Text>
              <Link color="purple.500" href={account.link} isExternal>{account?.link}</Link>
            </HStack>
          ))}
        </VStack>
      </Box>
      <Divider my={8} />
      <Stack direction={{ base: 'column', md: 'row' }} spacing={8} align="center" textAlign="center">
        <Avatar size="2xl" src={club?.superAdminId?.pic} name={club?.superAdminId?.name} boxShadow="md" />
        <Box w="100%">
          <Heading as="h3" size="lg" mb={2} color="purple.600">SUPER-ADMIN Info</Heading>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
            <Text fontSize="lg" color="gray.700"><strong>Name:</strong> {club?.superAdminId?.name}</Text>
            <Text fontSize="lg" color="gray.700"><strong>Email:</strong> {club?.superAdminId?.email}</Text>
            <Text fontSize="lg" color="gray.700"><strong>Username:</strong> {club?.superAdminId?.userName}</Text>
            <Text fontSize="lg" color="gray.700"><strong>Mobile:</strong> {club?.superAdminId?.mobile}</Text>
            <Text fontSize="lg" color="gray.700"><strong>Role:</strong> {club?.superAdminId?.jobTitle}</Text>
          </SimpleGrid>
        </Box>
      </Stack>
    </Box>
  );
};

export default ClubProfile;


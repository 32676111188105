import React, { useCallback, useEffect, useRef, useState } from 'react';
import { get, post } from '../../services/apis/api'; // Assuming 'post' is for API updates
import { Button, Table, Thead, Tbody, Tr, Th, Td, useToast, InputGroup, InputLeftElement, Input, Tooltip, Box, InputRightElement, Flex } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import BreadCrumbs from '../../components/BreadCrumbs';
import { CheckCircleIcon, CloseIcon, TimeIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { convertToCSV, formatNumber } from '../../services/glocalFunctions';
import { debounce } from 'lodash';
import ConfirmDownload from '../../components/dialogueBoxes/ConfirmDownload';
import { formatDateTime } from '../../utills/formateDate';

const TeamAttendance = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState(''); // State for search input
    const [limit, setLimit] = useState(10); // State for selected date
    const hasFetched = useRef(false); // Initially false
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [totalEmployees, setTotalEmployees] = useState(null);
    const [downloadOpen, setDownloadOpen] = useState(false);  // for confirmation dialog

    //  FLEX DIRECTION CLASSES 
    const flDir = isArabic ? 'flex-row-reverse' : 'flex-row'

    // Fetch all assigned employess to reporting head list with search
    const getTeamAttendanceList = async (skip, limit, search) => {
        try {
            // Construct query string
            const queryParams = new URLSearchParams();
            if (skip !== undefined) queryParams.append('skip', skip);
            if (limit !== undefined) queryParams.append('limit', limit);
            if (search) queryParams.append('name', search);

            const queryString = queryParams.toString();
            const res = await get(`attendance/list/assigned-employees${queryString ? `?${queryString}` : ''}`);

            // const res = await get(`attendance/list/assigned-employees?skip=${skip}&limit=${limit}&name=${searchTerm}`);
            if (res?.statusCode == 200) {
                setList(res?.data?.employees || []);
                setTotalPages(res?.data?.totalPages);
                setTotalEmployees(res?.data?.totalCount);
            } else {
                setList([]);
                setTotalPages(1);
                setPage(0);
                setTotalEmployees(0);
            }
        } catch (error) {
            setList([]);
            setTotalPages(1);
            setPage(0);
            setTotalEmployees(0);
            console.error('Error fetching attendance list:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!hasFetched.current) {
            getTeamAttendanceList(0, 10);
            hasFetched.current = true; // Mark as fetched
        }
    }, []);

    // HANDLE PAGE CHANGE FUNCTION 
    const handlePageChange = (newPage) => {
        try {
            if (newPage >= 0 && newPage <= totalPages) {
                getTeamAttendanceList(newPage * limit, limit)
                setPage(newPage);
            }
        } catch (e) {
            console.log(`ERROR WHILE CHANGING PAGE ${e}`);
        }
    };

    // Debounced version of the getEmployeesWithPayroll function
    const debouncedGetAllEmployees = useCallback(
        debounce((skip, limit, search) => {
            getTeamAttendanceList(skip, limit, search);
        }, 300), // Adjust debounce delay as needed
    );

    // Fetch all attendances and then download as CSV
    const downloadAttendanceCSV = async (skip, search) => {
        try {
            // Construct query string
            const queryParams = new URLSearchParams();
            if (skip !== undefined) queryParams.append('skip', skip);
            if (search) queryParams.append('name', search);

            const queryString = queryParams.toString();
            const res = await get(`attendance/list/assigned-employees${queryString ? `?${queryString}` : ''}`);

            if (res?.statusCode == 200) {
                const allEmployees = res?.data?.employees;
                // const csvContent = convertToCSV(allAttendances);
                const headers = ['Employee Name', 'EmployeeCode', 'Department', 'Designation', 'Approved Attendance', 'Rejected Attendance', 'Pending Attendance', 'Absent Attendance'];

                const rows = allEmployees?.map((employee) => [
                    employee?.name,
                    employee?.employeeCode,
                    employee?.department,
                    employee?.designation,
                    employee?.totalApproved,
                    employee?.totalRejected,
                    employee?.totalPending,
                    employee?.totalAbsent,
                ]);

                const csvContent = [headers, ...rows].map((e) => e.join(",")).join("\n");
                const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', 'attendances.csv');
                link.style.visibility = 'hidden';

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.log(`ERROR WHILE DOWNLOADING CSV`);
            }
        } catch (error) {
            console.error('Error fetching attendances for CSV:', error);
        }
    };

    // Confirm download
    const confirmDownload = () => {
        try {
            downloadAttendanceCSV(0, searchTerm);
            setDownloadOpen(false);
        } catch (e) {
            console.log(`ERROR WHILE GENERATING PDF ${e}`);
        }
    };

    return (
        <div className="p-2">
            <BreadCrumbs />
            <div className={`flex justify-between items-center pb-4 ${flDir}`}>
                <div className={`flex items-center gap-1 ${flDir}`}>
                    <h1 className='text-[#5145e5] text-lg lg:text-3xl font-bold'>{t('Team Attendance')}</h1>
                    <h1 className='text-[#5145e5] text-lg lg:text-xl font-bold'>
                        {totalEmployees > 0 && `(${totalEmployees})`}
                    </h1>
                </div>
                <div className={`flex ${flDir} items-center gap-4`}>
                    <InputGroup mb={{ base: 2, md: 0 }} w={{ base: "", md: "" }}>
                        {!isArabic &&
                            <InputLeftElement pointerEvents="none">
                                <SearchIcon color="gray.300" />
                            </InputLeftElement>
                        }
                        <Input
                            type="text"
                            textAlign={isArabic ? 'right' : 'left'}
                            placeholder={t('search')}
                            value={searchTerm}
                            onChange={(e) => {
                                const value = e?.target?.value;
                                debouncedGetAllEmployees(0, 10, value)
                                setSearchTerm(value);
                            }}
                        />
                        {isArabic &&
                            <InputRightElement pointerEvents="none">
                                <SearchIcon color="gray.300" />
                            </InputRightElement>
                        }
                    </InputGroup>
                    {/* Download Confirmation Dialog */}
                    <ConfirmDownload
                        isOpen={downloadOpen}
                        label="download-report"
                        setIsOpen={setDownloadOpen}
                        handleDownload={() => {
                            setDownloadOpen(true);
                        }}
                        confirmDownload={() => {
                            confirmDownload();
                        }}
                        closeDialog={() => {
                            setDownloadOpen(false);
                        }}
                    />
                    {/* <Input
                        id="date"
                        name="date"
                        type="date"
                        placeholder="Select Date"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                    /> */}
                </div>
            </div>

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <Table dir={isArabic ? 'rtl' : 'ltr'} className="w-full text-sm text-left text-gray-500" variant="simple">
                    <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <Tr>
                            <Th>{t('employee-name')}</Th>
                            <Th>{t('employee-code')}</Th>
                            {/* <Th>Email</Th> */}
                            <Th>{t('department')}</Th>
                            <Th>{t('designation')}</Th>
                            <Th>
                                <Tooltip label="Approved Sessions" aria-label="Approved Sessions">
                                    <Box display="inline-flex" alignItems="center">
                                        <CheckCircleIcon color="green.500" mr={1} />
                                        {t('atn')}
                                    </Box>
                                </Tooltip>
                            </Th>
                            <Th>
                                <Tooltip label="Rejected Sessions" aria-label="Rejected Sessions">
                                    <Box display="inline-flex" alignItems="center">
                                        <CloseIcon color="red.500" mr={1} />
                                        {t('atn')}
                                    </Box>
                                </Tooltip>
                            </Th>
                            <Th>
                                <Tooltip label="Pending Sessions" aria-label="Pending Sessions">
                                    <Box display="inline-flex" alignItems="center">
                                        <TimeIcon color="orange.500" mr={1} />
                                        {t('atn')}
                                    </Box>
                                </Tooltip>
                            </Th>
                            <Th>
                                <Tooltip label="Pending Sessions" aria-label="Pending Sessions">
                                    <Box display="inline-flex" alignItems="center">
                                        <TimeIcon color="red.500" mr={1} />
                                        {t('atn')}
                                    </Box>
                                </Tooltip>
                            </Th>

                            <Th>{t('view-attendance')}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {list?.length > 0 && list?.map((employee) => (
                            <Tr key={employee?._id}>
                                <Td>{employee?.name || 'N/A'}</Td>
                                <Td>
                                    {employee?.employeeCode || 'N/A'}
                                </Td>
                                <Td>
                                    {t(employee?.department?.toLowerCase()) || 'N/A'}
                                </Td>
                                <Td>
                                    {employee?.designation || 'N/A'}
                                </Td>
                                <Td>
                                    {formatNumber(employee?.totalApproved, isArabic) || 'N/A'}
                                </Td>
                                <Td>
                                    {formatNumber(employee?.totalRejected, isArabic) || 'N/A'}
                                </Td>
                                <Td>
                                    {formatNumber(employee?.totalPending, isArabic) || 'N/A'}
                                </Td>
                                <Td>
                                    {formatNumber(employee?.totalAbsent, isArabic) || 'N/A'}
                                </Td>
                                <Td>
                                    <Button
                                        colorScheme="blue"
                                        size="sm"
                                        onClick={() => {
                                            navigate(`/employee/team-attendance/${employee?._id}`)
                                        }}
                                    >
                                        {t('view')}
                                    </Button>
                                </Td>
                                {/* <Td>
                                    {record?.approvedStatus != 'PENDING' ? (
                                        <h2 className="text-green-500">{record?.approvedStatus}</h2>
                                    ) : (
                                        <>
                                            <Button
                                                colorScheme="blue"
                                                size="sm"
                                                isLoading={isLoading}
                                                onClick={() => approveAttendance(record?._id, 'APPROVED')}
                                            // isDisabled={!record?.checkInTime || !record?.checkOutTime || record?.approved}
                                            >
                                                Approve
                                            </Button>
                                            <Button
                                                colorScheme="red"
                                                size="sm"
                                                ml={3}
                                                isLoading={isLoading}
                                                onClick={() => approveAttendance(record?._id, 'REJECTED')}
                                            // isDisabled={!record?.checkInTime || !record?.checkOutTime || record?.approvedStatus == 'REJECTED'}
                                            >
                                                Reject
                                            </Button>
                                        </>
                                    )}
                                </Td> */}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                {!loading ?
                    list?.length <= 0 &&
                    <div className='w-full  min-h-80 flex items-center justify-center'>
                        <h1 className='text-center'>{t('no-attendance-found')}</h1>
                    </div>
                    :
                    <div className='w-full  min-h-80 flex items-center justify-center'>
                        <h1 className={`text-center `}>
                            {isArabic && '....'} {t('loading')} {!isArabic && '....'}
                        </h1>
                    </div>
                }
            </div>
            {list?.length > 0 &&
                <Flex justify="space-between" align="center" mt={4}>
                    <Button
                        onClick={() => handlePageChange(page - 1)}
                        isDisabled={page === 0}
                    >
                        {t('previous')}
                    </Button>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isArabic ? 'row-reverse' : 'row',
                            gap: 1
                        }}
                    >
                        <h1>{t('page')}</h1>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isArabic ? 'row-reverse' : 'row',
                                gap: 1
                            }}
                        >
                            {page + 1}

                            <h1>{t('of')}</h1>
                            {totalPages}
                        </ Box>
                    </Box>
                    <Button
                        onClick={() => handlePageChange(page + 1)}
                        isDisabled={page + 1 >= totalPages}
                    >
                        {t('next')}
                    </Button>
                </Flex>
            }
        </div>
    );
};

export default TeamAttendance;

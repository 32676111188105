import React from "react";
import {
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import { deletethis } from "../../services/apis/api";

const DeleteEvent = ({ event, setShowEvent, fetchEvents}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();

    const toast = useToast();

 const  deleteEvent = async()=>{
  try {
    let res =await deletethis(`event/delete/${event._id}`)
    setShowEvent(false);
    fetchEvents();
    if (res.statusCode === 200) {
      toast({
        title: 'Event',
        description: res.data.message,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });}
  } catch (error) {
    toast({
      title: 'Oops...!',
      description: error?.response?.data?.message,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  }
 }
    return (
        <>
            <Button onClick={onOpen} backgroundColor="red" color={'white'} >
                Delete
            </Button>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Product
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to delete this product?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                colorScheme="red"
                                onClick={deleteEvent}
                                ml={3}
                            >
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export default DeleteEvent;

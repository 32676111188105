
import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Avatar,
    Button,
    InputGroup,
    Text,
    Heading,
    useColorModeValue,
} from '@chakra-ui/react';
import { get } from '../../services/apis/api';
import DatePicker from 'react-datepicker';
import { CiCalendarDate } from 'react-icons/ci';
import { UserState } from '../../context/user';
import { jsPDF } from 'jspdf';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ConfirmDownload from '../../components/dialogueBoxes/ConfirmDownload';
import { formatNumber } from '../../services/glocalFunctions';
import BreadCrumbs from '../../components/BreadCrumbs';

const SingleEmployeePerformance = () => {
    const { id } = useParams();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [performanceData, setPerformanceData] = useState();
    const [startDate, setStartDate] = useState('');
    const [isOpen, setIsOpen] = useState(false);  // for confirmation dialog
    const [cancelRef, setCancelRef] = useState(null);  // for the cancel button in AlertDialog
    const { user } = UserState();
    const [downloadOpen, setDownloadOpen] = useState(false);  // for confirmation dialog

    // HANDLE DATE CHANGE FUNCTION 
    const handleDateChange = (date) => {
        try {
            if (date instanceof Date && !isNaN(date)) {
                const updatedDate = new Date(date.getFullYear(), date.getMonth(), 30);
                setStartDate(updatedDate);
            } else {
                console.log("Invalid date selected");
            }
        } catch (e) {
            console.log(`ERROR WHILE CHANGING DATES ${e}`);
        }
    };

    const getPerformanceDetails = async () => {
        try {
            const res = await get(`performance/employee-data/${id}?date=${startDate}`);
            if (res.statusCode === 200) {
                setPerformanceData(res.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getPerformanceDetails();
    }, [startDate]);

    // Colors based on mode
    const tableBg = useColorModeValue('gray.50', 'gray.700');
    const sectionBg = useColorModeValue('gray.100', 'gray.800');
    const textColor = useColorModeValue('gray.700', 'gray.200');

    // Function to generate PDF
    const generatePDF = () => {
        try {
            const doc = new jsPDF();

            // Title
            doc.setFontSize(16);
            doc.text("Employee Performance Report", 20, 20);

            // Employee Info
            doc.setFontSize(12);
            doc.text(`Employee: ${user?.name}`, 20, 30);
            doc.text(`Employee Code: ${user?.employeeCode}`, 20, 35);
            doc.text(`Designation: ${user?.designation}`, 20, 40);

            // Performance Data Table
            doc.text("Performance Data:", 20, 50);
            let y = 55;
            performanceData?.performanceQue?.forEach((plan, idx) => {
                doc.text(`${plan?.questionDetails?.questionText}: ${plan?.score}`, 20, y);
                y += 5;
            });

            // Monthly Performance Score
            doc.text(`Monthly Performance Score: ${performanceData?.finalPerformance}`, 20, y + 10);

            // Save the PDF
            doc.save("performance_report.pdf");
        } catch (e) {
            console.log(`ERROR WHILE GENERATING PDF ${e}`);
        }
    };

    // Function to handle confirmation and download
    const handleDownload = () => {
        setIsOpen(true);
    };

    // Close confirmation dialog
    const closeDialog = () => {
        setIsOpen(false);
    };

    // Confirm download
    const confirmDownload = () => {
        try {
            generatePDF();
            setDownloadOpen(false);
        } catch (e) {
            console.log(`ERROR WHILE DOWLOAIDNG PDF ${e}`);
        }
    };

    return (
        <Box width="100%" p={4} bg="white" shadow="md" rounded="lg">
            <BreadCrumbs />
            {/* Header */}
            <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} justify="space-between" align="center" mb={6} wrap={'wrap'}>
                <Heading size="lg">{t('performance')}</Heading>
                <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} align="center" gap={2} wrap={'wrap'}>
                    <InputGroup position="relative" w="full" maxW="240px">
                        <DatePicker
                            selected={startDate}
                            onChange={handleDateChange}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            placeholderText={t("select-month")}
                            className="border rounded-md p-2 w-[240px]"
                        />
                        <CiCalendarDate className="absolute right-3 top-1/2 transform -translate-y-1/2 text-xl text-gray-500 pointer-events-none" />
                    </InputGroup>
                    {/* Download Confirmation Dialog */}
                    <ConfirmDownload
                        isOpen={downloadOpen}
                        label='download-report'
                        setIsOpen={setDownloadOpen}
                        handleDownload={() => {
                            setDownloadOpen(true);
                        }}
                        confirmDownload={() => {
                            confirmDownload();
                        }}
                        closeDialog={() => {
                            setDownloadOpen(false);
                        }}
                    />
                </Flex>
            </Flex>

            {/* Employee Profile Card */}
            <Box bg={sectionBg} p={3} rounded="lg" mb={6} shadow="sm">
                <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} gap={4} align="center">
                    <Avatar src={performanceData?.employeeDetails?.pic} name={performanceData?.employeeDetails?.name} size="lg" />
                    <Box>
                        <Text textAlign={isArabic ? "right" : 'left'} dir={isArabic ? 'rtl' : 'ltr'} fontSize="lg" fontWeight="bold" color={textColor}>{performanceData?.employeeDetails?.name || '--'}</Text>
                        <Text textAlign={isArabic ? "right" : 'left'} dir={isArabic ? 'rtl' : 'ltr'} color="gray.500">{t('employee-code')}: {performanceData?.employeeDetails?.employeeCode || '--'}</Text>
                        <Text textAlign={isArabic ? "right" : 'left'} dir={isArabic ? 'rtl' : 'ltr'} color="gray.500">{t('designation')}: {performanceData?.employeeDetails?.designation || '--'}</Text>
                    </Box>
                </Flex>
            </Box>

            {/* Performance Data Table */}
            {performanceData ? (
                <Table dir={isArabic ? 'rtl' : 'ltr'} variant="simple" bg={tableBg} rounded="lg" overflow="hidden" shadow="sm">
                    <Thead bg="blue.600">
                        <Tr>
                            <Th color="white">{t('question')}</Th>
                            <Th color="white">{t('score')}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {performanceData?.performanceQue?.map((plan, idx) => (
                            <Tr key={idx} bg={idx % 2 === 0 ? 'gray.50' : 'white'}>
                                <Td>{plan?.questionDetails?.questionText}</Td>
                                <Td>{formatNumber(plan?.score, isArabic)}</Td>
                            </Tr>
                        ))}
                        <Tr bg="blue.100">
                            <Td colSpan="1" fontWeight="bold">{t('monthly-performance-score')}</Td>
                            <Td fontWeight="bold">{formatNumber(performanceData?.finalPerformance, isArabic)}</Td>
                        </Tr>
                    </Tbody>
                </Table>
            ) : (
                <Box
                    bg="red.100"
                    color="red.600"
                    p={4}
                    mt={4}
                    textAlign="center"
                    rounded="lg"
                    shadow="sm"
                >
                    {t('no-data-found')}
                </Box>
            )}
        </Box>
    );
};

export default SingleEmployeePerformance;

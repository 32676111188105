import { t } from 'i18next';
import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const EarningsAndSpendingsSummery = ({
    graphData,
    currentYear
}) => {

    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    // Define the colors for each series
    const seriesColors = {
        earnings: '#28a745',
        spendings: '#dc3545',
        'additional-income': '#007bff',
        'additional-expenses': '#fd7e14',
    };

    const chartOptions = {
        chart: {
            type: 'line',
            height: 350,
            toolbar: {
                show: false,
            },
        },
        // colors: ['#34D399', '#FBBF24','#34D399', '#EF4444'],
        colors: ['#28a745', '#dc3545', '#007bff', '#fd7e14'],
        stroke: {
            width: 3,
            curve: 'smooth',
        },
        xaxis: {
            categories: months,
            title: {
                text: `Months (${currentYear})`,
            },
        },
        yaxis: {
            title: {
                text: 'Amount (SAR)',
            },
        },
        legend: {
            position: 'bottom',
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false, // Disable the default legend
        },
    };

    return (
        <div className='w-full'>
            <Chart options={chartOptions} series={graphData} type="line" height={350} />
            {/* Custom Legends */}
            <div className="flex justify-center mb-4">
                {graphData?.map((item) => (
                    <div
                        key={item?.name}
                        className={`cursor-pointer flex items-center mx-3`}
                    >
                        <div
                            className="w-3 h-3 mr-2"
                            style={{ backgroundColor: seriesColors[item?.name] }}
                        />
                        <span className="capitalize">{t(item?.name)}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EarningsAndSpendingsSummery;

import React, { useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Text,
    Divider,
    useDisclosure,
    useToast,
    Spinner,
} from '@chakra-ui/react';
import { get } from '../../../services/apis/api';
import CompanyLeavePolicyModal from './AddLeavePolicy';

const CompanyLeavePolicyDetailsModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [leavePolicy, setLeavePolicy] = useState(null);
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    
    // Fetch leave policy data from the API
    const getLeavePolicy = async () => {
        setLoading(true);
        try {
            const res = await get(`leave-policy/get`); // Adjust the endpoint
            if (res.statusCode === 200 && res.data) {
                setLeavePolicy(res.data.data);
            } else {
                toast({
                    title: 'No Data',
                    description: 'No leave policy found for this company.',
                    status: 'warning',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.log('Error fetching leave policy:', error);
            toast({
                title: 'Error',
                description: 'Failed to fetch leave policy details.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            getLeavePolicy();
        }
    }, [isOpen]);

    return (
        <>
            {/* Button to open the modal */}
            <Button onClick={onOpen} colorScheme="blue">
               Leave Policy
            </Button>

            {/* Modal for displaying leave policy details */}
            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Leave Policy Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {loading ? (
                            <Box textAlign="center">
                                <Spinner size="xl" />
                            </Box>
                        ) : leavePolicy ? (
                            <Box>
                                {/* Company Name */}
                                <Text fontSize="2xl" fontWeight="bold" mb={3}>
                                    Policy Name : {leavePolicy.name}
                                </Text>
                                <Text fontSize="xl" fontWeight="bold" mb={3}>
                                    Financial Year - {leavePolicy.year}
                                </Text>

                                {/* Leave details */}
                                <Box>
                                    <Text fontWeight="medium" mb={2}>Paid Leave: <strong>{leavePolicy.paidLeave} Days</strong></Text>
                                    <Divider />
                                    <Text fontWeight="medium" mb={2} mt={2}>Sick Leave: <strong>{leavePolicy.sickLeave} Days</strong></Text>
                                    <Divider />
                                    <Text fontWeight="medium" mb={2} mt={2}>Casual Leave: <strong>{leavePolicy.casualLeave} Days</strong></Text>
                                    <Divider />
                                    <Text fontWeight="medium" mb={2} mt={2}>Unpaid Leave: <strong>{leavePolicy.unpaidLeave} Days</strong></Text>
                                    <Divider />
                                    <Text fontWeight="medium" mt={2}>Carry Forward Allowed: <strong>{leavePolicy.carryForward ? 'Yes' : 'No'}</strong></Text>
                                </Box>
                            </Box>
                        ) : (
                            <div className='flex flex-col items-center justify-center'>
                                <Text>No leave policy data available.</Text>
                                <CompanyLeavePolicyModal getLeavePolicy={getLeavePolicy} />
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default CompanyLeavePolicyDetailsModal
import React, { useEffect, useRef, useState } from 'react';
import { countries } from '../../utills/countryCode';
import { BiChevronDown } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

const CountrySelect = ({ initialFullNumber, setFullNumber }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    "code": "SA",
    "name": "Saudi Arabia",
    "dialCode": "+966",
    "flag": "https://flagcdn.com/w320/sa.png"
  });
  const [searchText, setSearchText] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const searchRef = useRef(null);
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  // Function to split full number into country code and mobile number
  const parseFullNumber = (fullNumber) => {
    // Sort countries by the length of dialCode in descending order
    const sortedCountries = [...countries].sort(
      (a, b) => b.dialCode.length - a.dialCode.length
    );

    for (let country of sortedCountries) {
      if (fullNumber.startsWith(country.dialCode)) {
        return {
          country,
          number: fullNumber.slice(country.dialCode.length),
        };
      }
    }
    return { country: countries[0], number: '' }; // Default case
  };

  useEffect(() => {
    if (initialFullNumber) {
      const { country, number } = parseFullNumber(initialFullNumber);
      setSelectedCountry(country);
      setMobileNumber(number);
    }
  }, [initialFullNumber]);

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (country) => {
    setSelectedCountry(country);
    setIsOpen(false);
    updateFullNumber(country.dialCode, mobileNumber);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value.toLowerCase());
  };

  const handleMobileNumberChange = (event) => {
    const number = event.target.value;
    setMobileNumber(number);
    updateFullNumber(selectedCountry?.dialCode, number);
  };

  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(searchText)
  );

  const updateFullNumber = (dialCode, number) => {
    const fullNumber = `${dialCode}${number}`;
    setFullNumber(fullNumber);
  };

  useEffect(() => {
    if (selectedCountry) {
      updateFullNumber(selectedCountry.dialCode, mobileNumber);
    }
  }, [selectedCountry, mobileNumber]);

  return (
    <div className="flex items-center space-x-4" dir={isArabic ? 'rtl' : 'ltr'}>
      <div className="relative flex items-center">
        <button
          className="flex items-center border h-10 px-3 py-2 rounded-md focus:outline-none hover:bg-gray-100"
          onClick={handleClick}
        >
          {selectedCountry ? (
            <img className="w-6 h-4 mr-2" src={selectedCountry.flag} alt={selectedCountry.name} />
          ) : (
            <img
              className="w-6 h-4 mr-2"
              src="https://flagcdn.com/w320/in.png"
              alt="Default Flag"
            />
          )}
          {selectedCountry ? (
            <span>{selectedCountry.dialCode}</span>
          ) : (
            <span> </span>
          )}
          <BiChevronDown />
        </button>
        {isOpen && (
          <ul className="absolute w-[250px] top-full left-0 bg-white rounded-md shadow-md overflow-y-auto max-h-60 z-50">
            <input
              className="w-full px-3 py-2 border-b focus:outline-none"
              placeholder={t("search-country")}
              value={searchText}
              onChange={handleSearchChange}
              ref={searchRef}
            />
            {filteredCountries.length > 0 ? (
              filteredCountries.map((country) => (
                <li
                  key={country.code}
                  className="hover:bg-gray-100 px-3 py-2 cursor-pointer flex items-center"
                  onClick={() => handleOptionClick(country)}
                >
                  <img className="w-4 h-4 mr-2" src={country.flag} alt={country.name} />
                  {country.name}
                </li>
              ))
            ) : (
              <li className="text-gray-400 px-3 py-2">No results found</li>
            )}
          </ul>
        )}
      </div>
      <div className="flex flex-col flex-1">
        <input
          type="tel"
          className="border px-3 py-2 flex-1 rounded-md focus:outline-none"
          placeholder={t("enter-mobile-number")}
          value={mobileNumber}
          onChange={handleMobileNumberChange}
          dir={isArabic ? 'rtl' : 'ltr'}
        />
      </div>
    </div>
  );
};

export default CountrySelect;

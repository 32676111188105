import React, { useEffect } from 'react';

const RequestBody = ({ requestType, additionalFields, setAdditionalFields }) => {

    useEffect(() => {

    }, [additionalFields])

    switch (requestType) {
        case 'Contract Renewal Request':
            const handleDateChange = () => {
                if (!additionalFields.contractStartDate || !additionalFields.contractEndDate) {
                    return;
                }
                const startDate = new Date(additionalFields.contractStartDate);
                const endDate = new Date(additionalFields.contractEndDate);
                console.log(startDate);
                console.log(endDate);
                
                // Calculate duration in months
                if (startDate && endDate) {
                    const duration = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth();
                    console.log(duration);
                    
                    setAdditionalFields({ ...additionalFields, contractDuration: duration });
                }
            };

            return (
                <div className="mb-4">
                    <label className="block text-gray-600 dark:text-gray-300 font-medium mb-2">
                        Previous Contract End Date
                    </label>
                    <input
                        type="date"
                        value={additionalFields.previousContractEndDate || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                previousContractEndDate: e.target.value,
                            })
                        }
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />

                    <label className="block text-gray-600 dark:text-gray-300 font-medium mt-2">
                        New Contract Start Date
                    </label>
                    <input
                        type="date"
                        value={additionalFields.contractStartDate || ''}
                        onChange={(e) => {
                            setAdditionalFields({
                                ...additionalFields,
                                contractStartDate: e.target.value,
                            });
                            handleDateChange();
                        }}
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />

                    <label className="block text-gray-600 dark:text-gray-300 font-medium mt-2">
                        New Contract End Date
                    </label>
                    <input
                        type="date"
                        value={additionalFields.contractEndDate || ''}
                        onChange={(e) => {
                            setAdditionalFields({
                                ...additionalFields,
                                contractEndDate: e.target.value,
                            });
                            handleDateChange();
                        }}
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />

                    <label className="block text-gray-600 dark:text-gray-300 font-medium mt-2">
                        Contract Duration (Months)
                    </label>
                    <input
                        type="text"
                        value={additionalFields.contractDuration || ''}
                        readOnly
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        placeholder="Calculated duration in months"
                    />

                    <label className="block text-gray-600 dark:text-gray-300 font-medium mt-2">
                        Reasons for Renewal
                    </label>
                    <textarea
                        value={additionalFields.renewalReason || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                renewalReason: e.target.value,
                            })
                        }
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    ></textarea>
                </div>
            );
        case 'Salary Increment Request':
            return (
                <div>
                    {/* Increment Percentage Field */}
                    <div className="mb-2">
                        <label className="block text-gray-600 dark:text-gray-300 font-medium mb-1">
                            Increment Percentage (%)
                        </label>
                        <input
                            type="number"
                            value={additionalFields.incrementPercentage || ''}
                            onChange={(e) =>
                                setAdditionalFields({
                                    ...additionalFields,
                                    incrementPercentage: e.target.value,
                                })
                            }
                            className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            placeholder="Enter increment percentage"
                        />
                    </div>

                    {/* Increment Amount Field */}
                    <div className="mb-2">
                        <label className="block text-gray-600 dark:text-gray-300 font-medium mb-1">
                            Total Amount After Increment <span className='text-xs text-blue-600'>(New CTC)</span>
                        </label>
                        <input
                            type="number"
                            value={additionalFields.incrementAmount || ''}
                            onChange={(e) =>
                                setAdditionalFields({
                                    ...additionalFields,
                                    incrementAmount: e.target.value,
                                })
                            }
                            className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            placeholder="Enter increment amount"
                        />
                    </div>

                    {/* Specify Reason for Increment Field */}
                    <div className="mb-2">
                        <label className="block text-gray-600 dark:text-gray-300 font-medium mb-1">
                            Specify Reason for Increment
                        </label>
                        <textarea
                            value={additionalFields.incrementReason || ''}
                            onChange={(e) =>
                                setAdditionalFields({
                                    ...additionalFields,
                                    incrementReason: e.target.value,
                                })
                            }
                            className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            placeholder="Enter reason for increment"
                        />
                    </div>
                </div>
            );

        case 'Training Request':
            return (
                <div className="mb-4">
                    <label className="block text-gray-600 dark:text-gray-300 font-medium mb-1">
                        Training Start From
                    </label>
                    <input
                        type="date"
                        value={additionalFields.traningStartDate || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                traningStartDate: e.target.value,
                            })
                        }
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                    <label className="block text-gray-600 dark:text-gray-300 font-medium mt-2 mb-1">
                        To
                    </label>
                    <input
                        type="date"
                        value={additionalFields.traningEndDate || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                traningEndDate: e.target.value,
                            })
                        }
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                    <label className="block text-gray-600 dark:text-gray-300 font-medium mb-1 mt-2">
                        Training Program Details
                    </label>
                    <textarea
                        value={additionalFields.trainingDetails || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                trainingDetails: e.target.value,
                            })
                        }
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        placeholder="Enter details about the training program"
                    />
                </div>
            );
        case 'Authorization Letter':
            return (
                <div className="mb-4">
                    <label className="block text-gray-600 dark:text-gray-300 font-medium mb-2">
                        More Context about letter
                    </label>
                    <textarea
                        value={additionalFields.letterContext || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                letterContext: e.target.value,
                            })
                        }
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        placeholder="Enter project access details"
                    />
                </div>
            );
        case 'Leave Request':
            return (
                <div className="mb-4">
                    <label className="block text-gray-600 dark:text-gray-300 font-medium mb-2">
                        Leave Type
                    </label>
                    <select
                        value={additionalFields.leaveType || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                leaveType: e.target.value,
                            })
                        }
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    >
                        <option value=""  disabled hidden>Select Leave Type</option>
                        <option value="Annual">Annual</option>
                        <option value="Emergency">Emergency</option>
                        <option value="Hajj Leave">Hajj Leave</option>
                        <option value="Absent">Absent</option>
                    </select>
                    <label className="block text-gray-600 dark:text-gray-300 font-medium my-2">
                        Specify Dates for Leave
                    </label>
                    <label className="block text-gray-600 dark:text-gray-300 font-medium mb-1">
                        From
                    </label>
                    <input
                        type="date"
                        value={additionalFields.startDate || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                startDate: e.target.value,
                            })
                        }
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                    <label className="block text-gray-600 dark:text-gray-300 font-medium mt-2 mb-1">
                        To
                    </label>
                    <input
                        type="date"
                        value={additionalFields.endDate || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                endDate: e.target.value,
                            })
                        }
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />

                    <label className="block text-gray-600 dark:text-gray-300 font-medium mt-2 mb-1">
                        Specify the reason for the Leave
                    </label>
                    <textarea
                        value={additionalFields.leaveReason || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                leaveReason: e.target.value,
                            })
                        }
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    ></textarea>
                </div>
            );
        case 'Employee Certificate Request':
            return (
                <div className="mb-4">
                    <label className="block text-gray-600 dark:text-gray-300 font-medium mb-2">
                        Certificate Type
                    </label>
                    <select
                        value={additionalFields.certificateType || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                certificateType: e.target.value,
                            })
                        }
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    >
                        <option value="Employment">Employment</option>
                        <option value="Salary">Salary</option>
                        <option value="Experience">Experience</option>
                        <option value="Other">Other</option>
                    </select>

                    {
                        additionalFields.certificateType === 'Other' &&
                        <div>
                            <label className="block text-gray-600 dark:text-gray-300 font-medium mt-2">
                                purpose of the certificate
                            </label>
                            <textarea
                                value={additionalFields.purposeOfCertificate || ''}
                                onChange={(e) =>
                                    setAdditionalFields({
                                        ...additionalFields,
                                        purposeOfCertificate: e.target.value,
                                    })
                                }
                                className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            ></textarea>
                        </div>
                    }
                </div>
            );
        case 'Resignation Request':
            return (
                <div className="mb-4">
                    <label className="block text-gray-600 dark:text-gray-300 font-medium mb-2">
                        Last Working Day
                    </label>
                    <input
                        type="date"
                        value={additionalFields.lastWorkingDay || ''}
                        onChange={(e) => {
                            setAdditionalFields({
                                ...additionalFields,
                                lastWorkingDay: e.target.value,
                            });
                        }}
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />

                    <label className="block text-gray-600 dark:text-gray-300 font-medium mt-2">
                        Reasons for Resignation
                    </label>
                    <textarea
                        value={additionalFields.resignationReason || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                resignationReason: e.target.value,
                            })
                        }
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    ></textarea>
                </div>
            );
        case 'Fixation of Salary Request':
            return (
                <div className="mb-4">
                    <label className="block text-gray-600 dark:text-gray-300 font-medium mt-2">
                        Specify the reason for the request
                    </label>
                    <textarea
                        value={additionalFields.fixationReason || ''}
                        onChange={(e) =>
                            setAdditionalFields({
                                ...additionalFields,
                                fixationReason: e.target.value,
                            })
                        }
                        className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    ></textarea>
                </div>
            )
        default:
            return null;
    }
};

export default RequestBody;

import React, { useEffect, useState } from "react";
import { get, patch } from "../../services/apis/api";
import CreateTeam from "../../components/dialogueBoxes/CreateTeam";
import ViewEvent from "../../components/mediaCenter/ViewEvent";
import ViewCoachProfile from "../../components/game-management/ViewCoachProfile";
import CreateCoach from "../../components/dialogueBoxes/CreateCoach";
import { truncateText } from "../../utills/truncateText";
import { useTranslation } from "react-i18next";
import {
    Switch,
    Box,
    Heading,
    Text,
    Spinner,
    useToast,
} from "@chakra-ui/react";
import { formatNumber } from "../../services/glocalFunctions";

const Coach = () => {
    const [coaches, setCoaches] = useState(null);
    const [viewCoach, setViewCoach] = useState(false);
    const [selectedCoach, setSelectedCoach] = useState({});
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const toast = useToast();

    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const handleViewCoach = (coach) => {
        setViewCoach(true);
        setSelectedCoach(coach);
    };

    // FETCH ALL COACHES
    const getAllCoaches = async (searchQuery = "") => {
        setIsLoading(true);
        try {
            const res = await get(
                `coach/all${searchQuery ? `?search=${searchQuery}` : ""}`
            );
            if (res.statusCode === 200) {
                setCoaches(res?.data?.coaches);
            } else {
                setCoaches([]);
            }
        } catch (e) {
            setCoaches([]);
            toast({
                title: t("error"),
                description: t("failed-to-fetch-coaches"),
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.log(`ERROR WHILE FETCHING COACHES ${e}`);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllCoaches();
    }, []);

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearch(query);
        getAllCoaches(query);
    };

    const handleCheckboxChange = async (id, value) => {
        try {
            const res = await patch(`coach/update/${id}`, { isActive: value });
            if (res.statusCode === 200) {
                toast({
                    title: t("success"),
                    description: t("coach-status-updated"),
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                });
                getAllCoaches();
            }
        } catch (error) {
            toast({
                title: t("error"),
                description: t("failed-to-update-status"),
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.log(error);
        }
    };

    return (
        <Box className="p-6">
            {viewCoach ? (
                <ViewCoachProfile
                    coach={selectedCoach}
                    setViewCoach={setViewCoach}
                    getAllCoaches={getAllCoaches}
                />
            ) : (
                <div>
                    <Box mb={6}>
                        <Heading size="lg" mb={4}>
                            {t("coaches")}
                        </Heading>
                        <div
                            className={`flex flex-col md:flex-row gap-4 ${
                                isArabic ? "md:flex-row-reverse" : ""
                            } justify-between items-center`}
                        >
                            <div className="w-full md:w-auto">
                                <div className="relative">
                                    <div
                                        className={`absolute inset-y-0 ${
                                            isArabic
                                                ? "start-auto end-0 pe-3"
                                                : "start-0 ps-3"
                                        } flex items-center pointer-events-none`}
                                    >
                                        <svg
                                            className="w-4 h-4 text-gray-500"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                            />
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        className={`block w-full p-2 ${
                                            isArabic
                                                ? "pe-10 text-right"
                                                : "ps-10 text-left"
                                        } text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out`}
                                        placeholder={t("search-for-coaches")}
                                        value={search}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                            <div>
                                <CreateCoach getAllCoaches={getAllCoaches} />
                            </div>
                        </div>
                    </Box>

                    <div
                        className="relative overflow-hidden shadow-md sm:rounded-lg bg-white"
                        dir={isArabic ? "rtl" : "ltr"}
                    >
                        {isLoading ? (
                            <div className="flex justify-center items-center h-64">
                                <Spinner size="xl" color="blue.500" />
                            </div>
                        ) : !coaches || coaches.length === 0 ? (
                            <div className="flex flex-col justify-center items-center h-64 text-center">
                                <Box className="text-gray-500 mb-4">
                                    <svg
                                        className="w-16 h-16 mx-auto mb-4"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                                        />
                                    </svg>
                                    <Text fontSize="lg" fontWeight="medium">
                                        {t("no-coaches-found")}
                                    </Text>
                                    <Text color="gray.500" mt={2}>
                                        {t("try-different-search")}
                                    </Text>
                                </Box>
                            </div>
                        ) : (
                            <div className="overflow-x-auto">
                                <table className="w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-4 text-start text-xs font-semibold text-gray-600 uppercase tracking-wider w-20"
                                            >
                                                {t("s-no")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-4 text-start text-xs font-semibold text-gray-600 uppercase tracking-wider"
                                            >
                                                {t("coach-name")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-4 text-start text-xs font-semibold text-gray-600 uppercase tracking-wider"
                                            >
                                                {t("game")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-4 text-start text-xs font-semibold text-gray-600 uppercase tracking-wider"
                                            >
                                                {t("email")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-4 text-start text-xs font-semibold text-gray-600 uppercase tracking-wider"
                                            >
                                                {t("specialization")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-4 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider w-48"
                                            >
                                                {t("action")}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {coaches.map((coach, index) => (
                                            <tr
                                                key={index}
                                                className="hover:bg-gray-50 transition-colors duration-200"
                                            >
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <Text className="text-sm text-gray-900">
                                                        {formatNumber(
                                                            index + 1,
                                                            isArabic
                                                        )}
                                                    </Text>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <Text className="text-sm font-medium text-gray-900">
                                                        {coach?.name || "N/A"}
                                                    </Text>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <Text className="text-sm text-gray-900">
                                                        {coach?.gameId?.name ||
                                                            "N/A"}
                                                    </Text>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <Text className="text-sm text-gray-900">
                                                        {coach?.email ||
                                                            "N/A"}
                                                    </Text>
                                                </td>
                                                <td className="px-6 py-4">
                                                    <Text className="text-sm text-gray-900">
                                                        {truncateText(
                                                            coach?.specialization,
                                                            150
                                                        ) || "N/A"}
                                                    </Text>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center justify-center gap-4">
                                                        <Switch
                                                            id={`coach-${coach._id}`}
                                                            isChecked={
                                                                coach.isActive
                                                            }
                                                            onChange={() =>
                                                                handleCheckboxChange(
                                                                    coach?._id,
                                                                    !coach.isActive
                                                                )
                                                            }
                                                            size="md"
                                                            colorScheme="blue"
                                                        />
                                                        <button
                                                            onClick={() =>
                                                                handleViewCoach(
                                                                    coach
                                                                )
                                                            }
                                                            className="text-white bg-blue.600 hover:bg-blue.700 font-medium py-2 px-4 rounded-md transition duration-150 ease-in-out bg-blue-600 hover:bg-blue-700"
                                                        >
                                                            {t("view")}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Box>
    );
};

export default Coach;

import React from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormLabel,
    Input,
    Radio,
    RadioGroup,
    Stack,
    Textarea,
    VStack,
    HStack,
    Grid,
    GridItem,
    Image,
    IconButton,
} from "@chakra-ui/react";
import { EmployeeState } from "../../../../context/addEmployee";
import { CloseIcon } from "@chakra-ui/icons";
import { t } from "i18next";

const PersonalInfo = () => {
    const { personalInfo, setPersonalInfo } = EmployeeState();

    console.log("persnal-info----->", personalInfo);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            setPersonalInfo((prevDetails) => ({
                ...prevDetails,
                agreements: { ...prevDetails.agreements, [name]: checked },
            }));
        } else {
            setPersonalInfo((prevDetails) => ({
                ...prevDetails,
                [name]: value,
            }));
        }
    };

    const handleDocumentChange = (event) => {
        setPersonalInfo((prevDetails) => ({
            ...prevDetails,
            documents: [
                ...prevDetails.documents,
                ...Array.from(event.target.files),
            ],
        }));
    };

    const handleRemoveDocument = (indexToRemove) => {
        setPersonalInfo((prevDetails) => ({
            ...prevDetails,
            documents: prevDetails.documents.filter(
                (_, index) => index !== indexToRemove
            ),
        }));
    };

    const inputStyles = {
        borderColor: "gray.300",
        borderRadius: "md",
        _hover: { borderColor: "gray.500" },
        _focus: { borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" },
    };

    return (
        <Box p={5} borderRadius="md" bg="white" mx="auto">
            <VStack spacing={4} align="stretch">
                <Grid
                    templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
                    gap={4}
                >
                    <GridItem colSpan={{ base: 1, md: 1 }}>
                        <FormControl id="alternate-contact">
                            <FormLabel>{t("alternate-contact-no")}</FormLabel>
                            <Input
                                type="text"
                                name="alternateContact"
                                placeholder={t("enter-alternate-contact-no")}
                                value={personalInfo.alternateContact || ""}
                                onChange={handleChange}
                                {...inputStyles}
                            />
                        </FormControl>
                    </GridItem>
                    <GridItem colSpan={{ base: 1, md: 1 }}>
                        <FormControl id="aadhar-no">
                            <FormLabel>
                                {t("gov-id-number")}{" "}
                                <span className="text-red-500">*</span>
                            </FormLabel>
                            <Input
                                type="text"
                                name="govIdNumber"
                                placeholder={t("enter-gov-id-number")}
                                value={personalInfo.govIdNumber || ""}
                                onChange={handleChange}
                                {...inputStyles}
                            />
                        </FormControl>
                    </GridItem>
                    <GridItem colSpan={{ base: 1, md: 1 }}>
                        <FormControl id="pan-card">
                            <FormLabel>
                                {t("gov-id-expiration-date")}
                                <span className="text-red-500">*</span>
                            </FormLabel>
                            <Input
                                type="date"
                                name="govIdExpiration"
                                placeholder={t("enter-govIdExpiration")}
                                value={personalInfo.govIdExpiration || ""}
                                onChange={handleChange}
                                {...inputStyles}
                            />
                        </FormControl>
                    </GridItem>
                    <GridItem colSpan={{ base: 1, md: 1 }}>
                        <FormControl id="driving-license">
                            <FormLabel>{t("driving-license")}</FormLabel>
                            <Input
                                type="text"
                                name="drivingLicense"
                                placeholder={t("enter-driving-license")}
                                value={personalInfo.drivingLicense || ""}
                                onChange={handleChange}
                                {...inputStyles}
                            />
                        </FormControl>
                    </GridItem>
                    <GridItem colSpan={{ base: 1, md: 1 }}>
                        <FormControl id="pay-method">
                            <FormLabel>{t("pay-method")}</FormLabel>
                            <RadioGroup
                                name="payMethod"
                                value={personalInfo.payMethod || ""}
                                onChange={(value) =>
                                    setPersonalInfo((prevDetails) => ({
                                        ...prevDetails,
                                        payMethod: value,
                                    }))
                                }
                            >
                                <Stack direction="row">
                                    <Radio value="Cash">{t("cash")}</Radio>
                                    <Radio value="Bank">{t("bank")}</Radio>
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                    </GridItem>
                </Grid>

                {personalInfo.payMethod === "Cash" ? (
                    ""
                ) : (
                    <Grid>
                        <GridItem colSpan={{ base: 1, md: 1 }}>
                            <FormControl id="holder-name">
                                <FormLabel>{t("holder-name")}</FormLabel>
                                <Input
                                    type="text"
                                    name="holderName"
                                    placeholder={t("enter-holder-name")}
                                    value={personalInfo.holderName || ""}
                                    onChange={handleChange}
                                    {...inputStyles}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={{ base: 1, md: 1 }}>
                            <FormControl id="account-no">
                                <FormLabel>{t("account-no")}</FormLabel>
                                <Input
                                    type="text"
                                    name="accountNo"
                                    placeholder={t("enter-account-no")}
                                    value={personalInfo.accountNo || ""}
                                    onChange={handleChange}
                                    {...inputStyles}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={{ base: 1, md: 1 }}>
                            <FormControl id="bank-name">
                                <FormLabel>{t("bank-name")}</FormLabel>
                                <Input
                                    type="text"
                                    name="bankName"
                                    placeholder={t("enter-bank-name")}
                                    value={personalInfo.bankName || ""}
                                    onChange={handleChange}
                                    {...inputStyles}
                                />
                            </FormControl>
                        </GridItem>
                    </Grid>
                )}
                <FormControl id="permanent-address">
                    <FormLabel>{t("permanent-address")}</FormLabel>
                    <Textarea
                        name="permanentAddress"
                        placeholder={t("enter-permanent-address")}
                        value={personalInfo.permanentAddress || ""}
                        onChange={handleChange}
                        {...inputStyles}
                    />
                </FormControl>
                <FormControl id="current-address">
                    <FormLabel>{t("current-addres")}</FormLabel>
                    <Checkbox
                        name="sameAsPermanentAddress"
                        isChecked={personalInfo.sameAsPermanentAddress || false}
                        onChange={(e) => {
                            const checked = e.target.checked;
                            setPersonalInfo((prevDetails) => ({
                                ...prevDetails,
                                sameAsPermanentAddress: checked,
                                currentAddress: checked
                                    ? prevDetails.permanentAddress
                                    : "",
                            }));
                        }}
                    >
                        {t("same-as-permanent-address")}
                    </Checkbox>
                    <Textarea
                        name="currentAddress"
                        placeholder={t("enter-current-address")}
                        value={personalInfo.currentAddress || ""}
                        onChange={handleChange}
                        isDisabled={personalInfo.sameAsPermanentAddress}
                        {...inputStyles}
                    />
                </FormControl>
                <FormControl id="attach-documents">
                    <FormLabel>{t("attach-documents")}</FormLabel>
                    <Input
                        type="file"
                        multiple
                        onChange={handleDocumentChange}
                    />
                    <Stack direction="row" spacing={4} mt={2} wrap="wrap">
                        {personalInfo.documents &&
                            personalInfo.documents.map((doc, index) => (
                                <Box key={index} position="relative">
                                    <Image
                                        src={
                                            typeof doc === "string"
                                                ? doc
                                                : URL.createObjectURL(doc)
                                        }
                                        alt={`document-${index}`}
                                        boxSize="100px"
                                        borderRadius="md"
                                    />
                                    <IconButton
                                        icon={<CloseIcon />}
                                        size="xs"
                                        position="absolute"
                                        top="0"
                                        right="0"
                                        onClick={() =>
                                            handleRemoveDocument(index)
                                        }
                                        bg="red.500"
                                        color="white"
                                        borderRadius="full"
                                        aria-label="Remove document"
                                    />
                                </Box>
                            ))}
                    </Stack>
                </FormControl>
                {/* <FormControl id="agreements" className="flex flex-col"> */}
                <FormLabel>{t("agreements")}</FormLabel>
                <Checkbox
                    name="agreeDetails"
                    isChecked={personalInfo.agreements.agreeDetails || false}
                    onChange={handleChange}
                >
                    {t("I,-the-employee,-agree-with-the-following-statements")}
                </Checkbox>
                <Checkbox
                    name="agreeTerms"
                    isChecked={personalInfo.agreements.agreeTerms || false}
                    onChange={handleChange}
                >
                    {t("I,-the-employee,-understand-the-terms")}
                </Checkbox>
                {/* </FormControl> */}
            </VStack>
        </Box>
    );
};

export default PersonalInfo;

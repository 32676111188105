import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  useToast,
  Spinner,
  FormControl,
  FormLabel,
  Input,
  Select,
  Grid,
  GridItem
} from "@chakra-ui/react";
import { get, post } from "../../services/apis/api";

const CreateTeam = ({ getAllTeams }) => {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const [games, setGames] = useState([]);
    const [ageCategories, setAgeCategories] = useState([]);
    const [formValues, setFormValues] = useState({
        name: '',
        ageCategoryId: '',
        gameId: '',
        totalPlayer: '',
        playingPlayer: '',
        startTime: '',
        endTime: ''
    });

    const getAllAgeCategories = async () => {
        const res = await get('agecategories/dropdown');
        if (res.statusCode === 200) {
            setAgeCategories(res.data.dropdown);
        }
    };

    const getAllGames = async (ageCategoryId) => {
        const res = await get(`game/dropdown?ageCategoryId=${ageCategoryId}`);
        if (res.statusCode === 200) {
            setGames(res.data.dropdown);
        }
    };

    useEffect(() => {
        getAllAgeCategories();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));

        // Fetch games when age category changes
        if (name === 'ageCategoryId') {
            setGames([]); // Clear the games dropdown
            if (value) {
                getAllGames(value);
            }
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const formData = {
                ...formValues,
                startTime: new Date(`1970-01-01T${formValues.startTime}:00Z`),
                endTime: new Date(`1970-01-01T${formValues.endTime}:00Z`)
            };

            const res = await post('team/add', formData);
            if (res.statusCode === 200) {
                toast({
                    title: "Team created.",
                    description: res.data.message,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                getAllTeams();
                onClose();
                setLoading(false);
            }
        } catch (error) {
            toast({
                title: "An error occurred.",
                description: error?.response?.data?.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button onClick={onOpen}>Create Team</Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size='5xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create New Team</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                            <GridItem>
                                <FormControl>
                                    <FormLabel>Name</FormLabel>
                                    <Input
                                        placeholder='Name'
                                        name='name'
                                        value={formValues.name}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl>
                                    <FormLabel>Age Category</FormLabel>
                                    <Select
                                        placeholder='Select Age Category'
                                        name='ageCategoryId'
                                        value={formValues.ageCategoryId}
                                        onChange={handleChange}
                                    >
                                        {ageCategories.map((category, index) => (
                                            <option key={index} value={category._id}>
                                                {category?.name}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl>
                                    <FormLabel>Game</FormLabel>
                                    <Select
                                        placeholder='Select Game'
                                        name='gameId'
                                        value={formValues.gameId}
                                        onChange={handleChange}
                                        isDisabled={!formValues.ageCategoryId} // Disable if no age category selected
                                    >
                                        {games.map((game, index) => (
                                            <option key={index} value={game._id}>
                                                {game?.name + " (" + game?.stage + ")"}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl>
                                    <FormLabel>Number Of Players</FormLabel>
                                    <Input
                                        type='number'
                                        placeholder='Total Players'
                                        name='totalPlayer'
                                        value={formValues.totalPlayer}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl>
                                    <FormLabel>Playing Players</FormLabel>
                                    <Input
                                        placeholder='Playing Players'
                                        size='md'
                                        type='number'
                                        name='playingPlayer'
                                        value={formValues.playingPlayer}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl>
                                    <FormLabel>Start Time</FormLabel>
                                    <Input
                                        placeholder='Start Time'
                                        type='time'
                                        name='startTime'
                                        value={formValues.startTime}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl>
                                    <FormLabel>End Time</FormLabel>
                                    <Input
                                        placeholder='End Time'
                                        type='time'
                                        name='endTime'
                                        value={formValues.endTime}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </GridItem>
                        </Grid>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={handleSubmit} isLoading={loading}>
                            Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateTeam;

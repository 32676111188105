import React, { useEffect, useState } from 'react';
import { get } from '../../services/apis/api';
import CreateTeam from '../../components/dialogueBoxes/CreateTeam';
import ViewTeam from '../../components/game-management/ViewTeam';

const Team = () => {
  const [teams, setTeams] = useState([]);
  const [viewTeam, setViewTeam] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState({});
  const [search, setSearch] = useState('');

  const getAllTeams = async (searchQuery = '') => {
    const res = await get(`team/all${searchQuery ? `?search=${searchQuery}` : ''}`);
    console.log(res);
    setTeams(res.data.team);
  };

  const handleView = async (team) => {
    setSelectedTeam(team);
    setViewTeam(true);
  };

  useEffect(() => {
    getAllTeams();
  }, []);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearch(query);
    getAllTeams(query);
  };

  return (
    <div>
      {viewTeam ? (
        <ViewTeam teamId={selectedTeam._id} setViewTeam={setViewTeam} />
      ) : (
        <div>
          <div className='flex justify-between px-2 items-center mb-4'>
            <div>
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                  </svg>
                </div>
                <input
                  type="text"
                  id="table-search-users"
                  className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Search for team"
                  value={search}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            <div>
              <CreateTeam getAllTeams={getAllTeams} />
            </div>
          </div>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="p-4">
                    <div className="flex items-center">S.No.</div>
                  </th>
                  <th scope="col" className="px-6 py-3">Team Name</th>
                  <th scope="col" className="px-6 py-3">Coach Name</th>
                  <th scope="col" className="px-6 py-3">Game</th>
                  <th scope="col" className="px-6 py-3">Total Player</th>
                  <th scope="col" className="px-6 py-3">Playing Player</th>
                  <th scope="col" className="px-6 py-3">Action</th>
                </tr>
              </thead>
              <tbody>
                {teams.map((team, index) => (
                  <tr key={team._id} className="bg-white border-b hover:bg-gray-50">
                    <td className="w-4 p-4">
                      <div className="flex items-center">{index + 1}</div>
                    </td>
                    <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                      <div className="text-base font-semibold">{team.name}</div>
                    </th>
                    <td className="px-6 py-4">{team?.coachId?.name}</td>
                    <td className="px-6 py-4">
                      <div className="flex items-center">{team?.gameId?.name}</div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center">{team.totalPlayer}</div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center">{team.playingPlayer}</div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <button onClick={() => { handleView(team); }} className='text-white bg-blue-600 font-semibold py-2 px-4 rounded-md'>View</button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Team;


import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';

const ConfirmRemoveTransaction = ({
    isOpen,
    onClose,
    isLoading,
    onDeleteTransaction
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return (
        <>
            <Modal
                // initialFocusRef={initialRef}
                // finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader textAlign={isArabic ? 'right' : 'left'} dir={isArabic ? 'rtl' : 'ltr'}>{t('remove-payment')}</ModalHeader>
                    <ModalCloseButton
                        style={{
                            [isArabic ? 'left' : 'right']: '10px' // Conditional positioning based on language
                        }}
                    />
                    <ModalBody dir={isArabic ? "rtl" : "ltr"} textAlign={isArabic ? "right" : "left"}>
                        {t('confirm-payment-msg')}
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={4}
                            onClick={() => {
                                onDeleteTransaction();
                            }}
                            colorScheme="red"
                            isLoading={isLoading}
                            variant="ghost"
                            size="sm"
                        >
                            {t('delete')}
                        </Button>
                        <Button
                            colorScheme="blue"
                            variant="solid"
                            size="sm"
                            onClick={onClose}
                        >
                            {t('cancel')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >

        </>
    )
}

export default ConfirmRemoveTransaction

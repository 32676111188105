import React from 'react'
import {
    Button,
    Flex,
    Heading,
    Image,
    useBreakpointValue,
    Box,
    Container,
    SimpleGrid,
    Icon,
    Text,
    Stack,
    HStack,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react'
import {
    FcAbout,
    FcAssistant,
    FcCollaboration,
    FcDonate,
    FcManager,
} from 'react-icons/fc'
import { CheckIcon } from '@chakra-ui/icons'
import meet from '../assets/images/club-meeting.jpg'
import { Link } from 'react-router-dom'
import CreateOrgnization from '../components/dialogueBoxes/CreateOrgnization'

const Card = ({ heading, description, icon, href }) => {
    return (
        <Box
        maxW={{ base: 'full', md: '275px' }}
        w={'full'}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={5}>
        <Stack align={'start'} spacing={2}>
            <Flex
            w={16}
            h={16}
            align={'center'}
            justify={'center'}
            color={'white'}
            rounded={'full'}
            bg={useColorModeValue('gray.100', 'gray.700')}>
            {icon}
            </Flex>
            <Box mt={2}>
            <Heading size="md">{heading}</Heading>
            <Text mt={1} fontSize={'sm'}>
                {description}
            </Text>
            </Box>
            <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
            Learn more
            </Button>
        </Stack>
        </Box>
    )
}


const Home = () => {
    return (
        <div>
            {/* hero section */}
            <Stack h={'60vh'} direction={{ base: 'column', md: 'row' }} mt={4}>
                <Flex p={8} flex={1} align={'center'} justify={'center'}>
                    <Stack spacing={6} w={'full'} maxW={'lg'}>
                        <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                            <Text
                                as={'span'}
                                position={'relative'}
                                _after={{
                                    content: "''",
                                    width: 'full',
                                    height: useBreakpointValue({ base: '20%', md: '30%' }),
                                    position: 'absolute',
                                    bottom: 1,
                                    left: 0,
                                    bg: 'blue.400',
                                    zIndex: -1,
                                }}>
                                We power
                            </Text>
                            <br />{' '}
                            <Text color={'#290dbd'} as={'span'}>
                                your performance
                            </Text>{' '}
                        </Heading>
                        <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>
                            Complete software solution for modern clubs and coaches
                        </Text>
                        <Stack>
                            <HStack align={'top'}>
                                <Box color={'green.400'} px={2}>
                                    <Icon as={CheckIcon} />
                                </Box>
                                <VStack align={'start'}>
                                    <Text fontWeight={600}>Monitor and analyze all training data</Text>
                                </VStack>
                            </HStack>
                            <HStack align={'top'}>
                                <Box color={'green.400'} px={2}>
                                    <Icon as={CheckIcon} />
                                </Box>
                                <VStack align={'start'}>
                                    <Text fontWeight={600}>Make data-driven coaching decisions</Text>
                                </VStack>
                            </HStack>
                            <HStack align={'top'}>
                                <Box color={'green.400'} px={2}>
                                    <Icon as={CheckIcon} />
                                </Box>
                                <VStack align={'start'}>
                                    <Text fontWeight={600}>Give instant feedback to your players</Text>
                                </VStack>
                            </HStack>
                        </Stack>
                        <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
                            {/* <CreateOrgnization /> */}
                            <Button rounded={'full'}>How It Works</Button>
                        </Stack>
                    </Stack>
                </Flex>
                <Flex flex={1}>
                    <Image
                        borderRadius={8}
                        alt={'Login Image'}
                        objectFit={'cover'}
                        src={meet}
                    />
                </Flex>
            </Stack>
            {/* hero section end */}

            {/* details here */}
            <Box p={4} my={10}>
                <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
                    <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
                    Main Features
                    </Heading>
                    <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }}>
                        You need a powerful solution for your athletes to excel Save time and keep everything in one place
                    </Text>
                </Stack>

                <Container maxW={'10xl'} mt={12}>
                    <Flex flexWrap="wrap" gridGap={6} justify="center">
                    <Card
                        heading={'Communicate with your athletes'}
                        icon={<Icon as={FcAssistant} w={10} h={10} />}
                        description={''}
                        href={'#'}
                    />
                    <Card
                        heading={'Analyze performance and training'}
                        icon={<Icon as={FcCollaboration} w={10} h={10} />}
                        description={''}
                        href={'#'}
                    />
                    <Card
                        heading={'Create high levels of accountability and feedback'}
                        icon={<Icon as={FcDonate} w={10} h={10} />}
                        description={''}
                        href={'#'}
                    />
                    <Card
                        heading={'Build your lifetime of coaching observation'}
                        icon={<Icon as={FcManager} w={10} h={10} />}
                        description={''}
                        href={'#'}
                    />
                    </Flex>
                </Container>
            </Box>
            {/* details here */}


        </div>
    )
}

export default Home;


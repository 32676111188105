import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Button,
    Select,
    useToast,
    Textarea,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';

// RECOVERY STATUS OPTIONS 
const recoveryStatusOptions = [
    { label: "not_started", value: "NOT_STARTED" },
    { label: "in_progress", value: "IN_PROGRESS" },
    { label: "completed", value: "COMPLETED" },
    { label: "follow_up", value: "FOLLOW_UP" },
    { label: "requires_treatment", value: "REQUIRES_FURTHER_TREATMENT" },
    { label: "discharged", value: "DISCHARGED" }
];

function UpdateRecoveryDialogue({
    isOpen,
    onClose,
    formData,
    onHandleChange,
    isLoading,
    onHandleSubmit,
}) {
    // const { isOpen, onOpen, onClose } = useDisclosure()
    const { t, i18n } = useTranslation();
    const { user } = UserState();
    const toast = useToast();
    const isArabic = i18n.language === 'ar';
    const [error, setError] = useState('')
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    return (
        <>
            {/* <Button >Open Modal</Button> */}
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign={isArabic ? 'right' : 'left'}>
                        {t('update-recovery')}
                    </ModalHeader>
                    <ModalCloseButton
                        style={{
                            [isArabic ? 'left' : 'right']: '10px' // Conditional positioning based on language
                        }}
                    />
                    <ModalBody pb={6}>
                        {/* UPDATE RECOVERY STATUS  */}
                        <FormControl mt={4}>
                            <FormLabel textAlign={isArabic ? 'right' : 'left'}>{t('recovery-status')} <span className='text-red-600'>*</span></FormLabel>
                            <Select
                                value={formData?.recoveryStatus}
                                onChange={(e) => {
                                    onHandleChange('recoveryStatus', e?.target?.value)
                                }}
                                placeholder={t('select-recovery-status')}
                            >
                                {recoveryStatusOptions?.map((status, index) => (
                                    <option key={index} value={status?.value}>
                                        {t(status?.label)}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>

                        {/* ADD FEEDBACK  */}
                        <FormControl mt={4}>
                            <FormLabel textAlign={isArabic ? 'right' : 'left'}>{t('feedback')}</FormLabel>
                            <Textarea
                                value={formData?.feedback}
                                textAlign={isArabic ? "right" : "left"}
                                placeholder={t('add-feedback')}
                                resize="vertical"  // Optional: allows vertical resizing of the textarea
                                onChange={(e) => {
                                    onHandleChange('feedback', e?.target?.value)
                                }}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={4}
                            onClick={onClose}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={onHandleSubmit}
                            colorScheme='blue'
                            isLoading={isLoading}
                            isDisabled={!formData?.recoveryStatus}
                        >
                            {t('update')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    )
}

export default UpdateRecoveryDialogue
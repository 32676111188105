import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    Input,
    FormLabel,
    Button,
    Select,
    useToast,
    Textarea
} from '@chakra-ui/react'
import { set } from 'lodash';

import React from 'react'
import { BiImage } from 'react-icons/bi'
import { postFormData } from '../../services/apis/api';
import { useTranslation } from 'react-i18next';

function AddCategoryModel({ isOpen, onClose, onReset, categoryFormData, onHandleChange, isLoading, onHandleSubmit }) {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const imgRef = React.useRef(null);

    // Function to handle file selection
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Create a URL for the selected image
            const imageURL = URL.createObjectURL(file);
            onHandleChange('selectedImg', imageURL);
            onHandleChange('file', file);
        }
    };

    return (
        <>
            {/* <Button >Open Modal</Button> */}
            <Modal
                // initialFocusRef={initialRef}
                // finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign={isArabic ? 'right' : 'left'} dir={isArabic ? 'rtl' : 'ltr'}>{t('create-new-income-category')}</ModalHeader>
                    <ModalCloseButton
                        style={{
                            [isArabic ? 'left' : 'right']: '10px' // Conditional positioning based on language
                        }}
                    />
                    <ModalBody pb={6}>
                        <FormControl
                            direction={isArabic ? 'rtl' : 'ltr'}
                        >
                            <FormLabel textAlign={isArabic ? 'right' : 'left'}>{t('name')} </FormLabel>
                            <Input
                                value={categoryFormData?.name}
                                textAlign={isArabic ? 'right' : 'left'}
                                onChange={(e) => {
                                    onHandleChange('name', e?.target?.value)
                                }}
                                placeholder={t('name')}
                            />
                        </FormControl>
                        <FormControl
                            mt={4}
                            direction={isArabic ? 'rtl' : 'ltr'}
                        >
                            <FormLabel textAlign={isArabic ? 'right' : 'left'}>{t('arabic-name')} </FormLabel>
                            <Input
                                value={categoryFormData?.arabicName}
                                textAlign={isArabic ? 'right' : 'left'}
                                onChange={(e) => {
                                    onHandleChange('arabicName', e?.target?.value)
                                }}
                                placeholder={t('arabic-name')}
                            />
                        </FormControl>
                        <FormControl
                            direction={isArabic ? 'rtl' : 'ltr'}
                            mt={4}
                        >
                            <FormLabel textAlign={isArabic ? 'right' : 'left'}>{t('description')} </FormLabel>
                            <Textarea
                                value={categoryFormData?.description}
                                textAlign={isArabic ? 'right' : 'left'}
                                onChange={(e) => {
                                    onHandleChange('description', e.target.value)
                                }}
                                placeholder={t('description')}
                            />
                        </FormControl>
                        {/* <FormControl mt={4}>
                            <FormLabel>Category Mode</FormLabel>
                            <Select
                                value={categoryFormData?.mode}
                                onChange={(e) => {
                                    onHandleChange('mode', e.target.value)
                                }}
                                placeholder='Select Mode'
                            >
                                <option value='online'>Online</option>
                                <option value='offline'>Offline </option>
                            </Select>
                        </FormControl> */}
                        {/* <FormControl mt={4}>
                            <FormLabel>Select Category Img</FormLabel>
                            {!categoryFormData?.selectedImg && (
                                <button
                                    onClick={() => imgRef.current.click()}
                                    className='flex justify-center items-center my-3 border-2 border-[#5E3EF5] py-2 px-4 text-[#5E3EF5] font-semibold text-base rounded-lg'>
                                    <BiImage className='me-2' size={16} />
                                    Select
                                </button>
                            )}
                            Hidden file input field
                            <input
                                ref={imgRef}
                                onChange={handleImageChange}
                                type='file'
                                accept="image/*"
                                className='hidden' // Hide the input, show button instead
                            />

                            Display the selected image
                            {categoryFormData?.selectedImg && (
                                <img
                                    src={categoryFormData?.selectedImg}
                                    onClick={() => imgRef.current.click()}
                                    alt="Selected"
                                    className='mt-4 w-32 h-32 object-cover rounded-lg'
                                />
                            )}
                        </FormControl> */}
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={4}
                            onClick={onReset}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={onHandleSubmit}
                            // onClick={handleSave}
                            colorScheme='blue'
                            isLoading={isLoading}
                            isDisabled={categoryFormData?.name ? false : true}
                        >
                            {t('add')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    )
}

export default AddCategoryModel
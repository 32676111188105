import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Button,
  useToast,
  Spinner,
  Switch,
  Select,
} from '@chakra-ui/react';
import { post, patch } from '../../services/apis/api';

const CreateExpense = ({ getAllExpenses, expanse, isEdit }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const toast = useToast();

  const [formValues, setFormValues] = useState({
    category: '',
    amount: '',
    description: '',
    expenseDate: '',
    isActive: false,
  });

  useEffect(() => {
    if (isEdit && expanse) {
      setFormValues({
        category: expanse.category || '',
        amount: expanse.amount || '',
        description: expanse.description || '',
        expenseDate: formatDate(expanse.expenseDate) || '',
        isActive: expanse.isActive || false,
      });
    }
  }, [isEdit, expanse]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSwitchChange = (e) => {
    setFormValues({
      ...formValues,
      isActive: e.target.checked,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      let res;
      if (isEdit) {
        res = await patch(`expense/update/${expanse._id}`, formValues);
      } else {
        res = await post('expense/add', formValues);
      }
      if (res.statusCode === 201 || res.statusCode === 200) {
        onClose();
        getAllExpenses();
        setIsLoading(false);
        toast({
          title: 'Successful!',
          description: res.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: 'Oops!',
        description: error?.response?.data?.message || 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error(error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const formattedDate = date.toISOString().slice(0, 16);
    return formattedDate;
  };

  return (
    <>
      <Button colorScheme='blue' onClick={onOpen}>
        {isEdit ? 'Edit' : 'Create Expense'}
      </Button>
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEdit ? 'Edit Expense' : 'Create New Expense'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Category</FormLabel>
              <Select
                ref={initialRef}
                placeholder='Category'
                name='category'
                value={formValues.category}
                onChange={handleChange}
              >
                <option value='Office'>Office</option>
                <option value='Marketing'>Marketing</option>
                <option value='Transport'>Transport</option>
                <option value='Miscellaneous'>Miscellaneous</option>
              </Select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Amount</FormLabel>
              <Input
                type='number'
                placeholder='Amount'
                name='amount'
                value={formValues.amount}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Input
                placeholder='Description'
                name='description'
                value={formValues.description}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Date</FormLabel>
              <Input
                placeholder='Select Date and Time'
                size='md'
                type='datetime-local'
                name='expenseDate'
                value={formValues.expenseDate}
                onChange={handleChange}
              />
            </FormControl>
            {isEdit && (
              <FormControl mt={5} display='flex' alignItems='center'>
                <FormLabel htmlFor='is-active' mb='0'>
                  Status of Expense?
                </FormLabel>
                <Switch
                  id='is-active'
                  name='isActive'
                  onChange={handleSwitchChange}
                  isChecked={formValues.isActive}
                />
              </FormControl>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={handleSubmit}>
              {isLoading ? <Spinner /> : isEdit ? 'Update' : 'Save'}
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateExpense;

import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Button,
  useToast,
  Spinner,
  Switch
} from '@chakra-ui/react';
import Select from 'react-select';
import { post, get, patch } from '../../services/apis/api';

const CreateSupplier = ({ getAllSuppliers, supplier, isEdit }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const toast = useToast();

  const [formValues, setFormValues] = useState({
    companyName: '',
    contactPerson: '',
    email: '',
    mobile: '',
    productsSupplied: [],
    isActive: true,
  });

  const fetchProducts = async () => {
    try {
      const res = await get('product/dropdown');
      setProducts(res.data.dropdown);
      console.log("product", res.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setFormValues({
        companyName: supplier.companyName,
        contactPerson: supplier.contactPerson,
        email: supplier.email,
        mobile: supplier.mobile,
        productsSupplied: supplier.productsSupplied.map(product => ({
          value: product.productId?._id,
          label: product.productId?.name // Assuming `productName` is available
        })),
        isActive: supplier.isActive,
      });
    }
    fetchProducts();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSwitchChange = (e) => {
    console.log("sss",e.target.checked);
    setFormValues({
      ...formValues,
      isActive: e.target.checked,
    });
  };

  const handleSelectChange = (selectedOptions) => {
    setFormValues({
      ...formValues,
      productsSupplied: selectedOptions,
    });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const supplierData = {
        ...formValues,
        productsSupplied: formValues.productsSupplied.map(product => ({
          productId: product.value
        })),
      };
      let res;
      if (isEdit) {
        res = await patch(`supplier/update/${supplier._id}`, supplierData);
      } else {
        res = await post('supplier/add', supplierData);
      }
      if (res.statusCode === 201 || res.statusCode === 200) {
        onClose();
        getAllSuppliers();
        setIsLoading(false);
        toast({
          title: 'Successful!',
          description: res.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'An error occurred.',
        description: error.response?.data?.message || error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button colorScheme="blue" onClick={onOpen}>{isEdit ? "Edit" : "Create Supplier"}</Button>
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEdit ? "Edit Supplier" : "Create New Supplier"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Company Name</FormLabel>
              <Input
                ref={initialRef}
                name="companyName"
                placeholder="Company Name"
                value={formValues.companyName}
                onChange={handleChange}
              />
              <FormLabel>Contact Person</FormLabel>
              <Input
                placeholder="Contact Person"
                name="contactPerson"
                value={formValues.contactPerson}
                onChange={handleChange}
              />
              <FormLabel>Email</FormLabel>
              <Input
                placeholder="Email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
              />
              <FormLabel>Phone</FormLabel>
              <Input
                type="number"
                placeholder="Phone"
                name="mobile"
                value={formValues.mobile}
                onChange={handleChange}
              />
              <FormLabel>Products</FormLabel>
              <Select
                isMulti
                placeholder="Select Products"
                name="productsSupplied"
                value={formValues.productsSupplied}
                onChange={handleSelectChange}
                options={products.map(product => ({
                  value: product._id,
                  label: product.name,
                }))}
              />
              {isEdit && (
                <>
                  <FormControl mt={5} display='flex' alignItems='center'>
                    <FormLabel htmlFor='email-alerts' mb='0'>
                      Status of Supplier ?
                    </FormLabel>
                    <Switch id='email-alerts' name='isActive' onChange={handleSwitchChange} isChecked={formValues.isActive} />
                  </FormControl>
                </>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              {isLoading ? <Spinner /> : isEdit ? "Update" : "Save"}
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateSupplier;

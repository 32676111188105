import React, { useRef } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const CustomAlert = ({
  isOpen,
  onClose,
  title,
  description,
  onConfirm,
  onCancel,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  confirmButtonColorScheme = 'red',
}) => {
  const cancelRef = useRef();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent dir={isArabic ? 'rtl' : 'ltr'}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold" className='flex' >
            {t(title)}
          </AlertDialogHeader>

          <AlertDialogBody className='flex'>
            {t(description)}
          </AlertDialogBody>

          <AlertDialogFooter dir={isArabic ? 'ltr' : 'ltr'} className=''>
            <Button ref={cancelRef} onClick={onClose}>
              {t(cancelButtonText)}
            </Button>
            <Button colorScheme={confirmButtonColorScheme} onClick={onConfirm} mx={3}>
              {t(confirmButtonText)}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default CustomAlert;

'use client'
import React, { useEffect } from 'react'

import { useState } from 'react'
import {
  Progress,
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  GridItem,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  InputLeftAddon,
  InputGroup,
  Textarea,
  FormHelperText,
  InputRightElement,
  Stack,
  Spinner,
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import { get, patch, post } from '../../services/apis/api'
import CountrySelect from '../globles/CountrySelect'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import ReactSelect from 'react-select';


const Form1 = ({ userData, setUserData, isEdit, getEmployee, employees }) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [roles, setRoles] = useState([]);
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  useEffect(() => {
    getEmployee();
    getRoles();
  }, []);



  const getRoles = async () => {
    try {
      const res = await get('user/roles');
      if (res.statusCode === 200) {
        setRoles(res.data.roles);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmployeeChange = (selectedEmployee) => {
    setUserData((prevState) => ({
      ...prevState,
      employeeId: selectedEmployee ? selectedEmployee.value : '',
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <h2 className={`text-lg font-semibold text-[#000] ${isArabic ? 'text-right' : 'text-left'}`}>
        {t("user-details")}
      </h2>

      <Flex direction={isArabic ? 'row-reverse' : 'row'}>
        <FormControl>
          <FormLabel htmlFor='employee' fontWeight={'normal'} textAlign={isArabic ? 'right' : 'left'}>
            {t("select-employee")}<span className='text-red-500'>*</span>
          </FormLabel>
          <ReactSelect
            id="employee"
            name="employeeId"
            options={employees}
            value={employees.find(emp => emp.value === userData.employeeId) || { value: userData._id, label: userData.name }}
            onChange={handleEmployeeChange}
            placeholder={t("select-employee")}
            isClearable
            isDisabled={isEdit}
          />
        </FormControl>
      </Flex>

      <Flex direction={isArabic ? 'row-reverse' : 'row'}>
        <FormControl mr={isArabic ? '0' : '3%'} ml={isArabic ? '3%' : '0'} mt='2%'>
          <FormLabel htmlFor='title' fontWeight={'normal'} textAlign={isArabic ? 'right' : 'left'}>
            {t("job-title")}<span className='text-red-500'>*</span>
          </FormLabel>
          <Input
            id='title'
            type='text'
            name='jobTitle'
            placeholder='E.g. game manager'
            value={userData.jobTitle || ''}
            onChange={handleChange}
            textAlign={isArabic ? 'right' : 'left'}
          />
        </FormControl>

        <FormControl mt='2%'>
          <FormLabel htmlFor='role' fontWeight={'normal'} textAlign={isArabic ? 'right' : 'left'}>
            {t("select-role")}<span className='text-red-500'>*</span>
          </FormLabel>
          <Select
            placeholder={t("select-option")}
            name='role'
            value={userData.role || ''}
            onChange={handleChange}
            textAlign={isArabic ? 'right' : 'left'}
          >
            {roles.map((role, index) => (
              <option
                key={index}
                value={role === 'C.E.O.' || role === 'PRESIDENT' ? 'SUPER-ADMIN' : role}
              >
                {role}
              </option>
            ))}
          </Select>
        </FormControl>
      </Flex>

      {!isEdit && (
        <Flex direction={isArabic ? 'row-reverse' : 'row'} mt={'2%'}>
          <FormControl mr={isArabic ? '0' : '3%'} ml={isArabic ? '3%' : '0'}>
            <FormLabel htmlFor='password' fontWeight={'normal'} mt='2%' textAlign={isArabic ? 'right' : 'left'}>
              {t("password")}<span className='text-red-500'>*</span>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                pr='4.5rem'
                type={show ? 'text' : 'password'}
                placeholder={t("password")}
                name='password'
                value={userData.password || ''}
                onChange={handleChange}
                textAlign={isArabic ? 'right' : 'left'}
              />
              <InputRightElement width='4.5rem'>
                <Button h='1.75rem' size='sm' onClick={handleClick}>
                  {show ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>

          <FormControl>
            <FormLabel htmlFor='confirm-password' fontWeight={'normal'} mt='2%' textAlign={isArabic ? 'right' : 'left'}>
              {t("confirm-password")}
            </FormLabel>
            <InputGroup size='md'>
              <Input
                pr='4.5rem'
                type={show ? 'text' : 'password'}
                placeholder={t("confirm-password")}
                name='cnfPassword'
                value={userData.cnfPassword || ''}
                onChange={handleChange}
                textAlign={isArabic ? 'right' : 'left'}
              />
              <InputRightElement width='4.5rem'>
                <Button h='1.75rem' size='sm' onClick={handleClick}>
                  {show ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </Flex>
      )}
    </>
  );
};



const Form2 = ({ departmentList, setDepartmentList, isEdit, user }) => {


  useEffect(() => {
    getDepartmentAccess();
  }, [])

  const getDepartmentAccess = async () => {
    const res = await get('user-services/me');
    console.log("access res----->", res);
    // setDepartmentList(res.data.data);
    let departmentAccess = [];
    for (let item of res.data.data) {
      const arr = [];
      for (let service of item.documents) {
        const newServices = {
          serviceId: service.serviceId,
          serviceName: service.serviceName,
          organizationServiceId: service.organizationServiceId,
          organizationId: service.organizationId,
          read: false,
          create: false,
          update: false,
          delete: false,
        }
        arr.push(newServices);
      }
      let obj = {
        departmentName: item._id,
        services: arr,
      }
      departmentAccess.push(obj);
    }

    if (isEdit) {
      const res = await get(`user-services/user/${user._id}`);
      console.log("res user access--->", res);
      departmentAccess.forEach(department => {
        department.services.forEach(service => {
          const permission = res.data.data.find(perm => perm.serviceId === service.serviceId);
          if (permission) {
            // Update permissions
            service.id = permission._id
            service.read = permission.read;
            service.create = permission.create;
            service.update = permission.update;
            service.delete = permission.delete;
          }
        });
      });
      console.log("boss permission--->", departmentAccess);
      setDepartmentList(departmentAccess)
    } else {
      setDepartmentList(departmentAccess)
    }
  }

  const handleCheckboxChange = (departmentName, serviceId, field, checked) => {
    const updatedDepartmentList = departmentList.map((department) => {
      if (department.departmentName === departmentName) {
        const updatedServices = department.services.map((service) => {
          if (service.serviceId === serviceId) {
            return { ...service, [field]: checked };
          }
          return service;
        });
        return { ...department, services: updatedServices };
      }
      return department;
    });
    setDepartmentList(updatedDepartmentList);
  };

  const handleAllCheck = (departmentName, checked) => {
    const updatedDepartmentList = departmentList.map((department) => {
      if (department.departmentName === departmentName) {
        const updatedServices = department.services.map((service) => ({
          ...service,
          create: checked,
          read: checked,
          update: checked,
          delete: checked
        }));
        return { ...department, services: updatedServices };
      }
      return department;
    });
    setDepartmentList(updatedDepartmentList);
  };
  const handleAllCheckType = (departmentName, checked, type) => {
    const updatedDepartmentList = departmentList.map((department) => {
      if (department.departmentName === departmentName) {
        const updatedServices = department.services.map((service) => {
          let updatedField;
          switch (type) {
            case "createAll":
              updatedField = "create";
              break;
            case "updateAll":
              updatedField = "update";
              break;
            case "deleteAll":
              updatedField = "delete";
              break;
            case "readAll":
              updatedField = "read";
              break;
            default:
              break;
          }
          if (updatedField) {
            return { ...service, [updatedField]: checked }; // Update the corresponding field
          } else {
            // For other types, keep the fields unchanged
            return { ...service };
          }
        });
        return { ...department, services: updatedServices };
      }
      return department;
    });
    setDepartmentList(updatedDepartmentList);
  };
  console.log(departmentList);

  return (
    <div className='h-96 overflow-y-scroll'>
      <h2 className='text-lg font-semibold text-[#000]'>
        {t("penal-permission")}
      </h2>
      {
        departmentList.length === 0 ? <div className='w-full h-full flex justify-center items-center'><Spinner /></div> :
          <Tabs>
            <TabList>
              {
                departmentList.map((tab, index) => (
                  <Tab key={index}>{t(tab?.departmentName)}</Tab>
                ))
              }
            </TabList>
            <TabPanels>
              {
                departmentList.map((tab, index) => (
                  <TabPanel key={index}>
                    <Stack className='h-[36vh]'>
                      <Flex justifyContent={'start'} className='bg-gray-100 px-4 py-2'>
                        <Stack spacing={5} direction='row'>
                          <Checkbox
                            onChange={(e) => {
                              handleAllCheck(tab?.departmentName, e.target.checked);
                            }}
                            colorScheme='green'
                          >
                            {t("all")}
                          </Checkbox>
                          <Checkbox
                            colorScheme='green'
                            onChange={(e) => {
                              handleAllCheckType(tab?.departmentName, e.target.checked, 'createAll');
                            }}
                          >
                            {t("all-create")}
                          </Checkbox>
                          <Checkbox
                            colorScheme='blue'
                            onChange={(e) => {
                              handleAllCheckType(tab?.departmentName, e.target.checked, 'readAll');
                            }}
                          >
                            {t("all-read")}
                          </Checkbox>
                          <Checkbox
                            colorScheme='yellow'
                            onChange={(e) => {
                              handleAllCheckType(tab?.departmentName, e.target.checked, 'updateAll');
                            }}
                          >
                            {t("all-update")}
                          </Checkbox>
                          <Checkbox
                            colorScheme='red'
                            onChange={(e) => {
                              handleAllCheckType(tab?.departmentName, e.target.checked, 'deleteAll');
                            }}
                          >
                            {t("all-delete")}
                          </Checkbox>
                        </Stack>
                      </Flex>
                      {
                        tab.services.map((service, index) => (
                          <Flex key={index} justifyContent={'space-between'} className='border-b-[1px] pb-2 mt-5'>
                            <Stack>
                              <h2 className='text-base font-semibold '>{t(service?.serviceName)}</h2>
                            </Stack>
                            <Stack spacing={5} direction='row'>
                              <Checkbox
                                colorScheme='green'
                                isChecked={service.create}
                                onChange={(e) => {
                                  handleCheckboxChange(tab?.departmentName, service.serviceId, "create", e.target.checked);
                                }}
                              >
                                {t("create")}
                              </Checkbox>
                              <Checkbox
                                colorScheme='blue'
                                isChecked={service.read}
                                onChange={(e) => {
                                  handleCheckboxChange(tab?.departmentName, service.serviceId, "read", e.target.checked);
                                }}>
                                {t("read")}
                              </Checkbox>
                              <Checkbox colorScheme='yellow' isChecked={service.update}
                                onChange={(e) => {
                                  handleCheckboxChange(tab?.departmentName, service.serviceId, "update", e.target.checked);
                                }}>
                                {t("update")}
                              </Checkbox>
                              <Checkbox colorScheme='red' isChecked={service.delete}
                                onChange={(e) => {
                                  handleCheckboxChange(tab?.departmentName, service.serviceId, "delete", e.target.checked);
                                }}>
                                {t("delete")}
                              </Checkbox>
                            </Stack>
                          </Flex>
                        ))
                      }
                    </Stack>
                  </TabPanel>
                ))
              }
            </TabPanels>
          </Tabs>

      }

    </div>
  )
}


const CreateUserMultiSteps = ({ onClose, isEdit, user, getUsers }) => {
  const toast = useToast();
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(50);
  const [userData, setUserData] = useState(isEdit ? user : {});
  const [departmentList, setDepartmentList] = useState([]);
  console.log("userData-- depart,ent>", userData);
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);

  const handleNewUSer = async () => {
    try {
      setLoading(true);
      let res;
      if (isEdit) {
        res = await patch('user/updateuser-permission', { ...userData, permissions: departmentList });
      } else {
        res = await post('user/newuser-permission', { ...userData, permissions: departmentList });
      }
      console.log(res);
      if (res.statusCode === 200) {
        onClose();
        getUsers();
        setLoading(false)
        toast({
          title: 'Successfull...!',
          description: res.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast({
        title: 'Error...!',
        description: error?.response?.data?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      getEmployee();
    }
  }
  const getEmployee = async () => {
    try {
      const res = await get('employee/unassigned-dropdown');
      if (res.statusCode === 200) {
        // Convert the employee data into { value, label } format for ReactSelect
        const employeeOptions = res.data.dropdown.map(emp => ({
          value: emp._id,
          label: emp.name
        }));
        setEmployees(employeeOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box
        borderWidth="1px"
        rounded="lg"
        // shadow="1px 1px 3px rgba(0,0,0,0.3)"
        maxWidth={800}
        p={6}
        m="10px auto"
        as="form">
        <Progress value={progress} mb="5%" mx="5%" isAnimated></Progress>
        {step === 1 ? <Form1 userData={userData} setUserData={setUserData} isEdit={isEdit} getEmployee={getEmployee} employees={employees} /> : <Form2 departmentList={departmentList} setDepartmentList={setDepartmentList} isEdit={isEdit} user={user} />}
        <ButtonGroup mt="5%" w="100%">
          <Flex w="100%" justifyContent="space-between">
            <Flex>
              <Button
                onClick={() => {
                  setStep(step - 1)
                  setProgress(progress - 50)
                }}
                isDisabled={step === 1}
                colorScheme="teal"
                variant="solid"
                w="7rem"
                mr="5%"
              >
                {t("back")}
              </Button>
              {
                step == 2 ? "" :
                  <Button
                    w="7rem"
                    isDisabled={step === 3}
                    onClick={() => {
                      setStep(step + 1)
                      setProgress(progress + 50)
                    }}
                    colorScheme="teal"
                    variant="outline">
                    {t("next")}
                  </Button>
              }
            </Flex>
            {step === 2 ? (

              <Button
                w="7rem"
                colorScheme="blue"
                variant="solid"
                onClick={() => {
                  handleNewUSer();
                }}>
                {loading ? <Spinner /> : isEdit ? (t("update")) : (t("submit"))}
              </Button>
            ) : null}
          </Flex>
        </ButtonGroup>
      </Box>
    </>
  )
}

export default CreateUserMultiSteps


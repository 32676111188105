import React, { useEffect, useRef, useState } from 'react'
import { get, post } from '../../services/apis/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserState } from '../../context/user';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Tooltip, useDisclosure, useToast } from '@chakra-ui/react';
import BreadCrumbs from '../../components/BreadCrumbs';
import { formatAndValidateDate, formatDateArEn, formatDateForInput, formatTimeArEn, getStatusColor } from '../../services/glocalFunctions';
import { MdOutlineVideogameAsset } from "react-icons/md";
import { FaBirthdayCake, FaRegFileAlt, FaUserMd } from "react-icons/fa";
import { CiCalendar, CiClock1, CiLocationOn, CiPhone, CiStethoscope, CiUser, CiWallet } from 'react-icons/ci';
import { MdOutlineMailOutline } from "react-icons/md";
import { FaHeartbeat } from 'react-icons/fa';
import ConfirmCloseRequestBox from '../../components/clinic/ConfirmCloseRequest';
import ScheduleAppointmentModal from '../../components/clinic/ScheduleAppoinment';
import UpdateRecoveryDialogue from '../../components/clinic/UpdateRecoveryDialogue';
import AddFeedbackNote from '../../components/clinic/AddFeedbackNote';
import { AppointmentCard, Descriptioncomponent, FeedbackCard, FieldLabel, getGenderIcon, ProfileComponent, StatusFieldLabel } from '../../components/clinic/ClinicComponent';
import { LuBuilding2 } from 'react-icons/lu';
import { RiErrorWarningLine } from "react-icons/ri";
import { BiClinic } from "react-icons/bi";
import ClinicTable from '../../components/clinic/ClinicTable';
import ConfirmRejectRequest from '../../components/clinic/ConfirmRejectRequest';
import ConfirmApproveRequest from '../../components/clinic/ConfirmApproveRequest';
import { FiFileText } from 'react-icons/fi';
import { LiaIndustrySolid } from "react-icons/lia";
import PaymentDetail from '../../components/clinic/PaymentDetail';
import { FaDollarSign } from 'react-icons/fa6';
import AppointmentPaymentRequest from '../../components/clinic/ApppointmentPaymentRequest';
import ConfirmCompletePayment from '../../components/clinic/ConfirmCompletePayment';
import ShowInjuryImg from '../../components/clinic/ShowInjuryImg';


// COLUMNS FOR TABLE 
const clinicRequestAppointmenColumns = [
    "appointment-id",
    "appointment-date",
    "status",
    "recovery-status",
    // "payment",
    "doctor-feedback",
    "action"
];

// DEFAULT SCHEDULE FORM DATA 
const _scheduleFormData = {
    appointmentDate: '',
    feedback: '',
    recoveryStatus: '',
    reason: ''
};

// RECOVERY STATUS FORM 
const _recoveryForm = {
    recoveryStatus: '',
    feedback: ''
}

// PAYMENT FORM 
const _paymentForm = {
    amount: '',
    paymentNote: '',
    paymentDueDate: ''
}

const RequestDetails = () => {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const toast = useToast();
    const navigate = useNavigate();
    const isArabic = i18n.language === 'ar';
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenPayement, onOpen: onOpenPayment, onClose: onPaymentClose } = useDisclosure();
    const { isOpen: isOpenComplete, onOpen: onOpenComplete, onClose: onCompleteClose } = useDisclosure();
    const { isOpen: isOpenImg, onOpen: onOpenImg, onClose: onImgClose } = useDisclosure();
    const { isOpen: isOpenRecovery, onOpen: onOpenRecovery, onClose: onReconveryClose } = useDisclosure();
    const { isOpen: isOpenNote, onOpen: onOpenNote, onClose: onNoteClose } = useDisclosure();
    const location = useLocation();
    const requestId = location?.pathname?.split('/')[3];
    const [treatmentRequest, setTreatmentRequest] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [scheduleFormData, setScheduleFormData] = useState({ ..._scheduleFormData });
    const [paymentFormData, setPaymentFormData] = useState({ ..._paymentForm });
    const [formData, setFormData] = useState({ ..._recoveryForm });
    const [isRechedule, setIsRechedule] = useState(false);
    const [request, setRequest] = useState(null)
    const [reason, setReason] = useState('');
    const [note, setNote] = useState('');
    const [noteType, setNoteType] = useState('');
    const [selectedDoctor, setSelectedDoctor] = useState({
        role: 'clinic-manager',
        _id: '6711fb36d66326c16c5763da'
    });


    // ACTIONS FUNCTIONS START FROM HERE 
    // GET REQUEST DETAILS  
    const hasFetched = useRef(false); // Initially false

    const getRequestById = async (requestId) => {
        try {
            if (user) {
                const res = await get(`clinic/treatment-request/${requestId}`)
                if (res.statusCode === 200) {
                    setTreatmentRequest(res?.data?.treatmentRequest || {});
                    setFormData((prevData) => ({
                        ...prevData,
                        recoveryStatus: res?.data?.treatmentRequest?.recoveryStatus || ''
                    }));
                }
            }
        } catch (error) {
            console.log(`ERROR WHILE FETHCING APPOINTMENT DETAIL ${error}`);
        }
    };

    useEffect(() => {
        if (!hasFetched.current) {
            getRequestById(requestId);
            hasFetched.current = true; // Mark as fetched
        }
    }, [])
    // GET REQUEST DETAILS  


    // CHANGE THE APPOINTMENT STATUS 
    const changeAppointmentStatus = async (_id, _status, reason) => {
        try {
            const _body = {
                appointmentId: _id,
                status: _status,
                reason: reason || ''
            }
            const response = await post(`clinic/chage-appointment-status`, _body);
            if (response?.statusCode == 200) {
                getRequestById(requestId);
                setReason('');
                toast({
                    title: response?.data?.message || 'success',
                    description: "",
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: response?.data?.message || 'error',
                    description: "",
                    status: 'error',
                    duration: 6000,
                    isClosable: true,
                })
            }
        } catch (e) {
            toast({
                title: e?.response?.data?.message || 'error',
                description: "",
                status: 'error',
                duration: 6000,
                isClosable: true,
            })
            console.log(`ERROR WHILE CHANGING STATUS ${e}`);
        }
    }

    // CHANGE THE REQUEST RECOVERY STATUS 
    const changeRequestRecoveryStatus = async (requestId, _status, feedback) => {
        try {
            setIsLoading(true);
            const _body = {
                requestId: requestId,
                status: _status,
                feedback: feedback || ''
            }
            const response = await post(`clinic/change-request-recovery-status`, _body);
            setIsLoading(false);
            if (response?.statusCode == 200) {
                setFormData(_recoveryForm);
                getRequestById(requestId);
                onReconveryClose();
                toast({
                    title: response?.data?.message || 'success',
                    description: "",
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: response?.data?.message || 'error',
                    description: "",
                    status: 'error',
                    duration: 6000,
                    isClosable: true,
                })
            }
        } catch (e) {
            setIsLoading(false);
            toast({
                title: e?.response?.data?.message || 'error',
                description: "",
                status: 'error',
                duration: 6000,
                isClosable: true,
            })
            console.log(`ERROR WHILE CHANGING RECOVERY STATUS ${e}`);
        }
    }

    // RE - SCHEDULE APPOINTMENT
    const reScheduleAppointment = async (appointmentId) => {
        setIsLoading(true);
        try {
            let _payload = {
                doctorId: selectedDoctor?._id,
                appointmentDate: scheduleFormData?.appointmentDate,
                reason: scheduleFormData?.reason
            };

            const res = await post(`clinic/re-schedule/appointment/${appointmentId}`, _payload)
            setIsLoading(false);

            if (res?.statusCode === 200 || res?.statusCode === 201) {
                getRequestById(treatmentRequest?._id);
                onClose();
                setScheduleFormData(_scheduleFormData);
                setIsRechedule(false);
                toast({
                    title: "Request scheduled successfully.",
                    status: "success",
                    duration: 5000, // 5 seconds    
                    isClosable: true,
                });
            } else {
                toast({
                    title: res?.data?.message || "Request scheduling failed.",
                    status: "error",
                    duration: 5000, // 5 seconds    
                    isClosable: true,
                });
            }
        } catch (error) {
            setIsLoading(false);
            toast({
                title: error?.response?.data?.message || "Request scheduling failed.",
                status: "error",
                duration: 5000, // 5 seconds    
                isClosable: true,
            });
        }
    };

    // SCHEDULE APPOINTMENT
    const scheduleAppointment = async (requestId) => {
        setIsLoading(true);
        try {
            let _payload = {
                doctorId: selectedDoctor?._id,
                requestId: requestId
            };

            let res
            _payload.appointmentDate = scheduleFormData?.appointmentDate;
            res = await post(`clinic/schedule/appointment/assign`, _payload);
            setIsLoading(false);

            if (res?.statusCode === 200 || res?.statusCode === 201) {
                getRequestById(requestId);
                onClose();
                setScheduleFormData(_scheduleFormData);
                toast({
                    title: res?.data?.message || "Request scheduled successfully.",
                    status: "success",
                    duration: 5000, // 5 seconds    
                    isClosable: true,
                });
            } else {
                toast({
                    title: res?.data?.message || "Request scheduling failed.",
                    status: "error",
                    duration: 5000, // 5 seconds    
                    isClosable: true,
                });
            }
        } catch (error) {
            setIsLoading(false);
            toast({
                title: error?.response?.data?.message || "Request scheduling failed.",
                status: "error",
                duration: 5000, // 5 seconds    
                isClosable: true,
            });
        }
    };

    // ADD A NOTE IN APPOINTMENT 
    const addNote = async (requestId, feedback, type) => {
        try {
            setIsLoading(true);
            const _body = {
                feedback: feedback || '',
                noteType: type || ''
            }
            const response = await post(`clinic/request/add-note/${requestId}`, _body);
            setIsLoading(false);
            if (response?.statusCode == 200) {
                getRequestById(requestId);
                setNote('');
                setNoteType('');
                onNoteClose();
                toast({
                    title: response?.data?.message || "Request scheduled successfully.",
                    status: "success",
                    duration: 5000, // 5 seconds    
                    isClosable: true,
                });
            } else {
                toast({
                    title: response?.data?.message || "Error while adding note.",
                    status: "error",
                    duration: 5000, // 5 seconds    
                    isClosable: true,
                });
            }
        } catch (e) {
            setIsLoading(false);
            console.log(`ERROR WHILE ADDING NOTE ${e}`);
            toast({
                title: e?.response?.data?.message || "Error while adding note.",
                status: "error",
                duration: 5000, // 5 seconds    
                isClosable: true,
            });
        }
    }

    // CARD BOX CLASSES 
    const cardBoxClass2 = `w-full lg:w-[48%] max-w-full lg:max-w-1/2 flex flex-col items-start gap-4 rounded-lg shadow-base border border-[#dce3f8]  bg-gray-50 overflow-hidden p-4`

    // IF LATEST DOCTOR/CLINIC FEEDBACK IS AVAILABLE SO FEEDBACK OTHERWISE INJURY DESCRIPTION 
    const headingClass = `text-xl lg:text-lg text-gray-900  font-bold leading-10`
    const headingClass2 = `text-xl lg:text-lg text-[#FFFFFF]  font-bold leading-10`
    const fieldValueClass1 = `text-[#FFFFFF] text-sm font-medium`
    const direction = isArabic ? 'rtl' : 'ltr';
    const textAlign = isArabic ? 'right' : 'left';

    // REQUEST DETAILS ACTIONS FUNCTIONS STARTS FROM HERE 
    // CHANGE THE REQUEST STATUS 
    const changeRequestStatus = async (_id, _status, rejectReason) => {
        try {
            setIsLoading(true);
            const _body = {
                requestId: _id,
                status: _status,
                rejectReason: rejectReason || ''
            }
            const response = await post(`clinic/chage-request-status`, _body);
            setIsLoading(false);
            if (response?.statusCode == 200) {
                getRequestById(requestId);
                setReason('');
                toast({
                    title: response?.data?.message || 'success',
                    description: "",
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: response?.data?.message || 'error',
                    description: "",
                    status: 'error',
                    duration: 6000,
                    isClosable: true,
                })
            }
        } catch (e) {
            setIsLoading(false);
            toast({
                title: e?.response?.data?.message || 'error',
                description: "",
                status: 'error',
                duration: 6000,
                isClosable: true,
            })
            console.log(`ERROR WHILE CHANGING STATUS ${e}`);
        }
    }

    // REQUEST PENDING PAYMENT 
    const requestPayment = async (requestId, data, status) => {
        try {
            setIsLoading(true);
            const _body = {
                amount: Number(data?.amount) || 0,  // Ensure amount is a number
                paymentDueDate: data?.paymentDueDate || '',
                paymentNote: data?.paymentNote || '',
                action: status || ''
            }
            const response = await post(`clinic/request/request-payment/${requestId}`, _body);
            setIsLoading(false);
            if (response?.statusCode == 200) {
                onPaymentClose();
                setPaymentFormData(_paymentForm);
                getRequestById(requestId);
                toast({
                    title: response?.data?.message || 'success',
                    description: "",
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: response?.data?.message || 'error...',
                    description: "",
                    status: 'error',
                    duration: 6000,
                    isClosable: true,
                })
            }
        } catch (e) {
            setIsLoading(false);
            toast({
                title: e?.response?.data?.message || 'error...',
                description: "",
                status: 'error',
                duration: 6000,
                isClosable: true,
            })
            console.log(`ERROR WHILE REQUESTING PAYMENT ${e}`);
        }
    };

    // COMPLETE PAYMENT REQUEST 
    const completePaymentRequest = async (requestId, status, paymentMode) => {
        try {
            setIsLoading(true);
            const _body = {
                action: status || '',
                paymentMode: paymentMode || 'CASH' // CURRENTOY SET TO DEFAULT CASH
            }
            const response = await post(`clinic/request/complete-payment/${requestId}`, _body);
            setIsLoading(false);
            if (response?.statusCode == 200) {
                onCompleteClose();
                getRequestById(requestId);
                toast({
                    title: response?.data?.message || 'success...',
                    description: "",
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: response?.data?.message || 'error...',
                    description: "",
                    status: 'error',
                    duration: 6000,
                    isClosable: true,
                })
            }
        } catch (e) {
            setIsLoading(false);
            toast({
                title: e?.response?.data?.message || 'error...',
                description: "",
                status: 'error',
                duration: 6000,
                isClosable: true,
            })
            console.log(`ERROR WHILE REQUESTING PAYMENT ${e}`);
        }
    };


    // PAYMENT REQUEST LABEL NOW THE PAYMENT FLOW IS REQUEST WISE NOT APPOINTMENT WISE 
    const _paymentStatus = treatmentRequest?.paymentStatus?.toLowerCase();
    const _userAppointmentType = treatmentRequest?.userAppointmentType?.toLowerCase();
    const paymentButtonLabel = _paymentStatus == 'pending' ? 'request-payment' : _paymentStatus == 'requested' || _paymentStatus == 'modified' ? 'update-payment-request' : 'complete-payment-request';

    return (
        <>
            <BreadCrumbs />
            <div className='w-full flex-1 flex flex-col p-2 gap-4'>
                <div className={`flex justify-between  ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}>
                    <h2 className='text-xl whitespace-nowrap font-bold'>
                        {t('request-details')}
                    </h2>
                    <div className={`flex items-center gap-2`}>
                        {treatmentRequest?.status == 'PENDING_APPROVAL' &&
                            <>
                                {/* APPROVE BUTTON  */}
                                <ConfirmApproveRequest
                                    handleApprove={() => {
                                        changeRequestStatus(treatmentRequest?._id, 'APPROVED');
                                    }}
                                    isLoading={isLoading}
                                    isButton={true} // FOR DISPLAY BUTTON INSTEAD OF ICON BUTTON 
                                />
                                {/* REJECT BUTTON  */}
                                <ConfirmRejectRequest
                                    toolLabel="reject-request"
                                    label="reject-request"
                                    buttonLabel="reject"
                                    isButton={true} // FOR DISPLAY BUTTON INSTEAD OF ICON BUTTON 
                                    handleSubmit={() => {
                                        changeRequestStatus(treatmentRequest?._id, 'REJECTED', reason);
                                    }}
                                    message="reject-request-msg"
                                    reason={reason}
                                    setReason={setReason}
                                />
                            </>
                        }

                        {/* REQUEST PAYMENT MODEL/BUTTON -- VISIBLE OLY WHEN THE REQUEST PAYMENT STATUS IS PENDING OR COMPLETED */}
                        {(_paymentStatus == 'pending' || _paymentStatus == 'completed') &&
                            _userAppointmentType == 'external' ?
                            <Button
                                colorScheme='blue'
                                onClick={() => {
                                    onOpenPayment();
                                }}
                            >
                                <FaDollarSign className="w-4 h-4" />
                                <span>{t('request-payment')}</span>
                            </Button>
                            : null}

                        {/* SCHEDULE BUTTON -- VISIBLE ONLY WHEN THE NEW REQUEST APPROVE OR THE CURRENT APPOINTMENT HAS CANCELLED OR MISSED */}
                        {(['cancelled', 'missed', 'completed'].includes(treatmentRequest?.currentLastAppointment?.status?.toLowerCase()) || treatmentRequest?.status === 'APPROVED') && (<Button
                            bg="blue.50"
                            _hover={{ bg: "blue.100" }}
                            color="blue.700"
                            borderColor="blue.200"
                            leftIcon={<CiCalendar className="w-4 h-4" />}
                            onClick={() => {
                                setRequest(treatmentRequest);
                                onOpen();
                            }}
                        >
                            <span className="text-sm font-medium">
                                {t('schedule-new')}
                            </span>
                        </Button>
                        )}
                        {treatmentRequest?.status &&
                            !["CLOSED", "REJECTED", "PENDING_APPROVAL"].includes(treatmentRequest.status.toUpperCase()) && (
                                <ConfirmCloseRequestBox
                                    request={treatmentRequest}
                                    fetchData={() => {
                                        getRequestById(requestId);
                                    }}
                                />
                            )
                        }
                    </div>
                </div>

                {/* REQUEST PAYMENT DETAULS */}
                {(['requested', 'modified', 'failed'].includes(_paymentStatus)) && !treatmentRequest?.corporateRequest ?
                    <div className={`flex flex-col items-start gap-4 w-full pt-0`}>
                        <PaymentDetail
                            amount={treatmentRequest?.pendingAmount || 0}
                            status={treatmentRequest?.paymentStatus || '--'}
                            dueDate={treatmentRequest?.paymentDueDate || '--'}
                            paymentNote={treatmentRequest?.paymentNote || '--'}
                            onEditPayment={() => {
                                onOpenPayment();
                                if (_paymentStatus == 'requested' || _paymentStatus == 'modified') {
                                    setPaymentFormData((prevData) => ({
                                        ...prevData,
                                        paymentDueDate: formatAndValidateDate(treatmentRequest?.paymentDueDate),
                                        amount: treatmentRequest?.pendingAmount,
                                        paymentNote: treatmentRequest?.paymentNote
                                    }))
                                }
                            }}
                            onConfirmComplete={() => {
                                onOpenComplete();
                            }}
                        />
                    </ div>
                    : null}

                {/* REQUEST DETAILS  */}
                <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
                    {/* HEADER PART TOP */}
                    <div className={`flex items-center justify-between bg-gradient-to-r from-blue-600 to-blue-700 p-4 px-0 lg:px-2 mb-4 ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}>
                        <div className={`flex items-center gap-2`}>
                            <FaRegFileAlt color='#cad7fb' size={30} />
                            <div className={`flex flex-col items-start gap-1`}>
                                <div className={`flex items-center gap-1`}>
                                    <span className={headingClass2}>{t('request')}</span>
                                    <span className={headingClass2}>{treatmentRequest?.requestId || '--'}</span>
                                </div>
                                <div className={`flex items-center gap-4`}>
                                    <div className='flex items-center gap-2'>
                                        <CiCalendar color='#ffffff' />
                                        <span className={fieldValueClass1}>
                                            {t('created-on')}
                                        </span>
                                        <span className={fieldValueClass1}>
                                            {formatDateArEn(treatmentRequest?.createdAt, isArabic)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* REQUEST STATUS  */}
                        <span className={`h-fit p-2 px-4 flex items-center justify-center text-xs font-semibold rounded-full ${getStatusColor(treatmentRequest?.status)}`}>
                            {t(treatmentRequest?.status?.toLowerCase()) || '--'}
                        </span>
                    </div>

                    {/* Injury Description - Full Width */}
                    <div className='p-4'>
                        <div className="bg-gray-50 rounded-xl p-4 w-full flex flex-col items-start gap-4">
                            <div className='w-full flex items-center justify-between'>
                                <div className={`flex items-center gap-2`}>
                                    <RiErrorWarningLine size={22} color='red' />
                                    <h3 className={headingClass}>
                                        {t("injury-description")}
                                    </h3>
                                </div>
                                <Button
                                    onClick={() => {
                                        onOpenImg();
                                    }}
                                >
                                    {t("view-injury")}
                                </Button>
                            </div>
                            <div className="bg-white w-full rounded-lg p-4 border border-gray-200">
                                <p className="text-sm text-gray-700 leading-relaxed">{treatmentRequest?.injuryDescription}</p>
                            </div>
                        </div>
                    </div>

                    {/* BASIC INFO OF DOCTOR AND PLAYER */}
                    <div className={`w-full flex flex-wrap items-center gap-4 p-4`}>
                        {/* PLAYER/PATIENT DETAILS  */}
                        {treatmentRequest?.userId &&
                            <div className={cardBoxClass2}>
                                <div className='w-full flex items-center justify-between'>
                                    <div className="flex items-center space-x-3 mb-3">
                                        <CiUser className="w-5 h-5 text-blue-600" />
                                        <span className={headingClass}>{t('patient-details') || '--'}</span>
                                    </div>
                                    <Tooltip label={t('update-recovery-status')}>
                                        <IconButton
                                            icon={<span style={{ fontSize: "1.2em" }}>✍️</span>}
                                            aria-label="View Asset Details"
                                            variant="ghost"
                                            onClick={() => {
                                                onOpenRecovery();
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                                <span className="text-gray-700 font-bold text-sm">
                                    {t(treatmentRequest?.userId?.name) || '__'}
                                </span>
                                <div className={`w-full flex flex-wrap items-start justify-between gap-6`}>
                                    {/* BASIC PLAYER/PATIENT INFO  */}
                                    <div>
                                        <FieldLabel
                                            icon={<MdOutlineVideogameAsset className="text-gray-400" />}
                                            title={treatmentRequest?.userId?.gameId?.name || '--'}
                                        />
                                        <FieldLabel
                                            icon={<CiPhone className="text-gray-400" />}
                                            title={treatmentRequest?.userId?.mobile || '--'}
                                        />
                                        <FieldLabel
                                            icon={<MdOutlineMailOutline className="text-gray-400" />}
                                            title={treatmentRequest?.userId?.email || '--'}
                                        />
                                    </div>

                                    {/* HEALTH INFO OF  PLAYER/PATIENT   */}
                                    <div>
                                        <FieldLabel
                                            // icon={<MdOutlineVideogameAsset className="text-gray-400" />}
                                            icon={<FaHeartbeat color="#e74c3c" />}
                                            title={treatmentRequest?.userId?.bloodGroup || '--'}
                                        />
                                        <FieldLabel
                                            icon={getGenderIcon(treatmentRequest?.userId?.gender?.toLowerCase())}
                                            title={t(treatmentRequest?.userId?.gender?.toLowerCase()) || '--'} // Show gender or fallback text '--'
                                        />
                                        <FieldLabel
                                            icon={<FaBirthdayCake className="text-gray-400" />}
                                            title={formatDateArEn(treatmentRequest?.userId?.dateOfBirth, isArabic) || '--'}
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                        {/* DOCTOR BASIC DETAILS  */}
                        {treatmentRequest?.doctorId &&
                            <div className={cardBoxClass2}>
                                <div className="flex items-center space-x-3 mb-3">
                                    <CiStethoscope className="w-5 h-5 text-blue-600" />
                                    <span className={headingClass}>{t('doctor-details') || '--'}</span>
                                </div>
                                <span className="text-gray-700 font-bold text-sm">
                                    {t(treatmentRequest?.userId?.name) || '__'}
                                </span>

                                <div className={`w-full flex flex-wrap items-start justify-between gap-6`}>
                                    {/* BASIC PLAYER/PATIENT INFO  */}
                                    <div>
                                        <FieldLabel
                                            icon={<FaUserMd className="text-gray-400" />}
                                            title={treatmentRequest?.doctorId?.experience || '--'}
                                        />
                                        <FieldLabel
                                            icon={<CiPhone className="text-gray-400" />}
                                            title={treatmentRequest?.doctorId?.mobile || '--'}
                                        />
                                        <FieldLabel
                                            icon={<MdOutlineMailOutline className="text-gray-400" />}
                                            title={treatmentRequest?.doctorId?.email || '--'}
                                        />
                                    </div>

                                    {/* HEALTH INFO OF  PLAYER/PATIENT   */}
                                    <div>
                                        <FieldLabel
                                            // icon={<MdOutlineVideogameAsset className="text-gray-400" />}
                                            icon={<FaHeartbeat color="#e74c3c" />}
                                            title={treatmentRequest?.doctorId?.bloodGroup || '--'}
                                        />
                                        <FieldLabel
                                            icon={getGenderIcon(treatmentRequest?.doctorId?.gender?.toLowerCase())}
                                            title={t(treatmentRequest?.doctorId?.gender?.toLowerCase()) || '--'} // Show gender or fallback text '--'
                                        />
                                        <FieldLabel
                                            icon={<FaBirthdayCake className="text-gray-400" />}
                                            title={formatDateArEn(treatmentRequest?.doctorId?.dateOfBirth, isArabic) || '--'}
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                        {/* REQUSTED BY BASIC DETAILS  */}
                        {treatmentRequest?.createdBy &&
                            <div className={cardBoxClass2}>
                                <div className="flex items-center space-x-3 mb-3">
                                    {treatmentRequest?.corporateRequest ?
                                        <CiUser className="w-5 h-5 text-gray-600" />
                                        :
                                        <LuBuilding2 className="w-5 h-5 text-blue-600" />
                                    }
                                    <span className={headingClass}>{t('requested-by') || '--'}</span>
                                </div>
                                <span className="text-gray-700 font-bold text-sm">
                                    {t(treatmentRequest?.createdBy?.name) || '__'}
                                </span>

                                <div className={`w-full flex flex-wrap items-start justify-between gap-6`}>
                                    {/* BASIC PLAYER/PATIENT INFO  */}
                                    <div>
                                        <FieldLabel
                                            icon={<FaUserMd className="text-gray-400" />}
                                            title={treatmentRequest?.createdBy?.experience || '--'}
                                        />
                                        <FieldLabel
                                            icon={<CiPhone className="text-gray-400" />}
                                            title={treatmentRequest?.createdBy?.mobile || '--'}
                                        />
                                        <FieldLabel
                                            icon={<MdOutlineMailOutline className="text-gray-400" />}
                                            title={treatmentRequest?.createdBy?.email || '--'}
                                        />
                                    </div>

                                    {/* HEALTH INFO OF  PLAYER/PATIENT   */}
                                    <div>
                                        <FieldLabel
                                            // icon={<MdOutlineVideogameAsset className="text-gray-400" />}
                                            icon={<FaHeartbeat color="#e74c3c" />}
                                            title={treatmentRequest?.createdBy?.bloodGroup || '--'}
                                        />
                                        <FieldLabel
                                            icon={getGenderIcon(treatmentRequest?.createdBy?.gender?.toLowerCase())}
                                            title={t(treatmentRequest?.createdBy?.gender?.toLowerCase()) || '--'} // Show gender or fallback text '--'
                                        />
                                        <FieldLabel
                                            icon={<FaBirthdayCake className="text-gray-400" />}
                                            title={formatDateArEn(treatmentRequest?.createdBy?.dateOfBirth, isArabic) || '--'}
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                        {/* CORPORATE BASIC DETAILS  */}
                        {treatmentRequest?.corporateRequest &&
                            <div className={cardBoxClass2}>
                                <div className="flex items-center space-x-3 mb-3">
                                    <LuBuilding2 className="w-5 h-5 text-blue-600" />
                                    <span className={headingClass}>{t('corpore-info') || '--'}</span>
                                </div>
                                <span className="text-gray-700 font-bold text-sm">
                                    {t(treatmentRequest?.corporateRequest?.organizationName) || '__'}
                                </span>

                                <div className={`w-full flex flex-wrap items-start justify-between gap-6`}>
                                    {/* BASIC PLAYER/PATIENT INFO  */}
                                    <div>
                                        <FieldLabel
                                            icon={<LiaIndustrySolid className="text-gray-400" />}
                                            title={treatmentRequest?.corporateRequest?.industry || '--'}
                                        />
                                        <FieldLabel
                                            icon={<CiPhone className="text-gray-400" />}
                                            title={treatmentRequest?.corporateRequest?.crNumber || '--'}
                                        />
                                        <FieldLabel
                                            icon={<MdOutlineMailOutline className="text-gray-400" />}
                                            title={treatmentRequest?.corporateRequest?.organizationEmail || '--'}
                                        />
                                    </div>

                                    {/* HEALTH INFO OF  PLAYER/PATIENT   */}
                                    <div>
                                        <FieldLabel
                                            // icon={<MdOutlineVideogameAsset className="text-gray-400" />}
                                            icon={<CiLocationOn />}
                                            title={treatmentRequest?.corporateRequest?.location || '--'}
                                        />
                                        <FieldLabel
                                            icon={<CiWallet color={treatmentRequest?.corporateRequest?.walletBalance < 0 ? "#e74c3c" : "#2ecc71"} />}
                                            title={treatmentRequest?.corporateRequest?.walletBalance || 0} // Show wallet balance
                                        />
                                        <FieldLabel
                                            icon={<CiCalendar />}
                                            title={formatDateArEn(treatmentRequest?.corporateRequest?.createdAt, isArabic) || '--'}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    {/* Upcoming Appointment Section */}
                    {treatmentRequest?.currentLastAppointment &&
                        <div className={`border-t flex flex-col items-start gap-4 w-full border-gray-200 p-6`}>
                            <h3 className={headingClass}>
                                {["scheduled", "rescheduled"].includes(treatmentRequest?.currentLastAppointment?.status) ||
                                    new Date() < new Date(treatmentRequest?.currentLastAppointment?.appointmentDate)
                                    ? t("upcoming-appointment")
                                    : t("last-appointment")}
                            </h3>

                            {/* LAST APPOINTMENT CARD  */}
                            <AppointmentCard
                                reason={reason}
                                setReason={setReason}
                                appointmentId={treatmentRequest?.currentLastAppointment?.appointmentId || ''}
                                appointmentDate={treatmentRequest?.currentLastAppointment?.appointmentDate || ''}
                                appointmentstatus={treatmentRequest?.currentLastAppointment?.status || ''}
                                onScheduleClick={() => {
                                    setRequest(treatmentRequest);
                                    if (treatmentRequest?.currentLastAppointment &&
                                        ['scheduled', 'rescheduled'].includes(treatmentRequest?.currentLastAppointment?.status?.toLowerCase())) {
                                        setIsRechedule(true);
                                        setScheduleFormData((prevData) => ({
                                            ...prevData,
                                            appointmentDate: formatDateForInput(treatmentRequest?.currentLastAppointment?.appointmentDate),
                                            reason: ''
                                        }));
                                    }
                                    onOpen();
                                }}
                                onCancelScheduleClick={() => {
                                    changeAppointmentStatus(treatmentRequest?.currentLastAppointment?._id, 'CANCELLED', reason);
                                }}
                                onViewAppointment={() => {
                                    navigate(`/clinic/appointment/${treatmentRequest?.currentLastAppointment?._id}`)
                                }}
                            />
                        </div>
                    }

                    {/* LATEST FEEDBACKS/NOTES DETAILS  */}
                    <div className={`border-t flex flex-col items-start gap-2 w-full border-gray-200 p-6`}>
                        <div className={`flex w-full items-center justify-between py-2`}>
                            <span className={headingClass}>{
                                t('feedbacks-notes')}
                            </span>
                            <div className={`flex items-center gap-4`}>
                                {/* DOCTOR NOTE BUTTON  */}
                                <Button
                                    bg="purple.50"
                                    _hover={{ bg: "purple.100" }}
                                    color="purple.700"
                                    borderColor="purple.200"
                                    leftIcon={<FiFileText className="w-4 h-4" />}
                                    onClick={() => {
                                        setNoteType('doctor');
                                        onOpenNote();
                                    }}
                                >
                                    <span className="text-sm font-medium">{t('doctor-note')}</span>
                                </Button>
                                {/* MANAGER NOTE BUTTON  */}
                                {user?.role?.toLowerCase() == 'clinic-manager' &&
                                    <Button
                                        bg="green.50"
                                        _hover={{ bg: "green.100" }}
                                        color="green.700"
                                        borderColor="green.200"
                                        leftIcon={<LuBuilding2 className="w-4 h-4" />}
                                        onClick={() => {
                                            setNoteType('manager');
                                            onOpenNote();
                                        }}
                                    >
                                        <span className="text-sm font-medium">{t('manager-note')}</span>
                                    </Button>
                                }
                            </div>
                        </div>
                        {(treatmentRequest?.latestFeedback || treatmentRequest?.latestManagerFeedback || treatmentRequest?.closingNote || treatmentRequest?.rejectReason) && (
                            <>
                                {/* LATEST FEEDBACK & NOTES CARD  */}
                                {treatmentRequest?.latestFeedback &&
                                    <FeedbackCard
                                        label={"doctor-feedback"}
                                        name={treatmentRequest?.doctorId?.name}
                                        designation={treatmentRequest?.doctorId?.designation || treatmentRequest?.doctorId?.role}
                                        icon={<CiStethoscope className="w-5 h-5 text-[#4f45e4]" />}
                                        feedback={treatmentRequest?.latestFeedback}
                                    />
                                }

                                {/* LATEST MANAGER FEEDBACK & NOTES CARD  */}
                                {treatmentRequest?.latestManagerFeedback &&
                                    <FeedbackCard
                                        label={"manager-feedback"}
                                        name={treatmentRequest?.approvedBy?.name}
                                        designation={treatmentRequest?.approvedBy?.designation || treatmentRequest?.approvedBy?.role}
                                        icon={<LuBuilding2 className="w-5 h-5 text-green-600" />}
                                        feedback={treatmentRequest?.latestManagerFeedback}
                                    />
                                }

                                {/* CLOSING/NOTE REQUEST CARD  */}
                                {treatmentRequest?.closingNote &&
                                    <FeedbackCard
                                        label={"closing-note"}
                                        name={treatmentRequest?.approvedBy?.name}
                                        designation={treatmentRequest?.approvedBy?.designation || treatmentRequest?.approvedBy?.role}
                                        icon={<BiClinic className="w-5 h-5 text-teal-600" />}
                                        feedback={treatmentRequest?.closingNote}
                                    />
                                }

                                {/* REJECT REQUEST CARD  */}
                                {treatmentRequest?.rejectReason &&
                                    <FeedbackCard
                                        label={"rejection-reason"}
                                        name={treatmentRequest?.approvedBy?.name}
                                        designation={treatmentRequest?.approvedBy?.designation || treatmentRequest?.approvedBy?.role}
                                        icon={<CiUser className="w-5 h-5 text-gray-600" />}
                                        feedback={treatmentRequest?.rejectReason}
                                    />
                                }
                            </>
                        )}
                    </div>
                </div>

                {/* APPOINTMENTS LIST  */}
                {treatmentRequest?.appointments && treatmentRequest?.appointments?.length > 0 &&
                    <div className='w-full flex-1 flex flex-col '>
                        <h2 className='text-lg whitespace-nowrap font-semibold mb-4'>
                            {t('appointment-list')}&nbsp;
                            {Number.isInteger(treatmentRequest?.appointments?.length) ? `(${treatmentRequest.appointments.length})` : '--'}
                        </h2>
                        <ClinicTable
                            rows={treatmentRequest?.appointments || []}
                            totalPages={1}
                            columns={clinicRequestAppointmenColumns}
                            page={0}
                            handlePageChange={(newPage) => {
                                console.log(newPage);
                                // handlePageChange(newPage);
                            }}
                            isHideFooter={true}// flag for the request detail screen to hide footer / pagination
                            onSchedule={(request) => {
                                setRequest(request);
                                onOpen();
                            }}
                            onNavigate={(appoinment) => {
                                navigate(`/clinic/appointment/${appoinment?._id}`);
                            }}
                        />
                    </div>
                }
            </div>

            {/* SCHEDULE APPOINTMENT MODEL  */}
            <ScheduleAppointmentModal
                isOpen={isOpen}
                isRechedule={isRechedule}
                onClose={() => {
                    onClose();
                    setIsRechedule(false);
                }}
                scheduleFormData={scheduleFormData}
                onHandleChange={(field, value) => {
                    setScheduleFormData((prevData) => ({
                        ...prevData,
                        [field]: value
                    }));
                }}
                isLoading={isLoading}
                onHandleSubmit={() => {
                    if (isRechedule) {
                        reScheduleAppointment(treatmentRequest?.currentLastAppointment?._id);
                    } else {
                        scheduleAppointment(treatmentRequest?._id);
                    }
                }}
                request={request}
            />

            {/* UPDATE RECOVERY STATUS MODEL  */}
            <UpdateRecoveryDialogue
                isOpen={isOpenRecovery}
                isLoading={isLoading}
                formData={formData}
                onClose={() => {
                    onReconveryClose();
                    setFormData((prevData) => ({
                        ...prevData,
                        recoveryStatus: treatmentRequest?.recoveryStatus,
                        feedback: ''
                    }))
                }}
                onHandleChange={(field, value) => {
                    setFormData((prevData) => ({
                        ...prevData,
                        [field]: value
                    }));
                }}
                onHandleSubmit={() => {
                    changeRequestRecoveryStatus(treatmentRequest?._id, formData?.recoveryStatus, formData?.feedback)
                }}
            />

            {/* ADD FEEDBACK NOTE MODEL  */}
            <AddFeedbackNote
                isOpen={isOpenNote}
                isLoading={isLoading}
                note={note}
                setNote={setNote}
                label={noteType == 'manager' ? "add-manager-note" : "add-doctor-note"}
                onClose={() => {
                    onNoteClose();
                    if (note || noteType) {
                        setNote('');
                        setNoteType('');
                    }
                }}
                onHandleSubmit={() => {
                    addNote(treatmentRequest?._id, note, noteType)
                }}
            />

            {/* REQUEST APPOINTMENT  */}
            <AppointmentPaymentRequest
                isOpen={isOpenPayement}
                formData={paymentFormData}
                isLoading={isLoading}
                label={paymentButtonLabel || "payment"}
                onHandleChange={(field, value) => {
                    setPaymentFormData((prevData) => ({
                        ...prevData,
                        [field]: value
                    }));
                }}
                isCorporateRequest={treatmentRequest?.corporateRequest ? true : false} // CORPORATE REQUEST FLAG 
                onClose={() => {
                    onPaymentClose();
                }}
                onHandleSubmit={() => {
                    const _status = _paymentStatus == 'pending' ? 'REQUESTED' : 'MODIFIED';
                    requestPayment(requestId, paymentFormData, _status);
                }}
            />

            {/* CONFIRM COMPLETE PAYMENT REQUEST */}
            <ConfirmCompletePayment
                isOpen={isOpenComplete}
                isLoading={isLoading}
                onClose={() => {
                    onCompleteClose();
                }}
                onCompleteRequest={() => {
                    completePaymentRequest(requestId, 'COMPLETED', 'CASH'); // CURRENTLY DEFAULT CASH WILL CHANGE FLOW LATTER 
                }}
            />

            {/* VIEW INJURY IMAGE */}
            <ShowInjuryImg
                isOpen={isOpenImg}
                imgUrl={treatmentRequest?.injuryBodyImage}
                onClose={() => {
                    onImgClose();
                }}
            />
        </>
    )
}

export default RequestDetails


import React, { useEffect, useState } from 'react'
import { UserState } from '../../context/user';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HamburgerIcon } from '@chakra-ui/icons';
import DateRangeSelect from '../../components/account/DateRangeSelect';
import { useToast } from '@chakra-ui/react';
import { Divider, IconButton, Input, Menu, MenuButton, MenuList, Select } from '@chakra-ui/react';
import { get } from '../../services/apis/api';
import TableComponent from './TableComponent';
import { debounce } from 'lodash';



const PastTransactions = ({ txnType }) => {
    const { user } = UserState();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [searchTerm, setSearchTerm] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0); // Initial skip should be 0
    const [limit, setLimit] = useState(10);
    const [allTransactions, setAllTransactions] = useState([]);
    const [date, setDate] = useState({
        startDate: '',
        endDate: ''
    });

    // TABLE COLUMNS 
    const columns = [
        's-no',
        'Date-of-entry',
        'amount',
        'transection-type',
        'source',
        'created-by',
        'Description',
        // 'Actions' //QUETIONS IT SHOLD GIVE HERE OR NOT 
    ];

    // FETCH ALL TRRANSECTIONS BY API'S 
    const getAllTransactions = async () => {
        try {
            if (user) {
                const res = await get(`expenses/getAllTransactions?skip=${skip}&limit=${limit}&search=${searchTerm}&startDate=${date.startDate}&endDate=${date.endDate}&transactionType=${txnType}`);
                setAllTransactions(res?.data?.data);
                const totalCount = res?.data?.totalCount || 0; // Ensure totalCount is defined
                setTotalPages(Math.ceil(totalCount / limit)); // Calculate total pages
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAllTransactions();
    }, [page, limit]); // Fetch data when page or limit changes

    // Debounce the getAllTransactions function
    const debouncedGetAllRequest = debounce(getAllTransactions, 300); // Adjust delay as needed

    useEffect(() => {
        debouncedGetAllRequest();
        // Cleanup function to cancel the debounced call on component unmount
        return () => {
            debouncedGetAllRequest.cancel();
        };
    }, [searchTerm]);

    useEffect(() => {
        if (date.startDate && date.endDate) {
            getAllTransactions();
        }
    }, [date]);

    const Heading = ({ title }) => (
        <h2 className="text-lg text-gray-600 mb-2 lg:text-xl font-bold">{t(title)}</h2>
    )
    return (
        <>
            <div className="w-full flex flex-col items-start gap-3 flex-1 min-h-[50vh]">
                <div className={`flex w-full justify-between px-0 lg:px-2 mb-4 gap-2 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                    <Heading
                        title={"past-transections"}
                    />
                    <div className={`flex ${isArabic ? 'flex-col-reverse lg:flex-row-reverse items-end justify-start' : 'flex-col lg:flex-row items-start'} gap-2 w-full justify-between lg:w-fit `}>
                        <div className="relative mr-3">
                            <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input
                                type="text"
                                id="table-search-users"
                                className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                                placeholder={t("search-request")}
                                value={searchTerm}
                                onChange={(e) => { setSearchTerm(e.target.value) }}
                            />
                        </div>
                        <DateRangeSelect
                            date={date}
                            setDate={setDate}
                            resetDates={async () => {
                                await setDate({ startDate: '', endDate: '' })
                                getAllTransactions()
                            }}
                            getData={() => { getAllTransactions() }}
                        />
                    </div>
                </div> 
                <div className='w-full flex-1 flex flex-col '>
                    <TableComponent
                        page={page}
                        skip={skip}
                        limit={limit}
                        setSkip={setSkip}
                        setLimit={setLimit}
                        setPage={setPage}
                        columns={columns}
                        rows={allTransactions}
                        totalPages={totalPages}
                    />
                </div>
            </div>
        </>
    )
}

export default PastTransactions

import React from 'react';
import { Box, Button, Text, VStack, Heading, HStack, Image, Stack, Icon } from '@chakra-ui/react';
import { FaCheckCircle, FaApple, FaAndroid, FaUsers, FaClipboardList, FaTrophy } from 'react-icons/fa';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import logo from '../assets/images/logo-rbg.png';

const appDetails = {
  name: "Al-ibtisam",
  version: "1.0.0",
  releaseDate: "2024-07-26",
  iosLink: "https://apps.apple.com/in/app/alibtisam/id6529530790",
  androidLink: "https://elasticbeanstalk-ap-south-1-302610564910.s3.ap-south-1.amazonaws.com/alibtisam.apk ",
  features: [
    {
      role: "Coach",
      description: [
        "Manage player attendance",
        "Track player statistics",
        "Chat with players",
        "Assign and modify player groups",
        "Promote players from academy to professional levels",
        "Request and organize tournaments",
        "Schedule events",
        "Create practice sessions",
        "Handle collection and enrollment"
      ],
      icon: FaUsers
    },
    {
      role: "External User",
      description: [
        "Access event information",
        "Fill out enrollment forms",
        "Browse sports products collections"
      ],
      icon: FaClipboardList
    },
    {
      role: "Player",
      description: [
        "View personal statistics",
        "Check attendance records",
        "Communicate with coaches",
        "Access events and sports information"
      ],
      icon: FaTrophy
    }
  ],
  screenshots: [
    "https://firebasestorage.googleapis.com/v0/b/snp-collection.appspot.com/o/img1.jpg?alt=media&token=bd73f53c-607d-4cca-b756-c27b8698222b",
    "https://firebasestorage.googleapis.com/v0/b/snp-collection.appspot.com/o/img2.jpg?alt=media&token=97e57ed4-8638-43d1-a5d5-0b7c296716aa",
    "https://firebasestorage.googleapis.com/v0/b/snp-collection.appspot.com/o/img3.jpg?alt=media&token=201963c6-df22-40e1-9b57-ca8e4a4fc732",
    "https://firebasestorage.googleapis.com/v0/b/snp-collection.appspot.com/o/img4.jpg?alt=media&token=0596f981-46a3-45ab-a92f-d4741dbdac7d",
    "https://firebasestorage.googleapis.com/v0/b/snp-collection.appspot.com/o/img5.jpg?alt=media&token=5ab34379-6da4-435b-8ac4-229fb486b379",
    "https://firebasestorage.googleapis.com/v0/b/snp-collection.appspot.com/o/img6.jpg?alt=media&token=70049653-7022-41aa-9006-b38c95f46b60",
    "https://firebasestorage.googleapis.com/v0/b/snp-collection.appspot.com/o/img7.jpg?alt=media&token=c7935237-7968-4519-951f-3ff7dcaecb9c",
    "https://firebasestorage.googleapis.com/v0/b/snp-collection.appspot.com/o/img2.jpg?alt=media&token=97e57ed4-8638-43d1-a5d5-0b7c296716aa",
    "https://firebasestorage.googleapis.com/v0/b/snp-collection.appspot.com/o/img8.jpg?alt=media&token=d273de9f-59b8-4adb-a8eb-2c422b591455",
  ]
};

const Products = () => {
  // Helper function to group screenshots into chunks of 3
  const chunkArray = (array, chunkSize) => {
    let index = 0;
    const chunks = [];
    while (index < array.length) {
      chunks.push(array.slice(index, index + chunkSize));
      index += chunkSize;
    }
    return chunks;
  };

  // Group screenshots into chunks of 3
  const groupedScreenshots = chunkArray(appDetails.screenshots, 3);

  return (
    <Box bg="" py={10} px={{ base: 4, md: 8 }} rounded="lg" shadow="xl" className="container mx-auto">
      {/* Section 1: App Details and Download Buttons */}
      <VStack spacing={5} mb={8} textAlign="center" >
        <Image src={logo} alt="App Logo" boxSize="100px" mb={4} />
        <Heading as="h1" size="2xl">{appDetails.name}</Heading>
        <Text fontSize="lg" color="gray.600">Version: {appDetails.version}</Text>
        <Text fontSize="lg" color="gray.600">Release Date: {appDetails.releaseDate}</Text>
        <Stack spacing={4} justifyContent="center" direction={{ base: 'column', md: 'row' }} className='border-b pb-5'>
          <Button leftIcon={<FaApple />} colorScheme="blue" as="a" href={appDetails.iosLink} target="_blank">Download for iOS</Button>
          <Button leftIcon={<FaAndroid />} colorScheme="green" as="a" href={appDetails.androidLink} target="_blank">Download for Android</Button>
        </Stack>
      </VStack>



      {/* Section 2: Carousel */}
      <Box mb={8} >
        <Heading as="h2" size="lg" mb={4} textAlign="center">Screenshots</Heading>
        <Carousel showThumbs={false} autoPlay infiniteLoop>
          {groupedScreenshots.map((group, index) => (
            <Box key={index} className="mobile-screenshot" display="flex" justifyContent="center" alignItems="center">
              {group.map((src, subIndex) => (
                <Box key={subIndex} p={2}>
                  <Box
                    border="2px"
                    borderColor="gray.200"
                    borderRadius="lg"
                    overflow="hidden"
                    w="full"
                    maxW="300px"
                    maxH="500px"
                    mx="auto"
                    boxShadow="lg"
                  >
                    <Image src={src} alt={`Screenshot ${index * 3 + subIndex + 1}`} />
                  </Box>
                </Box>
              ))}
            </Box>
          ))}
        </Carousel>
      </Box>

      {/* Section 3: Features */}
      <Box>
        <Heading as="h2" size="lg" mb={4} textAlign="center">Features</Heading>
        <Box>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            textAlign="center"
            justify="center"
            spacing={{ base: 4, lg: 10 }}
            py={10}
            className='items-center'
          >
            {appDetails.features.map((feature, index) => (
              <Box key={index} borderWidth="1px" borderRadius="lg" overflow="hidden" p={6} bg="white" shadow="md" width="320px" height={{ base: "100%", md: "450px"  }} mx={{ base: 0, md: 0 }}>
                <Icon as={feature.icon} w={8} h={8} color="blue.500" mb={4} />
                <Heading as="h3" size="md" mb={4} textAlign="center">{feature.role}</Heading>
                <VStack align="start" spacing={2}>
                  {feature.description.map((desc, i) => (
                    <HStack key={i} spacing={2} align="center">
                      <Icon as={FaCheckCircle} color="green.500" />
                      <Text fontSize="sm">{desc}</Text>
                    </HStack>
                  ))}
                </VStack>
              </Box>
            ))}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default Products;

// src/pages/employee/Payroll.jsx
import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    IconButton,
    Text,
    Badge,
    useColorModeValue,
    VStack,
    Divider,
} from '@chakra-ui/react';
import { DownloadIcon, ViewIcon } from '@chakra-ui/icons';
import { get } from '../../services/apis/api';
import moment from 'moment/moment';

const Payroll = () => {
    const [payrolls, setPayrolls] = useState([]);
    const tableHeaderBg = useColorModeValue('gray.100', 'gray.700');

    const getPayrollData = async () => {
        try {
            const res = await get('payroll/employee/all');
            if (res.statusCode === 200) {
                setPayrolls(res.data.data || []);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getPayrollData();
    }, []);

    const lastMonthPayroll = payrolls[0] || null;
    const previousPayrolls = payrolls.slice(1);

    return (
        <Box width="100%" p={6}>
            <Text fontSize="2xl" fontWeight="bold" mb={6} color="blue.600">
                Payroll
            </Text>

            {/* Last Month Payroll Summary */}
            {!lastMonthPayroll ? 
            (
                <Text>No payroll data available for last month.</Text>
            ): (
                <Box mb={8} p={6} shadow="md" borderWidth="1px" borderRadius="md" bg="white">
                    <Text fontSize="lg" fontWeight="bold" mb={4}>
                        Last Month Payroll - { moment(lastMonthPayroll?.payrollMonth).format("MMMM YYYY")}
                    </Text>
                    <Divider mb={4} />

                    {/* Payroll Details Grid */}
                    <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
                        <Flex justify="space-between">
                            <Text fontWeight="semibold">Paid Days</Text>
                            <Text>{lastMonthPayroll?.paidDays}</Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Text fontWeight="semibold">Payable Days</Text>
                            <Text>{lastMonthPayroll?.payableDays}</Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Text fontWeight="semibold">Leave Days</Text>
                            <Text>{lastMonthPayroll?.paidLeaves}</Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Text fontWeight="semibold">Absent Days</Text>
                            <Text>{lastMonthPayroll.unpaidLeaves}</Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Text fontWeight="semibold">Gross Salary</Text>
                            <Text>{lastMonthPayroll?.salaryBreakdown?.grossPay?.toFixed(2)}</Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Text fontWeight="semibold">Basic Pay</Text>
                            <Text>{lastMonthPayroll?.salaryBreakdown?.basicSalary?.toFixed(2)}</Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Text fontWeight="semibold">Allowances</Text>
                            <Text>{lastMonthPayroll?.salaryBreakdown?.specialAllowance?.toFixed(2)}</Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Text fontWeight="semibold">Deductions</Text>
                            <Text>{lastMonthPayroll?.deductions?.totalDeductions?.toFixed(2)}</Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Text fontWeight="semibold">Overtime Pay</Text>
                            <Text>{lastMonthPayroll?.salaryBreakdown?.variablePay?.toFixed(2)}</Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Text fontWeight="semibold">Bonus</Text>
                            <Text>{lastMonthPayroll?.salaryBreakdown?.bonus?.toFixed(2)}</Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Text fontWeight="semibold">Net Pay</Text>
                            <Text color="blue.600" fontWeight="bold">
                                {lastMonthPayroll?.salaryBreakdown?.netPay?.toFixed(2)}
                            </Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Text fontWeight="semibold">Payment Status</Text>
                            <Badge colorScheme={lastMonthPayroll.paymentStatus === 'Paid' ? 'green' : 'red'}>
                                {lastMonthPayroll.paymentStatus}
                            </Badge>
                        </Flex>
                    </Box>

                    {/* Download Button */}
                    <Flex mt={4} justify="flex-end">
                        <Button leftIcon={<DownloadIcon />} colorScheme="blue">
                            Download Payslip
                        </Button>
                    </Flex>
                </Box>
            ) }

            {/* Previous Payrolls */}
            <Text fontSize="lg" fontWeight="bold" mb={4}>
                Previous Payrolls
            </Text>
            <VStack spacing={4} align="stretch">
                {previousPayrolls.length > 0 ? (
                    previousPayrolls.map((payroll, index) => (
                        <Box key={index} p={4} shadow="sm" borderWidth="1px" borderRadius="md" bg="white">
                            <Flex justifyContent="space-between" alignItems="center">
                                <Text fontWeight="semibold">{moment(payroll.payrollMonth).format("MMMM YYYY")}</Text>
                                <Badge colorScheme={payroll.paymentStatus === 'Paid' ? 'green' : 'red'}>
                                    {payroll.paymentStatus}
                                </Badge>
                            </Flex>
                            <Divider my={2} />
                            <Table variant="unstyled" size="sm">
                                <Tbody>
                                    <Tr>
                                        <Td fontWeight="semibold">Gross Salary</Td>
                                        <Td>{payroll.salaryBreakdown?.grossPay?.toFixed(2)}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight="semibold">Net Pay</Td>
                                        <Td color="blue.600" fontWeight="bold">
                                            {payroll.salaryBreakdown?.netPay?.toFixed(2)}
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                            <Flex mt={2} justify="flex-end">
                                <IconButton
                                    icon={<ViewIcon />}
                                    aria-label="View Payslip"
                                    colorScheme="blue"
                                    variant="outline"
                                    mr={2}
                                />
                                <IconButton
                                    icon={<DownloadIcon />}
                                    aria-label="Download Payslip"
                                    colorScheme="blue"
                                    variant="solid"
                                />
                            </Flex>
                        </Box>
                    ))
                ) : (
                    <Text>No previous payroll records available.</Text>
                )}
            </VStack>
        </Box>
    );
};

export default Payroll;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Select,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
} from '@chakra-ui/react';
import { ArrowBackIcon, DownloadIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import PerformancePlan from '../../components/hr/components/performance/PerformancePlan';
import AddPerformance from '../../components/hr/dialogBoxes/AddPerformance';
import { get } from '../../services/apis/api';

const Performances = () => {
    const [openPlans, setOpenPlans] = useState(false);
    const [performances, setPerformances] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');

    // Function to get all employee performances from API
    const getAllEmployeePerformance = async (filters = {}) => {
        try {
            // Apply filters like month, year, search term
            const query = new URLSearchParams(filters).toString();
            const res = await get(`performance/employee/all?${query}`);
            if (res.statusCode === 200) {
                setPerformances(res.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };
    
    // Effect to handle filter changes (search, year, month)
    useEffect(() => {
        handleSearchAndFilter();
    }, [searchTerm, selectedYear, selectedMonth]); // Trigger whenever any of the filters change
    
    // Function to handle search and filters
    const handleSearchAndFilter = () => {
        const filters = {
            employeeName: searchTerm || '', 
            year: selectedYear || '',
            month: selectedMonth || '',
        };
        getAllEmployeePerformance(filters);
    };

    return (
        <>
            {openPlans ? (
                <PerformancePlan setOpenPlans={setOpenPlans} />
            ) : (
                <Box width="100%" p={4}>
                    <Flex justify="space-between" align="center" mb={4}>
                        <Box fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
                            Performances
                        </Box>
                        <Flex align="center">
                            {/* Search Input */}
                            <InputGroup mr={2} mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "auto" }}>
                                <InputLeftElement pointerEvents="none">
                                    <SearchIcon color="gray.300" />
                                </InputLeftElement>
                                <Input
                                    type="text"
                                    placeholder="Search by name"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </InputGroup>

                            {/* Year Filter */}
                            <Select
                                placeholder="Year"
                                w="100px"
                                mr={2}
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(e.target.value)}
                            >
                                {/* Add more years as options */}
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </Select>

                            {/* Month Filter */}
                            <Select
                                placeholder="Month"
                                w="150px"
                                mr={2}
                                value={selectedMonth}
                                onChange={(e) => setSelectedMonth(e.target.value)}
                            >
                                {/* Add all months as options */}
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </Select>

                            {/* Download CSV Button */}
                            <Button leftIcon={<DownloadIcon />} colorScheme="gray" mr={2} mb={{ base: 2, md: 0 }}>
                                Download CSV
                            </Button>

                            {/* Open Performance Plan Modal */}
                            <Button onClick={() => setOpenPlans(true)} colorScheme="blue" mb={{ base: 2, md: 0 }}>
                                Performance Plans
                            </Button>

                            {/* Add Performance Button */}
                            <AddPerformance getAllEmployeePerformance={getAllEmployeePerformance} isEdit={false} />
                        </Flex>
                    </Flex>

                    {/* Performance Table */}
                    <Table className="w-full text-sm text-left text-gray-500">
                        <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                            <Tr>
                                <Th>Profile</Th>
                                <Th>Name</Th>
                                <Th>Employee Code</Th>
                                <Th>Designation</Th>
                                <Th>Leaves</Th>
                                <Th>Reporting Head PP</Th>
                                <Th>Score</Th>
                                <Th>Performance</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {performances.map((row, index) => (
                                <Tr key={index}>
                                    <Td>
                                        <Avatar src={row?.employeeDetails?.pic} name={row?.employeeDetails?.name} />
                                    </Td>
                                    <Td>{row?.employeeDetails?.name}</Td>
                                    <Td>{row?.employeeDetails?.employeeCode}</Td>
                                    <Td>{row?.employeeDetails?.jobTitle || "-"}</Td>
                                    <Td>{row?.monthlyAttendance?.totalLeave || "N/A"}</Td>
                                    <Td>{row?.employeeDetails?.reportingHead?.name || "N/A"}</Td>
                                    <Td>{row?.finalPerformance || "N/A"}</Td>
                                    <Td>{row?.performanceCategory || "N/A"} 
                                        
                                        <AddPerformance getAllEmployeePerformance={getAllEmployeePerformance} isEdit={true} performance={row} />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            )}
        </>
    );
};

export default Performances;

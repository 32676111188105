import React from 'react'
import { Routes, Route } from "react-router-dom";
import CommanNav from '../components/globles/CommanNav';
import Appointments from '../pages/clinic/Appointments';
import Requests from '../pages/clinic/Requests';
import RequestDetails from '../pages/clinic/RequestDetails';
import CorporateRequests from '../pages/clinic/CorporateRequests';
import CorporateRequestDetails from '../pages/clinic/CorporateRequestDetails';
import AppointmentDetails from '../pages/clinic/AppointmentDetail';
import CorporateRequestDets from '../pages/clinic/CorporateRequestDets';
import ClinicDashboard from '../pages/clinic/ClinicDashboard';

const Clinic = () => {
  return (
    <Routes>
      <Route path="/clinic" element={<CommanNav />}>
        <Route index element={<ClinicDashboard />} />
        <Route path="appointments" element={<Appointments />} />
        <Route path="appointment" element={<Appointments />} />
        <Route path="requests" element={<Requests />} />
        <Route path="request" element={<Requests />} />
        <Route path="corporate-requests" element={<CorporateRequests />} />
        <Route path="request/:id" element={<RequestDetails />} />
        <Route path="corporate-request/:id" element={<CorporateRequestDets />} />
        <Route path="corporate-requests/:id" element={<CorporateRequestDetails />} />
        <Route path="appointment/:id" element={<AppointmentDetails />} />
      </Route>
    </Routes>
  )
}

export default Clinic
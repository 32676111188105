import React, { useEffect, useState } from "react";
import { formatDateTime } from "../../utills/formateDate";
import { get } from "../../services/apis/api";
import ViewAwards from "../../components/mediaCenter/ViewAwards";
import CreateAwards from "../../components/dialogueBoxes/CreateAwards";

const Awards = () => {
  const [awards, setAwards] = useState([]);
  const [viewAward, setViewAward] = useState(false);
  const [selectedAward, setSelectedAward] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  const getAllAwards = async () => {
    try {
      const res = await get("award/all");
      console.log(res);
      setAwards(res.data.awards);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllAwards();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredAwards = awards.filter(award => 
    award.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      {viewAward ? (
        <ViewAwards award={selectedAward} setViewAward={setViewAward} />
      ) : (
        <div>
          <div className="flex justify-between px-2 items-center mb-4">
            <div>
              <div className="relative">
                <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="table-search-awards"
                  className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Search for awards"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            <div>
              <CreateAwards getAllAwards={getAllAwards} />
            </div>
          </div>
          <div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="p-4">
                      <div className="flex items-center">S.No.</div>
                    </th>
                    <th scope="col" className="px-6 py-3">Name</th>
                    <th scope="col" className="px-6 py-3">Event</th>
                    <th scope="col" className="px-6 py-3">Team/Individual</th>
                    <th scope="col" className="px-6 py-3">Date</th>
                    <th scope="col" className="px-6 py-3">View</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAwards.map((award, index) => (
                    <tr className="bg-white border-b hover:bg-gray-50" key={award._id}>
                      <td className="w-4 p-4">
                        <div className="flex items-center">{index + 1}</div>
                      </td>
                      <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                        <div className="text-base font-semibold">{award.name}</div>
                      </th>
                      <td className="px-6 py-4">{award.eventId.name}</td>
                      <td className="px-6 py-4">
                        <div className="flex items-center">{award.individual}</div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex items-center">
                          {formatDateTime(award.createdAt, "date")}
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex items-center">
                          <button
                            onClick={() => {
                              setSelectedAward(award);
                              setViewAward(true);
                            }}
                            className="text-[#ffffff] bg-[#290dbd] font-semibold py-2 px-4 rounded-md"
                          >
                            View
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Awards;

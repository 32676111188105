import React, { useState, useEffect } from 'react';
import {
    Button,
    Input,
    Textarea,
    Select,
    useDisclosure,
    FormControl,
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast,
    IconButton,
    Spinner
} from '@chakra-ui/react';
import { get, patch, post } from '../../../services/apis/api';
import { EditIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';

const AddExpense = ({ getExpenses, isEdit, expense }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const [formData, setFormData] = useState({
        expenseType: '',
        employeeId: '',
        description: '',
        amount: '',
        paymentMethod: '',
        expenseDate: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        if (isEdit) {
            setFormData({
                expenseType: expense?.expenseType || '',
                description: expense?.description || '',
                amount: expense?.amount || '',
                paymentMethod: expense?.paymentMethod || '',
                expenseDate: expense?.expenseDate || '',
            });
        }
    }, [isEdit, expense]);

    const handleSubmit = async () => {
        try {
            setLoading(true);
            let response;
            if (isEdit) {
                response = await patch(`request/update-hr-expense/${expense._id}`, formData);
            } else {
                response = await post('request/add-hr-expense', formData);
            }

            if (response.statusCode === 201 || response.statusCode === 200) {
                toast({
                    title: 'Expense saved.',
                    description: 'Your expense has been saved successfully.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onClose();
                getExpenses();
                setLoading(false);
                setFormData({
                    employee: '',
                    expenseType: '',
                    description: '',
                    amount: '',
                    paymentMethod: '',
                    expenseDate: '',
                });
            } else {
                setLoading(false);
                toast({
                    title: 'Error',
                    description: 'Failed to save the expense.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: 'Error',
                description: 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const getEmployee = async () => {
        try {
            const res = await get('employee/dropdown');
            if (res.statusCode === 200) {
                const employeeOptions = res.data.dropdown.map(emp => ({
                    value: emp._id,
                    label: emp.name
                }));
                setEmployees(employeeOptions);
            }
        } catch (error) {
          console.log(error);
        }
    };
    useEffect(() => {
        getEmployee();
    }, []);

    const handleEmployeeChange = (selectedOption) => {
        setFormData((prevData) => ({
          ...prevData,
          employeeId: selectedOption?.value || "" // Set employeeId from the selected employee
        }));
    };
    

    return (
        <div>
            {
                isEdit ? 
                <IconButton
                    icon={<EditIcon />}
                    onClick={onOpen}
                    aria-label="Edit Expense"
                /> : 
                <Button onClick={onOpen} colorScheme="blue">
                    Add Expense
                </Button>
            }

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{isEdit ? 'Edit Expense' : 'Add Expense'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl id="expenseType" isRequired mt={4}>
                            <FormLabel>Expense Type</FormLabel>
                            <Select
                                name="expenseType"
                                value={formData.expenseType}
                                onChange={handleInputChange}
                                placeholder="Select expense type"
                            >
                                <option value="Recruitment">Recruitment</option>
                                <option value="Training">Training</option>
                                <option value="Office Supplies">Office Supplies</option>
                                <option value="Employee Benefits">Employee Benefits</option>
                                <option value="Miscellaneous">Miscellaneous</option>
                                <option value="Travel">Travel</option>
                                <option value="Meals">Meals</option>

                            </Select>
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel htmlFor='employee' textAlign={isArabic ? 'right' : 'left'}>
                            {t("select-employee (Optional)")}<span className='text-red-500'></span>
                            </FormLabel>
                            <ReactSelect
                                id="employee"
                                name="employeeId"
                                options={employees}
                                value={employees.find(emp => emp.value === formData.employeeId)}
                                onChange={handleEmployeeChange}
                                placeholder={t("select-employee")}
                                isClearable
                                isDisabled={isEdit}
                            />
                        </FormControl>

                        <FormControl id="description" isRequired mt={4}>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                placeholder="Enter description"
                            />
                        </FormControl>

                        <FormControl id="amount" isRequired mt={4}>
                            <FormLabel>Amount</FormLabel>
                            <Input
                                name="amount"
                                type="number"
                                value={formData.amount}
                                onChange={handleInputChange}
                                placeholder="Enter the amount"
                            />
                        </FormControl>

                        <FormControl id="paymentMethod" isRequired mt={4}>
                            <FormLabel>Payment Method</FormLabel>
                            <Select
                                name="paymentMethod"
                                value={formData.paymentMethod}
                                onChange={handleInputChange}
                                placeholder="Select payment method"
                            >
                                <option value="cash">Cash</option>
                                <option value="credit-card">Credit Card</option>
                                <option value="bank-transfer">Bank Transfer</option>
                                <option value="other">Other</option>
                            </Select>
                        </FormControl>

                        <FormControl id="expenseDate" isRequired mt={4}>
                            <FormLabel>Expense Date</FormLabel>
                            <Input
                                type="date"
                                name="expenseDate"
                                value={formData.expenseDate ? new Date(formData.expenseDate).toISOString().split('T')[0] : ''}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" mx={3} isDisabled={loading} onClick={handleSubmit}>
                            { loading ? <Spinner/> : "Submit" }
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default AddExpense;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CountrySelect from '../globles/CountrySelect';
import { BiImage, BiTrash } from 'react-icons/bi';

const Profile = () => {
    const [userDetails, setUserDetails] = useState(null);
    const [profileUrl, setProfileUrl] = useState('');
    const [rows, setRows] = useState(4);
    const [image, setImage] = useState("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTO2Zv5-ftMaaOpqJv7YXJjOw-JasAjG9YzZ5RSO7UqbiQkJn-z2MRxAkyoRMMWC7t_S9Q&usqp=CAU");
    const [file, setFile] = useState(null);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [currentPosition, setCurrentPosition] = useState("");
    const [fullNumber, setFullNumber] = useState("");  // Full number state
    const [description, setDescription] = useState("");

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const { data } = await axios.get('/api/user/details'); // Replace with your API route
                setUserDetails(data);
            } catch (error) {
                console.error("Error fetching user details:", error);
            }
        };

        fetchUserDetails();
    }, []);

    useEffect(() => {
        if (userDetails) {
            setFullName(userDetails.name || "");
            setEmail(userDetails.email || "");
            setCurrentPosition(userDetails.currentPosition || "");
            setDescription(userDetails.description || "");
            setFullNumber(userDetails.phone || "");  // Handle phone number with country code
        }
    }, [userDetails]);

    useEffect(() => {
        if (userDetails && userDetails.image) {
            const fetchProfileUrl = async () => {
                try {
                    const { data } = await axios.get(`/api/aws/url?key=${userDetails.image}`);
                    setProfileUrl(data.url);
                } catch (error) {
                    console.error("Error fetching profile URL:", error);
                }
            };
            fetchProfileUrl();
        }
    }, [userDetails]);

    useEffect(() => {
        if (profileUrl && !file) {
            setImage(profileUrl);
        }
    }, [profileUrl]);

    const handleFileSelection = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.onchange = (event) => {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);
            const imageUrl = selectedFile ? URL.createObjectURL(selectedFile) : '';
            setImage(imageUrl);
        };
        input.click();
    };

    const updateData = async () => {
        let body = {
            name: fullName,
            email,
            description,
            currentPosition,
            phone: fullNumber,  // Use full number with country code and mobile
            image: userDetails ? userDetails.image : ""
        };

        if (file) {
            const buffer = await file.arrayBuffer();
            const { data } = await axios.post('/api/aws', {
                buffer: buffer,
                type: file.type
            });
            body.image = data.key;
        }

        try {
            await axios.put('/api/user/update', body); // Replace with your API route
            setFile(null);
        } catch (error) {
            console.error("Error updating user details:", error);
        }
    };

    return (
        <div className='flex flex-col p-8'>
            <div className='flex'>
                <div className='rounded-full '>
                    <img className='rounded-full w-36 h-36' src={image} alt="Profile" />
                </div>
                <div className='flex flex-col ms-10'>
                    <button onClick={handleFileSelection} className='flex justify-center items-center my-3 border-2 border-[#5E3EF5] py-2 px-4 text-[#5E3EF5] font-semibold text-base rounded-lg'>
                        <BiImage className='me-2' size={16} />
                        Change
                    </button>
                    <button onClick={() => {}} className='flex justify-center items-center my-3 border-2 border-[#5E3EF5] py-2 px-4 text-[#5E3EF5] font-semibold text-base rounded-lg'>
                        <BiTrash className='me-2' size={16} />
                        Remove
                    </button>
                </div>
            </div>
            <div className="flex flex-row justify-between mt-4 pe-24">
                <div className="flex flex-col w-[42%]">
                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-1">Full Name</label>
                        <input value={fullName} onChange={(e) => setFullName(e.target.value)} type="text" id="fullName" name="fullName" placeholder='John Doe' className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500" />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-1">Email</label>
                        <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" id="email" name="email" placeholder='johndoe@example.com' className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500" />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-1">Current Position</label>
                        <input value={currentPosition} onChange={(e) => setCurrentPosition(e.target.value)} type="text" id="currentPosition" name="currentPosition" placeholder='Full Stack Developer' className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500" />
                    </div>
                    <div className="mt-4 flex">
                        <button className='flex justify-center items-center mx-1 border-2 border-[#5E3EF5] py-2 px-8 text-[#5E3EF5] font-semibold text-base rounded-lg'>
                            Cancel
                        </button>
                        <button onClick={updateData} className='flex justify-center items-center mx-1 border-2 border-[#5E3EF5] py-2 px-8 bg-[#5E3EF5] text-[#ffffff] font-semibold text-base rounded-lg'>
                            Update
                        </button>
                    </div>
                </div>
                <div className='w-[42%]'>
                    <label className="block text-sm font-medium mb-1">Contact Info</label>
                    <div className="mb-4 flex w-[100%]">
                        <CountrySelect
                            initialFullNumber={fullNumber}
                            setFullNumber={setFullNumber}
                        />
                    </div>
                    <div className="mb-4 w-[100%]">
                        <label className="block text-sm font-medium mb-2">Description</label>
                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} id="description" name="description" placeholder='Experienced Full Stack Developer specializing in building robust and scalable web and mobile applications.' rows={rows} className="w-[100%] px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;

import React, { useState } from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    Button,
    Tooltip,
    useToast,
    FormLabel,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { UserState } from '../../context/user'
import { CiCircleCheck } from 'react-icons/ci'
import { useTranslation } from 'react-i18next'

const ConfirmApproveBox = ({ handlePaySlipRequest, onCloseModel, label, request, fetchData }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast();
    const { user, setUser } = UserState();
    const cancelRef = React.useRef()
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return (
        <>
            <Tooltip label="Approve" aria-label="Reject tooltip">
                {/* <Button
                    variant="ghost"
                    colorScheme="red"
                    size="sm"
                    aria-label="Reject"
                    onClick={onOpen}
                    leftIcon={<RxCrossCircled size={20} />}
                >
                    {label ? 'Reject' : ''}
                </Button> */}
                <Button
                    variant="ghost"
                    colorScheme="green"
                    onClick={onOpen}
                    size="sm"
                    aria-label="Approve"
                >
                    <CiCircleCheck size={20} />
                </Button>
            </Tooltip>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader textAlign={isArabic ? "right" : "left"} fontSize='lg' fontWeight='bold'>
                            {t('approve-request')}
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <FormLabel textAlign={isArabic ? "right" : "left"}>
                                {t('approve-payroll-msg')}
                            </FormLabel>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button
                                size={"sm"}
                                ref={cancelRef}
                                onClick={onClose}
                            >
                                {t('cancel')}
                            </Button>
                            <Button
                                size={"sm"}
                                colorScheme='green'
                                onClick={() => {
                                    handlePaySlipRequest();
                                }}
                                ml={3}
                            >
                                {t('approve')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default ConfirmApproveBox
import React from 'react'
import { formatDateTime } from '../../utills/formateDate';
import { CloseButton, Button, Flex, Box } from '@chakra-ui/react';
import DeleteEvent from '../dialogueBoxes/DeleteEvent'
import CreateEvent from '../dialogueBoxes/CreateEvent';
import YouTube from 'react-youtube';

const ViewEvent = ({ event, setViewEvent, fetchEvents }) => {
  console.log(event);
  return (
    <div>
      <Flex justify={'space-between'}>
        <Button onClick={() => { setViewEvent(false) }} backgroundColor={'blue'} color={'white'}>Back</Button>
        <Flex gap={'10px'}>
          <DeleteEvent event={event} setShowEvent={setViewEvent} fetchEvents={fetchEvents} />
          <CreateEvent getAllEvents={fetchEvents} isEdit={true} event={event} setViewEvent={setViewEvent} />
        </Flex>
      </Flex>

      <div class="grid gap-4 mx-auto ">
        <div class="container my-2 mx-auto ">
          <h2 className='font-bold text-xl text-slate-950 ps-2'>Annual Sport Celebration</h2>
        </div>
        <div>
          <div className='flex flex-wrap'>
            <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-center items-center m-2'>
              <h2 className='font-bold text-md text-black'>Event Name: <span className='text-[#485060]'>{event.name}</span></h2>
            </div>
            <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-center items-center m-2'>
              <h2 className='font-bold text-md text-black'>Category: <span className='text-[#485060]'>{event.category}</span></h2>
            </div>
            <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-center items-center m-2'>
              <h2 className='font-bold text-md text-black'>Game: <span className='text-[#485060]'>{event.gameId?.name}</span></h2>
            </div>
            <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-center items-center m-2'>
              <h2 className='font-bold text-md text-black'>Location: <span className='text-[#485060]'>{event.location}</span></h2>
            </div>
            <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-center items-center m-2'>
              <h2 className='font-bold text-md text-black'>Date: <span className='text-[#485060]'>{formatDateTime(event.dateTime, "date")}</span></h2>
            </div>
            <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-center items-center m-2'>
              <h2 className='font-bold text-md text-black'>Time: <span className='text-[#485060]'>{formatDateTime(event.dateTime, "time")}</span></h2>
            </div>
          </div>
          <div>
            <p>
              {event.description}
            </p>
          </div>
        </div>
        <div className="flex flex-wrap gap-2 p-4">
          {event.media.length > 0 && event.media.map((item, index) => (
            <div key={index} className="w-full sm:w-1/2 md:w-[30%] p-2">
              <div className="relative w-full" style={{ paddingBottom: '56.25%' }}> {/* 16:9 aspect ratio */}
                {item.type === 'video' ? (
                  <video controls className="absolute top-0 left-0 w-full h-full object-cover">
                    <source src={item.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={item.url} alt={`media-${index}`} className="absolute top-0 left-0 w-full h-full object-cover" />
                )}
              </div>
            </div>
          ))}
        </div>

        {event.category === 'LIVE STREAM' &&
          <Box>
            <YouTube videoId={new URLSearchParams(new URL(event.streamUrl).search).get('v')} />
          </Box>
        }

      </div>

      {/* <p class="mb-3 text-gray-500 dark:text-gray-400">Track work across the enterprise through an open, collaborative platform. Link issues across Jira and ingest data from other software development tools, so your IT support and operations teams have richer contextual information to rapidly respond to requests, incidents, and changes.</p> */}
    </div>
  )
}

export default ViewEvent



import React from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

const RequestSummery = ({ requestsData }) => {
    const { t, i18n } = useTranslation();

    const [labels] = React.useState(['Completed', 'Pending', 'Rejected']);

    const chartOptions = {
        chart: {
            type: 'pie',
        },
        labels: requestsData?.labels || [],
        colors: ['#34D399', '#FBBF24', '#dc3545'],
        legend: {
            show: false,
        },
    };

    // Safely handle cases where requestsData or series might be undefined
    const seriesData = requestsData?.series || [];
    const totalRequests = requestsData?.total || 0;

    return (
        <div className="w-full lg:w-1/2 flex items-center justify-center flex-col bg-white shadow-md rounded-md p-2 lg:p-5">
            <div className="w-full flex justify-between">
                <h4 className="text-xl lg:text-xl font-bold mb-4">{t('request-summery')}</h4>
            </div>
            {seriesData.length > 0 ? (
                <Chart options={chartOptions} series={seriesData} type="donut" width="380" />
            ) : (
                <p>No data available</p>
            )}
            <div className="w-full">
                <div className="text-sm w-fit font-medium text-center mt-4">
                    <div className="flex items-center justify-between gap-12 mb-2">
                        <span className="flex items-center gap-2">
                            <span
                                className="w-3 h-3 rounded-full"
                                style={{ backgroundColor: '#007bff' }}
                            ></span>
                            {t('total')}
                        </span>
                        <span>{totalRequests}</span>
                    </div>
                    {requestsData?.labels?.length > 0 && seriesData?.length > 0
                        ? requestsData?.labels?.map((label, index) => (
                            <div key={index} className="flex items-center justify-between gap-12 mb-2">
                                <span className="flex items-center gap-2">
                                    <span
                                        className="w-3 h-3 rounded-full"
                                        style={{ backgroundColor: chartOptions.colors[index] }}
                                    ></span>
                                    {t(label)}
                                </span>
                                {/* Check if seriesData[index] is defined */}
                                <span>{seriesData[index] !== undefined ? seriesData[index] : 0}</span>
                            </div>
                        ))
                        : null}
                </div>
            </div>
        </div>
    );
};

export default RequestSummery;


export const getToken = ()=>{
    try {
        const token = JSON.parse(sessionStorage.getItem('token')) || JSON.parse(localStorage.getItem('token'));
        return token
    } catch (error) {
        console.log(error);
        return ""
    }
}

export const deleteToken = ()=>{
    try {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}
import React, { useState } from 'react';
import {
    Box,
    Button,
    Center,
    FormControl,
    Heading,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    PinInput,
    PinInputField,
    Stack,
    Text,
    VStack,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { patch, post } from '../../services/apis/api';
import CountrySelect from '../../components/globles/CountrySelect';

const ForgotPassword = () => {
    const [step, setStep] = useState(1);
    const [userName, setUserName] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState('');
    const toast = useToast();
    const navigate = useNavigate();

    const handleSendOtp = async () => {
        try {
            const response = await post('user/exist/send-otp', { userName: userName });
            setMessage(response.data.message);
            setUserName(response.data.to.mobile);
            setStep(2);
        } catch (error) {
            setMessage(error.response.data.message);
        }
    };

    const handleVerifyOtp = async () => {
        try {
            const response = await post('user/verify-otp', { to: userName, code: otp });
            setMessage(response.data.message);
            setStep(3);
        } catch (error) {
            setMessage(error.response.data.message);
        }
    };

    const handleUpdatePassword = async () => {
        try {
            const response = await patch('user/update-password', { userName, newPassword });
            setMessage(response.data.message);
            toast({
                title: "Password updated successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            navigate('/login');
        } catch (error) {
            setMessage(error.response.data.message);
        }
    };

    return (
        <Box className="relative flex justify-center items-center h-screen">
            <video
                autoPlay
                loop
                muted
                className="absolute inset-0 w-full h-full object-cover"
                style={{
                    filter: 'brightness(50%)',
                }}
            >
                <source src="https://firebasestorage.googleapis.com/v0/b/snp-collection.appspot.com/o/6077718-uhd_3840_2160_25fps.mp4?alt=media&token=687255f8-88ff-4f2a-8c82-b37f88f982a5" type="video/mp4" />
            </video>
            <Box className="relative z-10 p-8 bg-white rounded-lg shadow-md">
                <VStack spacing={4}>
                    <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
                        {
                            step === 3 ? "Add Your New Password" : "Forgot your password?"
                        }
                    </Heading>
                    {message && <Text className="text-red-500">{message}</Text>}
                    {step === 1 && (
                        <>
                            <Text
                                fontSize={{ base: 'sm', sm: 'md' }}
                                color={'gray.400'}>
                                You'll get an OTP on Provided Mobile Number (with country code) OR email OR userName
                            </Text>
                            <Input onChange={(e)=>{setUserName(e.target.value)}}  placeholder="email, mobile with countryCode, userName"/>
                            {/* <CountrySelect
                                setFullNumber={setUserName}
                            /> */}
                            <Button colorScheme='blue' onClick={handleSendOtp} className="bg-blue-500 text-white p-2 rounded">
                                Send OTP
                            </Button>
                        </>
                    )}
                    {step === 2 && (
                        <Stack
                            spacing={4}
                            w={'full'}
                            maxW={'sm'}
                            bg={'white'}
                            rounded={'xl'}
                            boxShadow={'lg'}
                            p={6}
                            my={10}>
                            <Center>
                                <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
                                    Verify your OTP
                                </Heading>
                            </Center>
                            <Center
                                fontSize={{ base: 'sm', sm: 'md' }}
                                color={'gray.400'}>
                                We have sent a code to your mobile number
                            </Center>
                            <Center
                                fontSize={{ base: 'sm', sm: 'md' }}
                                fontWeight="bold"
                                color={'gray.400'}>
                                {userName}
                            </Center>
                            <FormControl>
                                <Center>
                                    <HStack>
                                        <PinInput otp value={otp} onChange={setOtp}>
                                            <PinInputField />
                                            <PinInputField />
                                            <PinInputField />
                                            <PinInputField />
                                            <PinInputField />
                                            <PinInputField />
                                        </PinInput>
                                    </HStack>
                                </Center>
                            </FormControl>
                            <Stack spacing={6}>
                                <Button
                                    bg={'blue.400'}
                                    color={'white'}
                                    _hover={{
                                        bg: 'blue.500',
                                    }}
                                    onClick={handleVerifyOtp}>
                                    Verify
                                </Button>
                            </Stack>
                        </Stack>
                    )}
                    {step === 3 && (
                        <>
                            <InputGroup>
                                <Input
                                    placeholder="Enter your new password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    className="border p-2 rounded"
                                />
                                <InputRightElement width="4.5rem">
                                    <Button h="1.75rem" size="sm" onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? 'Hide' : 'Show'}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                            <Button colorScheme='blue' onClick={handleUpdatePassword} className="bg-blue-500 text-white p-2 rounded">
                                Update Password
                            </Button>
                        </>
                    )}
                </VStack>
            </Box>
        </Box>
    );
};

export default ForgotPassword;

import React from "react";
import {
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useDisclosure,
} from "@chakra-ui/react";
import { deletethis } from "../../services/apis/api"; // Ensure correct import path

const DeleteProduct = ({ product, getWarehouseProducts }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();

    const deleteProduct = async () => {
        console.log(product._id);

        try {
            const res = await deletethis(`warehouse/products/${product._id}`);
            if (res.statusCode === 200) {
                getWarehouseProducts();
                onClose();
            } else {
                console.error("Failed to delete product:", res);
            }
        } catch (error) {
            console.error("Error deleting product:", error);
        }
    };

    return (
        <>
            <Button onClick={onOpen} colorScheme="red" size="sm">
                Delete
            </Button>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Product
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to delete this product?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                colorScheme="red"
                                onClick={deleteProduct}
                                ml={3}
                            >
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export default DeleteProduct;

import React, { useState } from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    Button,
    Tooltip,
    useToast,
    FormLabel,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { UserState } from '../../context/user'
import { CiCircleCheck } from 'react-icons/ci'
import { useTranslation } from 'react-i18next'
import { GoCheckCircle } from "react-icons/go";

const ConfirmApproveRequest = ({
    handleApprove,
    isButton,
    isLoading
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast();
    const { user, setUser } = UserState();
    const cancelRef = React.useRef()
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return (
        <>
            <Tooltip isDisabled={isButton} label={t('approve')} aria-label="Approve tooltip">
                {isButton ?
                    <Button
                        bg="green.50"
                        _hover={{ bg: "green.100" }}
                        color="green.700"
                        borderColor="green.200"
                        onClick={onOpen}
                        leftIcon={<GoCheckCircle className="w-4 h-4" />}
                    >
                        <span className="text-sm font-medium">{t('approve')}</span>
                    </Button>
                    :
                    <button
                        onClick={onOpen}
                        className="text-green-600 hover:text-green-800"
                    >
                        <GoCheckCircle className="h-5 w-5" />
                    </button>
                }
            </Tooltip>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader textAlign={isArabic ? "right" : "left"} fontSize='lg' fontWeight='bold'>
                            {t('approve-request')}
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <FormLabel textAlign={isArabic ? "right" : "left"}>
                                {t('approve-request-msg')}
                            </FormLabel>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button
                                size={"sm"}
                                ref={cancelRef}
                                onClick={onClose}
                            >
                                {t('cancel')}
                            </Button>
                            <Button
                                size={"sm"}
                                colorScheme='green'
                                onClick={() => {
                                    handleApprove();
                                    onClose();
                                }}
                                isLoading={isLoading}
                                ml={3}
                            >
                                {t('approve')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default ConfirmApproveRequest
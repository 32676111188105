// src/pages/employee/EmployeePerformancePlan.jsx
import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Select,
  Text,
  Button,
  InputGroup,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import { get } from '../../services/apis/api';
import DatePicker from 'react-datepicker';
import { CiCalendarDate } from 'react-icons/ci';
import { UserState } from '../../context/user';

const EmployeePerformancePlan = () => {
  const [performanceData, setPerformanceData] = useState();
  const [startDate, setStartDate] = useState('');
  const {user} = UserState();

  const handleDateChange = (date) => {
    const updatedDate = new Date(date.getFullYear(), date.getMonth(), 30);
    setStartDate(updatedDate);
  };
  console.log(performanceData);
  

  const getPerformanceDetails = async () => {
    try {
      const res = await get(`performance/employee-data?date=${startDate}`);
      if (res.statusCode === 200) {
        setPerformanceData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getPerformanceDetails();
  }, [startDate])


  return (
    <Box width="100%" p={4}>
      <Flex justify="space-between" align="center" mb={4}>
        <Box fontSize="xl" fontWeight="bold">
          Performance Plan for {user?.name}
        </Box>
        <Flex align="center">
          <InputGroup mr={2} w={{ base: "100%", md: "auto" }}>
           <h2 className='font-semibold text-lg'>Search By Month : </h2>
          </InputGroup>
          <div className="relative w-[195px] me-4 ">
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              placeholderText="Select Month"
              className="w-[200px] border rounded-md p-2 pr-12"
            />
            <CiCalendarDate className="absolute right-0 top-1/2 transform -translate-y-1/2 text-xl text-gray-500 pointer-events-none" />
          </div>
          <Button leftIcon={<DownloadIcon />} colorScheme="gray">
            Download CSV
          </Button>
        </Flex>
      </Flex>

      {/* Employee Profile Section */}
      <Flex align="center" mb={4}>
        <Avatar src={user?.pic} name={user?.name} mr={4} />
        <Box>
          <Text fontSize="lg" fontWeight="bold">{user?.name}</Text>
          <Text color="gray.500">Employee Code: {user?.employeeCode}</Text>
          <Text color="gray.500">Designation: {user?.designation}</Text>
        </Box>
      </Flex>

      {
        performanceData ?
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Question</Th>
              <Th>Score</Th>
            </Tr>
          </Thead>
          <Tbody>
            {performanceData?.performanceQue?.map((plan, idx) => (
                  <Tr key={idx}>
                    <Td>{plan?.questionDetails?.questionText}</Td>
                    <Td>{plan?.score}</Td>
                  </Tr>
            ))}

            <Tr bg="gray.100">
              <Td colSpan="2" fontWeight="bold">
                Monthly Performance Score
              </Td>
              <Td fontWeight="bold">{performanceData?.finalPerformance}</Td>
            </Tr>
          </Tbody>
        </Table> 
        :
        <div>
          no data found
        </div>
      }
    </Box>
  );
};

export default EmployeePerformancePlan;

import React from 'react'

const ViewAwards = ({ award, setViewAward }) => {
  console.log(award);
  return (
    <div>
      <div className='py-4'>
        <button onClick={() => { setViewAward(false) }} className='bg-[#290dbd]  mx-auto  text-[#ffffff] px-4 py-2 font-medium text-base rounded-md'>Back</button>
      </div>

      <div class="grid gap-4 mx-auto ">
        <div class="container my-2 mx-auto ">
          <h2 className='font-bold text-xl text-slate-950 ps-2'>Annual Sport Celebration</h2>
        </div>
        <div>
          <div className='flex'>
            <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-center items-center m-2'>
              <h2 className='font-bold text-md text-black'>Event Name: <span className='text-[#485060]'>{award.eventId.name}</span></h2>
            </div>  
            <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-center items-center m-2'>
              <h2 className='font-bold text-md text-black'>Individual: <span className='text-[#485060]'>{award.individual}</span></h2>
            </div>
          </div>
          <div>
            <p>
              {award.description}
            </p>
          </div>
        </div>
        
        <div className="flex flex-wrap gap-4 p-4">
            {award.media.length > 0 && award.media.map((item, index) => (
                <div key={index} className="w-full sm:w-1/2 md:w-[31%] p-1">
                    <div className="relative w-full" style={{ paddingBottom: '56.25%' }}> {/* 16:9 aspect ratio */}
                        {item.type === 'video' ? (
                            <video controls className="absolute top-0 left-0 w-full h-full object-cover">
                                <source src={item.url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <img src={item.url} alt={`media-${index}`} className="absolute top-0 left-0 w-full h-full object-cover" />
                        )}
                    </div>
                </div>
            ))}
        </div>
      </div>

      {/* <p class="mb-3 text-gray-500 dark:text-gray-400">Track work across the enterprise through an open, collaborative platform. Link issues across Jira and ingest data from other software development tools, so your IT support and operations teams have richer contextual information to rapidly respond to requests, incidents, and changes.</p> */}
    </div>
  )
}

export default ViewAwards
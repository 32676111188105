import React, { useEffect, useState } from 'react';
import { get } from '../../services/apis/api';
import CreateCategory from '../../components/dialogueBoxes/CreateCategory';
import ViewEvent from '../../components/mediaCenter/ViewEvent';
import { truncateText } from '../../utills/truncateText';
import { useTranslation } from 'react-i18next';

const Category = () => {
  const [categories, setCategories] = useState([]);
  const [viewEvent, setViewEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const getAllCategories = async (search = '') => {
    try {
      const res = await get(`category/all?search=${search}`);
      console.log('category', res.data.data);
      setCategories(res.data.data);
      setError('');
    } catch (err) {
      setError('Failed to fetch categories');
      console.error(err);
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    getAllCategories(e.target.value);
  };

  return (
    <div>
      {viewEvent ? (
        <ViewEvent event={selectedEvent} setViewEvent={setViewEvent} />
      ) : (
        <div>
          <div className='flex justify-between px-2 items-center mb-4'>
            <div>
              <div className='relative'>
                <div className='absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none'>
                  <svg
                    className='w-4 h-4 text-gray-500 '
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 20 20'
                  >
                    <path
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                    />
                  </svg>
                </div>
                <input
                  type='text'
                  id='table-search-users'
                  className='block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 '
                  placeholder={t('Search for categories')}
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div>
              <CreateCategory getAllCategories={getAllCategories} />
            </div>
          </div>

          <div>
            <div className='relative overflow-x-auto shadow-md sm:rounded-lg'>
              <table className='w-full text-sm text-left rtl:text-right text-gray-500 '>
                <thead className='text-xs text-gray-700 uppercase bg-gray-50 '>
                  <tr>
                    <th scope='col' className='p-4'>
                      <div className='flex items-center'>{t('S.No.')}.</div>
                    </th>
                    <th scope='col' className='px-6 py-3'>{t('Categories')}</th>
                    <th scope='col' className='px-6 py-3'>{t('Description')}</th>
                    <th scope='col' className='px-6 py-3'>{t('action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category, index) => (
                    <tr
                      className='bg-white border-b '
                      key={category._id}
                    >
                      <td className='w-4 p-4'>
                        <div className='flex items-center'>{index + 1}</div>
                      </td>
                      <th
                        scope='row'
                        className='flex items-center px-6 py-4 text-gray-900 whitespace-nowrap '
                      >
                        <div className='text-base font-semibold'>{category.name}</div>
                      </th>
                      <td className='px-6 py-4'>{truncateText(category.description, 150)}</td>
                      <td className='px-6 py-4'>
                        <CreateCategory
                          getAllCategories={getAllCategories}
                          category={category}
                          isEdit={true}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {error && (
            <div className='flex justify-center items-center h-[60vh]'>
              <div className='text-red-500 text-center'>
                {error}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Category;

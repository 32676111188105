import { Avatar, Button, Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaMars, FaRegEye, FaTransgender, FaVenus } from "react-icons/fa6";
import { formatDateArEn, formatTimeArEn, getStatusColor } from "../../services/glocalFunctions";
import { CiCalendar, CiClock1, CiUser } from "react-icons/ci";
import ConfirmRejectRequest from "./ConfirmRejectRequest";
import { FiFileText, FiUsers } from "react-icons/fi";
import { LuFileCheck } from "react-icons/lu";
import { MdOutlineFileDownload } from "react-icons/md";
import React from "react";
import { BiTrendingDown, BiTrendingUp } from "react-icons/bi";
import { TbArrowDownRight, TbArrowUpRight } from "react-icons/tb";
import { DownloadIcon } from "@chakra-ui/icons";


const statusLabelClass = `text-xl lg:text-sm text-gray-700 font-semibold leading-none`


// LABLE FIELD COMPONENT /
export const FieldLabel = ({
    icon,
    title
}) => (
    <div className={`flex items-center gap-2  text-gray-500 text-sm font-medium`}>
        {icon}
        {title}
    </div>
)



// CORPORATE LABLE FIELD COMPONENT 
export const CorporateField = ({
    value,
    title,
    icon
}) => {
    const { t } = useTranslation();
    return (
        <div className="flex items-center space-x-2">
            {icon}
            <div className={`flex items-center gap-2 text-gray-600 text-sm font-medium`}>
                {title?.toLowerCase() === "since" ? (
                    <>
                        <span>{t(title) || '--'}</span>
                        <span>{value || 0}</span>
                    </>
                ) : (
                    <>
                        <span>{value || 0}</span>
                        <span>{t(title) || '--'}</span>
                    </>
                )}
            </div >
        </div>
    )
}

// STATUS LABLE FIELD COMPONENT 
export const StatusFieldLabel = ({
    value,
    title
}) => {
    const { t } = useTranslation();
    return (
        <>
            <div className={`flex flex-col items-start justify-start gap-2`}>
                <span className={statusLabelClass}>{t(title)}</span>
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(value)}`}>
                    {t(value?.toLowerCase()) || '--'}
                </span>
            </div>
        </>
    )
}

// PROFILE COMPONENT 
export const ProfileComponent = ({
    name,
    designation,
    profileImg
}) => {
    const { t } = useTranslation();
    return (
        <>
            <div className={`flex items-center gap-2 text-base font-semibold`}>
                <Avatar
                    name={name}
                    src={profileImg || undefined} // Use profile image if available
                    icon={<CiUser />}
                />
                <div className="flex flex-col">
                    <span className='text-xl lg:text-base text-gray-800  font-semibold leading-10'>{name || '--'}</span>
                    <span className='text-sm lg:text-sm text-gray-500'>{t(designation?.toLowerCase()) || '--'}</span>
                </div>
            </div>
        </>
    )
}


// GET THE GENDER ICON 
export const getGenderIcon = (gender) => {
    try {
        if (!gender) return null;

        const genderLower = gender.toLowerCase();

        if (genderLower === 'male') {
            return <FaMars className="text-blue-500" />;
        } else if (genderLower === 'female') {
            return <FaVenus className="text-pink-500" />;
        } else {
            return <FaTransgender className="text-gray-400" />;
        }
    } catch (e) {
        console.log(`ERROR WHILE FETCHING ICON ${e}`);
    }
};

// SHOW DESCRIPTION COMPONENT 
export const Descriptioncomponent = ({
    label,
    text
}) => {
    const { t } = useTranslation();

    return (
        <>
            {/* INJURY DESCRIPTION/LATEST FEEDBACK  */}
            <div className={`flex flex-col items-start gap-1`}>
                <span className="text-gray-700 font-bold text-sm">
                    {t(label)}
                </span>
                <span className={`text-gray-500 text-xs font-medium`}>
                    <Tooltip
                        isDisabled={!text || text?.length < 500}
                        label={text && text?.length > 500
                            ? text
                            : '--'}
                        aria-label="Full Injury Description"
                        hasArrow
                        placement="top"
                    >
                        <span>
                            {text?.length > 500
                                ? text.slice(0, 500) + '...'
                                : text || '--'}
                        </span>
                    </Tooltip>
                </span>
            </div>
        </>
    )
}

// FEEDBACK CARD 

export const FeedbackCard = ({
    name,
    designation,
    feedback,
    icon,
    label, // NEW LABEL PROP
}) => {
    const { t } = useTranslation();

    // Assign label color dynamically based on the label type
    const labelColors = {
        "doctor-feedback": "text-blue-600",
        "manager-feedback": "text-green-600",
        "closing-note": "text-gray-700",
        "rejection-reason": "text-red-600"
    };

    return (
        <div className="bg-gray-50 rounded-xl p-4 w-full">
            {/* LABEL WITH DYNAMIC COLOR */}
            <span className={`text-xs font-semibold ${labelColors[label] || "text-gray-600"}`}>
                {t(label)}
            </span>

            <div className="flex items-center justify-between mt-1">
                <div className="flex items-center space-x-3">
                    {icon}
                    <div className="flex flex-col items-start gap-0">
                        <h4 className="font-medium text-gray-900">{name || '--'}</h4>
                        <span className="text-gray-500 text-sm font-medium">
                            {designation?.toLowerCase() || '--'}
                        </span>
                    </div>
                </div>
            </div>

            {/* FEEDBACK TEXT WITH TOOLTIP */}
            <span className="text-gray-500 text-sm font-medium">
                <Tooltip
                    isDisabled={!feedback || feedback?.length < 500}
                    label={feedback && feedback?.length > 500 ? feedback : '--'}
                    aria-label="Full Feedback"
                    hasArrow
                    placement="top"
                >
                    <span>
                        {feedback?.length > 500
                            ? feedback.slice(0, 500) + '...'
                            : feedback || '--'}
                    </span>
                </Tooltip>
            </span>
        </div>
    );
};

// APPOINTMENT CARD 
export const AppointmentCard = ({
    reason,
    setReason,
    appointmentId,
    onScheduleClick,
    appointmentDate,
    appointmentstatus,
    onViewAppointment,
    onCancelScheduleClick
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return (
        <>
            <div className="bg-gray-50 rounded-xl p-4 w-full">
                {/* LAST APPOINTMENT BASIC INFO  */}
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                        <CiCalendar className="w-5 h-5 text-blue-600" />
                        <div>
                            <h4 className="font-medium text-gray-900">{t('appointment')} {appointmentId}</h4>
                            <div className={`flex items-center gap-4`}>
                                <div className='flex items-center gap-2'>
                                    <CiCalendar color='gray' />
                                    <span className={`text-sm lg:text-sm font-medium text-gray-500`}>
                                        {formatDateArEn(appointmentDate, isArabic)}
                                    </span>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <CiClock1 color='gray' />
                                    <span className={`text-sm lg:text-sm font-medium text-gray-500`}>
                                        {formatTimeArEn(appointmentDate, isArabic)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* LAST APPOINTMENT STATUS  */}
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(appointmentstatus)}`}>
                        {t(appointmentstatus?.toLowerCase()) || '--'}
                    </span>
                </div>

                {/* LAST APPOINTMENT ACIONS  */}
                <div className="flex items-center mt-3 justify-end space-x-2">
                    {/* SCHEDULE BUTTON  */}
                    {['cancelled', 'missed', 'scheduled', 'rescheduled'].includes(appointmentstatus?.toLowerCase()) &&
                        <Button
                            bg="blue.50"
                            _hover={{ bg: "blue.100" }}
                            color="blue.700"
                            borderColor="blue.200"
                            leftIcon={<CiCalendar className="w-4 h-4" />}
                            onClick={() => {
                                onScheduleClick();
                            }}
                        >
                            <span className="text-sm font-medium">
                                {['scheduled', 'rescheduled'].includes(appointmentstatus?.toLowerCase()) ? t('reschedule') : t('schedule-new')}
                            </span>
                        </Button>
                    }
                    {/* CONFIRM CANCEL APPOINTMENT  */}
                    {['scheduled', 'rescheduled'].includes(appointmentstatus?.toLowerCase()) &&
                        <ConfirmRejectRequest
                            toolLabel="cancel-appointment"
                            label="cancel-appointment"
                            buttonLabel="cancel"
                            handleSubmit={() => {
                                onCancelScheduleClick();
                            }}
                            isButton={true}
                            message="cancel-request-msg"
                            reason={reason}
                            setReason={setReason}
                        />
                    }
                    <Button
                        bg="gray.50"
                        _hover={{ bg: "gray.100" }}
                        color="gray.700"
                        borderColor="gray.200"
                        leftIcon={<FaRegEye className="w-4 h-4" />}
                        onClick={() => {
                            onViewAppointment();
                        }}
                    >
                        <span className="text-sm font-medium">
                            {t('view')}
                        </span>
                    </Button>
                </div>
            </div>
        </>
    )
}


// DOCUMENT CARD COMPONENT 
export const DocumentCard = ({
    name,
    uploadedDate,
    status,
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return (
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center space-x-3">
                <FiFileText className="w-5 h-5 text-gray-400" />
                <div>
                    <p className="font-medium text-gray-900">{name || '--'}</p>
                    <p className="text-sm text-gray-600">Uploaded on {new Date(uploadedDate)?.toLocaleDateString() || '--'}</p>
                </div>
            </div>
            <div className="flex items-center space-x-4">
                <span
                    className={`px-3 py-1 rounded-full text-sm font-medium ${status === 'VERIFIED' ? 'bg-green-50 text-green-700' :
                        status === 'REJECTED' ? 'bg-red-50 text-red-700' :
                            'bg-yellow-50 text-yellow-700'
                        }`}
                >
                    {status || '--'}
                </span>
                <Button
                    variant="outline"
                    size="sm"
                    leftIcon={<LuFileCheck className="w-4 h-4" />}
                >
                    {t('view')}
                </Button>
            </div>
        </div>
    )
}

// CARD TO SHOW THE TOTAL COUNTS SUMMERY ON DASHBOARD 
export const DashboardTotalCard = ({
    icon,
    label,
    colors,
    value,
    growth,
    subMsg,
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return (
        <>
            <div className="bg-white  rounded-xl border border-gray-200 p-6 hover:shadow-lg transition-all duration-200">
                <div className="flex items-center justify-between">
                    <div>
                        <p className="text-gray-600 text-sm font-medium">{t(label) || '--'}</p>
                        <p className="text-2xl font-bold mt-2">{value}</p>
                        <div className="flex items-center gap-2 mt-1">
                            {growth && (
                                <span className={`flex items-center font-semibold text-sm ${growth > 0 ? 'text-green-600' : 'text-red-600'}`}>
                                    {growth > 0 ? <BiTrendingUp className="w-4 h-4" /> : <BiTrendingDown className="w-4 h-4" />}
                                    &nbsp;{Math.abs(growth)}%
                                </span>
                            )}
                            <p className="text-xs font-medium text-gray-500">{subMsg}</p>
                        </div>
                    </div>
                    <div className={`p-3 rounded-lg ${colors}`}>
                        {icon}
                    </div>
                </div>
            </div>
        </>
    )
};

// ANALYTIC CARD COMPONENT 
export const AnlyticCard = ({
    status,
    config,
    Icon,
    isPositive,
    amount,
    percentage,
    lineLabel
}) => {
    const { t } = useTranslation();

    return (
        <>
            <div
                key={status}
                className={`relative overflow-hidden rounded-xl border ${config?.borderColor} p-4 hover:shadow-md transition-shadow`}
            >
                <div className={`absolute top-0 right-0 w-24 h-24 -mr-8 -mt-8 rounded-full opacity-10 ${config?.bgColor}`} />

                <div className="flex items-start justify-between mb-2">
                    <div className={`${config?.lightColor} p-2 rounded-lg`}>
                        <Icon className={`w-5 h-5 ${config?.textColor}`} />
                    </div>
                    {/* <div className={`flex items-center gap-1 text-sm ${isPositive ? 'text-emerald-600' : 'text-red-600'}`}>
                        {isPositive ? <TbArrowUpRight className="w-4 h-4" /> : <TbArrowDownRight className="w-4 h-4" />}
                        <span>{Math.floor(Math.random() * 20)}%</span>
                    </div> */}
                </div>

                <div className="space-y-2">
                    <div>
                        <h4 className="text-lg font-semibold text-gray-900">{amount.toLocaleString()}</h4>
                        <p className="text-sm text-gray-500">{config?.label}</p>
                    </div>

                    <div className="space-y-2">
                        <div className="flex justify-between text-sm">
                            <span className="text-gray-500">{t(lineLabel)} </span>
                            <span className={config?.textColor}>{percentage}%</span>
                        </div>
                        <div className="w-full h-2 bg-gray-100 rounded-full overflow-hidden">
                            <div
                                className={`h-full rounded-full transition-all duration-500 ${config?.bgColor}`}
                                style={{ width: `${percentage}%` }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

// ANALYTIC HEADER COMPONENT 
export const AnalyticHeader = ({
    label,
    msg
}) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="flex items-center justify-between mb-6">
                <div>
                    <h3 className="font-semibold text-xl text-gray-900">{t(label)}</h3>
                    <p className="text-sm text-gray-500">{t(msg)}</p>
                </div>
                {/* <Button
                    bg="blue.50"
                    color="blue.600"
                    borderRadius="lg"
                    _hover={{ bg: "blue.100" }}
                    leftIcon={<DownloadIcon w={4} h={4} />}
                >
                    {t("export-report")}
                </Button> */}
            </div>
        </>
    )
}

// DASHBOARD LISTS  CARDS 
export const DashboardListCard = ({
    icon,
    colors,
    status,
    heading,
    subHeading,
    rightHeading
}) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                <div className={`flex items-center gap-4`}>
                    <div className={`${colors} p-2 rounded-full`}>
                        {icon}
                    </div>
                    <div>
                        <p className="font-medium">{heading || '--'}</p>
                        <p className="text-sm text-gray-600">{subHeading}</p>
                    </div>
                </div>
                <div className={`flex flex-col gap-1 items-end`}>
                    <p className="font-medium">{rightHeading}</p>
                    {/* REQUEST STATUS  */}
                    <span className={`flex bg-transparent items-center justify-center text-xs font-semibold rounded-full ${getStatusColor(status)}`}>
                        {t(status?.toLowerCase()) || ''}
                    </span>
                </div>
            </div>
        </>
    )
}
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import { Button, useDisclosure, useToast } from '@chakra-ui/react';
import { debounce } from 'lodash';
import AssetsTable from '../../components/account/AssetsTable';
import CreateAsset from '../../components/hr/dialogBoxes/CreateAssets';
import ViewAssetDetailsModal from '../../components/hr/components/assets/ViewAssetDetailsModal';
import { get } from '../../services/apis/api';
import { formatDate } from '../../services/glocalFunctions';
import { DownloadIcon } from '@chakra-ui/icons';
import ConfirmDownload from '../../components/dialogueBoxes/ConfirmDownload';

// TABLE COLUMNS 
const columns = [
    's-no',
    'asset-name',
    // 'category',
    'asset-model',
    'asset-id',
    'purhcase-date',
    // 'depritiated-rate',
    // 'depritiated-freuency',
    'status',
    'condition',
    // 'Description',
    'actions'
];


const Assets = () => {
    const toast = useToast();
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [searchTerm, setSearchTerm] = useState('');
    const [allTransactions, setAllTransactions] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0); // Initial skip should be 0
    const [limit, setLimit] = useState(10);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure(); // Chakra UI's modal controls
    const [loading, setLoading] = useState(false);
    const [downloadOpen, setDownloadOpen] = useState(false);  // for confirmation dialog
    const [date, setDate] = useState({
        startDate: '',
        endDate: ''
    });

    // FETCH ALL TRRANSECTIONS BY API'S 
    const getAllAssets = async () => {
        try {
            if (user) {
                const res = await get(`assets/all?page=${page}&limit=${limit}&search=${searchTerm}`);
                if (res.statusCode === 200) {
                    setAllTransactions(res?.data?.data);
                    const totalCount = res?.data?.totalCount || 0; // Ensure totalCount is defined
                    setTotalPages(Math.ceil(totalCount / limit)); // Calculate total pages
                } else {
                    setAllTransactions([]);
                }
            } else {
                setAllTransactions([]);
            }
        } catch (error) {
            setAllTransactions([]);
            console.log(error);
        }
    };

    useEffect(() => {
        getAllAssets();
    }, [page, limit]); // Fetch data when page or limit changes

    // Debounce the getAllAssets function
    const debouncedGetAllRequest = debounce(getAllAssets, 300); // Adjust delay as needed

    useEffect(() => {
        debouncedGetAllRequest();
        // Cleanup function to cancel the debounced call on component unmount
        return () => {
            debouncedGetAllRequest.cancel();
        };
    }, [searchTerm]);

    // useEffect(() => {
    //     if (date.startDate && date.endDate) {
    //         getAllAssets();
    //     }
    // }, [date]);

    // EXPORT EMPLOYEE PAYROLL AS CSV 
    const downloadAssetsAsCSV = async () => {
        setLoading(true);
        try {
            const res = await get(`assets/all${searchTerm ? `?search=${searchTerm}` : ''}`);

            setLoading(false);
            if (res?.statusCode == 200) {
                const assetsDate = res?.data?.data
                // Construct the filename with the current year
                const filename = `assets.csv`;

                // Check if there are payslips to export
                if (!assetsDate || assetsDate?.length === 0) {
                    console.error("No payrolls to export.");
                    return;
                }

                // Generate headers from the first payslip object keys
                const headers = [
                    "S NO.",
                    "Asset Name",
                    "Asset Model",
                    "Asset ID",
                    "Purchase Date",
                    "status",
                    "Condition"
                ];

                // Convert payslip data into CSV rows
                const rows = assetsDate?.map((asset, index) => {
                    return [
                        index + 1,
                        asset?.name || "N/A",
                        asset?.model || "N/A",
                        asset?.serialNumber || "N/A",
                        asset?.createdAt ? formatDate(asset?.purchaseDate) : "N/A",
                        asset?.status || "N/A",
                        asset?.condition || "N/A"
                    ]?.join(",");
                });

                // Combine headers and rows
                const csvContent = [headers?.join(","), ...rows]?.join("\n");

                // Create Blob and trigger download
                const blob = new Blob([csvContent], { type: "text/csv" });
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                setLoading(false);
                toast({
                    title: 'Success',
                    description: 'CSV Downloaded Successfully!',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (e) {
            console.log(e, 'erroridcsv');

            setLoading(false);
            toast({
                title: 'Success',
                description: 'Error While Downloading CSV!',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            console.log(`ERROR WHILE DOWNLOADING ALL GENERATED PAYROLL AS CSV ${e}`);
        }
    };

    // CONFIRM DOWNLOAD FUNCTION 
    const confirmDownload = () => {
        try {
            downloadAssetsAsCSV();
            setDownloadOpen(false);
        } catch (e) {
            console.log(`ERROR WHILE GENERATING PDF ${e}`);
        }
    };

    return (
        <>
            <div className='w-full flex-1 flex flex-col p-2 lg:p-4'>
                <div className={`flex justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                    <h2 className='text-xl whitespace-nowrap font-bold mb-4'>{t("assets-&-depritiations")}</h2>
                    <div className={`flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} w-full justify-between lg:w-fit items-center gap-2 lg:gap-4`}>
                        <div className="relative">
                            <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input
                                type="text"
                                id="table-search-users"
                                className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                                placeholder={t("search-request")}
                                value={searchTerm}
                                onChange={(e) => { setSearchTerm(e.target.value) }}
                            />
                        </div>
                        {/* Download Confirmation Dialog */}
                        <ConfirmDownload
                            isOpen={downloadOpen}
                            label='download-csv'
                            setIsOpen={setDownloadOpen}
                            handleDownload={() => {
                                setDownloadOpen(true);
                            }}
                            confirmDownload={() => {
                                confirmDownload();
                            }}
                            closeDialog={() => {
                                setDownloadOpen(false);
                            }}
                            loading={loading}
                        />
                        <CreateAsset getAssets={getAllAssets} isEdit={false} />
                    </div>
                </div>
                <div className="w-full   justify-between py-4 gap-2 lg:gap-4 items-start">
                    <AssetsTable
                        page={page}
                        skip={skip}
                        limit={limit}
                        setSkip={setSkip}
                        setLimit={setLimit}
                        selectedAsset={selectedAsset}
                        setSelectedAsset={setSelectedAsset}
                        setPage={setPage}
                        columns={columns}
                        rows={allTransactions}
                        totalPages={totalPages}
                        onOpen={onOpen}
                        getAllAssets={getAllAssets}
                    />
                </div>
            </div>
            {/* Modal for viewing asset details */}
            {selectedAsset && (
                <ViewAssetDetailsModal
                    isOpen={isOpen}
                    onClose={onClose}
                    asset={selectedAsset}
                />
            )}
        </>
    )
}

export default Assets

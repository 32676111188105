import React, { useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    FormLabel,
    Button,
    Input,
  } from '@chakra-ui/react'
import ReactSelect from 'react-select';
import { get, post } from '../../../services/apis/api';  // import the post method for API call
import { useToast } from '@chakra-ui/react';  // for toast notifications

const CreateFNFRequest = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [employeeId, setEmployeeId] = useState(null);
    const [resignationDate, setResignationDate] = useState(null);
    const [lastWorkingDate, setLastWorkingDate] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    useEffect(() => {
        getAllEmployees();
    }, []);

    const getAllEmployees = async () => {
        try {
            const res = await get('employee/dropdown');
            if (res.statusCode === 200) {                
                const employeeOptions = res.data.dropdown.map(employee => ({
                    value: employee._id,
                    label: employee.name,
                }));
                setEmployees(employeeOptions);
            }
        } catch (error) {
            console.error(error);
            toast({
                title: "Error fetching employees.",
                description: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleSubmit = async () => {
        setLoading(true)
        if (!employeeId || !resignationDate || !lastWorkingDate) {
            toast({
                title: "Error",
                description: "All fields are required",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            setLoading(false)
            return;
        }

        const payload = {
            employeeId,
            resignationDate,
            lastWorkingDate,
        };

        try {
            const response = await post('request/add-fnf', payload); 
            if (response.statusCode === 201 || response.statusCode === 200) {
                toast({
                    title: "Success",
                    description: "FNF request created successfully",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                setLoading(false)
                onClose(); 
            } else {
                setLoading(false)
                toast({
                    title: "Error",
                    description: response.data.message || "Failed to create FNF request",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error(error);
            onClose(); 
            setLoading(false);
            toast({
                title: "Error",
                description: "Something went wrong.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            <Button onClick={onOpen} colorScheme='blue' m={2}>FNF Request</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create FNF Request</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl mb={4}>
                            <FormLabel>Employee</FormLabel>
                            <ReactSelect
                                options={employees}
                                value={employees.find(emp => emp.value === employeeId)}
                                onChange={(selectedOption) => setEmployeeId(selectedOption.value)}
                                placeholder="Select Employee"
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Last Working Date</FormLabel>
                            <Input
                                type="date"
                                value={lastWorkingDate}
                                onChange={(e) => setLastWorkingDate(e.target.value)}
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Resignation Date</FormLabel>
                            <Input
                                type="date"
                                value={resignationDate}
                                onChange={(e) => setResignationDate(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={onClose} variant='ghost'>
                            Close
                        </Button>
                        <Button colorScheme='blue' mx={3} onClick={handleSubmit}>
                            Process For FNF
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default CreateFNFRequest;

import { Spinner, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { get, patch } from '../../services/apis/api';
import CustomAlert from '../alerts/CustomAlert';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

const statusRoleMapping = {
    'SUPER-ADMIN-REVIEW': 'APPROVED',
};

const roleToTypeAndStatus = {
    'SUPER-ADMIN': 'SUPER-ADMIN-REVIEW',
   
};
const ViewPlayerRemoveRequest = ({requestId, setView, user, getAllRequest}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [loading, setLoading] = useState(false);
    const [requestAction, setRequestAction] = useState(false);
    const [request, setRequest] = useState({});
    const [canApprove, setCanApprove] = useState(false);
    const toast = useToast()
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const getRequestDetails = async () => {
        const res = await get(`request/get/${requestId}`);
        if (res.statusCode === 200) {
            setRequest(res.data.request);
            const userCanApprove = roleToTypeAndStatus[user?.role] === res.data.request.status;
            setCanApprove(userCanApprove);
        }
    };
    useEffect(() => {
        getRequestDetails();
    }, []);

    const handleApprove = async () => {
        const nextStatus = statusRoleMapping[request.status];
        if (!nextStatus) {
            toast({
                title: "Error",
                description: "Cannot approve this request.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        setLoading(true);
        const res = await patch(`request/update/player-remove/${requestId}`, { status: nextStatus });
        setLoading(false);
        if (res.statusCode === 200) {
            getRequestDetails();
        } else {
            toast({
                title: "Error",
                description: "Failed to approve the request.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };
    const handleReject = async()=>{
        try {
            setLoading(true);
            const res = await patch(`request/update/player-remove/${requestId}`, { status: "REJECTED", rejectedBy: user._id });
            if (res.statusCode === 200) {
                setLoading(false);
                getRequestDetails();
                onClose();
                toast({
                    title: "Success",
                    description: "Request has been rejected.",
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                onClose();
                toast({
                    title: "Error",
                    description: "Failed to approve the request.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Error",
                description: error.response.data.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }
    
    const confirmApprove = ()=>{
        handleApprove();
        onClose();
    }
    const handleApproveRejectRequest = (type)=>{
        if(type==="APPROVED"){
            setRequestAction(true);
            onOpen()
        }else{
            setRequestAction(false);
            onOpen()
        }
    }

    return (
        <div>
            <div className='w-full p-4 flex justify-between'>
                <button onClick={()=>{setView(false)}} className='bg-[#290dbd] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md flex items-center'> <IoMdArrowRoundBack /> {t('back')}</button>
                <div className='flex'>
                    {
                        canApprove ?
                        <div>
                            <button onClick={() => handleApproveRejectRequest('REJECTED')} className='bg-[#e32c35] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md me-2'>{loading ? <Spinner /> : t("reject-request")}</button> 
                            <button onClick={() => handleApproveRejectRequest('APPROVED')} className='bg-[#290dbd] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md'>{loading ? <Spinner /> : t("approve-request")}</button> 
                        </div> : ""
                    }
                </div>
            </div>
            <div className='ps-2' dir={isArabic ? 'rtl' : 'ltr'}>
                <div class="container mb-2">
                    <h2 className='font-bold text-xl text-slate-950'>{('request-details')}</h2>
                </div>
                <div>
                    <div className='flex flex-wrap mb-5'>
                        <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-start items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>{t('request-by')}: <span className='text-[#485060]'>{request?.requestedBy?.name}</span></h2>
                        </div>  
                        <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-start items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>{t('request-status')} <span className={ (request?.status == "APPROVED") ? 'text-green-600' : (request?.status === "REJECTED") ? 'text-red-600' : 'text-[#f99d4dfd]'}>{request?.status}</span></h2>
                        </div>
                        <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-start items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>{t('request-type')} <span className='text-[#485060]'>{request?.kind}</span></h2>
                        </div>
                        <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-start items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>{t('player-name')} <span className='text-[#485060]'>{request?.playerId?.name}</span></h2>
                        </div>
                        {/* <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-start items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>Experience: <span className='text-[#485060]'>{request?.experience}</span></h2>
                        </div> */}
                    </div>
                    <div className='flex flex-col items-start mb-4'>
                        <h2 className='font-bold text-md text-black font-[outfit]'>{t('reason')} </h2>
                        <p className='font-[outfit]'>{request?.reason}</p>
                    </div>
                    <div className='flex flex-col items-start mb-4'>
                        <h2 className='font-bold text-md text-black font-[outfit]'>{t('description')} </h2>
                        <p className='font-[outfit]'>{request?.description}</p>
                    </div>
                </div>
            </div>
            {
                requestAction ? 
                    <CustomAlert
                        isOpen={isOpen}
                        onClose={onClose}
                        title={t("approve-request")}
                        description={t("confirm-approve-request")}
                        onConfirm={confirmApprove}
                        confirmButtonText={t("confirm")}
                        cancelButtonText={t("cancel")}
                    /> 
                    : 
                    <CustomAlert
                        isOpen={isOpen}
                        onClose={onClose}
                        title={t("reject-request")}
                        description={t("confirm-reject-request")}
                        onConfirm={handleReject}
                        confirmButtonText={t("confirm")}
                        cancelButtonText={t("cancel")}
                    />
            }
        </div>
    )
}

export default ViewPlayerRemoveRequest
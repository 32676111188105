import React from 'react'
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    Button,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const CategoryHeader = () => {
    const navigate = useNavigate();

    return (
        <>
            <Breadcrumb>
                <BreadcrumbItem>
                    <BreadcrumbLink onClick={() => navigate('/account')} >Account</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink onClick={() => navigate('/account/earnings')} >Earnings</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink href='#'>Tournamnet Request</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            {/* <div className='w-full flex items-start justify-between py-4 lg:py-6'>
                <div className='flex flex-col items-start gap-2'>
                    <h1 className='text-xl lg:text-3xl font-bold mb-4 lg:mb-2'>Name : Winning Tournament</h1>
                    <h3 className='text-lg lg:text-xl font-semibold text-gray-600'>Total Earnings : $54,212</h3>
                    <p className='text-sm w-full lg:w-10/12 lg:text-base'>Description : Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum quod doloremque dolorum excepturi animi. Consequuntur temporibus quas repellat perspiciatis alias?</p>
                </div>
                <Button
                    variant="solid"
                    colorScheme='blue'
                >
                    Add New
                </Button>
            </div> */}
        </>
    )
}

export default CategoryHeader

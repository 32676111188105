import { Th, Table, TableContainer, Thead, Tr, Td, Tbody, Button, useDisclosure, useToast, Box, Flex, Tooltip, IconButton } from '@chakra-ui/react';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import moment from 'moment/moment';
import { truncateText } from '../../services/apis/api';
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { formatDate, formatDateArEn, formatNumber } from '../../services/glocalFunctions';

const TableComponent = ({
    rows,
    columns,
    setPage,
    limit,
    totalPages,
    page,
    setSkip,
    onOpenPayment,
    onConfirmDelete
}) => {
    const { user } = UserState();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const handleNextPage = () => {
        try {
            if (page < totalPages) {
                setPage(page + 1);
                // console.log(page * limit);
                setSkip(page * limit); // Update skip whenever page or limit changes
            }
        } catch (e) {
            console.log(`ERROR WHILE CHANGING PAGE ${e}`);
        }
    };

    const handlePrevPage = () => {
        try {
            if (page > 1) {
                setPage(page - 1);
                setSkip((page - 2) * limit);
            }
        } catch (e) {
            console.log(`ERROR WHILE CHANGING PAGE ${e}`);
        }
    };


    // TABLE COLUMNS AND COLUMNS  
    const direction = isArabic ? 'rtl' : 'ltr';
    const textAlign = isArabic ? 'right' : 'left';

    return (
        <>
            <TableContainer shadow="md" borderRadius="lg" display="flex" flexDirection="column">
                <Table dir={isArabic ? 'rtl' : "ltr"} variant="simple">
                    <Thead bg="gray.50">
                        <Tr>
                            {columns?.map((column, index) => (
                                <Th textAlign={textAlign} dir={direction} key={index}>{t(column)}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rows?.length > 0 && (
                            rows?.map((request, index) => (
                                <Tr key={request?._id} _hover={{ bg: 'gray.50' }}>
                                    <>
                                        <Td textAlign={textAlign} dir={direction}>{formatNumber(index + 1, isArabic)}</Td>
                                        {columns?.includes('Date-of-entry') &&
                                            <Td textAlign={textAlign} dir={direction}>{request?.date ? formatDateArEn(request?.date, isArabic) : ''}</Td>
                                        }
                                        {/* <Td textAlign={textAlign} dir={direction}>{request.amount?.toFixed(2) || 'N/A'}</Td> */}
                                        <Td textAlign={textAlign} dir={direction}>{formatNumber(request?.amount, isArabic) || 'N/A'}</Td>
                                        {(columns?.includes('Transection Type') || columns?.includes('transection-type')) && <Td textAlign={textAlign} dir={direction}>{t(request?.transactionType) || 'N/A'}</Td>}
                                        {columns?.includes('payment-method') &&
                                            <Td textAlign={textAlign} dir={direction}>{t(request?.paymentMethod) || 'N/A'}</Td>
                                        }
                                        {columns?.includes('payment-id') &&
                                            <Td textAlign={textAlign} dir={direction}>{request?.paymentId || 'N/A'}</Td>
                                        }
                                        {(columns?.includes('source-of-income') || columns?.includes('source')) && <Td textAlign={textAlign} dir={direction}>{request?.source || 'N/A'}</Td>}
                                        <Td textAlign={textAlign} dir={direction}>{request?.createdBy?.name || request?.requestedBy?.name || 'N/A'}</Td>
                                        {columns?.includes('created-at') &&
                                            <Td textAlign={textAlign} dir={direction}>{request?.createdAt ? formatDateArEn(request?.createdAt) : ''}</Td>
                                        }
                                        {columns?.includes('description') &&
                                            <Tooltip label={request?.description || 'N/A'}>
                                                <Td textAlign={textAlign} dir={direction}>{request?.description ? truncateText(request?.description) : 'N/A'}</Td>
                                            </Tooltip>
                                        }
                                        {columns?.includes('actions') &&
                                            <Td textAlign={textAlign} dir={direction}>
                                                <IconButton
                                                    mx={3}
                                                    onClick={() => {
                                                        if (onOpenPayment) {
                                                            onOpenPayment(request);
                                                        }
                                                    }}
                                                    aria-label="Search database">
                                                    <FaRegEdit />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => {
                                                        if (onConfirmDelete) {
                                                            onConfirmDelete(request);
                                                        }
                                                    }}
                                                    aria-label="Search database">
                                                    <MdDeleteOutline />
                                                </IconButton>
                                            </Td>
                                        }
                                    </>
                                </Tr>
                            ))
                        )}
                    </Tbody>
                </Table>
                {rows ?
                    rows?.length <= 0 &&
                    <div className='w-full  min-h-80 flex items-center justify-center'>
                        <h1 className='text-center'>{t('no-data-found')}</h1>
                    </div>
                    :
                    <div className='w-full  min-h-80 flex items-center justify-center'>
                        <h1 className={`text-center `}>
                            {isArabic && '....'} {t('loading')} {!isArabic && '....'}
                        </h1>

                    </div>
                }
            </TableContainer>
            {/* Pagination Controls */}
            {rows?.length > 0 &&
                <Flex justify="space-between" align="center" mt={4}>
                    <Button onClick={handlePrevPage} isDisabled={page === 1}>
                        {t('previous')}
                    </Button>
                    {/* <Box>
                        Page {page} of {totalPages}
                    </Box> */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isArabic ? 'row-reverse' : 'row',
                            gap: 1
                        }}
                    >
                        <h1>{t('page')}</h1>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isArabic ? 'row-reverse' : 'row',
                                gap: 1
                            }}
                        >
                            {page}

                            <h1>{t('of')}</h1>
                            {totalPages}
                        </ Box>
                    </Box>
                    <Button onClick={handleNextPage} isDisabled={page === totalPages}>
                        {t('next')}
                    </Button>
                </Flex>
            }
        </>
    );
};

export default TableComponent
import React, { useEffect, useState } from 'react'
import { UserState } from '../../context/user';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DownloadIcon, HamburgerIcon } from '@chakra-ui/icons';
import DateRangeSelect from '../../components/account/DateRangeSelect';
import { Tooltip, useToast } from '@chakra-ui/react';
import { Divider, IconButton, Input, Menu, MenuButton, MenuList, Select } from '@chakra-ui/react';
import { get } from '../../services/apis/api';
import TableComponent from './TableComponent';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import { formatDate } from '../../services/glocalFunctions';
import ConfirmDownload from '../dialogueBoxes/ConfirmDownload';



const PastTransactions = ({ txnType }) => {
    const { user } = UserState();
    const toast = useToast();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [searchTerm, setSearchTerm] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0); // Initial skip should be 0
    const [limit, setLimit] = useState(10);
    const [allTransactions, setAllTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [downloadOpen, setDownloadOpen] = useState(false);  // for confirmation dialog
    const [date, setDate] = useState({
        startDate: '',
        endDate: ''
    });

    // TABLE COLUMNS 
    const columns = [
        's-no',
        'Date-of-entry',
        'amount',
        // 'transection-type',
        'payment-method',
        'payment-id',
        'created-by',
        'description',
        // 'Action' //QUETIONS IT SHOLD GIVE HERE OR NOT 
    ];

    // FETCH ALL TRRANSECTIONS BY API'S 
    const getAllTransactions = async (skip, limit, searchTerm, startDate, endDate) => {
        try {
            if (user) {
                const res = await get(`expenses/getAllTransactions?skip=${skip}&limit=${limit}&search=${searchTerm}&startDate=${startDate}&endDate=${endDate}&transactionType=${txnType}`);
                setAllTransactions(res?.data?.data);
                const totalCount = res?.data?.totalCount || 0; // Ensure totalCount is defined
                setTotalPages(Math.ceil(totalCount / limit)); // Calculate total pages
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAllTransactions(skip, limit, searchTerm, date?.startDate, date?.endDate);
    }, [page, limit]);

    // Use useCallback to memoize the function so it doesn't recreate on every render
    const debouncedGetAllRequest = useCallback(
        debounce((...args) => {
            getAllTransactions(...args);
        }, 300), // Adjust delay as needed
        []
    );

    useEffect(() => {
        // Debounced API call
        debouncedGetAllRequest(0, 10, searchTerm, date?.startDate, date?.endDate);

        // Cleanup function to cancel the debounce on component unmount
        return () => {
            debouncedGetAllRequest.cancel();
        };
    }, [searchTerm, debouncedGetAllRequest]);

    useEffect(() => {
        if (date.startDate && date.endDate) {
            getAllTransactions(skip, limit, searchTerm, date?.startDate, date?.endDate);
        }
    }, [date]);

    const Heading = ({ title }) => (
        <h2 className="text-lg text-gray-600 mb-2 lg:text-xl font-bold">{t(title)}</h2>
    )

    // EXPORT HR EXPENSE AS CSV 
    const downloadExpenseCSV = async (searchTerm, startDate, endDate) => {
        setLoading(true);
        try {

            // Calculate start and end dates if not provided
            if (!startDate && !endDate) {
                const today = new Date();
                startDate = new Date(today.getFullYear(), 0, 1); // Set startDate to January 1st of the current year
                endDate = new Date();
            }

            const queryParams = new URLSearchParams();
            if (searchTerm) queryParams.append('search', searchTerm);
            if (startDate) queryParams.append('startDate', startDate);
            if (endDate) queryParams.append('endDate', endDate);
            if (txnType) queryParams.append('transactionType', txnType);
            const requestUrl = `expenses/getAllTransactions${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;
            // Make the GET request
            const res = await get(requestUrl);

            // Make the GET request with the query parameters
            setLoading(false);
            if (res?.statusCode == 200) {
                const transactionsData = res?.data?.data;

                //filename
                const filename = `transaction's.csv`;

                // Check if there are payslips to export
                if (!transactionsData || transactionsData?.length === 0) {
                    setLoading(false);
                    toast({
                        title: 'Info',
                        description: 'No data to export!',
                        status: 'info',
                        duration: 5000,
                        isClosable: true,
                    });
                    return;
                }

                // Generate headers from the first payslip object keys
                const headers = [
                    "S NO.",
                    "Date Of Entry",
                    "Amount",
                    "Payment Method",
                    "Payment Type",
                    "Payment ID",
                    "Created By",
                    "Description"
                ];

                // Convert payslip data into CSV rows
                const rows = transactionsData?.map((txn, index) => {
                    return [
                        index + 1,
                        txn?.date ? formatDate(txn?.date) : "N/A",
                        txn?.amount ?? 0,
                        txn?.paymentMethod || "N/A",
                        txn?.paymentType || "N/A",
                        txn?.paymentId || "N/A",
                        txn?.createdBy?.name || txn?.requestedBy?.name || 'N/A',
                        txn?.description || "N/A"
                    ]?.join(",");
                });

                // Combine headers and rows
                const csvContent = [headers?.join(","), ...rows]?.join("\n");

                // Create Blob and trigger download
                const blob = new Blob([csvContent], { type: "text/csv" });
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                setLoading(false);
                toast({
                    title: 'Success',
                    description: 'CSV Downloaded Successfully!',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (e) {
            console.log(e, 'ERROR IN DOWNLOAD PERFORMANCE CSV!');
            setLoading(false);
            toast({
                title: 'Success',
                description: 'Error While Downloading CSV!',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            console.log(`ERROR WHILE DOWNLOADING ALL PERFORMANCE AS CSV ${e}`);
        }
    };

    // CONFIRM DOWNLOAD FUNCTION 
    const confirmDownload = () => {
        try {
            downloadExpenseCSV();
            setDownloadOpen(false);
        } catch (e) {
            console.log(`ERROR WHILE GENERATING PDF ${e}`);
        }
    };

    return (
        <>
            <div className="w-full flex flex-col items-start gap-3 flex-1 min-h-[50vh]">
                <div className={`flex w-full justify-between px-0 lg:px-2 mb-4 gap-2 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                    <Heading
                        title={"past-transections"}
                    />
                    <div className={`flex ${isArabic ? 'flex-col-reverse lg:flex-row-reverse items-end justify-start' : 'flex-col lg:flex-row items-start'} gap-2 w-full justify-between lg:w-fit `}>
                        <div className="relative mr-3">
                            <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input
                                type="text"
                                id="table-search-users"
                                className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                                placeholder={t("search-request")}
                                value={searchTerm}
                                onChange={(e) => { setSearchTerm(e.target.value) }}
                            />
                        </div>
                        <DateRangeSelect
                            date={date}
                            setDate={setDate}
                            resetDates={async () => {
                                await setDate({ startDate: '', endDate: '' })
                                getAllTransactions(skip, limit, searchTerm, '', '');
                            }}
                            getData={() => {
                                getAllTransactions(skip, limit, searchTerm, date?.startDate, date?.endDate);
                            }}
                        />
                        {/* Download Confirmation Dialog */}
                        <ConfirmDownload
                            isOpen={downloadOpen}
                            label='download-report'
                            setIsOpen={setDownloadOpen}
                            handleDownload={() => {
                                setDownloadOpen(true);
                            }}
                            isIcon={true}
                            confirmDownload={() => {
                                confirmDownload();
                            }}
                            closeDialog={() => {
                                setDownloadOpen(false);
                            }}
                            loading={loading}
                        />
                    </div>
                </div>
                <div className='w-full flex-1 flex flex-col '>
                    <TableComponent
                        page={page}
                        skip={skip}
                        limit={limit}
                        setSkip={setSkip}
                        setLimit={setLimit}
                        setPage={setPage}
                        columns={columns}
                        rows={allTransactions}
                        totalPages={totalPages}
                    />
                </div>
            </div>
        </>
    )
}

export default PastTransactions

import React, { useEffect, useState } from 'react'
import { formatDateTime } from '../../utills/formateDate';
import { CloseButton } from '@chakra-ui/react';
import AssignCoachToTeam from '../dialogueBoxes/AssignCoachToTeam';
import { get } from '../../services/apis/api';
import ChangeTeamPlayer from '../dialogueBoxes/ChangeTeamPlayer';

const ViewTeam = ({teamId, setViewTeam}) => {
  const [team, setTeam] = useState();

  const getTeamDetails = async()=>{
    try {
      const res = await get(`team/get/${teamId}`);
      if(res.statusCode===200){
        setTeam(res.data.team)
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getTeamDetails();
  }, [])
  
  return (
    <div>
        <div className='w-full p-4 flex justify-between'>
          <button onClick={()=>{setViewTeam(false)}} className='bg-[#290dbd] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md'>Back</button>
          <div className='flex'>
            <AssignCoachToTeam teamId={teamId} name={team?.name} gameId={team?.gameId?._id} getTeamDetails={getTeamDetails}  />
            <ChangeTeamPlayer teamId={teamId} getTeamDetails={getTeamDetails}/>
          </div>
        </div>

        <div class="">
          <div class="container mb-2">
            <h2 className='font-bold text-xl text-slate-950 ps-2'>Team Detail</h2>
          </div>
          <div>
            <div className='flex flex-wrap mb-5'>
              <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-center items-center mx-2'>
                <h2 className='font-bold text-md text-black'>Team Name: <span className='text-[#485060]'>{team?.name}</span></h2>
              </div>  
              <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-center items-center mx-2'>
                <h2 className='font-bold text-md text-black'>Coach Name: <span className='text-[#485060]'>{team?.coachId?.name}</span></h2>
              </div>
              <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-center items-center mx-2'>
                <h2 className='font-bold text-md text-black'>Game: <span className='text-[#485060]'>{team?.gameId?.name}</span></h2>
              </div>
            </div>

            <div class="relative overflow-x-auto px-2">
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-6 py-3">
                          S.No
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Player Name
                      </th>
                      <th scope="col" class="px-6 py-3">
                        City
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                       team && team.players.map((player, index)=>(
                          <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <td class="w-4 p-4">
                              <div class="flex items-center">
                              {index+1}
                              </div>
                          </td>
                          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {player.playerId?.name}
                          </th>
                          <td class="px-6 py-4">
                                {player.playerId?.city}
                          </td>
                          </tr>  
                      ))
                    }
                  </tbody>
              </table>
            </div>
          </div>
        </div>
      {/* <p class="mb-3 text-gray-500 dark:text-gray-400">Track work across the enterprise through an open, collaborative platform. Link issues across Jira and ingest data from other software development tools, so your IT support and operations teams have richer contextual information to rapidly respond to requests, incidents, and changes.</p> */}
    </div>
  )
}

export default ViewTeam



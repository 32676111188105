import React from 'react'
import { FaUsers, FaChartLine, FaStar, FaDollarSign, FaClipboardList, FaWallet } from 'react-icons/fa';


const MetrixData = ({data}) => {
    const metrics = [
        {
          title: 'Total Employees',
          value: data?.employee?.totalCount,
          icon: <FaUsers className="text-blue-500" />,
          color: 'bg-blue-100',
          description: `+${((data?.employee?.totalLastMonthCount/data?.employee?.totalCount)*100)?.toFixed(1)}% from last month`,
          textColor: 'text-blue-700',
        },
        {
          title: 'Monthly Attendance Rate',
          value: `${data?.attendance?.averagePercentage}%`,
          icon: <FaChartLine className="text-green-500" />,
          color: 'bg-green-100',
          description: 'Average across departments',
          textColor: 'text-green-700',
        },
        {
          title: 'Avg. Performance Score',
          value: `${data?.performance?.averagePercentage}%`,
          icon: <FaStar className="text-yellow-500" />,
          color: 'bg-yellow-100',
          description: 'Overall rating',
          textColor: 'text-yellow-700',
        },
        {
          title: 'Payroll Expenditure',
          value: `$${data?.payroll?.expenditure}`,
          icon: <FaDollarSign className="text-purple-500" />,
          color: 'bg-purple-100',
          description: 'For this month',
          textColor: 'text-purple-700',
        },
        {
          title: 'Pending FNF Cases',
          value: `${data?.fnf?.requests}`,
          icon: <FaClipboardList className="text-red-500" />,
          color: 'bg-red-100',
          description: 'Awaiting approval',
          textColor: 'text-red-700',
        },
        {
          title: 'HR Expenses This Month',
          value: `$${data?.hr?.expenditure}`,
          icon: <FaWallet className="text-indigo-500" />,
          color: 'bg-indigo-100',
          description: 'This month\'s spending',
          textColor: 'text-indigo-700',
        },
    ];

    return (
        <div className="p-6">
            
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-6 ">
                {metrics.map((metric, index) => (
                    <div key={index} className={`flex items-center p-4 rounded-lg shadow-md ${metric.color}`}>
                    <div className="p-3 rounded-full bg-white">
                        {metric.icon}
                    </div>
                    <div className="ml-4">
                        <h3 className={`text-lg font-semibold ${metric.textColor}`}>{metric.title}</h3>
                        <p className="text-2xl font-bold">{metric.value}</p>
                        <p className="text-sm text-gray-600">{metric.description}</p>
                    </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default MetrixData

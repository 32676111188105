import axios from "axios"
import { getToken } from "./token";
import moment from "moment/moment";
import 'moment/locale/ar'; // Import Arabic locale for moment.js

//server urls--->
// export const baseUrl=`http://192.168.142.66:8000/api/v1/`    
// export const baseUrl = `http://localhost:8000/api/v1/`
export const baseUrl = `https://alibtisam.club/api/v1/`
// export const baseUrl=`https://alibtisam-backend.onrender.com/api/v1/`
export const socketBaseUrl = `https://alibtisam.club/`

//client urls--->
export const baseUrlClient = `http://localhost:3000/`
// export const baseUrl=`http://15.206.185.144:3100/api/`
// export const baseUrlClient=`http://localhost:3000/`


export const post = async (url, data) => {
    try {
        const res = await axios.post(baseUrl + url, data, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
            }
        })
        return { statusCode: res.status, data: res.data };
    }
    catch (error) {
        // console.log(error);
        throw error;
    }
}

export const postFormData = async (url, data) => {
    try {
        const res = await axios.post(baseUrl + url, data,
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
        return { statusCode: res.status, data: res.data };
    }
    catch (error) {
        // console.log(error)
        throw error
    }
}

export const patchFormData = async (url, data) => {
    try {
        const res = await axios.patch(baseUrl + url, data,
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
        return { statusCode: res.status, data: res.data };
    }
    catch (error) {
        // console.log(error)
        throw error
    }
}

export const patch = async (url, data) => {
    try {
        const res = await axios.patch(baseUrl + url, data, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
            }
        })
        return { statusCode: res.status, data: res.data };
    }
    catch (error) {
        // console.log(error)
        throw error;
    }
}

export const get = async (url) => {
    try {
        const res = await axios.get(baseUrl + url, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
            }
        });
        return { statusCode: res.status, data: res.data }
    }
    catch (error) {
        // console.log(error)
        throw error;
    }
}

export const deletethis = async (url) => {
    try {
        const res = await axios.delete(baseUrl + url, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
            }
        });
        return { statusCode: res.status, data: res.data }
    }
    catch (error) {
        // console.log(error)
        throw (error)
    }
}

export const truncateText = (text, maxLength = 30) => {
    return text?.length > maxLength ? text.slice(0, maxLength) + '...' : text;
}


// CONVET THE FILE IN THE FORMAT 
export function createAttachment(url) {
    try {
        // Extract file name
        const fileName = url.split('/').pop(); // Gets the file name from the URL
        const fileExtension = fileName.split('.').pop(); // Extracts the file extension

        // Determine MIME type based on file extension
        let fileType = 'application/octet-stream'; // Default type for unknown files

        if (fileExtension === 'pdf') {
            fileType = 'application/pdf';
        } else if (fileExtension === 'jpg' || fileExtension === 'jpeg') {
            fileType = 'image/jpeg';
        } else if (fileExtension === 'png') {
            fileType = 'image/png';
        } else if (fileExtension === 'txt') {
            fileType = 'text/plain';
        } // Add more extensions as necessary

        // Return the attachment object
        return {
            name: fileName,
            url: url,
            type: fileType,
        };
    } catch (e) {
        console.log(`ERROR WHILE FORMATING THE FILE ${e}`);
    }
}


import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    useDisclosure,
    useToast,
    Spinner,
    InputLeftElement,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { patch } from '../../../services/apis/api';
import { useTranslation } from 'react-i18next';

function UpdatePassword({ userId }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const handleUpdatePassword = async () => {
        if (password !== confirmPassword) {
            toast({
                title: "Error",
                description: "Passwords do not match.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            setLoading(true);
            const res = await patch('user/update-password', { userId, newPassword: password });

            if (res.statusCode === 200) {
                setLoading(false);
                toast({
                    title: "Success",
                    description: "Password updated successfully.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                onClose();
            } else {
                setLoading(false);
                toast({
                    title: "Error",
                    description: res.message || "An error occurred while updating the password.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: "Error",
                description: error.response?.data?.message || "Error updating password.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            {/* Button to trigger the modal */}
            <Button onClick={onOpen}>{t("update-password")}</Button>

            {/* Modal for updating password */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent dir={isArabic ? "rtl" : "ltr"}> {/* Change direction based on language */}
                    <ModalHeader >
                        {t("update-password")}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl id="password" isRequired>
                            <FormLabel textAlign={isArabic ? "right" : "left"}>{t("new-password")}</FormLabel>
                            <InputGroup>
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    autocomplete="new-password"
                                    placeholder='****'
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                {
                                    isArabic ?
                                        <InputLeftElement h="full">
                                            <Button
                                                variant="ghost"
                                                onClick={() => setShowPassword((show) => !show)}
                                            >
                                                {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                            </Button>
                                        </InputLeftElement> :
                                        <InputRightElement h="full">
                                            <Button
                                                variant="ghost"
                                                onClick={() => setShowPassword((show) => !show)}
                                            >
                                                {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                            </Button>
                                        </InputRightElement>
                                }
                            </InputGroup>
                        </FormControl>

                        <FormControl id="confirm-password" mt={4} isRequired>
                            <FormLabel textAlign={isArabic ? "right" : "left"}>{t("confirm-new-password")}</FormLabel>
                            <InputGroup>
                                <Input
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    value={confirmPassword}
                                    autocomplete="new-password"
                                    placeholder='****'
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                {
                                    isArabic ?
                                        <InputLeftElement h="full">
                                            <Button
                                                variant="ghost"
                                                onClick={() => setShowConfirmPassword((show) => !show)}
                                            >
                                                {showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />}
                                            </Button>
                                        </InputLeftElement> :
                                        <InputRightElement h="full">
                                            <Button
                                                variant="ghost"
                                                onClick={() => setShowConfirmPassword((show) => !show)}
                                            >
                                                {showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />}
                                            </Button>
                                        </InputRightElement>
                                }
                            </InputGroup>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" mx={3} onClick={onClose}>
                            {t("cancel")}
                        </Button>
                        <Button colorScheme="blue" onClick={handleUpdatePassword}>
                            {loading ? <Spinner /> : t("update-password")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default UpdatePassword;

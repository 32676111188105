import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  useToast,
  Spinner,
  Switch,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { patch, post } from "../../services/apis/api";

const CreateAgeCategory = ({ getAgeCategories, category, isEdit }) => {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [formData, setFormData] = useState({
        name: isEdit ? category.name : '',
        minAge: isEdit ? category.minAge : '',
        maxAge: isEdit ? category.maxAge : '',
        description: isEdit ? category.description : '',
        isActive: isEdit ? category.isActive : true,
    });
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSave = async (id) => {
        try {
            setLoading(true);
            let res;
            if (isEdit) {
                res = await patch(`ageCategories/update/${id}`, formData);
            } else {
                res = await post('ageCategories/add', formData);
            }
            console.log(res.data);
            if (res.statusCode === 201 || res.statusCode === 200) {
                setLoading(false);
                getAgeCategories();
                onClose();
                toast({
                    title: 'Success!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                setFormData({
                    name: '',
                    minAge: '',
                    maxAge: '',
                    description: '',
                    isActive: true,
                });
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: 'Error!',
                description: error.response.data.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            <Button colorScheme="blue" onClick={onOpen}>{isEdit ? "Edit" : "Create Age Category"}</Button>
            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>{isEdit ? "Edit Age Category" : "Create New Age Category"}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <div>
                        <div className="mb-3">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="name">Category Name</label>
                            <input
                                value={formData.name}
                                onChange={handleInputChange}
                                type="text"
                                name="name"
                                id="name"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="Category name"
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="minAge">Minimum Age</label>
                            <input
                                value={formData.minAge}
                                onChange={handleInputChange}
                                type="number"
                                name="minAge"
                                id="minAge"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="Minimum age"
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="maxAge">Maximum Age</label>
                            <input
                                value={formData.maxAge}
                                onChange={handleInputChange}
                                type="number"
                                name="maxAge"
                                id="maxAge"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="Maximum age"
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="description">Description</label>
                            <textarea
                                value={formData.description}
                                onChange={handleInputChange}
                                name="description"
                                id="description"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="Category description"
                                required
                            />
                        </div>
                        <FormControl display="flex" alignItems="center" mb="3">
                            <FormLabel htmlFor="isActive" mb="0">
                                Active?
                            </FormLabel>
                            <Switch
                                id="isActive"
                                name="isActive"
                                isChecked={formData.isActive}
                                onChange={handleInputChange}
                                colorScheme="blue"
                            />
                        </FormControl>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose} mr={3}>Cancel</Button>
                    <Button onClick={() => handleSave(isEdit ? category?._id : "")} colorScheme="blue">
                        {loading ? <Spinner /> : "Save"}
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateAgeCategory;

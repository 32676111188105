import React, { useEffect, useState } from 'react';
import { get } from '../../services/apis/api';
import CreateWarehouse from '../../components/dialogueBoxes/CreateWarehouse';
import ViewWarehouse from '../../components/inventory/ViewWarehouse';

const Warehouse = () => {
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  const getAllWarehouses = async (search = '') => {
    try {
      const res = await get(`warehouse/all?search=${search}`);
      console.log('warehouse', res.data.data);
      setWarehouses(res.data.data);
      setError(''); // Clear error if successful
    } catch (err) {
      setError('Failed to fetch warehouses');
      console.error(err);
    }
  };

  useEffect(() => {
    getAllWarehouses();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    getAllWarehouses(e.target.value);
  };

  return (
    <div>
      {show ? (
        <ViewWarehouse warehouse={selectedWarehouse} setShow={setShow} />
      ) : (
        <div>
          <div className='flex justify-between px-2 items-center mb-4'>
            <div>
              <div className='relative'>
                <div className='absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none'>
                  <svg
                    className='w-4 h-4 text-gray-500'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 20 20'
                  >
                    <path
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                    />
                  </svg>
                </div>
                <input
                  type='text'
                  id='table-search-warehouses'
                  className='block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500'
                  placeholder='Search for warehouse'
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div>
              <CreateWarehouse getAllWarehouses={getAllWarehouses} />
            </div>
          </div>
          <div>
            <div className='relative overflow-x-auto shadow-md sm:rounded-lg'>
              <table className='w-full text-sm text-left rtl:text-right text-gray-500'>
                <thead className='text-xs text-gray-700 uppercase bg-gray-50'>
                  <tr>
                    <th scope='col' className='p-4'>
                      <div className='flex items-center'>S.No.</div>
                    </th>
                    <th scope='col' className='px-6 py-3'>Warehouse</th>
                    <th scope='col' className='px-6 py-3'>Type</th>
                    <th scope='col' className='px-6 py-3'>Location</th>
                    <th scope='col' className='px-6 py-3'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {warehouses.map((warehouse, index) => (
                    <tr
                      className='bg-white border-b hover:bg-gray-50 cursor-pointer'
                      key={warehouse._id}
                      onClick={() => {
                        setSelectedWarehouse(warehouse); 
                        setShow(true);
                      }}
                    >
                      <td className='px-6 py-4'>
                        <div className='flex items-center'>{index + 1}</div>
                      </td>
                      <th scope='row' className='flex items-center px-6 py-4 text-gray-900 whitespace-nowrap'>
                        <div className='text-base font-semibold'>{warehouse.name}</div>
                      </th>
                      <td className='px-6 py-4'>{warehouse.type}</td>
                      <td className='px-6 py-4'>{warehouse.location}</td>
                      <td className='px-6 py-4'>
                        <div className='flex items-center'>
                          <CreateWarehouse 
                            getAllWarehouses={getAllWarehouses} 
                            warehouse={warehouse} 
                            isEdit={true} 
                            onClick={(e) => e.stopPropagation()} // Stop event propagation
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {error && (
            <div className='flex justify-center items-center h-[60vh]'>
              <div className='text-red-500 text-center'>
                {error}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Warehouse;

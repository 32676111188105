import React, { useEffect, useState } from 'react'
import { get, post } from '../../services/apis/api';
import { useLocation } from 'react-router-dom';
import { UserState } from '../../context/user';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, useDisclosure, useToast } from '@chakra-ui/react';
import ScheduleAppointmentModal from '../../components/dialogueBoxes/ScheduleAppoinment';
import ConfirmCloseRequestBox from '../../components/alerts/ConfirmCloseRequest';
import RequestPayment from '../../components/dialogueBoxes/RequestPayment';

const _scheduleFormData = {
    appointmentDate: '',
    feedback: '',
    recoveryStatus: ''
}

const RequestDetails = () => {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const toast = useToast();
    const isArabic = i18n.language === 'ar';
    const { isOpen, onOpen, onClose } = useDisclosure()
    const location = useLocation();
    const requestId = location?.pathname?.split('/')[3];
    const [request, setRequest] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [scheduleFormData, setScheduleFormData] = useState({ ..._scheduleFormData });

    const DetailField = ({ label, value }) => {
        return (
            <h2 className='font-bold text-md text-black'>
                {t(label)}: <span className='text-[#485060]'>{value}</span>
            </h2>
        );
    };

    // SCHEDULE APPOINTMENT
    const scheduleAppointment = async () => {
        setIsLoading(true);
        try {
            let res
            if (request?.status === 'OPEN') {
                const _payload = {
                    appointmentDate: scheduleFormData?.appointmentDate,
                }
                res = await post(`clinic/schedule/${requestId}/assign`, _payload);
            } else {
                const _payload = {
                    ...scheduleFormData,
                }
                res = await post(`clinic/feedback/${requestId}`, _payload);
            }
            setIsLoading(false);
            if (res.statusCode === 200) {
                getRequestById();
                onClose();
                toast({
                    title: "Request scheduled successfully.",
                    status: "success",
                    duration: 5000, // 5 seconds    
                    isClosable: true,
                });
            }
        } catch (error) {
            setIsLoading(false);
            toast({
                title: "Request scheduling failed.",
                status: "error",
                duration: 5000, // 5 seconds    
                isClosable: true,
            });
        }
    };

    // GET REQUEST DETAILS  
    const getRequestById = async () => {
        try {
            let res;
            if (user) {
                const res = await get(`clinic/request/${requestId}`)
                if (res.statusCode === 200) {
                    setRequest(res?.data?.appointment || {});
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getRequestById();
    }, [])

    return (
        <>
            <div className='w-full flex-1 flex flex-col'>
                <div className={`flex     justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                    <h2 className='text-xl whitespace-nowrap font-bold mb-4'>
                        {location?.pathname?.includes('appointment') ?
                            t('appointment-details') : t('request-details')
                        }
                    </h2>
                    < div>
                        {location?.pathname?.includes('appointment') && request?.status !== 'CLOSED' ?
                            <ConfirmCloseRequestBox
                                request={request}
                                fetchData={getRequestById}
                            />
                            : null}
                        {request?.userAppointmentType === 'EXTERNAL' ? (
                            request?.paymentStatus?.toLowerCase() === 'completed' ? (
                                <Button
                                    colorScheme="blue"
                                    onClick={() => {
                                        onOpen();
                                    }}
                                >
                                    {t('schedule-appointment')}
                                </Button>
                            ) : (
                                <RequestPayment
                                    request={request}
                                    fetchData={getRequestById}
                                />
                            )
                        ) : (
                            <Button
                                colorScheme="blue"
                                onClick={() => {
                                    onOpen();
                                }}
                            >
                                {t('schedule-appointment')}
                            </Button>
                        )}

                    </div>

                    <ScheduleAppointmentModal
                        isOpen={isOpen}
                        onClose={onClose}
                        scheduleFormData={scheduleFormData}
                        onHandleChange={(field, value) => {
                            setScheduleFormData((prevData) => ({
                                ...prevData,
                                [field]: value
                            }));
                        }}
                        isLoading={isLoading}
                        onHandleSubmit={scheduleAppointment}
                        request={request}
                    />



                </div>
                <div className='w-full flex gap-2  lg:gap-8 flex-1'>
                    <div className='flex gap-2 lg:gap-8 flex-1 '>
                        <div className='w-44 h-44 overflow-hidden rounded-md'>
                            <img className='w-full h-full object-cover hover:scale-110 transition-all duration-300 ' src={request?.userId?.pic || ''} alt="" />
                        </div>
                        <div className='flex items-start gap-1 flex-col'>
                            <DetailField
                                label="name"
                                value={request?.userId?.name || ''}
                            />
                            <DetailField
                                label="gender"
                                value={request?.userId?.gender || ''}
                            />
                            <DetailField
                                label="mobile"
                                value={request?.userId?.mobile || ''}
                            />
                            <DetailField
                                label="email"
                                value={request?.userId?.email || ''}
                            />
                            <DetailField
                                label="request-type"
                                value={request?.userAppointmentType || ''}
                            />
                            <DetailField
                                label="request-date"
                                value={request?.createdAt ? moment(request.createdAt).format('DD-MM-YYYY') : ''}
                            />
                            <DetailField
                                label="request-status"
                                value={request?.status || ''}
                            />
                            <DetailField
                                label="request-description"
                                value={request?.injuryDescription || ''}
                            />
                            {request?.clinicAppointments?.length > 0 && (
                                request?.clinicAppointments?.map((appointment, index) => (
                                    <>
                                        <DetailField
                                            label="appointment-date"
                                            value={appointment?.appointmentDate ? moment(appointment.appointmentDate).format('DD-MM-YYYY') : '--'}

                                        />
                                        {appointment.feedback &&
                                            <DetailField
                                                label="feedback"
                                                value={appointment.feedback}
                                            />}
                                    </>


                                ))
                            )}
                        </div>
                    </div>
                    <div className='w-1/4 overflow-hidden rounded-md'>
                        <img className='w-full h-full object-cover' src={request?.injuryBodyImage || ''} alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default RequestDetails


import React, { useEffect, useState } from 'react';
import { get } from '../../services/apis/api';
import CreateCollection from '../../components/dialogueBoxes/CreateCollection';
import { useTranslation } from 'react-i18next';

const Collection = () => {
    const [collections, setCollections] = useState([]);
    const [games, setGames] = useState([]);
    const [selectedGame, setSelectedGame] = useState('');
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const getAllCollections = async (gameId = '') => {
        const res = await get(`collection/get${gameId ? `?gameId=${gameId}` : ''}`);
        if (res.statusCode === 200) {
            setCollections(res.data.collection);
        }
    };

    const getAllGames = async () => {
        const res = await get('game/dropdown');
        if (res.statusCode === 200) {
            setGames(res.data.dropdown);
        }
    };

    useEffect(() => {
        getAllCollections();
        getAllGames();
    }, []);

    const handleGameChange = (e) => {
        const gameId = e.target.value;
        setSelectedGame(gameId);
        getAllCollections(gameId);
    };

    const isVideo = (media) => {
        const videoExtensions = ['mp4', 'webm', 'ogg'];
        const extension = media.split('.').pop();
        return videoExtensions.includes(extension);
    };

    return (
        <>
            <div>
                <div className='flex justify-between px-2 items-center mb-4'>
                    <div>
                        <select
                            value={selectedGame}
                            onChange={handleGameChange}
                            className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                        >
                            <option value=''>{t('All Games')}</option>
                            {games.map(game => (
                                <option key={game._id} value={game._id}>

                                    {
                                        isArabic ? game.arabicName :
                                            game.name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <CreateCollection getAllCollections={getAllCollections} />
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {collections.map((collection) => (
                        <div key={collection._id} className="border border-gray-300 rounded-lg overflow-hidden shadow-lg">
                            <div className="h-48 w-full overflow-hidden">
                                {isVideo(collection.media) ? (
                                    <video src={collection.media} controls className="w-full h-full object-cover"></video>
                                ) : (
                                    <img src={collection.media} alt={`Collection item ${collection._id}`} className="w-full h-full object-cover" />
                                )}
                            </div>
                            <div className="p-4 text-center">
                                <p className="text-sm text-gray-500">{t('game')}: {
                                    isArabic ?
                                        collection?.gameId?.arabicName :
                                        collection?.gameId?.name}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Collection;

import React from 'react'
import BarChart from '../../components/charts/BarChart'
import { FiEye } from "react-icons/fi";
import { BsArrowUpShort } from "react-icons/bs";
import DonutChart from '../../components/charts/DonutChart';


const MediaHome = () => {
  return (
    <div className='w-full mt-5 px-4'>
      <div className='flex flex-wrap w-full justify-between'>
        <div className='bg-[#f1f5f9] w-full sm:w-[48%] lg:w-[24%] py-4 px-8 rounded-md mb-4'>
          <div className='flex justify-between mb-2'>
            <FiEye className='h-10 w-10 p-2 bg-white rounded-full' />
            <h2 className='font-bold text-black text-2xl'>$ 147.56</h2>
          </div>
          <div className='w-full flex justify-between px-2'>
            <h2>Total Views</h2>
            <h2 className='flex items-center text-green-700'>0.15% <BsArrowUpShort /></h2>
          </div>
        </div>
        <div className='bg-[#f1f5f9] w-full sm:w-[48%] lg:w-[24%] py-4 px-8 rounded-md mb-4'>
          <div className='flex justify-between mb-2'>
            <FiEye className='h-10 w-10 p-2 bg-white rounded-full' />
            <h2 className='font-bold text-black text-2xl'>$ 147.56</h2>
          </div>
          <div className='w-full flex justify-between px-2'>
            <h2>Total Views</h2>
            <h2 className='flex items-center text-green-700'>0.15% <BsArrowUpShort /></h2>
          </div>
        </div>
        <div className='bg-[#f1f5f9] w-full sm:w-[48%] lg:w-[24%] py-4 px-8 rounded-md mb-4'>
          <div className='flex justify-between mb-2'>
            <FiEye className='h-10 w-10 p-2 bg-white rounded-full' />
            <h2 className='font-bold text-black text-2xl'>$ 147.56</h2>
          </div>
          <div className='w-full flex justify-between px-2'>
            <h2>Total Views</h2>
            <h2 className='flex items-center text-green-700'>0.15% <BsArrowUpShort /></h2>
          </div>
        </div>
        <div className='bg-[#f1f5f9] w-full sm:w-[48%] lg:w-[24%] py-4 px-8 rounded-md mb-4'>
          <div className='flex justify-between mb-2'>
            <FiEye className='h-10 w-10 p-2 bg-white rounded-full' />
            <h2 className='font-bold text-black text-2xl'>$ 147.56</h2>
          </div>
          <div className='w-full flex justify-between px-2'>
            <h2>Total Views</h2>
            <h2 className='flex items-center text-green-700'>0.15% <BsArrowUpShort /></h2>
          </div>
        </div>
      </div>
      <div className='mt-9 flex flex-wrap justify-between'>
        <div className='bg-[#f1f5f9] flex justify-center items-center p-4 w-full h-[60vh] lg:w-[60%] rounded-md shadow-md mb-4 lg:mb-0'>
          <BarChart />
        </div>
        <div className='bg-[#f1f5f9] flex justify-center items-center p-2 w-full lg:w-[37%] rounded-md shadow-md'>
          <DonutChart />
        </div>
      </div>
    </div>
  )
}

export default MediaHome

import { Select } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ChangeLanguage = () => {
    const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // Set initial language

    const changeLanguage = (lng) => {
        setSelectedLanguage(lng); // Update state when language changes
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        setSelectedLanguage(i18n.language);
    }, [i18n.language]);
    return (
        <div>
            <Select
                placeholder='Select Language'
                value={selectedLanguage}
                onChange={(e) => changeLanguage(e.target.value)}
            >
                <option value='en'>English</option>
                <option value='ar'>العربية</option>
            </Select>
        </div>
    )
}

export default ChangeLanguage;


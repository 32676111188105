import { useEffect, useState } from 'react'
import {
  Progress,
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  GridItem,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  InputLeftAddon,
  InputGroup,
  Textarea,
  FormHelperText,
  InputRightElement,
  Stack,
  useDisclosure,
  Spinner,
  useToast
} from '@chakra-ui/react'
import { postFormData } from '../services/apis/api';
import { Navigate, useNavigate } from 'react-router-dom';

const CreateClub = () => {

    const [orgName, setOrgName] = useState('');
    const [description, setDescription] = useState('');
    const [founder, setFounder] = useState('');
    const [foundedOn, setFoundedOn] = useState('');
    const [mission, setMission] = useState('');
    const [vision, setVision] = useState('');
    const [location, setLocation] = useState('');
    const [registrationNumber, setRegistrationNumber] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [banner, setBanner] = useState();
    const [logo, setLogo] = useState();

    const toast = useToast();
    const navigate = useNavigate();

    const createOrgnization = async()=>{
        try {   
            setIsLoading(true);
            let formData = new FormData();
            formData.append('name', orgName);
            formData.append('foundedOn', foundedOn);
            formData.append('founder', founder);
            formData.append('location', location);
            formData.append('mission', mission);
            formData.append('vision', vision);
            formData.append('description', description);
            formData.append('registrationNumber', registrationNumber);
            formData.append('logo', logo )
            formData.append('banner', banner)
    
            const res = await postFormData('organization/add', formData);
            console.log(res);
            if(res.statusCode === 201){
                setIsLoading(false);
                navigate('/dashboard')
                toast({
                    title: 'Successfull...!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            toast({
                title: 'Opps...!',
                description: error?.response?.data?.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }

    }

    useEffect(() => {
        const onBeforeUnload = (event) => {
          // Prevent the user from leaving the page
          event.preventDefault();
          event.returnValue = true;
        };
      
        window.addEventListener('beforeunload', onBeforeUnload);
      
        return () => {
          window.removeEventListener('beforeunload', onBeforeUnload);
        };
      }, []);

  return (
    <div className='h-screen flex justify-center items-center'>
        <div className='w-[70vw] border p-10 rounded-lg bg-slate-50'>
            <h2 className='text-lg font-semibold text-[#000]'>
                Club Details
            </h2>
            <Stack>
            <Flex>
                    <FormControl mr="3%">
                    <FormLabel htmlFor="full-name" fontWeight={'normal'}>
                        Organization Name<span className='text-red-500'>*</span>
                    </FormLabel>
                    <Input id="full-name" onChange={(e)=>{setOrgName(e.target.value)}} placeholder="E.g. Al-Ibtisam" />
                    </FormControl>

                    <FormControl>
                    <FormLabel htmlFor="user-name" fontWeight={'normal'}>
                        Registration Number<span className='text-red-500'>*</span>
                    </FormLabel>
                    <Input id="user-name" onChange={(e)=>{setRegistrationNumber(e.target.value)}} placeholder="E.g. SD45D5CAACA" />
                    </FormControl>
                </Flex>
                <Flex>
                    <FormControl mr="3%" mt="2%">
                        <FormLabel htmlFor="email" fontWeight={'normal'}>
                            Founder<span className='text-red-500'>*</span>
                        </FormLabel>
                        <Input id="text" type="text" onChange={(e)=>{setFounder(e.target.value)}} placeholder='E.g. Sanjay'/>
                    </FormControl>
                    <FormControl mt="2%">
                        <FormLabel htmlFor="email" fontWeight={'normal'}>
                            Foundation Date<span className='text-red-500'>*</span>
                        </FormLabel>
                        <Input placeholder='Select Date and Time' onChange={(e)=>{setFoundedOn(e.target.value)}} size='md' type='date' />
                    </FormControl>
                </Flex>
                <Flex>
                    <FormControl mr="3%" mt="2%">
                        <FormLabel htmlFor="title" fontWeight={'normal'}>
                            Address<span className='text-red-500'>*</span>
                        </FormLabel>
                        <Input id="title" type="text" onChange={(e)=>{setLocation(e.target.value)}} placeholder='E.g. Bhopal, India' />
                    </FormControl>
                    <FormControl mr="3%" mt="2%">
                        <FormLabel htmlFor="title" fontWeight={'normal'}>
                            Description<span className='text-red-500'>*</span>
                        </FormLabel>
                        <Input id="title" type="text" onChange={(e)=>{setDescription(e.target.value)}} placeholder='E.g. best sports organization among all' />
                    </FormControl>
                
                </Flex>
                <Flex>
                    <FormControl mr="3%" mt="2%">
                        <FormLabel htmlFor="title" fontWeight={'normal'}>
                            Mission<span className='text-red-500'>*</span>
                        </FormLabel>
                        <Input id="title" onChange={(e)=>{setMission(e.target.value)}} type="text" placeholder='E.g. best in sports' />
                    </FormControl>
                    <FormControl mr="3%" mt="2%">
                        <FormLabel htmlFor="title" fontWeight={'normal'}>
                            Vision<span className='text-red-500'>*</span>
                        </FormLabel>
                        <Input id="title" type="text" onChange={(e)=>{setVision(e.target.value)}} placeholder='E.g. best sports organization among all' />
                    </FormControl>
                
                </Flex>
                <Flex>
                    <FormControl mr="3%" mt="2%">
                        <FormLabel htmlFor="title" fontWeight={'normal'}>
                            Logo<span className='text-red-500'>*</span>
                        </FormLabel>
                        <Input id="logo" onChange={(e)=>{setLogo(e.target.files[0])}} type="file" placeholder='logo' />
                    </FormControl>
                    <FormControl mr="3%" mt="2%">
                        <FormLabel htmlFor="title" fontWeight={'normal'}>
                            Banner<span className='text-red-500'>*</span>
                        </FormLabel>
                        <Input id="banner" type="file" onChange={(e)=>{setBanner(e.target.files[0])}} placeholder='banner' />
                    </FormControl>
                
                </Flex>
                <Flex justifyContent={'end'} mt={5}>
                    <Button colorScheme='blue' onClick={createOrgnization}>
                        {isLoading ? <Spinner /> : "Submit"}
                    </Button>
                </Flex>

                
            </Stack>
        </div>
       
    </div>
  )
}

export default CreateClub
import React, { useState, useRef, useEffect } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    Button,
    useToast,
    Spinner,
    Switch,
    Box,
    IconButton,
    Grid,
    Stack,
    GridItem,
    Textarea,
    Checkbox,
} from "@chakra-ui/react";
import { Select } from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { postFormData, patchFormData, get } from "../../services/apis/api";

const CreateProduct = ({ getAllProducts, product, isEdit, getWarehouses }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const initialRef = useRef(null);
    const finalRef = useRef(null);
    const toast = useToast();
    const [selectedImages, setSelectedImages] = useState([]);
    const [existingImages, setExistingImages] = useState([]);
    // const [type, setType]= useState([]);
    const [formValues, setFormValues] = useState({
        name: "",
        category: "",
        description: "",
        isActive: false,
        type: [],
        customizable: false,
        customizationCost: 0,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSwitchChange = (e) => {
        setFormValues({
            ...formValues,
            isActive: e.target.checked,
        });
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setSelectedImages((prevImages) => [...prevImages, ...files]);
    };

    const removeImage = (index, isExisting = false) => {
        if (isExisting) {
            setExistingImages((prevImages) =>
                prevImages.filter((_, i) => i !== index)
            );
        } else {
            setSelectedImages((prevImages) =>
                prevImages.filter((_, i) => i !== index)
            );
        }
    };

    const fetchCategories = async () => {
        try {
            const res = await get("category/dropdown");
            if (res.statusCode === 200) {
                setCategories(res.data.dropdown);
            }
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    useEffect(() => {
        if (isEdit) {
            setFormValues({
                name: product.name,
                category: product.category._id, // Set category ID when editing
                description: product.description,
                isActive: product.isActive,
                type: product.type || [],
                customizable: product.customizable || false,
                customizationCost: product.customizationCost || 0,
            });

            setExistingImages(product.images || []); // Load existing images if editing
        }
        fetchCategories();
    }, [isEdit, product]);

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            const formData = new FormData();
            formData.append("name", formValues.name);
            formData.append("category", formValues.category); // Send category ID, not name
            formData.append("description", formValues.description);
            formData.append("isActive", formValues.isActive);
            formData.append("customizable", formValues.customizable);
            formData.append("customizationCost", formValues.customizationCost ||0);

            formValues.type.map((e) => formData.append("type", e));
            // Pass existing images
            formData.append("existingImages", JSON.stringify(existingImages));

            // Pass only newly selected images
            selectedImages.forEach((image) => {
                formData.append("images", image);
            });

            let res;
            if (isEdit) {
                res = await patchFormData(
                    `product/update/${product._id}`,
                    formData
                );
                getWarehouses();
            } else {
                res = await postFormData("product/add", formData);
            }

            if (res.statusCode === 201 || res.statusCode === 200) {
                onClose();
                getAllProducts();
                setIsLoading(false);
                toast({
                    title: "Success!",
                    description: res.data.message,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                setSelectedImages([]); // Reset selectedImages after successful submission
            }
        } catch (error) {
            setIsLoading(false);
            toast({
                title: "Oops!",
                description:
                    error?.response?.data?.message || "An error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            console.log(error);
        }
    };
    const handleUserCheckboxChange = (e) => {
        const { name, checked } = e.target;

        setFormValues((prevValues) => {
            if (checked) {
                if (!formValues.type.includes("EXTERNAL")) {
                    return {
                        ...prevValues,
                        customizable: false,
                        type: [...prevValues.type, name],
                    };
                }
                return {
                    ...prevValues,
                    type: [...prevValues.type, name],
                };
            } else {
                return {
                    ...prevValues,
                    type: prevValues.type.filter((type) => type !== name),
                };
            }
        });
    };
    const handleCustomizableCheckbox = (e) => {
        const { checked } = e.target;
        setFormValues((prevValues) => {
            if (checked) {
                return {
                    ...prevValues,
                    customizable: checked,
                };
            } else {
                return {
                    ...prevValues,
                    customizable: checked,
                    customizationCost: 0,
                };
            }
        });
    };
    return (
        <>
            <Button colorScheme="blue" onClick={onOpen}>
                {isEdit ? "Edit" : "Create Product"}
            </Button>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
                size="xl"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {isEdit ? "Edit Product" : "Create New Product"}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                <GridItem>
                                    <FormLabel>Product Name</FormLabel>
                                    <Input
                                        ref={initialRef}
                                        placeholder="Product Name"
                                        name="name"
                                        value={formValues.name}
                                        onChange={handleChange}
                                    />
                                </GridItem>

                                <GridItem>
                                    <FormLabel>Category</FormLabel>
                                    <Select
                                        placeholder="Select Category"
                                        name="category"
                                        value={formValues.category} // Ensure value is the category ID
                                        onChange={handleChange}
                                    >
                                        {categories &&
                                            categories.map((item, index) => (
                                                <option
                                                    key={index}
                                                    value={item._id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                    </Select>
                                </GridItem>
                                <FormControl>
                                    <FormLabel>Select product type</FormLabel>

                                    <Stack direction="row">
                                        <Checkbox
                                            name="INTERNAL"
                                            isChecked={formValues.type.includes(
                                                "INTERNAL"
                                            )}
                                            onChange={handleUserCheckboxChange}
                                        >
                                            Internal
                                        </Checkbox>
                                        <Checkbox
                                            name="EXTERNAL"
                                            isChecked={formValues.type.includes(
                                                "EXTERNAL"
                                            )}
                                            onChange={handleUserCheckboxChange}
                                        >
                                            External
                                        </Checkbox>
                                    </Stack>
                                </FormControl>
                            </Grid>
                            {formValues.type.includes("EXTERNAL") ? (
                                <>
                                    <Checkbox
                                        name="customizable"
                                        isChecked={formValues.customizable}
                                        onChange={handleCustomizableCheckbox}
                                    >
                                        Customizable
                                    </Checkbox>
                                    {formValues.customizable ? (
                                        <>
                                            <Input
                                                width={"50"}
                                                marginLeft={"5"}
                                                placeholder="Customization Cost"
                                                value={
                                                    formValues.customizationCost
                                                }
                                                onChange={(e) => {
                                                    setFormValues((prevValues) => {
                                                        return {
                                                            ...prevValues,
                                                            customizationCost: e.target.value,
                                                        };
                                                    });
                                                }}
                                                type="number"
                                            ></Input>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : (
                                <></>
                            )}

                            <FormLabel mt={4}>Description</FormLabel>
                            <Textarea
                                name="description"
                                value={formValues.description}
                                onChange={handleChange}
                            />

                            {isEdit && (
                                <FormControl
                                    mt={5}
                                    display="flex"
                                    alignItems="center"
                                >
                                    <FormLabel htmlFor="status-switch" mb="0">
                                        Status of Product?
                                    </FormLabel>
                                    <Switch
                                        id="status-switch"
                                        name="isActive"
                                        onChange={handleSwitchChange}
                                        isChecked={formValues.isActive}
                                    />
                                </FormControl>
                            )}

                            <FormLabel mt={4}>Product Images</FormLabel>
                            <Grid templateColumns="repeat(4, 1fr)" gap={4}>
                                {existingImages.map((image, index) => (
                                    <Box
                                        key={index}
                                        position="relative"
                                        borderRadius="md"
                                        overflow="hidden"
                                        width="100px"
                                        height="100px"
                                        bgImage={`url(${image})`}
                                        bgSize="cover"
                                        bgPosition="center"
                                    >
                                        <IconButton
                                            aria-label="Remove image"
                                            size="xs"
                                            icon={<CloseIcon />}
                                            position="absolute"
                                            top="2px"
                                            right="2px"
                                            onClick={() =>
                                                removeImage(index, true)
                                            }
                                        />
                                    </Box>
                                ))}

                                {selectedImages.map((image, index) => (
                                    <Box
                                        key={index}
                                        position="relative"
                                        borderRadius="md"
                                        overflow="hidden"
                                        width="100px"
                                        height="100px"
                                        bgImage={`url(${URL.createObjectURL(
                                            image
                                        )})`}
                                        bgSize="cover"
                                        bgPosition="center"
                                    >
                                        <IconButton
                                            aria-label="Remove image"
                                            size="xs"
                                            icon={<CloseIcon />}
                                            position="absolute"
                                            top="2px"
                                            right="2px"
                                            onClick={() => removeImage(index)}
                                        />
                                    </Box>
                                ))}

                                <Box
                                    as="label"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    border="1px dashed"
                                    borderColor="gray.300"
                                    borderRadius="md"
                                    width="100px"
                                    height="100px"
                                    cursor="pointer"
                                >
                                    <Input
                                        type="file"
                                        display="none"
                                        multiple
                                        accept="image/*"
                                        onChange={handleImageChange}
                                    />
                                    <AddIcon />
                                </Box>
                            </Grid>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button
                            colorScheme="blue"
                            ml={3}
                            onClick={handleSubmit}
                        >
                            {isLoading ? (
                                <Spinner size="sm" />
                            ) : isEdit ? (
                                "Update"
                            ) : (
                                "Create"
                            )}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateProduct;

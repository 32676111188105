import React from 'react'
import { Routes, Route } from "react-router-dom";
import Dashboard from '../pages/superAdmin/Dashboard';
import CommanNav from '../components/globles/CommanNav';
import Home from '../pages/hr/Home';
import Employee from '../pages/hr/Employee';
import Attendance from '../pages/hr/Attendance';
import Performances from '../pages/hr/Performances';
import Payroll from '../pages/hr/Payroll';
import Fnf from '../pages/hr/Fnf';
import Reports from '../pages/hr/Reports';
import Expense from '../pages/hr/Expense';
import Assets from '../pages/hr/Assets';
import Announcement from '../pages/hr/Announcement';
import AssetProvider from '../context/assets';
import Request from '../pages/game-management/Requests';

const HumanResource = () => {
  return (
    <AssetProvider>
      <Routes>
          <Route path="/hr" element={<CommanNav />}>
              <Route index element={<Employee />} />
              <Route path="employees" element={<Employee />} />
              <Route path="attendance" element={<Attendance />} />
              <Route path="performances" element={<Performances />} />
              <Route path="payroll" element={<Payroll />} />
              <Route path="fnf" element={<Fnf />} />
              <Route path="reports" element={<Reports />} />
              <Route path="expense" element={<Expense />} />
                <Route path="assets" element={<Assets />} />
              <Route path="announcement" element={<Announcement />} />
              <Route path="request" element={<Request />} />

              {/* <Route path="employee-performance" element={<EmployeePerformance />} /> */}
          </Route>
      </Routes>
    </AssetProvider>
  )
}

export default HumanResource
import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  Text,
} from '@chakra-ui/react';
import { DownloadIcon, SearchIcon } from '@chakra-ui/icons';
import AddAttendanceByCSV from '../../components/hr/dialogBoxes/AddAttendanceByCSV';
import AddSingleEmployeeAttendance from '../../components/hr/dialogBoxes/AddSingleEmployeeAttendance';
import { get } from '../../services/apis/api';
import { formatDateTime } from '../../utills/formateDate';
import CompanyLeavePolicyModal from '../../components/hr/dialogBoxes/AddLeavePolicy';
import CompanyLeavePolicyDetailsModal from '../../components/hr/dialogBoxes/CompanyLeavePolicyDetailsModal';

// Utility function to convert attendance data to CSV format
const convertToCSV = (data) => {
  const headers = ['Name', 'EmployeeCode', 'PaidDays', 'PayableDays', 'OvertimeHours', 'MonthEndDate'];

  const rows = data.map((attendance) => [
    attendance?.employeeId?.name,
    attendance?.employeeId?.employeeCode,
    attendance.paidDays,
    attendance.payableDays,
    attendance.overTimeHours, 
    formatDateTime(attendance.monthEndDate, 'date'), 
  ]);

  const csvContent = [headers, ...rows].map((e) => e.join(",")).join("\n");
  return csvContent;
};

const AttendanceTable = ({ attendances, page, totalPages, handlePageChange }) => (
  <>
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <Table className="w-full text-sm text-left text-gray-500">
        <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <Tr>
            <Th>Profile</Th>
            <Th>Name</Th>
            <Th>Employee Code</Th>
            <Th>Paid Days</Th>
            <Th>Payable Days</Th>
            <Th>Overtime Hours</Th> {/* Updated heading */}
            <Th>Month End Date</Th>
          </Tr>
        </Thead>
        <Tbody>
          {attendances.map((attendance) => (
            <Tr key={attendance.employeeCode} className="bg-white border-b hover:bg-gray-50 cursor-pointer">
              <Td>
                <Avatar src={attendance.profile} name={attendance.name} />
              </Td>
              <Td>{attendance?.employeeId?.name}</Td>
              <Td>{attendance?.employeeId?.employeeCode}</Td>
              <Td>{attendance.paidDays}</Td>
              <Td>{attendance.payableDays}</Td>
              <Td>{attendance.overTimeHours}</Td> {/* Adjusted field name */}
              <Td>{formatDateTime(attendance.monthEndDate, 'date')}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
    <Flex justify="space-between" align="center" mt={4}>
      <Button
        onClick={() => handlePageChange(page - 1)}
        disabled={page === 1}
      >
        Previous
      </Button>
      <Text>Page {page} of {totalPages}</Text>
      <Button
        onClick={() => handlePageChange(page + 1)}
        disabled={page >= totalPages}
      >
        Next
      </Button>
    </Flex>
  </>
);

const Attendance = () => {
  const [attendances, setAttendances] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalAttendances, setTotalAttendances] = useState(0);

  const getAttendances = async (limit = 10) => {
    try {
      const res = await get(`attendance/all?page=${page}&limit=${limit}&name=${search}`);
      console.log(res);
      
      setAttendances(res.data.data);
      setTotalPages(res.data.pages);
      setTotalAttendances(res.data.total);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAttendances();
  }, [page, search]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  // Fetch all attendances and then download as CSV
  const downloadCSV = async () => {
    try {
      const res = await get(`attendance/all?page=1&limit=${totalAttendances}&name=${search}`);
      const allAttendances = res.data.data;
      const csvContent = convertToCSV(allAttendances);
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'attendances.csv');
      link.style.visibility = 'hidden';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error fetching attendances for CSV:', error);
    }
  };

  return (
    <Box width="100%" p={4}>
      <Flex justify="space-between" align="center" mb={4}>
        <Box fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
          Attendance ({totalAttendances})
        </Box>
        <Flex align="center">
          <InputGroup mr={2} mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "auto" }}>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              type="text"
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
            />
          </InputGroup>

          <AddSingleEmployeeAttendance  getAttendances={getAttendances}/>
          <AddAttendanceByCSV />
          <Button
            leftIcon={<DownloadIcon />}
            colorScheme="gray"
            mr={2}
            mb={{ base: 2, md: 0 }}
            onClick={downloadCSV} 
          >
            Download CSV
          </Button>
          {/* <CompanyLeavePolicyModal /> */}
          <CompanyLeavePolicyDetailsModal />
        </Flex>
      </Flex>

      <AttendanceTable
        attendances={attendances}
        page={page}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
    </Box>
  );
};

export default Attendance;

const colors = {
    // primary: "#D32626",
    // hoverPrimary: "#D32626",
    primary: "#5F45A8",
    navBar: "gray.700",
    secondary: "#5F45A8",
    hoverPrimary: "#5F45A8",
    hoverSecondary: "#5F45A8",
    gradient: `linear-gradient(to right, #5F45A8, #5F45A8)`,
    hoverGradient: `linear-gradient(to right, #7928ca, #5F45A8)`
    // Add other colors as needed
};



export default colors;

import React, { createContext, useContext, useEffect, useState } from "react";


const employeeContext = createContext();

const EmployeeProvider = ({ children }) => {
  const [basicDetails, setBasicDetails] = useState({
    fullName: '',
    email: '',
    contactNo: '',
    department: '',
    type: '',
    designation: '',
    gender: '',
    joiningDate: '',
    location: '',
    experience: '',
  });
  const [personalInfo, setPersonalInfo] = useState({
    alternateContact: '',
    panCardNo: '',
    payMethod: '',
    holderName: '',
    permanentAddress: '',
    currentAddress: '',
    aadharNo: '',
    drivingLicense: '',
    accountNo: '',
    bankName: '',
    documents: [],
    contactWithCovid: '',
    agreements: { abc: false, xyz: false }
  });
  const [salaryTerms, setSalaryTerms] = useState({
    hra: '',
    travelingAllowance: '',
    hostelAllowance: '',
    specialAllowance: '',
    telecommunicationAllowance: '',
    basicSalary: '',
    grossPay: '',
    variablePay: '',
    pf: '',
    pt: '',
    esic: '',
    gratuity: '',
    bonus: '',
    tds: '',
    gst: '',
    workmenCompensation: '',
    netPay: '',
    appraisalDate: '',
    ctc: 0,
  });
  const [benefits, setBenefits] = useState({});
  const [step, setStep] = useState();

  console.log("personal info--->", personalInfo);
  console.log("personal info--->", personalInfo);


  return (
    <employeeContext.Provider
      value={{
        basicDetails, setBasicDetails,
        personalInfo, setPersonalInfo,
        salaryTerms, setSalaryTerms,
        benefits, setBenefits,
        step, setStep
      }}
    >
      {children}
    </employeeContext.Provider>
  );
};

export const EmployeeState = () => {
  return useContext(employeeContext);
};

export default EmployeeProvider;   


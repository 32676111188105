import { Button, FormControl, FormLabel, Input, useDisclosure, SimpleGrid, GridItem, Spinner, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { get, post } from '../../services/apis/api'
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';

const PlayerRemoveRequest = ({ isOpen, onClose }) => {
  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [players, setPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  // State to hold form values
  const [formValues, setFormValues] = useState({
    playerId: '',
    status: 'SUPER-ADMIN-REVIEW',
    reason: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const handleSave = async() => {
    try {
      setLoading(true);
      console.log(formValues);
      const res = await post('request/player-remove', formValues);
      if(res.statusCode === 200){
        onClose();
        setLoading(false);
        toast({
            title: 'Successful!',
            description: res.data.message,
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
      } 
    } catch (error) {
      setLoading(false);
      console.error(error);
      if(error.response.status === 500){
        toast({
          title: 'Error!',
          description: "Please fill all the fields correctly",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  }

  useEffect(() => {
    getPlayers();
  }, []);

  // Fetch players data
  const getPlayers = async () => {
    try {
      const res = await get('player/dropdown');
      if (res.statusCode === 200) {
        const formattedPlayers = res.data.dropdown.map(player => ({
          label: player.name,
          value: player._id
        }));
        setPlayers(formattedPlayers);
      }
    } catch (error) {
      console.error('Error fetching players:', error);
    }
  }

  const handlePlayerSelectChange = (selectedOption) => {
    setSelectedPlayer(selectedOption);
    setFormValues({
      ...formValues,
      playerId: selectedOption ? selectedOption.value : ''
    });
  }

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('remove-player-request')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <SimpleGrid columns={2} spacing={4} dir={isArabic ? 'rtl' : 'ltr'}>
              <GridItem colSpan={2}>
                <FormControl>
                  <FormLabel>{t('player-id')}</FormLabel>
                  <ReactSelect
                    className='text-right'
                    options={players}
                    value={selectedPlayer}
                    onChange={handlePlayerSelectChange}
                    placeholder={t("select-player-id")}
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={2}>
                <FormControl>
                  <FormLabel>{t('reason')}</FormLabel>
                  <Input
                    name="reason"
                    value={formValues.reason}
                    onChange={handleChange}
                    placeholder={t('reason')}
                  />
                </FormControl>
              </GridItem>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3} >{t('cancel')}</Button>
            <Button colorScheme="blue" onClick={handleSave}>
              {loading ? <Spinner /> : t("save")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default PlayerRemoveRequest

import React, { useState } from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { FaCheckCircle } from 'react-icons/fa'

const ConfirmCompletePayment = ({
    isOpen,
    onClose,
    isLoading,
    onCompleteRequest
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const cancelRef = React.useRef()


    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t('complete-payment')}
                        </AlertDialogHeader>

                        <AlertDialogBody py={4}>
                            {t('complete-payment-msg')}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                {t('cancel')}
                            </Button>
                            <Button
                                colorScheme='green'
                                isLoading={isLoading}
                                onClick={() => {
                                    onCompleteRequest();
                                }}
                                ml={3}
                                leftIcon={<FaCheckCircle />}
                            >
                                {t('complete-payment')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default ConfirmCompletePayment
import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
  } from '@chakra-ui/react'
import CreateUserMultiSteps from './CreateUserMultiSteps'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

const CreateUser = ({isEdit , user, getUsers}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    return (
        <>
            {
                isEdit ? 
                    <button onClick={onOpen} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md  px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">{t("edit")}</button>
                    : 
                    <button className='text-[#ffffff] bg-[#290dbd] font-semibold py-2 px-4 rounded-md' onClick={onOpen}>{t("create-user")}</button>
            }

            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={'3xl'}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>{t("create-new-user")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <CreateUserMultiSteps onClose={onClose} isEdit={isEdit} user={user} getUsers={getUsers} />
                </ModalBody>

                {/* <ModalFooter>
                    <Button colorScheme='blue' mr={3}>
                    Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter> */}
                </ModalContent>
            </Modal>
        </>
    )
}

export default CreateUser
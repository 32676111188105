import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Button,
  useToast,
  Spinner,
  Switch
} from '@chakra-ui/react';
import { Select } from '@chakra-ui/react';
import { post, patch } from '../../services/apis/api';
import { useTranslation } from 'react-i18next';



const CreateCategory = ({ getAllCategories, category, isEdit }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };
  useEffect(() => {
    if (isEdit) {
      setFormValues({
        name: category.name,
        description: category.description,
        isActive: category.isActive,
      })
    }
  }, [isEdit, category]);

  const handleSwitchChange = (e) => {
    console.log("sss", e.target.checked);
    setFormValues({
      ...formValues,
      isActive: e.target.checked,
    });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      console.log(formValues);
      let res;
      if (isEdit) {
        res = await patch(`category/update/${category._id}`, formValues);
      } else {
        res = await post('category/add', formValues);
      }
      console.log(res);
      if (res.statusCode === 201 || res.statusCode === 200) {
        onClose();
        getAllCategories();
        setIsLoading(false)
        toast({
          title: 'Successfull...!',
          description: res.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setIsLoading(false)
      toast({
        title: 'Opps...!',
        description: error?.response?.data?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      console.log(error);
    }


  };

  return (
    <>
      <Button colorScheme="blue" onClick={onOpen}>{isEdit ? t("Edit") : t("Create Category")}</Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('Create New Category')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>{t('Category')}</FormLabel>
              <Input
                ref={initialRef}
                placeholder={t('Category')}
                name='name'
                value={formValues.name}
                onChange={handleChange}
              />
              <FormLabel>{t('Description')}</FormLabel>
              <Input
                ref={initialRef}
                placeholder={t('Description')}
                name='description'
                value={formValues.description}
                onChange={handleChange}
              />
              {isEdit && (
                <>
                  <FormControl mt={5} display='flex' alignItems='center'>
                    <FormLabel htmlFor='email-alerts' mb='0'>
                      {t('Status of Category?')}
                    </FormLabel>
                    <Switch id='email-alerts' name='isActive' onChange={handleSwitchChange} isChecked={formValues.isActive} />
                  </FormControl>
                </>
              )}

            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={handleSubmit}>
              {isLoading ? <Spinner /> : isEdit ? t("update") : t("save")}
            </Button>
            <Button onClick={onClose}>{t('cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateCategory;

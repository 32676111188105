import React, { useState } from 'react'
import {
    Avatar, Box, Button, Flex, Heading, Spinner, Text, useColorModeValue, AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Tooltip,
    IconButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { DownloadIcon } from '@chakra-ui/icons';

const ConfirmDownload = ({
    handleDownload,
    confirmDownload,
    isOpen,
    closeDialog,
    label,
    loading,
    isIcon
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [cancelRef, setCancelRef] = useState(null);  // for the cancel button in AlertDialog

    return (
        <>
            {isIcon ?
                <Tooltip
                    label={t(label)}
                >
                    <IconButton
                        onClick={handleDownload}
                        isLoading={loading}
                        colorScheme="gray"
                        variant="outline"
                    >
                        <DownloadIcon />
                    </IconButton>
                </Tooltip>
                :
                <Button
                    leftIcon={isArabic ? null : <DownloadIcon />}
                    rightIcon={isArabic ? <DownloadIcon /> : null}
                    colorScheme="gray"
                    onClick={handleDownload}
                    variant="outline"

                >
                    {t(label)}
                </Button>
            }
            {/* Confirmation Dialog */}
            <AlertDialog isOpen={isOpen} onClose={closeDialog} leastDestructiveRef={cancelRef}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader textAlign={isArabic ? 'right' : 'left'} dir={isArabic ? 'rtl' : 'ltr'} fontSize="lg" fontWeight="bold">
                            {t('confirm-download')}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text textAlign={isArabic ? "end" : "start"}>{t('download-report-msg')}</Text>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={closeDialog}>
                                {t('cancel')}
                            </Button>
                            <Button
                                isLoading={loading}
                                colorScheme="blue"
                                onClick={confirmDownload}
                                ml={3}
                            >
                                {t('confirm')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default ConfirmDownload

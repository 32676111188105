import { useTranslation } from "react-i18next";
import { UserState } from "../../context/user";
import { useEffect, useState } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Select, Divider, Th, Table, TableContainer, Thead, Tr, Td, Tbody, Button } from '@chakra-ui/react';
import {
    Menu,
    MenuButton,
    MenuList,
    IconButton
} from '@chakra-ui/react'
import moment from 'moment';
import { HamburgerIcon } from "@chakra-ui/icons";
import { get } from "../../services/apis/api";
import { useNavigate } from "react-router-dom";

const Requests = () => {
    const { user } = UserState();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [searchTerm, setSearchTerm] = useState('');
    const [filterRequest, setFilterRequest] = useState("");// Debounce the getAllRequest function
    const [requests, setRequests] = useState([]);
    const [view, setView] = useState(false);
    const [selectedReq, setSelectedReq] = useState(null);

    const RequestList = () => {
        return (
            <TableContainer shadow="md" borderRadius="lg" flex={1} display="flex" flexDirection="column">
                <Table variant="simple">
                    <Thead bg="gray.50">
                        <Tr>
                            <Th>{t('s-no')}</Th>
                            <Th>{t('name')}</Th>
                            <Th>{t('gender')}</Th>
                            <Th>{t('request-type')}</Th>
                            <Th>{t('request-status')}</Th>
                            <Th>{t('request-description')}</Th>
                            <Th>{t('request-date')}</Th>
                            <Th>{t('action')}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {requests.length > 0 ? (
                            requests.map((request, index) => (
                                <Tr key={request._id} _hover={{ bg: 'gray.50' }}>
                                    <Td>{index + 1}</Td>
                                    <Td>{request?.userId?.name}</Td>
                                    <Td>{request?.userId?.gender}</Td>
                                    <Td>{request?.userAppointmentType}</Td>
                                    <Td>{request?.status}</Td>
                                    <Td>{request?.injuryDescription}</Td>
                                    <Td>{request?.createdAt ? moment(request.createdAt).format('DD-MM-YYYY') : ''}</Td>
                                    <Td>
                                        <Button
                                            colorScheme="blue"
                                            onClick={() => {
                                                setView(true);
                                                setSelectedReq(request);
                                                navigate(`/clinic/request/${request._id}`);
                                            }}
                                        >
                                            {t('view')}
                                        </Button>
                                    </Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Td colSpan="5" textAlign="center">
                                    {t('no-requests-found')}
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </TableContainer>
        );
    };

    // GET ALL AVAILABLE REQUESTS 
    const getAllRequest = async () => {
        try {
            let res;
            if (user) {
                const res = await get(`clinic/all?status=OPEN`)
                if (res.statusCode === 200) {
                    setRequests(res?.data?.appointments || []);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAllRequest();
    }, [])
    return (
        <>
            <div className='w-full flex-1 flex flex-col'>
                <div className={`flex     justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                    <h2 className='text-xl whitespace-nowrap font-bold mb-4'>{t("clinic")}</h2>
                    <div className='flex w-full justify-between lg:w-fit items-center'>
                        <div className="relative">
                            <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input
                                type="text"
                                id="table-search-users"
                                className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                                placeholder={t("search-request")}
                                value={searchTerm}
                                onChange={(e) => { setSearchTerm(e.target.value) }}
                            />
                        </div>
                        <div className='ms-2'>
                            <Menu closeonselect={false}>
                                <MenuButton
                                    as={IconButton}
                                    aria-label='Options'
                                    icon={<HamburgerIcon />}
                                    variant='outline'
                                />
                                <MenuList p={2}>
                                    <Select placeholder={t('expense-type')} textAlign={isArabic ? "right" : 'left'} bg="#f9fafb" borderColor="gray.300" value={filterRequest} onChange={(e) => { setFilterRequest(e.target.value); }}>
                                        {/* {expenseTypeOptions.map((item) => (
                                        <option key={item} value={item}>
                                            {t(item)}
                                        </option>
                                    ))} */}
                                    </Select>
                                    <Divider my={1} />
                                    <Select placeholder={t('request-status')} textAlign={isArabic ? "right" : 'left'} bg="#f9fafb" borderColor="gray.300" value={filterRequest} onChange={(e) => { setFilterRequest(e.target.value); }}>
                                        {/* {requestStatusOptions.map((item) => (
                                        <option key={item} value={item}>
                                            {t(item)}
                                        </option>
                                    ))} */}
                                    </Select>
                                </MenuList>
                            </Menu>
                        </div>
                    </div>
                </div>
                <div className='w-full flex-1 flex flex-col '>
                    <RequestList />
                </div>
            </div>
        </>
    );
}
export default Requests;
import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  Checkbox,
  Flex,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useToast,
  Spinner,
  Stack,
  InputLeftElement
} from '@chakra-ui/react';
import { get, patch, post } from '../../services/apis/api';
import ReactSelect from 'react-select';
import CountrySelect from '../globles/CountrySelect';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const CreateCoach = ({ getAllCoaches, byHr = false, requestId, getRequestDetails, isEdit, coach }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const toast = useToast();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [games, setGames] = useState([]);
  const [ageCategories, setAgeCategories] = useState([]);
  const [employees, setEmployees] = useState([]);
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const [formData, setFormData] = useState({
    employeeId: "",
    stage: [],
    gameId: '',
    ageCategoryIds: [],
    specialization: '',
    password: '',
    confirmPassword: '',
    role: "COACH",
  });

  const handleClick = () => setShow(!show);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'gameId') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        ageCategoryIds: [],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleEmployeeChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      employeeId: selectedOption?.value || "" // Set employeeId from the selected employee
    }));
  };

  const handleMultiSelectChange = (selectedOptions) => {
    setFormData((prevData) => ({
      ...prevData,
      ageCategoryIds: selectedOptions
    }));
  };

  const handleStageChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => {
      const updatedStage = checked
        ? [...prevData.stage, name]
        : prevData.stage.filter(stage => stage !== name);
      return { ...prevData, stage: updatedStage };
    });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (!isEdit && formData.password !== formData.confirmPassword) {
        toast({
          title: 'Oops...!',
          description: "Password does not match with Confirm Password",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
        return;
      }

      let res;
      const updatedFormData = {
        ...formData,
        ageCategoryIds: formData.ageCategoryIds.map(cat => cat.value)
      };

      if (isEdit) {
        const body = { ...updatedFormData };
        delete body.password;
        res = await patch(`coach/update/${coach._id}`, body);
      } else {
        res = await post('coach/add', { ...updatedFormData, requestId });
      }

      if (res.statusCode === 200) {
        setIsLoading(false);
        if (requestId) {
          getRequestDetails();
        } else {
          getAllCoaches();
        }
        onClose();
        toast({
          title: 'Successful...!',
          description: res.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: 'Oops...!',
        description: error?.response?.data?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      getEmployee();

    }
  };

  const getAllGames = async () => {
    const queryParams = formData.stage.map(stage => `stage=${encodeURIComponent(stage)}`).join('&');
    const res = await get(`game/dropdown?${queryParams}`);
    if (res.statusCode === 200) {
      setGames(res.data.dropdown);
    }
  };

  const getEmployee = async () => {
    try {
      const res = await get('employee/unassigned-dropdown');
      if (res.statusCode === 200) {
        const employeeOptions = res.data.dropdown.map(emp => ({
          value: emp._id,
          label: emp.name
        }));
        setEmployees(employeeOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllGames();
    getEmployee();
  }, [formData.stage]);

  const getAllAgeCategories = async () => {
    if (formData.gameId) {
      const res = await get(`game/age-categories/${formData.gameId}`);
      if (res.statusCode === 200) {
        const myCategories = res.data.dropdown.map(cat => ({ value: cat._id, label: cat.name }));
        setAgeCategories(myCategories);
      }
    } else {
      setAgeCategories([]);
    }
  };

  useEffect(() => {
    getAllAgeCategories();
  }, [formData.gameId]);

  useEffect(() => {
    if (isEdit && coach) {
      const formattedAgeCategories = coach.ageCategoryIds
        ? coach.ageCategoryIds.map(cat => ({ value: cat._id, label: cat.name }))
        : [];
      setFormData({
        employeeId: coach?._id || "",
        gameId: coach.gameId?._id,
        stage: coach.stage,
        ageCategoryIds: formattedAgeCategories,
        experience: coach.experience,
        specialization: coach.specialization,
        password: '',
        confirmPassword: '',
        role: "COACH",
      });
    }
  }, [isEdit, coach]);

  return (
    <>
      <Button onClick={onOpen}>{isEdit ? t("edit-coach") : t("create-coach")}</Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setFormData({
            employeeId: "",
            stage: [],
            gameId: '',
            ageCategoryIds: [],
            specialization: '',
            password: '',
            confirmPassword: '',
            role: "COACH",
          });
          // toast({
          //   title: 'Form Reset',
          //   description: 'Form data has been reset.',
          //   status: 'info',
          //   duration: 3000,
          //   isClosable: true,
          // });
        }}
        size={'5xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEdit ? t("edit-coach") : t('create-new-coach')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6} dir={isArabic ? 'rtl' : 'ltr'}>
            <Flex direction={isArabic ? 'row-reverse' : 'row'}>
              <FormControl>
                <FormLabel htmlFor='employee' fontWeight={'normal'} textAlign={isArabic ? 'right' : 'left'}>
                  {t("select-employee")}<span className='text-red-500'>*</span>
                </FormLabel>
                <ReactSelect
                  id="employee"
                  name="employeeId"
                  options={employees}
                  value={employees.find(emp => emp.value === formData.employeeId) || { value: coach?._id, label: coach?.name }}
                  onChange={handleEmployeeChange}
                  placeholder={t("select-employee")}
                  isClearable
                  isDisabled={isEdit}
                />
              </FormControl>
            </Flex>

            <Flex wrap="wrap">
              {/* <FormControl flex="1 1 30%" mx="1.5%" mt="2%">
                <FormLabel htmlFor="experience" fontWeight={'normal'}>
                  {t('experience')}<span className='text-red-500'>*</span>
                </FormLabel>
                <Input
                  id="experience"
                  name="experience"
                  value={formData.experience}
                  onChange={handleChange}
                  type="number"
                  placeholder={t('experience-in-years')}
                />
              </FormControl> */}

              <FormControl flex="1 1 30%" mt="2%">
                <FormLabel htmlFor="specialization" fontWeight={'normal'}>
                  {t('specialization')}<span className='text-red-500'>*</span>
                </FormLabel>
                <Input
                  id="specialization"
                  name="specialization"
                  value={formData.specialization}
                  onChange={handleChange}
                  placeholder='Specialization'
                />
              </FormControl>

              {/* <FormControl flex="1 1 30%" mx="1.5%" mt="2%">
                <FormLabel htmlFor="dateOfBirth" fontWeight={'normal'}>
                  {t('date-of-birth')}<span className='text-red-500'>*</span>
                </FormLabel>
                <Input
                  id="dateOfBirth"
                  name="dateOfBirth"
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                  type="date"
                  placeholder='Date Of Birth'
                />
              </FormControl> */}
            </Flex>

            <Flex wrap={'wrap'}>
              <FormControl flex="1 1 30%" mx="1.5%" mt="2%">
                <FormLabel>{t('stage')}<span className='text-red-500'>*</span></FormLabel>
                <Stack spacing={2} direction="row">
                  <Checkbox
                    name="SCHOOL"
                    isChecked={formData.stage.includes('SCHOOL')}
                    onChange={handleStageChange}
                  >
                    {t('school')}
                  </Checkbox>
                  <Checkbox
                    name="ACADEMY"
                    isChecked={formData.stage.includes('ACADEMY')}
                    onChange={handleStageChange}
                  >
                    {t('academy')}
                  </Checkbox>
                  <Checkbox
                    name="PROFESSIONAL"
                    isChecked={formData.stage.includes('PROFESSIONAL')}
                    onChange={handleStageChange}
                  >
                    {t('professional')}
                  </Checkbox>
                </Stack>
              </FormControl>

              {formData.stage.length > 0 && (
                <FormControl flex="1 1 30%" mx="1.5%" mt="2%" dir={isArabic ? 'rtl' : 'ltr'}>
                  <FormLabel htmlFor="gameId" fontWeight={'normal'}>
                    {t('select-game')}<span className='text-red-500'>*</span>
                  </FormLabel>
                  <Select
                    id="gameId"
                    name="gameId"
                    value={formData.gameId}
                    onChange={handleChange}
                    placeholder={t('select-game')}
                    textAlign={'center'}
                  >
                    {games.map(game => (
                      <option key={game._id} value={game._id}>{game.name}</option>
                    ))}
                  </Select>
                </FormControl>
              )}

              {formData.stage.includes('PROFESSIONAL') && formData.gameId && (
                <FormControl flex="1 1 30%" mt="2%">
                  <FormLabel htmlFor="ageCategoryIds" fontWeight={'normal'}>
                    {t('select-age-categories')}<span className='text-red-500'>*</span>
                  </FormLabel>
                  <ReactSelect
                    id="ageCategoryIds"
                    name="ageCategoryIds"
                    isMulti
                    options={ageCategories}
                    value={formData.ageCategoryIds}
                    onChange={handleMultiSelectChange}
                  />
                </FormControl>
              )}
            </Flex>

            {!isEdit && (
              <Flex wrap="wrap">
                <FormControl flex="1 1 47%" mx={"1.53%"} mt="2%">
                  <FormLabel htmlFor="password" fontWeight={'normal'}>
                    {t('password')}<span className='text-red-500'>*</span>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      type={show ? 'text' : 'password'}
                      placeholder='*******'
                    />
                    {
                      isArabic ?
                        <InputLeftElement width="4.5rem">
                          <Button h="1.75rem" size="sm" onClick={handleClick}>
                            {show ? <BsEyeSlash /> : <BsEye />}
                          </Button>
                        </InputLeftElement> :
                        <InputRightElement width="4.5rem">
                          <Button h="1.75rem" size="sm" onClick={handleClick}>
                            {show ? <BsEyeSlash /> : <BsEye />}
                          </Button>
                        </InputRightElement>
                    }
                  </InputGroup>
                </FormControl>

                <FormControl flex="1 1 47%" mt="2%">
                  <FormLabel htmlFor="confirmPassword" fontWeight={'normal'}>
                    {t('confirm-password')}<span className='text-red-500'>*</span>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      id="confirmPassword"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      type={show ? 'text' : 'password'}
                      placeholder={t("confirm-password")}
                    />
                    {
                      isArabic ?
                        <InputLeftElement width="4.5rem">
                          <Button h="1.75rem" size="sm" onClick={handleClick}>
                            {show ? <BsEyeSlash /> : <BsEye />}
                          </Button>
                        </InputLeftElement> :
                        <InputRightElement width="4.5rem">
                          <Button h="1.75rem" size="sm" onClick={handleClick}>
                            {show ? <BsEyeSlash /> : <BsEye />}
                          </Button>
                        </InputRightElement>
                    }
                  </InputGroup>
                </FormControl>
              </Flex>
            )}

          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>{t('cancel')}</Button>
            <Button
              colorScheme="blue"
              ml={3}
              onClick={handleSubmit}
              isLoading={isLoading}
              spinner={<Spinner size="sm" />}
              loadingText="Submitting"
            >
              {isEdit ? t('update') : t('create')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateCoach;

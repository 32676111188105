import React from 'react';
import {
  Box, Checkbox, FormControl, FormLabel, Radio, RadioGroup, Stack, Grid, GridItem, VStack, Heading
} from '@chakra-ui/react';
import { EmployeeState } from '../../../../context/addEmployee';

const benefitOptions = [
  { label: 'Accommodation', name: 'accommodation' },
  { label: 'Education Expense', name: 'educationExpense' },
  { label: 'Club Expense', name: 'clubExpense' },
  { label: 'Credit Card', name: 'creditCard' },
  { label: 'Vehicle Expense', name: 'vehicleExpense' },
  { label: 'Gift & Vouchers', name: 'giftVouchers' },
  { label: 'Traveling Expense', name: 'travelingExpense' },
  { label: 'House Help', name: 'houseHelp' },
  { label: 'Medical Expense', name: 'medicalExpense' },
];

const Benefits = () => {
  const { benefits, setBenefits } = EmployeeState();
  console.log("benefits-->", benefits);
  
  const handleCheckboxChange = (name) => (e) => {
    setBenefits((prevState) => ({
      ...prevState,
      [name]: e.target.checked ? prevState[name] || 'Not Applicable' : undefined,
    }));
  };

  const handleRadioChange = (name) => (value) => {
    setBenefits((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Box p={5} borderRadius="md" mx="auto">
      <VStack spacing={4} align="stretch">
        <Heading size="md" mb={4}>Benefits</Heading>
        <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={4}>
          {benefitOptions.map((benefit) => (
            <GridItem key={benefit.name} colSpan={{ base: 1, md: 1 }}>
              <FormControl>
                <FormLabel>{benefit.label}</FormLabel>
                <Checkbox
                  isChecked={benefits[benefit.name] !== undefined}
                  onChange={handleCheckboxChange(benefit.name)}
                >
                  <RadioGroup
                    value={benefits[benefit.name]}
                    onChange={handleRadioChange(benefit.name)}
                    isDisabled={benefits[benefit.name] === undefined}
                  >
                    <Stack direction="row">
                      <Radio value="Not Applicable">Not Applicable</Radio>
                      <Radio value="Inkind">Inkind</Radio>
                      <Radio value="One Time Reimbursement">One Time Reimbursement</Radio>
                      <Radio value="Annual Reimbursement">Annual Reimbursement</Radio>
                    </Stack>
                  </RadioGroup>
                </Checkbox>
              </FormControl>
            </GridItem>
          ))}
        </Grid>
      </VStack>
    </Box>
  );
};

export default Benefits;

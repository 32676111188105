import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Button,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { Select } from '@chakra-ui/react';
import { get, patch, post } from '../../services/apis/api';



const AssignCoachToTeam = ({teamId, name,getTeamDetails }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = useRef(null);
    const finalRef = useRef(null);
    const toast = useToast();

    const [coaches, setCoaches] = useState([]);
    const [selectedCoach, setSelectedCoach] = useState('');
    const [loading, setLoading] = useState(false);
    

    const handleSubmit = async() => {
        try {
            setLoading(true);
            const res = await patch(`team/update/${teamId}`, { coachId: selectedCoach});
            // console.log(res);
            if(res.statusCode === 200){
                setLoading(false);
                getTeamDetails();
                onClose();
                toast({
                    title: 'Successfull...!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: 'Opps...!',
                description: error.response.data.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
            console.log(error);
        }
    };

    const getMyTeamDetails = async()=>{
        try {
            const teamRes = await get(`team/get/${teamId}`);
            if (teamRes.statusCode === 200) {
                const res = await get(`coach/dropdown?gameId=${teamRes.data.team.gameId._id}`);
                if(res.statusCode === 200){
                    setCoaches(res.data.dropdown);
                }
            }
        } catch (error) {
            toast({
                title: "Error fetching team details",
                description: "There was an error fetching the team details.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    }

    useEffect(() => {
        getMyTeamDetails();
    }, [])
    

    return (
        <>  
            <button onClick={onOpen} className='bg-[#290dbd] text-[#ffffff] px-4 py-2 me-4 font-medium text-base rounded-md'>Assign Coach</button>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Assign Coach to Team</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl mt={4}>
                    <FormLabel>Team Name</FormLabel>
                    <Select
                        placeholder='Select Player Name'
                        name='team'
                        value={teamId}
                    >
                        <option value={teamId}>{name}</option>
                    </Select>
                    </FormControl>
                    <FormControl mt={4}>
                    <FormLabel>Select Coach Name</FormLabel>
                    <Select
                        placeholder='Select Coach Name'
                        name='team'
                        value={selectedCoach}
                        onChange={(e)=>{setSelectedCoach(e.target.value)}}
                    >
                        {coaches.length> 0 && coaches.map((coach, index) => (
                        <option key={coach._id} value={coach._id}>
                            {coach.name}
                        </option>
                        ))}
                    </Select>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={onClose} mr={3}>Cancel</Button>
                    <Button colorScheme='blue' onClick={handleSubmit}>
                        {loading ? <Spinner /> : "Save" }
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AssignCoachToTeam;

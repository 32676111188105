import React, { useEffect, useState } from 'react';
import RequestBody from '../../components/employees/RequestBody';
import { get, post } from '../../services/apis/api';
import { UserState } from '../../context/user';
import {
    Avatar, Box, Button, Flex, Heading, Spinner, Text, useColorModeValue,
} from '@chakra-ui/react';
import { HiOutlineCalendarDays } from "react-icons/hi2";
import RequestDetailsModal from '../../components/employees/RequestDetailsModal';
import { useTranslation } from 'react-i18next';
import { formatDate, formatDateArEn, requestTypeOptions } from '../../services/glocalFunctions';
import { jsPDF } from 'jspdf';
import ConfirmDownload from '../../components/dialogueBoxes/ConfirmDownload';

const Request = () => {
    // State for managing modals and form inputs
    const { user, setUser } = UserState();
    const [isModalOpen, setModalOpen] = useState(false);
    const [requests, setRequests] = useState(null);
    const [requestType, setRequestType] = useState('');
    const [loadding, setLoadding] = useState(false);
    const [additionalFields, setAdditionalFields] = useState({});
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [downloadOpen, setDownloadOpen] = useState(false);  // for confirmation dialog

    //  FLEX DIRECTION CLASSES 
    const flDir = isArabic ? 'flex-row-reverse' : 'flex-row'
    const tableBg = useColorModeValue('gray.50', 'gray.700');
    const sectionBg = useColorModeValue('gray.100', 'gray.800');
    const textColor = useColorModeValue('gray.700', 'gray.200');

    const getAllRequests = async () => {
        try {
            const res = await get(`request/all-employee-request/${user._id}`);
            if (res.statusCode === 200) {
                setRequests(res.data.requests)
            }
        } catch (error) {
            console.log(error);

        }
    }

    useEffect(() => {
        getAllRequests();
    }, [])


    const toggleModal = () => setModalOpen(!isModalOpen);

    const handleSubmitRequest = async () => {
        try {
            setLoadding(true);
            const body = {
                employeeId: user._id,
                requestType,
                status: user.role === 'COACH' ? "GAME-MANAGER-REVIEW" : "HR-MANAGER-REVIEW", //WHY IT IS STAIC TO NLY 2 ROLE 
                details: additionalFields
            }
            // console.log(body, 'body');
            const res = await post('request/employee-request', body);
            if (res.statusCode === 200) {
                setLoadding(false);
                setRequestType('');
                setAdditionalFields({});
                toggleModal();
                getAllRequests();
            }
        } catch (error) {
            setLoadding(false);
            console.log(error);
        }
    };

    // Function to generate PDF
    const generatePDF = () => {
        try {
            const doc = new jsPDF();

            // Title
            doc.setFontSize(16);
            doc.text("Employee Requests Report", 20, 20);

            // Employee Info
            doc.setFontSize(12);
            doc.text(`Employee: ${user?.name}`, 20, 30);
            doc.text(`Employee Code: ${user?.employeeCode}`, 20, 35);
            doc.text(`Designation: ${user?.designation}`, 20, 40);

            // Request Data Table
            doc.setFontSize(12);
            let y = 50;  // Starting y position for requests table
            const rowHeight = 10;

            // Table Header
            doc.text("Request Type", 20, y);
            doc.text("Request Date", 90, y);
            doc.text("Status", 130, y);
            y += rowHeight;

            // Add each request data as a row
            requests?.forEach((request) => {
                doc.text(request?.requestType || '--', 20, y);
                doc.text(request?.createdAt ? formatDate(request?.createdAt) : '--', 90, y);
                doc.text(request?.status || '--', 130, y);
                y += rowHeight;
            });

            // Save the PDF
            doc.save("requests_report.pdf");
        } catch (e) {
            console.log(`ERROR WHILE GENERATING PDF ${e}`);
        }
    };

    // Confirm download
    const confirmDownload = () => {
        try {
            generatePDF();
            setDownloadOpen(false);
        } catch (e) {
            console.log(`ERROR WHILE GENERATING PDF ${e}`);
        }
    };

    if (!requests) {
        return (
            <div className='h-[60vh] w-[60vw] flex justify-center items-center'>
                <Spinner />
            </div>
        )
    }


    return (
        <div className="w-full h-full p-4">
            {/* Header */}
            <div className={`flex ${flDir} justify-between items-center mb-8 flex-wrap`}>
                <Heading size="lg">{t('my-requests')}</Heading>
                <Flex flexDir={isArabic ? 'row-reverse' : 'row'} align="center" gap={2} wrap={'wrap'}>
                    {/* Confirmation Dialog */}
                    <ConfirmDownload
                        isOpen={downloadOpen}
                        setIsOpen={setDownloadOpen}
                        label='download-report'
                        handleDownload={() => {
                            setDownloadOpen(true);
                        }}
                        confirmDownload={() => {
                            confirmDownload();
                        }}
                        closeDialog={() => {
                            setDownloadOpen(false);
                        }}
                    />
                    <Button onClick={toggleModal} colorScheme='blue'>
                        {t('new-request')}
                    </Button>
                </Flex>
            </div>

            <Box bg={sectionBg} p={3} rounded="lg" mb={6} shadow="sm">
                <Flex flexDirection={isArabic ? 'row-reverse' : 'row'} gap={4} align="center">
                    <Avatar src={user?.pic} name={user?.name} size="lg" />
                    <Box>
                        <Text fontSize="lg" fontWeight="bold" color={textColor}>{user?.name}</Text>
                        <Text color="gray.500">{t('employee-code')}: {user?.employeeCode}</Text>
                        <Text color="gray.500">{t('designation')}: {user?.designation}</Text>
                    </Box>
                </Flex>
            </Box>


            {/* Request List */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {requests && requests?.map((request) => (
                    <div
                        key={request.id}
                        className="p-4 bg-white dark:bg-gray-800 shadow-md rounded-md transition duration-200 transform hover:scale-105"
                    >
                        <h2 className={`text-xl font-semibold text-gray-700 dark:text-gray-200 ${isArabic ? 'text-right' : 'text-left'}`}>
                            {t(request?.requestType?.toLowerCase()) || '--'}
                        </h2>
                        <div className={`flex items-center gap-1 ${flDir}`}>
                            <HiOutlineCalendarDays color='gray' />
                            <p className="text-gray-500 text-xs dark:text-gray-400 mt-2 flex items-center">
                                {request?.createdAt ? formatDateArEn(request?.createdAt, isArabic) : null}
                            </p>
                        </div>
                        <div className={`mt-4 flex justify-between items-center ${flDir}`}>
                            <span
                                className={`text-xs font-medium px-3 py-1 rounded-lg ${(request?.status === "REJECTED" || request?.status === "CANCELLED")
                                    ? 'bg-red-100 text-red-600'
                                    : request?.status === 'APPROVED'
                                        ? 'bg-green-100 text-green-600'
                                        : 'bg-yellow-100 text-yellow-600'
                                    }`}
                            >
                                {request?.status || '--'}
                            </span>
                            <span className="text-sm text-gray-400">
                                <RequestDetailsModal request={request} getAllRequests={getAllRequests} />
                            </span>
                        </div>
                    </div>
                ))}
            </div>

            {/* New Request Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3 p-6">
                        <h2 className={`text-2xl font-semibold text-gray-700 dark:text-gray-200 mb-4 ${isArabic ? 'text-right' : 'text-left'}`}>
                            {t('new-request')}
                        </h2>
                        <div className="mb-4">
                            <label className={`block text-gray-600 dark:text-gray-300 font-medium mb-2 ${isArabic ? 'text-right' : 'text-left'}`}>
                                {t('request-type')} <span className='text-red-500'>*</span>
                            </label>
                            <select
                                value={requestType}
                                onChange={(e) => {
                                    setRequestType(e?.target?.value);
                                    setAdditionalFields({})
                                }}
                                className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            >
                                <option value="" disabled hidden>
                                    {t('select-request-type')}
                                </option>
                                {requestTypeOptions && requestTypeOptions?.map((rType, index) => (
                                    <option key={index} value={rType?.value}>{t(rType?.label)}</option>
                                ))}
                            </select>

                        </div>

                        {/* Pass values to RequestBody */}
                        <RequestBody
                            requestType={requestType}
                            additionalFields={additionalFields}
                            setAdditionalFields={setAdditionalFields}

                        />

                        <div className="flex justify-end space-x-4 mt-4">
                            <button
                                onClick={toggleModal}
                                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-md"
                            >
                                {t('cancel')}
                            </button>
                            <button
                                disabled={loadding}
                                onClick={handleSubmitRequest}
                                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                            >
                                {loadding ? <Spinner /> : t('submit')}
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default Request;

import React from 'react'
import { Routes, Route } from "react-router-dom";
import CommanNav from '../components/globles/CommanNav';
import InventoryHome from '../pages/inventory/InventoryHome';
import Supplier from '../pages/inventory/Supplier';
import Category from '../pages/inventory/Category';
import Warehouse from '../pages/inventory/Warehouse';
import Product from '../pages/inventory/Product';
import SalesLedger from '../pages/inventory/SalesLedger';
import Expense from '../pages/inventory/Expense';
import Request from '../pages/game-management/Requests';

const Inventory = () => {
  return (
    <Routes>
        <Route path="/inventory" element={<CommanNav />}>
            <Route index element={<Category />} />
            <Route path="supplier" element={<Supplier />} />
            <Route path="category" element={<Category />} />
            <Route path="warehouse" element={<Warehouse />}>
              <Route path="products" element={<Category />} />
            </Route>
            <Route path="product" element={<Product />} />
            <Route path="sales-ledger" element={<SalesLedger />} />
            <Route path="expense" element={<Expense />} />
            <Route path="request" element={<Request />} />
        </Route>
    </Routes>
  )
}

export default Inventory
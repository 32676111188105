import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import { InfoIcon } from '@chakra-ui/icons';
import { get } from '../../services/apis/api';
import { ExpensesCardSkeleton } from '../../components/skeletons/Skeletons';
import { useNavigate } from 'react-router-dom';
import PastTransactions from '../../components/account/PastTransactions';

// CATEGORY CARD COLORS 
const cardColors = [
    '#e1f5fe', // Light cyan
    '#ffebee', // Light red
    '#c8e6c9', // Light green
    '#fff9c4', // Light blue,
    '#ffe0b2',
    '#f0f4c3'
]

const Expenses = () => {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [expensesData, setExpensesData] = React.useState(null);
    const navigate = useNavigate();

    // EXPENSE CARD 
    const ExpenseCard = ({ expense, color }) => {
        return (
            <div
                onClick={() => {
                    if (expense?.kind?.includes('additional-expenses')) {
                        navigate(`/account/additional-expenses`);
                    } else {
                        navigate(`/account/expenses/${expense?.kind}`)
                    }
                }}
                style={{ backgroundColor: color }}
                className={` shadow-md hover:scale-105 transition-all duration-300  flex flex-col ${isArabic ? 'items-end justify-end ' : 'items-start justify-start'} gap-2 w-full md:w-[49%] lg:w-[32%] py-6 px-8 rounded-md mb-4`}
            >
                <div className={`flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} items-center  gap-2`}>
                    <InfoIcon w={3} color="gray.500" />
                    <h6 className="text-sm whitespace-nowrap  lg:text-xs text-gray-500 font-medium capitalize">{t(expense?.label)}</h6>
                </div>
                <h4 className="text-xl lg:text-3xl font-bold">
                    {expense?.value?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </h4>
                {/* <div className="w-full flex items-center  gap-2">
                <InfoIcon w={3} color="gray.500" />
                <h6 className="text-sm whitespace-nowrap lg:text-xs text-gray-600 font-medium"><span className={`${expense?.change > 0 ? 'text-green-500' : 'text-red-500'}`}>{expense?.change > 0 ? '+' : '-'}{Math.abs(expense?.change)}</span> from last week</h6>
            </div> */}
            </div>
        )
    }

    // GET ALL EXEPENSE DATA 
    const getTotalExpenses = async () => {
        try {
            // console.log('data');
            const response = await get(`expenses/get-total`)
            if (response?.statusCode == 200) {
                // console.log(response, 'response');
                setExpensesData(response?.data?.data);
            } else {
                setExpensesData([]);
            }
        } catch (e) {
            setExpensesData([]);
            console.log(`ERROR WHILE FETCHING DATA:${e}`);
        }
    }

    useEffect(() => {
        getTotalExpenses();
    }, [])

    return (
        <>
            <div className='w-full flex-1 flex flex-col p-2 lg:p-4'>
                <div className={`flex justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                    <h2 className='text-xl whitespace-nowrap font-bold mb-4'>{t("expenses")}</h2>
                </div>
                <div className="w-full flex flex-wrap justify-start py-4 gap-2 lg:gap-4 items-start">
                    {expensesData ? expensesData?.map((expense, index) => (
                        <ExpenseCard
                            key={index}
                            expense={expense}
                            color={cardColors[index % cardColors.length]}
                        />
                    )) : <ExpensesCardSkeleton />}
                </div>
                <PastTransactions
                    txnType={'DR'}
                />
            </div>
        </>
    )
}

export default Expenses

import React from 'react'
import { Routes, Route } from "react-router-dom";
import CommanNav from '../components/globles/CommanNav';
import Clubs from '../pages/operations/Clubs';
import Home from '../pages/operations/Home';

const Admin = () => {
  return (
    <Routes>
        <Route path="/admin" element={<CommanNav />}>
            <Route index element={<Home />} />
            <Route path="clubs" element={<Clubs />} />
        </Route>
    </Routes>
  )
}

export default Admin
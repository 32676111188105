import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Text,
    Image,
    Stack,
    Badge,
    Flex,
    Heading,
    SimpleGrid,
    useColorModeValue,
    HStack,
} from "@chakra-ui/react";
import CreateProduct from "../dialogueBoxes/CreateProduct";
import { get } from "../../services/apis/api";
import Carousel from "../globles/CorouselSlider";

const ViewProduct = ({ product: initialProduct, setViewProduct }) => {
    const [product, setProduct] = useState(initialProduct);
    const [warehouses, setWarehouses] = useState([]);

    const getProduct = async () => {
        try {
            const res = await get(`product/get/${product._id}`);
            setProduct(res.data.data); // Update the state with the new product data
        } catch (error) {
            console.error("Failed to fetch products:", error);
        }
    };

    const getWarehouses = async () => {
        try {
            const res = await get(`product/get-warehouses/${product._id}`);
            setWarehouses(res.data.warehouses);
        } catch (error) {
            console.error("Failed to fetch warehouses:", error);
        }
    };

    useEffect(() => {
        getWarehouses();
    }, [product._id]);

    return (
        <Box p={6} maxW="7xl" mx="auto">
            {/* Back Button */}
            <Flex justifyContent="space-between" py={4}>
                <Button
                    onClick={() => setViewProduct(false)}
                    bg="blue.600"
                    color="white"
                    _hover={{ bg: "blue.500" }}
                >
                    Back
                </Button>
                <div className="flex items-center">
                    <CreateProduct
                        getAllProducts={getProduct}
                        getWarehouses={getWarehouses}
                        product={product}
                        isEdit={true}
                    />
                </div>
            </Flex>

            {/* Product Details */}
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                {/* Product Images */}
                <Stack spacing={4}>
                    <Carousel urls={product.images}/>
                    {/* {product.images.map((img, index) => (
                        <Image
                            key={index}
                            src={img}
                            alt={product.name}
                            borderRadius="md"
                            objectFit="cover"
                            height={"500px"}
                        />
                    ))} */}
                </Stack>

                {/* Product Information */}
                <Stack spacing={4}>
                    <Heading as="h1" size="xl">
                        {product.name}
                    </Heading>
                    <Text style={{ color: "gray.700" }}>
                        Category: {product?.category?.name}
                    </Text>
                    <Text color={useColorModeValue("gray.700", "gray.400")}>
                        {product.description}
                    </Text>

                    <Text
                        color={
                            product.totalAvailableStock > 0
                                ? "green.500"
                                : "red.500"
                        }
                        fontWeight="medium"
                    >
                        {product.totalAvailableStock > 0
                            ? "In Stock"
                            : "Out of Stock"}
                    </Text>
                    {product.type ? (
                        <div>
                            <Flex wrap="wrap">
                                {product.type.map((type, index) => (
                                    <Badge
                                        key={index}
                                        m={1}
                                        px={2}
                                        py={1}
                                        colorScheme="teal"
                                    >
                                        {type}
                                    </Badge>
                                ))}
                            </Flex>
                        </div>
                    ) : (
                        <div />
                    )}
                    {product.customizable ? (
                        <Text fontWeight={"600"}>
                            This Product is Customizable with a cost of SAR{product.customizationCost}
                        </Text>
                    ) : (
                        <></>
                    )}
                </Stack>
            </SimpleGrid>

            {/* Warehouses Information */}
            <Box mt={6}>
                <Heading as="h2" size="lg" mb={4}>
                    Warehouses
                </Heading>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                    {warehouses.map((warehouse) => (
                        <Box
                            key={warehouse._id}
                            borderWidth="1px"
                            borderRadius="md"
                            p={4}
                            // bg={"blue.100"}
                        >
                            <Heading as="h3" size="md">
                                {warehouse.warehouseId.name}
                            </Heading>
                            <Text mt={2}>
                                Location: {warehouse.warehouseId.location}
                            </Text>
                            <Text mt={2}>
                                Type: {warehouse.warehouseId.type}
                            </Text>
                            <Text fontWeight={"600"} mt={2}>
                                Price: SAR{warehouse.price}
                            </Text>
                            {warehouse.sizes.map((e) => (
                                <HStack>
                                    {" "}
                                    <Text mt={2}>
                                        Size: {e.size} & Quantity: {e.quantity}
                                    </Text>
                                    <Box
                                        mt={2}
                                        width="40px"
                                        height="20px"
                                        backgroundColor={e.color}
                                        borderRadius="md"
                                        border="1px solid"
                                        borderColor="gray.200"
                                    ></Box>
                                </HStack>
                            ))}
                        </Box>
                    ))}
                </SimpleGrid>
            </Box>
        </Box>
    );
};

export default ViewProduct;

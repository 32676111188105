import { Button, FormControl, FormLabel, Input, SimpleGrid, GridItem, useToast, Select, Spinner } from '@chakra-ui/react';
import React, { useState, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { post, postFormData } from '../../services/apis/api';  // Make sure the path is correct
import { useTranslation } from 'react-i18next';

const ProfessionalPlayerVisaRequest = ({ isOpen, onClose }) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

  // State to hold form values
  const [formValues, setFormValues] = useState({
    status: 'GAME-SECRETORY-REVIEW',
    name: '',
    passportNumber: '',
    passportType: 'Normal',
    dateOfBirth: '',
    passportExpirationDate: '',
    passportIssuanceDate: '',
    passportIssuancePlace: '',
    placeOfBirth: '',
    age: '',
    countryOfArrival: '',
    estimatedArrivalTime: '',
    nationality: '',
    recentPersonalPhoto: '',
    categoryToPlay: '',
    contractAmount: '',
    previousClubClearance: '',
    approvalUndertaking: '',
    attachments: {
      passport: null,
      personalPhoto: null,
      clearance: null,
      iqamaOrVisa: null,
      asianCertificate: null,
      internationalCertificate: null,
      federationContract: null,
    },
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // Handle nested input change for attachments
  const handleNestedChange = (e) => {
    const { name, files } = e.target;
    setFormValues({
      ...formValues,
      attachments: { ...formValues.attachments, [name]: files[0] }
    });
  };

  // Handle form save
  const handleSave = async () => {
    const formData = new FormData();
    setLoading(true);
    for (const key in formValues) {
      if (key === 'attachments') {
        for (const fileKey in formValues.attachments) {
          if (formValues.attachments[fileKey]) {
            formData.append(fileKey, formValues.attachments[fileKey]);
          }
        }
      } else {
        formData.append(key, formValues[key]);
      }
    }

    try {
      console.log(formData);
      const res = await postFormData('request/professional-player-visa', formData);
      console.log(res);
      if(res.statusCode === 200){
        onClose();
        setLoading(false);
        toast({
            title: 'Successful!',
            description: res.data.message,
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
      }

    } catch (error) {
      setLoading(false);
      console.error(error);
      if(error.response.status === 500){
        toast({
          title: 'Error!',
          description: "Please fill all the fields correctly",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      maxH={"100vh"}
      overflowY={'scroll'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('create-professional-player-visa-request')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={4} dir={isArabic ? 'rtl' : 'ltr'}>
            <GridItem>
              <FormControl>
                <FormLabel>{t('name')}</FormLabel>
                <Input
                  name="name"
                  value={formValues.name}
                  onChange={handleChange}
                  placeholder={t('name')}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('passport-number')}</FormLabel>
                <Input
                  name="passportNumber"
                  value={formValues.passportNumber}
                  onChange={handleChange}
                  placeholder={t('passport-number')}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('passport-type')}</FormLabel>
                
                <Select
                  name="passportType"
                  value={formValues.passportType}
                  onChange={handleChange}
                  placeholder={t('passport-type')}
                  textAlign={isArabic ? 'center' : 'left'}
                >
                    <option value="Normal" >{t('normal')}</option>
                    <option value="Diplomatic" >{t('diplomatic')}</option>
                    <option value="Private" >{t('private')}</option>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('date-of-birth')}</FormLabel>
                <Input
                  name="dateOfBirth"
                  type="date"
                  value={formValues.dateOfBirth}
                  onChange={handleChange}
                  placeholder={t('date-of-birth')}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('passport-expiration-date')}</FormLabel>
                <Input
                  name="passportExpirationDate"
                  type="date"
                  value={formValues.passportExpirationDate}
                  onChange={handleChange}
                  placeholder={t('passport-expiration-date')}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('passport-issuence-date')}</FormLabel>
                <Input
                  name="passportIssuanceDate"
                  type="date"
                  value={formValues.passportIssuanceDate}
                  onChange={handleChange}
                  placeholder={t('passport-issuence-date')}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('passport-issuence-place')}</FormLabel>
                <Input
                  name="passportIssuancePlace"
                  value={formValues.passportIssuancePlace}
                  onChange={handleChange}
                  placeholder={t('passport-issuence-place')}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('place-of-birth')}</FormLabel>
                <Input
                  name="placeOfBirth"
                  value={formValues.placeOfBirth}
                  onChange={handleChange}
                  placeholder={t('place-of-birth')}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('age')}</FormLabel>
                <Input
                  name="age"
                  type="number"
                  value={formValues.age}
                  onChange={handleChange}
                  placeholder={t('age')}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('country-of-arrival')}</FormLabel>
                <Input
                  name="countryOfArrival"
                  value={formValues.countryOfArrival}
                  onChange={handleChange}
                  placeholder={t('country-of-arrival')}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('estimated-arrival-time')}</FormLabel>
                <Input
                  name="estimatedArrivalTime"
                  type="date"
                  value={formValues.estimatedArrivalTime}
                  onChange={handleChange}
                  placeholder={t('estimated-arrival-time')}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('nationality')}</FormLabel>
                <Input
                  name="nationality"
                  value={formValues.nationality}
                  onChange={handleChange}
                  placeholder={t('nationality')}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('recent-personal-photo')}</FormLabel>
                <Input
                  name="recentPersonalPhoto"
                  value={formValues.recentPersonalPhoto}
                  onChange={handleChange}
                  placeholder={t('recent-personal-photo')}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('category-to-play')}</FormLabel>
                <Input
                  name="categoryToPlay"
                  value={formValues.categoryToPlay}
                  onChange={handleChange}
                  placeholder={t('category-to-play')}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('contract-amount')}</FormLabel>
                <Input
                  name="contractAmount"
                  type="number"
                  value={formValues.contractAmount}
                  onChange={handleChange}
                  placeholder={t('contract-amount')}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('previous-club-clearance')}</FormLabel>
                <Input
                  name="previousClubClearance"
                  value={formValues.previousClubClearance}
                  onChange={handleChange}
                  placeholder={t('previous-club-clearance')}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('approval-undertaking')}</FormLabel>
                <Input
                  name="approvalUndertaking"
                  value={formValues.approvalUndertaking}
                  onChange={handleChange}
                  placeholder={t('approval-undertaking')}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('attechment-passport')}</FormLabel>
                <Input
                  className='flex items-center'
                  type='file'
                  name="passport"
                  onChange={handleNestedChange}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('attechment-personal-photo')}</FormLabel>
                <Input
                  type="file"
                  name="personalPhoto"
                  onChange={handleNestedChange}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('attechment-clearance')}</FormLabel>
                <Input
                  type="file"
                  name="clearance"
                  onChange={handleNestedChange}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('attechment-iqama-or-visa')}</FormLabel>
                <Input
                  type='file'
                  name="iqamaOrVisa"
                  onChange={handleNestedChange}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('attechment-asian-certificate')}</FormLabel>
                <Input
                  type="file"
                  name="asianCertificate"
                  onChange={handleNestedChange}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('attechment-international-certificate')}</FormLabel>
                <Input
                  type='file'
                  name="internationalCertificate"
                  onChange={handleNestedChange}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t('attechment-federation-contract')}</FormLabel>
                <Input
                  type='file'
                  name="federationContract"
                  onChange={handleNestedChange}
                />
              </FormControl>
            </GridItem>
          </SimpleGrid>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>{t('cancel')}</Button>
          <Button colorScheme="blue"  onClick={handleSave}>
            {loading ? <Spinner /> : t("Save")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProfessionalPlayerVisaRequest;

import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box, Flex, Button, Tabs, TabList, Tab, Input, IconButton, InputGroup, InputLeftElement, TabPanels, TabPanel, Table, Thead, Tbody, Tr, Th, Td, Image, Text } from "@chakra-ui/react";
import { SearchIcon, DownloadIcon } from "@chakra-ui/icons";
import CreateEmployee from '../../components/hr/dialogBoxes/CreateEmployee';
import CreateMultipleEmployee from '../../components/hr/dialogBoxes/CreateMultipleEmployee';
import { get } from '../../services/apis/api';
import { formatDateTime } from '../../utills/formateDate';
import EmployeeDetails from '../../components/hr/components/addEmployee/EmployeeDetails';

// Utility function to convert data to CSV format
const convertToCSV = (data) => {
  const headers = ['Name', 'Employee Code', 'Gender', 'Designation', 'Mobile Number', 'Date of Joining', 'Email'];
  
  const rows = data.map((employee) => [
    employee.name,
    employee.employeeCode,
    employee.gender,
    employee.designation,
    employee.mobile,
    formatDateTime(employee.joiningDate, 'date'),
    employee.email
  ]);

  const csvContent = [headers, ...rows].map((e) => e.join(",")).join("\n");
  return csvContent;
};

const EmployeeTable = ({ employees, page, totalPages, handlePageChange, setShowDetails, setSelectedEmployee }) => (
    <>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <Table className="w-full text-sm text-left text-gray-500">
          <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <Tr>
              <Th>Name</Th>
              <Th>Employee Code</Th>
              <Th>Gender</Th>
              <Th>Designation</Th>
              <Th>Mobile Number</Th>
              <Th>Date of Joining</Th>
            </Tr>
          </Thead>
          <Tbody>
            {employees.map(employee => (
              <Tr key={employee.employeeCode} onClick={()=>{setShowDetails(true); setSelectedEmployee(employee)}} className="bg-white border-b hover:bg-gray-50 cursor-pointer">
                <Td className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                  <Image className="w-10 h-10 rounded-full" src={employee.pic} alt="Employee image" />
                  <Box ml={3}>
                    <Text className="text-base font-semibold">{employee.name}</Text>
                    <Text className="font-normal text-gray-500">{employee.email}</Text>
                  </Box>
                </Td>
                <Td>{employee.employeeCode}</Td>
                <Td>{employee.gender}</Td>
                <Td>{employee.designation}</Td>
                <Td>{employee.mobile}</Td>
                <Td>{formatDateTime(employee.joiningDate, 'date')}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
      <Flex justify="space-between" align="center" mt={4}>
        <Button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          Previous
        </Button>
        <Text>Page {page} of {totalPages}</Text>
        <Button
          onClick={() => handlePageChange(page + 1)}
          disabled={page >= totalPages}  
        >
          Next
        </Button>
      </Flex>
    </>
);
  

const Employee = () => {
    const [employees, setEmployees] = useState([]);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalEmployees, setTotalEmployees] = useState(0);
    const [status, setStatus] = useState('ACTIVE');
    const [showDetails, setShowDetails] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState();

    const getEmployees = async () => {
        try {
        const res = await get(`employee/all?page=${page}&limit=10&name=${search}&status=${status}`);
        setEmployees(res.data.data);
        setTotalPages(res.data.pages);
        setTotalEmployees(res.data.total,); 
        } catch (error) {
        console.error(error);
        }
    };

    useEffect(() => {
        getEmployees();
    }, [page, search, status]);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPage(1); 
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const handleTabChange = (index) => {
        const statuses = ['ACTIVE', 'PIP', 'EEP'];
        setStatus(statuses[index]);
        setPage(1); 
    };

    const downloadCSV = () => {
        const csvContent = convertToCSV(employees);

        // Create a blob with the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create a link element
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'employees.csv');
        link.style.visibility = 'hidden';

        // Append link to body and initiate download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

  return (
    <>
        {
            showDetails ? 
                <EmployeeDetails setShowDetails={setShowDetails} selectedEmployee={selectedEmployee} /> :
                    <Box p={4}>
                        <Flex justify="space-between" align="center" flexWrap="wrap" mb={4}>
                        <Box fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
                            Employees ({totalEmployees})
                        </Box>
                        <Flex flexWrap="wrap" justify={{ base: "center", md: "flex-end" }} w={{ base: "100%", md: "auto" }}>
                            <InputGroup mr={2} mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "auto" }}>
                              <InputLeftElement pointerEvents="none">
                                  <SearchIcon color="gray.300" />
                              </InputLeftElement>
                              <Input
                                  type="text"
                                  placeholder="Search"
                                  value={search}
                                  onChange={handleSearchChange}
                              />
                            </InputGroup>
                            <CreateEmployee />
                            <CreateMultipleEmployee getEmployees={getEmployees} />
                            <Button 
                              leftIcon={<DownloadIcon />} 
                              colorScheme="gray" 
                              variant="outline" 
                              mb={{ base: 2, md: 0 }}
                              onClick={downloadCSV} 
                            >
                              Download CSV
                            </Button>
                        </Flex>
                        </Flex>
                        <Tabs colorScheme="blue" onChange={handleTabChange}>
                        <TabList>
                            <Tab>ACTIVE</Tab>
                            <Tab>PIP</Tab>
                            <Tab>EEP</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel px={'0px'} py={'16px'}>
                            <EmployeeTable
                                employees={employees}
                                page={page}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                                setShowDetails={setShowDetails}
                                setSelectedEmployee={setSelectedEmployee}
                            />
                            </TabPanel>
                            <TabPanel px={'0px'} py={'16px'}>
                            <EmployeeTable
                                employees={employees}
                                page={page}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                                setShowDetails={setShowDetails}
                                setSelectedEmployee={setSelectedEmployee}
                            />
                            </TabPanel>
                            <TabPanel px={'0px'} py={'16px'}>
                            <EmployeeTable
                                employees={employees}
                                page={page}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                                setShowDetails={setShowDetails}
                                setSelectedEmployee={setSelectedEmployee}
                            />
                            </TabPanel>
                        </TabPanels>
                        </Tabs>
                    </Box>
        }
    </>
  );
};

export default Employee;

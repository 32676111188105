import {
  Button,
  Checkbox,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Image,
  Box,
  Spinner,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import logo from '../../assets/images/logo-rbg.png';
import player from '../../assets/images/playerImage.png';
import { useEffect, useState } from 'react';
import { get, post } from '../../services/apis/api';
import { useToast } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { FaRegEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { UserState } from '../../context/user';
import colors from '../../utills/appTheme';

const Login = () => {
  const toast = useToast();
  const { user, setUser } = UserState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const roles = ["EXTERNAL USER", "GUARDIAN", "ALRWAAD USER"]

  
  const handleLogin = async () => {
    try {
      setLoading(true);
      const body = { userName: email, password, device: "desktop" };
      const res = await post('user/login', body);
      if (res.statusCode === 200 && !roles.includes(res.data.user.role)) {
        setLoading(false);
        toast({
          title: 'Successful!',
          description: res.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setUser(res.data.user);
        localStorage.setItem('token', JSON.stringify(res.data.token));
        if (!res.data.user.organizationId) {
          navigate('/create-club');
          return;
        }
        goToUSerDashBoard(res.data.user);
      } else {
        setLoading(false);
        if (roles.includes(res.data.user.role)) {
          toast({
            title: 'Error Occurred',
            description: "Login not available on the web. Please download our mobile app to access your account.",
            status: 'error',
            duration: 7000,
            isClosable: true,
          });
        } else {
          toast({
            title: 'Error Occurred',
            description: "Some error occurred",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
      console.log(res);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast({
        title: 'Error Occurred',
        description: error.response?.data?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const goToUSerDashBoard = (user) => {

    if (user.role === 'SUPER-ADMIN') {
      navigate('/dashboard');
    } else if (user.role.includes('HR')) {
      navigate('/hr');
    } else if (user.role.includes('ACCOUNT')) {
      navigate('/account');
    } else if (user.role.includes('STORE')) {
      navigate('/inventory');
    } else if (user.role.includes('MEDIA')) {
      navigate('/media-center');
    } else if (user.role.includes('OPERATION')) {
      navigate('/operation');
    } else if (user.role.includes('GAME')) {
      navigate('/game-management');
    } else if (user.role.includes('ORGANIZER')) {
      navigate('/admin');
    } else if (user.role.includes('ALRWAAD')){
      navigate('/operation');
    }else if (user.role.includes('CLINIC')){
      navigate('/clinic');
    }else if (user.role.includes('EMPLOYEE')){
      navigate('/employee');
    }else if (user.role.includes('COACH')){
      navigate('/employee');
    }else if (user.role.includes('INTERNAL USER')){
      navigate('/employee');
    }
    return;
  }

  const getUserDetails = async () => {
    try {
      const res = await get('user/get');
      if (res.statusCode === 200) {
        setUser(res.data.user);
        goToUSerDashBoard(res.data.user);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUserDetails();
  }, []);

  if (user) {
    return (
      <div className='w-screen h-screen flex justify-center items-center'>
        <Spinner size={"xl"} />
      </div>
    )
  }


  return (
    <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <Stack spacing={4} w={'full'} maxW={'md'}>
          <div className='w-full flex justify-center items-center'>
            <img className='h48 w-48' src={logo} />
          </div>
          <div className='flex justify-center mb-5'>
            <Heading fontSize={'2xl'}>Sign in to your account</Heading>
          </div>
          <FormControl id="email">
            <FormLabel>User name or email address</FormLabel>
            <Input
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyDown}
              type="email"
              placeholder='Enter email & userName'
            />
          </FormControl>
          <FormControl id="password">
            <FormLabel>Password</FormLabel>
            <InputGroup size='md'>
              <Input
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
                pr='4.5rem'
                type={show ? 'text' : 'password'}
                placeholder='Enter password'
              />
              <InputRightElement>
                <Button variant={'ghost'} h='1.75rem' size='sm' onClick={handleClick}>
                  {show ? <FaEyeSlash /> : <FaRegEye />}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Stack spacing={6}>
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              align={'start'}
              justify={'space-between'}>
              <Checkbox>Remember me</Checkbox>
              <Text color={colors.primary}>Forgot password?</Text>
            </Stack>
            <button
              onClick={handleLogin}
              className="bg-secondary font-semibold text-white px-4 py-2 rounded-sm bg=primary"
            >
              {loading ? <Spinner /> : "Sign In"}
            </button>
          </Stack>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image
          alt={'Login Image'}
          objectFit={'cover'}
          src={player}
        />
      </Flex>
    </Stack>
  );
};

export default Login;

import React from "react";
import { formatDateTime } from "../../utills/formateDate";
import CreateCoach from "../dialogueBoxes/CreateCoach";
import UpdatePassword from "../operations/dialogBoxes/Updatepassword";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Heading,
    Text,
    Avatar,
    Badge,
    Flex,
    Grid,
    Divider,
    Icon,
} from "@chakra-ui/react";
import {
    FaUser,
    FaCalendar,
    FaMapMarkerAlt,
    FaEnvelope,
    FaPhone,
    FaGamepad,
} from "react-icons/fa";

const ViewCoachProfile = ({ coach, setViewCoach, getAllCoaches }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    return (
        <Box className="p-6">
            <Flex
                justify="space-between"
                align="center"
                mb={6}
                flexDir={isArabic ? "row-reverse" : "row"}
            >
                <Button
                    leftIcon={!isArabic && <Icon as={FaUser} />}
                    rightIcon={isArabic && <Icon as={FaUser} />}
                    colorScheme="blue"
                    onClick={() => setViewCoach(false)}
                >
                    {t("back")}
                </Button>
                <Flex gap={4}>
                    <UpdatePassword userId={coach._id} />
                    <CreateCoach
                        getAllCoaches={getAllCoaches}
                        coach={coach}
                        isEdit={true}
                    />
                </Flex>
            </Flex>

            <Box dir={isArabic ? "rtl" : "ltr"}>
                <Grid
                    templateColumns={{ base: "1fr", md: "300px 1fr" }}
                    gap={6}
                >
                    {/* Profile Card */}
                    <Box>
                        <Box
                            bg="white"
                            rounded="lg"
                            shadow="base"
                            overflow="hidden"
                            borderTop="4px"
                            borderColor="blue.500"
                        >
                            <Box p={6} textAlign="center">
                                <Avatar
                                    size="2xl"
                                    src={
                                        coach.pic ||
                                        "https://lavinephotography.com.au/wp-content/uploads/2017/01/PROFILE-Photography-112.jpg"
                                    }
                                    mb={4}
                                />
                                <Heading size="md" mb={2}>
                                    {coach.name}
                                </Heading>
                                <Text color="gray.600" mb={2}>
                                    <Icon as={FaGamepad} mr={2} />
                                    {coach?.gameId?.name}
                                </Text>
                                <Text color="gray.500" fontSize="sm">
                                    <Icon as={FaMapMarkerAlt} mr={2} />
                                    {coach?.city}
                                </Text>
                            </Box>

                            <Divider />

                            <Box p={4} bg="gray.50">
                                <Flex
                                    justify="space-between"
                                    align="center"
                                    mb={3}
                                >
                                    <Text fontWeight="medium">
                                        {t("status")}
                                    </Text>
                                    <Badge
                                        colorScheme="green"
                                        rounded="full"
                                        px={3}
                                    >
                                        {t("active")}
                                    </Badge>
                                </Flex>
                                <Flex justify="space-between" align="center">
                                    <Text fontWeight="medium">
                                        {t("member-since")}
                                    </Text>
                                    <Text fontSize="sm" color="gray.600">
                                        {formatDateTime(
                                            coach?.createdAt,
                                            "date"
                                        )}
                                    </Text>
                                </Flex>
                            </Box>
                        </Box>
                    </Box>

                    {/* Details Section */}
                    <Box>
                        <Box bg="white" rounded="lg" shadow="base" p={6}>
                            <Flex align="center" mb={6}>
                                <Icon
                                    as={FaUser}
                                    boxSize={5}
                                    color="blue.500"
                                    mr={2}
                                />
                                <Heading size="md">{t("about")}</Heading>
                            </Flex>

                            <Grid
                                templateColumns={{
                                    base: "1fr",
                                    md: "repeat(2, 1fr)",
                                }}
                                gap={6}
                                className={
                                    isArabic ? "text-right" : "text-left"
                                }
                            >
                                <InfoItem
                                    label={t("name")}
                                    value={coach.name}
                                />
                                <InfoItem
                                    label={t("user-name")}
                                    value={coach?.userName}
                                />
                                <InfoItem
                                    label={t("gender")}
                                    value={coach?.gender}
                                />
                                <InfoItem
                                    label={t("specialization")}
                                    value={coach?.specialization}
                                />
                                <InfoItem
                                    label={t("experience")}
                                    value={coach?.experience}
                                />
                                <InfoItem
                                    label={t("contact-no")}
                                    value={coach?.mobile}
                                    icon={FaPhone}
                                />
                                <InfoItem
                                    label={t("email")}
                                    value={coach?.email}
                                    icon={FaEnvelope}
                                    isLink
                                />
                                <InfoItem
                                    label={t("date-of-birth")}
                                    value={
                                        coach?.dateOfBirth
                                            ? formatDateTime(
                                                  coach?.dateOfBirth,
                                                  "date"
                                              )
                                            : "-"
                                    }
                                    icon={FaCalendar}
                                />
                                <InfoItem
                                    label={t("current-address")}
                                    value={coach?.currentAddress}
                                    icon={FaMapMarkerAlt}
                                    colSpan={2}
                                />
                                <InfoItem
                                    label={t("permanent-address")}
                                    value={coach?.permanentAddress}
                                    icon={FaMapMarkerAlt}
                                    colSpan={2}
                                />
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Box>
        </Box>
    );
};

// Helper component for info items
const InfoItem = ({ label, value, icon, isLink, colSpan }) => {
    const IconComponent = icon;
    return (
        <Box gridColumn={colSpan ? `span ${colSpan}` : "auto"}>
            <Flex align="center" mb={1}>
                {IconComponent && (
                    <Icon as={IconComponent} color="gray.500" mr={2} />
                )}
                <Text fontSize="sm" color="gray.600" fontWeight="medium">
                    {label}
                </Text>
            </Flex>
            {isLink ? (
                <a
                    href={`mailto:${value}`}
                    className="text-blue-600 hover:text-blue-800"
                >
                    {value || "-"}
                </a>
            ) : (
                <Text fontSize="md" color="gray.900">
                    {value || "-"}
                </Text>
            )}
        </Box>
    );
};

export default ViewCoachProfile;

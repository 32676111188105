import { useTranslation } from "react-i18next";
import { UserState } from "../../context/user";
import { useEffect, useState } from "react";
import { Th, Table, TableContainer, Thead, Tr, Td, Tbody, Button, useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import { get } from "../../services/apis/api";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs";
import ClinicTable from "../../components/clinic/ClinicTable";

// TABLE COLUMNS 
const clinicRequestColumns = [
    "player-info",
    'request-id',
    "injury",
    "appointment-date",
    "status",
    "recovery-status",
    // "payment",
    "action"
];

const Appointments = () => {
    const navigate = useNavigate();
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [searchTerm, setSearchTerm] = useState('');
    const [appoinments, setAppoinments] = useState([]);
    const [request, setRequest] = useState(null)
    const [totalPages, setTotalPages] = useState(1);
    const [totalRequests, setTotalRequests] = useState(0);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [page, setPage] = useState(0);

    // GET ALL AVAILABLE REQUESTS 
    const getAllAppoinments = async () => {
        try {
            let res;
            if (user) {
                const res = await get(`clinic/all/clinic-appointments`)
                if (res.statusCode === 200) {
                    setAppoinments(res?.data?.appointments || []);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAllAppoinments();
    }, [])

    // HANDLE PAGE CHANGE FUNCTION 
    const handlePageChange = (newPage) => {
        try {
            if (newPage >= 0 && newPage <= totalPages) {
                setPage(newPage);
                // getAllRequest(newPage * limit, limit)
            }
        } catch (e) {
            console.log(`ERROR WHILE CHANGING PAGE ${e}`);
        }
    };
    return (
        <>
            <BreadCrumbs />
            <div className='w-full flex-1 flex flex-col p-2'>
                <div className={`flex     justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                    <h2 className='text-xl whitespace-nowrap font-bold mb-4'>{t("appointments")}</h2>
                    <div className='hidden w-full justify-between lg:w-fit items-center'>
                        <div className="relative">
                            <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input
                                type="text"
                                id="table-search-users"
                                className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                                placeholder={t("search-request")}
                                value={searchTerm}
                                onChange={(e) => { setSearchTerm(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <div className='w-full flex-1 flex flex-col '>
                    {/* <RequestList /> */}
                    <ClinicTable
                        rows={appoinments}
                        totalPages={totalPages}
                        columns={clinicRequestColumns}
                        page={page}
                        handlePageChange={(newPage) => {
                            handlePageChange(newPage);
                        }}
                        onSchedule={(request) => {
                            setRequest(request);
                            onOpen();
                        }}
                        onNavigate={(appoinment) => {
                            navigate(`/clinic/appointment/${appoinment?._id}`);
                        }}
                    />
                </div>
            </div>
        </>
    );
}
export default Appointments;
import React, { useState, useRef } from 'react';
import { Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, useToast } from '@chakra-ui/react';
import { del, deletethis } from '../../services/apis/api'; // Assuming `del` is the method for making delete API calls
import { useTranslation } from 'react-i18next';

const DeleteAlRwaadService = ({ alRwaadService, getAlRwaadServices }) => {
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const cancelRef = useRef();
    const toast = useToast();
    const { t } = useTranslation();

    const handleDeleteClick = () => {
        setIsDeleteOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await deletethis(`al-rwaad/delete/${alRwaadService._id}`); // Assuming _id is the service ID
            toast({
                title: t("Service deleted"),
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            getAlRwaadServices(); // Refresh the list after deletion
        } catch (error) {
            console.error(error);
            toast({
                title: t("Failed to delete service"),
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsDeleteOpen(false);
        }
    };

    return (
        <>
            <Button colorScheme="red" onClick={handleDeleteClick}>
                {t("delete")}
            </Button>

            {/* Confirm Delete Dialog */}
            <AlertDialog
                isOpen={isDeleteOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => setIsDeleteOpen(false)}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {t("Delete Service")}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t("Are you sure you want to delete this service? This action cannot be undone.")}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => setIsDeleteOpen(false)}>
                                {t("Cancel")}
                            </Button>
                            <Button colorScheme="red" onClick={handleDeleteConfirm} ml={3}>
                                {t("Delete")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export default DeleteAlRwaadService;

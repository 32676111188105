import React, { useEffect, useState } from 'react';
import { FaEdit, FaEnvelope, FaPhone, FaMapMarkerAlt, FaFileAlt } from 'react-icons/fa';
import { get } from '../../../../services/apis/api';
import CreateEmployee from '../../dialogBoxes/CreateEmployee';
import { Button } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

const EmployeeDetails = ({ setShowDetails, selectedEmployee }) => {
    const [employee, setEmployee] = useState();

    const getEmployeeDetails = async () => {
        console.log("sss=------->", selectedEmployee);
        
        if (selectedEmployee) {
            try {
                const res = await get(`${selectedEmployee.role === "COACH" ? "coach" : "employee"}/get/${selectedEmployee._id}`);
                if (res.statusCode === 200) {
                    setEmployee(res.data.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        getEmployeeDetails();
    }, []);

    if (!employee) {
        return (
            <div className='min-h-screen w-full flex justify-center items-center'>
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className="min-h-screen p-5 bg-gray-50">
            <div className="flex justify-between mb-5 bg-white p-4 shadow rounded-lg">
                {/* <button onClick={() => { setShowDetails(false) }} className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-700">Back</button> */}
                <Button colorScheme='blue' onClick={() => { setShowDetails(false) }}><ArrowBackIcon /> Back</Button>
                <CreateEmployee employee={employee} isEdit={true} getEmployeeDetails={getEmployeeDetails}/>
            </div>
            <div className="container mx-auto">
                <div className="flex flex-col md:flex-row">
                    <div className="w-full md:w-1/3 p-4 bg-white rounded-lg shadow-md">
                        <div className="text-center">
                            <img className="h-48 w-48 mx-auto rounded-full object-cover" src={employee.pic || "default-image-url"} alt="Employee Profile" />
                            <h1 className="text-xl font-bold mt-4">{employee.name}</h1>
                            <h3 className="text-gray-600">{employee.designation}</h3>
                            <p className="text-gray-500">{employee.department}</p>
                        </div>
                        <div className="mt-5">
                            <h2 className="text-gray-600 font-semibold mb-2">Status</h2>
                            <div className="flex items-center justify-between py-2">
                                <span>Status</span>
                                <span className={`py-1 px-2 rounded text-white text-sm ${employee.status === 'ACTIVE' ? 'bg-green-500' : 'bg-red-500'}`}>{employee.status}</span>
                            </div>
                            <div className="flex items-center justify-between py-2">
                                <span>Role</span>
                                <span>{employee.role}</span>
                            </div>
                            <div className="flex items-center justify-between py-2">
                                <span>Joined on</span>
                                <span>{new Date(employee.joiningDate).toDateString()}</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-2/3 px-4">
                        <div className="bg-white p-5 shadow-md rounded-lg mb-5">
                            <h2 className="text-gray-600 text-lg font-bold mb-4">Basic Details:</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
                                <div>
                                    <div className="font-semibold">Name</div>
                                    <div>{employee.name}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">Email</div>
                                    <div><a className="text-blue-800" href={`mailto:${employee.email}`}>{employee.email}</a></div>
                                </div>
                                <div>
                                    <div className="font-semibold">Contact No.</div>
                                    <div>{employee.mobile}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">Department</div>
                                    <div>{employee.department}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">Location</div>
                                    <div>{employee.location}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">Employee Code</div>
                                    <div>{employee.employeeCode}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">Alternate Contact</div>
                                    <div>{employee.alternateContactNo}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">Reporting Head</div>
                                    <div>{employee?.reportingHead?.name}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">Performance Plan</div>
                                    <div>{employee?.performancePlan?.name}</div>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white p-5 shadow-md rounded-lg mb-5">
                            <h2 className="text-gray-600 text-lg font-bold mb-4">Personal Information</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
                                <div>
                                    <div className="font-semibold">Gov Id Number</div>
                                    <div>{employee?.govIdNumber}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">Gov Id Expiration Date</div>
                                    <div>{employee.govIdExpiration}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">Driving License</div>
                                    <div>{employee.drivingLicense}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">Pay Method</div>
                                    <div>{employee.payMethod}</div>
                                </div>
                                {
                                    employee.payMethod === "Cash" ? "" : 
                                        <div>
                                            <div>
                                                <div className="font-semibold">Bank Name</div>
                                                <div>{employee.bankName}</div>
                                            </div>
                                            <div>
                                                <div className="font-semibold">Account No.</div>
                                                <div>{employee.accountNo}</div>
                                            </div>
                                        </div>
                                }
                                <div>
                                    <div className="font-semibold">Permanent Address</div>
                                    <div>{employee.permanentAddress}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">Current Address</div>
                                    <div>{employee.currentAddress}</div>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white p-5 shadow-md rounded-lg mb-5">
                            <h2 className="text-gray-600 text-lg font-bold mb-4">Salary Terms</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
                                {Object.entries(employee.allowances).map(([key, value]) => (
                                    <div key={key}>
                                        <div className="font-semibold">{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</div>
                                        <div>{value}</div>
                                    </div>
                                ))}
                                <div>
                                    <div className="font-semibold">Gross Pay</div>
                                    <div>{employee.grossPay}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">Variable Pay</div>
                                    <div>{employee.variablePay}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">Net Pay</div>
                                    <div>{employee.netPay}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">Appraisal Date</div>
                                    <div>{new Date(employee.appraisalDate).toDateString()}</div>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white p-5 shadow-md rounded-lg mb-5">
                            <h2 className="text-gray-600 text-lg font-bold mb-4">Applicable Acts</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
                                {Object.entries(employee.applicableActs).map(([key, value]) => (
                                    <div key={key}>
                                        <div className="font-semibold">{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</div>
                                        <div>{value}</div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="bg-white p-5 shadow-md rounded-lg">
                            <h2 className="text-gray-600 text-lg font-bold mb-4">Attachments</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
                                {employee.attachments.map((attachment, index) => (
                                    <div key={index}>
                                        <a href={attachment} target="_blank" rel="noopener noreferrer" className="text-blue-800 hover:underline">
                                            <FaFileAlt className="inline-block mr-2" /> Attachment {index + 1}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmployeeDetails;

import React from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    useDisclosure,
    Button,
  } from '@chakra-ui/react'
import { deleteToken } from '../../services/apis/token'
import { useNavigate } from 'react-router-dom'
import { UserState } from '../../context/user'

const ConfirmBox = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { user, setUser } = UserState();
    const cancelRef = React.useRef()
    const navigate = useNavigate();

    const handleConfirm = async()=>{
      if(deleteToken()){
        setUser(undefined);
        navigate('/login');
      }
    }
 
    return (
        <>
          <Button colorScheme='red' onClick={onOpen}>
            Sign Out
          </Button>
    
          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                  Log Out Your Account
                </AlertDialogHeader>
    
                <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>
    
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button colorScheme='red' onClick={handleConfirm} ml={3}>
                    Log Out
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
    )
}

export default ConfirmBox
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  InputRightElement
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import CreateAnnouncement from '../../components/hr/components/Announcement/CreateAnnouncement';
import { get, patch } from '../../services/apis/api';
import EditAnnouncement from '../../components/hr/components/Announcement/EditAnnouncement';
import ConfirmRemoveAnnouncement from '../../components/hr/components/Announcement/ConfirmRemoveAnnouncement';
import { MdOutlineCancel } from "react-icons/md";
import { debounce } from 'lodash';
import { AnnouncementCardSkeleton } from '../../components/skeletons/Skeletons';
import AnnouncementCard from '../../components/hr/components/Announcement/AnnouncementCard';
import AnnouncementDetailDialogue from '../../components/hr/components/Announcement/AnnouncementDetailDialogue';
import BreadCrumbs from '../../components/BreadCrumbs';
import AnnouncementFilter from '../../components/hr/components/Announcement/AnnouncementFilter';
import 'react-date-range/dist/styles.css'; // Main CSS file for styling
import 'react-date-range/dist/theme/default.css'; // Theme CSS file for styling
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';


// DEFAULT FILTER FORM DATA 
const defaultAnnouncementForm = {
  roles: [],
  category: '',
  priority: '',
  search: '',
  announcementDate: {
    start: '',
    end: ''
  },
};


const Announcements = () => {
  const toast = useToast();
  const { user } = UserState();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
  const { isOpen: isDetailOpen, onOpen: onOpenDetail, onClose: onCloseDetail } = useDisclosure();
  const { isOpen: isConfirmOpen, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useDisclosure();
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [selectedAnnouncementWithAtt, setSelectedAnnouncementWithAtt] = useState(null);
  const [announcements, setAnnouncements] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState({ ...defaultAnnouncementForm })
  const [appliedFilterCount, setAppliedFilterCount] = useState(0);
  const [games, setGames] = useState([]);

  // GET THE ALL APPLIED FILTER COUNT 
  const checkAppliedFilterCount = (filterData) => {
    try {
      // Initialize filtercount to 0
      let filtercount = 0;

      // Increment count for each applied filter
      if (filterData?.search) {
        filtercount++;
      }
      if (filterData?.announcementDate?.start || filterData?.announcementDate?.end) {
        filtercount++;
      }
      if (filterData?.priority) {
        filtercount++;
      }
      if (filterData?.category) {
        filtercount++;
      }
      if (filterData?.roles?.length > 0) {
        filtercount++;
      }

      return filtercount; // Return the total count of applied filters
    } catch (error) {
      console.error("Error calculating applied filter count:", error);
      return 0; // Return a default value in case of an error
    }
  };

  // GET ALL ANNOUNCEMENT LIST 
  const getAllAnnouncements = async (page, limit, _filter) => {
    try {
      const filtercount = checkAppliedFilterCount(_filter);
      setAppliedFilterCount(filtercount)

      // Build query parameters dynamically
      const queryParams = new URLSearchParams();

      if (page) queryParams.append('page', page);
      if (limit) queryParams.append('limit', limit);
      if (_filter?.search) queryParams.append('search', _filter?.search);
      if (_filter?.priority) queryParams.append('priority', _filter?.priority);
      if (_filter?.roles) queryParams.append('roles', JSON.stringify(_filter?.roles));
      if (_filter?.category) queryParams.append('category', _filter?.category);
      if (_filter?.announcementDate?.start) queryParams.append('startDate', _filter?.announcementDate?.start);
      if (_filter?.announcementDate?.end) queryParams.append('endDate', _filter?.announcementDate?.end);

      // Construct the URL with query parameters
      const url = `announcement/all?${queryParams.toString()}`;

      // Make the API call
      const res = await get(url);
      if (res.statusCode === 200) {
        setAnnouncements(res?.data?.data);
        setTotalPages(res?.data?.pages);
        setTotalCount(res?.data?.total);
      }
    } catch (error) {
      console.error('Error fetching announcements:', error);
    }
  };
  // GET ALL ANNOUNCEMENT LIST 

  // GET ALL EMPLOYEES LIST 
  const getAllEmployees = async () => {
    try {
      const res = await get('employee/dropdown');
      if (res.statusCode == 200) {
        const myEmployees = res?.data?.dropdown?.map(emp => ({
          value: emp?._id,
          label: emp?.name
        }));
        setEmployees(myEmployees);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // FETCH INTIAL DATA 
  const hasFetched = useRef(false); // Initially false

  // FETCH ALL GAMES 
  const getAllGames = async () => {
    try {
      const res = await get('game/dropdown');
      if (res.statusCode === 200) {
        setGames(res?.data?.dropdown);
      }
    } catch (e) {
      console.log(`ERROR WHILE FETCHING ALL GAMES ${e}`);
    }
  };

  useEffect(() => {
    if (!hasFetched.current) {
      getAllEmployees();
      getAllAnnouncements(1, 10);
      if (user?.role?.toLowerCase() == "game-manager") {
        getAllGames();
      }
      hasFetched.current = true; // Mark as fetched
    }
  }, []);
  // GET ALL EMPLOYEES LIST 

  // REMOVE THE CURRENT SELECTED ANNOUNCEMENT 
  const removeSelectedAnnouncement = async (_id) => {
    try {
      setLoading(true);
      const response = await patch(`announcement/remove/${_id}`);
      setLoading(false);
      if (response?.statusCode == 200) {
        getAllAnnouncements(1, 10); //fetched updated announcements
        setPage(1);
        toast({
          title: 'Announcement deleted.',
          description: response?.data?.message || 'Your announcement has been created successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        onCloseConfirm();
      } else {
        toast({
          title: 'Error',
          description: response?.data?.message || 'Failed to create announcement.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (e) {
      setLoading(false)
      toast({
        title: 'Error',
        description: e?.response?.data?.message || 'An unexpected error occurred.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.log(`ERROR WHILE REMOVING THE ${e}`);
    }
  }

  // Debounced version of the getEmployeesWithPayroll function
  const debouncedGetAllAnnouncement = useCallback(
    debounce((skip, limit, _filter) => {
      getAllAnnouncements(skip, limit, _filter);
    }, 300), // Adjust debounce delay as needed
  );

  // CLEAR SEARCH FUNCTION 
  const clearSearch = () => {
    try {
      const _filter = {
        ...filterData,
        search: ""
      }
      debouncedGetAllAnnouncement(1, 10, _filter);
      setFilterData(_filter);
      setPage(1); // Reset to the first page when searching
    } catch (e) {
      console.log(`ERROR WHILE CLEARING SEARCH ${e}`);
    }
  }

  return (
    <Box width="100%" p={4}>
      <BreadCrumbs />
      {/* HEADER PART  */}
      <Flex
        flexDir={isArabic ? 'row-reverse' : 'row'}
        justify="space-between"
        align="center"
        py={2}
      >
        <Box fontSize="xl" fontWeight="bold">
          {t('announcements')} {totalCount > 0 && `(${totalCount})`}
        </Box>
        <div className={`flex items-center gap-2 py-4 ${isArabic ? "flex-row-reverse" : "flex-row"}`}>
          <InputGroup mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "auto" }}>
            <InputLeftElement
              // opacity={filterData?.search && isArabic ? 1 : 0}
              opacity={isArabic ? filterData?.search ? 1 : 0 : 1}
              pointerEvents={isArabic ? 'initial' : 'none'}
              onClick={() => {
                if (isArabic) {
                  clearSearch()
                }
              }}
            >
              {isArabic ?
                <MdOutlineCancel color="gray.300" />
                :
                <SearchIcon color="gray.300" />
              }
            </InputLeftElement>
            <Input
              type="text"
              placeholder={`${t('search-announcements')} ...`}
              textAlign={isArabic ? 'right' : 'left'}
              value={filterData?.search}
              onChange={(e) => {
                const _filter = {
                  ...filterData,
                  search: e?.target?.value
                }
                debouncedGetAllAnnouncement(1, 10, _filter);
                setFilterData(_filter);
                setPage(1); // Reset to page 1 on new search
              }}
            />
            <InputRightElement
              opacity={isArabic ? 1 : filterData?.search ? 1 : 0}
              pointerEvents={!isArabic ? 'initial' : 'none'}
              onClick={() => {
                if (!isArabic) {
                  clearSearch()
                }
              }}
            >
              {isArabic ?
                <SearchIcon color="gray.300" />
                :
                <MdOutlineCancel color="gray.300" />
              }
            </InputRightElement>
          </InputGroup>
          {/* CREATE NEW ANNOUNCEMENT DIALOGUE  */}
          <CreateAnnouncement
            getAllAnnouncements={() => {
              getAllAnnouncements(1, 10)
              setPage(1);
            }}
            employees={employees}
            games={games}
          />

          <AnnouncementFilter
            filterData={filterData}
            setFilterData={setFilterData}
            allDepartments={[]}
            appliedFilterCount={appliedFilterCount}
            onApplyFilter={() => {
              getAllAnnouncements(page, limit, filterData);
            }}
            onResetFilter={() => {
              const _payload = {
                ...defaultAnnouncementForm,
                search: filterData?.search
              }
              setFilterData(_payload)
              setPage(1);
              getAllAnnouncements(1, 10, _payload);
            }}
          />
        </div>
      </Flex>

      {/* ANNOUCEMENT CARD  */}
      {
        announcements ? announcements?.map((announcement, index) => {
          return (
            <AnnouncementCard
              key={index}
              onCardClick={(ans) => {
                if (announcement?.attachments?.length > 0) {
                  setSelectedAnnouncementWithAtt(ans);
                  onOpenDetail();
                }
              }}
              onOpenEdit={onOpenEdit}
              announcement={announcement}
              onOpenConfirm={onOpenConfirm}
              setSelectedAnnouncement={setSelectedAnnouncement}
            />
          )
        }) :
          <AnnouncementCardSkeleton />
      }

      {/* Pagination controls */}
      {
        announcements &&
        <Flex justify="space-between" align="center" mt={4} >
          <Button
            // onClick={handlePrevPage}
            onClick={() => {
              if (page > 1) {
                const _page = page - 1;
                setPage(_page);
                getAllAnnouncements(_page, limit, filterData)
              }
            }}
            isDisabled={page === 1}
          >
            {t('previous')}
          </Button>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isArabic ? 'row-reverse' : 'row',
              gap: 1
            }}
          >
            <h1>{t('page')}</h1>
            <Box
              sx={{
                display: 'flex',
                flexDirection: isArabic ? 'row-reverse' : 'row',
                gap: 1
              }}
            >
              {page}

              <h1>{t('of')}</h1>
              {totalPages}
            </ Box>
          </Box>
          <Button
            // onClick={handleNextPage}
            onClick={() => {
              if (page < totalPages) {
                const _page = page + 1;
                setPage(_page);
                getAllAnnouncements(_page, limit, filterData)
              }
            }}
            isDisabled={page === totalPages}
          >
            {t('next')}
          </Button>
        </Flex>
      }

      {/* EDIT ANNOUNCEMENT DIALOGUE  */}
      {
        selectedAnnouncement &&
        <EditAnnouncement
          isOpen={isEditOpen}
          onOpen={onOpenEdit}
          employees={employees}
          onClose={onCloseEdit}
          announcement={selectedAnnouncement}
          getAllAnnouncements={() => {
            getAllAnnouncements(1, 10);
            setPage(1);
          }}
          games={games}
        />
      }

      {/* EDIT ANNOUNCEMENT DIALOGUE  */}
      {
        selectedAnnouncementWithAtt &&
        <AnnouncementDetailDialogue
          isOpen={isDetailOpen}
          onOpen={onOpenDetail}
          onClose={onCloseDetail}
          announcement={selectedAnnouncementWithAtt}
          setAnnouncement={setSelectedAnnouncementWithAtt}
        />
      }


      {/* CONFRIM ANNOUNCEMENT REMOVE  */}
      {
        isConfirmOpen &&
        <ConfirmRemoveAnnouncement
          isLoading={loading}
          isOpen={isConfirmOpen}
          onClose={onCloseConfirm}
          onDeleteTransaction={(_id) => {
            removeSelectedAnnouncement(selectedAnnouncement?._id)
          }}
          selectedAnnouncement={selectedAnnouncement}
        />
      }

      {/* Modal for viewing announcement details */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Announcement Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedAnnouncement && (
              <Box>
                <Text fontWeight="bold">Title:</Text>
                <Text mb={2}>{selectedAnnouncement.title}</Text>

                <Text fontWeight="bold">Description:</Text>
                <Text mb={2}>{selectedAnnouncement.description}</Text>

                <Text fontWeight="bold">Date:</Text>
                <Text mb={2}>{new Date(selectedAnnouncement.announcementDate).toLocaleDateString()}</Text>

                <Text fontWeight="bold">Posted By:</Text>
                <Text mb={2}>{selectedAnnouncement.createdBy.name}</Text>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box >
  );
};

export default Announcements;

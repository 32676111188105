import React, { useEffect, useState } from 'react';
import { Badge, Button, IconButton, Select, Tooltip, } from '@chakra-ui/react';
import { MdFilterAlt } from "react-icons/md";
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import DateRangeSelect from '../account/DateRangeSelect';


// ALL REQUEST TYPES
export const allRequestType = [
    { value: 'EXTERNAL', label: 'external' },
    { value: 'INTERNAL', label: 'internal' },
    { value: 'CORPORATE', label: 'corporate' },
]

// ALL REQUEST STATUS
export const allRequestStatus = [
    { value: 'OPEN', label: 'open' },
    { value: 'CLOSED', label: 'closed' },
    { value: 'IN-PROGRESS', label: 'in-progress' },
    { value: 'PENDING_APPROVA', label: 'pending-approval' },
    { value: 'APPROVED', label: 'approved' },
    { value: 'REJECTED', label: 'rejected' }
]

// ALL PAYMENT STATUS
export const allPaymentStatus = [
    { value: 'PENDING', label: 'pending' },
    { value: 'COMPLETED', label: 'completed' },
    { value: 'FAILED', label: 'failed' },
]

// DEFAULT DATES 
const _defaultDates = {
    startDate: '',
    endDate: ''
}

const ClinicRequestsFilter = ({
    onApplyFilter,
    filterData,
    setFilterData,
    onResetFilter,
    appliedFilterCount
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [isOpen, setIsOpen] = useState(false);
    const [date, setDate] = useState({ ..._defaultDates });
    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);

    // CHANGE FORM DATA FUNCTION 
    const handleChangeData = (field, value) => {
        try {
            setFilterData((prevData) => ({
                ...prevData,
                [field]: value
            }))
        } catch (e) {
            console.log(`ERROR WHILE CHANGING FILTER DATA ${e}`);
        }
    }

    // UPDATE FILTERDATA ANNOUNCEMENT DATE RANGE ON DATE CHANGE 
    useEffect(() => {
        setFilterData((prevData) => ({
            ...prevData,
            dates: {
                start: date?.startDate,
                end: date?.endDate
            }
        }));
    }, [date, setFilterData]);

    // HEADING FIELD 
    const HeadingField = ({ title }) => (
        <h1 className='text-sm lg:text-base font-semibold'>{t(title)}</h1>
    )

    return (
        <>
            <Badge
                display="flex"
                alignItems="center"
                gap={2}
                px={2}
                borderRadius="md"
                onClick={onOpen}
            // colorScheme={appliedFilterCount > 0 ? "blue" : "gray"} // Highlight badge if filters are applied
            >
                {/* Display the applied filter count */}
                {appliedFilterCount}

                {/* Tooltip and IconButton for the filter icon */}
                <Tooltip
                    label={t('announcement-filter')}
                >
                    <IconButton
                        onClick={onOpen}
                    >
                        <MdFilterAlt />
                    </IconButton>
                </Tooltip >
            </Badge>

            <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader
                            padding={3}
                            borderBottom="1px solid #E1E1E1"
                        >
                            <h1>{t('clinic-filters')}</h1>
                        </DrawerHeader>
                        <DrawerBody padding={3}>
                            <div className='flex flex-col w-full items-start gap-2'>
                                <HeadingField
                                    title={"date"}
                                />
                                <DateRangeSelect
                                    date={date}
                                    setDate={setDate}
                                    resetDates={async () => {
                                        await setDate({ startDate: '', endDate: '' })
                                    }}
                                    getData={() => {
                                    }}
                                />
                                {/* REQUEST TYPE DROP DOWN  */}
                                <div className={`flex flex-col w-full ${isArabic ? 'items-end' : 'items-start'} gap-2`}>
                                    <HeadingField
                                        title={"request-type"}
                                    />
                                    <Select
                                        name="request-type"
                                        width="100%"
                                        zIndex={0}
                                        value={filterData?.requestType}
                                        onChange={(e) => {
                                            handleChangeData('requestType', e?.target?.value);
                                        }}
                                        placeholder="All"
                                    >
                                        {allRequestType?.map((req, index) => (
                                            <option
                                                key={index}
                                                value={req?.value}
                                            >
                                                {t(req?.label)}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                                {/* REQUEST STATUS DROP DOWN  */}
                                <div className={`flex flex-col w-full ${isArabic ? 'items-end' : 'items-start'} gap-2`}>
                                    <HeadingField
                                        title={"request-status"}
                                    />
                                    <Select
                                        name="request-status"
                                        width="100%"
                                        zIndex={0}
                                        value={filterData?.requestStatus}
                                        onChange={(e) => {
                                            handleChangeData('requestStatus', e?.target?.value);
                                        }}
                                        placeholder="All"
                                    >
                                        {allRequestStatus?.map((stat, index) => (
                                            <option
                                                key={index}
                                                value={stat?.value}
                                            >
                                                {t(stat?.label)}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                                {/* PAYMENT STATUS DROP DOWN  */}
                                <div className={`flex flex-col w-full ${isArabic ? 'items-end' : 'items-start'} gap-2`}>
                                    <HeadingField
                                        title={"payment-status"}
                                    />
                                    <Select
                                        name="paymentStatus"
                                        width="100%"
                                        zIndex={0}
                                        value={filterData?.paymentStatus}
                                        onChange={(e) => {
                                            handleChangeData('paymentStatus', e?.target?.value);
                                        }}
                                        placeholder={t("all")}
                                    >
                                        {allPaymentStatus?.map((pay, index) => (
                                            <option
                                                key={index}
                                                value={pay?.value}
                                            >
                                                {t(pay?.label)}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </DrawerBody>
                        <DrawerFooter
                            borderTop="1px solid #E1E1E1"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            padding={3}
                        >
                            <Button
                                variant="outline"
                                onClick={() => {
                                    setDate(_defaultDates)
                                    onResetFilter();
                                    onClose();
                                }}
                                mr={2}
                            >
                                {t('reset')}
                            </Button>
                            <Button
                                colorScheme="blue"
                                onClick={() => {
                                    onApplyFilter();
                                    onClose();
                                }}
                            >
                                {t('apply')}
                            </Button>
                        </DrawerFooter>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    );
};

export default ClinicRequestsFilter;

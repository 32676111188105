import React, { useEffect, useRef, useState } from 'react'
import { get, post } from '../../services/apis/api';
import { useLocation } from 'react-router-dom';
import { UserState } from '../../context/user';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Tooltip, useDisclosure, useToast } from '@chakra-ui/react';
import BreadCrumbs from '../../components/BreadCrumbs';
import { formatAndValidateDate, formatDateArEn, formatDateForInput, formatTimeArEn, getStatusColor } from '../../services/glocalFunctions';
import { MdOutlineVideogameAsset } from "react-icons/md";
import { FaBirthdayCake, FaCheckCircle, FaRegEdit, FaUserMd } from "react-icons/fa";
import { CiCircleCheck, CiPhone, CiStethoscope, CiUser } from 'react-icons/ci';
import { MdOutlineMailOutline } from "react-icons/md";
import { IoAlertCircleOutline, IoCheckmarkCircleOutline, IoLocationOutline } from "react-icons/io5";
import { FaHeartbeat } from 'react-icons/fa';
import { CiCalendar } from "react-icons/ci";
import { CiClock1 } from "react-icons/ci";
import { FiActivity, FiFileText } from 'react-icons/fi';
import { FaDollarSign } from 'react-icons/fa6';
import { LuBuilding2 } from "react-icons/lu";
import ConfirmCloseRequestBox from '../../components/clinic/ConfirmCloseRequest';
import ScheduleAppointmentModal from '../../components/clinic/ScheduleAppoinment';
import ConfirmRejectRequest from '../../components/clinic/ConfirmRejectRequest';
import UpdateRecoveryDialogue from '../../components/clinic/UpdateRecoveryDialogue';
import AddFeedbackNote from '../../components/clinic/AddFeedbackNote';
import { FieldLabel, getGenderIcon, ProfileComponent, StatusFieldLabel } from '../../components/clinic/ClinicComponent';
import ConfirmCompletePayment from '../../components/clinic/ConfirmCompletePayment';

// DEFAULT SCHEDULE FORM DATA 
const _scheduleFormData = {
    appointmentDate: '',
    feedback: '',
    recoveryStatus: '',
    reason: ''
};

// RECOVERY STATUS FORM 
const _recoveryForm = {
    recoveryStatus: '',
    feedback: ''
}

// PAYMENT FORM 
const _paymentForm = {
    amount: '',
    paymentNote: '',
    paymentDueDate: ''
}

const AppointmentDetails = () => {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const toast = useToast();
    const isArabic = i18n.language === 'ar';
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenComplete, onOpen: onOpenComplete, onClose: onCompleteClose } = useDisclosure();
    const { isOpen: isOpenRecovery, onOpen: onOpenRecovery, onClose: onReconveryClose } = useDisclosure();
    const { isOpen: isOpenPayement, onOpen: onOpenPayment, onClose: onPaymentClose } = useDisclosure();
    const { isOpen: isOpenNote, onOpen: onOpenNote, onClose: onNoteClose } = useDisclosure();
    const location = useLocation();
    const appointmentId = location?.pathname?.split('/')[3];
    const [appointment, setAppointment] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [scheduleFormData, setScheduleFormData] = useState({ ..._scheduleFormData });
    const [paymentFormData, setPaymentFormData] = useState({ ..._paymentForm });
    const [formData, setFormData] = useState({ ..._recoveryForm });
    const [isRechedule, setIsRechedule] = useState(false);
    const [request, setRequest] = useState(null)
    const [reason, setReason] = useState('');
    const [note, setNote] = useState('');
    const [noteType, setNoteType] = useState('');
    const [selectedDoctor, setSelectedDoctor] = useState({
        role: 'clinic-manager',
        _id: '6711fb36d66326c16c5763da'
    });

    // CARD BOX CLASSES 
    const cardBoxClass = `w-full flex flex-col items-start gap-4 rounded-lg shadow-base border border-[#dce3f8] bg-white overflow-hidden p-4`
    const cardBoxClass2 = `w-full lg:w-[48%] max-w-full lg:max-w-1/2 flex flex-col items-start gap-4 rounded-lg shadow-base border border-[#dce3f8]  bg-gray-50 overflow-hidden p-4`

    // IF LATEST DOCTOR/CLINIC FEEDBACK IS AVAILABLE SO FEEDBACK OTHERWISE INJURY DESCRIPTION 
    const detailDesc = appointment?.requestId?.latestFeedback || appointment?.requestId?.injuryDescription
    const descLabel = appointment?.requestId?.latestFeedback ? 'latest-feedback' : 'injury-description'
    const headingClass = `text-xl lg:text-lg text-gray-900  font-bold leading-10`
    const headingClass2 = `text-xl lg:text-lg text-[#FFFFFF]  font-bold leading-10`
    const fieldValueClass = `text-gray-500 text-xs font-medium`
    const fieldValueClass1 = `text-[#FFFFFF] text-sm font-medium`
    const statusLabelClass = `text-xl lg:text-sm text-gray-700 font-semibold leading-none`
    const direction = isArabic ? 'rtl' : 'ltr';
    const textAlign = isArabic ? 'right' : 'left';


    // ACTIONS FUNCTIONS START FROM HERE 

    // GET REQUEST DETAILS  
    const hasFetched = useRef(false); // Initially false

    const getRequestById = async (appointmentId) => {
        try {
            if (user) {
                const res = await get(`clinic/appointment/${appointmentId}`)
                if (res.statusCode === 200) {
                    setAppointment(res?.data?.appointment || {});
                    setFormData((prevData) => ({
                        ...prevData,
                        recoveryStatus: res?.data?.appointment?.requestId?.recoveryStatus || ''
                    }));
                }
            }
        } catch (error) {
            console.log(`ERROR WHILE FETHCING APPOINTMENT DETAIL ${error}`);
        }
    };

    useEffect(() => {
        if (!hasFetched.current) {
            getRequestById(appointmentId);
            hasFetched.current = true; // Mark as fetched
        }
    }, [])
    // GET REQUEST DETAILS  

    // CHANGE THE APPOINTMENT STATUS 
    const changeAppointmentStatus = async (_id, _status, reason) => {
        try {
            const _body = {
                appointmentId: _id,
                status: _status,
                reason: reason || ''
            }

            const response = await post(`clinic/chage-appointment-status`, _body);
            if (response?.statusCode == 200) {
                getRequestById(appointmentId);
                setReason('');
                toast({
                    title: response?.data?.message || 'success',
                    description: "",
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: response?.data?.message || 'error',
                    description: "",
                    status: 'error',
                    duration: 6000,
                    isClosable: true,
                })
            }
        } catch (e) {
            toast({
                title: e?.response?.data?.message || 'error',
                description: "",
                status: 'error',
                duration: 6000,
                isClosable: true,
            })
            console.log(`ERROR WHILE CHANGING STATUS ${e}`);
        }
    }

    // CHANGE THE REQUEST RECOVERY STATUS 
    const changeRequestRecoveryStatus = async (requestId, _status, feedback) => {
        try {
            setIsLoading(true);
            const _body = {
                requestId: requestId,
                status: _status,
                feedback: feedback || ''
            }
            const response = await post(`clinic/change-request-recovery-status`, _body);
            setIsLoading(false);
            if (response?.statusCode == 200) {
                setFormData(_recoveryForm);
                getRequestById(appointmentId);
                onReconveryClose();
                toast({
                    title: response?.data?.message || 'success',
                    description: "",
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: response?.data?.message || 'error',
                    description: "",
                    status: 'error',
                    duration: 6000,
                    isClosable: true,
                })
            }
        } catch (e) {
            setIsLoading(false);
            toast({
                title: e?.response?.data?.message || 'error',
                description: "",
                status: 'error',
                duration: 6000,
                isClosable: true,
            })
            console.log(`ERROR WHILE CHANGING RECOVERY STATUS ${e}`);
        }
    }

    // RE - SCHEDULE APPOINTMENT
    const reScheduleAppointment = async (appointmentId) => {
        setIsLoading(true);
        try {
            let _payload = {
                doctorId: selectedDoctor?._id,
                appointmentDate: scheduleFormData?.appointmentDate,
                reason: scheduleFormData?.reason
            };

            const res = await post(`clinic/re-schedule/appointment/${appointmentId}`, _payload)
            setIsLoading(false);

            if (res?.statusCode === 200 || res?.statusCode === 201) {
                getRequestById(appointmentId);
                onClose();
                setScheduleFormData(_scheduleFormData);
                setIsRechedule(false);
                toast({
                    title: "Request scheduled successfully.",
                    status: "success",
                    duration: 5000, // 5 seconds    
                    isClosable: true,
                });
            } else {
                toast({
                    title: res?.data?.message || "Request scheduling failed.",
                    status: "error",
                    duration: 5000, // 5 seconds    
                    isClosable: true,
                });
            }
        } catch (error) {
            setIsLoading(false);
            toast({
                title: error?.response?.data?.message || "Request scheduling failed.",
                status: "error",
                duration: 5000, // 5 seconds    
                isClosable: true,
            });
        }
    };

    // SCHEDULE APPOINTMENT
    const scheduleAppointment = async (requestId) => {
        setIsLoading(true);
        try {
            let _payload = {
                doctorId: selectedDoctor?._id,
                requestId: requestId
            };

            let res
            _payload.appointmentDate = scheduleFormData?.appointmentDate;
            res = await post(`clinic/schedule/appointment/assign`, _payload);
            setIsLoading(false);

            if (res?.statusCode === 200 || res?.statusCode === 201) {
                getRequestById(appointmentId);
                onClose();
                setScheduleFormData(_scheduleFormData);
                toast({
                    title: res?.data?.message || "Request scheduled successfully.",
                    status: "success",
                    duration: 5000, // 5 seconds    
                    isClosable: true,
                });
            } else {
                toast({
                    title: res?.data?.message || "Request scheduling failed.",
                    status: "error",
                    duration: 5000, // 5 seconds    
                    isClosable: true,
                });
            }
        } catch (error) {
            setIsLoading(false);
            toast({
                title: error?.response?.data?.message || "Request scheduling failed.",
                status: "error",
                duration: 5000, // 5 seconds    
                isClosable: true,
            });
        }
    };

    // ADD A NOTE IN APPOINTMENT 
    const addNote = async (appointmentId, feedback, type) => {
        try {
            setIsLoading(true);
            const _body = {
                feedback: feedback || '',
                noteType: type || ''
            }
            const response = await post(`clinic/appointment/add-note/${appointmentId}`, _body);
            setIsLoading(false);
            if (response?.statusCode == 200) {
                getRequestById(appointmentId);
                setNote('');
                setNoteType('');
                onNoteClose();
                toast({
                    title: response?.data?.message || "Request scheduled successfully.",
                    status: "success",
                    duration: 5000, // 5 seconds    
                    isClosable: true,
                });
            } else {
                toast({
                    title: response?.data?.message || "Error while adding note.",
                    status: "error",
                    duration: 5000, // 5 seconds    
                    isClosable: true,
                });
            }
        } catch (e) {
            setIsLoading(false);
            console.log(`ERROR WHILE ADDING NOTE ${e}`);
            toast({
                title: e?.response?.data?.message || "Error while adding note.",
                status: "error",
                duration: 5000, // 5 seconds    
                isClosable: true,
            });
        }
    };

    // REQUEST PENDING PAYMENT 
    const requestPayment = async (appointmentId, data, status) => {
        try {
            setIsLoading(true);
            const _body = {
                amount: Number(data?.amount) || 0,  // Ensure amount is a number
                paymentDueDate: data?.paymentDueDate || '',
                paymentNote: data?.paymentNote || '',
                action: status || ''
            }
            const response = await post(`clinic/appointment/request-payment/${appointmentId}`, _body);
            setIsLoading(false);
            if (response?.statusCode == 200) {
                onPaymentClose();
                setPaymentFormData(_paymentForm);
                getRequestById(appointmentId);
            }
        } catch (e) {
            setIsLoading(false);
            console.log(`ERROR WHILE REQUESTING PAYMENT ${e}`);
        }
    };

    // COMPLETE PAYMENT REQUEST 
    const completePaymentRequest = async (appointmentId, status, paymentMode) => {
        try {
            setIsLoading(true);
            const _body = {
                action: status || '',
                paymentMode: paymentMode || 'CASH' // CURRENTOY SET TO DEFAULT CASH
            }
            const response = await post(`clinic/appointment/complete-payment/${appointmentId}`, _body);
            setIsLoading(false);
            if (response?.statusCode == 200) {
                onCompleteClose();
                getRequestById(appointmentId);
            }
        } catch (e) {
            setIsLoading(false);
            console.log(`ERROR WHILE REQUESTING PAYMENT ${e}`);
        }
    };


    // PAYMENT REQUEST LABEL 
    const _paymentStatus = appointment?.paymentStatus?.toLowerCase();
    const paymentButtonLabel = _paymentStatus == 'pending' ? 'request-payment' : _paymentStatus == 'requested' || _paymentStatus == 'modified' ? 'update-payment-request' : 'complete-payment-request';

    return (
        <>
            <BreadCrumbs />
            <div className='w-full flex-1 flex flex-col p-2 gap-4'>
                <div className={`flex justify-between  ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}>
                    <h2 className='text-xl whitespace-nowrap font-bold mb-4'>
                        {location?.pathname?.includes('appointment') ?
                            t('appointment-details') : t('request-details')
                        }
                    </h2>
                    <div className={`flex items-center gap-2`}>
                        {/* REQUEST PAYMENT MODEL/BUTTON  */}
                        {/* {_paymentStatus == 'pending' &&
                            <Button
                                colorScheme='blue'
                                onClick={() => {
                                    onOpenPayment();
                                }}
                            >
                                <FaDollarSign className="w-4 h-4" />
                                <span>{t(paymentButtonLabel)}</span>
                            </Button>
                        } */}
                        {/*                         
                        <AppointmentPaymentRequest
                            isOpen={isOpenPayement}
                            formData={paymentFormData}
                            isLoading={isLoading}
                            label={paymentButtonLabel || "payment"}
                            onHandleChange={(field, value) => {
                                setPaymentFormData((prevData) => ({
                                    ...prevData,
                                    [field]: value
                                }));
                            }}
                            onClose={() => {
                                onPaymentClose();
                            }}
                            onHandleSubmit={() => {
                                const _status = _paymentStatus == 'pending' ? 'REQUESTED' : 'MODIFIED';
                                requestPayment(appointmentId, paymentFormData, _status);
                            }}
                        /> */}

                        {/* CONFIRM CLOSE REQUEST DIALOGUE  */}
                        {appointment?.requestId?.status !== 'CLOSED' && appointment?.requestId?.currentLastAppointment?._id == appointment?._id ?
                            <ConfirmCloseRequestBox
                                request={appointment?.requestId}
                                fetchData={getRequestById}
                            />
                            : null}
                    </div>
                </div>

                {/* APPOINTMENT PAYMENT DETAULS NOT FOR APPOINTMENT NOW  */}
                {/* {_paymentStatus != 'pending' &&
                    <PaymentDetail
                        amount={appointment?.amount || 0}
                        status={appointment?.paymentStatus || '--'}
                        dueDate={appointment?.paymentDueDate || '--'}
                        paymentNote={appointment?.paymentNote || '--'}
                        onEditPayment={() => {
                            onOpenPayment();
                            if (_paymentStatus == 'requested' || _paymentStatus == 'modified') {
                                setPaymentFormData((prevData) => ({
                                    ...prevData,
                                    paymentDueDate: formatAndValidateDate(appointment?.paymentDueDate),
                                    amount: appointment?.amount,
                                    paymentNote: appointment?.paymentNote
                                }))
                            }
                        }}
                        onConfirmComplete={() => {
                            onOpenComplete();
                        }}
                    />
                } */}

                {/* APPPOINTMENT DETAILS  */}
                <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
                    {/* HEADER PART TOP */}
                    <div className={`flex items-center justify-between bg-gradient-to-r from-blue-600 to-blue-700 p-4 px-0 lg:px-2 mb-4 ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}>
                        <div className={`flex items-center gap-2`}>
                            <CiCalendar color='#cad7fb' size={30} />
                            <div className={`flex flex-col items-start gap-1`}>
                                <div className={`flex items-center gap-1`}>
                                    <span className={headingClass2}>{t('appointment')}</span>
                                    <span className={headingClass2}>{appointment?.appointmentId || '--'}</span>
                                </div>
                                {/* UPCOMING APPOINTMENT #ffffff */}
                                <div className={`flex items-center gap-4`}>
                                    <div className='flex items-center gap-2'>
                                        <CiCalendar color='#ffffff' />
                                        <span className={fieldValueClass1}>
                                            {formatDateArEn(appointment?.appointmentDate, isArabic)}
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <CiClock1 color='#ffffff' />
                                        <span className={fieldValueClass1}>
                                            {formatTimeArEn(appointment?.appointmentDate, isArabic)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* REQUEST STATUS  */}
                        <span className={`h-fit p-2 px-4 flex items-center justify-center text-xs font-semibold rounded-full ${getStatusColor(appointment?.status)}`}>
                            {t(appointment?.status?.toLowerCase()) || '--'}
                        </span>
                    </div>

                    {/* BASIC INFO */}
                    <div className={`w-full flex flex-wrap items-center gap-4 p-4`}>
                        {/* PLAYER/PATIENT DETAILS  */}
                        <div className={cardBoxClass2}>
                            <div className='w-full flex items-center justify-between'>
                                <div className="flex items-center space-x-3 mb-3">
                                    <CiUser className="w-5 h-5 text-blue-600" />
                                    <span className={headingClass}>{t('patient-details') || '--'}</span>
                                </div>

                                {/* CAN ONLY ADD FEEDBACK IF THIS IS THE CURRENT LAST APPOINTMENT / LATEST */}
                                {appointment?.requestId?.currentLastAppointment?._id == appointment?._id &&
                                    <Tooltip label={t('update-recovery-status')}>
                                        <IconButton
                                            icon={<span style={{ fontSize: "1.2em" }}>✍️</span>}
                                            aria-label="View Asset Details"
                                            variant="ghost"
                                            onClick={() => {
                                                onOpenRecovery();
                                            }}
                                        />
                                    </Tooltip>
                                }
                            </div>

                            {/* PATIENT/PLAYER PROFILE COMPONENT  */}
                            <ProfileComponent
                                name={appointment?.requestId?.userId?.name || '--'}
                                designation={appointment?.requestId?.userId?.designation || appointment?.requestId?.userId?.role || '--'}
                                profileImg={appointment?.requestId?.userId?.pic}
                            />
                            <div className={`w-full flex flex-wrap items-start justify-between gap-6`}>
                                {/* BASIC PLAYER/PATIENT INFO  */}
                                <div>
                                    <FieldLabel
                                        icon={<MdOutlineVideogameAsset className="text-gray-400" />}
                                        title={appointment?.requestId?.userId?.gameId?.name || '--'}
                                    />
                                    <FieldLabel
                                        icon={<CiPhone className="text-gray-400" />}
                                        title={appointment?.requestId?.userId?.mobile || '--'}
                                    />
                                    <FieldLabel
                                        icon={<MdOutlineMailOutline className="text-gray-400" />}
                                        title={appointment?.requestId?.userId?.email || '--'}
                                    />
                                </div>

                                {/* HEALTH INFO OF  PLAYER/PATIENT   */}
                                <div>
                                    <FieldLabel
                                        // icon={<MdOutlineVideogameAsset className="text-gray-400" />}
                                        icon={<FaHeartbeat color="#e74c3c" />}
                                        title={appointment?.requestId?.userId?.bloodGroup || '--'}
                                    />
                                    <FieldLabel
                                        icon={getGenderIcon(appointment?.requestId?.userId?.gender?.toLowerCase())}
                                        title={t(appointment?.requestId?.userId?.gender?.toLowerCase()) || '--'} // Show gender or fallback text '--'
                                    />
                                    <FieldLabel
                                        icon={<FaBirthdayCake className="text-gray-400" />}
                                        title={formatDateArEn(appointment?.requestId?.userId?.dateOfBirth, isArabic) || '--'}
                                    />
                                </div>

                                {/* ADDRESS FIELD  */}
                                <div>
                                    <FieldLabel
                                        icon={<IoLocationOutline className="text-gray-400" />}
                                        title={appointment?.requestId?.userId?.location || '--'}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* DOCTOR BASIC DETAILS  */}
                        <div className={cardBoxClass2}>
                            <div className="flex items-center space-x-3 mb-3">
                                <CiStethoscope className="w-5 h-5 text-blue-600" />
                                <span className={headingClass}>{t('doctor-details') || '--'}</span>
                            </div>
                            {/* DOCTOR PROFILE COMPONENT  */}
                            <ProfileComponent
                                name={appointment?.doctorId?.name || '--'}
                                designation={appointment?.doctorId?.specialization || appointment?.doctorId?.role || '--'}
                                profileImg={appointment?.doctorId?.pic}
                            />

                            <div className={`w-full flex flex-wrap items-start justify-between gap-6`}>
                                {/* BASIC PLAYER/PATIENT INFO  */}
                                <div>
                                    <FieldLabel
                                        icon={<FaUserMd className="text-gray-400" />}
                                        title={appointment?.doctorId?.experience || '--'}
                                    />
                                    <FieldLabel
                                        icon={<CiPhone className="text-gray-400" />}
                                        title={appointment?.doctorId?.mobile || '--'}
                                    />
                                    <FieldLabel
                                        icon={<MdOutlineMailOutline className="text-gray-400" />}
                                        title={appointment?.doctorId?.email || '--'}
                                    />
                                </div>

                                {/* HEALTH INFO OF  PLAYER/PATIENT   */}
                                <div>
                                    <FieldLabel
                                        // icon={<MdOutlineVideogameAsset className="text-gray-400" />}
                                        icon={<FaHeartbeat color="#e74c3c" />}
                                        title={appointment?.doctorId?.bloodGroup || '--'}
                                    />
                                    <FieldLabel
                                        icon={getGenderIcon(appointment?.doctorId?.gender?.toLowerCase())}
                                        title={t(appointment?.doctorId?.gender?.toLowerCase()) || '--'} // Show gender or fallback text '--'
                                    />
                                    <FieldLabel
                                        icon={<FaBirthdayCake className="text-gray-400" />}
                                        title={formatDateArEn(appointment?.doctorId?.dateOfBirth, isArabic) || '--'}
                                    />
                                </div>

                                {/* ADDRESS FIELD  */}
                                <div>
                                    <FieldLabel
                                        icon={<IoLocationOutline className="text-gray-400" />}
                                        title={appointment?.doctorId?.location || '--'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Action Buttons */}
                    {appointment?.requestId?.currentLastAppointment?._id == appointment?._id &&
                        <div className="border-t border-gray-200 p-4">
                            <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
                                {/* SCHEDULE BUTTON  */}
                                {['cancelled', 'missed', 'scheduled', 'rescheduled'].includes(appointment?.status?.toLowerCase()) &&
                                    <Button
                                        bg="blue.50"
                                        _hover={{ bg: "blue.100" }}
                                        color="blue.700"
                                        borderColor="blue.200"
                                        leftIcon={<CiCalendar className="w-4 h-4" />}
                                        onClick={() => {
                                            setRequest(appointment?.requestId);
                                            if (appointment &&
                                                ['scheduled', 'rescheduled'].includes(appointment?.status?.toLowerCase())) {
                                                setIsRechedule(true);
                                                setScheduleFormData((prevData) => ({
                                                    ...prevData,
                                                    appointmentDate: formatDateForInput(appointment?.appointmentDate),
                                                    reason: ''
                                                }));
                                            }
                                            onOpen();
                                        }}
                                    >
                                        <span className="text-sm font-medium">
                                            {['scheduled', 'rescheduled'].includes(appointment?.status?.toLowerCase()) ? t('reschedule') : t('schedule-new')}
                                        </span>
                                    </Button>
                                }
                                {/* CONFIRM CANCEL APPOINTMENT  */}
                                {['scheduled', 'rescheduled'].includes(appointment?.status?.toLowerCase()) &&
                                    <ConfirmRejectRequest
                                        toolLabel="cancel-appointment"
                                        label="cancel-appointment"
                                        buttonLabel="cancel"
                                        handleSubmit={() => {
                                            changeAppointmentStatus(appointment?._id, 'CANCELLED', reason);
                                        }}
                                        isButton={true}
                                        message="cancel-request-msg"
                                        reason={reason}
                                        setReason={setReason}
                                    />
                                }
                                {/* FOLLOW UP  */}
                                {['completed', 'attended'].includes(appointment?.status?.toLowerCase()) && appointment?.requestId?.currentLastAppointment?._id == appointment?._id ?
                                    <Button
                                        bg="yellow.50"
                                        _hover={{ bg: "yellow.100" }}
                                        color="yellow.700"
                                        borderColor="yellow.200"
                                        leftIcon={<FiActivity className="w-4 h-4" />}
                                        onClick={() => {
                                            setRequest(appointment?.requestId);
                                            setIsRechedule(false);
                                            onOpen();
                                        }}
                                    >
                                        <span className="text-sm font-medium">{t('follow-up')}</span>
                                    </Button>
                                    : null}

                                {/* MARK MISSED BUTTON  */}
                                {['scheduled', 'rescheduled'].includes(appointment?.status?.toLowerCase()) &&
                                    <Button
                                        bg="purple.50"
                                        _hover={{ bg: "purple.100" }}
                                        color="purple.700"
                                        borderColor="purple.200"
                                        leftIcon={<IoAlertCircleOutline className="w-4 h-4" />}
                                        onClick={() => {
                                            changeAppointmentStatus(appointment?._id, 'MISSED');
                                        }}
                                    >
                                        <span className="text-sm font-medium">{t('mark-missed')}</span>
                                    </Button>
                                }

                                {/* MARK ATTENDED BUTTON */}
                                {['scheduled', 'rescheduled'].includes(appointment?.status?.toLowerCase()) &&
                                    <Button
                                        bg="teal.50"
                                        _hover={{ bg: "teal.100" }}
                                        color="teal.700"
                                        borderColor="teal.200"
                                        leftIcon={<IoCheckmarkCircleOutline className="w-4 h-4" />}
                                        onClick={() => {
                                            changeAppointmentStatus(appointment?._id, 'ATTENDED');
                                        }}
                                    >
                                        <span className="text-sm font-medium">{t('mark-attended')}</span>
                                    </Button>
                                }

                                {/* COMPLETE APPOINTMENT BUTTON  */}
                                {['scheduled', 'rescheduled', 'attended'].includes(appointment?.status?.toLowerCase()) &&
                                    <Button
                                        bg="green.50"
                                        _hover={{ bg: "green.100" }}
                                        color="green.700"
                                        borderColor="green.200"
                                        leftIcon={<CiCircleCheck className="w-4 h-4" />}
                                        onClick={() => {
                                            changeAppointmentStatus(appointment?._id, 'COMPLETED');
                                        }}
                                    >
                                        <span className="text-sm font-medium">{t('complete-appt')}</span>
                                    </Button>
                                }

                                {/* PAYMENT REQUEST BUTTON  HIDE FOR NOW */}
                                {/* <Button
                                bg="green.50"
                                _hover={{ bg: "green.100" }}
                                color="green.700"
                                borderColor="green.200"
                                leftIcon={<FaDollarSign className="w-4 h-4" />}
                            >
                                <span className="text-sm font-medium">{t('payment')}</span>
                            </Button> */}

                                {/* DOCTOR NOTE BUTTON  */}
                                <Button
                                    bg="cyan.50"
                                    _hover={{ bg: "cyan.100" }}
                                    color="cyan.700"
                                    borderColor="cyan.200"
                                    leftIcon={<FiFileText className="w-4 h-4" />}
                                    onClick={() => {
                                        setNoteType('doctor');
                                        onOpenNote();
                                    }}
                                >
                                    <span className="text-sm font-medium">{t('doctor-note')}</span>
                                </Button>

                                {/* MANAGER NOTE BUTTON  */}
                                {user?.role?.toLowerCase() == 'clinic-manager' &&
                                    <Button
                                        bg="pink.50"
                                        _hover={{ bg: "pink.100" }}
                                        color="pink.700"
                                        borderColor="pink.200"
                                        leftIcon={<LuBuilding2 className="w-4 h-4" />}
                                        onClick={() => {
                                            setNoteType('manager');
                                            onOpenNote();
                                        }}
                                    >
                                        <span className="text-sm font-medium">{t('manager-note')}</span>
                                    </Button>
                                }
                            </div>
                        </div>
                    }
                </div>

                {/* REQUEST DETAILS  */}
                <div className={cardBoxClass}>
                    <div className={`flex items-center gap-1`}>
                        <span className={headingClass}>{t('request-id')}</span>
                        <span className={headingClass}>{appointment?.requestId?.requestId || '--'}</span>
                    </div>
                    {/* INJURY DESCRIPTION/LATEST FEEDBACK  */}
                    <div className={`flex flex-col items-start gap-1`}>
                        <span className="text-gray-700 font-bold text-sm">
                            {t(descLabel)}
                        </span>
                        <span className={fieldValueClass}>
                            <Tooltip
                                isDisabled={!detailDesc || detailDesc?.length < 500}
                                label={detailDesc && detailDesc?.length > 500
                                    ? detailDesc
                                    : '--'}
                                aria-label="Full Injury Description"
                                hasArrow
                                placement="top"
                            >
                                <span>
                                    {detailDesc?.length > 500
                                        ? detailDesc.slice(0, 500) + '...'
                                        : detailDesc || '--'}
                                </span>
                            </Tooltip>
                        </span>
                    </div>
                    {/* STATUS LISTS  */}
                    <div className={`w-full flex flex-wrap items-start justify-between gap-6`}>
                        {/*  APPOINTMENT STATUS */}
                        <StatusFieldLabel
                            title={"appointment-status"}
                            value={appointment?.status}
                        />

                        {/*  PATIENT/PLAYER RECOVERY STATUS  */}
                        <StatusFieldLabel
                            title={"recovery-status"}
                            value={appointment?.requestId?.recoveryStatus}
                        />
                        {/*  PATIENT/PLAYER RECOVERY STATUS  */}
                        <StatusFieldLabel
                            title={"appointment-recovery-status"}
                            value={appointment?.recoveryStatus}
                        />
                        {/*  PATIENT/PLAYER RECOVERY STATUS  */}
                        <StatusFieldLabel
                            title={"payment-status"}
                            value={appointment?.paymentStatus}
                        />
                        {/*  PATIENT/PLAYER RECOVERY STATUS  */}
                        <StatusFieldLabel
                            title={"injury"}
                            value={appointment?.requestId?.injuryType}
                        />
                        {/*  PATIENT/PLAYER RECOVERY STATUS  */}
                        <StatusFieldLabel
                            title={"request-status"}
                            value={appointment?.requestId?.status}
                        />
                    </div>
                    {/* PATIENT/PLAYER PROFILE COMPONENT  */}
                    <div className={`flex items-start gap-12 justify-start`}>
                        <div className={`flex flex-col items-start justify-start gap-2`}>
                            <span className={statusLabelClass}>{t('scheduled-by')}</span>
                            <ProfileComponent
                                name={appointment?.createdBy?.name || '--'}
                                designation={appointment?.createdBy?.designation || appointment?.createdBy?.role || '--'}
                                profileImg={appointment?.createdBy?.pic}
                            />
                        </div>

                        {/* UPCOMING APPOINTMENT  */}
                        <div className={`flex flex-col items-start justify-start gap-2`}>
                            <span className={statusLabelClass}>{t('upcoming-appointment')}</span>
                            <div className={`flex gap-10 items-center justify-between`}>
                                <span className={fieldValueClass}>
                                    {formatDateArEn(appointment?.requestId?.currentLastAppointment?.appointmentDate, isArabic)}
                                </span>
                                <span className={fieldValueClass}>
                                    {formatTimeArEn(appointment?.requestId?.currentLastAppointment?.appointmentDate, isArabic)}
                                </span>
                            </div>
                        </div>

                        {/*  REQUEST TYPE  */}
                        <StatusFieldLabel
                            title={"request-type"}
                            // value={appointment?.requestId?.status}
                            value={appointment?.requestId?.corporateRequest && appointment?.requestId?.userAppointmentType?.toLowerCase() === 'external'
                                ? 'CORPORATE' // Display "corporate" for external corporate requests
                                : appointment?.requestId?.userAppointmentType // Display the translated userAppointmentType
                            }
                        />
                    </div>
                </div>
            </div>
            {/* SCHEDULE APPOINTMENT MODEL  */}
            <ScheduleAppointmentModal
                isOpen={isOpen}
                isRechedule={isRechedule}
                onClose={() => {
                    onClose();
                    setIsRechedule(false);
                }}
                scheduleFormData={scheduleFormData}
                onHandleChange={(field, value) => {
                    setScheduleFormData((prevData) => ({
                        ...prevData,
                        [field]: value
                    }));
                }}
                isLoading={isLoading}
                onHandleSubmit={() => {
                    if (isRechedule) {
                        reScheduleAppointment(appointmentId);
                    } else {
                        scheduleAppointment(appointment?.requestId?._id);
                    }
                }}
                request={request}
            />

            {/* UPDATE RECOVERY STATUS MODEL  */}
            <UpdateRecoveryDialogue
                isOpen={isOpenRecovery}
                isLoading={isLoading}
                formData={formData}
                onClose={() => {
                    onReconveryClose();
                    setFormData((prevData) => ({
                        ...prevData,
                        recoveryStatus: appointment?.requestId?.recoveryStatus,
                        feedback: ''
                    }))
                }}
                onHandleChange={(field, value) => {
                    setFormData((prevData) => ({
                        ...prevData,
                        [field]: value
                    }));
                }}
                onHandleSubmit={() => {
                    changeRequestRecoveryStatus(appointment?.requestId?._id, formData?.recoveryStatus, formData?.feedback)
                }}
            />

            {/* ADD FEEDBACK NOTE MODEL  */}
            <AddFeedbackNote
                isOpen={isOpenNote}
                isLoading={isLoading}
                note={note}
                noteType={noteType}
                setNote={setNote}
                label={noteType == 'manager' ? "add-manager-note" : "add-doctor-note"}
                onClose={() => {
                    onNoteClose();
                    if (note || noteType) {
                        setNote('');
                        setNoteType('');
                    }
                }}
                onHandleSubmit={() => {
                    addNote(appointment?._id, note, noteType)
                }}
            />

            {/* CONFIRM COMPLETE PAYMENT REQUEST */}
            <ConfirmCompletePayment
                isOpen={isOpenComplete}
                isLoading={isLoading}
                onClose={() => {
                    onCompleteClose();
                }}
                onCompleteRequest={() => {
                    completePaymentRequest(appointmentId, 'COMPLETED', 'CASH'); // CURRENTLY DEFAULT CASH WILL CHANGE FLOW LATTER 
                }}
            />
        </>
    )
}

export default AppointmentDetails


import React, { useState, useEffect } from 'react';
import { 
    Button, 
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalCloseButton, 
    ModalBody, 
    ModalFooter, 
    FormControl, 
    FormLabel, 
    Input, 
    Select, 
    useDisclosure, 
    useToast,
    Spinner,
    SimpleGrid
} from '@chakra-ui/react';
import { get, post } from '../../services/apis/api';
import { useTranslation } from 'react-i18next';

const CreateCoachHireRequest = ({ isOpen, onClose, getAllRequest }) => {
    const { onOpen } = useDisclosure();
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const [games, setGames] = useState([]);
    const [formValues, setFormValues] = useState({
        game: '',     
        experience: '',
        specialization: '',
        description: '',
        startDate: '',
        budget: "",
    });

    const getAllGames = async () => {
        const res = await get('game/dropdown');
        if (res.statusCode === 200) {
            setGames(res.data.dropdown);
        }
    };

    useEffect(() => {
        getAllGames();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const res = await post('request/coach-hiring', formValues);
            if (res.statusCode === 201) {
                onClose();
                setLoading(false);
                getAllRequest();
                toast({
                    title: 'Successful!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: 'Oops!',
                description: error?.response?.data?.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
            size="4xl"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('create-coach-hiring-request')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <SimpleGrid columns={2} spacing={4}  dir={isArabic ? 'rtl' : 'ltr'}>
                        <FormControl mb={4}>
                            <FormLabel>{t('game')}</FormLabel>
                            <Select
                                placeholder={t('select-game')}
                                name='game'
                                textAlign={isArabic ?  "center" : "left"}
                                value={formValues.game}
                                onChange={handleChange}
                            >
                                {games.map((game, index) => (
                                    <option key={index} value={game._id}>
                                        {game.name}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl mb={4}>
                            <FormLabel>{t('experience')}</FormLabel>
                            <Input
                                type="number"
                                placeholder='5'
                                name='experience'
                                value={formValues.experience}
                                onChange={handleChange}
                            />
                        </FormControl>

                        <FormControl mb={4}>
                            <FormLabel>{t('specialization')}</FormLabel>
                            <Input
                                type="text"
                                placeholder={t('specialization')}
                                name='specialization'
                                value={formValues.specialization}
                                onChange={handleChange}
                            />
                        </FormControl>

                        <FormControl mb={4}>
                            <FormLabel>{t('budget')}</FormLabel>
                            <Input
                                type="number"
                                placeholder='50,000'
                                name='budget'
                                value={formValues.budget}
                                onChange={handleChange}
                            />
                        </FormControl>

                        <FormControl mb={4}>
                            <FormLabel>{t('start-date')}</FormLabel>
                            <Input
                                type="date"
                                placeholder='date'
                                name='startDate'
                                value={formValues.startDate}
                                onChange={handleChange}
                            />
                        </FormControl>

                        <FormControl mb={4}>
                            <FormLabel>{t('description')}</FormLabel>
                            <Input
                                type="text"
                                placeholder={t('description')}
                                name='description'
                                value={formValues.description}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </SimpleGrid>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={handleSubmit}>
                        {loading ? <Spinner /> : t("save")}
                    </Button>
                    <Button onClick={onClose}>{t('cancel')}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CreateCoachHireRequest;

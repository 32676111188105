// import { io } from 'socket.io-client';
import io from "socket.io-client";
import { socketBaseUrl } from "./apis/api";

class SocketService {
    static socket = null;
    static initialized = false;

    // Retrieve token from localStorage
    static getToken() {
        try {
            const token = localStorage.getItem("token");
            console.log(token, "localStorage.getItem");
            return token ? JSON.parse(token) : null; // Ensure the token is parsed
        } catch (e) {
            console.log(`ERROR WHILE GETTING TOKEN ${e}`);
        }
    }

    static get instance() {
        if (!this.socket || !this.initialized) {
            throw new Error(
                "Socket not initialized. Call SocketService.initSocket() first."
            );
        }
        return this.socket;
    }

    static async initSocket() {
        if (this.initialized) return;

        const token = JSON.parse(localStorage.getItem("token"));

        if (!token) {
            console.info(
                "Socket initialization skipped - User not authenticated"
            );
            return;
        }

        // console.log(`Bearer ${token}`, '`Bearer ${token}`');

        try {
            this.socket = io(socketBaseUrl, {
                transports: ["websocket"],
                path: "/socket.io/",
                query: {
                    authToken: `Bearer ${token}`, // Add the token to the query string
                },
                autoConnect: true,
                reconnectionAttempts: 3,
            });

            // Set up basic socket event handlers
            this.socket.on("connect", () => {
                console.info("Socket Connected");
            });

            this.socket.on("connect_error", (error) => {
                console.error("Socket Connection Error:", error);
            });

            this.socket.on("disconnect", () => {
                console.warn("Socket Disconnected");
            });

            this.socket.on("testEvent", (data) => {
                console.log("Received testEvent:", data);
            });

            // Listen for events
            this.socket.on("newAnnouncement", (data) => {
                console.log("Received newAnnouncement:", data);
                // Call the provided callback to handle the event data
                if (this.onNewAnnouncement) this.onNewAnnouncement(data);
            });

            this.initialized = true;
            console.info("Socket initialized successfully");
        } catch (error) {
            console.error("Socket initialization failed:", error);
            this.initialized = false;
            throw error;
        }
    }

    // Set listener for new announcements
    static listenAnnouncementCreation(callback) {
        console.log("Setting up listener for new announcements");
        this.onNewAnnouncement = callback;
    }

    // Disconnect the socket
    static disconnect() {
        if (this.socket && this.initialized) {
            this.socket.disconnect();
            this.initialized = false;
            console.info("Socket disconnected");
        }
    }

    // Return the connection status
    static get isInitialized() {
        return this.initialized;
    }
}

export default SocketService;

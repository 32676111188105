import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { get, patch, post } from "../../services/apis/api";
import _ from "lodash";
import { Button } from "@chakra-ui/react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import UpdatePassword from "./dialogBoxes/Updatepassword";

const ViewAlRwaadUser = ({ showAlRwaadUsers, setShowAlRwaadUsers }) => {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState("");
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const getUsers = async (searchQuery = "") => {
        try {
            const res = await get(
                `user/alrwaad-user/all?role=ALRWAAD USER`
            );
            console.log(res);
            setUsers(res?.data?.users);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    const handleCheckboxChange = async (id, value) => {
        try {
            const res = await post(`user/update/${id}`, { isActive: value });
            console.log(res);
            if (res.statusCode === 200) {
                getUsers();
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Debounced search function
    const debouncedSearch = _.debounce((query) => {
        getUsers(query);
    }, 300);

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearch(query);
        debouncedSearch(query);
    };

    return (
        <>
            {
                showAlRwaadUsers ?
                    <div>
                        <Button margin={2} colorScheme="blue" onClick={() => setShowAlRwaadUsers(false)} > {t("back")}</Button>


                        <div className="flex justify-between px-2 items-center mb-4">
                            <div>
                                <div className="relative">
                                    <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                                        <svg
                                            className="w-4 h-4 text-gray-500"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                            />
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        id="table-search-users"
                                        className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                                        placeholder={t("search-users-name")}
                                        value={search}
                                        autoComplete="off"
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                            <div>
                                <div>{t("total-user")}: {users.length}</div>
                            </div>
                        </div>
                        <div>
                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                <table className="w-full text-sm text-left text-gray-500">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                        <tr>
                                            <th scope="col" className="p-4">
                                                <div className="flex items-center">
                                                    {t("s-no")}
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                {t("user-name")}
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                {t("phone")}
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                {t("role")}
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                {t("status")}
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                {t("action")}
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                {t("password")}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((user, index) => (
                                            <tr
                                                className="bg-white border-b hover:bg-gray-50"
                                                key={user._id}
                                            >
                                                <td className="w-4 p-4">
                                                    <div className="flex items-center">
                                                        {index + 1}
                                                    </div>
                                                </td>
                                                <th
                                                    scope="row"
                                                    className="flex items-center py-4 text-gray-900 whitespace-nowrap"
                                                >
                                                    <img
                                                        className="w-10 h-10 rounded-full"
                                                        src="https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg"
                                                        alt="User"
                                                    />
                                                    <div className="ps-3">
                                                        <div className="text-base font-semibold">
                                                            {user.name}
                                                        </div>
                                                        <div className="font-normal text-gray-500">
                                                            {user.email}
                                                        </div>
                                                    </div>
                                                </th>
                                                <td className="px-6 py-4">{user.mobile}</td>
                                                <td className="px-6 py-4">
                                                    <div className="flex items-center">
                                                        {user.role == "INTERNAL USER"
                                                            ? "PLAYER"
                                                            : user.role == "EXTERNAL USER"
                                                                ? "USER"
                                                                : user.role}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">
                                                    <div className="flex items-center">
                                                        <div
                                                            className={
                                                                user.isActive
                                                                    ? "h-2.5 w-2 rounded-full bg-green-500 me-2"
                                                                    : "h-2.5 w-2.5 rounded-full bg-red-500 me-2"
                                                            }
                                                        ></div>
                                                        {user.isActive
                                                            ? "Active"
                                                            : "Inactive"}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 flex items-center">
                                                    <label className="inline-flex items-center cursor-pointer me-8">
                                                        <input
                                                            type="checkbox"
                                                            className="sr-only peer"
                                                            checked={user.isActive}
                                                            onChange={() =>
                                                                handleCheckboxChange(
                                                                    user._id,
                                                                    !user.isActive
                                                                )
                                                            }
                                                        />
                                                        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                                    </label>
                                                </td>
                                                <td>
                                                    <UpdatePassword userId={user._id} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> : <div>
                        <Button colorScheme="blue" onClick={() => setShowAlRwaadUsers(true)} > {t("show-al-rwaad-users")}</Button>
                    </div>
            }
        </>

    );
};

export default ViewAlRwaadUser;

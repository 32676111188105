import {
  Button,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  SimpleGrid,
  GridItem,
  Select,
  useToast,
  Spinner,
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import ReactSelect from 'react-select';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { get, postFormData } from '../../services/apis/api'
import { useTranslation } from 'react-i18next';

const ContractRequest = ({ isOpen, onClose }) => {
  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)
  const toast = useToast();
  const [players, setPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  // State to hold form values
  const [formValues, setFormValues] = useState({
    playerId: '',
    status: 'GAME-MANAGER-REVIEW',
    annualContractAmount: '',
    paymentMethod: 'Monthly',
    rewardTerms: '',
    housing: '',
    car: '',
    specialConditions: '',
    attachments: {
      idOrPassport: null,
    },
  })

  useEffect(() => {
    getPlayers();
  }, []);

  // Fetch players data
  const getPlayers = async () => {
    try {
      const res = await get('player/dropdown');
      if (res.statusCode === 200) {
        const formattedPlayers = res.data.dropdown.map(player => ({
          label: player.name,
          value: player._id
        }));
        setPlayers(formattedPlayers);
      }
    } catch (error) {
      console.error('Error fetching players:', error);
    }
  }

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  // Handle nested input change for attachments
  const handleNestedChange = (e) => {
    const { name, files } = e.target
    setFormValues({
      ...formValues,
      attachments: { ...formValues.attachments, [name]: files[0] }
    })
  }

  const handleSave = async() => {
    try {
      setLoading(true)
      const formData = new FormData();
      console.log(formValues);
  
      for (const [key, value] of Object.entries(formValues)) {
        if (key !== 'attachments') {
          formData.append(key, value);
        }
      }
      formData.append("idOrPassport" , formValues.attachments.idOrPassport);
  
      const res = await postFormData('request/contract', formData);
      console.log(res);
      if(res.statusCode === 200){
        onClose();
        setLoading(false);
        toast({
            title: 'Successful!',
            description: res.data.message,
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
      }      
    } catch (error) {
      setLoading(false);
      console.error(error);
      if(error.response.status === 500){
        toast({
          title: 'Error!',
          description: "Please fill all the fields correctly",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  }

  // Handle player select change
  const handlePlayerSelectChange = (selectedOption) => {
    setSelectedPlayer(selectedOption);
    setFormValues({
      ...formValues,
      playerId: selectedOption ? selectedOption.value : ''
    });
  }

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('create-contract-request')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <SimpleGrid columns={3} spacing={4} dir={isArabic ? 'rtl' : 'ltr'}>

              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>{t('player-id')}</FormLabel>
                  <ReactSelect
                    className='text-right'
                    options={players}
                    value={selectedPlayer}
                    onChange={handlePlayerSelectChange}
                    placeholder={t('player-id')}
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>{t('annual-contract-amount')}</FormLabel>
                  <Input
                    name="annualContractAmount"
                    type="number"
                    value={formValues.annualContractAmount}
                    onChange={handleChange}
                    placeholder={t('annual-contract-amount')}
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>{t('payment-method')}</FormLabel>
                  <Select
                    name="paymentMethod"
                    value={formValues.paymentMethod}
                    onChange={handleChange}
                    textAlign={'center'}
                    placeholder={t('select-payment-method')}>
                    <option value='Monthly'>{t('monthly')}</option>
                    <option value='Quarterly'>{t('quarterly')}</option>
                    <option value='Yearly'>{t('yearly')}</option>
                  </Select>
                </FormControl>
              </GridItem>

              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>{t('reward-terms')}</FormLabel>
                  <Input
                    name="rewardTerms"
                    value={formValues.rewardTerms}
                    onChange={handleChange}
                    placeholder={t('reward-terms')}
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>{t('housing')}</FormLabel>
                  <Input
                    name="housing"
                    value={formValues.housing}
                    onChange={handleChange}
                    placeholder={t('housing')}
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>{t('car')}</FormLabel>
                  <Input
                    name="car"
                    value={formValues.car}
                    onChange={handleChange}
                    placeholder={t('car')}
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>{t('special-conditions')}</FormLabel>
                  <Input
                    name="specialConditions"
                    value={formValues.specialConditions}
                    onChange={handleChange}
                    placeholder={t('special-conditions')}
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>{t('attechment-id-or-passport')}</FormLabel>
                  <Input
                    type="file"
                    name="idOrPassport"
                    onChange={handleNestedChange}
                    accept=".jpg, .jpeg, .png, .pdf"
                  />
                </FormControl>
              </GridItem>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3}>{t('cancel')}</Button>
            <Button colorScheme="blue" onClick={handleSave}>
              {loading ? <Spinner /> : t("save")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ContractRequest

import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Text,
} from '@chakra-ui/react';

const ViewAssetDetailsModal = ({ isOpen, onClose, asset }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Asset Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text><strong>Asset Name:</strong> {asset?.name}</Text>
                    <Text><strong>Model:</strong> {asset?.model}</Text>
                    <Text><strong>Asset ID:</strong> {asset?.serialNumber}</Text>
                    <Text><strong>Price:</strong> {asset?.price}</Text>
                    <Text><strong>Depritiation Rate:</strong> {asset?.depritiationRate}%</Text>
                    <Text className='capitalize'><strong>Depritiation Frequency:</strong> {asset?.depritiationFrequency}</Text>
                    <Text><strong>Purchase Date:</strong> {new Date(asset?.purchaseDate).toLocaleDateString()}</Text>
                    <Text><strong>Status:</strong> {asset?.status}</Text>
                    <Text><strong>Condition:</strong> {asset?.condition}</Text>
                    <Text><strong>Category:</strong> {asset?.category}</Text>
                    <Text><strong>Description:</strong> {asset?.description}</Text>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ViewAssetDetailsModal;

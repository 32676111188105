import React, { useContext, useEffect } from 'react';
import { Box, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { NetworkStatusContext } from '../../context/networkStatusContext';
import { useToast } from '@chakra-ui/react'

const NetworkError = () => {
    const { isOnline, lastVisitedPath } = useContext(NetworkStatusContext);
    const navigate = useNavigate();
    const toast = useToast()
    useEffect(() => {
        if (isOnline && lastVisitedPath) {
        navigate(lastVisitedPath);
        toast({
            title: 'You are back online!',
            description: "",
            status: 'success',
            duration: 5000,
            isClosable: true,
        })
        }
    }, [isOnline, lastVisitedPath, navigate]);

    const handleRetry = () => {
        if (navigator.onLine) {
            console.log("last--->", lastVisitedPath, navigator.onLine);
            navigate(lastVisitedPath || '/');
        }
    };

    return (
        <Box textAlign="center" mt={10}>
        <Text fontSize="2xl" mb={4}>You are offline</Text>
        <Text>Please check your internet connection.</Text>
        <Button mt={4} onClick={handleRetry}>Retry</Button>
        </Box>
    );
};

export default NetworkError;

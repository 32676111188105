import React, { useEffect, useState } from 'react'
import { get, patch } from '../../services/apis/api';
import CreateTeam from '../../components/dialogueBoxes/CreateTeam'
import ViewEvent from '../../components/mediaCenter/ViewEvent';


const scoreCard = [1,2,3,4,5,6]
const ScoreCard = () => {
  const [ teams, setTeams] = useState([]);
  const [viewEvent, setViewEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  return (
    <div>
    {
        viewEvent ? 
            <ViewEvent event={selectedEvent} setViewEvent={setViewEvent}/>      : 
            <div>
               <div className='flex justify-between px-2 items-center mb-4'>
                 <div>
                     <div class="relative">
                         <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                             <svg class="w-4 h-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                 <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                             </svg>
                         </div>
                         <input type="text" id="table-search-users" class="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Search for tournament" />
                     </div>
                 </div>
                 <div>
                     {/* <CreateTeam getAllTeams={getAllTeams}/> */}
                 </div>
               </div>
               <div>
                 <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                     <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
                         <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
                             <tr>
                                 <th scope="col" class="p-4">
                                     <div class="flex items-center">
                                       S.No.
                                     </div>
                                 </th>
                                 <th scope="col" class="px-6 py-3">
                                      Tournament
                                 </th>
                                 <th scope="col" class="px-6 py-3">
                                     Description
                                 </th>
                                 <th scope="col" class="px-6 py-3">
                                     Winner Team
                                 </th>
                             </tr>
                         </thead>
                         <tbody>
                             {
                             scoreCard.map((team, index)=>(
                                 <tr class="bg-white border-b  hover:bg-gray-50 ">
                                     <td class="w-4 p-4">
                                         <div class="flex items-center">
                                         {index+1}
                                         </div>
                                     </td>
                                     <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap ">
                                       <div class="text-base font-semibold">{"Cricket Tournament"}</div>                                    
                                     </th>
                                     <td class="px-6 py-4">
                                         {"this is the Tournament discription"}
                                     </td>
                                     <td class="px-6 py-4">
                                         <div class="flex items-center">
                                             {"Team A"}
                                         </div>
                                     </td>
                         
                                 </tr>
                             ))
                             }
                         </tbody>
                     </table>
                 </div>
               </div>
             </div>

    }
</div>
  )
}


export default ScoreCard
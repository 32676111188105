import React, { createContext, useState, useEffect } from 'react';

const NetworkStatusContext = createContext();

const NetworkStatusProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [lastVisitedPath, setLastVisitedPath] = useState(window.location.pathname);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <NetworkStatusContext.Provider value={{ isOnline, lastVisitedPath, setLastVisitedPath }}>
      {children}
    </NetworkStatusContext.Provider>
  );
};

export { NetworkStatusContext, NetworkStatusProvider };

import React, { useState } from 'react'
import { get } from '../../../services/apis/api'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useToast,
    useDisclosure,
    Input,
    FormControl,
    FormLabel,
    Spinner,
  } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const GeneratePayroll = ({fetchData}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)
    const [loading, setLoading] = useState(false)
    
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 30)); // Set initial date to the 30th of current month
    console.log("-------------->", startDate);
    

    const generatePayrollForAll = async () => {
        try {
            setLoading(true);
            const res = await get(`payroll/generate?date=${startDate}`)
            if (res.statusCode === 200) {
                setLoading(false);
                toast({
                    title: 'Success',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                fetchData();
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: 'Error',
                description: 'Failed to generate Payroll',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    }
    const handleDateChange = (date) => {
        const updatedDate = new Date(date.getFullYear(), date.getMonth(), 30);
        setStartDate(updatedDate); 
    };

    return (
        <>
            
            <Button colorScheme="blue" me={2} onClick={onOpen} >
                Gen Payroll
            </Button>

            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create your account</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl mt={2}>
                            <FormLabel>{t('select-Month')}<span className='text-red-500'>*</span></FormLabel>
                            <DatePicker
                                selected={startDate}
                                onChange={handleDateChange} // Ensure the 30th day is selected
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                className='w-full border rounded-md p-2'
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button colorScheme='blue' mx={3} onClick={generatePayrollForAll}>
                            {
                                loading ? <Spinner /> : 'Gen Payroll'
                            }
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default GeneratePayroll

import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  useToast,
  Spinner,
  Input,
  FormControl,
  FormLabel,
  Switch,
  Image,
  Flex,
  Text,
  HStack,
  Tag,
  TagLabel,
  IconButton,
  Stack,
  Checkbox
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { get, postFormData } from "../../services/apis/api";
import { useTranslation } from "react-i18next";

const CreateGame = ({ getGames, game, isEdit }) => {
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [image, setImage] = useState();
  const [name, setName] = useState('');
  const [arabicName, setArabicName] = useState('');
  const [stage, setStage] = useState([]);
  const [imgLink, setImgLink] = useState('');
  const [active, setActive] = useState(true);
  const [loading, setLoading] = useState(false);
  const [ageGroups, setAgeGroups] = useState([]);
  const [ageGroupFrom, setAgeGroupFrom] = useState('');
  const [ageGroupTo, setAgeGroupTo] = useState('');
  const [showAgeGroups, setShowAgeGroups] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);

  useEffect(() => {
    if (isEdit && game) {
      setName(game.name);
      setArabicName(game?.arabicName);
      setStage(game.stage);
      setImgLink(game.icon);
      setActive(game.active);
      setAgeGroups(game.ageCategoryIds.map(cat => ({
        from: cat.minAge,
        to: cat.maxAge,
        isActive: cat.isActive || false,
        isNew: false,
        ageCategoryId: cat._id
      })) || []);
      if (game.stage.includes("PROFESSIONAL")) {
        setShowAgeGroups(true);
      } else {
        setShowAgeGroups(false);
      }
    }
  }, [isEdit, game]);

  const handleSave = async (id) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("icon", image);
      formData.append("name", name);
      formData.append("arabicName", arabicName);
      formData.append("stage", JSON.stringify(stage));
      formData.append("active", active);
      if (showAgeGroups) {
        formData.append("ageGroups", JSON.stringify(ageGroups));
      }

      let res;
      if (isEdit) {
        res = await postFormData(`game/update/${id}`, formData);
      } else {
        res = await postFormData('game/add', formData);
      }

      if (res.statusCode === 201 || res.statusCode === 200) {
        setLoading(false);
        onClose();
        toast({
          title: 'Success!',
          description: res.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        getGames();
        resetForm();
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: 'Error!',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleOpen = () => {
    onOpen();
  };

  const resetForm = () => {
    setName('');
    setArabicName('');
    setStage([]);
    setImgLink('');
    setAgeGroups([]);
    setShowAgeGroups(false);
    setEditingIndex(null);
  };

  const handleStageChange = (e) => {
    const { name, checked } = e.target;
    setStage(prevStage => {
      if (checked) {
        return [...prevStage, name];
      } else {
        return prevStage.filter(stage => stage !== name);
      }
    });

    if (name === "PROFESSIONAL") {
      setShowAgeGroups(checked);
    }
  };

  const addAgeGroup = () => {
    if (ageGroupFrom && ageGroupTo) {
      const newGroup = { from: ageGroupFrom, to: ageGroupTo, isActive: true, isNew: true };

      if (editingIndex !== null) {
        const updatedGroups = [...ageGroups];
        updatedGroups[editingIndex] = { ...newGroup, isNew: ageGroups[editingIndex].isNew, ageCategoryId: ageGroups[editingIndex].ageCategoryId };
        setAgeGroups(updatedGroups);
        setEditingIndex(null);
      } else {
        const exists = ageGroups.some(
          group => group.from === newGroup.from && group.to === newGroup.to
        );

        if (exists) {
          toast({
            title: 'Duplicate Age Group',
            description: 'This age group already exists.',
            status: 'warning',
            duration: 5000,
            isClosable: true,
          });
        } else {
          setAgeGroups([...ageGroups, newGroup]);
        }
      }

      setAgeGroupFrom('');
      setAgeGroupTo('');
    }
  };

  const editAgeGroup = (index) => {
    setAgeGroupFrom(ageGroups[index].from);
    setAgeGroupTo(ageGroups[index].to);
    setEditingIndex(index);
  };

  const deleteAgeGroup = (index) => {
    const updatedGroups = [...ageGroups];
    updatedGroups.splice(index, 1);
    setAgeGroups(updatedGroups);
  };

  const toggleAgeGroupActive = (index) => {
    const updatedGroups = [...ageGroups];
    updatedGroups[index].isActive = !updatedGroups[index].isActive;
    setAgeGroups(updatedGroups);
  };

  return (
    <>
      <Button colorScheme="blue" onClick={handleOpen}>{isEdit ? t("edit") : t("create-game")}</Button>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent dir={isArabic ? 'rtl' : 'ltr'}>
          <ModalHeader>{isEdit ? t("edit-game") : t("create-new-game")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              {imgLink && <Image src={imgLink} alt="Game Icon" mb={3} boxSize="100px" objectFit="cover" />}
              <FormLabel>{t("upload-logo")}</FormLabel>
              <Input type="file" onChange={(e) => { setImage(e.target.files[0]); const imageUrl = URL.createObjectURL(e.target.files[0]); setImgLink(imageUrl); }} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("game-name")}</FormLabel>
              <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Football" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("game-name-in-arabic")}</FormLabel>
              <Input value={arabicName} onChange={(e) => setArabicName(e.target.value)} placeholder="كرة القدم" />
            </FormControl>
            <FormControl mt={4}>
            </FormControl>
            <FormLabel>{t("select-a-stage")}</FormLabel>
            <Stack direction="row">
              <Checkbox
                name="SCHOOL"
                isChecked={stage.includes("SCHOOL")}
                onChange={handleStageChange}
              >
                {t("school")}
              </Checkbox>
              <Checkbox
                name="ACADEMY"
                isChecked={stage.includes("ACADEMY")}
                onChange={handleStageChange}
              >
                {t("academy")}
              </Checkbox>
              <Checkbox
                name="PROFESSIONAL"
                isChecked={stage.includes("PROFESSIONAL")}
                onChange={(e) => {
                  handleStageChange(e);
                }}
              >
                {t("professional")}
              </Checkbox>
            </Stack>
            {showAgeGroups && (
              <>
                <FormControl mt={4}>
                  <FormLabel>{t("age-groups")}</FormLabel>
                  <Flex gap={2} alignItems={'center'}>
                    <Text>{t("from")}</Text>
                    <Input value={ageGroupFrom} onChange={(e) => setAgeGroupFrom(e.target.value)} placeholder="E.g. 14" />
                    <Text>{t("to")}</Text>
                    <Input value={ageGroupTo} onChange={(e) => setAgeGroupTo(e.target.value)} placeholder="E.g. 15" />
                    <Button w={60} py={1} px={2} colorScheme="blue" onClick={addAgeGroup}>
                      {editingIndex !== null ? t("update") : t("add")}
                    </Button>
                  </Flex>
                </FormControl>
                <Flex mt={4} align="start" wrap={'wrap'}>
                  {ageGroups.map((group, index) => (
                    <HStack key={index}>
                      <Tag m={1}>
                        <TagLabel>{`From: ${group.from} - To: ${group.to}`}</TagLabel>
                        <Switch
                          size="sm"
                          isChecked={group.isActive}
                          onChange={() => toggleAgeGroupActive(index)}
                          mx={1}
                        />
                        {group.isNew ? (
                          <IconButton
                            size="sm"
                            icon={<DeleteIcon />}
                            onClick={() => deleteAgeGroup(index)}
                            aria-label="Delete Age Group"
                          />
                        ) : (
                          <IconButton
                            size="sm"
                            icon={<EditIcon />}
                            onClick={() => editAgeGroup(index)}
                            aria-label="Edit Age Group"
                          />
                        )}
                      </Tag>
                    </HStack>
                  ))}
                </Flex>
              </>
            )}
            <FormControl mt={4} display="flex" alignItems="center">
              <FormLabel htmlFor="active" mb="0">
                {t("active")}
              </FormLabel>
              <Switch id="active" isChecked={active} onChange={() => setActive(!active)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} mx={3}>{t("cancel")}</Button>
            <Button onClick={() => handleSave(isEdit ? game?._id : "")} colorScheme="blue">
              {loading ? <Spinner /> : t("save")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateGame;

import React, { useEffect, useRef, useState } from 'react'
import { CiMail, CiPhone } from 'react-icons/ci';
import { FaDollarSign } from 'react-icons/fa6';
import { FiFileText, FiUsers } from 'react-icons/fi';
import { LuBuilding2, LuFileCheck, LuFileSpreadsheet } from 'react-icons/lu';
import BreadCrumbs from '../../components/BreadCrumbs';
import ConfirmApproveRequest from '../../components/clinic/ConfirmApproveRequest';
import ConfirmRejectRequest from '../../components/clinic/ConfirmRejectRequest';
import { Button, useDisclosure, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { get, post } from '../../services/apis/api';
import { UserState } from '../../context/user';
import { useLocation } from 'react-router-dom';
import { DocumentCard } from '../../components/clinic/ClinicComponent';
import { IoIosInformation } from 'react-icons/io';
import RequestMoreInfo from '../../components/clinic/RequestMoreInfo';
import AppointmentPaymentRequest from '../../components/clinic/ApppointmentPaymentRequest';
import ConfirmCompletePayment from '../../components/clinic/ConfirmCompletePayment';
import { FaCheckCircle } from 'react-icons/fa';

// PAYMENT FORM 
const _paymentForm = {
    amount: '',
    paymentNote: '',
    paymentDueDate: ''
};

const CorporateRequestDetails = () => {
    const { user } = UserState();
    const location = useLocation();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenPayement, onOpen: onOpenPayment, onClose: onPaymentClose } = useDisclosure();
    const { isOpen: isOpenComplete, onOpen: onOpenComplete, onClose: onCompleteClose } = useDisclosure();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const requestId = location?.pathname?.split('/')[3];
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [reason, setReason] = useState('');
    const [message, setMessage] = useState(''); // TO ASK MORE DETAILS ABOUR CORPORATE
    const [paymentFormData, setPaymentFormData] = useState({ ..._paymentForm });

    // GET REQUEST DETAILS  
    const hasFetched = useRef(false); // Initially false

    const getCorporateRequestById = async (requestId) => {
        try {
            if (user) {
                const res = await get(`clinic/corporate-request/${requestId}`)
                if (res?.statusCode === 200) {
                    setSelectedRequest(res?.data?.corporateRequest || {});
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!hasFetched.current) {
            getCorporateRequestById(requestId);
            hasFetched.current = true; // Mark as fetched
        }
    }, [])

    // GET REQUEST DETAILS  

    // CHANGE THE REQUEST STATUS 
    const changeRequestStatus = async (_id, _status) => {
        try {
            setIsLoading(true);
            const _body = {
                action: _status,
                note: reason || '',
                followUpMessage: message || ''
            }
            const response = await post(`clinic/update-corporate-request-status/${_id}`, _body);
            setIsLoading(false);
            if (response?.statusCode == 200) {
                getCorporateRequestById(requestId);
                onClose();
                setReason('');
                setMessage('');
                toast({
                    title: response?.data?.message || 'success',
                    description: "",
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: response?.data?.message || 'error',
                    description: "",
                    status: 'error',
                    duration: 6000,
                    isClosable: true,
                })
            }
        } catch (e) {
            setIsLoading(false);
            toast({
                title: e?.response?.data?.message || 'error',
                description: "",
                status: 'error',
                duration: 6000,
                isClosable: true,
            })
            console.log(`ERROR WHILE CHANGING STATUS ${e}`);
        }
    }


    // REQUEST PENDING PAYMENT FROM CORPORATE/ORGANIZATION
    const requestPayment = async (requestId, data, status) => {
        try {
            setIsLoading(true);
            const _body = {
                amount: Number(data?.amount) || 0,  // Ensure amount is a number
                paymentDueDate: data?.paymentDueDate || '',
                paymentNote: data?.paymentNote || '',
                action: status || ''
            }
            const response = await post(`clinic/corporate/request-payment/${requestId}`, _body);
            setIsLoading(false);
            if (response?.statusCode == 200) {
                onPaymentClose();
                setPaymentFormData(_paymentForm);
                getCorporateRequestById(requestId);
                toast({
                    title: response?.data?.message || 'success',
                    description: "",
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: response?.data?.message || 'error...',
                    description: "",
                    status: 'error',
                    duration: 6000,
                    isClosable: true,
                })
            }
        } catch (e) {
            setIsLoading(false);
            toast({
                title: e?.response?.data?.message || 'error...',
                description: "",
                status: 'error',
                duration: 6000,
                isClosable: true,
            })
            console.log(`ERROR WHILE REQUESTING PAYMENT ${e}`);
        }
    };

    // COMPLETE PAYMENT REQUEST 
    const completePaymentRequest = async (requestId, status, paymentMode) => {
        try {
            setIsLoading(true);
            const _body = {
                action: status || '',
                paymentMode: paymentMode || 'CASH' // CURRENTOY SET TO DEFAULT CASH
            }
            const response = await post(`clinic/corporate/complete-payment/${requestId}`, _body);
            setIsLoading(false);
            if (response?.statusCode == 200) {
                onCompleteClose();
                getCorporateRequestById(requestId);
                toast({
                    title: response?.data?.message || 'success...',
                    description: "",
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: response?.data?.message || 'error...',
                    description: "",
                    status: 'error',
                    duration: 6000,
                    isClosable: true,
                })
            }
        } catch (e) {
            setIsLoading(false);
            toast({
                title: e?.response?.data?.message || 'error...',
                description: "",
                status: 'error',
                duration: 6000,
                isClosable: true,
            })
            console.log(`ERROR WHILE REQUESTING PAYMENT ${e}`);
        }
    };

    // PAYMENT REQUEST LABEL NOW THE PAYMENT FLOW IS REQUEST WISE NOT APPOINTMENT WISE 
    const _paymentStatus = selectedRequest?.paymentStatus?.toLowerCase();
    const paymentButtonLabel = _paymentStatus == 'pending' ? 'request-payment' : _paymentStatus == 'requested' || _paymentStatus == 'modified' ? 'update-payment-request' : 'complete-payment-request';

    return (
        <>
            <BreadCrumbs />
            <div className="p-2 w-full">
                {/* Header */}
                <div className="flex items-center justify-between mb-6">
                    <h2 className='text-xl whitespace-nowrap font-bold'>
                        {t('request-details')}
                    </h2>
                    {/* ACTIONS  */}
                    <div className="flex space-x-4">
                        {selectedRequest?.status?.toLowerCase() == 'pending' &&
                            <>
                                {/* APPROVE BUTTON  */}
                                <ConfirmApproveRequest
                                    handleApprove={() => {
                                        changeRequestStatus(selectedRequest?._id, 'APPROVED');
                                    }}
                                    isLoading={isLoading}
                                    isButton={true} // FOR DISPLAY BUTTON INSTEAD OF ICON BUTTON 
                                />
                                {/* REJECT BUTTON  */}
                                <ConfirmRejectRequest
                                    toolLabel="reject-request"
                                    label="reject-request"
                                    buttonLabel="reject"
                                    isButton={true} // FOR DISPLAY BUTTON INSTEAD OF ICON BUTTON 
                                    handleSubmit={() => {
                                        changeRequestStatus(selectedRequest?._id, 'REJECTED');
                                    }}
                                    message="reject-request-msg"
                                    reason={reason}
                                    setReason={setReason}
                                />

                            </>
                        }
                        {/* REQUEST MORE DETAILS BUTTON  */}
                        <RequestMoreInfo
                            isButton={true} // FOR DISPLAY BUTTON INSTEAD OF ICON BUTTON 
                            message={message}
                            setMessage={setMessage}
                            isLoading={isLoading}
                            isOpen={isOpen}
                            onClose={() => {
                                onClose();
                                setMessage('');
                            }}
                            onOpen={onOpen}
                            handleSubmit={() => {
                                changeRequestStatus(selectedRequest?._id, 'PENDING');
                            }}
                        />

                        {/* DOWNLOAD REPORT ONLY ON APPROVED STATUS  */}
                        {/* {selectedRequest?.status?.toLowerCase() == 'approved' &&
                            <Button
                                bg="gray.50"
                                _hover={{ bg: "gray.100" }}
                                color="gray.700"
                                borderColor="gray.200"
                                // onClick={onOpen} // Uncomment or add the desired functionality
                                leftIcon={<LuFileSpreadsheet className="w-4 h-4" />}
                            >
                                <span className="text-sm font-medium">{t('download-report')}</span>
                            </Button>
                        } */}

                    </div>
                </div>

                {/* Organization Header */}
                <div className="bg-white rounded-xl border border-gray-200 p-6 mb-6">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                            <div className="bg-blue-50 p-4 rounded-lg">
                                <LuBuilding2 className="w-8 h-8 text-blue-600" />
                            </div>
                            <div>
                                <h1 className="text-2xl font-bold text-gray-900">{selectedRequest?.organizationName || '--'}</h1>
                                <div className="flex items-center space-x-4 mt-2">
                                    <span className={`px-3 py-1 rounded-full text-sm font-medium ${selectedRequest?.status === 'PENDING' ? 'bg-yellow-50 text-yellow-700' :
                                        selectedRequest?.status === 'APPROVED' ? 'bg-green-50 text-green-700' :
                                            'bg-red-50 text-red-700'
                                        }`}>
                                        {t(selectedRequest?.status?.toLowerCase()) || '--'}
                                    </span>
                                    <span className="text-sm text-gray-600">ID: {selectedRequest?.corporateId || '--'}</span>
                                    <span className="text-sm text-gray-600">{selectedRequest?.location || '--'}</span>
                                </div>
                            </div>
                        </div>

                        {/* WALLET BALANCE  */}
                        {selectedRequest?.status?.toLowerCase() == 'approved' &&
                            <div className="text-right">
                                <div className="text-sm font-medium text-gray-600">{t('wallet-balance')}</div>
                                <div className={`text-2xl font-bold ${selectedRequest?.walletBalance < 0 ? 'text-red-600' : 'text-green-600'}`}>
                                    ${selectedRequest?.walletBalance?.toLocaleString() || '0.00'}
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {/* Main Content Grid */}
                <div className="grid grid-cols-3 gap-6">
                    {/* Left Column */}
                    <div className="col-span-2 space-y-6">
                        {/* Organization Details */}
                        <div className="bg-white rounded-xl border border-gray-200 p-6">
                            <h2 className="text-lg font-semibold text-gray-900 mb-4">{t('organization-details')}</h2>
                            <div className="grid grid-cols-2 gap-6">
                                <div>
                                    <label className="text-sm font-medium text-gray-600">{('industry')}</label>
                                    <p className="mt-1 text-gray-900">{selectedRequest?.industry || '--'}</p>
                                </div>
                                <div>
                                    <label className="text-sm font-medium text-gray-600">{t('employee-count')}</label>
                                    <p className="mt-1 text-gray-900">{selectedRequest?.employeeCount || 0}</p>
                                </div>
                                <div>
                                    <label className="text-sm font-medium text-gray-600">{('website')}</label>
                                    <p className="mt-1 text-blue-600">{selectedRequest?.website || '--'}</p>
                                </div>
                                <div>
                                    <label className="text-sm font-medium text-gray-600">{('location')}</label>
                                    <p className="mt-1 text-gray-900">{selectedRequest?.location || '--'}</p>
                                </div>
                            </div>
                            <div className="mt-4">
                                <label className="text-sm font-medium text-gray-600">{t('description')}</label>
                                <p className="mt-1 text-gray-900">{selectedRequest?.description || ''}</p>
                            </div>
                        </div>

                        {/* License Information */}
                        <div className="bg-white rounded-xl border border-gray-200 p-6">
                            <div className="flex items-center justify-between mb-4">
                                <h2 className="text-lg font-semibold text-gray-900">{t('license-information')}</h2>
                                <span className={`px-3 py-1 rounded-full text-sm font-medium ${selectedRequest?.licenseStatus === 'VALID' ? 'bg-green-50 text-green-700' :
                                    selectedRequest?.licenseStatus === 'EXPIRED' ? 'bg-red-50 text-red-700' :
                                        'bg-yellow-50 text-yellow-700'
                                    }`}>
                                    {selectedRequest?.licenseStatus || '--'}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-6">
                                <div>
                                    <label className="text-sm font-medium text-gray-600">{t('license-number')}</label>
                                    <p className="mt-1 text-gray-900">{selectedRequest?.licenseNumber}</p>
                                </div>
                                <div>
                                    <label className="text-sm font-medium text-gray-600">{t('expiry-date')}</label>
                                    <p className="mt-1 text-gray-900">{new Date(selectedRequest?.licenseExpiryDate)?.toLocaleDateString() || '--'}</p>
                                </div>
                            </div>
                        </div>

                        {/* Contact Information */}
                        <div className="bg-white rounded-xl border border-gray-200 p-6">
                            <h2 className="text-lg font-semibold text-gray-900 mb-4">{t('contact-information')}</h2>
                            <div className="flex space-x-6">
                                {/* PRIMARY CONTACT  */}
                                <div className="w-1/2 border-r">
                                    <h3 className="text-sm font-medium text-gray-600 mb-3">{t('primary-contact')}</h3>
                                    <div className="space-y-2">
                                        <p className="font-medium text-gray-900">{selectedRequest?.primaryContact?.name || '--'}</p>
                                        <p className="text-sm text-gray-600">{selectedRequest?.primaryContact?.position || '--'}</p>
                                        <div className="flex items-center space-x-2 text-sm text-gray-600">
                                            <CiMail className="w-4 h-4" />
                                            <span>{selectedRequest?.primaryContact?.email || '--'}</span>
                                        </div>
                                        <div className="flex items-center space-x-2 text-sm text-gray-600">
                                            <CiPhone className="w-4 h-4" />
                                            <span>{selectedRequest?.primaryContact?.phone || '--'}</span>
                                        </div>
                                    </div>
                                </div>
                                {/* ALTERNAT CONTACT  */}
                                <div className="w-1/2">
                                    <h3 className="text-sm font-medium text-gray-600 mb-3">{t('alternate-contact')}</h3>
                                    <div className="space-y-2">
                                        <p className="font-medium text-gray-900">{selectedRequest?.alternateContact?.name || '--'}</p>
                                        <p className="text-sm text-gray-600">{selectedRequest?.alternateContact?.position || '--'}</p>
                                        <div className="flex items-center space-x-2 text-sm text-gray-600">
                                            <CiMail className="w-4 h-4" />
                                            <span>{selectedRequest?.alternateContact?.email || '--'}</span>
                                        </div>
                                        <div className="flex items-center space-x-2 text-sm text-gray-600">
                                            <CiPhone className="w-4 h-4" />
                                            <span>{selectedRequest?.alternateContact?.phone || '--'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Documents */}
                        <div className="bg-white rounded-xl border border-gray-200 p-6">
                            <h2 className="text-lg font-semibold text-gray-900 mb-4">{t('submitted-documents')}</h2>
                            {/* REQUEST LATTER  */}
                            <DocumentCard
                                name={"Request Latter"}
                                uploadedDate={selectedRequest?.requestLetter?.uploadedAt}
                                status={selectedRequest?.requestLetter?.status}
                            />

                            <div className="space-y-4">
                                {selectedRequest?.documents?.map((doc, index) => (
                                    <DocumentCard
                                        key={doc?.id || index}
                                        name={doc?.name || '--'}
                                        uploadedDate={doc?.uploadedAt || '--'}
                                        status={doc?.status || '--'}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Right Column */}
                    <div className="space-y-6">
                        {selectedRequest?.status?.toLowerCase() == 'approved' &&
                            <>
                                {/* Financial Summary */}
                                <div className="bg-white rounded-xl border border-gray-200 p-6">
                                    <h2 className="text-lg font-semibold text-gray-900 mb-4">{t('financial-summary')}</h2>
                                    <div className="space-y-4">
                                        <div>
                                            <label className="text-sm font-medium text-gray-600">{t('total-treatment-cost')}</label>
                                            <p className="mt-1 text-xl font-semibold text-gray-900">
                                                ${selectedRequest?.totalTreatmentCost?.toLocaleString() || '0.00'}
                                            </p>
                                        </div>
                                        {selectedRequest?.pendingPayments ?
                                            <div>
                                                <label className="text-sm font-medium text-gray-600">{t('pending-payments')}</label>
                                                <p className="mt-1 text-xl font-semibold text-red-600">
                                                    ${selectedRequest?.pendingPayments?.toLocaleString() || '0.00'}
                                                </p>
                                            </div>
                                            : null}

                                        {selectedRequest?.requestedAmount ?
                                            <div>
                                                <label className="text-sm font-medium text-gray-600">{t('requested-amount')}</label>
                                                <p className="mt-1 text-xl font-semibold text-red-600">
                                                    ${selectedRequest?.requestedAmount?.toLocaleString() || '0.00'}
                                                </p>
                                            </div>
                                            : null}

                                        {selectedRequest?.lastPaymentDate && selectedRequest?.requestedAmount ?
                                            <div>
                                                <label className="text-sm font-medium text-gray-600">{t('last-payment-date')}</label>
                                                <p className="mt-1 text-gray-900">
                                                    {selectedRequest?.lastPaymentDate ? new Date(selectedRequest?.lastPaymentDate)?.toLocaleDateString() : '--'}
                                                </p>
                                            </div>
                                            : null}


                                        {selectedRequest?.paymentNote && selectedRequest?.requestedAmount ?
                                            <div>
                                                <label className="text-sm font-medium text-gray-600">{t('payment-note')}</label>
                                                <p className="mt-1 text-gray-900">
                                                    {selectedRequest?.paymentNote || '--'}
                                                </p>
                                            </div>
                                            : null}

                                        <div className={`flex items-center gap-3`}>
                                            {selectedRequest?.paymentStatus == 'PENDING' || selectedRequest?.pendingPayments ?
                                                <Button
                                                    colorScheme='blue'
                                                    size="sm"
                                                    onClick={() => {
                                                        onOpenPayment();
                                                        if (selectedRequest?.pendingPayments) {
                                                            setPaymentFormData((prevDate) => ({
                                                                ...prevDate,
                                                                amount: selectedRequest?.pendingPayments,
                                                            }))
                                                        }
                                                    }}
                                                >
                                                    <FaDollarSign className="w-4 h-4" />
                                                    <span>{t('request')}</span>
                                                </Button>
                                                : null}

                                            {/* COMPLETE PAYMENT BUTTON  */}
                                            {selectedRequest?.paymentStatus == 'REQUESTED' || selectedRequest?.paymentStatus == 'MODIFIED' || selectedRequest?.requestedAmount ?
                                                <Button
                                                    colorScheme="green"
                                                    aria-label="Complete"
                                                    onClick={() => {
                                                        onOpenComplete();
                                                    }}
                                                    size="sm"
                                                    leftIcon={<FaCheckCircle />}
                                                >
                                                    {t('complete')}
                                                </Button>
                                                : null}
                                        </div>
                                    </div>
                                </div>

                                {/* Treatment Statistics */}
                                <div className="bg-white rounded-xl border border-gray-200 p-6">
                                    <h2 className="text-lg font-semibold text-gray-900 mb-4">{t('treatment-statistics')}</h2>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div className="bg-blue-50 rounded-lg p-4">
                                            <div className="flex items-center space-x-2 text-blue-600 mb-2">
                                                <FiFileText className="w-4 h-4" />
                                                <span className="text-sm font-medium">{t('treatment-requests')}</span>
                                            </div>
                                            <p className="text-2xl font-bold text-blue-700">{selectedRequest?.treatmentRequests || 0}</p>
                                        </div>
                                        <div className="bg-green-50 rounded-lg p-4">
                                            <div className="flex items-center space-x-2 text-green-600 mb-2">
                                                <FiUsers className="w-4 h-4" />
                                                <span className="text-sm font-medium">{t('active-patients')}</span>
                                            </div>
                                            <p className="text-2xl font-bold text-green-700">{selectedRequest?.activePatients || 0}</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {/* REQUEST BY Information */}
                        <div className="bg-white rounded-xl border border-gray-200 p-6">
                            <h2 className="text-lg font-semibold text-gray-900 mb-4">{t('request-by')}</h2>

                            <div className="space-y-6">
                                <div className="space-y-2">
                                    <p className="font-medium text-gray-900">{selectedRequest?.requestedBy?.name || '--'}</p>
                                    <p className="text-sm text-gray-600">{selectedRequest?.requestedBy?.designation || '--'}</p>
                                    <div className="flex items-center space-x-2 text-sm text-gray-600">
                                        <CiMail className="w-4 h-4" />
                                        <span>{selectedRequest?.requestedBy?.email || '--'}</span>
                                    </div>
                                    <div className="flex items-center space-x-2 text-sm text-gray-600">
                                        <CiPhone className="w-4 h-4" />
                                        <span>{selectedRequest?.requestedBy?.mobile || '--'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {/* REQUEST APPOINTMENT  */}
            < AppointmentPaymentRequest
                isOpen={isOpenPayement}
                formData={paymentFormData}
                isLoading={isLoading}
                label={paymentButtonLabel || "payment"
                }
                onHandleChange={(field, value) => {
                    setPaymentFormData((prevData) => ({
                        ...prevData,
                        [field]: value
                    }));
                }}
                isCorporateRequest={selectedRequest?.corporateRequest ? true : false} // CORPORATE REQUEST FLAG 
                onClose={() => {
                    onPaymentClose();
                }}
                onHandleSubmit={() => {
                    const _status = _paymentStatus == 'pending' ? 'REQUESTED' : 'MODIFIED';
                    requestPayment(requestId, paymentFormData, _status);
                }}
            />

            {/* CONFIRM COMPLETE PAYMENT REQUEST */}
            <ConfirmCompletePayment
                isOpen={isOpenComplete}
                isLoading={isLoading}
                onClose={() => {
                    onCompleteClose();
                }}
                onCompleteRequest={() => {
                    completePaymentRequest(requestId, 'COMPLETED', 'CASH'); // CURRENTLY DEFAULT CASH WILL CHANGE FLOW LATTER 
                }}
            />
        </>
    )
}

export default CorporateRequestDetails

import React, { useEffect, useState } from "react";
import { InfoIcon } from "@chakra-ui/icons";
import { UserState } from "../../context/user";
import { useTranslation } from 'react-i18next';
import { Th, Table, TableContainer, Thead, Tr, Td, Tbody, Button, useDisclosure, useToast, Box } from '@chakra-ui/react';
import { FaStore } from "react-icons/fa";
import { GrVmMaintenance } from "react-icons/gr";
import { TbBrandMiniprogram } from "react-icons/tb"
import AddCategoryModel from "../../components/dialogueBoxes/AddCategory";
import { get, postFormData } from "../../services/apis/api";
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import { CategorySkeleton, EarningCardSkeleton } from "../../components/skeletons/Skeletons";
import { useNavigate } from "react-router-dom";
import TableComponent from "../../components/account/TableComponent";
import { debounce } from 'lodash';
import PastTransactions from "../../components/account/PastTransactions";


// CATEGORY CARD COLORS 
const cardColors = [
  '#e1f5fe', // Light cyan
  '#ffebee', // Light red
  '#c8e6c9', // Light green
  '#b3e5fc', // Light blue
]

const Heading = ({ title }) => (
  <h2 className="text-base text-gray-600 mb-2 lg:text-xl font-bold">{title}</h2>
)

const SubHeading = ({ title }) => (
  <h3 className="text-sm text-gray-500 mb-1 lg:text-lg font-semibold">{title}</h3>
);

// ADD CATEGORY FORM DATA 
const _categoryFormData = {
  name: '',
  // mode: '',
  file: null,
  selectedImg: null,
  description: ''
}

const EarningsAndIncome = () => {
  const { user } = UserState();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const isArabic = i18n.language === 'ar';
  const [requests, setRequests] = useState([]);
  const [categoryData, setCategoryData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryFormData, setCategoryFormData] = useState({ ..._categoryFormData });
  const [statisticsData, setStatisticsData] = useState(null);

  // EARNINGS CARD 
  const EarningsCard = ({ earning, color }) => {
    return (
      <div
        onClick={() => {
          if (earning?.label === 'additional-income') {
            navigate('/account/additional-income');
          }
        }}
        style={{ backgroundColor: color }}
        className={`bg-[#fcfbfb] shadow-md flex flex-col items-start gap-2 w-full md:w-[49%] lg:w-[24%] md py-6 px-8 rounded-md mb-4`}
      >

        <div className="w-full flex items-center  gap-2">
          <InfoIcon w={3} color="gray.500" />
          <h6 className="text-sm whitespace-nowrap lg:text-xs text-gray-500 font-medium capitalize">{t(earning?.label) || '--'}</h6>
        </div>
        <h4 className="text-xl lg:text-3xl font-bold">₹ {earning?.value?.toLocaleString('en-IN') || '--'}</h4>
        {/* <div className="w-full flex items-center  gap-2">
          <InfoIcon w={3} color="gray.500" />
          <h6 className="text-sm whitespace-nowrap lg:text-xs text-gray-600 font-medium"><span className={`${earning?.change >= 0 ? 'text-green-500' : 'text-red-500'}`}>{earning?.change > 0 ? '+' : '-'}{Math.abs(earning?.change) || '--'}</span> from last week</h6>
        </div> */}
      </div>
    )
  }

  // CATEGORY CARD 
  const CategoryCard = ({ category, onHandleClick }) => {
    return (
      <div onClick={onHandleClick} className='bg-[#f1f5f9] shadow-md lg:shadow-sm flex flex-col items-center justify-center gap-2 w-[49%] lg:w-1/5 md py-6 px-8 rounded-md mb-4'>
        {category?.categoryImg ?
          <img src={category?.categoryImg} className="w-14" alt="categoryImg" />
          :
          <GrVmMaintenance size={28} />
        }
        <h6 className="text-sm  lg:text-base font-semibold capitalize text-center">{t(category?.name)}</h6>
      </div>
    )
  }

  // CREATE A NEW CATEGORY 
  const addNewCategory = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();

      formData.append("categoryImg", categoryFormData?.file);
      formData.append("name", categoryFormData?.name);
      formData.append("description", categoryFormData?.description);
      // formData.append("incomeType", categoryFormData?.mode);

      const res = await postFormData(`transactionsCategory/add/`, formData);
      setIsLoading(false);
      if (res.statusCode === 201 || res.statusCode === 200) {
        getAllIncomeCategories();
        toast({
          title: 'Success!',
          description: res.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // getAlRwaadServices();
        // resetForm();
        setCategoryFormData({ ..._categoryFormData });
        onClose();
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: 'Error!',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.log(`ERROR WHILE CREATING CATEGORY: ${error}`);
    }
  };

  // GET ALL CATEGORY DATA BY API 
  const getAllIncomeCategories = async () => {
    try {
      const res = await get(`transactionsCategory/all`);
      if (res.statusCode === 200) {
        setCategoryData(res.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // GET TOTAL EARNINGS AND INCOMES BY API
  const getTotalEarnings = async () => {
    try {
      const res = await get(`transactionsCategory/get-total`);
      if (res.statusCode === 200) {
        console.log(res, 'response');
        setStatisticsData(res?.data?.data)
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllIncomeCategories();
    getTotalEarnings();
  }, []);

  return (
    <>
      <div className='w-full flex-1 flex flex-col gap-3 lg:gap-4 p-2 lg:p-4'>
        <div className={`flex justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
          <h2 className=' text-xl lg:text-2xl  whitespace-nowrap font-bold'>{t("earnings-income")}</h2>
        </div>
        <div className="w-full flex flex-wrap gap-2 lg:gap-4 items-start">
          {statisticsData ? statisticsData?.map((earning, index) => (
            <EarningsCard
              key={index}
              earning={earning}
              color={cardColors[index % cardColors.length]}
            />
          )) : <EarningCardSkeleton />}

        </div>
        <div className="w-full">
          <div className={`w-full flex  justify-between items-center mb-4`}>
            <Heading title={t("income-categories")} />
            <Button
              colorScheme="blue"
              onClick={onOpen}
            >
              {t("add-category")}
            </Button>
            <AddCategoryModel
              isOpen={isOpen}
              onClose={onClose}
              categoryFormData={categoryFormData}
              onHandleChange={(field, value) => {
                setCategoryFormData((prevData) => ({
                  ...prevData,
                  [field]: value
                }));
              }}
              isLoading={isLoading}
              onReset={() => {
                setCategoryFormData({ ..._categoryFormData });
                onClose();
              }}
              onHandleSubmit={addNewCategory}
            />
          </div>
          {/* Online Categories Section */}
          <div className="w-full py-4">
            {/* <SubHeading title={t("online")} /> */}
            <div className="w-full flex flex-wrap py-4 gap-2 lg:gap-4 items-center justify-start">
              {categoryData
                ? categoryData?.length > 0
                  ? categoryData?.map((category, index) => (
                    <CategoryCard
                      key={index}
                      category={category}
                      onHandleClick={() => {
                        navigate(`/account/earnings/${category?._id}`)
                      }}
                    />
                  ))
                  : 'NO CATEGORIES AVAILABLE'
                : <CategorySkeleton />
              }
            </div>
          </div>

        </div>

        <PastTransactions
          txnType={'CR'}
        />
      </div>
    </>
  );
};

export default EarningsAndIncome;

import React from 'react'
import { BsArrowUpShort } from 'react-icons/bs'
import { FiEye } from 'react-icons/fi'
import BarChart from '../../components/charts/BarChart'
import DonutChart from '../../components/charts/DonutChart'
import { GoGitPullRequest } from "react-icons/go";
import { FaChartBar } from "react-icons/fa";
import { AiFillBank } from "react-icons/ai";
import { GiMoneyStack } from "react-icons/gi";


const Home = () => {
  return (
    <div className='w-full mt-5 px-4'>
      <div className='flex flex-wrap w-full justify-between'>
        <div className='bg-[#f1f5f9] w-full sm:w-[48%] lg:w-[24%] py-4 px-8 rounded-md mb-4'>
          <div className='flex justify-between mb-2'>
            <GiMoneyStack className='h-12 w-12 bg-white p-1 rounded-lg' />
            <h2 className='font-bold text-black text-2xl'>120000</h2>
          </div>
          <div className='w-full flex justify-between px-2'>
            <h2 >Total Expense</h2>
            <h2 className='flex items-center text-green-700'>0.15% <BsArrowUpShort /></h2>
          </div>
        </div>
        <div className='bg-[#f1f5f9] w-full sm:w-[48%] lg:w-[24%] py-4 px-8 rounded-md mb-4'>
          <div className='flex justify-between mb-2'>
            <AiFillBank className='h-12 w-12 bg-white p-1 rounded-lg' />
            <h2 className='font-bold text-black text-2xl'>150</h2>
          </div>
          <div className='w-full flex justify-between px-2'>
            <h2>Total Assets</h2>
            <h2 className='flex items-center text-green-700'>0.15% <BsArrowUpShort /></h2>
          </div>
        </div>
        <div className='bg-[#f1f5f9] w-full sm:w-[48%] lg:w-[24%] py-4 px-8 rounded-md mb-4'>
          <div className='flex justify-between mb-2'>
            <GoGitPullRequest className='h-12 w-12 bg-white p-1 rounded-lg' />
            <h2 className='font-bold text-black text-2xl'>155</h2>
          </div>
          <div className='w-full flex justify-between px-2'>
            <h2>Total Requests</h2>
            <h2 className='flex items-center text-green-700'>0.15% <BsArrowUpShort /></h2>
          </div>
        </div>
        <div className='bg-[#f1f5f9] w-full sm:w-[48%] lg:w-[24%] py-4 px-8 rounded-md mb-4'>
          <div className='flex justify-between mb-2'>
            <FaChartBar className='h-12 w-12 bg-white p-1 rounded-lg' />
            <h2 className='font-bold text-black text-2xl'>5</h2>
          </div>
          <div className='w-full flex justify-between px-2'>
            <h2>Total Performance Plans</h2>
            <h2 className='flex items-center text-green-700'>0.15% <BsArrowUpShort /></h2>
          </div>
        </div>
      </div>
      <div className='mt-2'>
        <h2 className='text-lg font-medium'>Monthly Performance</h2>
      </div>
      <div className='mt-1 flex flex-wrap justify-between'>
        <div className='bg-[#f1f5f9] flex justify-center items-center p-4 w-full h-[60vh] lg:w-[60%] rounded-md shadow-md mb-4 lg:mb-0'>
          <BarChart />
        </div>
        <div className='bg-[#f1f5f9] flex justify-center items-center p-2 w-full lg:w-[37%] rounded-md shadow-md'>
          <DonutChart />
        </div>
      </div>
    </div>
  )
}

export default Home
'use client'

import React, { useState } from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spinner,
  Stack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { patch } from '../../services/apis/api';

export default function UpdatePassword() {
  const [username, setUsername] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const toast = useToast();

  const [loading, setLoading] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
        const response = await patch('user/update-old-password', { username, oldPassword, newPassword });
        console.log(response);
        setLoading(false);
        if (response.statusCode == 200) {
                toast({
                title: 'Password updated successfully.',
                status: 'success',
                duration: 5000,
                isClosable: true,
                });
        } 
    } catch (error) {
        setLoading(false);
        toast({
            title: 'Internal Server Error',
            description: error.response.data.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
    }
  };

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack
        spacing={4}
        w={'full'}
        maxW={'md'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}>
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Update New Password
        </Heading>
        <form onSubmit={handleSubmit}>
          <FormControl id="old-password" isRequired>
            <FormLabel>Old Password</FormLabel>
            <Input
              type="password"
              placeholder="Old Password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </FormControl>
          <FormControl id="new-password" isRequired>
            <FormLabel>New Password</FormLabel>
            <Input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </FormControl>
          <Stack spacing={6} mt={4}>
            <Button
              bg={'blue.400'}
              color={'white'}
              _hover={{
                bg: 'blue.500',
              }}
              type="submit">
                {
                    loading ? <Spinner /> : "Submit"
                }
            </Button>
          </Stack>
        </form>
      </Stack>
    </Flex>
  );
}

import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  useToast,
  Spinner,
  Input,
  FormControl,
  FormLabel,
  Switch,
  Image,
  Checkbox,
  Flex,
  Stack,
  Select
} from "@chakra-ui/react";
import { postFormData } from "../../services/apis/api";
import { useTranslation } from "react-i18next";

const CreateAlRwaadService = ({ getAlRwaadServices, AlRwaadService, isEdit }) => {
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [image, setImage] = useState();
  const [name, setName] = useState('');
  const [arabicName, setArabicName] = useState('');
  const [imgLink, setImgLink] = useState('');
  const [active, setActive] = useState(true);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('FREE');
  const [pricing, setPricing] = useState([]);

  const availablePlans = ["MONTHLY", "QUARTERLY", "HALF-YEARLY", "YEARLY", "ONE TIME PAYMENT"];

  useEffect(() => {
    if (isEdit && AlRwaadService) {
      setName(AlRwaadService.name);
      setArabicName(AlRwaadService?.arabicName);
      setImgLink(AlRwaadService.icon);
      setActive(AlRwaadService.active);
      setType(AlRwaadService.type || 'FREE');
      setPricing(AlRwaadService.pricing || []);
    }
  }, [isEdit, AlRwaadService]);

  const handleSave = async (id) => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("icon", image);
      formData.append("name", name);
      formData.append("arabicName", arabicName);
      formData.append("active", active);
      formData.append("type", type);
      console.log('hereee.......');

      if (type === "PAID" && pricing.length > 0) {

        formData.append("pricing", JSON.stringify(pricing));
      }
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }
      let res;
      if (isEdit) {
        res = await postFormData(`al-rwaad/update/${id}`, formData);
      } else {
        res = await postFormData('al-rwaad/add', formData);
      }

      if (res.statusCode === 201 || res.statusCode === 200) {
        setLoading(false);
        onClose();
        toast({
          title: 'Success!',
          description: res.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        getAlRwaadServices();
        resetForm();
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: 'Error!',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleOpen = () => {
    onOpen();
  };

  const resetForm = () => {
    setName('');
    setArabicName('');
    setImgLink('');
    setType('FREE');
    setPricing([]);
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
    if (e.target.value === 'FREE') {
      setPricing([]); // Reset pricing if type changes to FREE
    }
  };

  const handlePlanChange = (plan) => {
    const planExists = pricing.some(p => p.plan === plan);
    if (planExists) {
      // If plan is unchecked, remove it from the pricing array
      setPricing(pricing.filter(p => p.plan !== plan));
    } else {
      // If plan is checked, add it to the pricing array with an empty price
      setPricing([...pricing, { plan, price: "" }]);
    }
  };

  const handlePriceChange = (plan, value) => {
    setPricing(pricing.map(p => (p.plan === plan ? { ...p, price: value } : p)));
  };

  return (
    <>
      <Button colorScheme="blue" onClick={handleOpen}>{isEdit ? t("edit") : t("create-al-rwaad-service")}</Button>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent dir={isArabic ? 'rtl' : 'ltr'}>
          <ModalHeader>{isEdit ? t("edit-al-rwaad-Service") : t("create-al-rwaad-Service")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              {imgLink && <Image src={imgLink} alt="AlRwaadService Icon" mb={3} boxSize="100px" objectFit="cover" />}
              <FormLabel>{t("upload-logo")}</FormLabel>
              <Input type="file" onChange={(e) => { setImage(e.target.files[0]); const imageUrl = URL.createObjectURL(e.target.files[0]); setImgLink(imageUrl); }} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("al-rwaad-service-name")}</FormLabel>
              <Input value={name} onChange={(e) => setName(e.target.value)} placeholder={t("swimming")} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("al-rwaad-service-name-in-arabic")}</FormLabel>
              <Input value={arabicName} onChange={(e) => setArabicName(e.target.value)} placeholder="كرة القدم" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("service-type")}</FormLabel>
              <Select value={type} onChange={handleTypeChange}>
                <option value="FREE">{t("free")}</option>
                <option value="PAID">{t("paid")}</option>
              </Select>
            </FormControl>

            {type === "PAID" && (
              <FormControl mt={4}>
                <FormLabel>{t("select-plans")}</FormLabel>
                <Stack spacing={2}>
                  {availablePlans.map((plan) => (
                    <Flex key={plan} align="center" justify="space-between">
                      <Checkbox
                        isChecked={pricing.some(p => p.plan === plan)}
                        onChange={() => handlePlanChange(plan)}
                        flex="2"
                      >
                        {t(plan.toLowerCase())}
                      </Checkbox>
                      {pricing.some(p => p.plan === plan) && (
                        <Input
                          placeholder={t("enter-price")}
                          value={pricing.find(p => p.plan === plan)?.price || ""}
                          onChange={(e) => handlePriceChange(plan, e.target.value)}
                          ml={4}
                          // width="110px"
                          flex="1"
                        />
                      )}
                    </Flex>
                  ))}
                </Stack>

              </FormControl>
            )}

            <FormControl mt={4} display="flex" alignItems="center">
              <FormLabel htmlFor="active" mb="0">
                {t("active")}
              </FormLabel>
              <Switch id="active" isChecked={active} onChange={() => setActive(!active)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} mx={3}>{t("cancel")}</Button>
            <Button onClick={() => handleSave(isEdit ? AlRwaadService?._id : "")} colorScheme="blue">
              {loading ? <Spinner /> : t("save")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateAlRwaadService;

import React, { useEffect, useState } from "react";
import { get } from "../../services/apis/api";
import CreateProduct from "../../components/dialogueBoxes/CreateProduct";
import ViewProduct from "../../components/inventory/ViewProduct";

const Product = () => {
    const [products, setProducts] = useState([]);
    const [viewProduct, setViewProduct] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [searchTerm, setSearchTerm] = useState("");

    const getAllProducts = async (search = "") => {
        try {
            const res = await get(`product/penal/all?search=${search}`);
            console.log("product", res.data.data);
          
            setProducts(res.data.data);
        } catch (error) {
            console.error("Failed to fetch products:", error);
        }
    };

    useEffect(() => {
        getAllProducts(searchTerm);
    }, [searchTerm]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div>
            {viewProduct ? (
                <ViewProduct
                    product={selectedProduct}
                    setViewProduct={setViewProduct}
                />
            ) : (
                <div>
                    <div className="flex justify-between px-2 items-center mb-4">
                        <div>
                            <div className="relative">
                                <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <svg
                                        className="w-4 h-4 text-gray-500 "
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 20 20"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                        />
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    id="table-search-users"
                                    className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                                    placeholder="Search for Product name"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </div>
                        </div>
                        <div>
                            <CreateProduct getAllProducts={getAllProducts} />
                        </div>
                    </div>
                    <div>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    <tr>
                                        <th scope="col" className="p-4">
                                            <div className="flex items-center">
                                                S.No.
                                            </div>
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Product Name
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Category
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Type
                                        </th>
                                     
                                        <th scope="col" className="px-6 py-3">
                                            Stock
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.map((product, index) => (
                                        <tr
                                            key={product._id}
                                            className="bg-white border-b  hover:bg-gray-50 "
                                            onClick={() => {
                                                setSelectedProduct(product);
                                                setViewProduct(true);
                                            }}
                                        >
                                            <td className="w-4 p-4">
                                                <div className="flex items-center">
                                                    {index + 1}
                                                </div>
                                            </td>
                                            <th
                                                scope="row"
                                                className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap "
                                            >
                                                <div className="text-base font-semibold">
                                                    {product.name}
                                                </div>
                                            </th>
                                            <td className="px-6 py-4">
                                                {product?.category?.name}
                                            </td>
                                            <td className="px-6 py-4">
                                                {product?.type?.map((e) => (
                                                    <div>{e}</div>
                                                ))}
                                            </td>

                                          
                                            <td className="px-6 py-4">
                                                {product?.totalAvailableStock}
                                            </td>
                                            <td className="px-6 py-4">
                                                {product?.isActive
                                                    ? "Active"
                                                    : "Inactive"}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Product;

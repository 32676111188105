exports.formatDateTime = (dateTimeString, type) => {
  const dateTime = new Date(dateTimeString);

  if (type === 'date') {
    const day = dateTime.getDate();
    const month = dateTime.toLocaleString('default', { month: 'short' });
    const year = dateTime.getFullYear();
    return `${day} ${month} ${year}`;
  }

  if (type === 'time') {
    let hours = dateTime.getHours();
    const minutes = String(dateTime.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour clock
    return `${hours}:${minutes} ${ampm}`;
  }

  return null;
}



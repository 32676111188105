import { Button, FormControl, FormLabel, Input, Select, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { get, patch } from '../../services/apis/api'

const CreateMeasurementRequest = ({getRequestDetails, request}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)
  const [coaches, setCoaches] = useState([]);
  // State to hold form values
  const [formValues, setFormValues] = useState({
    coachId: '',
    status: 'COACH-REQUESTED',
  })

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  // Handle form save
  const handleSave = () => {
    console.log(formValues);
    assignCoach();
    onClose();
  }

  const assignCoach = async()=>{
    const res = await patch(`request/update/player-approval/${request._id}`, formValues);
    if(res.statusCode === 200){
      getRequestDetails();
    }
  }
  
  const getCoaches = async()=>{
    const res = await get('coach/dropdown');
    if(res.statusCode === 200){
      setCoaches(res.data.dropdown);
    }
  }
  useEffect(() => {
    getCoaches();
  }, [])
  
  return (
    <>
      <button onClick={onOpen} className='bg-[#290dbd] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md'>{"Assign Measurement Coach"}</button> 
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Measurement Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl mt={4}>
              <FormLabel>Coach ID</FormLabel>
              <Select
                placeholder='Select Coach'
                name='coachId'
                value={formValues.coachId}
                onChange={handleChange}
              >
                {coaches.map((coach, index) => (
                  <option className='pt-2' key={index} value={coach._id}>
                    {coach.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSave}>
              Assign Coach
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CreateMeasurementRequest

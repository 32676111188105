import React from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

const ExpensesSummery = ({
    expensesData,
}) => {
    const { t, i18n } = useTranslation();
    // Clean the labels: Replace any `null` value with 'Unknown' or a default name
    const cleanedLabels = expensesData?.expenseLabels?.map(label => label || 'unknown') || [];

    // Clean the series: Ensure corresponding values are aligned, replace `null` in series with 0 if necessary
    const cleanedSeries = expensesData?.expenseSeries?.map(value => value || 0) || [];

    const [series] = React.useState([{
        name: 'Expenses',
        data: cleanedSeries // Use the cleaned series data 
    }]);

    const chartOptions = {
        chart: {
            type: 'bar', // Bar chart type
        },
        colors: ['#34D399', '#FBBF24', '#dc3545', '#17a2b8'], // Color for each bar
        xaxis: {
            categories: cleanedLabels, // Use cleaned labels
            title: {
                text: 'Category', // X-axis label
            },
        },
        legend: {
            show: false, // Hide the default legend
        },
        plotOptions: {
            bar: {
                distributed: true, // Color each bar differently
                borderRadius: 1, // Rounded corners for bars
                horizontal: false, // Vertical bars
            },
        },
    };

    return (
        <div className="w-full lg:w-1/2 bg-white shadow-md flex-1 flex items-center justify-center flex-col rounded-md p-2 lg:p-5">
            <div className='w-full flex justify-between'>
                <h4 className="text-xl lg:text-xl font-bold mb-4">{t('expenses-summary')}</h4>
            </div>
            <Chart options={chartOptions} series={series} type="bar" width="500" />

            <div className="text-sm hidden flex-wrap justify-between font-medium text-center mt-4">
                {cleanedLabels.map((label, index) => (
                    <div key={index} className="flex items-center justify-between gap-12 mb-2">
                        <span className="flex items-center gap-2">
                            {/* Color dot */}
                            <span
                                className="w-3 h-3 rounded-full"
                                style={{ backgroundColor: chartOptions.colors[index % chartOptions.colors.length] }}
                            ></span>
                            {t(label)}
                        </span>
                        {/* Display count from series data */}
                        <span>{cleanedSeries[index] || ''}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ExpensesSummery;


import React, { useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Select,
    Grid,
    GridItem,
    useToast,
    Spinner,
} from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { post } from '../../services/apis/api'

const CreateAdmin = ({ getAdmins }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        jobTitle: '',
        gender: '',
        password: '',
        cnfPass: '',
        role: 'SUPER-ADMIN'
    })

    const [showPassword, setShowPassword] = useState(false)
    const [showCnfPass, setShowCnfPass] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === 'name') {
            // Regex to allow only alphabetical characters
            const regex = /^[a-zA-Z\s]*$/
            if (!regex.test(value)) {
                return
            }
        }
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleSubmit = async () => {
        console.log(formData);
        setLoading(true);
        try {
            const res = await post('user/register', formData);
            if (res.statusCode === 200) {
                getAdmins();
                toast({
                    title: "Successful...!",
                    description: res.data.message,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                })
                onClose();
            }
        } catch (error) {
            toast({
                title: "Error...!",
                description: error?.response?.data?.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        } finally {
            setLoading(false);
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    const toggleCnfPassVisibility = () => {
        setShowCnfPass(!showCnfPass)
    }

    return (
        <>
            <Button onClick={onOpen}>Create Club Admin</Button>

            <Modal isOpen={isOpen} onClose={onClose} size="5xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create Club Admin</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                            <GridItem>
                                <FormControl id="name" isRequired>
                                    <FormLabel>Name</FormLabel>
                                    <Input
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl id="email" isRequired>
                                    <FormLabel>Email</FormLabel>
                                    <Input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl id="mobile" isRequired>
                                    <FormLabel>Mobile</FormLabel>
                                    <Input
                                        type="tel"
                                        name="mobile"
                                        value={formData.mobile}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl id="jobTitle" isRequired>
                                    <FormLabel>Job Title</FormLabel>
                                    <Input
                                        name="jobTitle"
                                        value={formData.jobTitle}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl id="gender" isRequired>
                                    <FormLabel>Gender</FormLabel>
                                    <Select
                                        name="gender"
                                        value={formData.gender}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="MALE">Male</option>
                                        <option value="FEMALE">Female</option>
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl id="password" isRequired>
                                    <FormLabel>Password</FormLabel>
                                    <InputGroup>
                                        <Input
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                        />
                                        <InputRightElement width="4.5rem">
                                            <Button h="1.75rem" size="sm" onClick={togglePasswordVisibility}>
                                                {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl id="cnfPass" isRequired>
                                    <FormLabel>Confirm Password</FormLabel>
                                    <InputGroup>
                                        <Input
                                            type={showCnfPass ? "text" : "password"}
                                            name="cnfPass"
                                            value={formData.cnfPass}
                                            onChange={handleChange}
                                        />
                                        <InputRightElement width="4.5rem">
                                            <Button h="1.75rem" size="sm" onClick={toggleCnfPassVisibility}>
                                                {showCnfPass ? <ViewOffIcon /> : <ViewIcon />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                            </GridItem>
                        </Grid>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='gray' mr={3} onClick={onClose}>Cancel</Button>
                        <Button colorScheme='blue' onClick={handleSubmit} isLoading={loading}>
                            {loading ? <Spinner /> : "Submit"}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default CreateAdmin

import { Spinner, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { get, patch } from '../../services/apis/api';
import CustomAlert from '../alerts/CustomAlert';
import CreateCoach from '../dialogueBoxes/CreateCoach';
import { IoMdArrowRoundBack } from "react-icons/io";

const statusRoleMapping = {
    'HR-REVIEW': [
      'SUPER-ADMIN', 'HR-MANAGER', 'HR-SECRETORY', 'HR-ASSISTANT'
    ],
    'FINANCE-REVIEW': [
      'SUPER-ADMIN', 'ACCOUNT-MANAGER', 'ACCOUNT-SECRETORY', 'ACCOUNT-ASSISTANT'
    ],
    'ADMIN-REVIEW': [
      'SUPER-ADMIN', 'OPERATION-MANAGER', 'OPERATION-SECRETORY', 'OPERATION-ASSISTANT'
    ],
    // Add other status-to-role mappings as needed
};

const roleToTypeAndStatus = {
    'SUPER-ADMIN': { type: 'COACH-HIRING', status: 'APPROVED' },

    'HR-MANAGER': { type: 'COACH-HIRING', status: 'FINANCE-REVIEW' },
    'HR-SECRETORY': { type: 'COACH-HIRING', status: 'FINANCE-REVIEW' },
    'HR-ASSISTANT': { type: 'COACH-HIRING', status: 'FINANCE-REVIEW' },

    'ACCOUNT-MANAGER': { type: 'FINANCE-REQUEST', status: 'ADMIN-REVIEW' },
    'ACCOUNT-SECRETORY': { type: 'FINANCE-REQUEST', status: 'ADMIN-REVIEW' },
    'ACCOUNT-ASSISTANT': { type: 'FINANCE-REQUEST', status: 'ADMIN-REVIEW' },

};

const ViewRequest = ({requestId, setView, user, getAllRequest}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState({});
    const [canApprove, setCanApprove] = useState(false);
    const toast = useToast()
    const getHiringReq = async()=>{
        const res = await get(`request/get/coach-hiring/${requestId}`)
        if(res.statusCode === 200){
            setRequest(res.data.request);
            setCanApprove(statusRoleMapping[res.data.request.status]?.includes(user?.role));
        }
    }
    useEffect(() => {
        getHiringReq();
    }, [])
    
   
    const handleApprove = async () => {
        try {
            setLoading(true)
            const { status } = roleToTypeAndStatus[user?.role]
            if(status){
                const res = await patch(`request/coach-hiring/${request._id}`, {status: status})
                if(res.statusCode=== 200){
                    setLoading(false);
                    getHiringReq();
                    getAllRequest();
                    toast({
                        title: 'Successful!',
                        description: res.data.message,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: 'Oops!',
                description: error?.response?.data?.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleApproveClick = () => {
        onOpen();
    };
    const confirmApprove = ()=>{
        handleApprove();
        onClose();
    }

    return (
        <div>
            <div className='w-full p-4 flex justify-between'>
                <button onClick={()=>{setView(false)}} className='bg-[#290dbd] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md flex items-center'> <IoMdArrowRoundBack /> Back</button>
                <div className='flex'>
                    {
                        canApprove ?
                        <button onClick={() => handleApproveClick()} className='bg-[#290dbd] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md'>{loading ? <Spinner /> : "Approve Request"}</button>  : ""
                    }
                    {
                        request && request.status === "APPROVED" ? <CreateCoach />  : ""
                    }
                </div>
            </div>
            <div className='ps-2'>
                <div class="container mb-2">
                    <h2 className='font-bold text-xl text-slate-950'>Request Details</h2>
                </div>
                <div>
                    <div className='flex flex-wrap mb-5'>
                        <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-center items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>Request By: <span className='text-[#485060]'>{request?.requestedBy?.name}</span></h2>
                        </div>  
                        <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-center items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>Request Status: <span className='text-[#f99d4dfd]'>{request?.status}</span></h2>
                        </div>
                        <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-center items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>Request Type: <span className='text-[#485060]'>{request?.type}</span></h2>
                        </div>
                        <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-center items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>Game: <span className='text-[#485060]'>{request?.game?.name}</span></h2>
                        </div>
                        <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-center items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>Experience: <span className='text-[#485060]'>{request?.experience}</span></h2>
                        </div>
                    </div>
                    <div>
                        <h2 className='font-bold text-md text-black font-[outfit]'>Specialization: </h2>
                        <p className='font-[outfit]'>{request?.specialization}</p>
                    </div>
                    <div>
                        <h2 className='font-bold text-md text-black font-[outfit]'>Description: </h2>
                        <p className='font-[outfit]'>{request?.description}</p>
                    </div>
                </div>
            </div>
            <CustomAlert
                isOpen={isOpen}
                onClose={onClose}
                title="Approve request"
                description="Are you sure you want to approve this request ?"
                onConfirm={confirmApprove}
                confirmButtonText="Approve"
                cancelButtonText="Cancel"
                confirmButtonColorScheme="blue"
            />
        </div>
    )
}

export default ViewRequest
import React, { useState } from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    useDisclosure,
    Button,
    Tooltip,
    Input,
    useToast,
} from '@chakra-ui/react'
import { deleteToken } from '../../services/apis/token'
import { useNavigate } from 'react-router-dom'
import { UserState } from '../../context/user'
import { RxCrossCircled } from 'react-icons/rx'
import { patch } from '../../services/apis/api'
import { CiCircleCheck } from 'react-icons/ci'

const ConfirmApproveBox = ({ handlePaySlipRequest, onCloseModel, label, request, fetchData }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast();
    const { user, setUser } = UserState();
    const cancelRef = React.useRef()
    const navigate = useNavigate();

    
    return (
        <>
            <Tooltip label="Approve" aria-label="Reject tooltip">
                {/* <Button
                    variant="ghost"
                    colorScheme="red"
                    size="sm"
                    aria-label="Reject"
                    onClick={onOpen}
                    leftIcon={<RxCrossCircled size={20} />}
                >
                    {label ? 'Reject' : ''}
                </Button> */}
                <Button
                    variant="ghost"
                    colorScheme="green"
                    onClick={onOpen}
                    size="sm"
                    aria-label="Approve"
                >
                    <CiCircleCheck size={20} />
                </Button>
            </Tooltip>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Reject Request
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to approve this payroll request ?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button
                                size={"sm"}
                                ref={cancelRef}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                size={"sm"}
                                colorScheme='green'
                                onClick={() => {
                                    handlePaySlipRequest();
                                }}
                                ml={3}
                            >
                                APPROVE
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default ConfirmApproveBox
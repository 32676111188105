import React from 'react'

const Notification = () => {
    return (
        <div>
        Notification
        Notification
        </div>
    )
}

export default Notification

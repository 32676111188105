import React, { useEffect, useState } from 'react'
import CategoryHeader from '../../components/account/CategoryHeader'
import { useTranslation } from 'react-i18next'
import { Button, useDisclosure, useToast } from '@chakra-ui/react'
import { UserState } from "../../context/user";
import TableComponent from '../../components/account/TableComponent';
import AddEarningsHistory from '../../components/account/AddEarningHistory';
import { get, post } from '../../services/apis/api';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { CategoryDetailSkeleton } from '../../components/skeletons/Skeletons';
import AddAditionalIncome from '../../components/account/AddAdditionalIncome';


const Heading = ({ title }) => (
    <h2 className="text-base text-gray-600 mb-2 lg:text-xl font-bold">{title}</h2>
)

// TABLE COLUMNS 
const columns = [
    's-no',
    'Date-of-entry',
    'amount',
    'Transection Type',
    'source-of-income',
    'Description',
    'created-by'
    // 'Actions' //QUETIONS IT SHOLD GIVE HERE OR NOT 
];

const _formData = {
    entryDate: '',
    amount: null,
    description: '',
    sourceOfIncome: 'additional-income',
    transactionType: 'CR',
}

const AdditionalIncome = () => {
    const { user } = UserState();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const toast = useToast();
    const location = useLocation();
    const _id = location.pathname.split('/')[3];
    const [categoryData, setCategoryData] = useState(null)
    const [transactionsData, setTransactionsData] = useState(null)
    const [isLoading, setIsLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({ ..._formData })


    //    FETCH DATA FOR EARNING HISTORY TABLE
    useEffect(() => {
        getTransactionsDataById();
    }, [])

    // ADD NEW EARNING HISTORY  
    const addNewEarning = async (_id) => {
        setIsLoading(true)
        try {
            const _payload = {
                ...formData,
                incomeType: 'offline'
            }
            const res = await post(`transactionsCategory/add-earning`, _payload)
            setIsLoading(false);
            if (res.statusCode === 201 || res.statusCode === 200) {
                toast({
                    title: 'Success!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                setFormData({ ..._formData });
                getTransactionsDataById(_id);
                onClose();
            }
        } catch (e) {
            setIsLoading(false);
            console.log(`ERROR WHILE FETCHING DATA:${e}`);

        }
    }

    // GET ALL CATEGORY TRANSECTIONS BY CATEGORY ID
    const getTransactionsDataById = async () => {
        try {
            const response = await get(`transactionsCategory/transactions/additional-income/CR`)
            if (response?.statusCode == 200) {
                setTransactionsData(response?.data?.data);
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING DATA:${e}`);

        }
    }

    return (
        <div className='w-full p-2 lg:p-4'>
            {/* <CategoryHeader /> */}
            <button onClick={() => { navigate(-1) }} className="px-4 py-2 text-white bg-[#290dbd] rounded-md hover:bg-[#1d0a8c]">
                {t("back")}
            </button>
            <div className='w-full flex items-start justify-between py-4 lg:py-6'>
                <h1 className='text-xl lg:text-3xl font-bold mb-4 lg:mb-2 capitalize'>Additional Income</h1>
                <Button
                    variant="solid"
                    colorScheme='blue'
                    onClick={onOpen}
                >
                    Add New
                </Button>
                <AddAditionalIncome
                    onOpen={onOpen}
                    isOpen={isOpen}
                    onClose={onClose}
                    formData={formData}
                    onHandleChange={(field, value) => {
                        setFormData((prevData) => ({
                            ...prevData,
                            [field]: value
                        }));
                    }}
                    isLoading={isLoading}
                    onReset={() => {
                        setFormData({ ..._formData });
                        onClose();
                    }}
                    onHandleSubmit={() => addNewEarning(_id)}
                />
            </div>
            <div className="w-full flex flex-col items-start gap-3 flex-1 min-h-[50vh]">
                <Heading
                    title={t("history")}
                />
                <div className='w-full flex-1 flex flex-col '>
                    <TableComponent
                        columns={columns}
                        rows={transactionsData || []}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdditionalIncome

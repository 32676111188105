import { RadioGroup, Stack, Radio } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const SelectTransactionStateComponent = ({ selectedStatus, onSelelectState }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return (
        <RadioGroup
            onChange={(e) => {
                onSelelectState(e)
            }}
            value={selectedStatus}
        >
            <Stack
                direction={isArabic ? 'row-reverse' : 'row'} // Reverse direction for Arabic
                spacing={isArabic ? 4 : 5} // Adjust spacing for RTL layout
            >
                <Radio value="UNPAID">{t('unpaid')}</Radio>
                <Radio value="PAID">{t('paid')}</Radio>
                {/* <Radio value="pay-later">Pay Later</Radio> */}
            </Stack>
        </RadioGroup>
    );
};

export default SelectTransactionStateComponent;

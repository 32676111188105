import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import { get } from '../../services/apis/api';
import { Select, Divider, Th, Table, TableContainer, Thead, Tr, Td, Tbody, Button } from '@chakra-ui/react';
import {
    Menu,
    MenuButton,
    MenuList,
    IconButton
} from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons';
import ViewPlayerRequest from '../../components/game-management/ViewPlayerRequest';
import ViewTournamentRequest from '../../components/game-management/ViewTournamentRequest';
import { debounce } from 'lodash';
import ViewFNFRequest from '../../components/hr/components/fnf/ViewFNFRequest';
import ViewHrExpenseRequest from '../../components/account/ViewHrExpenseRequest';

// KIND FILTER OPTIONS 
const expenseTypeOptions = [
    'hrExpenseRequest',
    'tournamentRequest',
    'fNFRequest'
];

// STATUS FILTER OPTIONS
const requestStatusOptions = [
    'pending',
    'approved',
    'rejected',
];

const FinancialRequests = () => {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [searchTerm, setSearchTerm] = useState('');
    const [requests, setRequests] = useState([]);
    const [view, setView] = useState(false);
    const [selectedReq, setSelectedReq] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedKind, setSelectedKind] = useState(null);
    const [role, setRole] = useState(null);
    // GET ALL AVAILABLE REQUESTS 
    const getAllRequest = async () => {
        try {
            if (user) {
                // Build query parameters dynamically
                const queryParams = new URLSearchParams();
                if (searchTerm) queryParams.append("search", searchTerm);
                if (selectedStatus) queryParams.append("status", selectedStatus);
                if (selectedKind) queryParams.append("kind", selectedKind);

                // Make the API call with the dynamic query string
                const res = await get(`transactionsCategory/allFinanceRequests?${queryParams.toString()}`);

                if (res?.statusCode === 200) {
                    setRequests(res.data.requests || []);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Debounce the getAllRequest function
    const debouncedGetAllRequest = debounce(getAllRequest, 300); // Adjust delay as needed

    useEffect(() => {
        debouncedGetAllRequest();
        // Cleanup function to cancel the debounced call on component unmount
        return () => {
            debouncedGetAllRequest.cancel();
        };
    }, [searchTerm, selectedKind, selectedStatus]);

    // HANDLE USER ROLE 
    const handleRole = () => {
        if (user) {
            setRole(user.role);
        }
    };
    useEffect(() => {
        handleRole();
    }, [user]);

    const RequestList = () => {
        return (
            <TableContainer shadow="md" borderRadius="lg" flex={1} display="flex" flexDirection="column">
                <Table variant="simple">
                    <Thead bg="gray.50">
                        <Tr>
                            <Th>{t('s-no')}</Th>
                            <Th>{t('requested-by')}</Th>
                            <Th>{t('request-type')}</Th>
                            <Th>{t('request-status')}</Th>
                            <Th>{t('action')}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {requests.length > 0 ? (
                            requests.map((request, index) => (
                                <Tr key={request._id} _hover={{ bg: 'gray.50' }}>
                                    <Td>{index + 1}</Td>
                                    <Td>{request?.requestedBy?.name || request?.createdBy?.name}</Td>
                                    <Td>{request.kind}</Td>
                                    <Td>{request.status}</Td>
                                    <Td>
                                        <Button
                                            colorScheme="blue"
                                            onClick={() => { setView(true); setSelectedReq(request); }}
                                        >
                                            {t('view')}
                                        </Button>
                                    </Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Td colSpan="5" textAlign="center">
                                    {t('no-requests-found')}
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <>
            {view ? (
                <div className='p-2 lg:p-4'>
                    {
                        // <ViewRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} />
                        selectedReq && selectedReq.kind === "playerApprovalRequest" ? <ViewPlayerRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                            selectedReq.kind === "fNFRequest" ? <ViewFNFRequest setView={setView} request={selectedReq} user={user} getAllRequest={getAllRequest} /> :
                                selectedReq.kind === "tournamentRequest" ? <ViewTournamentRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                                    selectedReq.kind === "hrExpenseRequest" ? <ViewHrExpenseRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> : null
                    }
                </div>
            ) : (
                <div className='w-full flex-1 flex flex-col p-2 lg:p-4'>
                    <div className={`flex justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                        <h2 className='text-xl whitespace-nowrap font-bold mb-4'>{t("financial-request")}</h2>
                        <div className={`flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} gap-2 w-full justify-between lg:w-fit items-center`}>
                            <div className="relative">
                                <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    id="table-search-users"
                                    className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                                    placeholder={t("search-request")}
                                    value={searchTerm}
                                    onChange={(e) => { setSearchTerm(e.target.value) }}
                                />
                            </div>
                            <div className=''>
                                <Menu closeonselect={false}>
                                    <MenuButton
                                        as={IconButton}
                                        aria-label='Options'
                                        icon={<HamburgerIcon />}
                                        variant='outline'
                                    />
                                    <MenuList p={2}>
                                        <Select
                                            placeholder={t('expense-type')}
                                            textAlign={isArabic ? "right" : 'left'}
                                            bg="#f9fafb"
                                            borderColor="gray.300"
                                            value={selectedKind}
                                            onChange={(e) => { setSelectedKind(e.target.value); }}
                                        >
                                            {expenseTypeOptions.map((item) => (
                                                <option key={item} value={item}>
                                                    {t(item)}
                                                </option>
                                            ))}
                                        </Select>
                                        <Divider my={1} />
                                        <Select
                                            placeholder={t('request-status')}
                                            textAlign={isArabic ? "right" : 'left'}
                                            bg="#f9fafb"
                                            borderColor="gray.300"
                                            value={selectedStatus}
                                            onChange={(e) => { setSelectedStatus(e.target.value); }}
                                        >
                                            {requestStatusOptions.map((item) => (
                                                <option key={item} value={item}>
                                                    {t(item)}
                                                </option>
                                            ))}
                                        </Select>
                                    </MenuList>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <div className='w-full flex-1 flex flex-col '>
                        <RequestList />
                    </div>
                </div>
            )}
        </>
    )
}

export default FinancialRequests

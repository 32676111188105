import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import { get, patch, post } from '../../services/apis/api';
import { useLocation, useNavigate } from 'react-router-dom';
import ExpensesTransactionsTable from '../../components/account/ExoensesTransactionsTable';
import { Button, FormControl, FormLabel, IconButton, Select, useDisclosure, useToast } from '@chakra-ui/react';
import AddexpenseCategoryTransaction from '../../components/account/AddexpenseCategoryTransaction';
import { formatDateTime } from '../../utills/formateDate';
import SearchableSelectComponent from '../../components/account/SearchableSelectComponent';
import SelectYearComponent from '../../components/account/SelectYearComponent';
import { formatDate, formatMonthDateTime, formatNumber } from '../../services/glocalFunctions';
import BreadCrumbs from '../../components/BreadCrumbs';
import { DownloadIcon } from '@chakra-ui/icons';
import ShowTotalValue from '../../components/account/ShoTotalValue';
import ConfirmDownload from '../../components/dialogueBoxes/ConfirmDownload';

const _formData = {
    entryDate: '',
    amount: null,
    description: '',
    sourceOfIncome: '',
    transactionType: 'DR',
    game: null,
    player: null,
    paymentMethod: 'cash',
    paymentId: '',
    paymentState: 'UNPAID'
}

const ExpensesList = () => {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast();
    const isArabic = i18n.language === 'ar';
    const navigate = useNavigate();
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0); // Initial skip should be 0
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const route = decodeURIComponent(location.pathname.split('/').pop());
    const [allTransactions, setAllTransactions] = useState(null);
    const [limit, setLimit] = useState(10);
    const [isLoading, setIsLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({ ..._formData })
    const [allGames, setAllGamse] = React.useState([])
    const [allPlayers, setAllPlayers] = React.useState([])
    const [selectedId, setSelectedId] = React.useState(null)
    const [selectedData, setSelectedData] = React.useState(null)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [totalTransactionsAmount, setTotalTransactionsAmount] = useState(0);
    const [downloadOpen, setDownloadOpen] = useState(false);  // for confirmation dialog
    const _id = ''

    // Dynamically set columns based on the route
    const columns = [
        's-no',
        'Date-of-entry',
        'amount',
        // 'Transection Type',
        'payment-method',
        'payment-id',
        ...(route?.toLocaleLowerCase() === 'player' ? ['player-name'] : []),
        ...(route?.toLocaleLowerCase() === 'game-name' ? ['game-name'] : []),
        'created-by',
        'Description',
    ];

    // FETCH ALL TRANSACTIONS BY KIND 
    const fetchData = async (skip, limit, year) => {
        setLoading(true);
        try {
            // Include month and year in the API call if selected
            const res = await get(
                `expenses/transactions/kind/${route}?skip=${skip}&limit=${limit}&year=${year}`
            );
            if (res.statusCode === 200) {
                setAllTransactions(res?.data?.data);
                setTotalTransactionsAmount(res?.data?.totalAmount || 0)
                const totalCount = res?.data?.totalCount || 0; // Ensure totalCount is defined
                setTotalPages(Math.ceil(totalCount / limit));
            } else {
                setAllTransactions(res?.data?.data);
                setTotalPages(res?.data?.totalCount);
                setTotalTransactionsAmount(res?.data?.totalCount);
            }
        } catch (error) {
            setAllTransactions(error?.response?.data?.data);
            setTotalPages(error?.response?.data?.totalCount);
            setTotalTransactionsAmount(error?.response?.data?.totalAmount);
            console.error('Error fetching transactons by kind :', error);
        } finally {
            setLoading(false);
        }
    };

    // FETCH ALL TRANSACTIONS OF GAMES AND PLAYERS 
    const fetchTransactionsData = async (_skip, _limit, year) => {
        setLoading(true);
        try {
            // Include month and year in the API call if selected
            const res = await get(
                `expense-category/all-transactions?filter=${route?.toLocaleLowerCase()}&skip=${_skip}&limit=${_limit}&year=${year}`
            );
            if (res.statusCode === 200) {
                setAllTransactions(res?.data?.transactions);
                setSelectedData(null);
                setTotalTransactionsAmount(res?.data?.totalAmount || 0);
                const totalCount = res?.data?.totalCount || 0; // Ensure totalCount is defined
                setTotalPages(Math.ceil(totalCount / limit));
            } else {
                setAllTransactions([])
            }
        } catch (error) {
            setAllTransactions([])
            console.error('Error fetching payroll data:', error);
        } finally {
            setLoading(false);
        }
    };

    // GET ALL GAMES FOR SELECT LIST 
    const fetchGamesData = async () => {
        setLoading(true);
        try {
            // Include month and year in the API call if selected
            const res = await get(`game/all`);
            if (res.statusCode === 200) {
                setAllGamse(res?.data?.games);
            }
        } catch (error) {
            console.error('Error fetching payroll data:', error);
        } finally {
            setLoading(false);
        }
    };

    // GET ALL PLAYERS SELECT LIST 
    const fetchPlayersData = async () => {
        setLoading(true);
        try {
            // Include month and year in the API call if selected
            const res = await get(`player/all-professional`);
            if (res.statusCode === 200) {
                setAllPlayers(res?.data?.players);
            }
        } catch (error) {
            console.error('Error fetching payroll data:', error);
        } finally {
            setLoading(false);
        }
    };

    // ADD A GAME OR PLAYER EXPENSE TRANSACTION 
    const addExpenseTransaction = async () => {
        setLoading(true);
        try {
            const _payload = {
                ...formData,
                playerId: formData?.player?.value || null,
                gameId: formData?.game?.value || formData?.player?.gameId?._id || null,
            }

            delete _payload.player
            delete _payload.game
            const response = await post(`expense-category/add-transaction/${_id}`, _payload);
            setIsLoading(false);
            if (response?.statusCode == 200 || response?.statusCode == 201) {
                toast({
                    title: 'Success!',
                    description: response?.data?.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                if (selectedData) {
                    fetchSelectedData(selectedId, skip, limit, selectedYear);
                } else {
                    if (location?.pathname?.includes('game') || location?.pathname?.includes('player')) {
                        fetchTransactionsData(skip, limit, selectedYear);
                    }
                }
                setFormData({ ..._formData })
                onClose();
            }
        } catch (e) {
            setIsLoading(false);
            console.log(`ERROR WHILE ADDING TRANSACTION:${e}`);
        }
    }

    // PLACE HOLDER FOR SELECT 
    const placeholder = route?.includes('game') ? 'select-game' : 'select-player';

    // GET SELECTED GAME OR PLAYER DATA 
    const fetchSelectedData = async (_id, skip, limit, year) => {
        setLoading(true);
        try {
            // Include month and year in the API call if selected
            const res = await get(`expense-category/selected-details/${route?.toLocaleLowerCase()}/${_id}?skip=${skip}&limit=${limit}&year=${year}`);
            if (res.statusCode === 200) {
                setSelectedData({
                    ...res?.data?.data,
                    totalExpenses: res?.data?.total_amount,
                    dateRange: res?.data?.date_range
                });
                setAllTransactions(res?.data?.transactions);
                const totalCount = res?.data?.total_count || 0; // Ensure totalCount is defined
                setTotalPages(Math.ceil(totalCount / limit));
            } else {
                setAllTransactions([]);
            }
        } catch (error) {
            setAllTransactions([]);
            console.error('Error fetching payroll data:', error);
        } finally {
            setLoading(false);
        }
    };

    // SHOW SELECED OPTION 
    const SelectedOption = ({ title, value }) => (
        <div className='w-fit flex flex-col items-start gap-1'>
            <h6 className='text-[#858585] text-sm lg:text-md'>{t(title)}</h6>
            <h5 className='text-[#373737] text-base lg:text-base'>{value}</h5>
        </div>
    )

    // FETCH DATA   
    useEffect(() => {
        setAllTransactions(null);
        if (selectedData) {
            fetchSelectedData(selectedId, skip, limit, selectedYear)
        } else {
            if (location?.pathname?.includes('game') || location?.pathname?.includes('player')) {
                fetchTransactionsData(skip, limit, selectedYear);
            } else {
                fetchData(skip, limit, selectedYear); //FETCH OTHER TRANSACITON INSTEAD OF GAME AND PLAYER
            }
            if (location?.pathname?.includes('game')) {
                fetchGamesData();
            } else if (location?.pathname?.includes('player')) {
                fetchPlayersData();
            }
        }
    }, [selectedYear]);

    // DOWNLOAD DATA AS CSV 
    const downloadCSVData = async (year) => {
        try {
            setLoading(true);
            let response
            // Get current year
            let currentYear = new Date().getFullYear();
            if (selectedData) {
                response = await get(`expense-category/selected-details/${route?.toLocaleLowerCase()}/${selectedId}?year=${year}`);
            } else {
                if (location?.pathname?.includes('game') || location?.pathname?.includes('player')) {
                    response = await get(
                        `expense-category/all-transactions?filter=${route?.toLocaleLowerCase()}&year=${year}`
                    );
                } else {
                    response = await get(
                        `expenses/transactions/kind/${route}?year=${year}`
                    );
                }
            }

            if (response?.statusCode === 200) {
                const transactionsData = response?.data?.transactions || response?.data?.data;

                // Construct the filename with the current year
                const filename = `total-expenses-${currentYear}.csv`;

                // Check if there are payslips to export
                if (!transactionsData || transactionsData?.length === 0) {
                    setLoading(false);
                    toast({
                        title: 'Info',
                        description: 'No data to export!',
                        status: 'info',
                        duration: 5000,
                        isClosable: true,
                    });
                    return;
                }

                // Generate headers from the first payslip object keys
                const headers = [
                    "S NO.",
                    "Date Of Entry",
                    "Amount",
                    "Payment Method",
                    "Payment Type",
                    "Payment ID",
                    "Created By",
                    "Description"
                ];

                // Convert payslip data into CSV rows
                const rows = transactionsData?.map((txn, index) => {
                    return [
                        index + 1,
                        txn?.date ? formatDate(txn?.date) : "N/A",
                        txn?.amount ?? 0,
                        txn?.paymentMethod || "N/A",
                        txn?.paymentType || "N/A",
                        txn?.paymentId || "N/A",
                        txn?.createdBy?.name || txn?.requestedBy?.name || 'N/A',
                        txn?.description || "N/A"
                    ]?.join(",");
                });

                // Combine headers and rows
                const csvContent = [headers?.join(","), ...rows]?.join("\n");

                // Create Blob and trigger download
                const blob = new Blob([csvContent], { type: "text/csv" });
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                setLoading(false);
                toast({
                    title: 'Success',
                    description: 'CSV Downloaded Successfully!',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (e) {
            setLoading(false);
            console.log(`ERROR WHILE DOWNLOADING CSV ${e}`);
        }
    };

    // Confirm download
    const confirmDownload = () => {
        try {
            downloadCSVData(selectedYear)
            setDownloadOpen(false);
        } catch (e) {
            console.log(`ERROR WHILE GENERATING PDF ${e}`);
        }
    };

    return (
        <>
            <div className='w-full flex-1 flex flex-col items-start gap-2 lg:gap-4 p-2 lg:p-4'>
                {/* <button onClick={() => { navigate(-1) }} className="px-4 py-2 text-white bg-[#290dbd] rounded-md hover:bg-[#1d0a8c]">
                    {t("back")}
                </button> */}
                <BreadCrumbs />
                <div className={`flex w-full justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                    <div className={`flex flex-col ${isArabic ? 'items-end' : 'items-start'} gap-2`}>
                        <h2 className='text-xl lg:text-2xl  whitespace-nowrap font-bold'>{t(route)}</h2>
                        {/* SHOW TOTAL VALUE  */}
                        <ShowTotalValue
                            title="total-expenses"
                            value={totalTransactionsAmount}
                        />
                    </div>
                    <div className={`flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} items-center gap-2 lg:gap-4I`}>
                        <SelectYearComponent
                            selectedYear={selectedYear}
                            setSelectedYear={setSelectedYear}
                        />
                        {/* Download Confirmation Dialog */}
                        <ConfirmDownload
                            isOpen={downloadOpen}
                            label='download-csv'
                            setIsOpen={setDownloadOpen}
                            handleDownload={() => {
                                setDownloadOpen(true);
                            }}
                            isIcon={location?.pathname?.includes('game') || location?.pathname?.includes('player') ? true : false}
                            confirmDownload={() => {
                                confirmDownload();
                            }}
                            closeDialog={() => {
                                setDownloadOpen(false);
                            }}
                            loading={loading}
                        />
                        {location?.pathname?.includes('game') || location?.pathname?.includes('player') ?
                            <>
                                <SearchableSelectComponent
                                    placeholder={allGames?.length > 0 ? "select-game" : "select-player"}
                                    allOptions={allGames?.length > 0 ? allGames : allPlayers}
                                    fetchSelectedData={(value, skip, limit) => {
                                        fetchSelectedData(value, skip, limit, selectedYear)
                                    }}
                                    fetchTransactionsData={(skip, limit) => {
                                        fetchTransactionsData(skip, limit, selectedYear)
                                    }}
                                    fetchData={(skip, limit) => {
                                        fetchData(skip, limit, selectedYear)
                                    }}
                                    skip={skip}
                                    limit={limit}
                                    setPage={setPage}
                                    setSkip={setSkip}
                                    setLimit={setLimit}
                                    setSelectedData={setSelectedId}
                                    setSelectedId={setSelectedId}
                                />
                                <Button
                                    variant="solid"
                                    colorScheme='blue'
                                    onClick={onOpen}
                                    paddingX={10}
                                >
                                    {t('add-new')}
                                </Button>
                            </>
                            : null}
                    </div>
                    {onOpen ?
                        <AddexpenseCategoryTransaction
                            onOpen={onOpen}
                            isOpen={isOpen}
                            onClose={onClose}
                            heading={`add-${route}-expense`}
                            formData={formData}
                            onHandleChange={(field, value) => {
                                setFormData((prevData) => ({
                                    ...prevData,
                                    [field]: value
                                }));
                            }}
                            isLoading={loading}
                            allGamesData={allGames}
                            allPlayersData={allPlayers}
                            onReset={() => {
                                setFormData({ ..._formData });
                                onClose();
                            }}
                            onHandleSubmit={() => {
                                addExpenseTransaction()
                            }}
                        />
                        : null}
                    {/* SEARCH HIDE FOR CURRENT  */}
                    {/* <div className="relative">
                        <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                            <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                        <input
                            type="text"
                            id="table-search-users"
                            className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                            placeholder={t("search-request")}
                            value={searchTerm}
                            onChange={(e) => { setSearchTerm(e.target.value) }}
                        />
                    </div> */}
                </div>
                {selectedData ?
                    <div className='w-full rounded bg-[#5367FF1A] flex items-center justify-between p-4 lg:p-6'>
                        {route == 'player' ? <img className='w-20 h-20 rounded-sm' src={selectedData?.pic} alt="profileimg" /> : null}
                        <SelectedOption
                            title={"duration"}
                            value={`${formatMonthDateTime(selectedData?.dateRange?.from)} - ${formatMonthDateTime(selectedData?.dateRange?.to)}` || '--'}
                        />
                        <SelectedOption
                            title={"name"}
                            value={selectedData?.userName || selectedData?.name || '--'}
                        />
                        <SelectedOption
                            title={"total-expenses"}
                            value={formatNumber(selectedData?.totalExpenses, isArabic) || '--'}
                        // value={selectedData?.totalExpenses?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '--'}
                        />
                        <SelectedOption
                            title={"created-date"}
                            value={formatMonthDateTime(selectedData?.createdAt || selectedData?.updatedAt, isArabic) || '--'}
                        />
                    </div>
                    : null}
                <div className='w-full flex-1 flex flex-col '>
                    <ExpensesTransactionsTable
                        page={page}
                        skip={skip}
                        limit={limit}
                        setSkip={setSkip}
                        setLimit={setLimit}
                        setPage={setPage}
                        columns={columns}
                        rows={allTransactions}
                        totalPages={totalPages}
                        onPagination={(skip, limit) => {
                            if (selectedData) {
                                fetchSelectedData(selectedId, skip, limit, selectedYear);
                            } else {
                                if (location?.pathname?.includes('game') || location?.pathname?.includes('player')) {
                                    fetchTransactionsData(skip, limit, selectedYear);
                                } else {
                                    fetchData(skip, limit, selectedYear);
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default ExpensesList 

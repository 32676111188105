import { Select } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from './ChangeLanguage';

const General = () => {

    const { t, i18n } = useTranslation();

    return (
        <div>
            <div className='bg-slate-50 rounded-lg p-4 flex justify-between items-center'>
                <div>
                    <h2>{t("choose-your-language")}: </h2>
                </div>

                <div>
                    <ChangeLanguage />
                </div>
            </div>
        </div>
    );
};

export default General;



import React from 'react';
import { PiLockKey } from 'react-icons/pi';

const Account = () => {
  return (
    <div className='flex flex-col px-8 pt-8 w-[100%] h-[100%]'>
      <div className='h-10 flex justify-start mb-1'>
        <div className='rounded-full w-8 h-8 mt-1 me-2'>
          <PiLockKey size={25} />
        </div>
        <h2 className='font-bold text-[#000000] text-2xl'>Account Settings</h2>
      </div>
      <div className='flex w-[100%]'>
        <div className='w-[44%] flex flex-col me-12'>
          <div className="mb-3">
            <label className="block text-sm font-medium mb-1">Full Name</label>
            <input type="text" id="fullName" name="fullName" className="w-[100%] h-9 px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500" />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium mb-1">Email</label>
            <input type="email" id="email" name="email" className="w-[100%] h-9 px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500" />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium mb-1">Contact Number</label>
            <input type="tel" id="contactNumber" name="contactNumber" className="w-[100%] h-9 px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500" />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium mb-1">Password</label>
            <input type="password" id="password" name="password" className="w-[100%] h-9 px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500" />
          </div>
          <div className='flex'>
            <div className='w-8 flex justify-center'>
              <div className='h-6 w-6 border-2 border-slate-200 rounded-full flex justify-center items-center'>
                <div className='bg-[#5E3EF5] w-3 h-3 rounded-full'></div>
              </div>
            </div>
            <div className='w-[calc(100% - 32px)] ms-2'>
              <h2 className='font-semibold text-lg'>Enable Two-Step Verification</h2>
              <p className='text-[14px] font-normal'>Add an extra layer of security by requiring a verification code whenever you sign in on a new device.</p>
            </div>
          </div>
          <div className='flex'>
            <div className='w-8 flex justify-center'>
              <div className='h-6 w-6 border-2 border-slate-200 rounded-full flex justify-center items-center'>
                <div className='bg-[#5E3EF5] w-3 h-3 rounded-full'></div>
              </div>
            </div>
            <div className='w-[calc(100% - 32px)] ms-2'>
              <h2 className='font-semibold text-lg'>Allow Login from Multiple Devices</h2>
              <p className='text-[14px] font-normal'>Enable this option to login from multiple devices at once and use the app across all devices simultaneously.</p>
            </div>
          </div>
          <div className="mt-4 flex">
            <button className='flex justify-center items-center mx-1 border-2 border-[#5E3EF5] py-2 px-8 text-[#5E3EF5] font-semibold text-base rounded-lg'>
              Cancel
            </button>
            <button className='flex justify-center items-center mx-1 border-2 border-[#5E3EF5] py-2 px-8 bg-[#5E3EF5] text-[#ffffff] font-semibold text-base rounded-lg'>
              Save Changes
            </button>
          </div>
        </div>
        <div className='w-[50%] h-[100%] flex items-center pb-32'>
          <img width={'100%'} src='https://i.imgur.com/TcKFIqm.png' alt='Account Settings' />
        </div>
      </div>
    </div>
  );
}

export default Account;

import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Link,
  Input,
  Text,
  VStack,
  HStack,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { FaDownload, FaUpload } from 'react-icons/fa';
import csv from '../../../assets/files/employees-dummy-data.csv';
import { postFormData } from '../../../services/apis/api';

const CreateMultipleEmployee = ({getEmployees}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file to upload');
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
        const res = await  postFormData('employee/add-csv', formData)
        console.log(res);
        if(res.statusCode === 201){
            onClose();
            setLoading(false);
            getEmployees();
            toast({
              title: 'Success',
              description: `${res.data.message} Inserted Count: ${res.data.insertedCount}, Matched Count: ${res.data.matchedCount}, Modified Count: ${res.data.modifiedCount}, Upserted Count: ${res.data.upsertedCount}, Rejected Count: ${res.data.rejectedCount}`,
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
        }
    } catch (error) {
        setLoading(false);
        toast({
            title: 'Error!',
            description: error.response.data.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
        console.error('Error uploading file:', error);
    }
  };

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue" mr={2} mb={{ base: 2, md: 0 }}>
        ADD CSV
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Employee Data</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Box w="full" textAlign="center">
                <Text fontSize="lg" fontWeight="bold">Sample CSV Download</Text>
                <Link href={csv} download>
                  <Button leftIcon={<FaDownload />} colorScheme="blue" variant="solid" mt={2}>
                    Download Sample CSV
                  </Button>
                </Link>
              </Box>
              <Box w="full" textAlign="center">
                <Text fontSize="lg" fontWeight="bold">Upload CSV</Text>
                <HStack justify="center" mt={2}>
                  <Input type="file" accept=".csv" variant="outline" onChange={handleFileChange} />
                  <Button leftIcon={<FaUpload />} colorScheme="blue" variant="solid" onClick={handleUpload}>
                    {loading ? <Spinner /> : "Upload" }
                  </Button>
                </HStack>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateMultipleEmployee;

import React, { createContext, useContext, useState } from "react";

// Create the asset context
const assetContext = createContext();

const AssetProvider = ({ children }) => {
  const [assetRefresh, setAssetRefresh] = useState(null);

  return (
    <assetContext.Provider
      value={{
        assetRefresh,
        setAssetRefresh,
      }}
    >
      {children}
    </assetContext.Provider>
  );
};

export const AssetState = () => {
  return useContext(assetContext); 
};

export default AssetProvider;

import { MdOutlineCategory } from "react-icons/md";

import { RiMoneyRupeeCircleLine} from "react-icons/ri";
import { MdOutlineAccountBalance, MdOutlineWarehouse } from "react-icons/md";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { FaDropbox } from "react-icons/fa";
import { BsGraphUpArrow } from "react-icons/bs";

import { RiTeamFill, RiAddFill } from "react-icons/ri";
import { GiBabyfootPlayers } from "react-icons/gi";
import { FaHatCowboy } from "react-icons/fa";

import { MdEvent } from "react-icons/md";
import { TbCategoryPlus, TbTournament } from "react-icons/tb";
import { LiaAwardSolid } from "react-icons/lia";
import { IoNotificationsCircleOutline } from "react-icons/io5";

import { PiGameController } from "react-icons/pi";
import { TbUserPlus } from "react-icons/tb";

import { MdAppRegistration } from "react-icons/md";
import { FcPlanner } from "react-icons/fc";
import { LiaFileContractSolid } from "react-icons/lia";
import { TiGroupOutline } from "react-icons/ti";
import { MdSportsScore } from "react-icons/md";
import { RiPresentationFill } from "react-icons/ri";

import { BsPeople, BsLayoutTextSidebarReverse, BsCart4 } from "react-icons/bs";
import { IconType } from 'react-icons'
import { FaUsersGear } from "react-icons/fa6";
import { GrUserAdmin, GrUserManager } from "react-icons/gr";
import { RiTeamLine } from "react-icons/ri";
import { MdSportsHandball } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { GoGitPullRequest } from "react-icons/go";
import { MdOutlineScoreboard } from "react-icons/md";
import { GrUserWorker } from "react-icons/gr";
import { FaMobileScreen } from "react-icons/fa6";
import { BsColumns } from "react-icons/bs";
import { GrOrganization } from "react-icons/gr";
import { TbUsersGroup } from "react-icons/tb";
import { FaRegCalendarCheck } from "react-icons/fa6";
import { VscGraph } from "react-icons/vsc";
import { GiMoneyStack } from "react-icons/gi";
import { FaBullhorn } from "react-icons/fa";
import { FaRegAddressCard } from "react-icons/fa";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { MdRealEstateAgent } from "react-icons/md";
import { TbDeviceAnalytics } from "react-icons/tb";


export const getIconComponent = (iconName) => {
    const allIcons = {
      // Supplier section icons
      FaUsersGear: FaUsersGear,
      MdOutlineCategory: MdOutlineCategory,
      MdOutlineWarehouse: MdOutlineWarehouse,
      LiaFileInvoiceDollarSolid: LiaFileInvoiceDollarSolid,
      FaDropbox: FaDropbox,
      BsGraphUpArrow: BsGraphUpArrow,
      RiMoneyRupeeCircleLine: RiMoneyRupeeCircleLine,
  
      // Game Manager section icons
      RiTeamFill: RiTeamFill,
      GiBabyfootPlayers: GiBabyfootPlayers,
      FaHatCowboy: FaHatCowboy,
      RiAddFill: RiAddFill,
  
      // Media Center section icons
      MdEvent: MdEvent,
      TbTournament: TbTournament,
      LiaAwardSolid: LiaAwardSolid,
      IoNotificationsCircleOutline: IoNotificationsCircleOutline,
      TbUserPlus: TbUserPlus,
  
      // Operation section icons
      PiGameController: PiGameController,
  
      // Secretary section icons
      MdAppRegistration: MdAppRegistration,
      FcPlanner: FcPlanner,
      LiaFileContractSolid: LiaFileContractSolid,
      TiGroupOutline: TiGroupOutline,
      MdSportsScore: MdSportsScore,
      RiPresentationFill: RiPresentationFill,

      // game manager section icons
      RiTeamLine: RiTeamLine,
      FaUserTie: FaUserTie,
      MdSportsHandball: MdSportsHandball,
      GoGitPullRequest: GoGitPullRequest, 
      MdOutlineScoreboard: MdOutlineScoreboard,
      GrUserWorker: GrUserWorker,
      FaMobileScreen: FaMobileScreen,
      BsColumns: BsColumns,
      GrOrganization: GrOrganization,
      TbCategoryPlus: TbCategoryPlus,

      // hr management section icons
      TbUsersGroup: TbUsersGroup,
      FaRegCalendarCheck: FaRegCalendarCheck,
      VscGraph: VscGraph,
      GiMoneyStack: GiMoneyStack,
      FaBullhorn: FaBullhorn,
      FaRegAddressCard: FaRegAddressCard,
      MdOutlineAccountBalanceWallet: MdOutlineAccountBalanceWallet,
      MdRealEstateAgent: MdRealEstateAgent,
      TbDeviceAnalytics: TbDeviceAnalytics,
      

    };
  
    return allIcons[iconName] || null; 
  };
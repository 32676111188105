import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Textarea,
    Input,
    Switch,
    useDisclosure,
    Box,
    Select,
    Spinner,
    useToast,
    IconButton
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import ReactSelect from 'react-select'; // Import React Select
import { post, patch } from '../../../services/apis/api';

const AddPerformancePlan = ({ getAllPlans, isEdit, plan }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    // List of departments
    const departmentOptions = [
        { value: 'operation', label: 'Operation' },
        { value: 'account', label: 'Account' },
        { value: 'hr', label: 'HR' },
        { value: 'media-center', label: 'Media Center' },
        { value: 'store', label: 'Store' },
        { value: 'game-management', label: 'Game Management' },
    ];

    // List of applicable roles
    const applicableToOptions = [
        { value: 'manager', label: 'Manager' },
        { value: 'secretary', label: 'Secretary' },
        { value: 'assistant', label: 'Assistant' },
    ];

    // Form state
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        departments: [],
        isActive: true,
        createdBy: '', // Assuming the user ID is available
        applicableTo: [],
        frequency: 'monthly',
    });

    // Prefill form with plan data when editing
    useEffect(() => {
        if (isEdit && plan) {
            setFormData({
                name: plan?.name || '',
                description: plan?.description || '',
                departments: plan?.departments || [],
                isActive: plan?.isActive,
                applicableTo: plan?.applicableTo || [],
                frequency: plan?.frequency || 'monthly',
            });
        }
    }, [isEdit, plan]);

    // Handle form field changes
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // Handle department multi-select change
    const handleDepartmentChange = (selectedOptions) => {
        setFormData({
            ...formData,
            departments: selectedOptions ? selectedOptions.map(option => option.value) : [],
        });
    };

    // Handle applicableTo multi-select change
    const handleApplicableToChange = (selectedOptions) => {
        setFormData({
            ...formData,
            applicableTo: selectedOptions ? selectedOptions.map(option => option.value) : [],
        });
    };

    // Handle form submit
    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            let response;
            if (isEdit) {
                response = await patch(`performance/plan/update/${plan._id}`, formData);
            } else {
                response = await post('performance/plan/add', formData);
            }

            if (response.statusCode === 200 || response.statusCode === 201) {
                toast({
                    title: isEdit ? 'Plan Updated' : 'Plan Created',
                    description: `The performance plan has been ${isEdit ? 'updated' : 'created'} successfully.`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onClose();
                getAllPlans();
            } else {
                throw new Error('Failed to save the plan.');
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box>
            <div>
                {isEdit ? (
                    <IconButton
                        icon={<EditIcon />}
                        onClick={onOpen}
                        aria-label="Edit Performance Plan"
                    />
                ) : (
                    <Button onClick={onOpen} colorScheme="blue" mb={{ base: 2, md: 0 }}>
                        Add Plan
                    </Button>
                )}
            </div>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{isEdit ? 'Edit Performance Plan' : 'Add Performance Plan'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {/* Name */}
                        <FormControl mb="4">
                            <FormLabel>Name</FormLabel>
                            <Input
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Enter plan name"
                                required
                            />
                        </FormControl>

                        {/* Description */}
                        <FormControl mb="4">
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                placeholder="Enter performance plan description"
                                required
                            />
                        </FormControl>

                        {/* Department (ReactSelect for multi-select) */}
                        <FormControl mb="4">
                            <FormLabel>Department</FormLabel>
                            <ReactSelect
                                isMulti
                                name="departments"
                                options={departmentOptions}
                                value={departmentOptions.filter(option =>
                                    formData.departments.includes(option.value)
                                )}
                                onChange={handleDepartmentChange}
                                placeholder="Select departments"
                            />
                        </FormControl>

                        {/* Applicable To (ReactSelect for multi-select) */}
                        <FormControl mb="4">
                            <FormLabel>Applicable To</FormLabel>
                            <ReactSelect
                                isMulti
                                name="applicableTo"
                                options={applicableToOptions}
                                value={applicableToOptions.filter(option =>
                                    formData.applicableTo.includes(option.value)
                                )}
                                onChange={handleApplicableToChange}
                                placeholder="Select applicable roles"
                            />
                        </FormControl>

                        {/* Frequency */}
                        <FormControl mb="4">
                            <FormLabel>Frequency</FormLabel>
                            <Select
                                name="frequency"
                                value={formData.frequency}
                                onChange={handleChange}
                                required
                            >
                                <option value="monthly">Monthly</option>
                                <option value="quarterly">Quarterly</option>
                                <option value="annually">Annually</option>
                            </Select>
                        </FormControl>

                        {/* Is Active */}
                        <FormControl display="flex" alignItems="center" mb="4">
                            <FormLabel htmlFor="isActive" mb="0">
                                Is Active?
                            </FormLabel>
                            <Switch
                                id="isActive"
                                name="isActive"
                                isChecked={formData.isActive}
                                onChange={handleChange}
                            />
                        </FormControl>

                        <ModalFooter>
                            <Button variant="ghost" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button onClick={handleSubmit} colorScheme="blue" mx={3} type="submit">
                                {isLoading ? <Spinner /> : 'Submit'}
                            </Button>
                        </ModalFooter>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default AddPerformancePlan;

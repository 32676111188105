import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Text,
    Divider,
    VStack,
    HStack,
    Tag,
    Flex,
    useDisclosure,
    ModalFooter,
    useToast,
    Spinner,
} from '@chakra-ui/react';
import { patch } from '../../services/apis/api';
import { useTranslation } from 'react-i18next';
import { formatDateArEn } from '../../services/glocalFunctions';

const RequestDetailsModal = ({ request, getAllRequests }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);
    const toast = useToast();
    const [loading, setLoading] = useState();

    // HANDLE CANCEL REQUEST 
    const handleCancelRequest = async () => {
        try {
            setLoading(true)
            const res = await patch(`request/update-employee-request/${request?._id}`, { status: 'CANCELLED' });
            if (res.statusCode === 200) {
                toast({
                    title: 'Successfull...!',
                    description: "Request has been Cancelled",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onConfirmClose();
                closeModal();
                setLoading(false);
                getAllRequests();
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    return (
        <>
            <button colorScheme="blue" onClick={openModal} className='text-white bg-blue-600 px-4 py-[6px] rounded-lg font-medium'>
                {t('view')}
            </button>

            <Modal isOpen={isOpen} onClose={closeModal} size="lg">
                <ModalOverlay />
                <ModalContent borderRadius="lg" boxShadow="lg" overflow="hidden">
                    <ModalHeader bg="blue.500" color="white" fontSize="lg">
                        {t('request-details')}
                    </ModalHeader>
                    <ModalCloseButton color="white" />
                    <ModalBody py={6} px={8}>
                        <VStack align="stretch" spacing={6}>
                            <Box>
                                <Flex justify="space-between" align="center">
                                    <Box>
                                        <Text fontSize="sm" color="gray.500">
                                            {t('employee-name')}
                                        </Text>
                                        <Text fontWeight="bold">{request?.employeeId?.name}</Text>
                                    </Box>
                                    <Box>
                                        <Text fontSize="sm" color="gray.500">
                                            {t('employee-code')}
                                        </Text>
                                        <Text fontWeight="bold">{request?.employeeId?.employeeCode}</Text>
                                    </Box>
                                </Flex>
                            </Box>
                            <Divider />
                            <Box>
                                <Flex justify="space-between" align="center">
                                    <Box>
                                        <Text fontSize="sm" color="gray.500">
                                            {t('request-type')}
                                        </Text>
                                        <Text fontWeight="bold">{request?.requestType}</Text>
                                    </Box>
                                    <Box>
                                        <Text fontSize="sm" color="gray.500">
                                            {t('status')}
                                        </Text>
                                        <Tag
                                            size="md"
                                            colorScheme={
                                                (request?.status === "REJECTED" || request?.status === "CANCELLED")
                                                    ? "red"
                                                    : request?.status === "APPROVED"
                                                        ? "green"
                                                        : "yellow"
                                            }
                                            fontWeight="bold"
                                        >
                                            {request?.status}
                                        </Tag>
                                    </Box>
                                </Flex>
                            </Box>
                            <Divider />
                            <Box>
                                <Text fontSize="sm" color="gray.500" mb={2}>
                                    {t('details')}
                                </Text>
                                <VStack align="start" spacing={2}>
                                    {/* Leave request */}
                                    {request?.details?.startDate && (
                                        <Text>
                                            <strong>{t('start-date')}:</strong> {formatDateArEn(request?.details?.startDate, isArabic)}
                                        </Text>
                                    )}
                                    {request?.details?.endDate && (
                                        <Text>
                                            <strong>{t('end-date')}:</strong> {formatDateArEn(request?.details?.endDate, isArabic)}
                                        </Text>
                                    )}
                                    {request?.details?.leaveType && (
                                        <Text>
                                            <strong>{t('leave-type')}:</strong> {request?.details?.leaveType}
                                        </Text>
                                    )}
                                    {request?.details?.leaveReason && (
                                        <Text>
                                            <strong>{t('reason')}:</strong> {request?.details?.leaveReason}
                                        </Text>
                                    )}
                                    {/* for contract */}
                                    {request?.details?.previousContractEndDate && (
                                        <Text>
                                            <strong>{t('previous-contract-end-date')}:</strong> {formatDateArEn(request?.details?.previousContractEndDate, isArabic)}
                                        </Text>
                                    )}
                                    {request?.details?.contractStartDate && (
                                        <Text>
                                            <strong>{t('new-contract-start-date')}:</strong> {formatDateArEn(request?.details?.contractStartDate, isArabic)}
                                        </Text>
                                    )}
                                    {request?.details?.contractEndDate && (
                                        <Text>
                                            <strong>{t('new-contract-end-date')}:</strong> {formatDateArEn(request?.details?.contractEndDate, isArabic)}
                                        </Text>
                                    )}
                                    {request?.details?.contractDuration && (
                                        <Text>
                                            <strong>{t('contract-duration')}:</strong> {request?.details?.contractDuration}
                                        </Text>
                                    )}
                                    {request?.details?.renewalReason && (
                                        <Text>
                                            <strong>{t('reasons-for-renewal')}:</strong> {request?.details?.renewalReason}
                                        </Text>
                                    )}
                                    {/* salary Incriment Request */}
                                    {request?.details?.incrementPercentage && (
                                        <Text>
                                            <strong>{t('increment-percentage')}:</strong> {request?.details?.incrementPercentage}%
                                        </Text>
                                    )}
                                    {request?.details?.incrementAmount && (
                                        <Text>
                                            <strong>{t('total-amount-after-increment')}:</strong> {request?.details?.incrementAmount}
                                        </Text>
                                    )}
                                    {request?.details?.incrementReason && (
                                        <Text>
                                            <strong>{t('increment-reason')}:</strong> {request?.details?.incrementReason}
                                        </Text>
                                    )}
                                    {/* traning request */}
                                    {request?.details?.traningStartDate && (
                                        <Text>
                                            <strong>{t('traning-start-date')}:</strong> {formatDateArEn(request?.details?.traningStartDate, isArabic)}
                                        </Text>
                                    )}
                                    {request?.details?.traningEndDate && (
                                        <Text>
                                            <strong>{t('traning-end-date')}:</strong> {formatDateArEn(request?.details?.traningEndDate, isArabic)}
                                        </Text>
                                    )}
                                    {request?.details?.letterContext && (
                                        <Text>
                                            <strong>{t('training-program-details')}:</strong> {request?.details?.letterContext}
                                        </Text>
                                    )}
                                    {/* Autherization letter */}
                                    {request?.details?.trainingDetails && (
                                        <Text>
                                            <strong> {t('more-context-about-letter')}:</strong> {request?.details?.trainingDetails}
                                        </Text>
                                    )}
                                    {/* employee Certificate type */}
                                    {request?.details?.certificateType && (
                                        <Text>
                                            <strong>{t('certificate-type')}:</strong> {request?.details?.certificateType}
                                        </Text>
                                    )}
                                    {request?.details?.purposeOfCertificate && (
                                        <Text>
                                            <strong>{t('purpose-of-certificate')}:</strong> {request?.details?.purposeOfCertificate}
                                        </Text>
                                    )}
                                    {/* resignation request  */}
                                    {request?.details?.lastWorkingDay && (
                                        <Text>
                                            <strong>{t('last-working-day')}:</strong> {request?.details?.lastWorkingDay}
                                        </Text>
                                    )}
                                    {request?.details?.resignationReason && (
                                        <Text>
                                            <strong>{t('reason-for-resignation')}:</strong> {request?.details?.resignationReason}
                                        </Text>
                                    )}
                                    {request?.details?.fixationReason && (
                                        <Text>
                                            <strong>{t('specify-the-reason-for-the-request')}:</strong> {request?.details?.fixationReason}
                                        </Text>
                                    )}
                                </VStack>

                            </Box>
                            <Divider />
                            <Box>
                                <Text fontSize="sm" color="gray.500">
                                    {t('created-at')}
                                </Text>
                                <Text>{request?.createdAt ? formatDateArEn(request?.createdAt, isArabic) : null}</Text>
                            </Box>
                            {
                                request?.status != "CANCELLED" &&
                                <Box display="flex" justifyContent="flex-end" onClick={onConfirmOpen}>
                                    <Button colorScheme="red">{t('cancel-request')}</Button>
                                </Box>
                            }
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* confirm model  */}

            <Modal isOpen={isConfirmOpen} onClose={onConfirmClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign={isArabic ? 'right' : 'left'} dir={isArabic ? 'rtl' : 'ltr'}>{t('confirm-cancellation')}</ModalHeader>
                    <ModalCloseButton
                        style={{
                            [isArabic ? 'left' : 'right']: '10px' // Conditional positioning based on language
                        }}
                    />
                    <ModalBody>
                        <Text textAlign={isArabic ? 'right' : 'left'}>
                            {t('confirm-cancel-msg')}
                        </Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" onClick={onConfirmClose}>
                            {t('no')}
                        </Button>
                        <Button disabled={loading} colorScheme="red" ml={3} onClick={handleCancelRequest}>
                            {loading ? <Spinner /> : t('yes-cancel')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default RequestDetailsModal;
